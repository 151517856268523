import { stripDiacritics } from '@/utils/matcher.js';
//import ResponseAddress from './billing/responseAddress';
//import ResponseCard from './billing/responseCard';

class Auth {
  constructor(token, data) {
    this.token = token;
    if (data.sentParams) {
      this.pageId = data.sentParams.page_id;
      this.psid = data.sentParams.psid;
    }
    this.type = data.type;

    this.app = data.app;
    this.redirectTo = data.redirectTo;
    this.source = data.source;
    this.campaignId = data.campaignId;

    // state = booking | phoneConfirmation | nameConfirmation
    this.state = data.state;
    this.clientPhoneConfirmed = data.clientPhoneConfirmed;

    this.client = {};
    this.client.id = data.clientId;
    this.client.customerId = data.customerId;
    this.client.name = data.clientName;
    this.client.phoneCountry = data.phoneCountry;
    this.client.phoneNumber = data.phoneNumber;
    this.client.signupState = null;
    this.client.email = null;
    this.client.taxNumber = null;
    this.client.addresses = [];
    this.client.cards = [];

    this.business = {};
    this.business.id = data.businessId;
    this.business.name = data.businessName;
    this.business.permalink = data.businessPermalink;
    this.business.slotsMappingType = null;
    this.business.phoneCountry = null;
    this.business.phoneNumber = null;
  }

  updateCurrentClient(obj) {
    this.client.signupState = obj.state;

    this.client.name = obj.name;
    this.client.email = obj.email;
    this.client.taxNumber = obj.taxNumber;

    if (obj.addresses) {
      this.client.addresses = obj.addresses;
      /*
      this.client.addresses = [];
      obj.addresses.forEach(element =>
        this.client.addresses.push(new ResponseAddress(element)));
        */
    }
    if (obj.cards) {
      this.client.cards = obj.cards;
      /*this.client.cards = [];
      obj.cards.forEach(element =>
        this.client.cards.push(new ResponseCard(element)));*/
    }
  }

  hasName() {
    return this.client.signupState === 'has_name';
  }

  hasPaymentBasic() {
    return this.client.signupState === 'has_payment_basic';
  }

  hasAddress() {
    return this.client.signupState === 'has_address';
  }

  hasCard() {
    return this.client.signupState === 'has_card';
  }

  getPreferedAddress() {
    if (this.client.addresses.length > 0) {
      let address;
      for (let i = 0; i < this.client.addresses.length; i++) {
        address = this.client.addresses[i];
        if (address.isPrefered === true) {
          return address;
        }
      }
      return this.client.addresses[0];
    }
    return null;
  }

  getPreferedCard() {
    if (this.client.cards.length > 0) {
      let card;
      for (let i = 0; i < this.client.cards.length; i++) {
        card = this.client.cards[i];
        if (card.isPrefered === true) {
          return card;
        }
      }
    }
    return null;
  }

  getClientActiveCards() {
    return this.client.cards
      .filter(elem => elem.isPrefered === true || elem.status === 'active')
      .sort(function (a, b) {
        if (a.isPrefered === b.isPrefered) return 0;
        if (a.isPrefered === true) return -1;
        return 1;
      });
  }

  getClientActiveCardsCount() {
    return this.client.cards
      .filter(elem => elem.status === 'active').length;
  }

  getClientCard(uid) {
    const list = this.client.cards.filter(elem => elem.uid === uid);
    if (list.length > 0) {
      return list[0];
    }
    return null;
  }

  isAuthenticated() {
    return this.client.customerId && this.token;
  }

  needPhoneConfirmation() {
    return !this.client.customerId; // phoneConfirmation
  }

  setPhoneConfirmation() {
    this.state = null;
  }

  needCodeConfirmation() {
    return this.state && this.state.toLowerCase() === 'phoneconfirmation';
  }

  setCodeConfirmation() {
    this.state = 'phoneconfirmation';
  }

  needNameConfirmation() {
    //return this.state && this.state.toLowerCase() === 'nameconfirmation';
    return this.client.customerId && this.client.signupState === 'blank';
  }

  setNameConfirmation() {
    this.state = 'nameconfirmation';
  }

  needNameConfirmationAtBooking() {
    return !this.client.customerId && this.clientPhoneConfirmed === true;
  }

  getCoverImage(baseUrl) {
    const category = this.business.category;
    if (category && category !== '') {
      const key = stripDiacritics(category).toLowerCase();
      return `${baseUrl}images/placeholder-${key}.png`;
    }
    return '';
  }
}

export default Auth;
