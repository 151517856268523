<template>
  <div
    class="account-details">
    <Sticky>
      <Navbar
        :title="navbarTitle"
        :show-shadow="false"
        :on-ok="onButtonOk"
        :disable-btn-ok="!formComplete"
        :save-btn="navbarSaveButton"
        :is-loading="isLoading"
        show-back-bevel
        class="account-details__navbar" />
    </Sticky>

    <div class="account-details__content">
      <transition name="fade">
        <PaymentSection1
          v-if="customer"
          ref="details_component"
          :show-title="false"
          :edit-state="true"
          :is-loading="isLoading"
          :customer="customer"
          :on-data-changed="stepDataChanged"
          class="account-details__content__details_form" />
      </transition>
    </div>

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />

    <AlertDestructive
      v-if="alertConfirmShowing"
      :title="alertConfirmTitle"
      :message="alertConfirmMessage"
      :btn-cancel="alertConfirmCancel"
      :btn-destructive="alertConfirmOk"
      @closed="onAlertConfirmDismissed"
      @cancel="onAlertConfirmDismissed"
      @ok="onAlertConfirmOk" />

  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import PaymentSection1 from '@/components/Payments/PaymentSection_1.vue';

import Alert from '@/components/Alerts/BaseAlert.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';

import CustomerDetails from '@/models/billing/customerDetails';

export default {
  name: 'AccountAddress',

  // Components ********************************
  components: {
    Navbar,
    PaymentSection1,
    Alert,
    AlertDestructive,
  },

  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      const component = this.$refs.details_component;
      if (this.showConfirmationOnBack === true
        && component
        && component.originalDataChanged() === true) {
        next(false);
        const self = this;
        this.alertConfirm(
          this.$t('alerts.alert_payment_details_on_back_title'),
          this.$t('alerts.alert_payment_details_on_back_message'),
          this.$t('alerts.alert_payment_details_on_back_yes'),
          this.$t('alerts.alert_payment_details_on_back_no'),
          function () {
            self.showConfirmationOnBack = false;
            self.$router.go(-1);
          }
        );
        return;
      }
    }
    next();
  },

  // Props ********************************
  props: {
  },

  data() {
    return {
      isLoading: false,

      customer: null,

      formComplete: false,
      showConfirmationOnBack: true,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,

      alertConfirmShowing: false,
      alertConfirmTitle: null,
      alertConfirmMessage: null,
      alertConfirmOk: null,
      alertConfirmCancel: null,
    };
  },

  // Computed ********************************
  computed: {
    showNavbarBack() {
      return this.$store.getters.isExternalSource === true;
    },
    navbarTitle() {
      return 'Editar dados';
    },
    navbarSaveButton() {
      return this.$t('modals.buttons.save');
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated()) {
      this.$router.replace({
        name: this.$constants.ROUTE_LOGIN,
      }, () => {});
    }

    this.customer = new CustomerDetails();
    this.customer.name = auth.client.name;
    this.customer.email = auth.client.email;
    this.customer.taxNumber = auth.client.taxNumber;
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated()) {
      return;
    }

    this.$analytics.track('Visit: My Account Info');
  },

  destroyed() {
  },


  // Methods ********************************
  methods: {
    onButtonOk() {
      this.requestUpdateInfo();
    },

    stepDataChanged(complete) {
      this.formComplete = complete;
    },

    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    alertConfirm(title, message, buttonOk, buttonCancel, callback, cancelCallback) {
      this.alertConfirmTitle = title;
      this.alertConfirmMessage = message;
      this.alertConfirmCancel = buttonCancel;
      this.alertConfirmOk = buttonOk;
      this.alertConfirmShowing = true;
      this.alertCallback = callback;
      this.alertCancelCallback = cancelCallback;
    },
    onAlertConfirmOk() {
      this.alertConfirmShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
    onAlertConfirmDismissed() {
      this.alertConfirmShowing = false;
      this.alertCallback = null;
      const callback = this.alertCancelCallback;
      if (callback) {
        this.alertCancelCallback = null;
        callback();
      }
    },

    requestUpdateInfo() {
      const self = this;
      this.isLoading = true;
      this.$graphql.updateCustomerBasicInfo(this.customer, function () {
        self.updateCustomer();
      }, function () {
        self.isLoading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Account Update Info');
      });
    },

    updateCustomer() {
      const self = this;
      this.$graphql.getCurrentCustomer(false, function (responseCustomer) {
        self.customer.name = responseCustomer.name;
        self.customer.email = responseCustomer.email;
        self.customer.taxNumber = responseCustomer.taxNumber;

        const auth = self.$store.getters.getAuth;
        auth.updateCurrentClient(responseCustomer);
        self.$store.dispatch('updateAuth', auth);

        self.alert(self.$t('popover.save_success'), null, function () {
          self.showConfirmationOnBack = false;
          self.$router.go(-1);
        });
      }, function () {
        self.isLoading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Account Update Info (request current)');
      });
    },
  },
};
</script>


<style lang="scss" scoped>

.account-details {
  //height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  min-height: 360px;

  @media screen and (min-width: $min-screen-width) {
    //min-height: 100vh;
  }
  @media screen and (max-width: $min-screen-width - 1) {
    min-height: 100vh;
  }

  &__content {
    flex: 0 0 auto;

    &__details_form {
      margin: 0 $padding-section;
    }
  }

}

</style>
