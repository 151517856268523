import qs from 'qs';
import ilmReferrer from '@iloveme/referrer';
import Login from './views/Login.vue';
import Account from './views/Account/Account';
import AccountDetails from './views/Account/AccountDetails';
import AccountCards from './views/Account/AccountCards';
import AccountAddress from './views/Account/AccountAddress';
import BusinessDetails from './views/BusinessDetails.vue';
import SearchService from './views/SearchService.vue';
import DiscountsList from './views/Discounts/DiscountsList.vue';
import NewBookingServices from './views/NewBookingServices.vue';
import NewBooking from './views/NewBooking.vue';
import NewBookingConfirmation from './views/NewBookingConfirmation.vue';
import VerifyPhone from './views/VerifyPhone.vue';
import Success from './views/Success.vue';
import MyBookings from './views/MyBookings.vue';
import MyBookingsItem from './views/MyBookingsItem.vue';
import MyBookingsItems from './views/MyBookingsItems.vue';
import PaymentDetails from './views/PaymentClientDetails';
import VoucherDetails from './views/VoucherDetails';
//import TermsConditions from './views/TermsConditions';
//import PrivacyPolicy from './views/PrivacyPolicy';
//import Home from './views/Home.vue';
import PageNotFound from './views/PageNotFound.vue';
import ErrorScreen from './views/Error.vue';

import store from './store';
import Constants from './utils/constants.js';
import IlmDomain from './libs/ilmDomain';

async function parseReferrer(url, referrer) {
  const description = await ilmReferrer.referrer.parse(url, referrer);
  return description;
}


export default {
  getRoutes(root, businessName) {
    return [
      {
        path: `${root}`,
        name: 'root',
        beforeEnter: (to, from, next) => {
          let shouldCheckReferrer = true;
          //let trackingId = null;

          let referrer = Constants.SOURCE_GENERAL_KEY;
          const queryRef = to.query.ref;
          if (queryRef) {
            if (queryRef === Constants.REF_WHATSAPP_KEY) {
              referrer = Constants.SOURCE_WHATSAPP_KEY;
            } else if (queryRef === Constants.REF_GOOGLE_KEY) {
              referrer = Constants.SOURCE_GOOGLE_KEY;
            } else if (queryRef === Constants.REF_SMS_KEY) {
              referrer = Constants.SOURCE_SMS_KEY;
            } else if (queryRef === Constants.REF_SMS_MARKETING_KEY) {
              referrer = Constants.SOURCE_SMS_MARKETING_KEY;
            } else if (queryRef === Constants.REF_QRCODE_KEY) {
              referrer = Constants.SOURCE_QRCODE_KEY;
            } else if (queryRef === Constants.REF_MARKETING_VALUE) {
              referrer = Constants.SOURCE_MARKETING_KEY;
              // check the parent iframe ´ilm_ref´
              if (window !== window.parent) {
                // The page is in an iframe
                const iframeReferrer = document.referrer;
                const indexOfQuery = iframeReferrer.lastIndexOf('?');
                if (indexOfQuery > -1) {
                  const queryParams = qs.parse(iframeReferrer.slice(indexOfQuery + 1));
                  const param = queryParams[Constants.REF_MARKETING_KEY];
                  if (param === Constants.REF_MARKETING_GOOGLE_VALUE) {
                    referrer = Constants.SOURCE_MARKETING_GOOGLE_KEY;
                  } else if (param === Constants.REF_MARKETING_INSTAGRAM_VALUE) {
                    referrer = Constants.SOURCE_MARKETING_INSTAGRAM_KEY;
                  } else if (param === Constants.REF_MARKETING_FACEBOOK_VALUE) {
                    referrer = Constants.SOURCE_MARKETING_FACEBOOK_KEY;
                  }

                  // const trackingParam = queryParams[Constants.REF_ANALYTICS_TRACKING_KEY];
                  // if (trackingParam) {
                  //   trackingId = trackingParam;
                  // }
                }
              }
              shouldCheckReferrer = false;
            }
          } else {
            const source = store.getters.getSourceDescription;
            if (source) {
              referrer = source;
            }
          }

          const docReferrer = document.referrer;
          if (docReferrer && shouldCheckReferrer) {
            const url = window.location.href;
            parseReferrer(url, docReferrer)
              .then(result => {
                //console.log(result);
                if (result.referrer.type === 'social'
                  && (result.referrer.network === 'facebook' || result.referrer.network === 'instagram')) {
                  referrer = `${Constants.SOURCE_GENERAL_KEY}.${result.referrer.network}`;
                } else if (result.referrer.type === 'search' && result.referrer.engine === 'google') {
                  referrer = `${Constants.SOURCE_GENERAL_KEY}.${result.referrer.engine}`;
                } else if (result.referrer.type === 'ad' && result.referrer.network === 'google') {
                  referrer = `${Constants.SOURCE_GENERAL_KEY}.${result.referrer.network}`;
                }
                next({
                  name: Constants.ROUTE_LOGIN,
                  replace: true,
                  params: {
                    source: referrer,
                    endpoint: to.query.endpoint,
                    permalink: to.params.permalink,
                  },
                });
              }, error => {
                console.log(error);
                next({
                  name: Constants.ROUTE_LOGIN,
                  replace: true,
                  params: {
                    source: referrer,
                    endpoint: to.query.endpoint,
                    permalink: to.params.permalink,
                  },
                });
              });
          } else {
            next({
              name: Constants.ROUTE_LOGIN,
              replace: true,
              params: {
                source: referrer,
                endpoint: to.query.endpoint,
                permalink: to.params.permalink,
                //trackingId,
              },
            });
          }
        },
        meta: {
          title: 'Aguarde...',
          showAccount: false,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
        //redirect: 'login/token',
      },
      {
        path: `${root}messenger_bot`,
        name: 'messenger_bot',
        beforeEnter: (to, from, next) => {
          next({
            name: Constants.ROUTE_LOGIN,
            replace: true,
            params: {
              source: Constants.SOURCE_MESSENGER_KEY,
              bunsinessId: to.query.business_id,
              endpoint: to.query.endpoint,
              pageId: to.query.page_id,
              comp: to.query.ref,
            },
          });
        },
        meta: {
          title: 'Aguarde...',
          showAccount: false,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
      },
      {
        path: `${root}login`,
        name: Constants.ROUTE_LOGIN,
        //props: route => route.query,
        props: true,
        component: Login,
        beforeEnter: (to, from, next) => {
          let perma;
          //let source = to.query.source;
          let source = to.params.source === 'facebook' ? Constants.SOURCE_MESSENGER_KEY : to.params.source;

          const url = `${window.location.host}${window.location.pathname}`;
          if (IlmDomain.isGeneric(url) && source !== Constants.SOURCE_MESSENGER_KEY) {
            perma = IlmDomain.getPermalink(url);
            if (!source) {
              source = Constants.SOURCE_GENERAL_KEY;
            }
          } else if (Constants.TEST_USE_MESSENGER_FLOW === false && IlmDomain.isTest(url)) {
            perma = IlmDomain.getTestPermalink(url);
            if (!source) {
              source = Constants.SOURCE_GENERAL_KEY;
            }
          }
          //to.query.permalink = perma;

          //to.query.source = source;
          to.query.permalink = perma;

          to.params.source = source;
          to.params.permalink = perma;

          if (source.indexOf(Constants.SOURCE_GENERAL_KEY) !== -1 && (!perma || perma === '')) {
            next({
              name: Constants.ROUTE_NOT_FOUND,
              replace: true,
            });
          } else {
            next();
          }
        },
        meta: {
          title: 'Aguarde...',
          showAccount: false,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      /*{
        path: `${root}home`,
        name: 'home',
        component: Home,
      },*/
      {
        path: `${root}info`,
        name: Constants.ROUTE_BUSINESS_DETAILS,
        component: BusinessDetails,
        props: true,
        meta: {
          index: 1,
          title: businessName,
          showAccount: true,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
      },
      {
        path: `${root}account`,
        name: Constants.ROUTE_ACCOUNT,
        component: Account,
        meta: {
          index: 2,
          title: businessName,
          showAccount: false,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
        children: [
          {
            path: 'me',
            name: Constants.ROUTE_ACCOUNT_DETAILS,
            component: AccountDetails,
            meta: {
              index: 3,
              title: businessName,
              showAccount: false,
              metaTags: [
                {
                  name: 'apple-mobile-web-app-title',
                  content: businessName,
                },
              ],
            },
          },
          {
            path: 'address',
            name: Constants.ROUTE_ACCOUNT_ADDRESS,
            component: AccountAddress,
            meta: {
              index: 4,
              title: businessName,
              showAccount: false,
              transitionName: 'slide',
              metaTags: [
                {
                  name: 'apple-mobile-web-app-title',
                  content: businessName,
                },
              ],
            },
          },
          {
            path: 'cards',
            name: Constants.ROUTE_ACCOUNT_CARDS,
            component: AccountCards,
            props: true,
            meta: {
              index: 14,
              title: businessName,
              showAccount: false,
              transitionName: 'slide',
              metaTags: [
                {
                  name: 'apple-mobile-web-app-title',
                  content: businessName,
                },
              ],
            },
          },
        ],
      },
      {
        path: `${root}search`,
        name: Constants.ROUTE_SEARCH_SERVICE,
        component: SearchService,
        props: true,
        meta: {
          index: 10,
          title: businessName,
          showAccount: true,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}discounts`,
        name: Constants.ROUTE_DISCOUNTS_LIST,
        component: DiscountsList,
        props: true,
        meta: {
          index: 11,
          title: businessName,
          showAccount: false,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}choose-services`,
        name: Constants.ROUTE_NEW_APPOINTMENT_SERVICES,
        component: NewBookingServices,
        props: true,
        meta: {
          index: 11,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}new`,
        name: Constants.ROUTE_NEW_APPOINTMENT,
        component: NewBooking,
        props: true,
        meta: {
          index: 12,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}confirm`,
        name: Constants.ROUTE_NEW_APPOINTMENT_CONFIRMATION,
        component: NewBookingConfirmation,
        props: true,
        meta: {
          index: 13,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}voucher`,
        name: Constants.ROUTE_VOUCHER,
        component: VoucherDetails,
        meta: {
          index: 12,
          title: businessName,
          showAccount: true,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}verify`,
        name: Constants.ROUTE_VERIFY_PHONE,
        component: VerifyPhone,
        props: true,
        meta: {
          index: 13,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}payment-details`,
        name: Constants.ROUTE_PAYMENT_DETAILS,
        component: PaymentDetails,
        props: true,
        meta: {
          index: 14,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}success`,
        name: Constants.ROUTE_SUCCESS,
        component: Success,
        props: true,
        meta: {
          index: 15,
          title: businessName,
          showAccount: false,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}bookings`,
        name: Constants.ROUTE_MY_BOOKINGS,
        component: MyBookings,
        props: true,
        meta: {
          index: 10,
          title: businessName,
          showAccount: true,
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}bookings/:id`,
        name: Constants.ROUTE_MY_BOOKINGS_ITEM,
        component: MyBookingsItem,
        props: true,
        meta: {
          index: 11,
          title: businessName,
          showAccount: true,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      {
        path: `${root}bookings/:day`,
        name: Constants.ROUTE_MY_BOOKINGS_ITEMS,
        component: MyBookingsItems,
        props: true,
        meta: {
          index: 11,
          title: businessName,
          showAccount: true,
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
          /*metaTags: [
            {
              name: 'viewport',
              content: viewportContent,
            },
          ],*/
        },
      },
      /*{
        path: `${root}terms-conditions`,
        name: Constants.ROUTE_TERMS_CONDITIONS,
        component: TermsConditions,
        props: true,
        meta: {
          index: 12,
          showAccount: false,
          title: 'Termos e condições',
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
      },
      {
        path: `${root}privacy-policy`,
        name: Constants.ROUTE_PRIVACY_POLICY,
        component: PrivacyPolicy,
        meta: {
          index: 11,
          showAccount: false,
          title: 'Política de privacidade',
          transitionName: 'slide',
          metaTags: [
            {
              name: 'apple-mobile-web-app-title',
              content: businessName,
            },
          ],
        },
      },*/
      {
        path: `${root}error`,
        name: Constants.ROUTE_ERROR,
        component: ErrorScreen,
        props: true,
        meta: {
          title: 'Erro',
          showAccount: false,
        },
      },
      {
        path: '*',
        name: Constants.ROUTE_NOT_FOUND,
        component: PageNotFound,
        props: true,
        meta: {
          title: 'Página não encontrada',
          showAccount: false,
        },
      },
      /*{
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(//webpackChunkName: "about"
         './views/About.vue'),
  },*/
    ];
  },
};
