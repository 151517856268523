<template>
  <div class="payment-section">
    <h1
      v-if="showTitle"
      class="payment-section__title">
      {{ $t('headers.payment_details_title_3') }}
    </h1>
    <div
      v-if="showTitle"
      class="payment-section__text">
      {{ $t('text.payment_details_text_3') }}
    </div>
    <div class="payment-section__fields">
      <div class="payment-section__fields__divide">
        <PaymentCardInput
          ref="input_card"
          :placeholder="$t('placeholders.payment_card_number')"
          :on-change="onCardNumberChange"
          :value="cardNumber"
          :focus-on-start="false"
          :disabled="isLoading"
          type-card
          class="payment-section__fields__divide__input" />
          <!--PaymentCardInput
          ref="input_date"
          :placeholder="$t('placeholders.payment_card_expire')"
          :on-change="onExpireChange"
          :focus-on-start="false"
          :value="expire"
          :on-helper-clicked="onExpireHelperClick"
          type-date
          show-helper
          class="payment-section__fields__divide__input
          payment-section__fields__divide__right
          payment-section__fields__divide__date" /-->
      </div>

      <div class="payment-section__fields__divide">
        <PaymentCardInput
          ref="input_date"
          :placeholder="$t('placeholders.payment_card_expire')"
          :on-change="onExpireChange"
          :focus-on-start="false"
          :value="expire"
          :on-helper-clicked="onExpireHelperClick"
          :disabled="isLoading"
          show-helper
          type-date
          class="payment-section__fields__divide__input
          payment-section__fields__divide__date
          payment-section__fields__divide__left" />
        <PaymentCardInput
          ref="input_cvv"
          :placeholder="$t('placeholders.payment_card_cvv')"
          :on-change="onCvvChange"
          :focus-on-start="false"
          :on-helper-clicked="onCvvHelperClick"
          :disabled="isLoading"
          show-helper
          type-cvv
          class="payment-section__fields__divide__input
          payment-section__fields__divide__cvv
          payment-section__fields__divide__right" />
      </div>

      <div class="payment-section__fields__divide">
        <SectionInput
          ref="input_name"
          :placeholder="$t('placeholders.payment_card_name')"
          :on-change="onNameChange"
          :focus-on-start="false"
          :value="name"
          :show-clear="false"
          :validate-field="validateNameField"
          :disabled="isLoading"
          :caps-first-letter="true"
          class="payment-section__fields__divide__input" />
          <!--PaymentCardInput
          ref="input_cvv"
          :placeholder="$t('placeholders.payment_card_cvv')"
          :on-change="onCvvChange"
          :focus-on-start="false"
          :on-helper-clicked="onCvvHelperClick"
          type-cvv
          show-helper
          class="payment-section__fields__divide__input
          payment-section__fields__divide__right
          payment-section__fields__divide__cvv" /-->
      </div>
    </div>

    <!--div class="payment-section__footer">
      <p
        class="payment-section__footer__text"
        v-html="$t('notes.payment_methods')" />
    </div-->

    <div
      v-if="!showTitle"
      class="payment-section__options">
      <!--IlmButtonSelect
        :on-click="handleDropdownClick"
        label="2 x R$ 49,50"
        class="payment-section__options__select
        payment-section__options__left" /-->
      <IlmButton
        ref="btn_ok"
        :on-click="handleOkClick"
        :disabled="!formComplete"
        :label="$t('button.payment_create_choose_card')"
        theme="success"
        class="payment-section__options__ok" />
    </div>

    <!--div
      class="payment-section__footer"
      @click="footerNoteClick">
      <p
        class="payment-section__footer__text"
        v-html="$t('notes.payment_confirm')" />
    </div-->
  </div>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import SectionInput from '@/components/Inputs/SectionInput.vue';
import PaymentCardInput from '@/components/Inputs/PaymentCardInput.vue';

import cardUtils from '@/utils/payment/utils';

export default {
  name: 'PaymentSection3',

  components: {
    IlmButton,
    SectionInput,
    PaymentCardInput,
  },

  // Properties ********************************
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customer: Object,
    onDataChanged: Function,
    onDateHelperClicked: Function,
    onCvvHelperClicked: Function,
    onSaveClicked: Function,
  },

  // Data ********************************
  data() {
    return {
      cardNumber: null,
      expire: null,
      name: null,
      cvv: null,
      formComplete: false,
    };
  },

  // Computed ********************************
  computed: {
    /*
    isFormComplete() {
      if (!this.cardNumber || this.cardNumber.length === 0) return false;
      if (!this.expire || this.expire.length === 0) return false;
      if (!this.name || this.name.length === 0) return false;
      if (!this.cvv || this.cvv.length === 0) return false;

      const inputCard = this.$refs.input_card;
      const inputDate = this.$refs.input_date;
      const inputCvv = this.$refs.input_cvv;

      if (!inputCard || inputCard.isValid() === false) {
        console.log(' card not valid');
        return false;
      }
      if (!inputDate || inputDate.isValid() === false) {
        console.log(' date not valid');
        return false;
      }
      if (!inputCvv || inputCvv.isValid() === false) {
        console.log(' cvv not valid');
        return false;
      }

      return true;
    },*/
  },

  // Mounted ********************************
  mounted() {
    this.cardNumber = this.customer.card.number;
    this.name = this.customer.card.holderName;
    this.expire = this.customer.card.expirationDate;
  },

  // Methods ********************************
  methods: {
    handleCountryClick() {
      this.modalCountriesShowing = true;
    },
    onModalClosed() {
      this.modalCountriesShowing = false;
    },
    onCountryCodeSelected(iso) {
      this.onModalClosed();

      this.selectedCountry = this.countries.filter(function (item) {
        return item.iso === iso;
      })[0];
    },

    checkDataChanged() {
      this.formComplete = true;

      if (!this.cardNumber || this.cardNumber.length === 0) {
        this.formComplete = false;
      }
      if (!this.expire || this.expire.length === 0) {
        this.formComplete = false;
      }
      if (!this.name || this.name.trim().length < 2) {
        this.formComplete = false;
      }
      if (!this.cvv || this.cvv.length === 0) {
        this.formComplete = false;
      }

      if (this.formComplete) {
        const inputCard = this.$refs.input_card;
        const inputDate = this.$refs.input_date;
        const inputCvv = this.$refs.input_cvv;

        if (!inputCard || inputCard.isValid() === false) {
          this.formComplete = false;
        }
        if (!inputDate || inputDate.isValid() === false) {
          this.formComplete = false;
        }
        if (!inputCvv || inputCvv.isValid() === false) {
          this.formComplete = false;
        }
      }

      const card = cardUtils.cardFromNumber(this.cardNumber);
      if (card) {
        // eslint-disable-next-line vue/no-mutating-props
        this.customer.card.brand = card.type;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.customer.card.number = this.cardNumber;
      // eslint-disable-next-line vue/no-mutating-props
      this.customer.card.holderName = this.name;
      // eslint-disable-next-line vue/no-mutating-props
      this.customer.card.expirationDate = this.expire;
      // eslint-disable-next-line vue/no-mutating-props
      this.customer.card.cvv = this.cvv;


      if (this.onDataChanged) {
        this.onDataChanged(this.formComplete);
      }
    },

    onExpireHelperClick() {
      if (this.onDateHelperClicked) {
        this.onDateHelperClicked();
      }
    },
    onCvvHelperClick() {
      if (this.onCvvHelperClicked) {
        this.onCvvHelperClicked();
      }
    },

    onCardNumberChange(text, valid) {
      this.cardNumber = text;
      this.checkDataChanged();

      if (valid) {
        const inputDate = this.$refs.input_date;
        if (inputDate) {
          inputDate.setFocus();
        }
      }
    },
    onExpireChange(text, valid) {
      this.expire = text;
      this.checkDataChanged();

      if (valid) {
        const inputCvv = this.$refs.input_cvv;
        if (inputCvv) {
          inputCvv.setFocus();
        }
      }
    },
    onNameChange(text) {
      this.name = text;
      this.checkDataChanged();
    },
    onCvvChange(text, valid) {
      this.cvv = text;
      this.checkDataChanged();

      if (valid && this.customer.card.brand
        && ['mastercard', 'visa'].includes(this.customer.card.brand)) {
        const inputName = this.$refs.input_name;
        if (inputName) {
          inputName.setFocus();
        }
      }
    },
    validateNameField() {
      if (this.name && this.name.length > 1) {
        return true;
      }
      return false;
    },

    footerNoteClick() {

    },

    handleDropdownClick() {

    },

    handleOkClick() {
      if (this.onSaveClicked) {
        this.onSaveClicked();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-section {
  padding: $padding-section 0;

  &__text {
    font-size: 14px;
    color: black;
    text-align: center;
    padding: 0 $padding-section;
    max-width: 360px;
    margin: 0 auto;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 8px $padding-section;
    margin: 0;
  }

  &__fields {
    margin-top: $padding-section;

    &__divide {
      margin-top: 12px;
      display: flex;
      justify-content: space-evenly;

      &__select {
        flex: 1 1 auto;
      }
      &__input {
        flex: 1 1 auto;
      }
      &__left {
        margin-right: 6px;
      }
      &__right {
        margin-left: 6px;
      }
      &__date {
        flex: 1 1 auto;
        //width: 114px;
      }
      &__cvv {
        flex: 1 1 auto;
        //width: 98px;
      }
    }

    &__input {
      margin-top: 12px;
    }
  }

  &__options {
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $theme-list-divider-color;

    &__select {
      flex: 1 1 auto;
      box-sizing: border-box;
      width: 50%;
    }
    &__ok {
      flex: 1 1 auto;
      box-sizing: border-box;
      //width: 50%;
      //text-align: left;
      //padding-left: 10px;
    }
    &__left {
      margin-right: 6px;
    }
    &__right {
      margin-left: 6px;
    }
  }

  &__footer {
    margin: 20px 0;
    padding-top: 16px;
    border-top: 1px solid $theme-list-divider-color;

    &__text {
      font-size: 12px;
      line-height: 1.2;
      color: $theme-2-color;
      margin-top: 0;

      & ::v-deep span {
        font-weight: bold;
        color: $theme-focus-color;
      }
    }
  }
}
</style>
