<template>
  <div class="card-details">
    <CardItem
      :card="customer.card"
      class="card-details__card" />

    <div
      class="card-details__footer">
      <div
        :class="btnPreferedClasses"
        class="card-details__footer__prefered"
        @click="preferedClicked">
        <IlmIcon
          :icon="preferedButtonIcon"
          size="lg"
          class="card-details__footer__prefered__icon" />
        <span
          class="card-details__footer__prefered__btn">
          {{ preferedButtonText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import CardItem from '@/components/Payments/CardItem.vue';

export default {
  name: 'AccountCardDetails',

  components: {
    IlmIcon,
    CardItem,
  },

  // Properties ********************************
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    customer: Object,
    showAlert: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },

  // Computed ********************************
  computed: {
    preferedButtonIcon() {
      if (this.customer.card.isPrefered) {
        return 'star';
      }
      return 'star_border';
    },
    preferedButtonText() {
      if (this.customer.card.isPrefered) {
        return this.$t('account.edit_card_is_prefered');
      }
      return this.$t('account.edit_card_set_prefered');
    },
    btnPreferedClasses() {
      return {
        'card-details__footer__prefered--is-prefered': this.customer.card.isPrefered,
      };
    },
  },

  // Mounted ********************************
  mounted() {
  },

  // Methods ********************************
  methods: {
    preferedClicked() {
      if (!this.customer.card.isPrefered) {
        const uid = this.customer.card.uid;
        this.setPreferedCard(uid);
      }
    },

    setPreferedCard(uid) {
      const self = this;
      this.$root.loading = true;
      this.$graphql.setPreferedCard(uid, function () {
        self.$root.loading = false;
        self.$router.go(-1);
      }, function () {
        self.$root.loading = false;
        if (self.showAlert) {
          self.showAlert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.card-details {
  padding: $padding-section 0;

  &__card {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  }

  &__footer {
    margin: 30px 0;
    padding-top: 20px;
    border-top: 1px solid $theme-list-divider-color;
    display: flex;
    align-items: center;

    &__prefered {
      display: flex;
      align-items: center;
      color: $theme-info;
      cursor: pointer;

      @media (hover) {
        &:hover {
          opacity: 0.5;
        }
      }

      &--is-prefered {
        color: black;
        cursor: default;
        opacity: 1 !important;
      }

      &__icon {
        flex: 0 0 auto;
        line-height: 1;
      }
      &__btn {
        flex: 1 1 auto;
        padding-left: 10px;
        line-height: 1;
        font-size: 14px;
      }
    }
  }
}
</style>
