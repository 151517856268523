import AvailabilityDays from './availabilityDays';

class AvailabilityServiceList {
  constructor(data) {
    this.serviceAvailabilities = {};

    if (data) {
      //data.forEach(element => this.search.push(new Search(element)));
      const self = this;
      data.forEach(function (element) {
        self.serviceAvailabilities[element.serviceId] = {
          professionalId: element.professionalId,
          availabilities: new AvailabilityDays(element.availabilities),
        };
      });
    }
  }

  getServiceAvailabilities(serviceId) {
    return this.serviceAvailabilities[serviceId];
  }
}

export default AvailabilityServiceList;
