<template>
  <div class="new-booking">
    <Sticky
      ref="sticky"
      :show-shadow="showNavShadow">
      <div
        ref="navbar"
        class="new-booking__fixed_top">
        <Navbar
          :title="navbarSubtitle"
          :show-shadow="false"
          show-back
          show-back-bevel />
      </div>
    </Sticky>

    <div
      v-if="dateSelected"
      ref="date_container"
      :style="{ background: 'white' }">
      <div class="new-booking__fixed_top__date_header">
        <!--h3
          v-if="scheduleSelected"
          class="new-booking__fixed_top__date_header__text">{{ $t('section_list.date_title') }}</h3>
        <SectionHeader
          v-else
          :label="$t('section_list.appointment_choose_date')"/-->
        <!--LoadingSpinner
          v-if="isLoadingSchedules"
          class="new-booking__fixed_top__date_header__loader"/-->
      </div>
      <div class="new-booking__fixed_top__date_container">
        <IlmButtonIcon
          ref="calendar_prev"
          :disabled="prevDisabled"
          :on-click="onCalendarPrev"
          :class="calendarNavArrowClasses"
          size="xxl"
          icon="keyboard_arrow_left"
          class="new-booking__fixed_top__date_container__icon
            new-booking__fixed_top__date_container__icon--prev" />

        <span class="new-booking__fixed_top__date_container__text">
          {{ dateString }}
        </span>

        <IlmButtonIcon
          ref="calendar_next"
          :disabled="nextDisabled"
          :on-click="onCalendarNext"
          :class="calendarNavArrowClasses"
          size="xxl"
          icon="keyboard_arrow_right"
          class="new-booking__fixed_top__date_container__icon
            new-booking__fixed_top__date_container__icon--next" />
      </div>
    </div>

    <div
      :class="contentClassNames"
      :style="contentStyle"
      class="new-booking__content">
      <div class="new-booking__content__calendar_container">
        <IlmCalendar
          ref="calendar"
          :key="calendarKey"
          :unavailable-week-days="businessUnavailableWeekDays"
          :is-loading="isLoadingSchedules || isLoading"
          :selected="dateSelected"
          :on-date-selected="onDateSelected"
          :on-page-change="onCalendarSlide"
          :day-discounts="dayDiscounts"
          class="new-booking__content__calendar_container__ilmcalendar" />
      </div>

      <div v-if="showForm">
        <div>
          <MultipleServiceGrid
            ref="multipleServiceGrid"
            :business="business"
            :appointments="appointments"
            :edit-appointment="editAppointment"
            :date-selected="dateSelected"
            :navbar-height="navbarHeight"
            :on-date-change-clicked="onDateChangeClicked"
            :on-professional-clicked="onProfessionalClicked"
            :on-header-stick-update="onGridHeaderStickUpdate"
            :on-loader-changed="onMultipleGridLoaderChanged"
            :on-slot-selected-changed="onSelectedSlotsChaged"
            :on-scroll-to-section="onScrollToSection"
            :on-slots-changed="onSlotsChange"
            :on-alert="alert" />
        </div>
      </div>
    </div>

    <!--transition name="fade-fast"-->
    <div
      v-if="!businessUnavailable"
      ref="footer"
      :class="footerClass"
      class="new-booking__footer new-booking__footer--shadow">
      <IlmButton
        v-if="showConfirmButton"
        :on-click="clickNext"
        :is-working="isLoading"
        icon="arrow_forward"
        t-key="button.booking_choose_slots_ok"
        prevent-default
        theme="block success"
        class="new-booking__footer__confirm_button" />

      <GridSectionOptions
        v-else
        ref="gridSectionOptions"
        :business="business"
        :services-ids="selectedServicesIds"
        :selected-date="dateSelected"
        :on-change="onSectionTabChange" />
    </div>
    <!--/transition-->

    <ModalProfessional
      v-if="modalProfessionalShowing"
      ref="professional_modal"
      :selected-profissional="appointments[professionalClickedIndex].professional"
      :selected-service="appointments[professionalClickedIndex].service"
      :selected-date="dateSelected"
      :time-slot="scheduleSelected"
      :professional-list="professionals"
      @selected="onProfessionalSelected"
      @closed="onModalClosed" />

    <Alert
      v-if="simpleAlertShowing && dataInitialized"
      :title="simpleAlertTitle"
      :message="simpleAlertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onSimpleAlertClosed"
      @ok="onSimpleAlertClosed" />
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import ModalProfessional from '@/components/Modals/ModalProfessional.vue';
import IlmCalendar from '@/components/Calendar/IlmCalendar.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import DateUtils from '@/utils/dateUtils';
import Utils from '@/utils/utils';
/*import Appointment from '@/models/appointment';
import Error from '@/graphql/error';
import jwt from '@/utils/jwt';*/
import DiscountList from '@/models/discountList';
import MultipleServiceGrid from '@/components/Slots/MultipleServiceGrid.vue';
import GridSectionOptions from '@/components/Slots/GridSectionOptions.vue';
//import graphql from '../graphql/queries.js';

//const MAX_SCROLL_Y_SHOW_CAL_NAV = 36;

export default {
  name: 'NewBooking',

  // Components ********************************
  components: {
    Navbar,
    ModalProfessional,
    IlmCalendar,
    IlmButton,
    IlmButtonIcon,
    Alert,
    MultipleServiceGrid,
    GridSectionOptions,
  },

  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      if (this.modalProfessionalShowing) {
        next(false);
        this.$refs.professional_modal.close();
        //this.modalProfessionalShowing = false;
        return;
      }
      /*if (this.scheduleSelected) {
        if (this.isEditMode && this.editAppointmentChanged() === false) {
          next();
          return;
        }
        next(false);
        this.$store.dispatch('resetAppointment');
        this.allowEditAppointmentChange = true;
        this.scheduleSelected = null;
        this.getAvailableHours();
        return;
      }*/
      if (this.isLoading) {
        next(false);
        return;
      }

      this.$store.getters.getAppointments.getAppointments().forEach(a => {
        a.time = null;
      });
    }
    next();
  },

  // Props ********************************
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },

  // Data ********************************
  data() {
    return {
      isDestroyed: false,
      dataInitialized: false,
      business: null,
      businessUnavailableWeekDays: null,
      businessUnavailable: false,
      calendarKey: 1,
      dateSelected: null,
      selectedProfessionalId: null, // from 'book again'
      professionals: [],
      professional: null,
      scheduleEndTime: null,
      selectedSlotGroup: null,
      discounts: null,
      dayDiscounts: null,
      selectedDayDiscount: null,
      scheduleSelected: null,
      modalProfessionalShowing: false,

      prevDisabled: true,
      nextDisabled: false,

      editAppointment: null,
      allowEditAppointmentChange: false,

      navbarHeight: 0,
      scrollY: 0,
      isLoadingSchedules: false,
      isLoading: false,
      windowWidth: null,

      simpleAlertShowing: false,
      simpleAlertTitle: '',
      simpleAlertMessage: '',
      simpleAlertCallback: null,

      appointments: null,
      professionalClickedIndex: null,
      showNavShadow: true,
    };
  },

  // Computed ********************************
  computed: {
    navbarSubtitle() {
      const dateContainer = this.$refs.date_container;
      if (dateContainer) {
        const elementOffetTop = this.getOffsetTop(dateContainer);
        if (this.scrollY > elementOffetTop) {
          return this.dateString;
        }
      }
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return this.editAppointment !== null
          ? this.$t('title.edit_appointment')
          : this.$tc('title.new_appointment', this.appointments ? this.appointments.length : 1);
      }
      return this.business ? this.business.name : '';
    },
    contentClassNames() {
      //return { 'new-booking__content--bottom_space': this.scheduleSelected !== null };
      //return { 'new-booking__content--bottom_space': this.$root.isDesktop === false };
      return {};
    },
    contentStyle() {
      let paddingBottom = 10;
      if (this.$root.isDesktop === false) {
        if (this.showConfirmButton) {
          paddingBottom = 110;
        } else {
          paddingBottom = 110;
        }
      }
      return { 'padding-bottom': `${paddingBottom}px` };
    },
    showForm() {
      return (
        this.$root.loading === false
        //&& this.dataInitialized === true
        && this.businessUnavailableWeekDays !== null
      );
    },
    footerClass() {
      return {
        'new-booking__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
        'new-booking__footer--raised': Utils.isIos(),
      };
    },
    footerStyles() {
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return {
          position: 'sticky',
        };
      }
      return {
        position: 'fixed',
      };
    },
    professionalUnavailable() {
      return false;
      /*
      const weekday = this.$moment(this.dateSelected).isoWeekday();
      return this.professional !== null
        && (this.professional.getUnavailableWeekdays().includes(weekday)
            || this.schedules.length === 0);
      */
    },
    selectedServicesIds() {
      if (this.appointments) {
        return this.appointments.map(x => x.service.id);
      }
      return [];
    },
    currentMonthString() {
      return this.$refs.calendar.monthName();
    },
    dateString() {
      if (this.dateSelected) {
        const currDate = this.dateSelected.format('D MMMM');
        const isToday = DateUtils.isTodayDate(this.dateSelected);
        const isTomorrow = DateUtils.isTomorrowDate(this.dateSelected);

        if (isToday === true || isTomorrow === true) {
          return this.$t(isToday ? 'dates.today_format' : 'dates.tomorrow_format', {
            date: currDate,
          });
        }

        return `${this.dateSelected.format('ddd')} ${currDate}`;
      }
      return '';
    },
    calendarNavArrowClasses() {
      return {
        //'new-booking__fixed_top__date_container__icon--hidden':
        //this.scrollY > MAX_SCROLL_Y_SHOW_CAL_NAV,
      };
    },
    /*prevDisabled() {
      return !this.$refs.calendar.canSlideBack();
    },
    nextDisabled() {
      return !this.$refs.calendar.canSlideForward();
    },*/
    confirmButtonDateText() {
      if (this.isEditMode) {
        return null;
      }
      if (this.windowWidth < 340) {
        return `${this.dateSelected.format('D MMM')}, ${this.scheduleSelected.time_str}`;
      }
      return `${this.dateSelected.format('ddd D MMM')}, ${this.scheduleSelected.time_str}`;
    },
    isConfirmButtonDisabled() {
      return this.isLoadingSchedules === true;
    },
    showConfirmButton() {
      return this.scheduleSelected && this.isEditMode === false;
    },
    confirmButtonLabel() {
      return '';
      //return this.isEditMode ? this.$t('button.confirm_change') : this.$t('button.confirm');
    },
  },

  // Watch ********************************
  watch: {},

  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    const appointmentsList = this.$store.getters.getAppointments;
    if (!appointmentsList) {
      this.$router.go(-1);
      return;
    }
    this.appointments = appointmentsList.getAppointments();
    this.discounts = this.$store.getters.getDiscounts;
    this.business = auth.business;
    this.businessUnavailableWeekDays = this.business.getUnavailableWeekdays();
    this.businessUnavailable = this.isBusinessUnavailable();
    this.editAppointment = this.$store.getters.getEditAppointment;

    this.$nextTick(() => {
      const navbar = this.$refs.navbar;
      if (navbar) {
        this.navbarHeight = navbar.offsetHeight;
      }
    });

    this.windowWidth = document.body.clientWidth;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);

    if (this.editAppointment && this.editAppointment.discount) {
      if (!this.discounts) {
        this.discounts = new DiscountList();
      }
      this.discounts.addDiscount(this.editAppointment.discount);
    }

    if (this.discounts) {
      const serviceIds = this.appointments.map(x => `${x.service.id}`);
      this.dayDiscounts = this.discounts.getServiceDateDiscountsMax(serviceIds);
    }

    if (this.editAppointment) {
      this.$analytics.track('Visit: Edit booking');

      if (!this.editAppointment) {
        this.$router.go(-1);
        return;
      }

      // set selected date
      this.dateSelected = this.$moment(this.editAppointment.day, 'YYYY-MM-DD');
      this.$refs.calendar.setSelectedDate(this.dateSelected);
      this.$refs.calendar.update();

      const weekday = this.dateSelected.isoWeekday();
      if (this.dayDiscounts) {
        this.selectedDayDiscount = this.dayDiscounts[weekday];
      }
    } else {
      this.$analytics.track('Visit: New booking');

      const prof = this.$store.getters.getProfessional;
      if (prof) {
        this.selectedProfessionalId = prof.id;
      }

      // set selected date
      //const d = this.$store.getters.getDate;
      //this.setDate(d);
      this.initCalendar();

      const weekday = this.dateSelected.isoWeekday();
      if (this.dayDiscounts) {
        this.selectedDayDiscount = this.dayDiscounts[weekday];
      }
    }

    this.scheduleSelected = null;
  },

  destroyed() {
    this.isDestroyed = true;
    this.schedules = [];
    this.businessUnavailableWeekDays = [];
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element && element.tagName.toLowerCase() !== 'html') {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return this.distance ? offsetTop - this.distance : offsetTop;
    },

    // SETTERS *****************

    setDate(d) {
      let dt;
      if (d) {
        dt = this.$moment(d);
      } else {
        const date = this.$moment(new Date());
        const weekday = date.isoWeekday();
        if (
          this.businessUnavailableWeekDays.includes(weekday)
          /*|| (this.scheduleEndTime && date.hour() > this.scheduleEndTime - 3)
          || (this.schedules !== null && this.schedules.length === 0)*/
        ) {
          date.add(1, 'd');
        }
        dt = date;
      }
      this.dateSelected = dt;
    },

    // GETTERS *****************

    editAppointmentChanged(time) {
      if (this.editAppointment) {
        if (
          this.dateSelected
          && this.dateSelected.format('YYYY-MM-DD') !== this.editAppointment.day
        ) {
          return true;
        }
        if (this.editAppointment.time !== time) {
          return true;
        }
        if (this.professional && this.professional.id !== this.editAppointment.professional.id) {
          return true;
        }
      }

      return false;
    },

    isBusinessUnavailable() {
      const gridElement = this.$refs.multipleServiceGrid;
      if (gridElement) {
        if (gridElement.isDayUnavailable() === true) {
          return true;
        }
      }

      const weekday = this.$moment(this.dateSelected).isoWeekday();
      return (
        this.businessUnavailableWeekDays !== null
        && this.businessUnavailableWeekDays.includes(weekday)
      );
    },

    // EVENTS *****************

    onSectionTabChange(tab, hour) {
      const gridElement = this.$refs.multipleServiceGrid;
      if (gridElement) {
        gridElement.scrollToHour(tab, hour);
      }
    },

    onScrollToSection(tab) {
      const ref = this.$refs.gridSectionOptions;
      if (ref) {
        ref.onPageScroll(tab);
      }
    },

    onSlotsChange() {
      this.businessUnavailable = this.isBusinessUnavailable();
      /*
      const date = this.$moment(new Date());
      const weekday = date.isoWeekday();
      if (this.businessUnavailableWeekDays.includes(weekday)
        || (this.scheduleEndTime && date.hour() > this.scheduleEndTime - 3)
        || (this.schedules !== null && this.schedules.length === 0)) {
        date.add(1, 'd');
      }
      dt = date;*/
    },

    onSelectedSlotsChaged(selectedSlots) {
      let choosenComplete = true;
      this.appointments.forEach(a => {
        const serviceId = `${a.service.id}`;
        if (!selectedSlots[serviceId]) {
          choosenComplete = false;
        }
      });
      if (choosenComplete === false) {
        this.scheduleSelected = null;
      } else {
        this.scheduleSelected = selectedSlots;
        if (this.appointments.length === 1) {
          const serviceId = `${this.appointments[0].service.id}`;
          if (!this.editAppointment || this.editAppointmentChanged(selectedSlots[serviceId].time)) {
            this.clickNext();
          }
        }
      }
    },

    onGridHeaderStickUpdate(isSticked) {
      this.showNavShadow = !isSticked;
    },

    onMultipleGridLoaderChanged(isLoading) {
      this.isLoadingSchedules = isLoading;
    },

    onDateSelected(d, discount) {
      /*if (this.isLoading) {
        return;
      }*/
      //this.dateSelected = d;
      this.selectedDayDiscount = null;

      if (this.dataInitialized === false) {
        this.dataInitialized = true;
      } else {
        //this.editAppointmentChanged = true;
        const now = this.$moment(new Date());
        if (d.dayOfYear() < now.dayOfYear() && d.year() <= now.year()) {
          this.calendarKey++;
          now.add(1, 'd');
          this.$refs.calendar.setSelectedDate(now);
          this.dateSelected = now;
          //this.$refs.calendar.$forceUpdate();
        } else {
          //this.isLoadingSchedules = true;
          this.selectedDayDiscount = discount;
          this.dateSelected = d;
          //this.getAvailableHours();
        }
      }
    },
    onDateChangeClicked(date) {
      this.setDate(date);
      this.$refs.calendar.setSelectedDate(this.dateSelected);
      this.$refs.calendar.update();
    },
    onProfessionalClicked(index, professionalList) {
      if (this.isLoading) {
        return;
      }
      this.professionalClickedIndex = index;
      this.professionals = professionalList;
      this.modalProfessionalShowing = true;
    },
    onProfessionalSelected(p) {
      if (!p || p.id === -1) {
        this.professional = null;
        this.appointments[this.professionalClickedIndex].professional = null;
        this.$analytics.track('Action: Choose Professional', {
          preference: false,
        });
      } else {
        this.professional = p;
        this.appointments[this.professionalClickedIndex].professional = p;
        this.$analytics.track('Action: Choose Professional', {
          preference: true,
        });
      }

      //this.editAppointmentChanged = true;
      this.allowEditAppointmentChange = true;

      this.onModalClosed();
      //this.getAvailableHours();
      const gridElement = this.$refs.multipleServiceGrid;
      if (gridElement) {
        gridElement.refresh();
      }
    },
    onModalClosed() {
      this.modalProfessionalShowing = false;
      window.canBackStack = true;
    },
    onSimpleAlertClosed() {
      this.simpleAlertShowing = false;
      const callback = this.simpleAlertCallback;
      if (callback) {
        this.simpleAlertCallback = null;
        callback();
      }
    },
    clickNext() {
      if (this.isLoading || this.isLoadingSchedules) {
        return;
      }
      const gridElement = this.$refs.multipleServiceGrid;
      if (gridElement) {
        const appointments = gridElement.getAppointments();

        if (appointments.length === 1) {
          this.$analytics.track('Action: Confirm Slot');
        } else {
          this.$analytics.track('Action: Confirm Slots');
        }
        this.$store.getters.getAppointments.updateAppointments(appointments);

        this.$router.push(
          {
            name: this.$constants.ROUTE_NEW_APPOINTMENT_CONFIRMATION,
          },
          () => {},
        );
      }
    },

    alert(title, message, callback) {
      this.simpleAlertTitle = title;
      this.simpleAlertMessage = message;
      this.simpleAlertCallback = callback;
      this.simpleAlertShowing = true;
    },

    initCalendar() {
      const d = this.$store.getters.getDate;
      this.setDate(d);
      this.$refs.calendar.setSelectedDate(this.dateSelected);
      this.$refs.calendar.update();
      //window.addEventListener('scroll', this.handleScroll);
    },

    // Calendar EVENTS

    onCalendarPrev() {
      //if (this.isLoading || this.isLoadingSchedules) return;
      this.$refs.calendar.prevSlide();
    },
    onCalendarNext() {
      //if (this.isLoading || this.isLoadingSchedules) return;
      this.$refs.calendar.nextSlide();
    },

    onCalendarSlide() {
      this.prevDisabled = !this.$refs.calendar.canSlideBack();
      this.nextDisabled = !this.$refs.calendar.canSlideForward();
    },

    // Scroll EVENT

    handleScroll() {
      this.scrollY = window.scrollY;
    },

    // Resize EVENT

    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-booking {
  //height: 100%;
  min-height: 100vh;
  background: $theme-3-bg;
  position: relative;

  @media screen and (min-width: $min-screen-width) {
    min-height: initial;
  }

  &__fixed_top {
    background: white;
    position: relative;

    &__service_container {
      background: $theme-3-bg;
      border-bottom: 1px solid $theme-list-divider-color;

      &__name {
        margin: 0;
        padding: 12px $padding-section;
        text-align: center;
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__date_header {
      position: relative;
      padding-top: $padding-section;
      /*margin-top: 10px;
      border-top: 1px solid $theme-list-divider-color;*/

      &__text {
        display: inline-flex;
        font-family: $heading-font-family;
        font-weight: bold;
        font-size: 12px;
        color: $theme-2-color;
        margin: 0;
        text-transform: uppercase;
        line-height: 1.5em;

        //padding: 10px $padding-section 8px $padding-section;
        padding: 7px 16px;

        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
      }

      &__loader {
        position: absolute;
        right: 16px;
        top: 8px;
      }
    }

    &__date_container {
      position: relative;
      background: white;
      color: black;
      text-align: center;
      padding: 0 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 780px;
      margin: 0 auto;

      &__text {
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
        //color: $theme-ilm-color;
        font-family: $heading-font-family;
        font-weight: bold;
        font-size: 16px;
        text-transform: capitalize;
      }

      &__icon {
        display: inline-flex;
        flex: 0 0 auto;
        color: #333;

        &--hidden {
          opacity: 0.3;
          cursor: default;
        }

        /*
        &--prev {
        }

        &--next {
        }*/
      }

      &__icon:disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
  }

  &__content {
    z-index: 0;
    position: relative;
    padding-bottom: 10px;
    background: #f5f5f5;

    &--bottom_space {
      padding-bottom: 140px;
      /* CHANGE LATER: because of fixed footer height */
    }

    &__slot_columns {
      display: grid;
    }

    &__calendar_container {
      //padding-top: 5px;
      padding: 6px 6px 0 6px;
      user-select: none;
      background: white;
      margin-bottom: 15px;
      border-bottom: 1px solid $theme-list-divider-color;

      &__ilmcalendar {
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &__section {
      background: white;
      //margin: 15px 0;
      margin-bottom: 15px;
      border-top: 1px solid $theme-list-divider-color;
      border-bottom: 1px solid $theme-list-divider-color;
    }

    &__section--first {
      //border-top: none;
      margin-top: 15px;
    }

    /*&__section + &__section {
    }*/

    &__unavailable_day {
      text-align: center;
      background: white;
      border-bottom: 1px solid $theme-list-divider-color;

      &__btn {
        font-size: 13px;
        margin-top: 10px;
      }
    }

    &__unavailable_day p {
      font-family: $heading-font-family;
      font-size: 12px;
      color: black;
      padding: 20px $padding-section;
      margin: 0;
    }
  }

  &__footer {
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    padding: 0 $padding-section 10px $padding-section;
    position: fixed;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    &--raised {
      padding-bottom: 16px;
    }

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {
      max-width: 480px;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
</style>
