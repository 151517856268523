class BusinessSchedule {
  constructor(element) {
    // week indexes: 1 - 7 (starts on monday)
    // moment.isoWeekday()
    this.weekday = element.weekday;
    this.schedules = [];

    if (element.timeIntervals) {
      const obj = JSON.parse(element.timeIntervals);
      if (obj.length > 0) {
        const first = obj[0][0];
        const lastElement = obj[obj.length - 1];
        const last = lastElement[lastElement.length - 1];

        this.scheduleStart = parseInt(first, 10);
        this.scheduleEnd = parseInt(last, 10);
        if (last.split(':')[1] !== '00') {
          this.scheduleEnd++;
        }

        const self = this;
        obj.forEach(function (el) {
          self.schedules.push(el);
        });
      }
    }
  }

  hasSchedule() {
    return this.schedules.length > 0;
  }
}

export default BusinessSchedule;
