//Add strings
const en = {
  bar_title: '🍪 Esse site utiliza cookies',
  description: 'Armazenamos dados temporariamente para melhorar a sua experiência de navegação. Ao usar nossos serviços você concorda com tal utilização.',
  settings: 'Configurações',
  accept: 'Aceitar',
  statement: 'Política de privacidade',
  save: 'Salvar',
  always_on: 'Sempre ligado',
  cookie_essential_title: 'Cookies necessários',
  cookie_essential_desc: 'Os cookies necessários ajudam a tornar o site utilizável, permitindo funções básicas como navegação de página e acesso a áreas seguras do site. O site não pode funcionar corretamente sem esses cookies.',
  cookie_performance_title: 'Cookies de desempenho',
  cookie_performance_desc: 'Esses cookies são usados para melhorar o desempenho e a funcionalidade de nossos sites, mas não são essenciais para seu uso. Por exemplo, ele armazena seus dados de conta e dispositivo para nos ajudar a efetuar melhorias e correções.',
  cookie_analytics_title: 'Cookies de análise',
  cookie_analytics_desc: 'Usamos cookies de análise para nos ajudar a medir como os usuários interagem com o conteúdo do site, o que nos ajuda a personalizar nossos sites e aplicativos para você, a fim de aprimorar sua experiência.',
  cookie_marketing_title: 'Cookies de marketing',
  cookie_marketing_desc: 'Esses cookies são usados para tornar as mensagens publicitárias mais relevantes para você e seus interesses. A intenção é exibir anúncios que sejam relevantes e envolventes para o usuário individual e, portanto, mais valiosos para editores e anunciantes terceirizados.',
};

export default en;
