
<template>
  <div class="section-voucher">
    <div class="section-voucher__card">

      <div class="section-voucher__card__top">
        <span class="section-voucher__card__top__separator" />
        <h2
          class="section-voucher__card__top__text"
          v-html="$t('voucher.voucher_title')" />
      </div>

      <div class="section-voucher__card__content">
        <p class="section-voucher__card__content__text">
          {{ $t('voucher.voucher_text') }}
        </p>
      </div>

      <div class="section-voucher__card__option">
        <IlmButton
          :on-click="buttonClick"
          t-key="voucher.voucher_btn"
          prevent-default
          theme="info-inverted block"
          class="section-voucher__card__option__button" />
      </div>

    </div>
  </div>
</template>


<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';

export default {
  name: 'SectionVoucher',

  // Components ********************************
  components: {
    IlmButton,
  },

  // Properties ********************************
  props: {
    onSelected: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  methods: {
    buttonClick() {
      if (this.onSelected) {
        this.onSelected();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.section-voucher {
  background: white;
  padding: $padding-section;

  &__card {
    border-radius: 16px;
    background: rgb(218,66,255);
    background: $voucher-gradient;

    &__top {
      position: relative;
      padding: 28px 30px;
      //border-bottom: 2px dashed white;

      &__separator {
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='0' x2='100%25' y2='0' stroke='white' stroke-width='4' stroke-dasharray='0%2c15%2c0' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
      }

      &:before, &:after {
        content: ' ';
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: white;
        bottom: -18px;
      }
      &:before {
        left: -18px;
      }
      &:after {
        right: -18px;
      }

      &__text {
        color: white;
        font-weight: bold;
        line-height: 1.3;
        font-size: 26px;
        margin: 0;
      }
    }

    &__content {
      padding: 30px 30px 26px 30px;

      &__text {
        color: white;
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
      }
    }

    &__option {
      padding: 0 20px 20px 20px;

      &__button {
        background: white;
      }
    }
  }
}

</style>
