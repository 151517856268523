<template>
  <p class="footer">
    <a
      href="https://cdn.agende.online/terms.html"
      target="_blank"
      class="footer__link">Termos e condições</a> ∙
    <a
      href="https://ilove.me/privacy-policy.html"
      class="footer__link"
      target="_blank">Política de privacidade</a>
    <br>
    Powered by ilove.me.
    <br v-if="showLogout">
    <IlmButton
      v-if="showLogout"
      :on-click="handleLogoutClick"
      t-key="button.logout"
      icon="power_settings_new"
      icon-size="md"
      theme="simple"
      class="footer__logout_btn" />

    <AlertDestructive
      v-if="alertShowing"
      :title="$t('alerts.alert_logout_title')"
      :message="$t('alerts.alert_logout_message')"
      :btn-cancel="$t('alerts.alert_logout_no')"
      :btn-destructive="$t('alerts.alert_logout_yes')"
      @closed="onAlertDismissed"
      @cancel="onAlertDismissed"
      @ok="logout" />

  </p>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';

export default {
  components: {
    IlmButton,
    AlertDestructive,
  },

  data() {
    return {
      alertShowing: false,
      alertCallback: Function,
    };
  },

  computed: {
    showLogout() {
      if (this.$route.name !== this.$constants.ROUTE_BUSINESS_DETAILS) {
        return false;
      }
      const auth = this.$store.getters.getAuth;
      return auth
      && auth.isAuthenticated()
      && this.$store.getters.isExternalSource === true;
    },
  },

  mounted() {
  },

  methods: {
    handleLogoutClick() {
      this.alertShowing = true;
    },
    logout() {
      this.onAlertDismissed();
      this.$localStorage.setToken('');
      this.$analytics.reset();
      window.location.reload(false);
    },
    onAlertDismissed() {
      this.alertShowing = false;
    },

    /*termsConditionsClick(e) {
      e.preventDefault();
      this.$router.push({
        name: this.$constants.ROUTE_TERMS_CONDITIONS,
        params: { userShouldAccept: false },
      }, () => {});
    },
    privacyPolicyClick(e) {
      e.preventDefault();
      this.$router.push({
        name: this.$constants.ROUTE_PRIVACY_POLICY,
      }, () => {});
    },*/
  },
};
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  font-size: 12px;
  color: $theme-2-color;

  &__link {
    color: $theme-2-color;
  }

  &__logout_btn {
    font-weight: normal;
    font-size: 12px;
    color: $theme-destructive-contrast;
    padding-right: 32px;
    padding-left: 16px;
  }
  &__logout_btn:hover {
    color: $theme-danger;
  }
}
.footer_logo {
  width: 100px;
  height: auto;
}
</style>
