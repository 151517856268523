class ResponseCard {
  constructor(obj) {
    this.uid = obj.uid;
    this.holderName = obj.holderName;
    this.lastDigits = obj.lastDigits;
    this.expirationDate = obj.expirationDate;
    this.brand = obj.brand;
    this.isPrefered = obj.isPrefered;
    this.status = obj.status;
  }
}

export default ResponseCard;
