<template>
  <div
    v-if="startTime && endTime"
    :class="mainClass">
    <div
      v-if="showMorning"
      :class="tabClassNames(0)"
      @click="tabSelected(0)">
      <img
        v-show="!showIconSelected(0) && !showIconInactive(0)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sun.svg">
      <img
        v-show="showIconSelected(0)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sun_selected.svg">
      <img
        v-show="showIconInactive(0)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sun_inactive.svg">
      <div
        :class="`${mainClass}__options_section__option--interval`">
        {{ morningHours }}
      </div>
    </div>
    <div
      v-if="showAfternoon"
      :class="tabClassNames(1)"
      @click="tabSelected(1)">
      <img
        v-show="!showIconSelected(1) && !showIconInactive(1)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sunset.svg">
      <img
        v-show="showIconSelected(1)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sunset_selected.svg">
      <img
        v-show="showIconInactive(1)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/sunset_inactive.svg">
      <div
        :class="`${mainClass}__options_section__option--interval`">
        {{ afternoonHours }}
      </div>
    </div>
    <div
      v-if="showNight"
      :class="tabClassNames(2)"
      @click="tabSelected(2)">
      <img
        v-show="!showIconSelected(2) && !showIconInactive(2)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/moon.svg">
      <img
        v-show="showIconSelected(2)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/moon_selected.svg">
      <img
        v-show="showIconInactive(2)"
        :class="`${mainClass}__options_section__option__icon`"
        src="@/assets/images/booking/moon_inactive.svg">
      <div
        :class="`${mainClass}__options_section__option--interval`">
        {{ nightHours }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GridSectionOptions',

  // Components ********************************
  components: {
  },

  // Properties ********************************
  props: {
    business: Object,
    servicesIds: Array,
    selectedDate: Object,
    onChange: Function,
  },

  // Data ********************************
  data() {
    return {
      selectedTab: 0,
      currentHour: 0,
      isToday: false,
      morningTime: null,
      afternoonTime: null,

      startTime: null,
      endTime: null,

      discounts: null,
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'grid-section-options';
    },
    morningHours() {
      const start = this.startTime;
      const end = this.morningTime - 1;
      if (start === end) {
        return `${start}h`;
      }
      return `${start}h - ${end}h`;
    },
    afternoonHours() {
      const start = this.morningTime;
      const end = this.afternoonTime - 1;
      if (start === end) {
        return `${start}h`;
      }
      return `${start}h - ${end}h`;
    },
    nightHours() {
      const start = this.afternoonTime;
      const end = this.endTime - 1;
      if (start === end) {
        return `${start}h`;
      }
      return `${start}h - ${end}h`;
    },
    showMorning() {
      const start = this.startTime;
      const end = this.morningTime - 1;
      if (start > end) {
        return false;
      }
      return true;
    },
    showAfternoon() {
      if (this.morningTime > this.endTime - 1 || this.afternoonTime < this.startTime - 1) {
        return false;
      }
      return true;
    },
    showNight() {
      const start = this.afternoonTime;
      const end = this.endTime - 1;
      if (start > end) {
        return false;
      }
      return true;
    },
  },

  // Watchers ********************************
  watch: {
    selectedDate() {
      this.updateValues();
    },
  },

  // Created ********************************
  created() {
    this.morningTime = this.$constants.SCHEDULE_MORNING;
    this.afternoonTime = this.$constants.SCHEDULE_AFTERNOON;

    //this.discounts = this.$store.getters.getDiscounts;
  },

  // Mounted ********************************
  mounted() {
    this.updateValues();
  },

  destroyed() {
  },

  methods: {
    updateValues() {
      if (!this.selectedDate) {
        return;
      }
      const now = this.$moment(new Date());
      this.isToday = this.selectedDate.isSame(now, 'day');
      this.currentHour = now.hour();
      if (this.business) {
        const weekday = this.$moment(this.selectedDate).isoWeekday();
        const scheduleDay = this.business.getSchedule(weekday);

        if (scheduleDay) {
          this.startTime = scheduleDay.scheduleStart;
          this.endTime = scheduleDay.scheduleEnd;
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      }

      if (this.isToday === true) {
        if (this.currentHour >= this.$constants.SCHEDULE_AFTERNOON) {
          //this.setTab(2);
          this.selectedTab = 2;
        } else if (this.currentHour >= this.$constants.SCHEDULE_MORNING) {
          //this.setTab(1);
          this.selectedTab = 1;
        }
      } else {
        //this.setTab(0);
        this.selectedTab = 0;
      }
    },

    tabClassNames(tab) {
      const classNames = {};
      classNames[`${this.mainClass}__options_section__option`] = true;
      classNames[`${this.mainClass}__options_section__option--active`] = this.selectedTab === tab;
      classNames[`${this.mainClass}__options_section__option--inactive`] = this.isToday === true
        && ((this.currentHour >= this.$constants.SCHEDULE_AFTERNOON && (tab === 0 || tab === 1))
          || (this.currentHour >= this.$constants.SCHEDULE_MORNING && tab === 0));

      return classNames;
    },
    showIconInactive(tab) {
      return this.isToday === true
        && ((this.currentHour >= this.$constants.SCHEDULE_AFTERNOON && (tab === 0 || tab === 1))
          || (this.currentHour >= this.$constants.SCHEDULE_MORNING && tab === 0));
    },
    showIconSelected(tab) {
      return this.selectedTab === tab;
      //return false;
    },
    tabSelected(tab) {
      //console.log(`currentHour: ${this.currentHour} | tab: ${tab}`);
      if (this.isToday === false
        || ((this.currentHour >= this.$constants.SCHEDULE_AFTERNOON && (tab === 2))
        || (this.currentHour < this.$constants.SCHEDULE_AFTERNOON && tab > 0))
        || (this.currentHour < this.$constants.SCHEDULE_MORNING)) {
        this.setTab(tab);
      }
    },
    setTab(tab) {
      this.selectedTab = tab;

      if (this.onChange) {
        let hour;
        if (tab === 0) {
          hour = this.startTime;
        } else if (tab === 1) {
          hour = this.morningTime;
        } else if (tab === 2) {
          hour = this.afternoonTime;
        }
        this.onChange(tab, hour);
      }
    },

    onPageScroll(tab) {
      if (this.selectedTab !== tab && this.showIconInactive(tab) === false) {
        this.selectedTab = tab;
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.grid-section-options {
  position: relative;
  background: white;
  display: block;
  //padding: 10px;
  //border-top: 1px solid $theme-list-divider-color;
  //border-bottom: 1px solid $theme-list-divider-color;

  &__options_section {
    padding-top: 10px;
    padding-bottom: 14px;
    text-align: center;

    &__option {
      position: relative;
      //padding: 2px 2px;
      margin: 0 10px;
      //margin-bottom: 0;
      font-size: 18px;
      //font-weight: bold;
      color: black;
      cursor: pointer;
      touch-action: auto;
      //-webkit-tap-highlight-color: $list-pressed-row-background-alpha;
      display: inline-block;
      line-height: 1.1;
      user-select: none;

      &__break {
        font-size: 10px;
      }

      &--interval {
        font-size: 12px;
        font-weight: bold;
        border-radius: 12px;
        padding: 2px 2px;
        margin: 0 !important;
        min-width: 62px;
      }

      /*@media (hover) {
        &:hover &--interval {
          //color: $theme-ilm-selection-dimmed;
          background: $theme-highlight-color;
        }
      }*/

      &--active {
        color: black; //$theme-ilm-color;
        font-weight: bold;
        /*border-bottom: 4px solid $theme-ilm-color;*/
      }

      &--active ::v-deep &--interval {
        background: $theme-ilm-slot-background-selected !important;//$theme-ilm-selection-dimmed;
        color: white;
        font-weight: bold;
      }

      &--inactive {
        color: $theme-color-dim !important;
        cursor: not-allowed;
      }

      &--inactive ::v-deep &--interval {
        background: none !important;
      }

      &__icon {
        height: 40px;
        width: auto;
        margin-bottom: -6px;
      }

      &--discount ::v-deep &--interval {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          //border: 1px solid #ccc;
          background: $discount-background-color;
        }
      }
    }

    &__text {
      display: inline-flex;
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 12px;
      color: $theme-2-color;
      margin: 0 0 $padding-section 8px;
      text-transform: uppercase;
      line-height: 1.2em;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
    }
  }
}

</style>
