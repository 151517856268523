<template>
  <div :class="`${mainClass}`">
    <Sticky>
      <div :class="`${mainClass}__fixed_top`">
        <Navbar
          :title="title"
          :show-shadow="false"
          :show-back="true"
          :on-back="onBack"
          background="#F0E969"
          button-border="#CDC75A"
          show-back-bevel
          show-divider
          is-modal />
      </div>
    </Sticky>

    <div :class="`${mainClass}__content`">
      <DiscountsListCard
        v-if="discounts.length > 1"
        :selected="selectedDiscountIds.length === discounts.length"
        :discount="allDiscountsObject"
        :on-click="onAllDiscountsCardClick" />
      <DiscountsListCard
        v-for="(discount, index) in discounts"
        :key="index"
        :selected="selectedDiscountIds.includes(discount.id)
          && (selectedDiscountIds.length < discounts.length
            || (discounts.length === 1 && selectedDiscountIds.length === discounts.length))"
        :discount="discount"
        :on-click="onCardClick" />
    </div>

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import DiscountsListCard from '@/components/Sections/Discounts/DiscountsListCard.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';

export default {
  name: 'DiscountsList',

  components: {
    Navbar,
    DiscountsListCard,
    Alert,
  },

  // Properties ********************************
  props: {
    selectedDiscounts: Array,
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  // Data ********************************
  data() {
    return {
      discounts: [],
      selectedDiscountsArray: this.selectedDiscounts,
      selectedDiscountIds: [],

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,
    };
  },

  // Computed ********************************
  computed: {
    mainClass() {
      return 'discounts-list';
    },
    title() {
      return this.$t('title.discounts_list');
    },
    allDiscountsObject() {
      const maxDiscount = this.discounts.reduce((total, obj) => Math.max(total, obj.maxValue), 0);
      return {
        maxValue: maxDiscount,
        title: this.$t('button.discount_card_choose_all'),
        isChooseAll: true,
      };
    },
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business.id) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    if (this.$store.getters.getDiscounts) {
      this.discounts = this.$store.getters.getDiscounts.getDiscounts();
    }
    if (this.selectedDiscounts) {
      //this.selectedDiscountsArray = this.selectedDiscounts;
      this.selectedDiscountIds = this.selectedDiscountsArray.map(o => o.id);
    }
  },

  // Mounted ********************************
  mounted() {
    this.$analytics.track('Visit: Discounts List');
  },

  // Methods ********************************
  methods: {
    onBack() {
      this.$router.replace({
        name: this.$constants.ROUTE_SEARCH_SERVICE,
        params: {
          showBusinessServices: true,
          selectedDiscounts: this.selectedDiscountsArray,
        },
      }, () => {});
    },
    onCardClick(discount) {
      if (this.selectedDiscountsArray
        && this.selectedDiscountsArray.filter(d => d.id === discount.id).length > 0) {
        // remove selected discount

        if (this.selectedDiscountsArray.length === this.discounts.length
          && this.discounts.length > 1) {
          // if all discounts is selected: remove all and let select this one
          this.selectedDiscountsArray = [];
          this.selectedDiscountIds = [];
        } else {
          // remove the discount selected
          for (let i = 0; i < this.selectedDiscountsArray.length; i++) {
            if (this.selectedDiscountsArray[i].id === discount.id) {
              this.selectedDiscountsArray.splice(i, 1);
              break;
            }
          }
          this.$router.replace({
            name: this.$constants.ROUTE_SEARCH_SERVICE,
            params: {
              showBusinessServices: true,
              selectedDiscounts:
                this.selectedDiscountsArray.sort((a, b) => a.maxValue > b.maxValue),
            },
          }, () => {});
          return;
        }
      }

      let array = [];
      if (this.selectedDiscountsArray) {
        array = this.selectedDiscountsArray;
      }
      if (!this.selectedDiscountIds || this.selectedDiscountIds.includes(discount.id) === false) {
        array.push(discount);
      }

      if (this.isModal === false && discount.getServiceIds().length === 1) {
        this.requestServiceDetails(discount.getServiceIds()[0], discount);
      } else {
        this.$router.replace({
          name: this.$constants.ROUTE_SEARCH_SERVICE,
          params: {
            showBusinessServices: true,
            selectedDiscounts: array.sort((a, b) => a.maxValue > b.maxValue),
          },
        }, () => {});
      }
    },
    onAllDiscountsCardClick() {
      if (this.selectedDiscountsArray
        && this.selectedDiscountsArray.length === this.discounts.length) {
        // remove all discounts if deselecting
        this.$router.replace({
          name: this.$constants.ROUTE_SEARCH_SERVICE,
          params: {
            showBusinessServices: true,
            selectedDiscounts: [],
          },
        }, () => {});
        return;
      }

      const array = [];
      this.discounts.forEach(o => array.push(o));


      this.$router.replace({
        name: this.$constants.ROUTE_SEARCH_SERVICE,
        params: {
          showBusinessServices: true,
          selectedDiscounts: array.sort((a, b) => a.maxValue > b.maxValue),
        },
      }, () => {});
    },

    requestServiceDetails(serviceId, discount) {
      this.$root.loading = true;

      this.$graphql.servicesDetailsQuery([serviceId], services => {
        this.$root.loading = false;

        if (services.length === 0) {
          return;
        }
        const service = services[0];
        if (!this.$store.getters.getAppointments) {
          this.$store.dispatch('resetAppointment');
        }
        service.discount = discount;

        if (this.$store.getters.getAppointments.addService(service)) {
          this.$router.replace({ name: this.$constants.ROUTE_NEW_APPOINTMENT_SERVICES });
        } else {
          this.alert(this.$t('errors.services_select_duplicated_title'), this.$t('errors.services_select_duplicated'));
        }
      }, () => {
        this.$root.loading = false;
        this.showError(this.$t('errors.generic_title'), this.$t('errors.generic_message'));
      });
    },


    showError(title, message) {
      this.alert(title, message);
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.discounts-list {
  background: white;
  //height: 100%;
  //min-height: 100vh;

  @media screen and (min-width: $min-screen-width) {
    min-height: initial;

    &__content {
      min-height: 200px;
    }
  }
  &__content {
    padding: 10px 0;
  }

  &__fixed_top {
    background: white;
  }

}

</style>
