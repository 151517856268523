
<template>
  <div
    :class="mainClasses"
    @click="handleClick">
    <div :class="innerCardClasses">
      <div :class="`${mainClass}__inner__content`">
        <div
          :class="`${mainClass}__inner__content__container`">
          <IlmIcon
            :class="`${mainClass}__inner__content__icon`"
            icon="arrow_forward" />
        </div>
        <span
          :class="`${mainClass}__inner__content__text`">
          {{ $t('button.discount_card_more') }}
        </span>
      </div>
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'DiscountsCardMore',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    onClick: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'discounts-card';
    },
    mainClasses() {
      const classes = {};
      classes[`${this.mainClass}`] = true;
      return classes;
    },
    innerCardClasses() {
      const classes = {};
      classes[`${this.mainClass}__inner`] = true;
      classes[`${this.mainClass}__inner--selected`] = this.selected;
      return classes;
    },
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.discounts-card {
  position: relative;
  max-width: 220px;
  height: 260px;
  user-select: none;

  &__inner {
    position: absolute;
    border-radius: 20px;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transform: scale(0.9);
    transition: $hover-transition;
    border: 2px solid $theme-link-color;
    background: white;

    &--selected {
      box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.3);
      transform: scale(1.0);
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-content: center;

      &__container {
        display: flex;
        align-content: center;
        justify-content: center;
        align-self: center;
        width: 90px;
        height: 90px;
        border: 2px solid $theme-link-color;
        border-radius: 50%;
        background-color: white;
        box-sizing: border-box;
      }
      &__icon {
        align-self: center;
        color: $theme-link-color;
        font-size: 60px;
        line-height: 86px;
        transform: rotate(90deg);
        transition: transform .4s ease-in-out, background-color .3s ease-out;
      }
      &__text {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: $theme-link-color;
        line-height: 1.2;
        margin-top: 16px;
      }
    }
    &--selected &__content__icon {
      transform: none;
    }
  }

  @media (hover) {
    &:hover &__inner--selected {
      cursor: pointer;
      //border: 2px solid $theme-link-variant;
    }
    &:hover &__inner--selected &__inner__content__icon {
      animation-duration: 0.5s;
      animation-name: animateTranslate;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

}
@keyframes animateTranslate {
  from {
    transform: translateX(-2px);
  }

  to {
    transform: translateX(4px);
  }
}

</style>
