import utils from '@/utils/utils';
import Address from './address';
import BillingDetails from './billingDetails';
import CustomerCard from './customerCard';

class CustomerDetails {
  constructor() {
    this.tcAccepted = false;
    this.type = null;

    this.name = null;
    this.email = null;
    this.taxNumber = null;

    this.address = new Address();
    this.billing = new BillingDetails();
    this.card = new CustomerCard();
    this.voucher = null;
  }

  static typecast(obj) {
    const finalObj = Object.assign(new CustomerDetails(), obj);
    if (obj.address) {
      finalObj.address = Object.assign(new Address(), obj.address);
    }
    if (obj.billing) {
      finalObj.billing = Object.assign(new BillingDetails(), obj.billing);
    }
    if (obj.card) {
      finalObj.card = Object.assign(new CustomerCard(), obj.card);
    }
    return finalObj;
  }

  getCleanTaxNumber() {
    return this.taxNumber ? utils.stripInvalidChars(this.taxNumber) : '';
  }
}

export default CustomerDetails;
