<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="ilmloader">
      <!--loading-heart class="ilmloader__loading"/-->
      <LoadingCircle class="ilmloader__loading" />
    </div>
  </transition>
</template>

<script>
import LoadingCircle from './LoadingCircle.vue';

export default {
  name: 'IlmPageLoader',
  components: {
    LoadingCircle,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>


<style lang="scss" scoped>
.ilmloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #ffffff, $alpha: 0.85) !important;
  z-index: 99999;
  touch-action: none;

  &__loading {
    display: block;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

</style>
