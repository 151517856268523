<template>
  <BaseModal
    ref="basemodal"
    :title="$t('modals.titles.add_notes')"
    :disable-ok="disabledSave"
    :show-save="!showDelete"
    :show-delete="showDelete"
    class="modal-notes"
    @closed="onClosed"
    @ok="onOk"
    @delete="onDelete">
    <div class="modal-notes__content">
      <textarea
        ref="textarea"
        v-model.trim="note"
        :maxlength="charLimit"
        :placeholder="$t('placeholders.modal_add_note')"
        class="modal-notes__content__textarea" />
      <span class="modal-notes__content__counter">
        <span :class="charLimitClass">
          {{ currentCount }}
        </span> / {{ charLimit }}
      </span>
    </div>
  </BaseModal>
</template>


<script>
import BaseModal from '@/components/Modals/BaseModal.vue';

export default {
  name: 'ModalNotes',

  // Components ********************************
  components: {
    BaseModal,
  },

  // Properties ********************************
  props: {
    savedNote: String,
  },

  // Data ********************************
  data() {
    return {
      textarea: null,
      note: this.savedNote,
      originalNote: this.savedNote,
      charLimit: 255,
    };
  },

  // Computed ********************************
  computed: {
    disabledSave() {
      return (!this.originalNote || this.originalNote.trim().length === 0)
        && (!this.note || this.note.trim().length === 0);
    },
    showDelete() {
      return this.originalNote && this.originalNote.length > 0
        && (this.originalNote === this.note.trim() || this.note.trim().length === 0);
    },
    charLimitClass() {
      return this.currentCount >= this.charLimit ? 'modal-notes__content__counter__exceed' : '';
    },
    currentCount() {
      return this.note ? this.note.length : 0;
    },
  },

  // Watchers ********************************
  watch: {
    note() {
      //const okEnabled = val && val.length > 0;
      if (this.$refs.basemodal) {
        this.$refs.basemodal.setBtnOkState(this.disabledSave);
      }
    },
    /*selectedTab(val) {

    },*/
  },

  // Mounted ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      setTimeout(function () { // timeout because of modal animation
        self.textarea = self.$refs.textarea;
        if (document.body.clientWidth >= self.$constants.MIN_SCREEN_WIDTH) {
          self.textarea.setAttribute('style', `height: ${self.textarea.scrollHeight}px;overflow-y:hidden;`);
        } else {
          self.textarea.setAttribute('style', 'overflow-y:hidden;');
        }
        //self.textarea.addEventListener('input', self.onTextAreaInput);

        self.textarea.focus();
      }, 300);
    });
  },

  destroyed() {
    if (this.textarea) {
      //this.textarea.removeEventListener('input', this.textarea);
    }
  },

  // Methods ********************************
  methods: {
    close() {
      this.$refs.basemodal.handleCloseClick();
    },
    onClosed() {
      this.$emit('closed', 'close');
    },
    onOk() {
      this.$emit('ok', this.note);
    },
    onDelete() {
      this.$emit('ok', '');
    },
    onTextAreaInput() {
      //const ta = this.$refs.textarea;
      //this.note = this.textarea.value;

      //this.textarea.style.height = 'auto';
      if (document.body.clientWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        this.textarea.style.height = `${this.textarea.scrollHeight}px`;
      }

      //const okEnabled = this.note && this.note.length > 0;
      //this.$refs.basemodal.setBtnOkState(!okEnabled);
    },
  },
};
</script>


<style lang="scss" scoped>

.modal-notes {

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    //padding: $padding-section;
    height: 100%;
    //background: red;
    //overflow: scroll;
    //max-height: 340px;
    //-webkit-overflow-scrolling: touch;

    &__header {
      margin: $padding-section+6px 0;
    }

    &__textarea {
      font-family: $heading-font-family;
      flex: 1 1 auto;
      padding: $padding-section;
      padding-top: $padding-section + 8px;
      border: none;
      background: white;
      outline: none;
      color: black;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.2em;
      margin: 0;
      resize: none;
      box-shadow: none;
      //min-height: 80px;
      overflow: auto;
      border-radius: 0;
    }

    &__counter {
      position: absolute;
      top: 4px;
      right: 8px;
      z-index: 10;
      font-size: 14px;
      color: #ADADAD;

      &__exceed {
        color: red;
      }
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__content {
      //padding: $padding-section;
      min-height: 80px;
      max-height: 340px;
    }
  }
}
</style>
