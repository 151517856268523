
<template>
  <!--transition name="fade-slow"-->
  <div
    :class="slotClassNames"
    @click="itemSelected">
    <span
      class="slot-button__title">
      {{ itemSlot.time_str }}
    </span>
    <span
      v-if="hasDiscount"
      :class="slotDiscountClassNames">
      {{ discountValue }}
    </span>
  </div>
  <!--IlmButton
    :class="slotClassNames"
    :label="itemSlot.time"
    :theme="itemSlot.available ? 'success': 'inverted'"
    :on-click="itemSelected"
    prevent-default /-->
  <!--/transition-->
</template>


<script>

export default {
  name: 'ScheduleGridSlot',

  // Components ********************************
  components: {
  },

  // Properties ********************************
  props: {
    itemSlot: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    discount: Number,
    /*selected: {
      type: Boolean,
      default: false,
    },*/
    maxDiscount: Number,
    onItemSelected: Function,
  },

  // Data ********************************
  data() {
    return {
      selected: false,
    };
  },


  // Computed ********************************
  computed: {
    slotClassNames() {
      const classNames = {
        'slot-button': true,
        'slot-button--disabled': this.itemSlot.available === false,
        'slot-button--selected': this.selected,
        'slot-button--first': this.itemSlot.first,
        'slot-button--last': this.itemSlot.last,
        'slot-button--has-discount': this.hasDiscount,
        'slot-button--active': this.active,
      };
      return classNames;
    },
    slotDiscountClassNames() {
      const classNames = {
        'slot-button__discount': true,
        'slot-button__discount--max': this.hasDiscount && this.discount === this.maxDiscount,
      };
      return classNames;
    },
    hasDiscount() {
      /*if (this.itemSlot.available === false) {
        return false;
      }*/
      if (this.discount && this.discount !== null) {
        return true;
      }
      return false;
    },
    discountValue() {
      return `-${this.discount}%`;
    },
  },

  // Watchers ********************************
  watch: {
    /*itemSlot() {
      this.selected = this.itemSlot.selected === true;
    },*/
  },

  // Created ********************************
  mounted() {
    //this.selected = this.itemSlot.selected === true;
  },

  methods: {
    itemSelected() {
      if (this.onItemSelected && this.itemSlot.available) {
        this.selected = true;
        setTimeout(() => {
          this.onItemSelected(this.itemSlot);
        }, 300);
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.slot-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 18px 0 !important;
  width: 100% !important;
  border-radius: 0;
  background: $theme-ilm-slot-background;
  color: black;
  cursor: pointer;
  box-sizing: border-box;
  min-height: 60px;
  padding: 8px 0;
  box-sizing: border-box;
  user-select: none;
  //background: $theme-ilm-slot-background-selected;
  //color: white;

  &__title {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 1.2;
  }

  /*&__discount {
    font-size: 14px !important;
    font-weight: normal;
    margin-top: 4px;
    line-height: 1.2;
  }*/

  &__discount {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 1.2;
    background: $discount-default-background-color;
    border-radius: 4px;
    padding: 2px 6px;
    position: relative;
    width: 86%;
    margin-top: 6px;
    color: black;
    box-sizing: border-box;
    user-select: none;

    &--max {
      background: $discount-max-background-color;
      //color: white;
    }
  }

  /*&--has-discount {
    background: $discount-background-color;
  }*/

  @media (hover) {
    &:hover {
      //background: $theme-ilm-selection-dimmed;
      //background: $theme-ilm-slot-background-selected;
      //color: white;
      border: 2px solid $theme-ilm-slot-background-selected;
    }
    /*&--has-discount:hover {
      background: $discount-background-color-dimmed;
    }*/
  }

  &--disabled {
    color: #D2D2D2 !important;
    text-decoration: line-through;
    cursor: not-allowed;
    box-shadow: none;
    background: #eee;
    cursor: not-allowed;
    //background: #ccc;
  }

  &--disabled &__discount {
    background: rgb(206, 206, 206);
    border-color: rgb(206, 206, 206);
    color: #fff !important;
  }

  @media (hover) {
    &--disabled:hover {
      background: #eee;
      border: none;
      //background: #ccc;
    }
  }

  &--selected {
    background: $theme-ilm-slot-background-selected;
    color: white;
  }

  &--active {
    background: $theme-state-booked;
    color: white;
    text-decoration: none;
    box-shadow: none;
    border-color: $theme-state-booked !important;
  }

  &--first {
    //font-size: 24px !important;
    //padding: 12px 0 !important;

    border-top-left-radius: $theme-border-radius;
    border-bottom-left-radius: $theme-border-radius;
  }

  /*&--first ::v-deep &__title {
    font-size: 24px !important;
  }

  &--has-discount ::v-deep &__title {
    font-size: 14px !important;
  }*/

  &--last {
    border-top-right-radius: $theme-border-radius;
    border-bottom-right-radius: $theme-border-radius;
  }
}

</style>
