<template>
  <span class="discount_badge">
    {{ value }}
  </span>
</template>


<script>

export default {
  name: 'IlmDiscountBadge',

  // Properties ********************************
  props: {
    value: String,
  },

  // Data ********************************
  data() {
    return {};
  },


  // Computed ********************************
  computed: {
  },
};
</script>


<style lang="scss" scoped>

.discount_badge {
  font-family: $heading-font-family;
  font-weight: bold;
  color: black;
  margin: 0;
  background: $discount-background-color;
  border-radius: 4px;
  text-align: center;
  padding: 2px 6px;
}
</style>
