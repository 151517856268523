
<template>
  <div class="section-appointments">
    <div
      v-if="dateText"
      class="section-appointments__date-container">
      <div class="section-appointments__date-container__date-text">
        {{ dateText }}
      </div>
      <div
        v-if="showServicesCount"
        class="section-appointments__date-container__services-count">
        {{ servicesCountText }}
      </div>
    </div>
    <div class="section-appointments__cards-list">
      <SectionAppointmentCard
        v-for="(appointment, index) in appointments"
        :key="appointment.key"
        :business="business"
        :appointment="appointment"
        :confirmation-mode="confirmationMode"
        :appointment-policies="appointmentPolicies(appointment.key)"
        :selected-key="selectedKey"
        :show-animation="shouldAnimate(appointment.key)"
        :scroll-to-selected="shouldScrollTo(appointment.key, index)"
        :show-interruption="showInterruption(index)"
        :show-state="showState"
        :show-notes="showNotes"
        :show-ending-time="showEndingTime(index)"
        :interruption-duration="interruptionDuration(index)"
        :is-intersected-before="isIntersectedBefore(index)"
        :discount="appointmentDiscount(appointment)"
        :is-loading="isLoading"
        :show-spacer-before="showSpacingBefore(index)"
        :show-spacer-after="showSpacingAfter(index)"
        :on-edit-note-click="clickEditNote"
        :on-cancel-click="clickCancel"
        :on-repeat-click="clickRepeat"
        :on-edit-click="clickEdit"
        :on-remove-click="clickRemove" />
    </div>
  </div>
</template>


<script>
import SectionAppointmentCard from '@/components/Sections/SectionAppointmentCard.vue';
import formatter from '@/utils/formatter.js';

export default {
  name: 'SectionAppointments',

  // Components ********************************
  components: {
    SectionAppointmentCard,
  },

  // Properties ********************************
  props: {
    selectedKey: String,
    appointments: Array,
    appointmentsPolicies: Array,
    dateText: String,
    confirmationMode: {
      type: Boolean,
      default: false,
    },
    showServicesCount: {
      type: Boolean,
      default: true,
    },
    discount: Object,
    showState: {
      type: Boolean,
      default: true,
    },
    showNotes: {
      type: Boolean,
      default: true,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showAnimation: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stickyTop: Number,
    onEditNoteClick: Function,
    onCancelClick: Function,
    onRepeatClick: Function,
    onEditClick: Function,
    onRemoveClick: Function,
  },

  // Data ********************************
  data() {
    return {
      state: null,
    };
  },


  // Computed ********************************
  computed: {
    business() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        return auth.business;
      }
      return null;
    },
    servicesCountText() {
      return this.appointments ? this.$tc('title.services_counter', this.appointments.length, { count: this.appointments.length }) : '';
    },
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  methods: {
    appointmentDiscount(appointment) {
      /*if (this.discount
        && appointment.discountId
        && appointment.discountId === this.discount.id) {
        return this.discount;
      }*/
      if (appointment.discount) {
        return appointment.discount;
      }
      return null;
    },
    appointmentPolicies(key) {
      if (this.appointmentsPolicies && this.appointmentsPolicies.length > 0) {
        return this.appointmentsPolicies.filter(e => e.key === key)[0];
      }
      return null;
    },
    shouldAnimate(key) {
      return this.selectedKey === key && this.appointments.length > 1;
    },
    shouldScrollTo(key, index) {
      return this.selectedKey === key && index > 0;
    },
    showInterruption(index) {
      if (index < this.appointments.length - 1) {
        const current = this.appointments[index];
        const next = this.appointments[index + 1];

        const currDate = this.$moment(`${current.day} ${current.time}`).add(current.service.duration, 'minutes');
        const nextDate = this.$moment(`${next.day} ${next.time}`);

        return currDate.isSameOrAfter(nextDate) === false;
      }
      return false;
    },
    isIntersectedBefore(index) {
      if (index > 0) {
        const current = this.appointments[index];
        const prev = this.appointments[index - 1];

        const currDate = this.$moment(`${current.day} ${current.time}`);
        const prevDate = this.$moment(`${prev.day} ${prev.time}`).add(prev.service.duration, 'minutes');

        return currDate.isBefore(prevDate);
      }
      return false;
    },
    showSpacingBefore(index) {
      if (index === 0) {
        return true;
      }
      const current = this.appointments[index];
      const prev = this.appointments[index - 1];

      const currDate = this.$moment(`${current.day} ${current.time}`);
      const prevDate = this.$moment(`${prev.day} ${prev.time}`).add(prev.service.duration, 'minutes');

      return currDate.isSameOrBefore(prevDate);
    },
    showSpacingAfter(index) {
      if (index === this.appointments.length - 1) {
        return false;
      }
      /*const current = this.appointments[index];
      const next = this.appointments[index + 1];

      const currDate = this.$moment(`${current.day} ${current.time}`)
        .add(current.service.duration, 'minutes');
      const nextDate = this.$moment(`${next.day} ${next.time}`);

      return currDate.isAfter(nextDate);*/
      return true;
    },
    showEndingTime(index) {
      if (index < this.appointments.length - 1) {
        const current = this.appointments[index];
        const next = this.appointments[index + 1];

        const currDate = this.$moment(`${current.day} ${current.time}`).add(current.service.duration, 'minutes');
        const nextDate = this.$moment(`${next.day} ${next.time}`);

        return currDate.isSame(nextDate) === false;
      }
      return true;
    },
    interruptionDuration(index) {
      if (index > 0) {
        const current = this.appointments[index];
        const prev = this.appointments[index - 1];

        const currDate = this.$moment(`${current.day} ${current.time}`);
        const prevDate = this.$moment(`${prev.day} ${prev.time}`).add(prev.service.duration, 'minutes');

        const duration = currDate.diff(prevDate, 'minutes');
        return duration > 0 ? formatter.formatDuration(duration) : null;
      }
      return null;
    },

    clickEditNote(appointment) {
      if (this.onEditNoteClick) {
        this.onEditNoteClick(appointment);
      }
    },

    clickCancel(appointment) {
      if (this.onCancelClick) {
        this.onCancelClick(appointment);
      }
    },

    clickRepeat(appointment) {
      if (this.onRepeatClick) {
        this.onRepeatClick(appointment);
      }
    },

    clickEdit(appointment) {
      if (this.onEditClick) {
        this.onEditClick(appointment);
      }
    },

    clickRemove(appointment) {
      if (this.onRemoveClick) {
        this.onRemoveClick(appointment);
      }
    },

  },

};
</script>


<style lang="scss" scoped>

.section-appointments {
  position: relative;
  z-index: 1;

  &__date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $padding-section $padding-section 0 $padding-section;

    &__date-text {
      flex: 1 1 auto;
      font-size: 24px;
      font-weight: bold;
      color: black;
    }

    &__services-count {
      flex: 0 0 auto;
      font-size: 14px;
      color: $theme-2-color;
    }
  }

  &__cards-list {
    position: relative;
    //padding-left: 120px;
    padding-top: 20px;
    padding-bottom: $padding-section;
  }
}

</style>
