//import mixpanel from 'mixpanel-browser';
import md5 from 'md5';
import mixpanel from './mixpanel';
import google from './google';

let isProdution = false;

export default {

  init(router) {
    if (process.env.NODE_ENV === 'production') {
      // Mixpanel
      mixpanel.init();

      // Google Analytics
      google.init(router);

      isProdution = true;
    }
    return this;
  },

  disableTracking() {
    if (this.isProdution) {
      google.optOut();
      mixpanel.optOut();
    }
  },

  enableTracking() {
    if (this.isProdution) {
      google.optIn();
      mixpanel.optIn();
    }
  },

  setTrackingId(id) {
    if (isProdution === true) {
      google.setTrackingId(id);
    }
  },

  setBusiness(businessId) {
    if (isProdution === true) {
      return google.setBusiness(businessId);
    }
    return true;
  },

  /**
   * Identify a user with a unique ID instead of a Mixpanel randomly generated distinct_id.
   * If the method is never called, then unique visitors will be identified by a UUID generated
   * the first time they visit the site.
   * @param {*} uniqueId
   */
  identify(uniqueId) {
    if (isProdution === true && uniqueId && uniqueId !== '') {
      const userKey = md5(uniqueId);

      mixpanel.identify(userKey);

      google.identify(userKey);
    }
  },

  /**
   * Create an alias, which Mixpanel will use to link two distinct_ids
   * going forward (not retroactively). Multiple aliases can map to the
   * same original ID, but not vice-versa. Aliases can also be chained
   * @param {*} uniqueId
   */
  alias(uniqueId) {
    if (isProdution === true && uniqueId && uniqueId !== '') {
      const userKey = md5(uniqueId);
      mixpanel.alias(userKey);
    }
  },

  /**
   * Register a set of super properties, which are included with all events.
   * This will overwrite previous super property values.
   * @param {*} properties
   */
  register(properties) {
    if (isProdution === true) {
      mixpanel.register(properties);
    }
  },

  reset() {
    if (isProdution === true) {
      mixpanel.reset();
    }
  },

  /**
   * Track an event. This is the most important and frequently used Mixpanel function.
   * @param {*} eventName
   * @param {*} properties
   */
  track(eventName, properties) {
    if (isProdution === true) {
      mixpanel.track(eventName, properties);
      google.track(eventName, properties);

      this.sendEventToParentWindow(eventName, properties);
    }
  },

  /**
   * Set properties on a user record.
   * @param {*} key
   * @param {*} value
   */
  setPeopleParam(key, value) {
    if (isProdution === true) {
      mixpanel.setPeopleParam(key, value);
    }
  },


  trackNewBookingEvent(businessId) {
    if (isProdution === true) {
      google.trackNewBookingEvent(businessId);
    }
  },

  sendEventToParentWindow(eventName, properties) {
    if (window.parent && window !== window.parent) {
      const event = {
        name: eventName,
        ...properties,
      };
      window.parent.postMessage(JSON.stringify(event), '*');
    }
  },
};
