import { stripDiacritics } from '@/utils/matcher';
/*
const deviceType = {
  Android: () => navigator.platform.match(/Android/i),
  BlackBerry: () => navigator.platform && navigator.platform.match(/BlackBerry/i),
  iOS: () => navigator.platform &&
  navigator.platform.match(/(iPhone|iPad|iPod|iPhone)( simulator){0,1}/i),
  Opera: () => navigator.platform && navigator.platform.match(/Opera Mini/i),
  Windows: () => navigator.platform &&
  (navigator.platform.match(/IEMobile/i) || navigator.platform.match(/WPDesktop/i)),
  any: () => (deviceType.Android()
              || deviceType.iOS()),
  //|| deviceType.BlackBerry()
  //|| deviceType.Opera()),
  //|| deviceType.Windows()),
};*/
const deviceType = {
  Android: () => navigator.userAgent.match(/Android/i) !== null,
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod|iPhone/i) !== null,
  iPad: () => navigator.userAgent.match(/iPad/i) !== null,
  Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
  Windows: () => navigator.userAgent.match(/IEMobile|Windows Phone|WPDesktop/i) !== null,
  any: () => deviceType.iOS()
  || deviceType.Android()
  || deviceType.BlackBerry()
  || deviceType.Opera()
  || deviceType.Windows(),
};


export default {

  /**
   *
   * @returns true if mobile device
   */
  isMobile() {
    return deviceType.any();
  },

  /**
   *
   * @returns true if iOS Device present
   */
  isIos() {
    return deviceType.iOS();
    /*
    const iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ];

    if (navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) { return true; }
      }
    }
    return false;*/
  },

  /**
   *
   * @returns true if iPad Device present
   */
  isIPad() {
    return deviceType.iPad();
  },

  /**
   *
   * @returns true if is running in PWA (Progressive Web App)
   */
  isInStandaloneMode() {
    return this.isIos()
      ? ('standalone' in window.navigator) && (window.navigator.standalone)
      : window.matchMedia('(display-mode: standalone)').matches;
  },

  /**
   *
   * @returns capitalized first letter words
   */
  capitalizeWords(str) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  },

  /**
   *
   * @returns true if email is valid
   */
  isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  /**
   *
   * @returns true if CPF is valid
   */
  isCpfValid(cpfRaw) {
    if (typeof cpfRaw !== 'string') { return false; }

    const cpf = cpfRaw.replace(/[^\d]+/g, '');
    if (cpf.length !== 11) { return false; }

    const re = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
    const regex = re.test(cpfRaw);

    if (regex === false) {
      return false;
    }
    const INVALID_CPFS = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];
    if (INVALID_CPFS.indexOf(cpf) >= 0) { return false; }

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
      return false;
    }

    return true;
  },

  formatCpf(cpf) {
    const re = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
    if (re.test(cpf) === false) {
      let val = cpf.replace(/\D/g, ''); //Remove tudo o que não é dígito

      if (val.length > 11) {
        val = val.substr(0, 11);
      }

      val = val.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
      val = val.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      val = val.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
      return val;
    }
    return cpf;
  },

  /**
   *
   * @returns true if CEP is valid
   */
  isCepValid(cep) {
    const re = /^(([0-9]{5}-[0-9]{3}))$/;
    return re.test(cep);
  },

  formatCep(cep) {
    if (cep && cep.length > 0) {
      const re = /^(([0-9]{5}-[0-9]{3}))$/;
      if (re.test(cep) === false) {
        let val = cep.replace(/\D/g, ''); //Remove tudo o que não é dígito

        if (val.length > 8) {
          val = val.substr(0, 8);
        }

        val = val.replace(/(\d{5})(\d*)/, '$1-$2'); //Coloca um hífen entre o quinto e o sexto dígitos
        return val;
      }
    }
    return cep;
  },


  /**
   *
   * @returns true if CEP is valid
   */
  isPTPostalCodeValid(pc) {
    const re = /^(([0-9]{4}-[0-9]{3}))$/;
    return re.test(pc);
  },

  formatPTPostalCode(pc) {
    if (pc && pc.length > 0) {
      const re = /^(([0-9]{4}-[0-9]{3}))$/;
      if (re.test(pc) === false) {
        let val = pc.replace(/\D/g, ''); //Remove tudo o que não é dígito

        if (val.length > 7) {
          val = val.substr(0, 7);
        }

        val = val.replace(/(\d{4})(\d*)/, '$1-$2'); //Coloca um hífen entre o quinto e o sexto dígitos
        return val;
      }
    }
    return pc;
  },
  stripInvalidChars(text) {
    return text.replace(/\W/g, ''); //Remove tudo o que não é dígito ou letra
  },

  /**
   *
   * @returns number string with padded size zeros
   */
  pad(num, size) {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  },

  getNameInitials(fullName) {
    const name = stripDiacritics(fullName).replace(/[^a-z0-9\\ ]/gi, '');
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    } else if (names[0].length > 1) { // only 1 name - check if it has > 1 letters
      initials += names[0].substring(1, 2);
    }
    return initials;
  },

  replaceAt(str, index, ch) {
    return str.replace(/./g, (c, i) => (i === index ? ch : c));
  },

  splice(text, idx, rem, str) {
    return text.slice(0, idx) + str + text.slice(idx + Math.abs(rem));
  },

  isExactMatch(text, search) {
    if (search) {
      const trimmed = stripDiacritics(search.trim()).toLowerCase();
      const field = stripDiacritics(text).toLowerCase();
      //console.log(`trimmed: ${trimmed} | field: ${field} | match: ${field.search(trimmed)}`);
      return field.search(trimmed) !== -1;
    }
    return false;
  },

  highlightSimple(text, searchText, highlightClass, minSearch) {
    if (searchText) {
      //const trimmed = this.searchText.trim();
      const trimmed = stripDiacritics(searchText.trim()).replace('\\', '');
      if (trimmed.length >= minSearch) {
        const search = stripDiacritics(text);
        const pattern = `${trimmed}`;
        const regex = new RegExp(pattern, 'gi');
        const match = regex.exec(search);
        if (match !== null) {
          const index = match.index;

          let result = this.splice(text, index + trimmed.length, 0, '</span>');
          result = this.splice(result, index, 0, `<span class="${highlightClass}">`);
          return result;
        }
      }
      return text;
    }
    return `<span class="${highlightClass}" style="color:black;">${text}</span>`;
  },

  highlight(text, searchText, highlightClass, minSearch) {
    if (searchText) {
      //const trimmed = this.searchText.trim();
      const trimmed = stripDiacritics(searchText.trim());
      if (trimmed.length >= minSearch) {
        const search = stripDiacritics(text);
        let pattern = `(\\b[^\\s]${trimmed})`;
        for (let i = 0; i < trimmed.length; i++) {
          if (i === 0 || i === trimmed.length - 1) {
            pattern += `|(\\b${this.replaceAt(trimmed, i, '[^\\s]?')})`;
          } else {
            pattern += `|(\\b${this.replaceAt(trimmed, i, '[^\\s]')})`;
          }
        }
        pattern += `|(\\b${trimmed}[^\\s])`;
        const regex = new RegExp(pattern, 'gi');
        const match = regex.exec(search);
        if (match !== null) {
          const index = match.index;
          //const first = text.slice(index, search.length);

          let result = this.splice(text, index + trimmed.length, 0, '</span>');
          result = this.splice(result, index, 0, `<span class="${highlightClass}">`);
          return result;
        }
        /*
        const regex = new RegExp(pattern, 'gi');
        if (regex.exec(text)) {
          return text.replace(regex,
          '<span class="row-container__flex__text__highlighted">$&</span>');
        }*/

        /*
        // first word sentence
        let pattern = `^${trimmed}\\b`;
        let regex = new RegExp(pattern, 'gi');
        if (regex.exec(text)) {
          return text.replace(regex, '<span class="row-container__flex__text__highlighted
          row-container__flex__text__highlighted--pad-right">$&</span>');
        }

        // first letters sentence
        pattern = `^${trimmed}`;
        regex = new RegExp(pattern, 'gi');
        if (regex.exec(text)) {
          return text.replace(regex,
          '<span class="row-container__flex__text__highlighted">$&</span>');
        }

        // middle sentence incomplete words
        pattern = `\\s${trimmed}\\b`;
        regex = new RegExp(pattern, 'gi');
        if (regex.exec(text)) {
          return text.replace(regex, '<span class="row-container__flex__text__highlighted
          row-container__flex__text__highlighted--pad-left
          row-container__flex__text__highlighted--pad-right">$&</span>');
        }

        // middle sentence words
        pattern = `\\s${trimmed}`;
        regex = new RegExp(pattern, 'gi');
        if (regex.exec(text)) {
          return text.replace(regex, '<span class="row-container__flex__text__highlighted
          row-container__flex__text__highlighted--pad-left">$&</span>');
        }*/
      }
      return text;
    }
    return `<span class="${highlightClass}" style="color:black;">${text}</span>`;
    //return text.replace(new RegExp('Massag', 'gi'),
    //'<span class="row-container__text__highlighted" style="color:black;">$&</span>');
  },


  addAppManifest(name, shortName, imagesUrl, url) {
    const myDynamicManifest = {
      name,
      short_name: shortName,
      start_url: url,
      display: 'standalone',
      background_color: '#ffffff',
      theme_color: '#ffffff',
      icons: [{
        src: `${imagesUrl}/icons/android-icon-36x36.png`,
        sizes: '36x36',
        type: 'image/png',
        density: '0.75',
      },
      {
        src: `${imagesUrl}/icons/android-icon-48x48.png`,
        sizes: '48x48',
        type: 'image/png',
        density: '1.0',
      },
      {
        src: `${imagesUrl}/icons/android-icon-72x72.png`,
        sizes: '72x72',
        type: 'image/png',
        density: '1.5',
      },
      {
        src: `${imagesUrl}/icons/android-icon-96x96.png`,
        sizes: '96x96',
        type: 'image/png',
        density: '2.0',
      },
      {
        src: `${imagesUrl}/icons/android-icon-144x144.png`,
        sizes: '144x144',
        type: 'image/png',
        density: '3.0',
      },
      {
        src: `${imagesUrl}/icons/android-icon-192x192.png`,
        sizes: '192x192',
        type: 'image/png',
        density: '4.0',
      }],
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
  },

};
