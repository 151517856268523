<template>
  <div class="business-details">
    <div>
      <transition name="fade">
        <div
          v-if="isBusinessNameSticked"
          class="business-details__name-container
          business-details__name-container--sticky
          fixed-with-limited">
          <h1 class="business-details__name-container__name">
            {{ businessName }}
          </h1>
        </div>
      </transition>

      <!--Sticky
        :show-shadow="false"
        :z-index="-1">
        <div
          :class="coverContainerClass"
          :style="coverContainerStyle"
          class="business-details__cover-container"-->
      <!--img
            :src="businessPosterImage"
            class="business-details__cover-container__img"-->
      <!--div v-if="showSmallHeader">
            <h3
              v-if="showUser"
              class="business-details__cover-container__category
                business-details__cover-container__client">
              {{ $t('headers.client_greeting', { username: getUserName }) }}
            </h3>
            <h1
              class="business-details__cover-container__name">{{ businessName }}</h1-->
      <!--h3
              class="business-details__cover-container__category">{{ businessCategory }}</h3-->
      <!--/div>
        </div>
      </Sticky-->

      <NavigationBar
        v-if="showNavbar"
        :title="businessName"
        :subtitle="showUser
          ? $t('headers.client_greeting', { username: getUserName }): ''"
        :on-primary-option="openAccount"
        :primary-option-icon="accountIcon"
        :on-my-appointments-click="navMyAppointmentsClick"
        show-my-appointments />

      <div
        ref="full_business_name_bar"
        :class="fullNameContainerClass"
        class="business-details__name-container">
        <h1
          v-if="!showSmallHeader"
          class="business-details__name-container__name">{{ businessName }}</h1>
        <h3
          v-if="!showSmallHeader"
          class="business-details__name-container__category">{{ businessCategory }}</h3>
      </div>
      <div
        :class="containerClasses"
        class="business-details__list">

        <div
          v-if="showVoucher && isInitialized"
          class="business-details__list__section">
          <SectionVoucher
            :on-selected="onVoucherSelected"
            class="business-details__list__section__voucher" />
          <div class="business-details__list__divider" />
        <!--SectionBundle />
          <div class="business-details__list__divider"/-->
        </div>

        <div
          v-if="clientSuggestions.length > 0 && showServicesList"
          class="business-details__list__section">
          <div
            class="business-details__list__header-container">
            <ListHeader
              t-key="headers.search_service_client_list"
              icon="favorite"
              icon-color="#F10000" />
          </div>
          <AutocompleteList
            ref="list_client_suggestions"
            :on-service-selected="serviceSelected"
            :show-category="false"
            :items="clientSuggestionsFiltered"
            data-type="client" />

          <div
            class="business-details__list__divider" />
        </div>

        <div
          v-if="isInitialized && discounts && discounts.getFeaturedDiscounts().length > 0"
          class="business-details__list__section">
          <div
            class="business-details__list__header-container">
            <ListHeader
              t-key="headers.search_service_discount_list"
              icon="discount-tag"
              icon-color="#000000"
              circle-color="#F3EA00" />
          </div>

          <DiscountsSlider
            ref="discounts_section"
            :initialize="isDiscountsInitalized"
            :discounts="discounts" />

          <div
            class="business-details__list__divider" />
        </div>


        <div
          v-if="suggestions && suggestions.length > 0 && showServicesList"
          class="business-details__list__section">
          <div
            class="business-details__list__header-container">
            <ListHeader
              t-key="headers.search_service_list"
              icon="star"
              icon-size="lg"
              icon-color="#FFC000" />
          </div>
          <AutocompleteList
            ref="list_suggestions"
            :on-service-selected="serviceSelected"
            :show-category="false"
            :items="businessSuggestionsFiltered"
            data-type="business" />

          <div
            class="business-details__list__see-more-container">
            <IlmButton
              :on-click="showAllClicked"
              t-key="button.show_all_services"
              prevent-default
              theme="block success"
              class="business-details__list__see-more-container__button" />
          </div>

          <div
            class="business-details__list__divider" />
        </div>
        <div
          v-else-if="showServicesList && !showFooter">
          <div
            class="business-details__list__see-more-container">
            <IlmButton
              :on-click="showAllClicked"
              :label="buttonBookingLabel"
              prevent-default
              theme="block success"
              class="business-details__list__see-more-container__button" />
          </div>
        </div>

        <div
          v-if="isInitialized"
          class="business-details__list__section">
          <div
            v-if="business"
            class="business-details__list__section">
            <div
              class="business-details__list__header-container">
              <ListHeader
                t-key="headers.business_details_schedule" />
            </div>
            <DrawerSchedule :business="business" />
            <div
              class="business-details__list__margin" />
          </div>
        </div>

        <div
          v-if="isInitialized">
          <SectionMap
            :title="$t('headers.business_details_address')" />
        </div>

        <div
          v-if="isInitialized"
          class="business-details__list__footer">
          <IlmFooter />
        </div>

        <!--div
          v-if="isInitialized"
          class="business-details__list__section">
          <div v-if="business && businessAddress !== ''">
            <div
              class="business-details__list__header-container">
              <ListHeader
                t-key="headers.business_details_address" />
            </div>
            <p
              class="business-details__list__text"
              v-html="businessAddress" />
          </div>

          <div
            v-if="business && showMap"
            class="business-details__list__map-container">
            <div
              class="business-details__list__map-container__clickable"
              @click="handleMapClick">
              <img
                :src="googleMapImage"
                class="business-details__list__map-container__clickable__img">
              <div class="business-details__list__map-container__clickable__overlay" />
            </div>
          </div>
        </div-->
      </div>

    </div>
    <transition name="slide-up-fast-delay">
      <div
        v-if="showFooter"
        ref="footer"
        :class="footerClass"
        class="business-details__footer">
        <div
          class="business-details__footer__split">
          <IlmButton
            v-if="showServicesList"
            :on-click="clickNewAppointment"
            :discount-label="buttonBookingDiscountLabel"
            :label="buttonBookingLabel"
            prevent-default
            theme="block success"
            class="business-details__footer__confirm_button
            business-details__footer__confirm_button--left" />
          <IlmButton
            :on-click="clickMyAppointments"
            t-key="button.success_my_appointments"
            prevent-default
            theme="block inverted"
            class="business-details__footer__confirm_button
            business-details__footer__confirm_button--right" />
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import NavigationBar from '@/components/Nav/NavigationBar.vue';
import ListHeader from '@/components/Lists/Header.vue';
import AutocompleteList from '@/components/Lists/AutocompleteList.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import DrawerSchedule from '@/components/Lists/DrawerSchedule.vue';
import SectionMap from '@/components/Sections/SectionMap.vue';
import SectionVoucher from '@/components/Sections/SectionVoucher.vue';
import DiscountsSlider from '@/components/Sections/Discounts/DiscountsSlider.vue';
import IlmFooter from '@/components/IlmFooter.vue';
import Error from '@/graphql/error';
import placeholderImage from '@/assets/images/placeholder-grey.png';
import DiscountList from '@/models/discountList';

export default {
  name: 'BusinessDetails',


  // Components ********************************
  components: {
    NavigationBar,
    ListHeader,
    AutocompleteList,
    IlmButton,
    DrawerSchedule,
    SectionMap,
    SectionVoucher,
    DiscountsSlider,
    IlmFooter,
  },

  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      next(false);

      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger && fbMessenger.inFrame) {
        window.MessengerExtensions.requestCloseBrowser(function success() {
          // webview closed
        }, function error() {
          // an error occurred
        });
      }
      return;
    }
    next();
  },

  // Properties ********************************
  props: {
  },

  // Data ********************************
  data() {
    return {
      isInitialized: false,
      isDiscountsInitalized: false,
      business: null,
      isBusinessNameSticked: false,
      isBusinessScheduleOpen: false,
      showScrollInertia: true,

      showServicesList: false,
      clientSuggestions: [],
      suggestions: [],
      discounts: null,

      windowWidth: null,
      windowHeight: null,
      scrollHeight: null,
      windowScrollY: 0,
      showFooterShadow: false,
      footerInitiated: false,

      showVoucher: false,
    };
  },

  // Computed ********************************
  computed: {
    showUser() {
      const auth = this.$store.getters.getAuth;
      return auth && auth.isAuthenticated() && auth.client.name;
    },
    accountIcon() {
      const auth = this.$store.getters.getAuth;
      if (auth
        && auth.isAuthenticated()
        && this.$constants.SHOW_USER_ACCOUNT
        && auth.business.hasPaymentsActive()) {
        return 'account_circle';
      }
      return null;
    },
    getUserName() {
      const auth = this.$store.getters.getAuth;
      if (auth && auth.client.name) {
        return auth.client.name;
      }
      return '';
    },
    showSmallHeader() {
      // TODO: check if business has cover image defined
      return true;
    },
    businessName() {
      if (this.business && this.business.name) {
        return this.business.name;
      }
      return '';
    },
    businessCategory() {
      if (this.business && this.business.category) {
        return this.business.category;
      }
      return '';
    },
    coverContainerClass() {
      return {
        'business-details__cover-container--small': this.showSmallHeader,
      };
    },
    coverContainerStyle() {
      const img = this.businessPosterImage;
      if (img && img !== '') {
        return {
          //'background-image': `url('${img}')`,
          'background-size': '100%',
        };
      }
      return {};
    },
    fullNameContainerClass() {
      return {
        'business-details__name-container--hidden': this.showSmallHeader,
      };
    },
    /*contentListStyle() {
      const paddingBottom = 140;
      const style = {
        paddingBottom: `${paddingBottom}px`,
      };
      return style;
    },*/
    showNavbar() {
      return this.windowWidth < this.$constants.MIN_SCREEN_WIDTH;
    },
    showFooter() {
      if (this.$root.loading) return false;
      return this.$root.isDesktop;
      /*if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) return true;
      if (this.footerInitiated) return true;

      return this.windowWidth < this.$constants.MIN_SCREEN_WIDTH
        && (this.showVoucher === false || (this.scrollHeight > this.windowHeight
        && this.windowScrollY > 0));*/
    },
    containerClasses() {
      return {
        'business-details__list--scrollInertia': this.showScrollInertia,
        'business-details__list--shadow': this.windowScrollY > 5,
        'business-details__list--padding-bottom': this.windowWidth < this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    containerStyles() {
      if (this.windowWidth < this.$constants.MIN_SCREEN_WIDTH) {
        return {
          'padding-bottom': '140px',
        };
      }
      return {};
    },
    buttonBookingDiscountLabel() {
      /*const discountValue = this.discounts ? this.discounts.getMaxDiscountValue() : null;
      if (discountValue) {
        return this.$t('button.discount_variable_label', { value: discountValue });
      }*/
      return null;
    },
    buttonBookingLabel() {
      /*const discountValue = this.discounts ? this.discounts.getMaxDiscountValue() : null;
      if (discountValue) {
        return this.$t('button.my_appointments_new_w_discount');
      }*/
      return this.$t('button.my_appointments_new');
    },
    footerClass() {
      return {
        'business-details__footer--shadow': this.showFooterShadow,
        'business-details__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    footerStyles() {
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return {
          position: 'sticky',
        };
      }
      return {
        position: 'fixed',
      };
    },
    clientSuggestionsFiltered() {
      return this.clientSuggestions.slice(0, 3);
    },
    businessSuggestionsFiltered() {
      if (this.clientSuggestionsFiltered.length > 0 && this.suggestions.length > 0) {
        const ids = this.clientSuggestionsFiltered.map(item => item.services[0].id);
        return this.suggestions.filter(function (item) {
          return ids.includes(item.services[0].id) === false;
        }).slice(0, 5);
      }
      return this.suggestions.slice(0, 5);
    },
    businessPosterImage() {
      /*let baseUrl = process.env.VUE_APP_ILM_HOST;
      if (process.env.NODE_ENV !== 'production') {
        baseUrl = 'http://booking.dev.ilove.me:8080/';
      }*/
      const auth = this.$store.getters.getAuth;
      if (auth) {
        //return auth.getCoverImage(baseUrl);
        //return `${baseUrl}images/placeholder-grey.png`;
        //return '~@/assets/images/placeholder-grey.png';
        return placeholderImage;
      }
      //return '';
      //return '~@/assets/images/placeholder-grey.png';
      return placeholderImage;
      //return `${baseUrl}images/placeholder-grey.png`;
    },
  },

  // Created ********************************
  created() {
    this.$root.loading = true;

    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }
    this.business = auth.business;

    this.$root.checkCookieConsent();
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },


  // Mounted ********************************
  mounted() {
    //window.scrollTo(0, 0);
    //this.$root.loading = true;
    this.$root.loading = true;

    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      return;
    }

    this.$store.dispatch('setCustomer', null);
    this.$store.dispatch('resetAppointment');

    this.$analytics.track('Visit: Business details');

    this.showVoucher = auth.business.hasCovidActive() && auth.business.hasPaymentsActive();
    this.showServicesList = auth.business.hasBookingsActive();

    const self = this;
    this.$nextTick(function () {
      self.windowWidth = document.body.clientWidth;
      self.windowHeight = document.body.clientHeight;
      self.scrollHeight = document.body.scrollHeight;
      window.addEventListener('scroll', self.handleScroll);
      window.addEventListener('resize', self.handleResize);
      window.scrollTo(0, 0);
    });

    self.getDiscounts();
  },

  // Methods ********************************
  methods: {
    getDiscounts() {
      const self = this;
      this.$graphql.businessDiscountsQuery(function (discounts) {
        self.$store.dispatch('setDiscounts', discounts);
        self.discounts = discounts;
        self.getSuggestions();
      }, function () {
        self.$store.dispatch('setDiscounts', null);
        self.discounts = new DiscountList();
        self.getSuggestions();
      });
    },

    getSuggestions() {
      const self = this;
      this.$graphql.suggestionsQuery(5, 10, function (suggestions) {
        //console.log(suggestions);
        const suggestionsArray = suggestions.getItems();
        const clientSuggestionsArray = suggestions.getClientItems();
        const discount = self.discounts ? self.discounts.getDiscount() : null;
        //if (discount) {
        for (let i = 0; i < suggestionsArray.length; i++) {
          const services = suggestionsArray[i].services;
          for (let j = 0; j < services.length; j++) {
            services[j].discount = discount;
            const serviceId = services[j].id;
            const serviceDiscount = self.discounts.getServiceDiscount(serviceId);
            if (serviceDiscount) {
              if (!discount
                || (serviceDiscount.getMaxDiscount(serviceId) > discount.getMaxDiscount())) {
                services[j].discount = serviceDiscount;
              }
            }
          }
          suggestionsArray[i].services = services;
        }
        for (let i = 0; i < clientSuggestionsArray.length; i++) {
          const services = clientSuggestionsArray[i].services;
          for (let j = 0; j < services.length; j++) {
            services[j].discount = discount;
            //services[j].discount = discount;
            const serviceId = services[j].id;
            const serviceDiscount = self.discounts.getServiceDiscount(serviceId);
            if (serviceDiscount) {
              if (!discount
                || (serviceDiscount.getMaxDiscount(serviceId) > discount.getMaxDiscount())) {
                services[j].discount = serviceDiscount;
              }
            }
          }
          clientSuggestionsArray[i].services = services;
        }
        //}
        self.suggestions = suggestionsArray;
        self.clientSuggestions = clientSuggestionsArray;

        self.$store.dispatch('setClientSuggestions', self.clientSuggestions);

        self.isInitialized = true;
        self.$root.loading = false;
        self.$nextTick(function () {
          self.handleScroll();
          self.handleResize();
          //self.showFooterShadow = document.body.scrollHeight > document.body.clientHeight;
          self.showScrollInertia = false;
        });
      }, function (error) {
        self.suggestions = [];
        self.clientSuggestions = [];
        self.isInitialized = true;
        self.$root.loading = false;
        self.$nextTick(function () {
          self.handleScroll();
          //self.showFooterShadow = document.body.scrollHeight > document.body.clientHeight;
          self.showScrollInertia = false;
        });
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
          self.$router.push({ name: self.$constants.ROUTE_LOGIN }, () => {});
        }
        //self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
    },

    getOffsetTop(element) {
      let offsetTop = 0;
      while (element && element.tagName.toLowerCase() !== 'html') {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return this.distance ? offsetTop - this.distance : offsetTop;
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      const nameBar = this.$refs.full_business_name_bar;
      this.windowScrollY = window.pageYOffset;
      if (nameBar) {
        //const barHeight = nameBar.clientHeight;
        let offset = nameBar.offsetTop + (this.showSmallHeader ? 0 : 30);
        if (this.windowWidth > this.$constants.MIN_SCREEN_WIDTH) {
          offset += 120;
        }
        if (offset < this.windowScrollY && !this.isBusinessNameSticked) {
          this.isBusinessNameSticked = true;
        } else if (offset >= this.windowScrollY && this.isBusinessNameSticked) {
          this.isBusinessNameSticked = false;
        }
      }
      //this.showFooterShadow = document.body.scrollHeight > document.body.clientHeight;
      if (this.$refs.footer) {
        if (this.windowWidth > this.$constants.MIN_SCREEN_WIDTH) {
          const offset = this.$refs.footer.offsetTop;
          const height = this.$refs.footer.offsetHeight;
          const parentHeight = this.$refs.footer.offsetParent.clientHeight;
          this.showFooterShadow = (offset + height < parentHeight);
        } else {
          const height = document.body.clientHeight;
          this.showFooterShadow = (this.windowScrollY + height
            < document.body.scrollHeight);
        }
        if (this.showFooter) {
          this.footerInitiated = true;
        }
      }

      const discountsSection = this.$refs.discounts_section;
      if (this.isInitialized
        && discountsSection
        && discountsSection.$el
        && !this.isDiscountsInitalized
        && this.discounts
        && this.discounts.getFeaturedDiscounts().length > 2) {
        /*
        const offset = this.getOffsetTop(discountsSection.$el);
        let pageOffset = nameBar.offsetTop + (this.showSmallHeader ? 0 : 30);
        if (this.windowWidth > this.$constants.MIN_SCREEN_WIDTH) {
          pageOffset += 120;
        }
        console.log(`windowScrollY: ${this.windowScrollY} | offset: ${offset}`);
        if (offset <= this.windowScrollY + pageOffset) {
          this.isDiscountsInitalized = true;
        }*/
        if (this.isInViewport(discountsSection.$el)) {
          this.isDiscountsInitalized = true;
        }
      }
    },
    handleResize() {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
      this.scrollHeight = document.body.scrollHeight;
    },
    updateStickyBusinessName(val) {
      this.isBusinessNameSticked = val;
    },
    serviceSelected(item, dataType) {
      this.$store.dispatch('resetAppointment');
      this.$store.dispatch('setAppointmentService', item);

      if (dataType === 'client') {
        this.$analytics.track('Action: Client Suggestion');
      } else {
        this.$analytics.track('Action: Business Most Used');
      }
      //this.$router.push({ name: this.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
      if (!this.$store.getters.getAppointments) {
        this.$store.dispatch('resetAppointment');
      }
      this.$store.getters.getAppointments.addService(item);
      this.$router.push({ name: this.$constants.ROUTE_NEW_APPOINTMENT_SERVICES });

      this.showPWA();
    },

    showPWA() {
      this.$root.showPWAPrompt();
    },

    clickNewAppointment() {
      this.$analytics.track('Action: New Appointment Click');
      this.showServiceList();
    },
    clickMyAppointments() {
      this.$analytics.track('Action: My Appointments Click (Desktop)');
      /*const auth = this.$store.getters.getAuth;
      if (auth.isAuthenticated()) {
        // go to my appointments
        this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS });
      } else {
        // go to phone verification before my appointments
        this.$router.push({
          name: this.$constants.ROUTE_VERIFY_PHONE,
          params: { endpoint: this.$constants.SOURCE_LOCATION_MY_BOOKINGS },
        });
      }*/
      this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS }, () => {});
      this.showPWA();
      //this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS });
    },
    navMyAppointmentsClick() {
      this.$analytics.track('Action: My Appointments Click');
      this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS }, () => {});
      this.showPWA();
    },
    showAllClicked() {
      this.$analytics.track('Action: All Services Click');
      this.showServiceList();
    },
    showServiceList() {
      this.$router.push({
        name: this.$constants.ROUTE_SEARCH_SERVICE,
        params: { showBusinessServices: true },
      }, () => {});
      this.showPWA();
    },

    onVoucherSelected() {
      this.$router.push({ name: this.$constants.ROUTE_VOUCHER }, () => {});
    },

    openAccount() {
      this.$router.push({ name: this.$constants.ROUTE_ACCOUNT }, () => {});
    },
  },
};
</script>


<style lang="scss" scoped>

.business-details {
  position: relative;

  @media screen and (min-width: $min-screen-width) {
    background: white;
    &__cover-container {
      display: none !important;
    }
  }
  &__cover-container {
    display: block;
    min-height: 190px;
    //background: #ebebeb;

    &--small {
      min-height: 48px;
      //padding: $padding-section;
      padding: 60px $padding-section 54px $padding-section;
      text-align: center;
    }

    &__img {
      width: 100%;
    }

    &__name {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.2em;
      //padding-top: 20px;
      margin: 0;
      //color: white;
      color: black;
      //text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
    }
    &__category {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.2em;
      //color: white;
      //color: $theme-2-color;
      color: black;
      margin: 0;
      padding-top: 2px;
      //text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
    }

    &__client {
      padding-top: 0;
      padding-bottom: 6px;
    }
  }

  &__name-container {
    background: white;
    padding: $padding-section;
    font-family: $heading-font-family;
    transition: all 0.3s ease;

    &--hidden {
      //padding: 8px;
      padding: 0;
    }

    &__name {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.2em;
      margin: 0;
      color: black;
      text-align: left;
    }
    &__category {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.2em;
      color: $theme-2-color;
      margin: 0;
      padding-top: 2px;
    }

    &--sticky {
      //background: $theme-ilm-color;
      background: white;
      padding: 8px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      box-shadow: $box-shadow-bottom;
    }
    &--sticky ::v-deep &__name {
      //color: white;
      color: black;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }

    @media screen and (min-width: $min-screen-width) {
      &--sticky {
        background: white;
      }
      &--sticky ::v-deep &__name {
        color: black;
      }
    }

    &--sticky ::v-deep &__category {
      display: none;
    }
  }

  &__list {
    background: $theme-3-bg;
    padding-bottom: 20px;
    transition: padding 0.3s ease-out 0.1s, box-shadow 0.3s ease-out 0s;

    &__section {
      background: white;
    }
    &__section + &__section {
      border-top: 1px solid $theme-list-divider-color;
    }

    &__footer {
      padding-top: 20px;
      padding-bottom: 10px;
    }

    &--scrollInertia {
      padding-top: 200px;
    }

    &--shadow {
      box-shadow: 0px -2px 8px 0px rgba(156,156,156,0.5);
    }

    &--padding-bottom {
      padding-bottom: 10px;
    }


    @media screen and (min-width: $min-screen-width) {
      &--scrollInertia {
        padding-top: 0;
      }

      &--shadow {
        box-shadow: none;
      }

      &__footer {
        display: none;
      }
    }

    &__text {
      color: black;
      padding: 10px $padding-section;
      margin: 0;
    }

    &__header-container {
      background: white;
      padding: $padding-section;
      z-index: 1;
    }

    &__divider {
      border-bottom: 1px solid $theme-list-divider-color;
      margin-bottom: 20px;
    }

    &__margin {
      margin-bottom: 20px;
    }

    &__see-more-container {
      text-align: center;
      padding: $padding-section+10px $padding-section;
      border-top: 1px solid $theme-list-divider-color;

      &__button {
        //color: $theme-link-color;
        width: 100%;
        max-width: 360px;
      }
      /*&__button:hover {
        //color: $theme-link-variant;
      }*/
    }
  }

  &__footer {
    background: white;
    padding: $padding-section;
    border-top: 1px solid $theme-list-divider-color;
    text-align: center;
    position: fixed;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {

      &--left {
        margin-bottom: 6px;
        //margin-right: 5px;
      }

      &--right {
        margin-left: 0;
      }

      &--save {
        position: relative;
      }
    }

    &__split {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 600px;
    }

    @media screen and (min-width: $min-screen-width) {
      &__confirm_button {
        &--left {
          margin-bottom: 0;
          margin-right: 5px;
        }
        &--right {
          margin-left: 5px;
        }
      }
      &__split {
        flex-direction: row;
      }
    }
  }

}
</style>
