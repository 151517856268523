import utils from '@/utils/utils';

const moment = require('moment-timezone');

let timezone;
let i18n;

export default {

  setTranslation(translate) {
    i18n = translate;
  },

  /**
   * @param date date to parse
   * @param format date format
   * @param showDay show prefix on the resulting text (today, yesterday, tomorrow)
   * @returns date string
   */
  getDateString(date, format, showDay) {
    const dateString = date.format(format);//moment(date, format);
    if (showDay === true && i18n) {
      if (this.isTodayDate(date)) {
        return i18n.t('dates.today_format', { date: dateString });
      } if (this.isTomorrowDate(date)) {
        return i18n.t('dates.tomorrow_format', { date: dateString });
      } if (this.isYesterdayDate(date)) {
        return i18n.t('dates.yesterday_format', { date: dateString });
      }
    }
    return utils.capitalizeWords(dateString);
  },

  /**
   *
   * @returns true if date is today
   */
  isTodayDate(date) {
    const now = new Date();
    const today = moment.tz(now, timezone);

    return date.isSame(today, 'day');
  },

  /**
   *
   * @returns true if date is tomorrow
   */
  isTomorrowDate(date) {
    const now = new Date();
    const tomorrow = moment.tz(now, timezone).add(1, 'days');

    return date.isSame(tomorrow, 'day');
  },

  /**
   *
   * @returns true if date is tomorrow
   */
  isYesterdayDate(date) {
    const now = new Date();
    const yesterday = moment.tz(now, timezone).add(-1, 'days');

    return date.isSame(yesterday, 'day');
  },

  /**
   * @returns true if browser timezone is different from the businessTimeZone
   */
  isDifferentTimezone() {
    if (timezone) {
      return moment.tz.guess() !== timezone;
    }
    return false;
  },

  /**
   * Sets default timezone
   * @param businessTimeZone business timezone
   */
  setTimezone(businessTimezone) {
    timezone = businessTimezone;
    if (businessTimezone) {
      moment.tz.setDefault(businessTimezone);
    }
  },

  /**
   * Get business timezone
   */
  getTimezone() {
    if (timezone) {
      return timezone;
    }
    return moment.tz.guess();
  },

  getMoment() {
    return moment;
  },

};
