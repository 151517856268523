import DateUtils from '@/utils/dateUtils.js';
import Constants from '@/utils/constants.js';
import Utils from '@/utils/utils.js';

const SIMULTANEOUS_CATEGORIES = [
  'manicure_pedicure',
];

class MultipleServiceAvailabilities {
  moment = DateUtils.getMoment();

  constructor(business) {
    this.reset();
    this.business = business;

    this.isDynamicHours = this.business.isMappingTypeDynamic();
    this.servicesInterval = this.business.minInterval;
    this.scheduleStartTime = null;
    this.scheduleEndTime = null;

    this.date = null;
    this.appointments = [];
    this.servicesAvailabilities = [];
    this.selectedServiceSlot = {};
    this.tempSelectedServiceSlot = {};
    this.discounts = null;

    this.now = null;
    this.isToday = false;
    this.currentHour = null;

    //this.slotsBlacklist = [];
  }

  reset() {
    this.slots = {};
    this.slotsWhitelist = [];
    this.serviceSlots = {};
  }

  getSingleServiceSlots() {
    const serviceId = `${this.appointments[0].service.id}`;
    const items = this.serviceSlots[serviceId];
    const size = items.length;

    const weekday = this.moment(this.date).isoWeekday();
    let discount = null;
    if (this.discounts) {
      const dayDiscounts = this.discounts.getServiceDateDiscounts(serviceId);
      if (dayDiscounts) {
        discount = dayDiscounts[weekday];
      }
    }

    const slots = [];
    slots[0] = [];
    slots[1] = [];
    slots[2] = [];
    let timeSection = 0;
    for (let i = 0; i < size; i++) {
      const item = items[i];
      const time = item.time;
      const hour = parseInt(time.split(':')[0], 10);
      if (hour < Constants.SCHEDULE_MORNING) {
        timeSection = 0;
      } else if (hour < Constants.SCHEDULE_AFTERNOON) {
        timeSection = 1;
      } else {
        timeSection = 2;
      }
      if (discount) {
        item.discount = discount.getHourDiscount(weekday, item.time);
      }

      slots[timeSection].push(item);
    }
    return slots;
  }


  initAvailabilities(dateSelected, appointments, availabilities, discounts) {
    this.date = dateSelected;
    this.appointments = appointments;
    this.servicesAvailabilities = availabilities;
    this.discounts = discounts;
    this.tempSelectedServiceSlot = this.selectedServiceSlot;
    this.selectedServiceSlot = {};
    //this.slotsBlacklist = [];

    const businessHours = this.getBusinessHours();
    if (businessHours) {
      this.scheduleStartTime = businessHours.start;
      this.scheduleEndTime = businessHours.end;
    } else {
      this.scheduleStartTime = null;
      this.scheduleEndTime = null;
    }

    this.setAppointmentsAvailabilites();
  }

  setSelectedSlot(serviceId, slot) {
    if (slot) {
      const duration = this.appointments.filter(e => e.service.id === serviceId)
        .map(e => e.service.duration)[0];

      const dateTime = this.moment(`${this.date.format('YYYY-MM-DD')} ${slot.time}`, 'YYYY-MM-DD HH:mm');
      //const startDateTime = this.moment(dateTime);
      dateTime.add(duration, 'm');
      const endTimeStr = dateTime.format('HH:mm');

      this.selectedServiceSlot[`${serviceId}`] = {
        start: slot.time,
        end: endTimeStr,
        duration,
      };
    } else {
      this.selectedServiceSlot[`${serviceId}`] = null;
      delete this.selectedServiceSlot[`${serviceId}`];
    }

    /*this.slotsBlacklist = [];
    let time;
    this.appointments.forEach((a) => {
      const selectedSlot = this.selectedServiceSlot[`${a.service.id}`];
      if (selectedSlot) {
        time = selectedSlot.start;
        while (time < selectedSlot.end) {
          const dateTime = this.moment(time, 'HH:mm');
          dateTime.add(5, 'm');
          time = dateTime.format('HH:mm');
          if (time !== selectedSlot.end) {
            this.slotsBlacklist.push(time);
          }
        }
      }
    });*/

    this.setAppointmentsAvailabilites();
  }

  getHourIndex(hour) {
    let time;
    let timeSplit;
    const length = this.slotsWhitelist.length;
    for (let i = 0; i < length; i++) {
      time = this.slotsWhitelist[i];
      timeSplit = time.split(':');
      if (parseInt(timeSplit[0], 10) === hour) {
        return i;
      }
    }
    return -1;
  }

  setAppointmentsAvailabilites() {
    this.reset();
    const day = this.date.format('YYYY-MM-DD');
    this.now = this.moment(new Date());
    this.isToday = this.date.isSame(this.now, 'day');
    this.currentHour = this.now.hour();

    let serviceId = '';
    let serviceList = [];
    let dayAvailability = null;

    this.appointments.forEach(a => {
      serviceId = `${a.service.id}`;
      serviceList = this.servicesAvailabilities.getServiceAvailabilities(serviceId);
      if (serviceList) {
        dayAvailability = serviceList.availabilities.getSlotsInDay(day);
        if (dayAvailability) {
          this.slots[serviceId] = dayAvailability.getSlots();
        } else {
          this.slots[serviceId] = [];
        }
        this.calculateServiceHoursWhitelist(serviceId);
      }
    });
    this.slotsWhitelist = this.slotsWhitelist.sort();


    this.appointments.forEach(a => {
      serviceId = `${a.service.id}`;
      this.setServiceSlots(serviceId);
    });

    /*const prevSelectedSlots = {};
    this.appointments.forEach((a) => {
      serviceId = `${a.service.id}`;
      const prevSelectedSlot = this.tempSelectedServiceSlot[serviceId];
      if (prevSelectedSlot) {
        const slot = this.serviceSlots[serviceId]
          .filter(item => item.time === prevSelectedSlot.start);
        if (slot.length > 0 && slot[0].available) {
          prevSelectedSlots[serviceId] = slot[0];
          this.tempSelectedServiceSlot[serviceId] = null;
        }
      }
    });

    if (Object.keys(prevSelectedSlots).length > 0) {
      this.appointments.forEach((a) => {
        this.setSelectedSlot(`${a.service.id}`, prevSelectedSlots[`${a.service.id}`]);
      });
    }*/
  }

  calculateServiceHoursWhitelist(serviceId) {
    //const currentTime = this.now.format('HH:mm');
    const columnCount = this.getSlotColumnCount();
    const totalHours = (this.scheduleEndTime - this.scheduleStartTime) * columnCount;

    let hourSection;
    let hour;
    let minute;
    let hourStr;
    let minuteStr;
    let columnIndex;
    let time;

    //console.log(`------- serviceId: ${serviceId} -------`);
    //console.log(this.slots[serviceId]);

    for (let i = 1; i <= totalHours; i++) {
      hourSection = Math.ceil(i / columnCount);
      hour = hourSection + (this.scheduleStartTime - 1);
      columnIndex = MultipleServiceAvailabilities.getSlotColumnIndex(columnCount, i);
      minute = this.getSlotMinute(columnIndex);
      hourStr = Utils.pad(hour, 2);
      minuteStr = Utils.pad(minute, 2);

      if (this.isDynamicHours === true) {
        time = MultipleServiceAvailabilities.getDynamicSlotTime(
          this.slots[serviceId], minute, hourStr, minuteStr
        );

        /*if (this.slotsBlacklist.indexOf(time) < 0) {
          this.whitelistHourSlot(time);
        }*/
        this.whitelistHourSlot(time);
      } else {
        this.whitelistHourSlot(`${hourStr}:${minuteStr}`);
      }
    }


    // Add time the selected service ends
    const selectedSlot = this.selectedServiceSlot[serviceId];
    if (selectedSlot && this.isDynamicHours === true) {
      const splitTime = selectedSlot.end.split(':');
      const hour = parseInt(splitTime[0], 10);
      if (hour < this.scheduleEndTime) {
        this.whitelistHourSlot(selectedSlot.end);
      }
      this.whitelistHourSlot(selectedSlot.start);
      /*this.appointments.forEach((a) => {
        if (serviceId !== `${a.service.id}`)
          const isAvailable = this.slots[serviceId]
            .filter(item => item.time === selectedSlot.end).length > 0;
          if (isAvailable) {
            this.whitelistHourSlot(selectedSlot.end);
          }
      });*/
    }

    // Add time the selected service can start
    if (this.isDynamicHours === true) {
      let selected;
      let dateTime;
      let startTime;
      const appointment = this.appointments.filter(item => `${item.service.id}` === serviceId);
      let service = null;
      if (appointment.length > 0) {
        service = appointment[0].service;
      }
      const interval = this.servicesInterval || 0;

      this.appointments.forEach(item => {
        if (serviceId !== `${item.service.id}`) {
          selected = this.selectedServiceSlot[`${item.service.id}`];
          if (selected && service) {
            dateTime = this.moment(selected.start, 'HH:mm');
            dateTime.add((service.duration * -1) - interval, 'm');
            startTime = dateTime.format('HH:mm');
            if (MultipleServiceAvailabilities.getTimeSlot(
              startTime,
              this.slots[serviceId]
            ).length > 0) {
              this.whitelistHourSlot(startTime);
            }
          }
        }
      });
    }
  }

  // se não tiver slots dinamicas, devemos mostrar a slot de fim do serivço seleccionado?
  // a hora de fim de um slot deve ser apresentada mesmo que
  //    não esteja disponivel em nenhum serviço?
  // ao alterar o dia o slot escolhido é removido. Sim
  // não é possível escolher o mesmo serviço 2x. Perguntar

  whitelistHourSlot(time) {
    if (this.isToday) {
      let hourTime = time;

      if (this.appointments.length === 1) {
        const splitTime = time.split(':');
        const hour = parseInt(splitTime[0], 10);
        hourTime = `${hour + 1}:00`;
      }

      const selectedTime = this.moment(`${hourTime}`, 'HH:mm');
      if (selectedTime < this.now) {
        return;
      }
    }
    // check time was added to whitelist before
    if (this.slotsWhitelist.indexOf(time) < 0) {
      this.slotsWhitelist.push(time);
    }
  }

  setServiceSlots(serviceId) {
    const slotsList = this.slots[serviceId];
    const slotsLength = this.slotsWhitelist.length;
    let time;
    let timeSplit;
    let hour;
    let minute;
    let itemPrice;
    let isAvailable;
    let timeSlot;
    let slot;
    let locked;

    const currentTime = this.now.format('HH:mm');
    const weekday = this.moment(this.date).isoWeekday();

    const appointment = this.appointments.filter(item => `${item.service.id}` === serviceId);
    let service = null;
    let professionalId = null;
    if (appointment.length > 0) {
      service = appointment[0].service;
      professionalId = appointment[0].professional ? appointment[0].professional.id : null;
    }
    let multipleAppointments = true;
    if (this.appointments.length === 1) {
      multipleAppointments = false;
    }

    const serviceIds = this.appointments.map(x => `${x.service.id}`);
    let hasSomeDiscount = false;

    for (let i = 0; i < slotsLength; i++) {
      time = this.slotsWhitelist[i];
      timeSplit = time.split(':');
      hour = parseInt(timeSplit[0], 10);
      minute = parseInt(timeSplit[1], 10);

      timeSlot = MultipleServiceAvailabilities.getTimeSlot(time, slotsList);
      isAvailable = timeSlot.length > 0;

      if (this.isToday === true && currentTime > time) {
        isAvailable = false;
      }

      itemPrice = 0;
      if (isAvailable === true) {
        itemPrice = timeSlot[0].price;
      }

      locked = false;
      // check selected slots availability
      if (this.isLocked(
        serviceId,
        professionalId,
        time,
        service,
        this.servicesInterval || 0
      ) === false) {
        locked = true;
      }

      if (this.discounts) {
        hasSomeDiscount = this.discounts.hasServiceDateTimeDiscounts(serviceIds, weekday, time);
      }

      slot = {
        hour: time, //d.format('HH:mm'),
        time, //d.format('H:mm'),
        time_str: time,
        available: isAvailable,
        locked,
        price: itemPrice,
        hasSomeDiscount,
      };

      if (multipleAppointments) {
        // multiple appointments
        if (i === 0) {
          slot.first = true;
        }
        if (i === slotsLength - 1) {
          slot.last = true;
        }

        if (i !== 0 && minute === 0) {
          slot.firstHour = true;
          if (hour === Constants.SCHEDULE_MORNING
              || hour === Constants.SCHEDULE_AFTERNOON) {
            slot.firstSection = true;

            const len = this.serviceSlots[serviceId].length;
            this.serviceSlots[serviceId][len - 1].lastSection = true;
          }
        }
      } else if (!multipleAppointments) {
        // single appointment
        if (minute === 0) {
          slot.time_str = `${hour}h`;
          slot.first = true;
        } else if (i === slotsLength - 1) {
          slot.last = true;
        } else if (i < slotsLength - 1) {
          const next = this.slotsWhitelist[i + 1];
          const nextHour = parseInt(next.split(':')[0], 10);
          if (hour < nextHour) {
            slot.last = true;
          }
        }
      }

      if (!this.serviceSlots[serviceId]) {
        this.serviceSlots[serviceId] = [];
      }
      this.serviceSlots[serviceId].push(slot);
    }
  }

  static getTimeSlot(time, list) {
    return list.filter(item => item.time === time);
  }

  isLocked(serviceId, professionalId, time, service, interval) {
    let selected;
    let dateTime;
    let startTime;
    let endTime;

    const result = this.appointments.filter(item => {
      if (serviceId !== `${item.service.id}`) {
        selected = this.selectedServiceSlot[`${item.service.id}`];

        //console.log(`Selected lenght: ${Object.keys(this.selectedServiceSlot).length}`);

        if (selected && service) {
          //console.log(item);
          //console.log(this.slots[serviceId]);

          dateTime = this.moment(selected.start, 'HH:mm');
          dateTime.add((service.duration * -1) - interval, 'm');
          startTime = dateTime.format('HH:mm');

          endTime = selected.end;
          if (interval > 0) {
            dateTime = this.moment(endTime, 'HH:mm');
            dateTime.add(interval, 'm');
            endTime = dateTime.format('HH:mm');
          }

          if (SIMULTANEOUS_CATEGORIES.includes(service.category)
            || SIMULTANEOUS_CATEGORIES.includes(item.service.category)) {
            if (!item.professional || !professionalId || item.professional.id !== professionalId) {
              //return false;

              if (time > startTime && time < endTime) {
                // get all available professionals
                // check if there are enough professionals to fill the columns
                // union arrays : a.concat(b.filter((item) => a.indexOf(item) < 0))

                const serviceKeys = Object.keys(this.slots);
                let availableProfessionals = [];
                for (let i = 0; i < serviceKeys.length; i++) {
                  const key = serviceKeys[i];
                  const slotsTime = this.slots[key].filter(x => x.time === time);
                  if (slotsTime.length === 1) {
                    const slot = slotsTime[0];
                    const professionals = slot.professionals;
                    availableProfessionals = availableProfessionals.concat(professionals);
                  }
                }

                // remove duplicates
                availableProfessionals = [...new Set(availableProfessionals)];

                const selectedKeys = Object.keys(this.selectedServiceSlot);
                //let beforeStart;
                const filteredSelectedKeys = selectedKeys.filter(key => {
                  const beforeStart = this.moment(this.selectedServiceSlot[key].start, 'HH:mm');
                  beforeStart.add((this.selectedServiceSlot[key].duration * -1), 'm');
                  return time > beforeStart.format('HH:mm')
                    && time < this.selectedServiceSlot[key].end;
                });

                if (selectedKeys.length < serviceKeys.length
                  && availableProfessionals.length <= filteredSelectedKeys.length
                  && selectedKeys.includes(serviceId) === false) {
                  return true;
                }
                if (selectedKeys.length === serviceKeys.length
                  && availableProfessionals.length <= filteredSelectedKeys.length) {
                  return true;
                }
              }
              return false;
            }
          }

          return time > startTime && time < endTime;
        }
      }
      return false;
    });

    return result.length === 0;
  }


  static getDynamicSlotTime(array, minute, hourStr, minuteStr) {
    let m;
    let mStr;
    let str;
    let filterAvailable;

    let itemHour = `${hourStr}:${minuteStr}`;
    const filter = array.filter(item => item.time === itemHour);
    const isAvailable = filter.length > 0;

    if (isAvailable === false) {
      if (minute === 15) {
        // check minute 10
        m = 10;
        mStr = Utils.pad(m, 2);
        str = `${hourStr}:${mStr}`;
        filterAvailable = array.filter(item => item.time === str);
        if (filterAvailable.length > 0) {
          itemHour = str;
        } else {
          // check minute 5
          m = 5;
          mStr = Utils.pad(m, 2);
          str = `${hourStr}:${mStr}`;
          filterAvailable = array.filter(item => item.time === str);
          if (filterAvailable.length > 0) {
            itemHour = str;
          }
        }
      } else if (minute === 45) {
        // check minute 50
        m = 50;
        mStr = Utils.pad(m, 2);
        str = `${hourStr}:${mStr}`;
        filterAvailable = array.filter(item => item.time === str);
        if (filterAvailable.length > 0) {
          itemHour = str;
        } else {
          // check minute 55
          m = 55;
          mStr = Utils.pad(m, 2);
          str = `${hourStr}:${mStr}`;
          filterAvailable = array.filter(item => item.time === str);
          if (filterAvailable.length > 0) {
            itemHour = str;
          }
        }
      } else if (minute === 20) {
        // check minute 25
        m = 25;
        mStr = Utils.pad(m, 2);
        str = `${hourStr}:${mStr}`;
        filterAvailable = array.filter(item => item.time === str);
        if (filterAvailable.length > 0) {
          itemHour = str;
        }
      } else if (minute === 40) {
        // check minute 35
        m = 35;
        mStr = Utils.pad(m, 2);
        str = `${hourStr}:${mStr}`;
        filterAvailable = array.filter(item => item.time === str);
        if (filterAvailable.length > 0) {
          itemHour = str;
        }
      }
    }

    return itemHour;
  }


  getSlotColumnCount() {
    if (this.business.isMappingTypeDynamic()) {
      return 6;
    }

    if (this.business.isMappingTypeCustom()) {
      const options = this.business.getCustomMappingTypeValues();
      if (options[0] !== 0) {
        return options.length + 1;
      }
      return options.length;
    }

    const type = this.business.getSlotMappingType();
    if (type === '15min') {
      return 4;
    }
    if (type === '10min') {
      return 6;
    }
    if (type === '20min') {
      return 3;
    }
    if (type === '30min') {
      return 2;
    }
    if (type === '60min') {
      return 1;
    }

    return 6;
  }

  getSlotMinute(minuteIndex) {
    if (this.isDynamicHours === true) {
      if (minuteIndex === 0) {
        return 0;
      }
      if (minuteIndex === 1) {
        return 15;
      }
      if (minuteIndex === 2) {
        return 20;
      }
      if (minuteIndex === 3) {
        return 30;
      }
      if (minuteIndex === 4) {
        return 40;
      }
      return 45;
    }

    if (this.business.isMappingTypeCustom()) {
      const options = this.business.getCustomMappingTypeValues();
      if (options[0] !== 0) {
        if (minuteIndex === 0) {
          return 0;
        }
        return options[minuteIndex - 1];
      }
      return options[minuteIndex];
    }

    const type = this.business.getSlotMappingType();
    if (type === '15min') {
      return minuteIndex * 15;
    }
    if (type === '10min') {
      return minuteIndex * 10;
    }
    if (type === '20min') {
      return minuteIndex * 20;
    }
    if (type === '30min') {
      return minuteIndex * 30;
    }
    if (type === '60min') {
      return 0;
    }
    return 45;
  }

  getBusinessHours() {
    const weekday = this.moment(this.date).isoWeekday();
    const scheduleDay = this.business.getSchedule(weekday);

    if (scheduleDay) {
      return {
        start: scheduleDay.scheduleStart,
        end: scheduleDay.scheduleEnd,
      };
    }
    return null;
  }

  getDefaultSlotColumnCount() {
    if (this.isDynamicHours === true) {
      return 6;
    }

    if (this.business.isMappingTypeCustom()) {
      const options = this.business.getCustomMappingTypeValues();
      if (options[0] !== 0) {
        return options.length + 1;
      }
      return options.length;
    }

    const type = this.business.getSlotMappingType();
    if (type === '15min') {
      return 4;
    }
    if (type === '10min') {
      return 6;
    }
    if (type === '20min') {
      return 3;
    }
    if (type === '30min') {
      return 2;
    }
    if (type === '60min') {
      return 1;
    }

    return 6;
  }

  static getSlotColumnIndex(columnCount, index) {
    const remain = index % columnCount;
    return (remain === 0 ? columnCount : remain) - 1;
  }
}


export default MultipleServiceAvailabilities;
