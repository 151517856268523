export default {

  /**
   *
   * @param {*} num
   * @param {*} size
   * @returns number padded with $size zeros
   */
  padNumber(num, size = 2) {
    let s = String(num);
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  },


  /**
   *
   * @param {*} value
   * @returns price formatted
   */
  formatPrice(value, locale) {
    //return `R$ ${value},00`;
    let localeCode = locale;
    if (!localeCode) {
      localeCode = 'pt-BR';
    }
    let currencyCode;
    if (localeCode === 'pt-BR') {
      currencyCode = 'BRL';
    } else if (localeCode === 'pt-PT') {
      currencyCode = 'EUR';
    }

    const result = value.toLocaleString(localeCode, { style: 'currency', currency: currencyCode, maximumFractionDigits: 2 });
    return `${result}`;
  },

  /**
   *
   * @param {*} minutesDuration
   * @returns duration text
   */
  formatDuration(minutesDuration) {
    const hours = Math.floor(minutesDuration / 60);
    const minutes = minutesDuration % 60;

    if (hours === 1 && minutes === 0) {
      return '1h';
    }
    if (hours === 0) {
      return `${minutes} min.`;
    }
    return `${hours}h ${this.padNumber(minutes)}m`;
  },

};
