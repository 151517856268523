
<template>
  <div
    class="row-container"
    @click="handleClick">
    <div
      class="row-container__text-container">
      <span
        :class="classInactive"
        class="row-container__text">
        {{ title }}
      </span>
    </div>

    <span
      :class="classInactive"
      class="row-container__icon">
      <IlmIcon
        icon="keyboard_arrow_right"
        size="xl" />
    </span>

    <div
      v-if="showDivider"
      class="row-divider" />
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'SimpleListRow',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    title: {
      type: String,
      required: true,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    showDivider: Boolean,
    onClick: Function,
  },

  // Computed ********************************
  computed: {
    classInactive() {
      return {
        'row-container--dimmed': this.inactive,
      };
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onClick) return;
      this.onClick();
    },
  },
};
</script>


<style lang="scss" scoped>

.row-container {
  position: relative;
  background: white;
  padding: 12px $padding-section;
  min-height: 37px;
  cursor: pointer;
  color: $theme-3-color;
  //color: black;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  &--dimmed {
    opacity: 0.4;
  }

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }
/*
  &:hover {
    background: $theme-3-bg;
  }

  &:active {
    background: $list-pressed-row-background;
  }
*/
  &__text-container {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__text {
    font-family: $heading-font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2em;
    color: black;
  }

  &__icon {
    font-family: $heading-font-family;
    font-size: 14px;
    line-height: 1.2em;
    color: $theme-color-dim;

    flex: 0 0 auto;
  }

}

.row-divider {
  position: absolute;
  bottom: 0;
  left: $padding-section;
  right: $padding-section;
  height: 1px;
  background: $theme-list-divider-color;
}

</style>
