
<template>
  <div>
    <div
      v-if="showBusinessName"
      class="navbar-bar">
      <h3
        class="navbar-bar__title">
        {{ barTitle }}
      </h3>
    </div>
    <div
      :class="navbarClasses"
      class="navbar-container">
      <div
        :class="titleClassNames"
        class="navbar-container__title-container">
        <transition name="fade">
          <h1
            v-if="title"
            class="navbar-container__title-container__title"
            v-html="titleText" />
        </transition>
        <transition name="fade">
          <h2
            v-if="subtitle"
            class="navbar-container__title-container__subtitle"
            v-html="subtitle" />
        </transition>
      </div>
      <!--div class="navbar-container__breadcrumb-container">
        <div class="navbar-container__breadcrumb-container__breadcrumb">
          <IlmIcon
            :class="step1Class"
            icon="smartphone"
            size="lg"
            class="navbar-container__breadcrumb-container__breadcrumb__item"/>

          <div
            :class="step1SeparatorClass"
            class="navbar-container__breadcrumb-container__breadcrumb__arrow">
            <div class="navbar-container__breadcrumb-container__breadcrumb__arrow__line"/>
            <div class="navbar-container__breadcrumb-container__breadcrumb__arrow__point"/>
          </div>

          <IlmIcon
            :class="step2Class"
            icon="fiber_pin"
            size="lg"
            class="navbar-container__breadcrumb-container__breadcrumb__item" />

          <div
            :class="step2SeparatorClass"
            class="navbar-container__breadcrumb-container__breadcrumb__arrow">
            <div class="navbar-container__breadcrumb-container__breadcrumb__arrow__line"/>
            <div class="navbar-container__breadcrumb-container__breadcrumb__arrow__point"/>
          </div>

          <IlmIcon
            :class="step3Class"
            icon="check"
            size="lg"
            class="navbar-container__breadcrumb-container__breadcrumb__item" />
        </div>
      </div-->
      <IlmButtonIcon
        v-if="canShowBack"
        :on-click="handleBackClick"
        icon="arrow_back"
        prevent-default
        class="navbar-container__back" />
      <IlmButton
        v-if="showOk"
        ref="btn_ok"
        :is-working="isLoading"
        :on-click="handleOkClick"
        :disabled="disableBtnOk"
        :label="$t('modals.buttons.ok')"
        icon="arrow_forward"
        theme="success"
        class="navbar-container__ok" />
    </div>
  </div>
</template>


<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';

export default {
  name: 'NavbarPhoneBreadcrumb',

  // Components ********************************
  components: {
    IlmButton,
    IlmButtonIcon,
  },

  // Properties ********************************
  props: {
    title: String,
    subtitle: String,
    step: {
      type: Number,
      default: 0,
    },
    canShowTopBar: {
      type: Boolean,
      default: true,
    },
    showOk: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showShadow: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disableBtnOk: {
      type: Boolean,
      default: true,
    },
    onBack: Function,
    onOk: Function,
  },

  data() {
    return {
      barTitle: null,
      windowWidth: null,
    };
  },

  // Computed ********************************
  computed: {
    titleText() {
      return this.title;
    },
    titleClassNames() {
      const classNames = {
        'navbar-container__title-container--show-nav-back': this.canShowBack,
      };
      return classNames;
    },
    navbarClasses() {
      const classNames = {
        'navbar-container--shadow': this.showShadow,
      };

      return classNames;
    },
    showBusinessName() {
      /*
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return false;
      }
      return this.$store.getters.isExternalSource && this.canShowTopBar;*/
      return false;
    },
    canShowBack() {
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (body.classList.contains('os--ios') && fbMessenger.inFrame) {
        return false;
      }
      if (this.$store.getters.isExternalSource) {
        return true;
      }

      return this.showBack;
    },
    step1Class() {
      return {
        'navbar-container__breadcrumb-container__breadcrumb__item--active': this.step >= 0,
      };
    },
    step2Class() {
      return {
        'navbar-container__breadcrumb-container__breadcrumb__item--active': this.step >= 1,
      };
    },
    step3Class() {
      return {
        'navbar-container__breadcrumb-container__breadcrumb__item--active': this.step >= 2,
      };
    },
    step1SeparatorClass() {
      return {
        'navbar-container__breadcrumb-container__breadcrumb__arrow--active': this.step >= 1,
      };
    },
    step2SeparatorClass() {
      return {
        'navbar-container__breadcrumb-container__breadcrumb__arrow--active': this.step >= 2,
      };
    },
  },

  mounted() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.business && auth.business.name) {
      this.barTitle = auth.business.name;
    }
    const self = this;
    this.$nextTick(function () {
      self.windowWidth = document.body.clientWidth;
      window.addEventListener('resize', self.handleResize);
    });
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },
    handleBackClick() {
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },
    handleOkClick() {
      if (this.onOk) {
        this.onOk();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.navbar-bar {
  background: white;
  border-bottom: 1px solid $theme-list-divider-color;

  &__title {
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin: 0;
  }
}

.navbar-container {
  position: relative;
  display: block;
  padding: 4px 0;
  min-height: 60px;
  background: white;
  margin-bottom: 16px;
  transition: all 0.2s ease-in-out 0s;
  text-align: center;
  box-sizing: border-box;

  /*&--shadow {
    //box-shadow: $box-shadow-bottom;
  }*/

  &__breadcrumb-container {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__breadcrumb {
      width: 100%;
      padding: 0 60px;
      max-width: 280px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
        background: $theme-border;
        color: white;
        border-radius: 50%;
        padding: 8px;
        margin: 0 10px;
        flex: 0 0 auto;

        &--active {
          background: $theme-success;
        }
      }

      &__arrow {
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__line {
          width: 100%;
          background: $theme-border;
          height: 2px;
          float:left;
        }
        &__point {
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid $theme-border;
          float: right;
        }

        &--active &__line {
          background: $theme-success;
        }
        &--active &__point {
          border-left: 6px solid $theme-success;
        }
      }
    }
  }

  &__title-container {
    display: block;
    padding: 10px $padding-section;
    max-width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;

    &--show-nav-back {
      padding: 0 76px;
    }

    &__title {
      //color: white;
      color: $theme-3-color;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 1.2em;
      margin: 0;
      padding-bottom: 4px;
      padding-top: 4px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      //color: white;
      color: black;
      font-weight: normal;
      font-size: 22px;
      text-align: center;
      line-height: 1.2em;
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__back {
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 24px;
    height: 40px;
    background: white;
    color: black !important;
    border: 1px solid $theme-list-divider-color;
  }

  &__ok {
    position: absolute;
    right: 12px;
    top: 8px;
    bottom: 8px;
    padding: 10px 40px 10px 20px;
    line-height: 1;
    box-sizing: border-box;
  }
}

</style>
