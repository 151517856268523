<template>
  <div
    class="card-list">
    <h1 class="card-list__title">
      {{ titleText }}
    </h1>
    <div
      ref="slider"
      class="swiper-container">
      <div class="swiper-wrapper">
        <!-- It is important to set "left" style prop on every slide -->
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="swiper-slide">
          <CardItem
            :card="card"
            :index="index"
            :on-click="handleCardClick" />
        </div>
        <div
          :key="cards.length"
          class="swiper-slide">
          <CardItem
            :index="cards.length"
            :on-click="handleCardClick" />
        </div>
      </div>
      <!-- Add Arrows -->
      <div
        class="swiper-button-next" />
      <div
        class="swiper-button-prev" />
      <!-- Add Pagination -->
      <div class="swiper-pagination" />
    </div>
    <div class="card-list__footer">
      <IlmButton
        :on-click="handleOkClick"
        :disabled="slider && slider.isEnd"
        :label="$t('button.payment_choose_card')"
        theme="success block"
        class="card-list__footer__ok" />
    </div>
  </div>
</template>

<script>
// https://swiperjs.com
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CardItem from '@/components/Payments/CardItem.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';

export default {
  name: 'CardList',

  // Components ********************************
  components: {
    CardItem,
    IlmButton,
  },

  // Props ********************************
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    showNewCard: {
      type: Boolean,
      default: false,
    },
    customer: Object,
    onAddCard: Function,
    onCardSelected: Function,
  },

  data() {
    return {
      cards: [],
      height: 0,

      slider: null,
      swipeOptions: {
        direction: 'horizontal',
        centeredSlides: true,
        spaceBetween: 8,
        //slidesOffsetBefore: 18,
        //slidesOffsetAfter: 18,
        grabCursor: true,
        loop: false,
        slidesPerView: 1.4,
        slidesPerGroup: 1,
        iOSEdgeSwipeDetection: true,
        threshold: 5,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 8,
          hideOnClick: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          // when window width is >= 300px
          300: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          // when window width is >= 360px
          360: {
            slidesPerView: 1.35,
            spaceBetween: 0,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1.4,
            spaceBetween: 0,
          },
          // when window width is >= 540px
          540: {
            slidesPerView: 1.6,
            spaceBetween: 0,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1.6,
            spaceBetween: 4,
          },
        },
      },
    };
  },

  // Computed ********************************
  computed: {
    cardStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    titleText() {
      if (this.slider && this.slider.isEnd) {
        return 'Adicionar um novo cartão';
      }
      return 'Escolha o cartão a usar';
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.client) {
      //this.cards = auth.client.cards;
      this.cards = auth.getClientActiveCards();
    }
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.client) {
      //this.cards = auth.client.cards;
      this.cards = auth.getClientActiveCards();
    }

    this.slider = new Swiper(this.$refs.slider, this.swipeOptions);
    //this.slider.init();
    const self = this;
    this.$nextTick(() => {
      if (self.customer) {
        if (self.showNewCard) {
          self.slider.slideTo(self.cards.length);
        } else {
          const selectedCardUid = self.customer.card.uid;
          let card;
          for (let i = 0; i < self.cards.length; i++) {
            card = self.cards[i];
            if (card.uid === selectedCardUid) {
              self.slider.slideTo(i);
              break;
            }
          }
        }
      }
    });
  },

  destroyed() {
  },


  // Methods ********************************
  methods: {
    slideClasses(index) {
      if (index === this.slider.activeIndex) {
        return {
          'swiper-slide--active': true,
        };
      }
      return {};
    },

    handleCardClick(index) {
      if (this.slider.isEnd) {
        if (index === this.cards.length) {
          if (this.onAddCard) {
            this.onAddCard();
          }
        } else {
          this.slider.slideTo(index);
        }
      } else if (index !== this.slider.activeIndex) {
        this.slider.slideTo(index);
      } else if (this.onCardSelected) {
        this.onCardSelected(this.cards[this.slider.activeIndex]);
      }
    },
    handleOkClick() {
      if (this.onCardSelected) {
        this.onCardSelected(this.cards[this.slider.activeIndex]);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.swiper-container {
  //height: 60px;
  overflow-x: hidden;
}
.swiper-wrapper {
  //height: 100%;
  display: flex;
  //overflow-x: scroll;
}
.swiper-slide {
  flex-shrink: 0;
  //padding-left: $padding-section;
  box-sizing: border-box;

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: scale(0.9, 0.9);
  opacity: 0.5;
}
.swiper-slide-active {
  transform: unset;
  opacity: 1;
}
.swiper-pagination {
  position: relative;
  bottom: initial;
  margin-top: 20px;
}
.swiper-pagination ::v-deep .swiper-pagination-bullet {
  background: white;
  border: 2px solid $theme-border;
  opacity: 1;
}
.swiper-pagination ::v-deep .swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
  background: $theme-border;
}
.swiper-button-disabled {
  display: none;
}
.swiper-button-next, .swiper-button-prev {
  color: black;
  background: white;
  border-radius: 50%;
  //border: 2px solid $theme-border;
  width: 42px;
  height: 42px;
  outline: none;
  box-shadow: 0px 0px 12px 1px rgba(156,156,156,0.5);
  transform: translateY(-16px);

  /*transition: box-shadow 0.3s ease;
  @media(hover) {
    &:hover {
      box-shadow: none;
    }
  }*/

  &:after {
    font-size: 14px;
    font-weight: bold;
  }
}


@media screen and (min-width: 300px) {
  .swiper-button-prev {
    left: 4px;
  }
  .swiper-button-next {
    right: 4px;
  }
}
@media screen and (min-width: 360px) {
  .swiper-button-prev {
    left: 6%;
  }
  .swiper-button-next {
    right: 6%;
  }
}
@media screen and (min-width: 480px) {
  .swiper-button-prev {
    left: 8%;
  }
  .swiper-button-next {
    right: 8%;
  }
}
@media screen and (min-width: 540px) {
  .swiper-button-prev {
    left: 13.5%;
  }
  .swiper-button-next {
    right: 13.5%;
  }
}
@media screen and (min-width: $min-screen-width) {
  .swiper-button-prev {
    left: 78px;
  }
  .swiper-button-next {
    right: 78px;
  }
}

.card-list {

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 8px $padding-section;
    margin-top: 0;
  }

  &__footer {
    margin: $padding-section;
    margin-top: 20px;
    //padding-top: $padding-section;
    //border-top: 1px solid $theme-list-divider-color;
  }
}

</style>
