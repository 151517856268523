import Suggestion from './suggestion';
//import { stripDiacritics } from '@/utils/matcher';

class SuggestionList {
  constructor(data) {
    this.suggestions = [];
    this.clientSuggestions = [];
    if (data) {
      const self = this;
      let uniqueServiceIds = [];
      let uniqueClientServiceIds = [];

      /*
      const array = data.sort(function compare(a, b) {
        const firstCount = a.docCount;
        const secondCount = b.docCount;

        if (firstCount < secondCount) {
          return 1;
        }
        if (firstCount > secondCount) {
          return -1;
        }

        const first = stripDiacritics(a.name);
        const second = stripDiacritics(b.name);

        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return 0;
      });
      */

      data.forEach(function (element) {
        const obj = new Suggestion(element);
        if (obj.groupType === 'client') {
          if (obj.count === 1 && uniqueClientServiceIds.includes(obj.services[0].id) === false) {
            uniqueClientServiceIds.push(obj.services[0].id);
            self.clientSuggestions.push(obj);
          } else if (obj.count > 1) {
            self.clientSuggestions.push(obj);
            /*obj.services.forEach(function (service) {
              uniqueClientServiceIds.push(service.id);
            });*/
          }
        } else if (obj.groupType === 'business' || obj.groupType === 'tags') {
          if (obj.count === 1 && uniqueServiceIds.includes(obj.services[0].id) === false) {
            uniqueServiceIds.push(obj.services[0].id);
            self.suggestions.push(obj);
          } else if (obj.count > 1) {
            self.suggestions.push(obj);
            /*obj.services.forEach(function (service) {
              uniqueServiceIds.push(service.id);
            });*/
          }
        }
      });
      uniqueServiceIds = [];
      uniqueClientServiceIds = [];
    }
  }

  getItems() {
    return this.suggestions;
    /*
    return this.suggestions.sort(function compare(a, b) {
      const firstScore = a.score;
      const secondScore = b.score;

      if (firstScore < secondScore) {
        return 1;
      }
      if (firstScore > secondScore) {
        return -1;
      }

      const first = stripDiacritics(a.name);
      const second = stripDiacritics(b.name);

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });
    */
  }

  getClientItems() {
    return this.clientSuggestions;
  }

  print() {
    this.suggestions.forEach(element => console.log(`optionType: ${element.optionType} | optionKey: ${element.optionKey} | count: ${element.count} | name: ${element.getName()}`));
  }
}

export default SuggestionList;
