
<template>
  <div
    :class="selectedClassNames"
    class="row-container"
    @click="handleClick">

    <div
      v-if="item.profile"
      class="row-container__profile">
      <div
        class="row-container__profile__icon">
        <span
          class="row-container__profile__icon__text">
          {{ item.profile }}
        </span>
        <ImageFade
          v-if="item.profileUrl"
          :src="item.profileUrl"
          class="row-container__profile__icon__img" />
      </div>
    </div>

    <div
      v-else-if="showIcon"
      class="row-container__profile-empty">
      <IlmIcon
        class="row-container__profile-empty__icon"
        icon="person"
        size="xl" />
    </div>

    <div
      class="row-container__text-container">
      <span
        :class="selectedClassNames"
        class="row-container__text"
        v-html="highlight(item.name)" />
      <span
        v-if="subtitleText"
        class="row-container__subtitle">
        {{ subtitleText }}
      </span>
    </div>

    <span
      :class="selectedClassNames"
      class="row-container__subtext">
      <IlmIcon
        v-if="selected"
        icon="check"
        size="xl" />
    </span>

    <div
      v-if="showDivider"
      class="row-divider" />
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import ImageFade from '@/components/ImageFade.vue';
import { stripDiacritics } from '../../utils/matcher.js';

export default {
  name: 'ItemListRow',

  // Components ********************************
  components: {
    IlmIcon,
    ImageFade,
  },

  // Properties ********************************
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    searchText: String,
    subtitleText: String,
    showDivider: Boolean,
    onClick: Function,
  },

  // Computed ********************************
  computed: {
    selectedClassNames() {
      return this.selected ? 'row-container--selected' : '';
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onClick) return;
      this.onClick(this.item);
    },
    highlight(text) {
      if (!this.searchText || this.searchText.length < this.$constants.MIN_SEARCH_STRING_LENGTH) {
        return `<span class="row-container__highlighted">${text}</span>`;
      }
      return stripDiacritics(text).replace(new RegExp(stripDiacritics(this.searchText), 'gi'),
        '<span class="row-container__highlighted">$&</span>');
      //return text.replace(new RegExp(this.searchText, 'gi'),
      //'<span class="row-container__highlighted">$&</span>');
    },
  },
};
</script>


<style lang="scss" scoped>

.row-container {
  position: relative;
  background: white;
  padding: 12px $padding-section;
  min-height: 37px;
  cursor: pointer;
  color: $theme-3-color;
  //color: black;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }
/*
  &:hover {
    background: $theme-3-bg;
  }

  &:active {
    background: $list-pressed-row-background;
  }
*/

  &__profile-empty {
    position: relative;
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    margin-right: $padding-section;
    border: 2px dashed $theme-color-dim;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      text-align: center;
      color: $theme-color-dim;
      padding-left: 1px;
      padding-bottom: 2px;
      font-size: 34px;
    }
  }

  &__profile {
    position: relative;
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    margin-right: $padding-section;
    border: 2px solid $theme-color-dim;
    padding: 2px;
    border-radius: 50%;

    &__icon {
      width: 100%;
      position: relative;
      padding-top: 100%; /* 1:1 Aspect Ratio */
      background-color: $theme-color-dim;
      border-radius: 50%;

      &__img {
        position:  absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      &__text {
        position:  absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 22px;
      }
    }
  }

  &--selected ::v-deep &__profile {
    border-color: $theme-ilm-selection;
  }


  &__text-container {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__text {
    font-family: $heading-font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2em;
  }
  &__subtitle {
    font-family: $heading-font-family;
    font-size: 14px;
    line-height: 1;
    padding-top: 2px;
  }

  &__subtext {
    font-family: $heading-font-family;
    font-size: 14px;
    line-height: 1.2em;

    flex: 0 0 auto;
  }

  &__text ::v-deep &__highlighted {
    color: black;
  }
}


.row-container--selected, .row-container--selected ::v-deep .row-container__highlighted {
  //color: $theme-ilm-color !important;
  color: black !important;
  font-weight: bold;
  color: $theme-ilm-selection !important;
}


.row-divider {
  position: absolute;
  bottom: 0;
  left: $padding-section;
  right: $padding-section;
  height: 1px;
  background: $theme-list-divider-color;
}

</style>
