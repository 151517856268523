
<template>
  <div
    class="row-container"
    @click="handleClick">
    <div
      :class="element.getState()"
      class="row-container__col1">
      {{ element.time }}
    </div>
    <div class="row-container__col2">
      <div class="row-container__col2__container">
        <div
          v-if="element.professional"
          class="row-container__title-container">
          <span
            :class="element.getState()"
            class="row-container__title">
            {{ element.service.name }}
          </span>
          <!--span class="row-container__state">
            <ilm-state-badge :state="element.getState()" />
          </span-->
        </div>
        <div
          v-if="element.professional"
          class="row-container__category-container">
          <span
            :class="element.getState()"
            class="row-container__category">
            {{ element.professional.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="row-container__col3">
      <span class="row-container__state">
        <ilm-state-badge :state="element.getState()" />
      </span>
      <!--IlmIcon
        class="row-container__col3__arrow"
        size="xl"
        icon="keyboard_arrow_right" /-->
    </div>
    <div
      v-if="showDivider"
      class="row-container__divider" />
  </div>
</template>


<script>
import IlmStateBadge from '@/components/IlmStateBadge.vue';

export default {
  name: 'AppointmentListRow',

  // Components ********************************
  components: {
    IlmStateBadge,
  },


  // Properties ********************************
  props: {
    element: {
      type: Object,
      required: true,
    },
    onClick: Function,
    showDivider: Boolean,
  },

  methods: {
    handleClick() {
      if (!this.onClick) return;
      this.onClick(this.element);
    },
  },

};
</script>


<style lang="scss" scoped>

.row-container {
  position: relative;
  display: flex;
  //background: white;
  padding: 10px 10px 10px $padding-section;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }
  /*
  &:active {
    background: $list-pressed-row-background;
  }*/

  &__col1 {
    font-family: $heading-font-family;
    font-size: 18px;
    color: black;
    line-height: 1.2em;

    padding-right: $padding-section;

    display: inline-flex;
    flex: 0 0 auto;

    align-self: flex-start;
  }

  &__col1.canceled {
    color: $theme-3-color;
  }

  &__col2 {
    display: inline-flex;
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    &__container {
      width: 100%;
    }
  }

  &__col3 {
    padding-left: $padding-section;

    display: inline-flex;
    flex: 0 0 auto;

    &__arrow {
      color: $theme-3-color;
    }
  }

  &__title {
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 18px;
    color: black;
    margin: 0;
    line-height: 1.2em;

    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__title.canceled {
    color: $theme-3-color;
    text-decoration: line-through;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__category-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;

    margin-top: 6px;
  }

  &__category {
    display: inline-flex;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 12px;
    color: $theme-2-color;
    line-height: 1.2em;

    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__category.canceled {
    color: $theme-3-color;
  }

  &__state {
    display: inline-flex;
    font-family: $heading-font-family;
    font-size: 12px;
    color: $theme-3-color;
    line-height: 1.2em;
    margin: 0;
    margin-top: 5px;
    margin-right: 6px;

    flex: 0 0 auto;
  }

  &__divider {
    position: absolute;
    display: block;
    height: 1px;
    background: $theme-list-divider-color;
    bottom: 0;
    left: $padding-section;
    right: $padding-section;
  }
}


</style>
