
<template>
  <div
    :class="classNames"
    class="section-fixed-container">
    <div class="section-fixed-container__title-container">
      <h3
        v-if="element.section"
        class="section-fixed-container__title-container__text">
        {{ element.section }}
      </h3>
      <span
        v-if="isEditable"
        class="section-fixed-container__title-container__clear">
        <IlmButton
          :on-click="handleDeleteClick"
          label="Eliminar"
          icon-size="md"
          icon="close"
          theme="simple"
          class="section-fixed-container__title-container__clear__icon" />
      </span>
    </div>
    <div class="section-fixed-container__name-container">
      <h2 class="section-fixed-container__name-container__text">
        {{ element.name }}
      </h2>
      <span
        v-if="isEditable"
        class="section-fixed-container__name-container__edit">
        <IlmButton
          :on-click="handleEditClick"
          t-key="button.edit"
          icon-size="md"
          icon="mode_edit"
          theme="simple"
          class="section-fixed-container__name-container__edit__icon" />
      </span>
    </div>

    <AlertDestructive
      v-if="alertShowing"
      :title="$t('alerts.alert_delete_note_title')"
      :message="$t('alerts.alert_delete_note_message')"
      :btn-cancel="$t('alerts.alert_delete_note_no')"
      :btn-destructive="$t('alerts.alert_delete_note_yes')"
      @closed="onAlertDismissed"
      @cancel="onAlertDismissed"
      @ok="deleteNote" />

  </div>
</template>


<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';

export default {
  name: 'SectionNotes',

  components: {
    IlmButton,
    AlertDestructive,
  },

  // Properties ********************************
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    onItemSelected: Function,
    onItemDeleted: Function,
    onItemEdit: Function,
  },

  // Data ********************************
  data() {
    return {
      alertShowing: false,
      alertCallback: Function,
    };
  },

  // Computed ********************************
  computed: {
    classNames() {
      const classNames = {
        'section-fixed-container--clickable': this.onItemSelected,
      };
      return classNames;
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onItemSelected) return;
      this.onItemSelected(this.element);
    },
    handleDeleteClick() {
      this.alertShowing = true;
    },
    handleEditClick() {
      if (!this.onItemEdit) return;
      this.onItemEdit(this.element);
    },
    deleteNote() {
      this.onAlertDismissed();
      if (!this.onItemDeleted) return;
      this.onItemDeleted(this.element);
    },
    onAlertDismissed() {
      this.alertShowing = false;
    },
  },
};
</script>


<style lang="scss" scoped>

.section-fixed-container {
  position: relative;
  display: block;
  background: white;
  padding: 10px $padding-section;
  border-top: 1px solid $theme-list-divider-color;
  border-bottom: 1px solid $theme-list-divider-color;

/*
  &--clickable {
    cursor: pointer;
    touch-action: auto;
    -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  }
  @media (hover) {
    &--clickable:hover {
      background: $list-pressed-row-background;
    }
  }
*/

  /*&__section-title-container {
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 12px;
    color: $theme-2-color;
    margin: 0 0 $padding-section 0;
    text-transform: uppercase;
  }*/

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding-top: 6px;

    &__text {
      display: inline-flex;
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 12px;
      color: $theme-2-color;
      margin: 0 0 28px 0;
      text-transform: uppercase;
      line-height: 1.2em;

      padding-right: 10px;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;

      &--icon {
        margin-right: 4px;
        font-size: 14px;
      }

      &--saved {
        font-size: 20px;
        margin-top: -6px;
        color: $theme-success;
      }
    }

    &__clear {
      display: inline-flex;
      margin: 0;

      flex: 0 0 auto;

      &__icon {
        color: red !important;
        font-size: 12px;
        position: absolute;
        padding: 8px 30px 8px 8px;
        right: 2px;
        top: 10px;
      }
    }
  }


  &__name-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    /*padding-top: 10px;*/

    &__text {
      display: inline-flex;
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 18px;
      color: black;
      margin: 0;
      line-height: 1.2em;

      padding-right: 20px;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
    }

    &__edit {
      display: inline-flex;
      font-family: $heading-font-family;
      font-size: 16px;
      color: black;
      margin: 0;

      flex: 0 0 auto;

      &__icon {
        font-size: 12px;
        padding: 8px 30px 8px 8px;
        right: -14px;
        top: -4px;
      }
    }
  }


  &__category-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;

    margin-top: 4px;
  }

  &__category {
    display: inline-flex;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 12px;
    color: $theme-2-color;
    line-height: 1.2em;

    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__subcategory {
    display: inline-flex;
    font-family: $heading-font-family;
    font-size: 12px;
    color: $theme-3-color;
    line-height: 1.2em;
    margin: 0;

    flex: 0 0 auto;
  }

}


</style>
