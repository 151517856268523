import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import BusinessSchedule from './businessSchedule';

const CUSTOM_SLOT_SETTING_KEY = 'custom:';

class BusinessData {
  constructor(obj) {
    this.id = obj.id;
    this.timezone = obj.timezone;
    this.locale = obj.locale;
    this.name = obj.brand;
    this.category = obj.category;
    this.phoneCountry = obj.phoneCountry;
    this.phoneNumber = obj.phoneNumber;

    this.addressCity = obj.addressCity;
    this.addressNeighbourhood = obj.addressNeighbourhood;
    this.addressPostal = obj.addressPostal;
    this.addressState = obj.addressState;
    this.addressStreet = obj.addressStreet;
    this.addressCountry = obj.addressCountry;

    this.addressLatitude = obj.addressLatitude;
    this.addressLongitude = obj.addressLongitude;

    /* SETTINGS */

    this.slotsMappingType = obj.settings.b2cSlotsType ?? obj.settings.b2CSlotsType;
    this.advanceTime = obj.settings.b2cAdvanceTime ?? obj.settings.b2CAdvanceTime;
    this.activeModules = obj.settings.b2cActiveModules ?? obj.settings.b2CActiveModules;
    this.minTimeToCancel = obj.settings.b2cMinTimeToCancel ?? obj.settings.b2CMinTimeToCancel;
    this.minTimeToUpdate = obj.settings.b2cMinTimeToUpdate ?? obj.settings.b2CMinTimeToUpdate;
    this.minInterval = obj.settings.b2cMinInterval ?? obj.settings.b2CMinInterval;

    /* SCHEDULES */

    this.weekdaysSchedule = [];
    if (obj.schedules) {
      const self = this;
      obj.schedules.forEach(function (element) {
        self.weekdaysSchedule.push(new BusinessSchedule(element));
      });
    }

    this.workingSchedule = [];
    if (obj.publicSchedules) {
      const self = this;
      obj.publicSchedules.forEach(function (element) {
        self.workingSchedule.push(new BusinessSchedule(element));
      });
    }
  }

  getPhoneFormatted() {
    const { phoneCountry, phoneNumber } = this;
    if (phoneCountry && phoneNumber) {
      const formatter = new AsYouType();
      const fullPhone = formatter.input(`+${phoneCountry}${phoneNumber}`);
      return fullPhone;
    }
    return phoneNumber;
  }

  getPhone() {
    const { phoneCountry, phoneNumber } = this;
    if (phoneCountry && phoneNumber) {
      return `+${phoneCountry}${phoneNumber}`;
    }
    return phoneNumber;
  }

  getWeekSchedule() {
    return this.weekdaysSchedule.sort(this.orderWeekday);
  }

  getWorkingSchedule() {
    return this.workingSchedule.sort(this.orderWeekday);
  }

  getSchedule(day) {
    // week indexes: 1 - 7 (starts on monday)
    // moment.isoWeekday()
    const schedule = this.workingSchedule.length === 0
      ? this.weekdaysSchedule : this.workingSchedule;
    const weekdayFilter = schedule.filter(function (item) {
      return item.weekday === day;
    });
    if (weekdayFilter.length > 0) {
      return weekdayFilter[0];
    }
    return null;
  }

  getUnavailableWeekdays() {
    const schedule = this.workingSchedule.length === 0
      ? this.weekdaysSchedule : this.workingSchedule;
    return schedule.filter(function (item) {
      return item.hasSchedule() === false;
    }).map(item => item.weekday);
  }


  orderWeekday = function compare(a, b) {
    const first = a.weekday;
    const second = b.weekday;
    if (first < second) {
      return -1;
    } if (first > second) {
      return 1;
    }
    return 0;
  }


  getSlotMappingType() {
    return this.slotsMappingType ? this.slotsMappingType : 'dynamic';
  }

  isMappingTypeDynamic() {
    return this.getSlotMappingType() === 'dynamic';
  }

  isMappingTypeCustom() {
    return this.getSlotMappingType().lastIndexOf(CUSTOM_SLOT_SETTING_KEY, 0) === 0;
  }

  getCustomMappingTypeValues() {
    const r = this.getSlotMappingType().replace(CUSTOM_SLOT_SETTING_KEY, '');
    const d = r.split(',')
      .map(s => parseInt(s, 10))
      .sort(this.orderNumbers);
    return d;
  }

  orderNumbers = function compare(a, b) {
    return a - b;
  }


  //bookings,payments,covid19
  hasBookingsActive() {
    if (this.activeModules && this.activeModules !== '') {
      const modules = this.activeModules.split(',');
      return modules.filter(function (item) {
        return item.trim().toLowerCase() === 'bookings';
      }).length > 0;
    }
    return false;
  }

  hasPaymentsActive() {
    if (this.activeModules && this.activeModules !== '') {
      const modules = this.activeModules.split(',');
      return modules.filter(function (item) {
        return item.trim().toLowerCase() === 'payments';
      }).length > 0;
    }
    return false;
  }

  hasCovidActive() {
    if (this.activeModules && this.activeModules !== '') {
      const modules = this.activeModules.split(',');
      return modules.filter(function (item) {
        return item.trim().toLowerCase() === 'covid19';
      }).length > 0;
    }
    return false;
  }
}

export default BusinessData;
