import Appointment from '../models/appointment';
import AppointmentList from '../models/appointmentList';

export default {
  SET_TOKEN: (state, value) => {
    state.token = value;
  },
  SET_MESSENGER: (state, value) => {
    state.source.fbMessenger.isMessenger = value;
  },
  SET_MESSENGER_FRAME: (state, value) => {
    state.source.fbMessenger.inFrame = value;
  },
  SET_SOURCE_DESCRIPTION: (state, value) => {
    state.source.description = value;
  },

  SET_BUSINESS_CLOSED_WEKKDAYS: (state, value) => {
    state.businessClosedWeekdays = value;
  },

  SET_AUTH: (state, value) => {
    state.auth = value;
  },
  UPDATE_AUTH: (state, value) => {
    if (state.auth) {
      state.auth.client = value.client;
      state.auth.token = value.token;
    } else {
      state.auth = value;
    }
  },

  SET_CAN_RESUME_VERIFICATION: (state, value) => {
    state.canResumeVerification = value;
  },

  SET_CLIENT_SUGGESTIONS: (state, value) => {
    state.clientSuggestions = value;
  },
  SET_SERVICE_SEARCH: (state, text) => {
    state.serviceSearch = text;
  },
  SET_SELECTED_CATEGORY: (state, category) => {
    state.selectedCategory = category;
  },

  RESET_APPOINTMENT: state => {
    state.appointment = null;
    state.editAppointment = null;
    state.professionals = [];

    state.appointments = new AppointmentList();
  },

  SET_APPOINTMENT_SERVICE: (state, service) => {
    if (!state.appointment) {
      state.appointment = new Appointment();
    }
    state.appointment.service = service;
  },
  SET_APPOINTMENT_PROFESSIONAL: (state, professional) => {
    if (!state.appointment) {
      state.appointment = new Appointment();
    }
    state.appointment.professional = professional;
  },

  SET_APPOINTMENT_PARAMS: (state, params) => {
    const date = params.date;
    const day = params.day;
    const time = params.time;
    const professional = params.professional;
    const notes = params.notes;
    const key = params.key;
    const discount = params.discount;
    const isLocked = params.getIsLocked();
    const aState = params.getState();

    if (!state.appointment) {
      state.appointment = new Appointment();
    }

    state.appointment.date = date;
    state.appointment.day = day;
    state.appointment.time = time;
    state.appointment.professional = professional;
    state.appointment.notes = notes;
    state.appointment.key = key;
    state.appointment.isLocked = isLocked;
    state.appointment.state = aState;
    state.appointment.discount = discount;
  },

  SET_APPOINTMENT_NOTE: (state, note) => {
    if (!state.appointment) {
      state.appointment = new Appointment();
    }
    state.appointment.notes = note;
  },

  SET_EDIT_APPOINTMENT: (state, appointment) => {
    state.editAppointment = appointment;
  },

  SET_APPOINTMENT: (state, appointment) => {
    state.appointment = new Appointment();
    state.appointment.key = appointment.key;
    state.appointment.date = appointment.date;
    state.appointment.day = appointment.day;
    state.appointment.time = appointment.time;
    state.appointment.service = appointment.service;
    state.appointment.professional = appointment.professional;
    state.appointment.notes = appointment.notes;
    state.appointment.discount = appointment.discount;
    state.appointment.state = appointment.getState();
    state.appointment.isLocked = appointment.getIsLocked();
    state.appointment.discount = appointment.discount;
  },

  SET_APPOINTMENTS: (state, appointments) => {
    state.appointments = appointments;
  },

  SET_DISCOUNTS: (state, discounts) => {
    state.discounts = discounts;
  },

  SET_PROFESSIONALS: (state, value) => {
    state.professionals = value;
  },

  SET_CUSTOMER: (state, obj) => {
    state.customer = obj;
  },
};
