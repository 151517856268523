class MessengerContext {
  constructor(obj) {
    this.tid = obj.tid;
    this.threadType = obj.thread_type;
    this.psid = obj.psid;
    this.signedRequest = obj.signed_request;
  }
}

export default MessengerContext;
