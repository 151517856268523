<template>
  <div
    :style="containerStyle"
    class="my-bookings">
    <div>
      <!--Sticky
        :show-shadow="false">
        <div class="my-bookings__fixed_top">
          <Navbar
            :subtitle="navbarTitle"
            :title="navbarSubtitle"
            :show-back="showNavbar"/>
        </div>
      </Sticky-->
      <!--NavbarExtended
        :subtitle="navbarTitle"
        :title="navbarSubtitle"
        :show-back="showNavbar"/-->
      <NavigationBar
        :title="navbarSubtitle"
        :subtitle="navbarTitle"
        :show-back="showNavbar"
        is-extendable
        show-divider />
      <AppointmentList
        v-if="appointments"
        :items="appointments"
        :on-section-selected="sectionSelected"
        :on-section-scroll="onSectionScroll"
        class="my-bookings__list" />
    </div>
    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertClosed"
      @ok="onAlertClosed" />
  </div>
</template>

<script>
import AppointmentList from '@/components/Lists/AppointmentList.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import NavigationBar from '@/components/Nav/NavigationBar.vue';
import Error from '@/graphql/error';


const MAX_POOLING_REQUESTS = 4;
const POOLING_DELAY = 5000; // 5 seconds

export default {
  name: 'MyBookings',

  // Components ********************************
  components: {
    AppointmentList,
    Alert,
    NavigationBar,
  },

  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton && !this.showNavbar) {
      next(false);
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger && fbMessenger.inFrame) {
        window.MessengerExtensions.requestCloseBrowser(function success() {
          // webview closed
        }, function error() {
          // an error occurred
        });
      }
      return;
    }
    next();
  },

  // Props ********************************
  props: {
    delayedPooling: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      appointments: null,

      navbarTitle: null,
      navbarSubtitle: null,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: null,

      poolingCount: 0,
      poolingTimer: null,
    };
  },

  // Computed ********************************
  computed: {
    showNavbar() {
      return this.$store.getters.isExternalSource === true;
      //return true;
    },
    containerStyle() {
      /*
      if (this.appointments && this.appointments.length === 0) {
        return {
          background: '#ffffff',
        };
      }*/
      return {};
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({
        name: this.$constants.ROUTE_LOGIN,
        params: { endpoint: this.$constants.SOURCE_LOCATION_MY_BOOKINGS },
      }, () => {});
    }
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      return;
    }

    this.$analytics.track('Visit: My bookings');
    this.appointments = null;

    this.navbarTitle = auth.business.name;
    this.navbarSubtitle = this.$t('title.my_appointments');

    this.$root.loading = true;
    this.requestMyBookings();
  },

  destroyed() {
    if (this.poolingTimer) {
      clearTimeout(this.poolingTimer);
      this.poolingTimer = null;
    }
  },

  // Methods ********************************
  methods: {
    requestMyBookings() {
      const self = this;
      this.$graphql.getMyAppointments(function (list) {
        if (self.delayedPooling && list.length === 0 && self.poolingCount < MAX_POOLING_REQUESTS) {
          self.poolingCount++;
          self.poolingTimer = setTimeout(self.requestMyBookings, POOLING_DELAY);
        } else {
          self.$root.loading = false;
          self.appointments = list;
        }
      },
      function (error) {
        self.$root.loading = false;
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
          self.$router.push({
            name: self.$constants.ROUTE_LOGIN,
            params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
          }, () => {});
        } else {
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        }
      });
    },

    itemSelected(item) {
      this.$store.dispatch('setAppointment', item);
      this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS_ITEM, params: { id: item.key } },
        () => {});
      //this.$router.replace({ name: 'my-bookings-item', params: { id: item.id },
      //query: { back: 'my-bookings' } });
    },

    sectionSelected(key, day, appointments) {
      this.$router.push({
        name: this.$constants.ROUTE_MY_BOOKINGS_ITEMS,
        params: { selectedKey: key, day, appointments },
      }, () => {});

      /*
      const item = appointments.filter(function (item) {
        return item.key === key;
      })[0];
      this.$store.dispatch('setAppointment', item);
      this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS_ITEM, params: { id: key } });
      */
      //this.$store.dispatch('setAppointment', item);
      //this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS_ITEM,
      //params: { id: item.key } });
    },

    onSectionScroll(text) {
      if (text) {
        //this.navbarTitle = text;
        //this.navbarSubtitle = this.$t('title.my_appointments');
        this.navbarSubtitle = text;
      } else {
        this.navbarTitle = this.$t('title.my_appointments');
        this.navbarSubtitle = null;
      }
    },

    // Alerts
    showError(title, message) {
      this.isLoading = false;
      this.alert(title, message);
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertClosed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.my-bookings {
  //height: 100%;
  background: $theme-3-bg;

  @media screen and (min-width: $min-screen-width) {
    min-height: 100vh;
  }

  &__list {
    margin-top: 20px;
  }

}

</style>
