<template>
  <div
    ref="pinContainer"
    v-test="{ id: 'pinContainer' }"
    class="pin-pad"
    @click="handleClick"
  >
    <div class="pin-pad__separator">
      -
    </div>
    <pin-pad-char
      v-for="n in length"
      :key="n"
      :char="chars[n-1]"
      :is-loading="isLoading"
      :has-focus="isFocused && n === pin.length+1"
      :class="!(n % separator) && (n < length) ? 'separator' : ''"
    />
    <input
      ref="input"
      v-model="pin"
      v-test="{ id: 'pinInput' }"
      :name="ramdomText"
      autofocus
      autocomplete="one-time-code"
      dir="ltr"
      type="text"
      class="pin-pad__input"
      pattern="\d*"
      inputmode="numeric"
      @keyup.enter="handleEnter"
      @keydown="handleKeydown"
      @input="handleInput($event.target.value)"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >
  </div>
</template>


<script>
import { setTimeout } from 'timers';
import PinPadChar from './PinPadChar.vue';

function unmask(value) {
  // Remove every non-digit character
  return value.replace(new RegExp(/[^\d]/, 'g'), '');
}

export default {
  name: 'PinPad',

  // Components ********************************
  components: {
    PinPadChar,
  },

  // Properties ********************************
  props: {
    length: {
      type: Number,
      default: 6,
    },
    separator: {
      type: Number,
      default: 3,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    focusable: Boolean,
    onClick: Function,
    onComplete: Function,
  },

  // Data ********************************
  data() {
    return {
      pin: '',
      hasFocus: false,
    };
  },


  // Computed ********************************
  computed: {
    isFilled() {
      return this.pin.length === this.length;
    },

    chars() {
      const chars = this.pin.split('');
      if (chars.length < this.length) {
        const size = chars.length;
        for (let i = size; i < this.length; i++) {
          chars.push('');
        }
      }
      return chars;
    },

    isFocused() {
      return this.hasFocus;
    },

    ramdomText() {
      // ramdom string on input ´name´ 'autocomplete' to avoid autocomplete field
      return `pin-pad-${Date.now()}`;
    },

  },

  // Mounted ********************************
  mounted() {
    if (this.focusable) {
      const self = this;
      setTimeout(function () {
        self.handleClick();
      }, 600);

      //this.$refs.input.trigger('touchstart');
      //this.$refs.pinContainer.click();
      /*
      const el = this.$refs.pinContainer;
      const evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0,
      false, false, false, false, 0, null);
      el.dispatchEvent(evt);*/
    }
  },


  // Methods ********************************
  methods: {
    clear() {
      this.pin = '';
      this.handleInput(this.pin);
    },

    handleEnter() {
      if (!this.isFilled) return;
      this.$refs.input.blur();
      if (!this.onComplete) return;
      this.onComplete(this.pin);
      //if (!this.onClick) return;
      //this.onClick(this.pin);
    },

    handleClick() {
      if (this.isLoading === false) {
        this.$refs.input.focus();
        if (this.isFilled) {
          this.clear();
        }
      }
    },

    handleKeydown(e) {
      const value = e.target.value;
      if (
        (e.keyCode >= 48 && e.keyCode <= 57)
        && (e.keyCode >= 96 && e.keyCode <= 105)
        && value.length >= this.length
      ) {
        e.preventDefault();
        return;
      }
      this.$_oldValue = value;
    },

    handleInput(value) {
      const numRegExp = new RegExp(`^\\d{0,${this.length}}$`, 'g');
      const newValue = unmask(value);
      if (newValue.match(numRegExp)) {
        if (newValue !== '') {
          this.pin = newValue;
        } else {
          this.pin = '';
        }
      } else {
        this.pin = this.$_oldValue;
      }

      if (this.pin.length === this.length) {
        this.$refs.input.blur();
        if (this.onComplete) {
          this.onComplete(this.pin);
        }
      }
    },

    handleFocus(val) {
      this.hasFocus = val;
    },
  },
};
</script>


<style lang="scss" scoped>
.pin-pad {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: none;

  &__separator {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    text-align: center;
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  &__input {
    //opacity: 1;
    position: absolute;
    left: -200px;
    top: -900px;
    height: 10px;
    width: 100px;
    /*
    left: -99px;
    height: 6px;
    width: 10px;
    */
    font-size: 16px;
    caret-color: transparent;
    /*caret-color: black;*/
  }
}
.separator {
  margin-right: 30px;
}
</style>
