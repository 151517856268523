
<template>
  <div>
    <div
      v-if="showBusinessName"
      :class="containerClasses"
      :style="backgroundStyle"
      class="navbar-bar">
      <h3
        class="navbar-bar__title">
        {{ barTitle }}
      </h3>
    </div>
    <div
      ref="navbar_container"
      :class="navbarClasses"
      :style="backgroundStyle"
      class="navbar-container">
      <div
        :class="titleClassNames"
        class="navbar-container__title-container">
        <transition name="fade">
          <h1
            v-if="title"
            class="navbar-container__title-container__title"
            v-html="titleText" />
        </transition>
        <transition name="fade">
          <h2
            v-if="subtitle && !interchangeableTitles"
            class="navbar-container__title-container__subtitle"
            v-html="subtitle" />
        </transition>
      </div>
      <IlmButtonIcon
        v-if="canShowBack"
        :class="backClasses"
        :on-click="handleBackClick"
        :icon="backIcon"
        :is-working="isLoading"
        :style="buttonStyles"
        prevent-default
        class="navbar-container__back" />
      <IlmButton
        v-if="saveBtn"
        ref="btn_ok"
        :is-working="isLoading"
        :on-click="handleOkClick"
        :disabled="disableBtnOk"
        :label="saveBtn"
        :style="buttonStyles"
        theme="success"
        class="navbar-container__ok" />
    </div>
  </div>
</template>


<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';

export default {
  name: 'Navbar',

  // Components ********************************
  components: {
    IlmButton,
    IlmButtonIcon,
  },

  // Properties ********************************
  props: {
    title: String,
    subtitle: String,
    state: String,
    saveBtn: String,
    background: String,
    buttonBorder: String,
    isModal: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    interchangeableTitles: {
      type: Boolean,
      default: false,
    },
    canShowTopBar: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showBackBevel: {
      type: Boolean,
      default: false,
    },
    showShadow: {
      type: Boolean,
      default: true,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    disableBtnOk: {
      type: Boolean,
      default: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    onBack: Function,
    onOk: Function,
  },

  data() {
    return {
      barTitle: null,
      isScrolledDown: false,
    };
  },

  // Computed ********************************
  computed: {
    titleText() {
      if (this.isScrolledDown && this.interchangeableTitles) {
        return this.subtitle;
      }
      /*if (this.interchangeableTitles && this.isBigScreen) {
        return '';
      }
      if (this.interchangeableTitles) {
        if (this.isScrolledDown) {
          return this.subtitle;
        }
        if (this.isBigScreen) {
          return '';
        }
      }*/
      return this.title;
      /*
      return this.interchangeableTitles && this.isScrolledDown
        ? this.subtitle
        : this.title;*/
    },
    titleClassNames() {
      const classNames = {
        'navbar-container__title-container--show-nav-back': this.canShowBack,
      };
      return classNames;
    },
    backClasses() {
      return {
        'navbar-container__back--bevel': this.showBackBevel,
      };
    },
    backgroundStyle() {
      const styles = {};
      if (this.background) {
        styles.backgroundColor = this.background;
      }
      return styles;
    },
    buttonStyles() {
      const styles = {};
      if (this.background) {
        styles.backgroundColor = this.background;
      }
      if (this.buttonBorder) {
        styles.borderColor = this.buttonBorder;
      }
      return styles;
    },
    containerClasses() {
      const classNames = {
        'navbar-bar--transparent': this.isTransparent,
      };

      return classNames;
    },
    navbarClasses() {
      const classNames = {
        'navbar-container--shadow': this.showShadow,
        'navbar-container--divider': this.showDivider,
        'navbar-container--transparent': this.isTransparent,
      };
      if (this.state) {
        classNames[`navbar-container--${this.state}`] = true;
      }

      return classNames;
    },
    showBusinessName() {
      /*if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return false;
      }
      return this.$store.getters.isExternalSource && this.canShowTopBar;*/
      return false;
      //return true;
    },
    isBigScreen() {
      //return this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH;
      return this.$root.isDesktop;
    },
    canShowBack() {
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (body.classList.contains('os--ios') && fbMessenger.inFrame) {
        return false;
      }
      if (this.$store.getters.isExternalSource) {
        return true;
      }

      return this.showBack;
    },
    backIcon() {
      return this.isModal ? 'close' : 'arrow_back';
    },
  },

  mounted() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.business && auth.business.name) {
      this.barTitle = auth.business.name;
    }
    const self = this;
    this.$nextTick(function () {
      if (self.interchangeableTitles) {
        window.addEventListener('scroll', self.handleScroll);
      }
    });
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    if (this.interchangeableTitles) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  // Methods ********************************
  methods: {
    handleScroll() {
      const container = this.$refs.navbar_container;
      const windowScrollY = window.pageYOffset;
      //console.log(`container.offsetTop: ${container.offsetTop}`);
      //console.log(`windowScrollY: ${windowScrollY}`);
      //console.log(`container.clientHeight: ${container.clientHeight}`);
      if (container) {
        const offset = container.offsetTop;
        const containerHeight = container.clientHeight;
        const ditanceGap = this.isBigScreen ? 130 : 0;
        if (offset + containerHeight + ditanceGap > windowScrollY) {
          this.isScrolledDown = false;
        } else {
          this.isScrolledDown = true;
        }
      }
    },
    handleBackClick() {
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },
    handleOkClick() {
      if (this.onOk) {
        this.onOk();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.navbar-bar {
  background: white;

  &--transparent {
    background: transparent;
  }

  &__title {
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin: 0;
  }
}

.theme-brazil {
  height: 6px;
  background: rgb(0,156,59);
  background: linear-gradient(90deg, rgba(0,156,59,1) 0%, rgba(0,156,59,1) 30%,
  rgba(255,223,0,1) 30%, rgba(255,223,0,1) 46%,
  rgba(0,39,118,1) 46%, rgba(0,39,118,1) 50%, rgba(0,39,118,1) 54%,
  rgba(255,223,0,1) 54%, rgba(255,223,0,1) 70%, rgba(0,156,59,1) 70%, rgba(0,156,59,1) 100%);

  @media screen and (min-width: $min-screen-width) {
    display: none;
  }
}
.navbar-container {
  position: relative;
  display: block;
  //padding: 4px 0;
  min-height: 60px;
  //background: $theme-ilm-color;
  background: white;
  //margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out 0s;

  &--shadow {
    box-shadow: $box-shadow-bottom;
  }

  &--divider {
    border-bottom: 1px solid $theme-list-divider-color;
  }


  &__title-container {
    display: block;/*
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);*/
    padding: 10px $padding-section;
    max-width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;

    &--show-nav-back {
      padding: 10px 64px;
    }

    &__title {
      //color: white;
      color: black;
      font-size: 16px;
      text-align: center;
      line-height: 1.2em;
      margin: 0;
      font-weight: normal;
      //padding-bottom: 6px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      //color: white;
      color: black;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      line-height: 1.2em;
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__back {
    top: 10px;
    bottom: 10px;
    display: block;
    position: absolute;
    left: 16px;
    color: white !important;
    font-size: 24px;
    height: 40px;
      /*
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: white;
    font-size: 24px;
    height: 40px;
    */
    &--bevel {
      background: white;
      color: black !important;
      border: 1px solid $nav-button-border;
    }
    @media (hover) {
      &:hover {
        background: $theme-2-bg;
      }
    }
  }

  &--transparent  {
    background: transparent;
  }
  &--transparent ::v-deep &__back  {
    //color: white !important;
    border: 1px solid white;

    @media (hover) {
      &:hover {
        background: white;
        color: black !important;
      }
    }
  }

  &__ok {
    position: absolute;
    right: 12px;
    top: 8px;
    bottom: 8px;
    padding: 10px 20px 10px 20px;
    line-height: 1;
    box-sizing: border-box;
  }
/*
  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $theme-ilm-color;
    border-width: 8px;
    margin-left: -8px;
  }
*/

  &--booked { background: $theme-state-booked; }
  //&--booked:after { border-top-color: $theme-state-booked }
  &--confirmed { background: $theme-state-confirmed; }
  //&--confirmed:after { border-top-color: $theme-state-confirmed }
  &--waiting { background: $theme-state-waiting; }
  //&--waiting:after { border-top-color: $theme-state-waiting }
  &--started { background: $theme-state-started; }
  //&--started:after { border-top-color: $theme-state-started }
  &--late { background: $theme-state-late; }
  //&--late:after { border-top-color: $theme-state-late }
  &--paid { background: $theme-state-paid; }
  //&--paid:after { border-top-color: $theme-state-paid }
  &--pending { background: $theme-state-paid; }
  //&--pending:after { border-top-color: $theme-state-paid }
  &--canceled { background: $theme-state-canceled; }
  //&--canceled:after { border-top-color: $theme-state-canceled }
  &--noshow { background: $theme-state-noshow; }
  //&--noshow:after { border-top-color: $theme-state-noshow }

  &--booked &__title-container__title, &--booked &__title-container__subtitle, &--booked &__back,
  &--confirmed &__title-container__title, &--confirmed &__title-container__subtitle,
  &--confirmed &__back,
  &--waiting &__title-container__title, &--waiting &__title-container__subtitle, &--waiting &__back,
  &--started &__title-container__title, &--started &__title-container__subtitle, &--started &__back,
  &--late &__title-container__title, &--late &__title-container__subtitle, &--late &__back,
  &--paid &__title-container__title, &--paid &__title-container__subtitle, &--paid &__back,
  &--pending &__title-container__title, &--pending &__title-container__subtitle, &--pending &__back,
  &--canceled &__title-container__title, &--canceled &__title-container__subtitle,
  &--canceled &__back,
  &--noshow &__title-container__title, &--noshow &__title-container__subtitle, &--noshow &__back
  { color: white }
}

</style>
