//Add strings
const en = {
  bar_title: '🍪 This website uses cookies',
  description: 'We store data temporarily to improve your browsing experience. By using our services you agree to such use.',
  settings: 'Configurations',
  accept: 'Accept',
  statement: 'Privacy policy',
  save: 'Save',
  always_on: 'Always on',
  cookie_essential_title: 'Essential website cookies',
  cookie_essential_desc: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
  cookie_performance_title: 'Performance cookies',
  cookie_performance_desc: 'These cookies are used to enhance the performance and functionality of our websites but are non-essential to their use. For example it stores your preferred language or the region that you are in.',
  cookie_analytics_title: 'Analytics cookies',
  cookie_analytics_desc: 'We use analytics cookies to help us measure how users interact with website content, which helps us customize our websites and application for you in order to enhance your experience.',
  cookie_marketing_title: 'Marketing cookies',
  cookie_marketing_desc: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
};

export default en;
