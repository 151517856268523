
<template>
  <div
    :class="mainClass">

    <MultipleServiceGridSlotsButton
      v-for="(item, index) in slots"
      :ref="itemReference(item.time)"
      :key="index"
      :index="index"
      :item-slot="item"
      :services-size="servicesSize"
      :discount="getMaxDiscountValue(item.time)"
      :max-discount="getMaxDiscount()"
      :on-slot-clicked="onSlotClicked"
      :class="`${mainClass}__slot`" />

    <div
      v-if="selectedSlot && !isLoading"
      ref="selector"
      :class="selectedSlotClasses"
      :style="selectedSlotStyle"
      @click="selectorClicked">
      <span
        :class="`${mainClass}__selector__start`"
        :style="stickyTopStyle">
        {{ selectedSlot.time }}
      </span>
      <span
        v-if="selectedSlotTotalRows !== 1"
        :class="`${mainClass}__selector__spacing`">
        <span :class="`${mainClass}__selector__spacing__line`" />
        <span
          v-if="selectedSlotDiscount"
          :class="selectedSlotDiscountClasses">
          {{ selectedSlotDiscount }}
        </span>
      </span>
      <IlmIcon
        v-if="selectedSlotTotalRows !== 1"
        :class="`${mainClass}__selector__icon`"
        icon="caret-down"
        size="md" />
      <span
        v-if="selectedSlotTotalRows !== 1"
        :class="`${mainClass}__selector__end`"
        :style="stickyTopStyle">
        {{ selectedSlot.endTime }}
      </span>
      <IlmIcon
        :class="`${mainClass}__selector__icon_remove`"
        icon="close"
        size="md" />
    </div>

    <transition name="fade">
      <div
        v-if="showSelectorTooltip && !isLoading"
        :class="selectedTooltipClasses"
        :style="selectedTooltipStyle"
        @click="tooltipClick">
        <!--IlmIcon
          :class="`${mainClass}__selector-tooltip__icon`"
          icon="check_circle"
          size="sm"/-->
        <span
          :class="`${mainClass}__selector-tooltip__text`">
          {{ selectedSlot.time }}
        </span>
      </div>
    </transition>

    <div
      v-if="isLoading"
      :class="`${mainClass}__loading`" />
  </div>
</template>


<script>
import MultipleServiceGridSlotsButton from '@/components/Slots/MultipleServiceGridSlotsButton.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'MultipleServiceGridSlots',

  // Components ********************************
  components: {
    MultipleServiceGridSlotsButton,
    IlmIcon,
  },

  // Properties ********************************
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    headerHeight: Number,
    service: Object,
    servicesSize: Number,
    selectedDate: Object,
    onSlotSelected: Function,
    onSlotTooltipSelected: Function,
  },

  // Data ********************************
  data() {
    return {
      selectedSlot: null,
      slots: [],
      selectedSlotStyle: {},
      selectedSlotTotalRows: 0,

      discounts: null,

      showTooltip: false,
      tooltipTop: null,
      tooltipBottom: null,
      tooltipLeft: null,

      morningPosition: null,
      afternoonPosition: null,
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'multiple-service-grid-slots';
    },
    selectedSlotClasses() {
      const classes = {};
      classes[`${this.mainClass}__selector`] = true;
      if (this.selectedSlot) {
        classes[`${this.mainClass}__selector--has-discount`] = this.getMaxDiscountValue(this.selectedSlot.time);

        if (this.selectedSlotTotalRows <= 1) {
          classes[`${this.mainClass}__selector--single-slot`] = true;
        }
      }
      return classes;
    },
    selectedSlotDiscount() {
      if (this.selectedSlot && this.discounts) {
        const discountValue = this.getMaxDiscountValue(this.selectedSlot.time);
        if (discountValue) {
          return `-${this.getMaxDiscountValue(this.selectedSlot.time)}%`;
        }
      }
      return null;
    },
    selectedSlotDiscountClasses() {
      const classes = {};
      classes[`${this.mainClass}__selector__spacing__discount`] = true;
      if (this.selectedSlot) {
        const selectedDiscount = this.getMaxDiscountValue(this.selectedSlot.time);
        const maxDiscount = this.getMaxDiscount();
        classes[`${this.mainClass}__selector__spacing__discount--max`] = selectedDiscount === maxDiscount;
      }
      return classes;
    },
    selectedTooltipClasses() {
      const classes = {};
      classes[`${this.mainClass}__selector-tooltip`] = true;
      classes[`${this.mainClass}__selector-tooltip--bottom`] = this.tooltipBottom !== null;
      classes[`${this.mainClass}__selector-tooltip--top`] = this.tooltipTop !== null;
      if (this.selectedSlot) {
        //classes[`${this.mainClass}__selector-tooltip--has-discount`]
        // = this.getMaxDiscountValue(this.selectedSlot.time);
      }
      return classes;
    },

    selectedTooltipStyle() {
      const style = {};
      if (this.tooltipBottom) {
        style.bottom = `${this.tooltipBottom}px`;
      } else if (this.tooltipTop) {
        style.top = `${this.tooltipTop}px`;
      }
      if (this.tooltipLeft) {
        style.left = `${this.tooltipLeft}px`;
      }
      return style;
    },

    showSelectorTooltip() {
      return this.selectedSlot && this.showTooltip;
      //&& this.$root.isDesktop === false;
    },

    stickyTopStyle() {
      const style = {};
      style.top = `${this.headerHeight ? this.headerHeight : 0}px`;
      return style;
    },
  },

  // Watchers ********************************
  watch: {
    slots() {
      if (!this.slots) {
        return;
      }

      this.$nextTick(() => {
        const borderRadius = 8;
        let top = 0;
        let borderTopLeftRadius = 0;
        let borderTopRightRadius = 0;
        let borderBottomLeftRadius = 0;
        let borderBottomRightRadius = 0;
        let height = 0;

        if (this.selectedSlot) {
          const startReference = this.itemReference(this.selectedSlot.time);
          let startIndex = 0;
          if (this.$refs[startReference]
            && this.$refs[startReference].length > 0
            && this.$refs[startReference][0].$el) {
            const startElement = this.$refs[startReference][0].$el;
            startIndex = this.$refs[startReference][0].index;
            if (startElement) {
              top = startElement.offsetTop;
              const slot = this.slots[startIndex];
              if (startIndex === 0 || slot.firstSection === true) {
                borderTopLeftRadius = borderRadius;
                borderTopRightRadius = borderRadius;
              }
              if (startIndex === this.slots.length - 1) {
                borderBottomLeftRadius = borderRadius;
                borderBottomRightRadius = borderRadius;
              }
            }
          }

          //const duration = this.service.duration;
          //const dateTime = this.$moment(`${this.selectedSlot.time}`, 'HH:mm');
          //dateTime.add(duration, 'm');
          const endTimeStr = this.selectedSlot.endTime;

          let endReference = this.itemReference(endTimeStr);
          if (!this.$refs[endReference] || !this.$refs[endReference][0]) {
            for (let i = startIndex; i < this.slots.length; i++) {
              const slotTime = this.slots[i].time;
              if (endTimeStr <= slotTime) {
                endReference = this.itemReference(slotTime);
                break;
              }
            }
            if (!this.$refs[endReference] || !this.$refs[endReference][0]) {
              endReference = this.itemReference(this.slots[this.slots.length - 1].time);
            }
          }
          const endElement = this.$refs[endReference][0].$el;
          const endIndex = this.$refs[endReference][0].index;
          if (endIndex) {
            const endTop = endElement.offsetTop;
            height = endTop - top - 1;

            const slot = this.slots[endIndex - 1];
            if (slot.lastSection === true) {
              borderBottomLeftRadius = borderRadius;
              borderBottomRightRadius = borderRadius;
              height -= 15;
            }
            if (endTimeStr > this.slots[this.slots.length - 1].time) {
              height += endElement.offsetHeight + 1;
              borderBottomLeftRadius = borderRadius;
              borderBottomRightRadius = borderRadius;
            }
          }

          if (startIndex !== null && endIndex !== null) {
            this.selectedSlotTotalRows = (endIndex + 1) - (startIndex + 1);
          } else {
            this.selectedSlotTotalRows = 0;
          }
        }

        this.selectedSlotStyle = {
          top: `${top}px`,
          'border-top-left-radius': `${borderTopLeftRadius}px`,
          'border-top-right-radius': `${borderTopRightRadius}px`,
          'border-bottom-left-radius': `${borderBottomLeftRadius}px`,
          'border-bottom-right-radius': `${borderBottomRightRadius}px`,
          height: `${height}px`,
        };


        const len = this.slots.length;
        let s;
        let time;
        let timeSplit;
        let hour;
        let minute;
        if (this.slots.length > 0 && this.slots[0].time > `${this.$constants.SCHEDULE_AFTERNOON}:00`) {
          const reference = this.itemReference(this.slots[0].time);
          const startElement = this.$refs[reference][0].$el;
          if (startElement) {
            this.afternoonPosition = startElement.offsetTop;
          }
        } else if (this.slots.length > 0 && this.slots[0].time > `${this.$constants.SCHEDULE_MORNING}:00`) {
          const reference = this.itemReference(this.slots[0].time);
          const startElement = this.$refs[reference][0].$el;
          if (startElement) {
            this.morningPosition = startElement.offsetTop;
          }
        }
        for (let i = 0; i < len; i++) {
          s = this.slots[i];
          time = s.time;
          timeSplit = time.split(':');
          hour = parseInt(timeSplit[0], 10);
          minute = parseInt(timeSplit[1], 10);
          if (hour === this.$constants.SCHEDULE_MORNING && minute === 0) {
            const reference = this.itemReference(time);
            const startElement = this.$refs[reference][0].$el;
            if (startElement) {
              this.morningPosition = startElement.offsetTop;
              //this.morningPosition = startElement.getBoundingClientRect().top;
            }
          } else if (hour === this.$constants.SCHEDULE_AFTERNOON && minute === 0) {
            const reference = this.itemReference(time);
            const startElement = this.$refs[reference][0].$el;
            if (startElement) {
              this.afternoonPosition = startElement.offsetTop;
              //this.afternoonPosition = startElement.getBoundingClientRect().top;
              break;
            }
          }
        }
      });
    },
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  destroyed() {
    this.clearSlots();
  },

  methods: {
    itemReference(time) {
      return `${this.service.id}_${time.replace(':', '_')}`;
    },
    onSlotClicked(item) {
      if (!item || item.available === false || item.locked === true) {
        if (this.onSlotSelected) {
          this.onSlotSelected(null, this.service);
        }
        this.selectedSlot = null;
      } else /*if (item.available === true && item.locked === false)*/ {
        const duration = this.service.duration;
        const dateTime = this.$moment(`${item.time}`, 'HH:mm');
        dateTime.add(duration, 'm');
        const endTimeStr = dateTime.format('HH:mm');
        item.endTime = endTimeStr;
        if (this.onSlotSelected) {
          this.onSlotSelected(item, this.service);
        }
        this.selectedSlot = item;

        this.$analytics.track('Action: Choose Slot', { time: item.time });
      }
    },
    selectorClicked() {
      this.onSlotClicked(null);
    },

    clearSlots() {
      this.selectedSlot = null;
      this.slots = [];
    },
    clearSelectedSlot() {
      this.selectedSlot = null;
    },
    addSlot(slot) {
      this.slots.push(slot);
    },
    setSlots(slots) {
      this.slots = slots;
    },

    setDiscounts(discounts) {
      this.discounts = discounts;
    },

    getMaxDiscountValue(time) {
      const weekday = this.$moment(this.selectedDate).isoWeekday();
      if (this.discounts && this.discounts.length > 0) {
        const discountValue = this.discounts.reduce((prev, current) => {
          const currentValue = current.getHourDiscount(weekday, time);
          const prevValue = prev.getHourDiscount(weekday, time);
          return (prevValue > currentValue) ? prev : current;
        });
        //console.log(`service: ${this.service.id} time: ${time} discount: ${discountValue}`);
        return discountValue.getHourDiscount(weekday, time);
      }
      return null;
    },

    getMaxDiscount() {
      const serviceId = `${this.service.id}`;
      const weekday = this.$moment(this.selectedDate).isoWeekday();
      if (this.discounts && this.discounts.length > 0) {
        let maxDiscount;
        let discountValues = [];
        this.discounts.forEach(element => {
          const currentValue = element.getDayDiscount(weekday, serviceId);
          if (maxDiscount) {
            if (currentValue > maxDiscount) {
              maxDiscount = currentValue;
            }
          } else {
            maxDiscount = currentValue;
          }
          const values = element.getDayAvailableDiscounts(weekday, serviceId);
          discountValues = discountValues.concat(values);
        });
        // check discount values > 1
        /*if (discountValues.filter((item, pos) =>
        discountValues.indexOf(item) === pos).length > 1) {
          return maxDiscount;
        }*/
        return maxDiscount;
      }
      return null;
    },

    getSlotPosition(hour) {
      let time;
      //let timeSplit;
      const length = this.slots.length;
      for (let i = 0; i < length; i++) {
        time = this.slots[i].time;
        //timeSplit = time.split(':');
        //if (parseInt(timeSplit[0], 10) === hour) {
        if (time === hour) {
          const eref = this.itemReference(time);
          const el = this.$refs[eref][0].$el;
          return el.getBoundingClientRect().top;
          //return el.offsetTop;
        }
      }
      return 0;
    },
    getMorningPosition() {
      return this.morningPosition;
    },
    getAfternoonPosition() {
      return this.afternoonPosition;
    },

    onScroll(obj) {
      //console.log(`top: ${top} | bottom: ${bottom}`);
      const selector = this.$refs.selector;
      if (selector) {
        //const selectorTop = selector.getBoundingClientRect().top;
        const selectorTop = selector.offsetTop;
        const selectorHeight = selector.offsetHeight;

        const boundingBox = selector.getBoundingClientRect();

        //if (selectorTop + selectorHeight < obj.top) {
        if (obj.top + selectorHeight < -selectorTop) {
          // show top tooltip
          this.showTooltip = true;
          this.tooltipTop = obj.fixedTop;
          this.tooltipBottom = null;
        //} else if (selectorTop > obj.bottom) {
        } else if ((this.$root.isDesktop && obj.bottom + obj.fixedBottom < boundingBox.top)
          || (!this.$root.isDesktop && selectorTop > obj.bottom)) {
          // show bottom tooltip
          this.showTooltip = true;
          this.tooltipTop = null;
          this.tooltipBottom = obj.fixedBottom;
        } else {
          this.showTooltip = false;
          this.tooltipBottom = null;
          this.tooltipTop = null;
        }
        if (obj.center) {
          this.tooltipLeft = obj.center;
        }
      } else {
        this.showTooltip = false;
        this.tooltipBottom = null;
        this.tooltipTop = null;
        this.tooltipLeft = null;
      }
    },

    tooltipClick() {
      if (this.selectedSlot && this.onSlotTooltipSelected) {
        const position = this.getSlotPosition(this.selectedSlot.time);
        this.onSlotTooltipSelected(position);
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.multiple-service-grid-slots {
  width: 100%;
  position: relative;
  display: block;

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0.4;
  }

  &__selector-tooltip {
    position: fixed;
    background: $theme-ilm-slot-background-selected;
    border: 1px solid white;
    padding: 10px 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px 0px rgba(156,156,156,0.5);
    z-index: 5;
    transform: translateX(-50%);
    cursor: pointer;
    user-select: none;

    &__text {
      flex: 0 0 auto;
      color: white;
      font-weight: bold;
      font-size: 12px;
      line-height: 1;
      padding: 0;
    }

    &__icon {
      color: white;
      margin-right: 4px;
    }

    &--top:after, &--top:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &--top:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $theme-ilm-slot-background-selected;
      border-width: 10px;
      margin-left: -10px;
    }
    &--top:before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 11px;
      margin-left: -11px;
    }

    &--bottom:after, &--bottom:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &--bottom:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $theme-ilm-slot-background-selected;
      border-width: 10px;
      margin-left: -10px;
    }
    &--bottom:before {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 11px;
      margin-left: -11px;
    }
  }

  &__selector {
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    //border: 4px solid $theme-ilm-slot-background-selected;
    background: $theme-ilm-slot-background-selected;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 0;

    &__start {
      position: sticky;
      padding: 4px 8px;
      border-radius: 4px;
      margin-bottom: 24px;
      flex: 0 0 auto;
      color: white;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      z-index: 2;
      background: $theme-ilm-slot-background-selected;
    }
    &--single-slot ::v-deep &__start {
      margin-bottom: 0;
    }
    &__end {
      position: sticky;
      flex: 0 0 auto;
      color: white;
      font-weight: bold;
      font-size: 14px;
      padding: 4px 0;
      line-height: 1.2;
      z-index: 1;
    }
    &__spacing {
      position: relative;
      flex: 1 1 auto;
      //width: 1px;
      margin-right: 2px;
      margin-top: -18px;
      display: flex;
      align-items: center;
      z-index: 0;

      &__line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -1px;
        width: 1px;
        border-width: 2px;
        border-color: white;
        border-style: none dashed none none;
        z-index: 1;
      }

      &__discount {
        flex: 0 0 auto;
        background: $discount-background-color;
        font-size: 12px;
        font-weight: bold;
        padding: 2px 10px;
        border-radius: 4px;
        margin-left: 2px;
        border: 1px solid white;
        line-height: 1.2;
        z-index: 2;
        user-select: none;

        &--max {
          background: $discount-max-background-color;
        }
      }
    }

    &__icon {
      flex: 0 0 auto;
      margin-right: 0;
      margin-top: -6px;
      margin-bottom: 6px;
      color: white;
    }
    &__icon_remove {
      position: absolute;
      top: 3px;
      left: 3px;
      color: white;
      z-index: 3;
    }


    &--has-discount {
      //background: $discount-background-color-border;
    }

    /*&:after {
      position: absolute;
      content: '';
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      border: 2px solid white;
    }*/
    /*&:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      height: 42px;
      background: $theme-ilm-slot-background-selected;
      z-index: 0;
    }*/

    @media (hover) {
      &:hover {
        //border-color: $theme-ilm-slot-background;
        //background: #ccc;
      }
    }
  }
}

</style>
