<template>
  <div class="voucher-root">
    <Sticky
      :on-stick-update="onNavBarSticked">
      <Navbar
        :title="navbarTitle"
        :show-back="true"
        :show-shadow="false"
        :is-transparent="!isNavbarSticked"
        :show-back-bevel="isNavbarSticked"
        is-modal
        class="voucher-root__navbar" />
    </Sticky>
    <div class="voucher-root__gradient" />

    <div class="voucher-root__content">

      <h1 class="voucher-root__content__title">
        {{ $t('voucher.voucher_details_title') }}
      </h1>
      <p class="voucher-root__content__text">
        {{ $t('voucher.voucher_text') }}
      </p>

      <div
        class="voucher-root__content__checkbox_container">
        <div
          class="voucher-root__content__checkbox_container__checkbox"
          @click="onCheckboxClick">
          <IlmIcon
            :icon="iconCheckbox"
            size="xl"
            class="voucher-root__content__checkbox_container__checkbox__icon" />
        </div>
        <p
          class="voucher-root__content__checkbox_container__text">
          <span
            @click="onCheckboxClick">
            {{ $t('voucher.voucher_details_confirm_checkbox') }}
          </span>
          <span
            class="voucher-root__content__checkbox_container__text__tc"
            @click="onTermsConditionsClick">
            {{ $t('voucher.voucher_details_confirm_checkbox_tc') }}
          </span>
        </p>
      </div>

      <div>
        <VoucherRow
          v-for="voucher in vouchers"
          :key="voucher.id"
          :is-disabled="isCheckboxSelected === false"
          :voucher="voucher"
          :on-selected="onVoucherSelected" />
          <!--VoucherRow
          :voucher-id="0"
          :is-disabled="isCheckboxSelected === false"
          :on-selected="onVoucherSelected"
          title="VOUCHER 1"
          description="Pague R$30<br>Receba R$60"
          color="#FF8F92" />

        <VoucherRow
          :voucher-id="1"
          :is-disabled="isCheckboxSelected === false"
          :on-selected="onVoucherSelected"
          title="VOUCHER 2"
          description="Pague R$60<br>Receba R$120"
          color="#00DC00" />

        <VoucherRow
          :voucher-id="2"
          :is-disabled="isCheckboxSelected === false"
          :on-selected="onVoucherSelected"
          title="VOUCHER 3"
          description="Pague R$120<br>Receba R$240"
          color="#6A89C3" /-->
      </div>
    </div>

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="alertButton"
      @closed="onAlertClosed"
      @ok="onAlertClosed" />
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import VoucherRow from '@/components/Lists/VoucherRow.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';

import CustomerDetails from '@/models/billing/customerDetails';


export default {
  name: 'VoucherDetails',

  components: {
    Navbar,
    IlmIcon,
    VoucherRow,
    Alert,
  },

  // Properties ********************************
  props: {
  },

  // Data ********************************
  data() {
    return {

      isNavbarSticked: false,
      isCheckboxSelected: false,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertButton: '',

      vouchers: [
        {
          id: 'covid30',
          title: 'Voucher 1',
          description: '',
          color: '#3E95F3',
          userValue: 30,
          value: 45,
        },
        {
          id: 'covid60',
          title: 'Voucher 2',
          description: '',
          color: '#4CAF50',
          userValue: 60,
          value: 90,
        },
        {
          id: 'covid120',
          title: 'Voucher 3',
          description: '',
          color: '#F79702',
          userValue: 120,
          value: 180,
        },
      ],
    };
  },

  // Computed ********************************
  computed: {
    navbarTitle() {
      return this.isNavbarSticked ? this.$t('voucher.voucher_details_title') : '';
    },
    iconCheckbox() {
      return this.isCheckboxSelected ? 'check_box' : 'check_box_outline_blank';
    },
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
    }

    this.$store.dispatch('resetAppointment');

    const customer = this.$store.getters.getCustomer;
    if (customer) {
      this.isCheckboxSelected = customer.tcAccepted;
    }

    this.$analytics.track('Visit: Vouchers');
  },


  // Methods ********************************
  methods: {
    onNavBarSticked(isSticked) {
      this.isNavbarSticked = isSticked;
    },
    onCheckboxClick() {
      this.isCheckboxSelected = !this.isCheckboxSelected;
    },
    onTermsConditionsClick() {
      let customer = this.$store.getters.getCustomer;
      if (!customer) {
        customer = new CustomerDetails();
      }
      customer.tcAccepted = this.isCheckboxSelected;
      this.$store.dispatch('setCustomer', customer);

      this.$router.push({
        name: this.$constants.ROUTE_TERMS_CONDITIONS,
        params: { userShouldAccept: true },
      }, () => {});
    },
    onVoucherSelected(voucher) {
      if (!this.isCheckboxSelected) {
        this.showAlert();
        return;
      }

      this.$analytics.track('Action: Voucher Selected', { voucherId: voucher.id });

      const customer = new CustomerDetails();
      customer.tcAccepted = true;
      customer.billing.value = voucher.userValue;
      customer.billing.description = voucher.title;

      customer.voucher = voucher;

      this.$store.dispatch('setCustomer', customer);

      /*const auth = this.$store.getters.getAuth;
      if (auth.isAuthenticated()) {
        this.$router.push({ name: this.$constants.ROUTE_PAYMENT_DETAILS });
      } else {
        // go to phone verification
        this.$router.push({
          name: this.$constants.ROUTE_VERIFY_PHONE,
          params: { endpoint: this.$constants.ROUTE_PAYMENT_DETAILS },
        });
      }*/
      this.$router.push({ name: this.$constants.ROUTE_PAYMENT_DETAILS }, () => {});
    },

    showAlert() {
      this.alertTitle = this.$t('alerts.alert_choose_voucher_accept_terms_title');
      this.alertMessage = this.$t('alerts.alert_choose_voucher_accept_terms_message');
      this.alertButton = this.$t('alerts.alert_choose_voucher_accept_terms_ok');
      this.alertShowing = true;
    },
    onAlertClosed() {
      this.alertShowing = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.voucher-root {
  position: relative;
  background: white;

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 480px;
    background: rgb(218,66,255);
    background: linear-gradient(150deg, rgba(218,66,255,1) 10%, rgba(255,172,149,1) 80%);

    /*&:after {
      content: ' ';
      bottom: 0;
      transform: skewY(-10.5deg);
      transform-origin: 100%;
    }*/
    &:after {
      content: ' ';
      background-image: linear-gradient(to left top, white 0%, white 50%, transparent 50%);
      position: absolute;
      width: 100%;
      height: 120px;
      bottom: 0;
      right: 0;
    }
  }

  &__content {
    position: relative;
    padding: $padding-section;

    &__title {
      color: white;
      font-weight: bold;
      line-height: 1.3;
      font-size: 26px;
      margin: 0;
      margin-bottom: 20px;
    }

    &__text {
      color: white;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 16px;
    }

    &__checkbox_container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &__checkbox {
        flex: 0 0 auto;
        color: white;
        padding: 10px 10px 10px 0;
        cursor: pointer;

        @media (hover) {
          &:hover {
            opacity: 0.5;
          }
        }

        &__icon {
          font-size: 32px;
        }
      }

      &__text {
        flex: 1 1 auto;
        color: white;
        font-size: 16px;
        line-height: 1.5;
        margin: 0;

        &__tc {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;

          @media (hover) {
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
</style>
