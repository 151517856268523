import utils from '../utils/utils';

class Service {
  constructor(obj) {
    this.id = obj.id;
    this.name = utils.capitalizeWords(obj.name);
    this.price = obj.price;
    this.duration = obj.duration;
    this.category = obj.category;
    this.isPriceVariable = obj.isPriceVariable;
    this.description = obj.description;
    this.isPriceUnavailable = obj.isPriceUnavailable;

    this.discount = null;
  }

  toString() {
    return `id: ${this.id} | name: ${this.name} | price: ${this.price} | duration: ${this.duration} | category: ${this.category}`;
  }
}

export default Service;
