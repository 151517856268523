<template>
  <div
    :class="containerClassNames"
    class="select-container"
    @click="handleClick">
    <span class="select-container__text">
      {{ label }}
    </span>
    <IlmIcon
      icon="caret-down"
      class="select-container__caret" />
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'IlmSelect',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    label: String,
    disabled: Boolean,
    onClick: Function,
  },

  // Computed ********************************
  computed: {
    isDisabled() { return this.disabled; },
    containerClassNames() {
      const classNames = {
        'select-container--disabled': this.isDisabled,
      };
      return classNames;
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (this.isDisabled || !this.onClick) return;
      this.onClick();
    },
  },
};
</script>


<style lang="scss" scoped>

.select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  color: black;
  cursor: pointer;

  &:active {
    color: $theme-color-dim;
  }

  &--disabled {
    color: black !important;
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
/*
  &__symbol {
    display: inline-flex;
    font-size: $html-font-size;
    font-weight: bold;
    padding-right: 3px;
  }
*/
  &__text {
    display: inline-flex;
    font-size: $font-size-lg;
    font-weight: bold;
  }

  &__caret {
    display: inline-flex;
    padding-left: 14px;
  }
}


</style>
