
<template>
  <div :class="mainClasses">
    <div
      :class="innerCardClasses"
      @click="cardClick">
      <div :class="`${mainClass}__inner__content`">
        <div :class="`${mainClass}__inner__content__col1`">
          <div :class="`${mainClass}__inner__content__col1__discount_container`">
            <span
              v-if="discount.isChooseAll === true"
              :class="`${mainClass}__inner__content__col1__discount_container__until_label`">
              {{ $t('button.discount_card_choose_all_value') }}
            </span>
            <span
              :class="`${mainClass}__inner__content__col1__discount_container__discount`">
              {{ discountText }}
            </span>
          </div>
        </div>
        <div :class="`${mainClass}__inner__content__col2`">
          <div :class="`${mainClass}__inner__content__col2__info`">
            <span :class="titleClasses">
              {{ title }}
            </span>
            <span
              v-if="!discount.isChooseAll"
              :class="`${mainClass}__inner__content__col2__info__description`">
              {{ description }}
            </span>
          </div>
        </div>
        <IlmIcon
          v-if="selected"
          :class="`${mainClass}__inner__content__selected_icon`"
          icon="check" />
      </div>
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'DiscountsListCard',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    discount: Object,
    selected: {
      type: Boolean,
      default: false,
    },
    onClick: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'discounts-list-card';
    },
    mainClasses() {
      const classes = {};
      classes[`${this.mainClass}`] = true;
      let roundValue = Math.ceil(this.discountValue / 10) * 10;
      if (roundValue === this.discountValue) {
        roundValue = Math.ceil((roundValue - 1) / 10) * 10;
      }
      classes[`${this.mainClass}--${roundValue}`] = true;
      return classes;
    },
    innerCardClasses() {
      const classes = {};
      classes[`${this.mainClass}__inner`] = true;
      classes[`${this.mainClass}__inner--selected`] = this.selected;
      return classes;
    },
    discountValue() {
      return this.discount.maxValue;
    },
    discountText() {
      /*if (this.discount.isChooseAll === true) {
        return this.$t('button.discount_card_choose_all_value', { value: this.discountValue });
      }*/
      return `-${this.discountValue}%`;
    },
    titleClasses() {
      const classes = {};
      classes[`${this.mainClass}__inner__content__col2__info__title`] = true;
      classes[`${this.mainClass}__inner__content__col2__info__title--all`] = this.discount.isChooseAll === true;
      return classes;
    },
    title() {
      return this.discount.title;
    },
    description() {
      return this.discount.description;
    },
  },

  watch: {
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  // Methods ********************************
  methods: {
    cardClick() {
      if (this.onClick) {
        this.onClick(this.discount);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

$border-width: 2px;
$border-radius: 18px;

.discounts-list-card {
  padding: 6px $padding-section;
  user-select: none;

  &__inner {
    position: relative;
    box-sizing: border-box;
    //min-height: 100px;
    border-radius: $border-radius;
    // default
    background: $theme-discount-40;
    padding: $border-width;
    cursor: pointer;

    &__content {
      display: flex;
      flex-direction: row;
      //background: white;
      border-radius: $border-radius;
      min-height: 120px;

      &__selected_icon {
        color: $theme-ilm-color;
        font-size: 34px;
        position: absolute;
        top: 4px;
        right: 8px;
      }

      &__col1 {
        flex: 0 0 auto;
        min-width: 130px;
        border-top-left-radius: $border-radius - 2px;
        border-bottom-left-radius: $border-radius - 2px;
        display: flex;
        justify-content: center;

        &__discount_container {
          display: flex;
          align-self: center;
          flex-direction: column;
          padding: 18px 10px;

          &__until_label {
            color: white;
            font-family: 'DINNeuzeitGroteskStd-BdCond';
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            box-sizing: border-box;
          }

          &__discount {
            color: white;
            font-family: 'DINNeuzeitGroteskStd-BdCond';
            font-size: 50px;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            box-sizing: border-box;
          }
        }
      }
      &__col2 {
        position: relative;
        flex: 1 1 auto;
        box-sizing: border-box;
        background: white;;
        border-top-right-radius: $border-radius - 2px;
        border-bottom-right-radius: $border-radius - 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 10px 16px 16px;
        width: 0;

        &:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: border-box;
          top: 0;
          bottom: 0;
          left: -6px;
          width: 10px;
          background:
            linear-gradient(45deg,
              transparent 33.333%,
              #fff 33.333%,
              #fff 66.667%,
              transparent 66.667%
            ),
            linear-gradient(-45deg,
              transparent 33.333%,
              #fff 33.333%,
              #fff 66.667%,
              transparent 66.667%
            );
          background-size: (10px * 2) 10px;
          background-position: -10px 0;
          transform: rotate(180deg);
        }

        &__info {
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          justify-content: center;
          max-width: 100%;

          &__title {
            color: black;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;

            &--all {
              font-size: 26px;
            }
          }
          &__description {
            flex: 0 0 auto;
            color: black;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.4;
            padding-top: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
        }
      }
    }

    &--selected &__content__col2 {
      background: #FDF5EB;

      &:after {
        content: '';
        display: block;
        position: absolute;
        box-sizing: border-box;
        top: 0;
        bottom: 0;
        left: -6px;
        width: 10px;
        background:
          linear-gradient(45deg,
            transparent 33.333%,
            #FDF5EB 33.333%,
            #FDF5EB 66.667%,
            transparent 66.667%
          ),
          linear-gradient(-45deg,
            transparent 33.333%,
            #FDF5EB 33.333%,
            #FDF5EB 66.667%,
            transparent 66.667%
          );
        background-size: (10px * 2) 10px;
        background-position: -10px 0;
        transform: rotate(180deg);
      }

      &__info {
        &__title, &__description {
          color: $theme-ilm-color;
        }
      }
    }

    @media (hover) {
      &:hover &__content__col2 {
        background: $theme-highlight-color;

        &:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: border-box;
          top: 0;
          bottom: 0;
          left: -6px;
          width: 10px;
          background:
            linear-gradient(45deg,
              transparent 33.333%,
              $theme-highlight-color 33.333%,
              $theme-highlight-color 66.667%,
              transparent 66.667%
            ),
            linear-gradient(-45deg,
              transparent 33.333%,
              $theme-highlight-color 33.333%,
              $theme-highlight-color 66.667%,
              transparent 66.667%
            );
          background-size: (10px * 2) 10px;
          background-position: -10px 0;
          transform: rotate(180deg);
        }
      }
    }
  }

  &--10 &__inner, &--10 &__inner__content__col1 {
    background: $theme-discount-10;
  }
  &--20 &__inner, &--20 &__inner__content__col1 {
    background: $theme-discount-20;
  }
  &--30 &__inner, &--30 &__inner__content__col1 {
    background: $theme-discount-30;
  }
  &--40 &__inner, &--40 &__inner__content__col1 {
    background: $theme-discount-40;
  }
  &--50 &__inner, &--50 &__inner__content__col1 {
    background: $theme-discount-50;
  }
  &--60 &__inner, &--60 &__inner__content__col1 {
    background: $theme-discount-60;
  }
  &--70 &__inner, &--70 &__inner__content__col1 {
    background: $theme-discount-70;
  }
  &--80 &__inner, &--80 &__inner__content__col1 {
    background: $theme-discount-80;
  }
  &--90 &__inner, &--90 &__inner__content__col1 {
    background: $theme-discount-90;
  }
}

</style>
