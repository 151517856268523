import axios from 'axios';
import store from './store';

let BASE_URL = process.env.VUE_APP_ILM_API_URL;

// for local testing
if (process.env.NODE_ENV !== 'production') {
  BASE_URL = 'https://staging.agende.online/api/b2c/';
  //BASE_URL = 'https://agende.online/api/b2c/';
  //BASE_URL = '/api/b2c/';
}

const httpAxios = axios.create({
  baseURL: BASE_URL,
  //timeout: 1000,
  /*headers: {
    //'Ilm-Token': this.$store.getToken(),
    'content-type': 'application/json',
  },*/
});

/*
* The interceptor here ensures that we check for the token every time an ajax request is made
*/
httpAxios.interceptors.request.use(
  config => {
    const auth = store.getters.getAuth;

    if (auth && auth.token !== null && auth.token !== '') {
      config.headers['Ilm-Token'] = auth.token;
    }
    config.headers.Authorization = '';

    if (config.method === 'get' && auth && auth.business && auth.business.permalink) {
      config.params = { ...config.params, permalink: auth.business.permalink };
    }

    //config.headers['Content-Type'] = 'application/json';

    return config;
  },
  error => {
    console.error(`http-common.js: Error - ${error}`);
    return Promise.reject(error);
  },
);

export default httpAxios;
