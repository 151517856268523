import Appointment from './appointment';

class AppointmentList {
  constructor() {
    this.appointments = [];
  }

  getAppointments() {
    return this.appointments;
  }

  getAppointmentsDate() {
    return this.appointments.length > 0 ? this.appointments[0].date : null;
  }

  addService(service) {
    if (!this.appointments) {
      this.appointments = [];
    }
    if (this.appointments.filter(x => x.service.id === service.id).length > 0) {
      return false;
    }
    const appointment = new Appointment();
    appointment.service = service;

    this.appointments.push(appointment);
    return true;
  }

  removeAppointment(index) {
    if (index < this.appointments.length) {
      this.appointments.splice(index, 1);
    }
  }

  removeAppointmentItem(appointment) {
    for (let i = 0; i < this.appointments.length; i++) {
      const item = this.appointments[i];
      if (appointment.service.id === item.service.id
        && appointment.time === item.time) {
        this.appointments.splice(i, 1);
        break;
      }
    }
  }

  resetProfessionals() {
    this.appointments = this.appointments.map(item => {
      item.professional = null;
      return item;
    });
  }

  updateAppointments(aps) {
    this.appointments = aps;
  }
}

export default AppointmentList;
