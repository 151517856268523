<template>
  <div class="verify-phone">
    <Sticky
      ref="sticky"
      :show-shadow="true">
      <NavbarPhoneBreadcrumb
        :step="step"
        :show-ok="step === 0"
        :on-ok="clickNavbarOk"
        :disable-btn-ok="navbarOkDisabled"
        :title="navbarTitle"
        :subtitle="navbarSubtitle"
        :is-loading="isLoading"
        show-back-bevel />
    </Sticky>
    <div class="verify-phone__content">

      <IlmIconValidation
        :step="step"
        :is-animating-transition="isAnimatingStep"
        class="verify-phone__content__icon-container" />
      <!--div
        :class="iconContainerClasses"
        class="verify-phone__content__icon-container">
        <IlmIcon
          :icon="mainIcon"
          class="verify-phone__content__icon-container__icon"
          size="xl" />
        <IlmIcon
          icon="check"
          class="verify-phone__content__icon-container__icon
          verify-phone__content__icon-container__icon--check"
          size="xl" />
        <img
          :src="mainIcon"
          class="verify-phone__content__icon-container__icon" >
        <img
          src="/images/validate-phone/check.svg"
          class="verify-phone__content__icon-container__icon
          verify-phone__content__icon-container__icon--check" >
      </div-->

      <transition
        name="fade"
        mode="out-in">

        <!-- REQUEST PHONE -->

        <div
          v-if="currentStep === 0"
          key="phone"
          class="verify-phone__content__edit_phone">

          <h1 class="verify-phone__title">
            {{ $t('title.phone_validation') }}
          </h1>
          <p
            class="verify-phone__content__edit_phone__note"
            v-html="phoneValidationMessage" />

          <div class="section phone-section">
            <PhoneBox
              ref="phonebox"
              :on-change="onPhoneTextChange" />

            <div
              class="verify-phone__content__edit_phone__checkbox_container">
              <div
                class="verify-phone__content__edit_phone__checkbox_container__checkbox"
                @click="onCheckboxClick">
                <IlmIcon
                  :icon="iconCheckbox"
                  size="lg"
                  :class="checkboxClasses" />
              </div>
              <p
                class="verify-phone__content__edit_phone__checkbox_container__text">
                <span
                  @click="onCheckboxClick">
                  {{ $t('text.accept_terms') }}
                </span>
                <a
                  href="https://cdn.agende.online/terms.html"
                  target="_blank"
                  class="verify-phone__content__edit_phone__checkbox_container__text__tc">
                  {{ $t('voucher.voucher_details_confirm_checkbox_tc') }}
                </a>
              </p>
            </div>
          </div>

          <!--IlmButton
            ref="verifyphone"
            :on-click="clickValidate"
            :is-working="isValidatingPhone"
            disabled
            t-key="button.verify_phone"
            prevent-default
            theme="block success"
            class="verify-phone__content__edit_phone__verify_button"/-->

        </div>

        <!-- CONFIRM PHONE CODE -->

        <div
          v-else-if="currentStep === 1"
          key="pin"
          class="verify-phone__content__confirm">

          <!--Navbar
            :subtitle="$t('title.phone_validation_sent')"
            class="verify-phone__content__confirm__navbar"
            show-back /-->

          <h1 class="verify-phone__title">
            {{ headerConfirmationTitle }}
          </h1>

          <!--p
            class="verify-phone__content__confirm__note"
            v-html="$t('notes.phone_validation_code', { phone: phoneFull })" /-->

          <div class="section pin-section">

            <PinPad
              ref="pinpad"
              :is-loading="isValidatingPin"
              :on-complete="validatePin"
              focusable />

          </div>

          <p
            v-if="retriesCount > 0"
            class="verify-phone__content__confirm__question"
            v-html="retryMessage" />

          <p
            v-if="timer"
            class="verify-phone__content__confirm__question">
            {{ $t('notes.phone_validation_wait') }}
          </p>
          <p
            v-else-if="retriesCount === 0"
            class="verify-phone__content__confirm__question">
            {{ $t('notes.phone_validation_code_question') }}
            <IlmButton
              :on-click="clickResendCode"
              t-key="button.resend_code"
              prevent-default
              theme="simple"
              class="verify-phone__content__confirm__question__button" />
          </p>
          <transition
            v-else
            name="fade">
            <IlmButton
              v-if="showCallButton"
              :on-click="clickCall"
              :label="callButtonTitle"
              icon="phone"
              icon-position="left"
              prevent-default
              theme="block info"
              class="verify-phone__content__confirm__question__call_button" />
            <div
              v-else
              class="verify-phone__content__confirm__question__call_container">
              <p
                class="verify-phone__content__confirm__question__call_container__note">
                {{ $t('notes.phone_business_call') }}
              </p>
              <p
                class="verify-phone__content__confirm__question__call_container__phone"
                v-html="businessPhoneText" />
            </div>
          </transition>
        </div>

        <!-- SAVE CLIENT NAME -->

        <div
          v-else-if="currentStep === 2"
          key="client_name"
          class="verify-phone__content__edit_name">

          <!--Navbar
            :subtitle="$t('title.name_input')"
            class="verify-phone__content__confirm__navbar"/-->

          <h1 class="verify-phone__title">
            {{ $t('title.name_input') }}
          </h1>

          <p
            class="verify-phone__content__edit_phone__note"
            v-html="nameValidationMessage" />

          <div class="section phone-section">
            <SectionInput
              ref="namebox"
              :header="$t('section_list.client_name')"
              :caps-first-letter="true"
              :placeholder="$t('placeholders.name_box')"
              :on-change="onNameTextChange"
              icon="face" />
          </div>

          <IlmButton
            ref="verifyname"
            :on-click="clickValidateName"
            :is-working="isValidatingName"
            disabled
            t-key="button.verify_name"
            prevent-default
            theme="block success"
            class="verify-phone__content__edit_phone__verify_button" />

        </div>

      </transition>
    </div>
    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />
    <AlertDestructive
      v-if="alertConfirmShowing"
      :title="alertConfirmTitle"
      :message="alertConfirmMessage"
      :btn-cancel="alertConfirmCancel"
      :btn-destructive="alertConfirmOk"
      @closed="onAlertConfirmDismissed"
      @cancel="onAlertConfirmDismissed"
      @ok="onAlertConfirmOk" />
  </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import NavbarPhoneBreadcrumb from '@/components/Nav/NavbarPhoneBreadcrumb.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import PhoneBox from '@/components/Inputs/PhoneBox.vue';
import SectionInput from '@/components/Inputs/SectionInput.vue';
import PinPad from '@/components/IlmPinPad/PinPad.vue';
import IlmIconValidation from '@/components/IlmIcon/IlmIconValidation.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';
import DiscountList from '@/models/discountList';
import Appointment from '@/models/appointment';
import utils from '@/utils/utils';
import Error from '@/graphql/error';
import jwt from '@/utils/jwt';

const WAIT_FIRST_SEND = 30;
const WAIT_SECOND_SEND = 60;
//const WAIT_FIRST_SEND = 5;
//const WAIT_SECOND_SEND = 10;

const SUCCESS_DELAY_ANIMATION = 1600;

const STEP_PHONE_INPUT = 0;
const STEP_CODE_INPUT = 1;
const STEP_NAME_INPUT = 2;

export default {
  name: 'VerifyPhone',

  // Components ********************************
  components: {
    NavbarPhoneBreadcrumb,
    IlmButton,
    PhoneBox,
    SectionInput,
    PinPad,
    IlmIconValidation,
    IlmIcon,
    AlertDestructive,
    Alert,
  },

  // Before Route Leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      if (this.isValidatingPin || this.isValidatingPhone || this.isValidatingName) {
        next(false);
        return;
      }
      if (!this.phone) {
        // phone input view
        if (this.$refs.phonebox && this.$refs.phonebox.modalCountriesShowing) {
          next(false);
          this.$refs.phonebox.closeCountryModal();
        } else if (this.alertShowing || this.alertConfirmShowing) {
          next(false);
          this.alertShowing = false;
          this.alertConfirmShowing = false;
        } else if (this.$store.getters.isExternalSource) {
          this.$store.dispatch('setCanResumeVerification', false);
          next();
          /*
          if (this.previousRouteName
            && this.$refs.phonebox && this.$refs.phonebox.getPhoneNumber().length > 0) {
            next(false);
            const self = this;
            this.alertConfirm(
              this.$t('alerts.verifiy_phone_go_back_title'),
              this.$t('alerts.verify_starting_point_message'),
              this.$t('alerts.verify_starting_point_ok'),
              this.$t('alerts.verify_starting_point_cancel'),
              function () {
                self.$store.dispatch('setCanResumeVerification', false);
                self.$router.replace({ name: self.$constants.ROUTE_LOGIN });
              }
            );
          } else {
            this.$store.dispatch('setCanResumeVerification', false);
            next();
          }*/
        } else if (this.isMyBookingsEndpoint) {
          next(false);
          const fbMessenger = this.$store.getters.getMessenger;
          if (fbMessenger.isMessenger && fbMessenger.inFrame) {
            window.MessengerExtensions.requestCloseBrowser(function success() {
              // webview closed
            }, function error() {
              // an error occurred
            });
          } else {
            this.$store.dispatch('setCanResumeVerification', false);
            this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
          }
        } else {
          next(false);
          this.alert(this.$t('alerts.verifiy_phone_go_back_title'), this.$t('alerts.verifiy_phone_go_back_message'));
        }
      } else {
        next(false);
        // confirmation code view or name view
        const self = this;
        if (this.previousRouteName) {
          this.alertConfirm(
            this.$t('alerts.verifiy_phone_go_back_title'),
            this.$t('alerts.verify_starting_point_message'),
            this.$t('alerts.verify_starting_point_ok'),
            this.$t('alerts.verify_starting_point_cancel'),
            function () {
              self.$store.dispatch('setCanResumeVerification', false);
              self.$router.replace({ name: self.$constants.ROUTE_LOGIN }, () => {});
            }
          );
        } else if (this.currentStep === STEP_NAME_INPUT) {
          this.alertConfirm(
            this.$t('alerts.verifiy_phone_go_back_title'),
            this.$t('alerts.verifiy_phone_name_go_back_message'),
            this.$t('alerts.alert_generic_ok'),
            this.$t('alerts.alert_cancel_appointment_title'),
            function () {
              self.$store.dispatch('setCanResumeVerification', false);
              self.$router.replace({ name: self.$constants.ROUTE_LOGIN }, () => {});
            }
          );
        } else {
          this.alertConfirm(
            this.$t('alerts.verifiy_phone_go_back_title'),
            this.$t('alerts.verifiy_phone_code_go_back_message'),
            this.$t('alerts.alert_generic_ok'),
            this.$t('alerts.alert_cancel_appointment_title'),
            function () {
              self.$store.dispatch('setCanResumeVerification', false);
              self.setPhoneState();
            }
          );
        }
        //this.setPhoneState();
      }
    } else {
      this.$store.dispatch('setCanResumeVerification', false);
      next();
    }
  },

  // Props ********************************
  props: {
    endpoint: String,
    previousRouteName: String,
  },

  data() {
    return {
      auth: null,
      business: null,
      appointments: null,
      navbarOkDisabled: true,

      currentStep: -1,
      phone: null,
      phoneFull: null,
      countryCode: null,
      isCheckboxSelected: false,

      isAnimatingStep: false,
      isValidatingPhone: false,
      isValidatingPin: false,
      isValidatingName: false,
      validatingPinSuccess: false,

      confirmationToken: null,
      retriesCount: 0,
      timer: null,
      timeLeft: null,

      savedIntent: null,

      alertShowing: false,
      alertTitle: null,
      alertMessage: null,

      alertConfirmShowing: false,
      alertConfirmTitle: null,
      alertConfirmMessage: null,
      alertConfirmOk: null,
      alertConfirmCancel: null,
      alertCallback: Function,
    };
  },

  // Computed ********************************
  computed: {
    step() {
      return this.currentStep;
      /*
      if (this.validatingPinSuccess) {
        return 2;
      }
      if (this.showPhoneLayout) {
        return 0;
      }
      if (this.showPinLayout) {
        return 1;
      }
      return 2;*/
    },
    navbarTitle() {
      if (this.step === 1) {
        return this.$t('title.phone_validation_sent_to');
      }
      return null;
    },
    navbarSubtitle() {
      if (this.step === 1) {
        if (this.auth && this.auth.client.phoneCountry && this.auth.client.phoneNumber) {
          const cc = this.auth.client.phoneCountry;
          const p = this.auth.client.phoneNumber;
          const formatter = new AsYouType();
          const phoneFormated = formatter.input(`+${cc}${p}`);
          if (this.business && this.business.phoneCountry && this.business.phoneCountry !== cc) {
            return phoneFormated;
          }
          return phoneFormated.replace(`+${cc}`, '');
        }
      }
      return null;
    },
    headerConfirmationTitle() {
      if (!this.isMyBookingsEndpoint && !this.endpoint && this.appointments) {
        return this.$t('title.phone_validation_sent_new_appointment');
      }
      return this.$t('title.phone_validation_sent');
    },
    iconCheckbox() {
      return this.isCheckboxSelected ? 'check_box' : 'check_box_outline_blank';
    },
    checkboxClasses() {
      const classes = {};
      classes['verify-phone__content__edit_phone__checkbox_container__checkbox__icon'] = true;
      classes['verify-phone__content__edit_phone__checkbox_container__checkbox__icon--checked'] = this.isCheckboxSelected;
      return classes;
    },
    iconContainerClasses() {
      return {
        'verify-phone__content__icon-container--animate': this.isAnimatingStep,
      };
    },
    mainIcon() {
      let name = 'smiley-01';
      if (this.step === 0) {
        name = 'cell';
      }
      if (this.step === 1) {
        name = 'lock';
      }
      return `/images/validate-phone/${name}.svg`;
      /*
      if (this.step === 0) {
        return 'smartphone';
      }
      if (this.step === 1) {
        return 'vpn_key';
      }
      return 'face';
      */
    },
    isMyBookingsEndpoint() {
      return (this.endpoint && this.endpoint === this.$constants.SOURCE_LOCATION_MY_BOOKINGS)
        || (this.previousRouteName
          && this.previousRouteName === this.$constants.SOURCE_LOCATION_MY_BOOKINGS);
    },
    isVoucherDetailsEndpoint() {
      return (this.endpoint && this.endpoint === this.$constants.ROUTE_PAYMENT_DETAILS)
        || (this.previousRouteName
          && this.previousRouteName === this.$constants.ROUTE_PAYMENT_DETAILS);
    },
    title() {
      return this.phone ? this.$t('title.phone_validation_sent', { cel: this.phone }) : this.$t('title.phone_validation');
    },
    phoneValidationMessage() {
      //return this.isMyBookingsEndpoint ?
      //this.$t('notes.phone_validation_my_bookings') : this.$t('notes.phone_validation');
      return this.$t('notes.phone_validation_note');
    },
    nameValidationMessage() {
      return this.$t('notes.name_validation');
    },
    showPhoneLayout() {
      return !this.showPinLayout && !this.showNameLayout && !this.isValidatingPin;
    },
    showPinLayout() {
      return this.auth
        && (this.auth.needCodeConfirmation() || this.isValidatingPin)
        && this.auth.client.phoneCountry
        && this.auth.client.phoneNumber;
    },
    showNameLayout() {
      return this.auth && !this.isValidatingPin && this.auth.needNameConfirmation();
      //&& (this.auth.needNameConfirmation() || this.auth.needNameConfirmationAtBooking());
    },
    retryMessage() {
      if (this.retriesCount > 0 && !this.timer) {
        return this.$t('notes.phone_validation_retry_error');
      }
      return this.$t('notes.phone_validation_retry_message');
    },
    callButtonTitle() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        const business = auth.business;
        if (business.name) {
          return this.$t('button.contact_business', { name: business.name });
        }
      }
      return this.$t('button.contact_business_unknown');
    },
    showCallButton() {
      return utils.isMobile();
    },
    businessPhoneText() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        const business = auth.business;
        if (business.phoneCountry && business.phoneNumber) {
          const formatter = new AsYouType();
          const fullPhone = formatter.input(`+${business.phoneCountry}${business.phoneNumber}`);
          /*let businessName = '';
          if (auth.businessName) {
            businessName = auth.businessName;
          }
          return this.$t('notes.phone_business_call', { name: businessName, phone: fullPhone });*/
          return fullPhone;
        }
      }
      return this.$t('notes.phone_business_call_unavailable');
    },
    isLoading() {
      return this.isValidatingPhone || this.isValidatingPin || this.isValidatingName;
    },
  },

  // Watch ********************************
  watch: {
    currentStep(val) {
      if (val === STEP_PHONE_INPUT) {
        this.$analytics.track('Visit: Verify phone');
      } else if (val === STEP_CODE_INPUT) {
        this.$analytics.track('Visit: Verify phone code');
      } else if (val === STEP_NAME_INPUT) {
        this.$analytics.track('Visit: Save name');
      }
    },
  },

  created() {
    this.auth = this.$store.getters.getAuth;
  },

  // Mounted ********************************
  mounted() {
    //this.auth = this.$store.getters.getAuth;
    if (!this.auth || !this.auth.business || !this.auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    this.business = this.auth.business;

    this.appointments = this.$store.getters.getAppointments
      ? this.$store.getters.getAppointments.getAppointments() : null;

    const self = this;
    this.$nextTick(function () {
      if (self.auth.needNameConfirmation()/* || self.auth.needNameConfirmationAtBooking()*/
        && !self.isMyBookingsEndpoint
        && !self.isVoucherDetailsEndpoint) {
        self.setNameState();
        //this.currentStep = 2;
      } else if (self.auth.needCodeConfirmation()) {
        self.setCodeState();
        //this.currentStep = 1;
      } else {
        self.setPhoneState();
        //this.currentStep = 0;
      }
    });

    //window.addEventListener('focus', this.onWindowFocused);

    this.onVisibilityChange(function (visible) {
      if (visible) {
        self.onWindowFocused();
      }
    });
  },

  destroyed() {
    this.invalidateTimer();
    //window.removeEventListener('focus', this.onWindowFocused);
  },

  // Methods ********************************
  methods: {
    onCheckboxClick() {
      this.isCheckboxSelected = !this.isCheckboxSelected;
    },
    onVisibilityChange(callback) {
      let visible = true;

      if (!callback) {
        throw new Error('no callback given');
      }

      function focused() {
        if (!visible) {
          callback(visible = true);
        }
      }

      function unfocused() {
        if (visible) {
          callback(visible = false);
        }
      }

      // Standards:
      if ('hidden' in document) {
        document.addEventListener('visibilitychange',
          function () {
            if (document.hidden) unfocused();
            else focused();
          });
      }
      if ('mozHidden' in document) {
        document.addEventListener('mozvisibilitychange',
          function () {
            if (document.mozHidden) unfocused();
            else focused();
          });
      }
      if ('webkitHidden' in document) {
        document.addEventListener('webkitvisibilitychange',
          function () {
            if (document.webkitHidden) unfocused();
            else focused();
          });
      }
      if ('msHidden' in document) {
        document.addEventListener('msvisibilitychange',
          function () {
            if (document.msHidden) unfocused();
            else focused();
          });
      }
      // IE 9 and lower:
      if ('onfocusin' in document) {
        document.onfocusin = focused;
        document.onfocusout = unfocused;
      }
      // All others:
      // eslint-disable-next-line no-multi-assign
      window.onpageshow = window.onfocus = focused;
      // eslint-disable-next-line no-multi-assign
      window.onpagehide = window.onblur = unfocused;
    },
    onWindowFocused() {
      if (this.savedIntent
        && this.savedIntent.id
        && this.$localStorage.isIntentIdProcessed(this.savedIntent.id) === false) {
        this.alert(this.$t('alerts.alert_user_already_confirmed_title'),
          this.$t('alerts.alert_user_already_confirmed'),
          () => {
            this.$store.dispatch('setCanResumeVerification', false);
            this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
          });
      }
    },
    onPhoneTextChange(txt) {
      //const btn = this.$refs.verifyphone;

      if (txt && txt.length > 2) {
        //btn.setDisabled(false);
        this.navbarOkDisabled = false;
      } else {
        //btn.setDisabled(true);
        this.navbarOkDisabled = true;
      }
    },
    onNameTextChange(txt) {
      const btn = this.$refs.verifyname;

      if (txt && txt.length >= 2) {
        btn.setDisabled(false);
        this.navbarOkDisabled = false;
      } else {
        btn.setDisabled(true);
        this.navbarOkDisabled = true;
      }
    },
    setPhoneState() {
      /*if (this.auth) {
        this.auth.setPhoneConfirmation();
      }*/
      this.currentStep = STEP_PHONE_INPUT;
      //this.$analytics.track('Visit: Verify phone');
      this.navbarOkDisabled = true;
      this.phone = null;
      this.confirmationToken = null;
      this.invalidateTimer();
    },
    setCodeState() {
      /*if (this.auth) {
        this.auth.setCodeConfirmation();
      }*/
      this.currentStep = STEP_CODE_INPUT;
      //const auth = this.$store.getters.getAuth;
      if (this.auth && this.auth.client.phoneCountry && this.auth.client.phoneNumber) {
        this.countryCode = this.auth.client.phoneCountry;
        this.phone = this.auth.client.phoneNumber;
        const formatter = new AsYouType();
        this.phoneFull = formatter.input(`+${this.countryCode}${this.phone}`);
      }
      this.retriesCount = 1;
    },
    setNameState() {
      this.currentStep = STEP_NAME_INPUT;
      this.navbarOkDisabled = true;
      if (this.auth && this.auth.client.phoneCountry && this.auth.client.phoneNumber) {
        this.countryCode = this.auth.client.phoneCountry;
        this.phone = this.auth.client.phoneNumber;
        const formatter = new AsYouType();
        this.phoneFull = formatter.input(`+${this.countryCode}${this.phone}`);
      }
    },
    setTokenLocal(token) {
      if (this.$store.getters.isExternalSource) {
        this.$localStorage.setToken(token);
      }
    },
    clickNavbarOk() {
      if (this.currentStep === STEP_PHONE_INPUT) {
        this.clickValidatePhone();
      } else if (this.currentStep === STEP_NAME_INPUT) {
        this.clickValidateName();
      }
      /*
      if (this.showPhoneLayout) {
        this.clickValidatePhone();
      } else if (this.showNameLayout) {
        this.clickValidateName();
      }*/
    },

    clickValidatePhone() {
      if (this.isCheckboxSelected === false) {
        this.$analytics.track('Event: Must accept terms & conditions');
        this.alert(this.$t('errors.payment_generic_title'), this.$t('errors.must_accept_terms_conditions'));
        return;
      }
      const input = this.$refs.phonebox;

      if (input.checkValid() === false) {
        //const btn = this.$refs.verifyphone;
        //btn.setDisabled(true);
        this.navbarOkDisabled = true;
        this.$analytics.track('Event: Invalid phone');
        return;
      }

      //this.$analytics.track('Visit: Verify phone code');

      const phoneNumber = input.getPhoneNumber();
      this.phoneFull = input.getFullPhoneNumber();
      this.countryCode = input.getCountryCode();

      if (!this.countryCode || this.countryCode === '') {
        this.alert(this.$t('errors.generic_title'), this.$t('alerts.verifiy_phone_country_code_missing'));
        return;
      }

      const self = this;
      this.isValidatingPhone = true;

      this.getPhoneConfirmationCode(this.countryCode, phoneNumber, function () {
        /*self.setTokenLocal(token);
        self.confirmationToken = token;
        const auth = jwt.parseToken(token);
        self.$store.dispatch('updateAuth', auth);
        self.auth = auth;*/

        self.phone = phoneNumber;
        self.currentStep = STEP_CODE_INPUT;
        self.isValidatingPhone = false;
        if (self.retriesCount === 0) {
          self.setTimer(WAIT_FIRST_SEND);
        }
      }, function (error) {
        self.isValidatingPhone = false;
        if (error.getCode() === Error.businessRestrictedUserAccessCode) {
          self.$router.replace({
            name: self.$constants.ROUTE_ERROR,
          }, () => {});
        } else {
          if (self.phoneFull) {
            self.$analytics.track('Event: Error phone token', { phone: self.phoneFull });
          } else {
            self.$analytics.track('Event: Error phone token');
          }
          self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        }
      });
    },

    clickValidateName() {
      const input = this.$refs.namebox;
      const name = input.getText();

      const self = this;
      this.isValidatingName = true;

      this.$graphql.updateCustomerName(name, function () {
        self.$analytics.track('Save name: Success');
        self.getCurrentClient();
      }, function () {
        self.$analytics.track('Save name: Error');
        self.isValidatingName = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
    },

    setTimer(limit) {
      this.invalidateTimer();
      this.timeLeft = limit;
      const self = this;
      this.timer = setInterval(() => {
        self.timeLeft -= 1;
        if (self.timeLeft <= 0) {
          self.invalidateTimer();
        }
      }, 1000);
    },
    invalidateTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
    },
    clearCodeField() {
      const input = this.$refs.pinpad;
      if (input) {
        input.clear();
      }
    },

    clickResendCode() {
      const self = this;
      this.clearCodeField();
      if (!this.countryCode || this.countryCode === '') {
        this.alert(this.$t('errors.generic_title'), this.$t('alerts.verifiy_phone_country_code_missing'));
        return;
      }
      this.$root.loading = true;

      this.$analytics.track(`Action: Resend code #${this.retriesCount}`);
      this.getPhoneConfirmationCode(this.countryCode, this.phone, function () {
        /*self.setTokenLocal(token);
        self.confirmationToken = token;
        const auth = jwt.parseToken(token);
        self.$store.dispatch('updateAuth', auth);
        self.auth = auth;*/
        self.retriesCount += 1;
        self.setTimer(WAIT_SECOND_SEND);
        self.$root.loading = false;
      }, function () {
        if (self.phoneFull) {
          self.$analytics.track('Event: Error phone token', { phone: self.phoneFull });
        } else {
          self.$analytics.track('Event: Error phone token');
        }
        self.$root.loading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
    },
    getPhoneConfirmationCode(countryCode, phone, success, failed, sendPhoneCodeSms) {
      const intent = {};
      if (this.isMyBookingsEndpoint) {
        intent.endpoint = this.$constants.ROUTE_MY_BOOKINGS;
      } else if (this.isVoucherDetailsEndpoint) {
        intent.endpoint = this.$constants.ROUTE_PAYMENT_DETAILS;
        intent.params = this.$store.getters.getCustomer;
      } else if (this.appointments) {
        intent.endpoint = this.$constants.ROUTE_NEW_APPOINTMENT_CONFIRMATION;
        const appointmentsArray = [];
        this.appointments.forEach(a => {
          const obj = {
            day: a.day,
            time: a.time,
            serviceId: a.service.id,
          };
          if (a.professional) {
            obj.professionalId = a.professional.id;
          }
          appointmentsArray.push(obj);
        });
        intent.params = appointmentsArray;
      }
      intent.id = new Date().getTime();
      if (this.auth && this.auth.source) {
        intent.source = this.auth.source;
      }

      const self = this;
      this.$graphql.getPhoneConfirmationToken(countryCode, phone, sendPhoneCodeSms, intent,
        function (token) {
          self.savedIntent = intent;
          /*self.isAnimatingStep = true;
          setTimeout(function () {
            self.isAnimatingStep = false;
            self.setTokenLocal(token);
            self.confirmationToken = token;
            const auth = jwt.parseToken(token);
            self.$store.dispatch('updateAuth', auth);
            self.auth = auth;
            success(token);
          }, SUCCESS_DELAY_ANIMATION);*/
          self.setTokenLocal(token);
          self.confirmationToken = token;
          const auth = jwt.parseToken(token);
          self.$store.dispatch('updateAuth', auth);
          self.auth = auth;
          success(token);
        }, failed);
    },
    saveMixpanelUser(auth) {
      try {
        if (this.$store.getters.isExternalSource) {
          this.$analytics.alias(auth.client.customerId);
          setTimeout(() => {
            this.$analytics.identify(auth.client.customerId);
          }, 500);
        }
        this.$analytics.register({
          clientName: auth.client.name,
          clientId: auth.client.id,
          clientPhone: this.phoneFull,
          customerId: auth.client.customerId,
        });
        this.$analytics.setPeopleParam('name', auth.client.name);
        if (this.phoneFull) {
          this.$analytics.setPeopleParam('phone', this.phoneFull);
        }
        this.$analytics.setPeopleParam('clientId', auth.client.id);
        this.$analytics.setPeopleParam('clientProfileId', auth.client.customerId);
      } catch (ex) {
        console.log(ex);
      }
    },

    validatePin(pin) {
      const self = this;
      //this.$root.loading = true;
      this.isValidatingPin = true;

      this.$graphql.confirmPhone(pin, function (token) {
        if (token) {
          self.setTokenLocal(token);
          const auth = jwt.parseToken(token);
          self.$store.dispatch('updateAuth', auth);
          self.auth = auth;
          self.$analytics.track('Response: Confirm Phone Success');

          self.getCurrentClient();
        } else {
          self.$analytics.track('Confirm Phone: Token Error');
          //self.$root.loading = false;
          self.isValidatingPin = false;
          self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
            self.clearCodeField();
          });
        }
      }, function (error) {
        //self.$root.loading = false;
        self.isValidatingPin = false;
        if (error.getCode() === Error.invalidConfirmationCode) {
          self.$analytics.track('Confirm Phone: Invalid', { code: pin });

          self.alert(self.$t('errors.invalid_confirmation_code_title'), self.$t('errors.invalid_confirmation_code'), function () {
            self.clearCodeField();
          });
        } else if (error.getCode() === Error.expiredTokenPhoneValidationCode
          && self.phone
          && self.countryCode) {
          self.isValidatingPin = true;
          self.getPhoneConfirmationCode(self.countryCode, self.phone, function () {
            self.validatePin(pin);
          }, function () {
            self.isValidatingPin = false;
            self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
              self.clearCodeField();
            });
          }, false);
        } else {
          self.$analytics.track('Response: Confirm Phone Error', { code: pin });
          self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
            self.clearCodeField();
          });
        }
      });
    },

    getCurrentClient() {
      const self = this;
      this.$graphql.getCurrentCustomer(false, function (obj) {
        self.auth.updateCurrentClient(obj);
        self.$store.dispatch('updateAuth', self.auth);
        self.$analytics.track('Response: Current Client Success');
        self.isAnimatingStep = true;
        setTimeout(function () {
          if (self.auth.needNameConfirmation()
            && !self.isMyBookingsEndpoint
            && !self.isVoucherDetailsEndpoint) {
            self.isValidatingPin = false;
            self.isAnimatingStep = false;
            self.setNameState();
            //self.$root.loading = false;
          } else {
            self.validatingPinSuccess = true;
            //self.isValidatingName = false;
            self.saveMixpanelUser(self.auth);
            self.finished();
          }
        }, SUCCESS_DELAY_ANIMATION);
      }, function () {
        self.isValidatingName = false;
        self.isValidatingPin = false;
        self.$analytics.track('Response: Current Client Error');
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
          self.clearCodeField();
        });
      });
    },

    getDiscounts() {
      const self = this;
      this.$graphql.businessDiscountsQuery(function (discounts) {
        self.$analytics.track('Response: Discounts Success');
        self.$store.dispatch('setDiscounts', discounts);
        /*
        if (discounts && discounts.length > 0) {
          const dayDiscounts = discounts.getServiceDateDiscounts(self.appointment.service.id);

          //const discount = discounts.getDiscount();
          const day = self.appointment.day;
          const date = self.$moment(day);
          if (dayDiscounts && date) {
            const weekday = date.isoWeekday();
            const dayDiscount = dayDiscounts[weekday];
            if (dayDiscount) {
              const discountValue = dayDiscount.getHourDiscount(
                weekday,
                self.appointment.time
              );
              if (discountValue) {
                self.appointment.discount = dayDiscount;
              }
            }
          }
        }*/
        self.saveAppointments(discounts);
      }, function () {
        self.$analytics.track('Response: Discounts Error');
        self.$store.dispatch('setDiscounts', null);
        self.discounts = new DiscountList();
        self.saveAppointments();
      });
    },


    saveAppointments(discounts) {
      if (discounts && discounts.getItems().length > 0) {
        let item = null;
        let weekday = null;
        let serviceDiscounts = null;
        let discountValue = null;
        let maxDiscount = 0;
        let selectedDiscount = null;

        for (let i = 0; i < this.appointments.length; i++) {
          item = this.appointments[i];
          weekday = this.$moment(item.day).isoWeekday();
          serviceDiscounts = discounts.getServiceDiscounts(weekday, item.service.id);
          maxDiscount = 0;
          if (serviceDiscounts && serviceDiscounts.length > 0) {
            for (let j = 0; j < serviceDiscounts.length; j++) {
              const d = serviceDiscounts[j];
              discountValue = d.getHourDiscount(weekday, item.time);
              if (discountValue > maxDiscount) {
                maxDiscount = discountValue;
                selectedDiscount = d;
              }
            }
            item.service.discount = selectedDiscount;
          }
        }
      }


      this.$analytics.track('Request: Create Appointment');

      //this.$router.app.loading = true;
      const self = this;
      const appointments = [];

      this.appointments.forEach(item => {
        const appointment = new Appointment();
        appointment.date = item.date;
        appointment.day = item.day;
        appointment.time = item.time;
        appointment.service = item.service;
        appointment.professional = item.professional;

        if (item.service.discount) {
          appointment.discount = item.service.discount;
        }
        appointments.push(appointment);
      });

      this.$graphql.createAppointments(appointments,
        function (appointmentsPost) {
          self.sendAppointmentSuccess(appointments, appointmentsPost, false);
        },
        function (error) {
          self.isValidatingPin = false;
          self.$root.loading = false;

          self.$store.getters.getAppointments.appointments.forEach(item => {
            item.day = null;
            item.time = null;
          });

          const errorCode = error.getCode();
          if (errorCode === Error.unavailableSlotCode) {
            self.alert(self.$t('errors.generic_title'),
              self.$t('errors.slot_unavailable'), function () {
                self.$router.replace({ name: self.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
              });
            self.$analytics.track('Error: unavailableSlotCode', {});
          } else if (errorCode === Error.unavailableProfessionalOverrideCode) {
            self.alert(self.$t('errors.payment_generic_title'),
              self.$t('errors.professional_override'), function () {
                self.$router.replace({ name: self.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
              });
            self.$analytics.track('Error: unavailableProfessionalOverrideCode', {});
          } else {
            self.alert(self.$t('errors.generic_title'),
              self.$t('errors.appointment_after_validation'), function () {
                self.$router.replace({ name: self.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
              });
          }
        });
    },

    sendAppointmentSuccess(appointments, appointmentsPost, isEditing) {
      this.$analytics.trackNewBookingEvent(this.auth.business.id);

      const discounts = this.$store.getters.getDiscounts;
      const professionals = this.$store.getters.getProfessionals;

      /*
      const keys = appointmentsPost.map(x => x.key);
      try {
        const now = this.$moment(new Date()).startOf('day');
        const day = appointments[0].day;
        const comandaDate = this.$moment(day, 'YYYY-MM-DD');
        const diff = comandaDate.startOf('day').diff(now, 'days');
        keys.forEach(key => {
          this.$analytics.track(mixpanelAction, {
            key,
            dayDiff: diff,
            campaignId: this.auth.campaignId,
          });
        });
      } catch (ex) {
        console.log(ex);
        keys.forEach(key => {
          this.$analytics.track(mixpanelAction, {
            key,
            campaignId: this.auth.campaignId,
          });
        });
      }*/

      let diff = null;
      try {
        const now = this.$moment(new Date()).startOf('day');
        const day = appointments[0].day;
        const comandaDate = this.$moment(day, 'YYYY-MM-DD');
        diff = comandaDate.startOf('day').diff(now, 'days');
      } catch (ex) {
        console.log(ex);
      }

      const mixpanelAction = isEditing ? 'Response: Appointment Edited' : 'Response: Appointment Created';
      const changedAppointments = [];
      for (let i = 0; i < appointmentsPost.length; i++) {
        const item = appointmentsPost[i];

        this.$analytics.track(mixpanelAction, {
          key: item.key,
          dayDiff: diff,
          campaignId: this.auth.campaignId,
        });

        const appointment = appointments.filter(
          x => x.service.id === item.serviceId && x.time === item.time
        )[0];
        if (appointment) {
          const newAppointment = new Appointment();
          newAppointment.key = item.key;
          newAppointment.day = item.day;
          newAppointment.time = item.time;
          newAppointment.service = appointment.service;
          newAppointment.state = 'booked';

          if (professionals) {
            const professionalsFilter = professionals
              .filter(p => p.id === item.professionalId);
            if (professionalsFilter.length > 0) {
              newAppointment.professional = professionalsFilter[0];
            }
          }

          if (discounts) {
            newAppointment.discount = discounts.getDiscountWithId(item.discountId);
          }

          changedAppointments.push(newAppointment);
        }
      }


      if (!isEditing) {
        //appointment.key = appointmentPost.key;
      }
      /*const professionalsFilter = this.professionals
        .filter(item => item.id === appointmentsPost.professionalId);
      if (professionalsFilter.length > 0) {
        appointment.professional = professionalsFilter[0];
      }*/
      //this.$store.dispatch('setAppointmentParams', appointment);

      this.$root.loading = false;
      this.isValidatingPin = false;
      this.$router.replace({
        name: this.$constants.ROUTE_SUCCESS,
        //params: { isEditMode: this.isEditMode },
        params: {
          isEditMode: false,
          appointments: changedAppointments,
        },
      }, () => {});
    },
    /*saveAppointments() {
      this.$analytics.track('Request Create Appointment');
      const self = this;
      this.$graphql.createAppointment(this.appointment,
        function (appointmentPost) {
          //self.isLoading = false;
          //self.$router.app.loading = false;
          self.appointment.key = appointmentPost.key;

          const now = self.$moment(new Date()).startOf('day');
          const aDate = self.$moment(self.appointment.day).startOf('day');
          const diff = aDate.diff(now, 'days');
          let campaignId = null;
          if (self.auth && self.auth.campaignId) {
            campaignId = self.auth.campaignId;
          }
          self.$analytics.track('Appointment Created', {
            key: appointmentPost.key,
            dayDiff: diff,
            campaignId,
          });

          self.$analytics.trackNewBookingEvent(self.auth.business.id);

          const professionals = self.$store.getters.getProfessionals;
          const professionalsFilter = professionals
            .filter(item => item.id === appointmentPost.professionalId);
          if (professionalsFilter.length > 0) {
            self.appointment.professional = professionalsFilter[0];
          }

          self.$store.dispatch('setAppointmentParams', self.appointment);
          self.$root.loading = false;
          self.isValidatingPin = false;
          self.$router.replace({ name: self.$constants.ROUTE_SUCCESS }, () => {});
        },
        function (error) {
          self.isValidatingPin = false;
          self.$root.loading = false;
          if (error.getCode() === Error.unavailableSlotCode) {
            self.alert(self.$t('errors.generic_title'),
            self.$t('errors.slot_unavailable'), function () {
              self.$router.replace({ name: self.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
            });
          } else {
            self.alert(self.$t('errors.generic_title'),
            self.$t('errors.appointment_after_validation'), function () {
              self.$router.replace({ name: self.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
            });
          }
        });
    },*/

    finished() {
      const self = this;

      if (this.isMyBookingsEndpoint === true) {
        //console.log(`this.isMyBookingsEndpoint: ${this.isMyBookingsEndpoint}`);
        this.$root.loading = false;
        this.isValidatingPin = false;
        this.$router.replace({
          name: self.$constants.ROUTE_MY_BOOKINGS,
          params: { delayedPooling: true },
        }, () => {});
      } else if (this.endpoint && this.endpoint !== '') {
        //console.log(`this.previousRouteName: ${this.previousRouteName}`);
        this.$root.loading = false;
        this.isValidatingPin = false;
        this.$router.replace({
          name: this.endpoint,
        }, () => {});
      } else if (this.previousRouteName && this.previousRouteName !== '') {
        //console.log(`this.previousRouteName: ${this.previousRouteName}`);
        this.$store.dispatch('setCanResumeVerification', false);
        this.$root.loading = false;
        this.isValidatingPin = false;
        this.$router.replace({
          name: this.previousRouteName,
          params: { delayedPooling: true },
        }, () => {});
      } else if (this.appointments) {
        self.getDiscounts();
      } else {
        this.$root.loading = false;
        this.isValidatingPin = false;
        this.$router.replace({
          name: self.$constants.ROUTE_LOGIN,
        }, () => {});
      }
      //this.$root.loading = false;
      //this.$router.push({ name: this.$constants.ROUTE_SUCCESS });
      /*
      this.$root.loading = true;
      const self = this;
      setTimeout(function () {
        self.$root.loading = false;
        self.$router.push({ name: 'success' });
        //self.$router.replace({ name: 'success' });
      }, 2000);
      */
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    alertConfirm(title, message, buttonOk, buttonCancel, callback) {
      this.alertConfirmTitle = title;
      this.alertConfirmMessage = message;
      this.alertConfirmCancel = buttonCancel;
      this.alertConfirmOk = buttonOk;
      this.alertConfirmShowing = true;
      this.alertCallback = callback;
    },
    onAlertConfirmOk() {
      this.alertConfirmShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
    onAlertConfirmDismissed() {
      this.alertConfirmShowing = false;
      this.alertCallback = null;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
    clickCall() {
      this.$analytics.track('Action: Click call business');
      //const auth = this.$store.getters.getAuth;
      if (this.auth) {
        const business = this.auth.business;
        if (business.phoneCountry && business.phoneNumber) {
          const phoneLink = `tel:+${business.phoneCountry}${business.phoneNumber}`;
          window.open(phoneLink);
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@keyframes popout {
    from {transform:scale(1)}
    70% {transform:scale(1.3)}
    to {transform:scale(1)}
}

.verify-phone {
  //height: 100%;
  //min-height: 100vh;
  //background: $theme-3-bg;
  background: white;

  @media screen and (min-width: $min-screen-width) {
    height: initial;
    min-height: initial;
  }

  &__fixed_top {
    background: white;
  }

  &__title {
    font-size: 16px;
    @media screen and (min-width: $min-screen-width) {
      font-size: 18px;
    }
    color: black;
    line-height: 1.2;
    text-align: center;
    padding: 0;
    max-width: 444px;
    margin: 0 auto;
  }

  &__content {
    //padding: 10px $padding-section;
    padding: 0 $padding-section 10px $padding-section;

    &__icon-container {
      display: flex;
      align-items: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      //border: 2px solid $theme-border;
      margin: 0 auto;
      margin-bottom: $padding-section;
      box-sizing: border-box;

      border: 0;
      position: relative;
      /*
      &:before, &:after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 100%;
      }
      &:before {
        border: 2px solid $theme-border;
      }
      //&__animate:before {
      &--animate:before {
        border-top-color: $theme-ilm-selection;
        border-right-color: $theme-ilm-selection;
        border-bottom-color: $theme-ilm-selection;
        //box-shadow: 0px 0px 4px 4px $theme-ilm-selection-shadow-color;

        transition: border-top-color 0.15s linear,
          border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s;
      }
      &:after {
        border: 0 solid transparent;
        box-shadow: none;
      }
      //&__animate:after {
      &--animate:after {
        border-top: 2px solid $theme-ilm-selection;
        border-left-width: 2px;
        border-right-width: 2px;
        transform: rotate(270deg);
        transition: transform 0.4s linear 0s,
          border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
      }
      */

      &__icon {
        font-size: 48px;
        color: $theme-border;
        line-height: 48px;
        margin: 0 auto;
        //transition: opacity 0.25s linear;
        position: absolute;

        &--check {
          //display: none;
          opacity: 0;
          //color: $theme-ilm-selection;
          width: 100%;
          height: auto;
        }
      }

      &--animate ::v-deep &__icon {
        //display: none;
        opacity: 0;

        &--check {
          //display: initial;
          transition: opacity 0.25s linear 0.3s;
          animation: popout 0.3s ease-out 0.3s;
          opacity: 1;
        }
      }

    }

    &__edit_phone {
      text-align: center;

      &__note {
        font-size: 14px;
        color: black;
        text-align: center;
        line-height: 1.2;
        padding: 0;
        margin: 8px 0;
      }

      &__verify_button {
        max-width: 524px;
      }

      &__checkbox_container {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding: 0 12px;
        justify-content: left;

        &__checkbox {
          flex: 0 0 auto;
          color: $theme-2-color;
          padding: 10px 10px 10px 0;
          cursor: pointer;

          @media (hover) {
            &:hover {
              opacity: 0.5;
            }
          }

          &__icon {
            font-size: 26px;

            &--checked {
              color: $theme-ilm-selection;
            }
          }
        }

        &__text {
          color: black;
          font-size: 14px;
          line-height: 1.5;
          margin: 0;
          cursor: pointer;

          &__tc {
            text-decoration: underline;
            cursor: pointer;
            color: $theme-link-color;

            @media (hover) {
              &:hover {
                color: $theme-link-variant;
              }
            }
          }
        }
      }
    }

    &__edit_name {
      text-align: center;
    }

    &__confirm {
      text-align: center;

      &__navbar {
        margin-left: -$padding-section;
        margin-right: -$padding-section;
        margin-top: -$padding-section;
      }

      &__note {
        font-size: 14px;
        line-height: 1.4;
        //font-weight: bold;
        color: black;
        text-align: left;
        padding: 0;
        margin: 8px 0;
        //margin: 8px 0 0 0;
      }

      &__question {
        font-size: 14px;
        color: black;
        margin: 0;
        text-align: center;
        //padding-top: 10px;
        line-height: 1.4;

        &__button {
          //margin-top: 10px;
          font-size: 14px;
          margin-left: -10px;
        }

        &__call_button {
          margin-top: 20px;
          max-width: 524px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &__call_container {
          margin-top: 20px;
          font-family: $heading-font-family;
          font-size: 22px;
          color: $theme-2-color;

          border: 1px solid $theme-2-color;
          border-radius: 8px;
          display: flex;
          padding: 12px;

          &__note {
            flex: 0 0 auto;
            margin: 0;
          }
          &__phone {
            text-align: right;
            margin: 0;

            flex: 1 1 auto;
            min-width: 0;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.pin-section {
  margin-top: 20px;
}

.phone-section {
  padding: $padding-section;
}

.section {
  //background: white;
  margin-left: -$padding-section;
  margin-right: -$padding-section;
  //padding-top: 10px;
  //margin-bottom: 10px;

  //border-bottom: 1px solid $theme-list-divider-color;

  &__header {
    text-align: left;
    position: relative;
    margin-bottom: $padding-section;
    margin-left: $padding-section;
    /*margin-top: 10px;*/
  }

}
</style>
