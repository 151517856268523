<template>
  <div :class="mainClass">
    <Sticky>
      <div :class="`${mainClass}__fixed_top`">
        <Navbar
          :title="businessName"
          :show-shadow="false"
          :show-back="true"
          :on-back="onBackClick"
          show-back-bevel
          show-divider
          is-modal />
      </div>
    </Sticky>

    <div :class="`${mainClass}__content`">
      <AppointmentServiceList
        v-if="sectionList.length > 0"
        :appointments="sectionList"
        :on-item-deleted="handleItemDeleted" />
    </div>

    <div
      ref="footer"
      :class="footerClass">
      <div :class="`${mainClass}__footer__split`">
        <IlmButton
          v-if="sectionList.length < 4"
          :class="`${mainClass}__footer__button
          ${sectionList.length < 4 ? `${mainClass}__footer__button--left` : ''}`"
          :on-click="clickAddService"
          :label="$t('button.services_make_appointment')"
          :icon="addServiceButtonIcon"
          prevent-default
          theme="block success-inverted"
          data-test-id="button-add-service" />
        <div
          v-if="sectionList.length < 4"
          :class="`${mainClass}__footer__separator`" />
        <IlmButton
          v-if="sectionList.length > 0"
          :class="`${mainClass}__footer__button
          ${sectionList.length < 4 ? `${mainClass}__footer__button--right` : ''}`"
          :on-click="clickNext"
          :label="makeAppointmentBtnLabel"
          icon="arrow_forward"
          prevent-default
          theme="block success"
          data-test-id="button-continue" />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import AppointmentServiceList from '@/components/Lists/AppointmentServices/AppointmentServiceList.vue';

export default {
  name: 'NewBookingServices',

  // Components ********************************
  components: {
    Navbar,
    IlmButton,
    AppointmentServiceList,
  },

  data() {
    return {
      business: null,
      sectionList: [],

      windowWidth: null,
      windowHeight: null,
      scrollHeight: null,
      windowScrollY: 0,
      showFooterShadow: false,
    };
  },

  // Computed ********************************
  computed: {
    mainClass() {
      return 'new-booking-services';
    },
    businessName() {
      if (this.$root.isDesktop) {
        return this.headerText;
      }
      if (this.business && this.business.name) {
        return this.business.name;
      }
      return '';
    },
    headerText() {
      return this.$tc('headers.services_counter', this.sectionList.length, {
        count: this.sectionList.length,
      });
    },
    singleServiceName() {
      if (this.sectionList.length === 1) {
        return this.sectionList[0].service.name;
      }
      return '';
    },
    makeAppointmentBtnLabel() {
      /*if (sectionList.length === 1) {

      } else {
        this.$t('button.services_make_appointment')
      }*/
      return this.$tc('button.services_add', this.sectionList.length, {
        count: this.sectionList.length,
      });
    },
    addServiceButtonIcon() {
      return this.windowWidth <= this.$constants.MIN_SCREEN_WIDTH ? 'add' : null;
    },
    footerClass() {
      return {
        'new-booking-services__footer': true,
        'new-booking-services__footer--shadow': this.showFooterShadow,
        'new-booking-services__footer--sticky':
          this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
  },

  // Watch ********************************
  watch: {},

  // Mounted ********************************
  mounted() {
    const appointments = this.$store.getters.getAppointments;
    if (!appointments || appointments.getAppointments().length === 0) {
      this.$router.go(-1);
      return;
    }
    this.sectionList = appointments.getAppointments();

    this.$analytics.track('Visit: Choose Services');

    //const discounts = this.$store.getters.getDiscounts;
    /*this.sectionList.forEach((item) => {
      item.service.discountMax = discounts.getServiceDiscount(item.service.id);
    });*/

    this.$nextTick(() => {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
      this.scrollHeight = document.body.scrollHeight;
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize);
      window.scrollTo(0, 0);
    });
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }
    this.business = auth.business;
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    clickNext() {
      this.$analytics.track('Action: Confirm Services', { services: this.sectionList.length });
      if (this.sectionList.length < 5) {
        this.$store.getters.getAppointments.resetProfessionals();
        this.$router.push({ name: this.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
      }
    },
    clickAddService() {
      this.$analytics.track('Action: Add Service');

      if (this.sectionList.length === 0) {
        this.$router.replace(
          {
            name: this.$constants.ROUTE_SEARCH_SERVICE,
            params: {
              showBusinessServices: true,
              showClientSuggestions: true,
            },
          },
          () => {},
        );
      } else if (this.sectionList.length < 5) {
        this.$router.push(
          {
            name: this.$constants.ROUTE_SEARCH_SERVICE,
            params: {
              showBusinessServices: true,
              showClientSuggestions: true,
            },
          },
          () => {},
        );
      }
    },
    onBackClick() {
      this.$router.replace({ name: this.$constants.ROUTE_BUSINESS_DETAILS }, () => {});
    },
    handleItemDeleted(index) {
      //const appointments = this.$store.getters.getAppointments;
      this.$store.getters.getAppointments.removeAppointment(index);
      this.sectionList = this.$store.getters.getAppointments.getAppointments();
      //this.sectionList.splice(index, 1);
      if (this.sectionList.length === 0) {
        this.clickAddService();
      }
    },
    handleScroll() {
      this.windowScrollY = window.pageYOffset;
      if (this.$refs.footer) {
        if (this.windowWidth > this.$constants.MIN_SCREEN_WIDTH) {
          const offset = this.$refs.footer.offsetTop;
          const height = this.$refs.footer.offsetHeight;
          const parentHeight = this.$refs.footer.offsetParent.clientHeight;
          this.showFooterShadow = offset + height < parentHeight;
        } else {
          const height = document.body.clientHeight;
          this.showFooterShadow = this.windowScrollY + height < document.body.scrollHeight;
        }
      }
    },
    handleResize() {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
      this.scrollHeight = document.body.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-booking-services {
  height: 100%;
  min-height: 100vh;
  background: $theme-3-bg;
  position: relative;

  @media screen and (min-width: $min-screen-width) {
    min-height: initial;

    &__content {
      min-height: 200px;
    }
  }

  &__fixed_top {
    background: white;
  }

  &__content {
    padding-bottom: 120px;

    @media screen and (min-width: $min-screen-width) {
      padding-bottom: 50px;
    }

    &__add_container {
      border-top: 1px solid $theme-list-divider-color;
      padding: $padding-section 0;
      margin: 0 $padding-section;
      box-sizing: border-box;
      text-align: center;

      &__button {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 18px;
        border-radius: 20px;
        /*background: $theme-link-color;
        width: 64px;
        height: 64px;
        border-radius: 32px;
        color: white;
        font-size: 38px;*/
      }
    }
  }

  &__footer {
    background: white;
    padding: $padding-section;
    border-top: 1px solid $theme-list-divider-color;
    text-align: center;
    position: fixed;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__separator {
      height: 1px;
      background-color: $theme-2-bg;
      margin-top: 16px;
      margin-bottom: 16px;
      @media screen and (min-width: $min-screen-width) {
        display: none;
      }
    }

    &__button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      /*&--left {
        //margin-bottom: 6px;
        //margin-right: 5px;
      }*/

      &--right {
        margin-left: 0;
      }
    }

    &__split {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 600px;
    }

    @media screen and (min-width: $min-screen-width) {
      &__button {
        &--left {
          margin-bottom: 0;
          margin-right: 5px;
        }
        &--right {
          margin-left: 5px;
        }
      }
      &__split {
        flex-direction: row;
      }
    }
  }
}
</style>
