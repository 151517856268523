
<template>
  <div
    :class="containerClasses"
    @click="itemClicked">
    <div
      v-if="professional"
      :class="`${mainClass}__icon`">
      <span :class="`${mainClass}__icon__text`">
        {{ professionalInitials }}
      </span>
      <ImageFade
        v-if="professionalProfileImage"
        :src="professionalProfileImage"
        :class="`${mainClass}__icon__img`"
      />
    </div>

    <div
      v-else
      :class="`${mainClass}__icon_empty_professional`">
      <IlmIcon
        :class="`${mainClass}__icon_empty_professional__icon`"
        icon="person"
        size="xl"
      />
    </div>

    <div :class="`${mainClass}__labels`">
      <span
        :class="professionalLabelClasses"
        v-html="chooseProfessionalLabel"
      />
      <div :class="`${mainClass}__labels__professional`">
        <span
          :class="professionalNameClasses"
          v-html="professionalLabel"
        />
        <IlmIcon
          :class="`${mainClass}__labels__professional__dropdown`"
          icon="sort"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import ImageFade from '@/components/ImageFade.vue';
import Utils from '@/utils/utils.js';

export default {
  name: 'SingleServiceGridProfessional',

  // Components ********************************
  components: {
    IlmIcon,
    ImageFade,
  },

  // Properties ********************************
  props: {
    service: Object,
    professional: Object,
    onItemClicked: Function,
  },

  // Data ********************************
  data() {
    return {};
  },

  // Computed ********************************
  computed: {
    mainClass() {
      return 'multiple-service-grid-professional';
    },
    containerClasses() {
      const classes = {};
      this.$set(classes, `${this.mainClass}`, true);
      //this.$set(classes, `${this.mainClass}--single`, this.singleItem);
      return classes;
    },
    containerProfessionalClass() {
      const classes = {};
      this.$set(classes, `${this.mainClass}__professional`, true);
      //this.$set(classes, `${this.mainClass}__professional--single`, this.singleItem);
      return classes;
    },
    containerEmptyProfessionalClass() {
      const classes = {};
      //this.$set(classes, `${this.mainClass}__empty_professional--single`, this.singleItem);
      return classes;
    },
    professionalNameClasses() {
      const classes = {};
      this.$set(classes, `${this.mainClass}__labels__professional__name`, true);
      if (this.professional) {
        this.$set(classes, `${this.mainClass}__labels__professional__name--selected`, true);
      }
      return classes;
    },
    professionalLabelClasses() {
      const classes = {};
      this.$set(classes, `${this.mainClass}__labels__choose`, true);
      if (this.professional) {
        this.$set(classes, `${this.mainClass}__labels__choose--selected`, true);
      }
      return classes;
    },
    professionalInitials() {
      if (!this.professional) {
        return '';
      }
      return Utils.getNameInitials(this.professional.name);
    },
    chooseProfessionalLabel() {
      if (this.professional) {
        return this.$t('button.appointment_choosen_professional');
      }
      return this.$t('button.appointment_choose_professional');
    },
    professionalLabel() {
      if (this.professional) {
        return this.professional.name;
      }
      //const label = this.$t('button.appointment_choose_professional_empty');
      return this.$t('section_list.appointment_professional_any');
      //return label.replace('<br>', ' ');
    },
    professionalProfileImage() {
      if (!this.professional) {
        return null;
      }
      return this.professional.profileImageUrl;
    },
  },

  // Watchers ********************************
  watch: {},

  // Created ********************************
  created() {},

  // Mounted ********************************
  mounted() {},

  destroyed() {},

  methods: {
    itemClicked() {
      if (this.onItemClicked) {
        this.onItemClicked(0);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.multiple-service-grid-professional {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 0;

  &__icon_empty_professional {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    background-color: $theme-3-bg;
    border-radius: 50%;
    //border: 1px solid $theme-link-color;
    border: 2px dashed $theme-color-dim;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;

    &__icon {
      //color: $theme-link-color;
      color: $theme-color-dim;
      font-size: 24px;
      padding-left: 1px;
      padding-bottom: 3px;
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    position: relative;
    background-color: $theme-3-bg;
    border-radius: 50%;
    border: 1px solid $theme-link-color;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 100%;
      text-align: center;
      font-size: 20px;
      color: $theme-3-color;
      font-weight: bold;
      font-size: 22px;
    }
  }

  &__labels {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-content: center;

    &__choose {
      color: $theme-dark;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.1;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-lines: 1;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;

      &--selected {
        font-size: 14px;
        font-weight: normal;
      }
    }

    &__professional {
      display: flex;
      flex-direction: row;
      color: $theme-link-color;
      align-items: baseline;
      padding-top: 4px;
      padding-bottom: 0;

      &__name {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-lines: 1;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;

        &--selected {
          font-size: 18px;
        }
      }

      &__dropdown {
        padding-left: 6px;
      }
    }
  }

  @media (hover) {
    &:hover &__icon_empty_professional {
      border-color: $theme-link-variant;
      &__icon {
        color: $theme-link-variant;
      }
    }
    &:hover &__icon {
      border-color: $theme-link-variant;
      &__icon {
        color: $theme-link-variant;
      }
    }
    &:hover &__labels {
      &__professional {
        color: $theme-link-variant;
      }
    }
  }
}
</style>
