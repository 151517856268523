import IlmError from './error';

export default {

  sentry: null,

  processError(error) {
    let err = error;
    if (error) {
      if (error.length > 0) {
        err = new IlmError(error[0]);
        this.captureError(err);
      } else {
        this.captureException(error);
        err = this.processGenericError();
      }
    } else {
      err = this.processGenericError();
    }
    return err;
  },

  processGenericError() {
    return new IlmError();
  },


  captureError(error) {
    const httpCode = error.getHttpCode();
    const regex = /^5\d{2}$/g; // http codes 5xx
    if (this.sentry && (!httpCode || httpCode.match(regex))) {
      this.sentry.captureException(new Error(error.toString()));
    }
  },

  captureException(ex) {
    if (this.sentry) {
      this.sentry.captureException(ex);
    }
  },

};
