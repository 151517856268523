<template>
  <div
    class="pin-char">
    <span
      :class="containerClassNames"
      class="pin-char__dot">
      {{ char }}
    </span>
  </div>
</template>


<script>
export default {
  name: 'PinChar',

  // Properties ********************************
  props: {
    char: String,
    hasFocus: Boolean,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClassNames() {
      const classNames = {
        'pin-char__dot--focused': this.hasFocus,
        'pin-char__dot--loading': this.isLoading,
      };
      return classNames;
    },
  },
};
</script>


<style lang="scss" scoped>

.pin-char {
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  color: black;
  text-align: center;
  width: 1.2em;
  margin: 0 3px;

  &__dot {
    position: relative;
    height: 64px;
    width: 1.2em;
    /*background-color: $pin-pad-dot-color;
    border-radius: 50%;*/
    border-radius: $theme-border-radius;
    background: $theme-highlight-color;
    border: 1px solid $theme-list-divider-color;
    display: table-cell;
    line-height: 62px;
    box-sizing: border-box;

    &--focused {
      //background: $theme-highlight-color;
      border: 1px solid $theme-ilm-selection;
    }

    &--loading {
      border: 1px solid $theme-ilm-selection;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      //background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
      //background-size: 400px 64px;
      background: linear-gradient(to top, #eeeeee 8%, #bdbdbd 18%, #eeeeee 33%);
      background-size: 64px 400px;
    }
  }
}
@keyframes placeHolderShimmer {
    0%{
        //background-position: -200px 0;
        background-position: 0 200px;
    }
    100%{
        //background-position: 100px 0;
        background-position: 0 -10px;
    }
}

</style>
