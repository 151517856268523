
<template>
  <div
    :class="containerClasses"
    @click="itemClicked">

    <div
      v-if="!professional"
      :class="containerEmptyProfessionalClass">
      <IlmIcon
        :class="`${mainClass}__empty_professional__icon_empty_professional`"
        icon="person"
        size="xl" />

      <span
        :class="`${mainClass}__empty_professional__label_empty`">
        <span
          :class="`${mainClass}__empty_professional__label_empty_professional`"
          v-html="emptyProfessionalLabel" />
        <IlmIcon
          :class="`${mainClass}__empty_professional__icon_empty_professional__sort`"
          icon="sort"
          size="sm" />
      </span>
    </div>

    <div
      v-else
      :class="`${mainClass}__professional-container`">
      <div
        :class="containerProfessionalClass">
        <div
          :class="`${mainClass}__professional__icon`">
          <span
            :class="`${mainClass}__professional__icon__text`">
            {{ professionalInitials }}
          </span>
          <ImageFade
            v-if="professionalProfileImage"
            :class="`${mainClass}__professional__icon__img`"
            :src="professionalProfileImage" />
          <div :class="`${mainClass}__professional__icon__dropdown`">
            <IlmIcon
              :class="`${mainClass}__professional__icon__dropdown__icon`"
              icon="caret-down" />
          </div>
        </div>
      </div>
      <!--div v-if="singleItem">
        <span
          :class="`${mainClass}__professional__name`">
          {{ professionalName }}
        </span>
      </div-->
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import ImageFade from '@/components/ImageFade.vue';
import Utils from '@/utils/utils.js';


export default {
  name: 'MultipleServiceGridProfessional',

  // Components ********************************
  components: {
    IlmIcon,
    ImageFade,
  },

  // Properties ********************************
  props: {
    professional: Object,
    index: Number,
    onItemClicked: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'multiple-service-grid-professional';
    },
    containerClasses() {
      const classes = {};
      this.$set(classes, `${this.mainClass}`, true);
      //this.$set(classes, `${this.mainClass}--single`, this.singleItem);
      return classes;
    },
    containerProfessionalClass() {
      const classes = {};
      this.$set(classes, `${this.mainClass}__professional`, true);
      //this.$set(classes, `${this.mainClass}__professional--single`, this.singleItem);
      return classes;
    },
    containerEmptyProfessionalClass() {
      const classes = {};
      //this.$set(classes, `${this.mainClass}__empty_professional--single`, this.singleItem);
      return classes;
    },
    professionalInitials() {
      if (!this.professional) {
        return '';
      }
      return Utils.getNameInitials(this.professional.name);
    },
    professionalProfileImage() {
      if (!this.professional) {
        return null;
      }
      return this.professional.profileImageUrl !== '' ? this.professional.profileImageUrl : null;
    },
    professionalName() {
      if (!this.professional) {
        return '';
      }
      const name = this.professional.name;
      return name;
    },
    emptyProfessionalLabel() {
      const label = this.$t('button.appointment_choose_professional_empty');
      return label;
    },
  },

  // Watchers ********************************
  watch: {
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  destroyed() {
  },

  methods: {
    itemClicked() {
      if (this.onItemClicked) {
        this.onItemClicked(this.index);
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.multiple-service-grid-professional {
  display: block;
  width: 100%;
  max-width: 66px;
  margin: 0 auto;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;

  &--single {
    max-width: 100%;
  }

  &__empty_professional {
    &__label_empty {
      display: inline-flex;
    }

    &__icon_empty_professional {
      color: $theme-link-color;
      vertical-align: bottom;

      &__sort {
        align-self: flex-end;
        color: $theme-link-color;
        font-size: 10px;
        padding-left: 4px;
        padding-bottom: 2px;
      }
    }

    &__label_empty_professional {
      color: $theme-link-color;
      font-size: 11px;
      line-height: 1.3;
    }
  }

  @media (hover) {
    &:hover &__empty_professional {
      &__icon_empty_professional, &__label_empty_professional {
        color: $theme-link-variant;

        &__sort {
          color: $theme-link-variant;
        }
      }
    }
  }

  &--single ::v-deep &__professional-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__professional {
    border: 2px solid $theme-link-color;
    padding: 2px;
    border-radius: 50%;

    &__icon {
      width: 100%;
      position: relative;
      padding-top: 100%; /* 1:1 Aspect Ratio */
      background-color: $theme-color-dim;
      border-radius: 50%;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &__text {
        position:  absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 22px;
      }

      &__dropdown {
        color: $theme-link-color;
        position: absolute;
        bottom: -2px;
        right: -2px;
        background: white;
        width: 14px;
        height: 12px;
        border: 2px solid $theme-link-color;
        border-radius: 4px;
        display: flex;
        justify-content: center;

        &__icon {
          align-self: center;
          font-size: 17px;
        }
      }
    }

    &__name {
      font-size: 16px;
      font-weight: bold;
      color: black;
      margin-left: 10px;
    }
  }

  @media (hover) {
    &:hover &__professional {
      border-color: $theme-color-dim;

      &__icon {
        &__dropdown {
          border-color: $theme-color-dim;
          color: $theme-color-dim;
          //background: $theme-link-color;
        }
      }
    }
  }

  &__empty_professional {
    &--single {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
    &--single ::v-deep &__label_empty_professional {
      margin-left: 10px;
    }
  }

  &__professional {
    &--single {
      width: 66px;
      flex: 0 0 auto;
    }
    &--single ::v-deep &__icon {

    }
  }
}

</style>
