const GENERIC_CODE = 'GENERIC_CODE';
const UNAVAILABLE_SERVICE_CODE = 'UNAVAILABLE_SERVICE_CODE';
const UNAVAILABLE_SLOT_CODE = 'UNAVAILABLE_SLOT_CODE';
const UNAVAILABLE_PROFESSIONAL_OVERRIDE_CODE = 'UNAVAILABLE_PROFESSIONAL_OVERRIDE_CODE';
const INVALID_CONFIRMATION_CODE = 'INVALID_CONFIRMATION_CODE';
const EXPIRED_TOKEN_PHONE_VALIDATION_CODE = 'EXPIRED_TOKEN_PHONE_VALIDATION_CODE';
const EXPIRED_TOKEN_CODE = 'EXPIRED_TOKEN_CODE';
const APP_INACTIVE_CODE = 'APP_INACTIVE_CODE';
const BUSINESS_NOT_FOUND_CODE = 'BUSINESS_NOT_FOUND_CODE';
const BUSINESS_RESTRICTED_USER_ACCESS_CODE = 'BUSINESS_RESTRICTED_USER_ACCESS_CODE';

const APPOINTMENTS_CANCEL_CODE = 'APPOINTMENTS_CANCEL_CODE';
const APPOINTMENTS_EDIT_CODE = 'APPOINTMENTS_EDIT_CODE';

const PURCHASE_HASH_EXPIRED = 'PURCHASE_HASH_EXPIRED';
const PURCHASE_CREATE_TRANSACTION = 'PURCHASE_CREATE_TRANSACTION';
const PURCHASE_CAPTURE_TRANSACTION = 'PURCHASE_CAPTURE_TRANSACTION';
const PURCHASE_CAPTURE_TRANSACTION_NO_REFUND = 'PURCHASE_CAPTURE_TRANSACTION_NO_REFUND';

const unavailableServiceCode = 'services.not_found';
const unavailableSlotCode = 'appointment.slot_unavailable';
const unavailableProfessionalOverrideCode = 'appointment.professional_override';
const invalidConfirmationCode = 'users.wrong_code';
const expiredTokenPhoneValidation = 'users.token_invalid';
const expiredTokenCode = 'token.validation';
const appInactiveCode = 'token.app_inactive';
const businessNotFoundCode = 'businesses.not_found';
const businessRestrictedUserAccessCode = 'user.restrict_access';

const appointmentsCancelCode = 'appointments.cancel_policy';
const appointmentsEditCode = 'appointments.update_policy';

const purchaseHashExpired = 'purchase.expired_hash';
const purchaseCreateTransaction = 'payments.provider.create_transaction';
const purchaseCaptureTransaction = 'payments.provider.capture_transaction';
const purchaseCaptureTransactionNoRefund = 'payments.provider.capture_transaction_no_refund';

class Error {
  constructor(err) {
    if (err && err.extensions) {
      this.code = err.extensions.code;
      if (err.extensions.httpCode && err.extensions.httpCode !== '') {
        this.httpCode = err.extensions.httpCode;
      } else {
        this.httpCode = null;
      }
      this.description = err.extensions.message;
      this.title = err.extensions.title;

      if (err.extensions.meta) {
        const meta = err.extensions.meta;
        this.business = {
          id: meta.businessId,
          name: meta.businessBrand,
          permalink: meta.businessPermalink,
          phoneCountry: meta.businessPhoneCountry,
          phoneNumber: meta.businessPhoneNumber,
        };
      }
    } else {
      this.code = null;
      this.httpCode = null;
      this.description = null;
      this.title = null;
    }
  }

  getCode() {
    if (this.code === unavailableServiceCode) {
      return UNAVAILABLE_SERVICE_CODE;
    }
    if (this.code === unavailableSlotCode) {
      return UNAVAILABLE_SLOT_CODE;
    }
    if (this.code === unavailableProfessionalOverrideCode) {
      return UNAVAILABLE_PROFESSIONAL_OVERRIDE_CODE;
    }
    if (this.code === invalidConfirmationCode) {
      return INVALID_CONFIRMATION_CODE;
    }
    if (this.code === expiredTokenPhoneValidation) {
      return EXPIRED_TOKEN_PHONE_VALIDATION_CODE;
    }
    if (this.code === expiredTokenCode) {
      return EXPIRED_TOKEN_CODE;
    }
    if (this.code === businessNotFoundCode) {
      return BUSINESS_NOT_FOUND_CODE;
    }
    if (this.code === appInactiveCode) {
      return APP_INACTIVE_CODE;
    }
    if (this.code === businessRestrictedUserAccessCode) {
      return BUSINESS_RESTRICTED_USER_ACCESS_CODE;
    }
    if (this.code === purchaseHashExpired) {
      return PURCHASE_HASH_EXPIRED;
    }
    if (this.code === purchaseCreateTransaction) {
      return PURCHASE_CREATE_TRANSACTION;
    }
    if (this.code === purchaseCaptureTransaction) {
      return PURCHASE_CAPTURE_TRANSACTION;
    }
    if (this.code === purchaseCaptureTransactionNoRefund) {
      return PURCHASE_CAPTURE_TRANSACTION_NO_REFUND;
    }
    if (this.code === appointmentsCancelCode) {
      return APPOINTMENTS_CANCEL_CODE;
    }
    if (this.code === appointmentsEditCode) {
      return APPOINTMENTS_EDIT_CODE;
    }
    return GENERIC_CODE;
  }

  getHttpCode() {
    return this.httpCode;
  }

  toString() {
    return `code: ${this.code} | httpCode: ${this.httpCode} | description: ${this.description} | title: ${this.title}`;
  }


  static get genericCode() {
    return GENERIC_CODE;
  }

  static get unavailableServiceCode() {
    return UNAVAILABLE_SERVICE_CODE;
  }

  static get unavailableSlotCode() {
    return UNAVAILABLE_SLOT_CODE;
  }

  static get unavailableProfessionalOverrideCode() {
    return UNAVAILABLE_PROFESSIONAL_OVERRIDE_CODE;
  }

  static get invalidConfirmationCode() {
    return INVALID_CONFIRMATION_CODE;
  }

  static get expiredTokenCode() {
    return EXPIRED_TOKEN_CODE;
  }

  static get expiredTokenPhoneValidationCode() {
    return EXPIRED_TOKEN_PHONE_VALIDATION_CODE;
  }

  static get appInactiveCode() {
    return APP_INACTIVE_CODE;
  }

  static get businessNotFoundCode() {
    return BUSINESS_NOT_FOUND_CODE;
  }

  static get purchaseHashExpired() {
    return PURCHASE_HASH_EXPIRED;
  }

  static get purchaseCreateTransaction() {
    return PURCHASE_CREATE_TRANSACTION;
  }

  static get purchaseCaptureTransaction() {
    return PURCHASE_CAPTURE_TRANSACTION;
  }

  static get purchaseCaptureTransactionNoRefund() {
    return PURCHASE_CAPTURE_TRANSACTION_NO_REFUND;
  }

  static get appointmentsCancelCode() {
    return APPOINTMENTS_CANCEL_CODE;
  }

  static get appointmentsEditCode() {
    return APPOINTMENTS_EDIT_CODE;
  }
}

export default Error;
