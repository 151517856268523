class BillingDetails {
  constructor() {
    this.type = null;
    this.description = null;
    this.value = null;

    this.reference = null;
    this.hash = null;
  }
}

export default BillingDetails;
