<template>
  <div class="new-booking-confirmation">
    <Sticky>
      <div class="new-booking__fixed_top">
        <Navbar
          :subtitle="title"
          :state="state"
          :show-shadow="false"
          show-back-bevel
          show-back />
      </div>
    </Sticky>
    <div class="new-booking-confirmation__content">
      <SectionResume
        :business="business"
        :appointment="appointment"
        :discount="discount"
        :is-loading="isLoading"
        :show-notes="!isFutureAppointment"
        :show-options="showOptions"
        :show-cancel="showCancel"
        :on-business-click="businessClick"
        :on-cancel-click="clickCancel"
        :on-repeat-click="clickRepeat"
        :on-edit-click="clickEdit" />
      <!--SummaryList
        :items="sectionList"
        class="new-booking-confirmation__content__section" /-->

      <!-- Add notes button -->
      <IlmButton
        v-if="appointment && !appointment.notes && showEditableFields"
        :on-click="clickAddNote"
        :is-working="isLoading"
        t-key="appointment_options.add_note"
        prevent-default
        theme="simple"
        class="new-booking-confirmation__content__note_button" />

      <SectionNotes
        v-else-if="appointment && appointment.notes && isFutureAppointment"
        :element="getNotesSection()"
        :is-editable="isFutureAppointment"
        :on-item-deleted="onNotesSectionClear"
        :on-item-edit="clickAddNote"
        class="new-booking-confirmation__content__note-section" />

      <SectionMap
        v-if="showEditableFields"
        :title="$t('button.business_details_open_map')" />
    </div>
    <!--div
      v-if="showOptions"
      :class="footerClass"
      class="new-booking-confirmation__footer
      new-booking-confirmation__footer--shadow">
      <IlmButton
        v-if="showCancel"
        :on-click="clickCancel"
        :is-working="isLoading"
        t-key="button.cancel_appointment"
        prevent-default
        theme="block destructive"
        class="new-booking-confirmation__footer__confirm_button"/>

      <IlmButton
        v-else
        :on-click="clickRepeat"
        t-key="button.book_again"
        prevent-default
        theme="block info-inverted"
        class="new-booking-confirmation__footer__confirm_button"/>
    </div-->

    <AlertCancelPrompt
      v-if="alertCancelShowing"
      :title="$t('alerts.alert_cancel_appointment_title')"
      :message="$t('alerts.alert_cancel_appointment_message')"
      :btn-cancel="$t('alerts.alert_cancel_appointment_no')"
      :btn-destructive="$t('alerts.alert_cancel_appointment_yes')"
      @closed="onAlertClosed"
      @cancel="onAlertClosed"
      @ok="cancelAppointment" />

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />

    <ModalNotes
      v-if="modalNotesShowing"
      ref="notes_modal"
      :saved-note="appointment.notes"
      @closed="onModalNotesClosed"
      @ok="onModalNotesSaved" />

    <Popover
      v-if="popupSuccessShowing"
      :title="$t('popover.save_success')"
      icon="check"
      @closed="popupSuccessShowing = false" />
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import SectionResume from '@/components/Sections/SectionResume.vue';
import SectionNotes from '@/components/Sections/SectionNotes.vue';
import SectionMap from '@/components/Sections/SectionMap.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import AlertCancelPrompt from '@/components/Alerts/AlertCancelPrompt.vue';
import ModalNotes from '@/components/Modals/ModalNotes.vue';
import Popover from '@/components/Popover/PopoverSimple.vue';
import Error from '@/graphql/error';

export default {
  name: 'MyBookingItem',

  // Components ********************************
  components: {
    Navbar,
    IlmButton,
    SectionResume,
    SectionNotes,
    SectionMap,
    Alert,
    AlertCancelPrompt,
    ModalNotes,
    Popover,
  },

  // Props ********************************
  props: {
    id: String,
    day: String,
    appointments: Array,
  },

  // Data ********************************
  data() {
    return {
      isDestroyed: false,
      alertCancelShowing: false,
      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,
      popupSuccessShowing: false,
      modalNotesShowing: false,
      appointment: null,
      policies: null,
      discount: null,
      title: '',
      subtitle: '',
      state: null,
      isLockedAppointment: true,
      showOptions: true,
      sectionList: [],
      isLoading: false,
      windowWidth: null,
    };
  },

  // Computed ********************************
  computed: {
    isFutureAppointment() {
      if (this.appointment) {
        const day = this.appointment.day;
        const time = this.appointment.time;

        const timezone = this.$dateUtils.getTimezone();
        const date = this.$moment.tz(`${day} ${time}`, timezone);
        const now = this.$moment.tz(new Date(), timezone);

        return date.isAfter(now);
      }
      return false;
    },
    showEditableFields() {
      if (this.appointment) {
        return this.isFutureAppointment === true && this.state !== 'canceled';
      }
      return false;
    },
    showCancel() {
      return this.isLockedAppointment === false && ['paid', 'pending'].includes(this.state) === false;
    },
    footerClass() {
      return {
        'new-booking-confirmation__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    footerStyles() {
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return {
          position: 'sticky',
        };
      }
      return {
        position: 'fixed',
      };
    },
    business() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        return auth.business;
      }
      return null;
    },
  },

  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    this.appointment = this.$store.getters.getAppointment;

    if (!this.appointment) {
      this.$router.go(-1);
      return;
    }

    this.updateFields(this.appointment);
    //this.updateNavbar(this.appointment);
  },

  // Mounted ********************************
  mounted() {
    if (!this.appointment) {
      return;
    }
    this.windowWidth = document.body.clientWidth;
    window.addEventListener('resize', this.handleResize);

    this.$analytics.track('Visit: My bookings item');
    this.requestAppointment();
    /*
    const dt = this.$moment(new Date());
    dt.add(1, 'd');
    this.dateSelected = dt;
    this.$refs.calendar.setSelectedDate(dt);*/

    //const now = this.$moment(new Date());
    //this.isPastAppointment = !date.isAfter(now) && this.state !== 'canceled';
    //this.updateFields(this.appointment);
    /*
    // SERVICE
    const service = this.appointment.service;
    this.sectionList.push({
      section: this.$t('section_list.service_title'),
      name: service.name,
      price: service.value,
      estimate: ['paid', 'pending'].includes(this.state) === false,
      duration: service.duration,
    });

    // PROFESSIONAL
    const professional = this.appointment.professional;
    this.sectionList.push({
      section: this.$t('section_list.professional_title'),
      name: professional ? professional.name : this.$t('section_list.appointment_professional_any'),
    });

    // NOTES
    const notes = this.appointment.notes;
    if (notes) {
      this.sectionList.push({
        section: this.$t('section_list.note_title'),
        name: notes,
      });
    }*/
  },

  destroyed() {
    this.isDestroyed = true;
    this.sectionList = [];
    this.appointment = null;
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    updateNavbar(appointment) {
      // STATE
      this.state = appointment.getState();
      // DATE
      //const timezone = this.$dateUtils.getTimezone();
      const day = appointment.day;
      const date = this.$moment(day);
      const time = appointment.time;
      if (!date || !time) {
        this.$router.go(-1);
        return;
      }
      /*
      const dayStr = `${date.format('ddd').toUpperCase()} ${date.format('D')}`;
      this.subtitle = this.$t('dates.full_format', {
        day: dayStr,
        month: date.format('MMMM'),
        hour: time,
      });
      */
      this.checkState();
      /*
      if (this.state) {
        let color = this.$constants.theme_state_booked;
        if (this.state === 'confirmed') {
          color = this.$constants.theme_state_confirmed;
        } else if (this.state === 'waiting') {
          color = this.$constants.theme_state_waiting;
        } else if (this.state === 'started') {
          color = this.$constants.theme_state_started;
        } else if (this.state === 'late') {
          color = this.$constants.theme_state_late;
        } else if (this.state === 'paid' || this.state === 'pending') {
          color = this.$constants.theme_state_paid;
        } else if (this.state === 'canceled') {
          color = this.$constants.theme_state_canceled;
        } else if (this.state === 'noshow') {
          color = this.$constants.theme_state_noshow;
        }
        const body = document.body;
        body.style.background = color;
      }*/
    },

    updateFields(appointment) {
      this.updateNavbar(appointment);
      // SERVICE
      const service = appointment.service;
      const serviceObj = {
        section: this.$t('section_list.service_title'),
        name: service.name,
        price: service.value,
        estimate: ['paid', 'pending'].includes(this.state) === false,
        duration: service.duration,
      };
      const serviceIndex = this.sectionList.map(item => item.section).indexOf(serviceObj.section);
      if (serviceIndex === -1) {
        this.sectionList.push(serviceObj);
      } else {
        this.sectionList[serviceIndex] = serviceObj;
      }

      // PROFESSIONAL
      const professional = appointment.professional;
      const professionalObj = {
        section: this.$t('section_list.professional_title'),
        name: professional ? professional.name : this.$t('section_list.appointment_professional_any'),
      };
      const professionalIndex = this.sectionList.map(item => item.section)
        .indexOf(professionalObj.section);
      if (professionalIndex === -1) {
        this.sectionList.push(professionalObj);
      } else {
        this.sectionList[professionalIndex] = professionalObj;
      }

      // NOTES
      const notes = appointment.notes;
      if (notes) {
        const notesObj = {
          section: this.$t('section_list.note_title'),
          name: notes,
        };
        const notesIndex = this.sectionList.map(item => item.section).indexOf(notesObj.section);
        if (notesIndex === -1) {
          this.sectionList.push(notesObj);
        } else {
          this.sectionList[notesIndex] = notesObj;
        }
      }
    },

    getNotesSection() {
      return { section: this.$t('section_list.note_appointment_title'), name: this.appointment.notes };
    },

    clickAddNote() {
      if (this.isFutureAppointment) {
        this.modalNotesShowing = true;
      }
    },

    onNotesSectionClear() {
      if (this.isFutureAppointment) {
        this.saveNote('');
      }
    },

    onModalNotesClosed() {
      this.modalNotesShowing = false;
    },
    onModalNotesSaved(notes) {
      this.onModalNotesClosed();
      if (!this.isFutureAppointment) {
        return;
      }

      if (notes && notes.length > 0) {
        const trimmedNotes = notes.trim();
        if (trimmedNotes.length > 0) {
          this.saveNote(trimmedNotes);
          return;
        }
      }
      this.saveNote('');
    },

    saveNote(notes) {
      this.isLoading = true;
      this.$root.loading = true;
      const self = this;
      this.$graphql.saveAppointmentNote(this.appointment, notes, function () {
        /*if (notes && notes.length > 0) {
          self.sectionList.push({
            section: self.$t('section_list.note_title'),
            name: self.notes,
          });
        }*/
        self.appointment.notes = notes;
        try {
          self.$analytics.track('Action: Appointment Note Edited', { key: self.appointment.key });
        } catch (ex) {
          console.log(ex);
        }
        self.isLoading = false;
        self.$root.loading = false;
        self.popupSuccessShowing = true;
      }, function () {
        self.isLoading = false;
        self.$root.loading = false;
        self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
    },

    clickRepeat() {
      //const appointment = this.$store.getters.getAppointment;
      const appointment = this.appointment;
      if (!appointment) {
        return;
      }
      const service = appointment.service;
      const professional = appointment.professional;
      const notes = appointment.notes;

      this.$analytics.track('Action: Repeat Booking', { key: appointment.key });

      this.$store.dispatch('resetAppointment');
      this.$store.dispatch('setAppointmentService', service);
      this.$store.dispatch('setAppointmentProfessional', professional);
      this.$store.dispatch('setAppointmentNote', notes);
      this.$router.replace({ name: this.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
    },
    clickEdit() {
      const appointment = this.appointment;
      if (!appointment) {
        return;
      }

      this.$analytics.track('Action: Edit Booking', { key: appointment.key });

      this.$store.dispatch('resetAppointment');
      this.$store.dispatch('setAppointment', appointment);
      this.$router.replace({
        name: this.$constants.ROUTE_NEW_APPOINTMENT,
        params: { isEditMode: true },
      }, () => {});
    },
    clickCancel() {
      this.alertCancelShowing = true;
    },
    checkState() {
      const day = this.appointment.day;
      const time = this.appointment.time;

      const timezone = this.$dateUtils.getTimezone();
      const date = this.$moment.tz(`${day} ${time}`, timezone);

      const now = this.$moment.tz(new Date(), timezone);
      this.isLockedAppointment = !date.isAfter(now) || this.state === 'canceled' || this.appointment.getIsLocked() === true;

      //this.showOptions = !(date.isAfter(now) && ['paid', 'pending'].includes(this.state));

      this.title = this.$t(`states.${this.state}`).toUpperCase();
    },

    businessClick() {
      this.$router.replace({ name: 'root' }, () => {});
    },

    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },

    // Alerts
    showError(title, message) {
      this.alert(title, message);
    },
    onAlertClosed() {
      this.alertCancelShowing = false;
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },


    // REQUESTS

    requestAppointment() {
      if (!this.id) {
        return;
      }
      if (this.isDestroyed) { return; }
      const self = this;
      //this.$root.loading = true;
      this.isLoading = true;
      this.$graphql.getAppointmentDetails([this.id], function (a) {
        if (self.isDestroyed) { return; }
        self.$store.dispatch('setAppointment', a);
        self.appointment = a;
        self.updateFields(a);

        self.requestPolicies(function () {
          if (self.appointment && self.appointment.discountId && self.appointment.discountId !== '') {
            self.requestDiscount(self.appointment.discountId);
          } else {
            self.isLoading = false;
          }
        });
        //self.$root.loading = false;
      }, function (error) {
        //self.$root.loading = false;
        self.isLoading = false;
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
          self.$router.push({
            name: self.$constants.ROUTE_LOGIN,
            params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
          }, () => {});
        }
      });
    },

    requestPolicies(callback) {
      const self = this;
      //this.$root.loading = true;
      this.isLoading = true;
      this.$graphql.getAppointmentPolicies(this.id, function (a) {
        if (self.isDestroyed) { return; }
        self.policies = a;

        if (callback) {
          callback();
        }
        //self.$root.loading = false;
      }, function (error) {
        //self.$root.loading = false;
        if (error.getCode() === Error.expiredTokenCode) {
          self.isLoading = false;
          self.$localStorage.setToken('');
          self.$router.push({
            name: self.$constants.ROUTE_LOGIN,
            params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
          }, () => {});
        } else if (callback) {
          callback();
        }
      });
    },

    requestDiscount(discountId) {
      const self = this;
      //this.$root.loading = true;
      this.isLoading = true;
      this.$graphql.getAppointmentDiscount(discountId, function (discount) {
        if (self.isDestroyed) { return; }
        //self.$store.dispatch('setAppointment', a);
        self.discount = discount;
        //self.updateFields(a);
        self.isLoading = false;
        //self.$root.loading = false;
      }, function (error) {
        //self.$root.loading = false;
        self.isLoading = false;
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
          self.$router.push({
            name: self.$constants.ROUTE_LOGIN,
            params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
          }, () => {});
        }
      });
    },


    cancelAppointment(reasonKey) {
      this.isLoading = true;
      this.onAlertClosed();

      //const reason = reasonKey === 'other' ? null : reasonKey;

      this.$analytics.track('Action: Cancel Appointment', { key: this.appointment.key, reason: reasonKey });

      const self = this;
      this.$graphql.cancelAppointment(this.appointment, reasonKey, function () {
        if (self.isDestroyed) { return; }
        self.isLoading = false;
        self.$analytics.track('Response: Appointment Canceled', { key: self.appointment.key, reason: reasonKey });
        self.appointment.isLocked = true;
        self.appointment.state = 'canceled';
        self.appointment.cancelReason = reasonKey;
        self.state = 'canceled';
        self.checkState();
      }, function () {
        if (self.isDestroyed) { return; }
        self.isLoading = false;
        self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
      /*
      this.state = 'canceled';
      this.checkState();*/
    },
  },
};
</script>


<style lang="scss" scoped>


.new-booking-confirmation {
  height: 100%;
  background: $theme-3-bg;

  &__fixed_top {
    background: white;

  }

  &__content {
    z-index: 0;
    //padding-bottom: 100px;

    @media screen and (min-width: $min-screen-width) {
      //padding-bottom: 60px;
    }
    &__section {
      margin-bottom: 10px;
      user-select: none;
    }

    &__note_button {
      font-size: 13px;
      margin: 10px 0;
    }

    &__note-section {
      margin-top: 20px;
    }
  }

  &__footer {
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    padding: $padding-section;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {
      max-width: 480px;

      &--left {
        margin-right: 6px;
      }

      &--right {
        margin-left: 6px;
      }
    }

    &__split {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      max-width: 600px;
    }

  }
}

</style>
