import utils from '../utils/utils';

class Professional {
  constructor(obj) {
    this.id = obj.id;
    this.name = utils.capitalizeWords(obj.name);
    this.profileImageUrl = obj.profileImageUrl;

    this.weekSchedule = [true, true, true, true, true, true, true];
    if (obj.weekSchedule) {
      const res = obj.weekSchedule.split(',');
      let index = 0;
      while (index < res.length) {
        this.weekSchedule[index] = res[index] === 't';
        index++;
      }
    }

    this.serviceIds = [];
    if (obj.serviceIds) {
      this.serviceIds = obj.serviceIds;
    }
  }


  hasService(serviceId) {
    return this.serviceIds.indexOf(serviceId) > -1;
  }

  /**
   *
   * @param {*} weekDay week index that starts on monday
   */
  isAvailable(weekDay) {
    // week indexes: 1 - 7 (starts on monday)
    // moment.isoWeekday()
    return this.weekSchedule[weekDay - 1] === false;
  }


  getUnavailableWeekdays() {
    const arr = [];
    // week indexes: 1 - 7 (starts on monday)
    // moment.isoWeekday()
    for (let i = 0; i < this.weekSchedule.length; i++) {
      if (this.weekSchedule[i] === true) {
        //arr.push(i === 6 ? 0 : i + 1);
        arr.push(i + 1);
      }
    }
    return arr;
  }


  toString() {
    return `id: ${this.id} | name: ${this.name} | schedule: ${this.weekSchedule}`;
  }
}

export default Professional;
