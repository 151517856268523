<template>
  <div class="steps-nav">
    <div class="steps-nav">
      <div
        :class="stepClass(1)"
        class="steps-nav__step">
        <IlmIcon
          v-if="currentStep > 1"
          icon="check"
          size="lg"
          class="steps-nav__step__check" />
        <span
          v-else
          class="steps-nav__step__number">1</span>
      </div>
      <span
        :class="stepLineClass(2)"
        class="steps-nav__line" />
      <div
        :class="stepClass(2)"
        class="steps-nav__step">
        <IlmIcon
          v-if="currentStep > 2"
          icon="check"
          size="lg"
          class="steps-nav__step__check" />
        <span
          v-else
          class="steps-nav__step__number">2</span>
      </div>
      <span
        :class="stepLineClass(3)"
        class="steps-nav__line" />
      <div
        :class="stepClass(3)"
        class="steps-nav__step">
        <IlmIcon
          v-if="currentStep > 3"
          icon="check"
          size="lg"
          class="steps-nav__step__check" />
        <span
          v-else
          class="steps-nav__step__number">3</span>
      </div>
    </div>
  </div>
</template>

<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    currentStep: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
    };
  },

  computed: {
  },

  mounted() {
  },

  methods: {
    stepClass(step) {
      const obj = {};
      if (step <= this.currentStep) {
        obj['steps-nav__step--selected'] = true;
      }
      return obj;
    },
    stepLineClass(step) {
      const obj = {};
      if (step <= this.currentStep) {
        obj['steps-nav__line--selected'] = true;
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>

.steps-nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;

  &__line {
    flex: 1 1 auto;
    height: 2px;
    background: $box-border-color;

    &--selected {
      background: $theme-ilm-selection;
    }
  }

  &__step {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border: 2px solid $box-border-color;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    color: $theme-3-color;
    background: white;
    text-align: center;

    &--selected {
      background: $theme-ilm-selection;
      border: 2px solid $theme-ilm-selection;
      color: white;
    }

    &__check {
      font-size: 20px;
      line-height: 22px;
    }
    &__number {
      display: block;
      font-size: 16px;
      width: 25px;
      text-align: center;
    }
  }

}

</style>
