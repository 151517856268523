<template>

  <div class="modal">
    <!--transition name="fade"-->
    <div
      class="modal__frame"
      @click="handleCloseClick" />
    <!--/transition-->
    <transition name="slide-up">
      <div
        v-if="contentShowing"
        class="modal__content">
        <div class="modal__content__slot">
          <div class="modal__content__title">
            <IlmButtonIcon
              :on-click="handleCloseClick"
              icon="close"
              class="modal__content__title__close_icon" />
            <!--IlmButton
              :on-click="handleCloseClick"
              label="Cancelar"
              prevent-default
              theme="simple"
              class="modal__content__title__close" /-->
            <IlmButton
              v-if="showOk"
              ref="btn_ok"
              :on-click="handleOkClick"
              :disabled="disableBtnOk"
              :label="$t('modals.buttons.ok')"
              icon="check"
              theme="info"
              class="modal__content__title__ok" />
            <IlmButton
              v-else-if="showSave"
              ref="btn_ok"
              :on-click="handleOkClick"
              :disabled="disableBtnOk"
              :label="$t('modals.buttons.save')"
              theme="success"
              class="modal__content__title__save" />
            <IlmButton
              v-else-if="showDelete"
              :on-click="handleDeleteClick"
              :label="$t('modals.buttons.delete')"
              theme="danger"
              class="modal__content__title__save" />
            <span>
              {{ title }}
            </span>
          </div>
          <div
            ref="modal_content_slot_content"
            class="modal__content__slot_content">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';

const noScrollClass = 'no-scroll';


export default {
  name: 'BaseModal',

  // Components ********************************
  components: {
    IlmButtonIcon,
    IlmButton,
  },

  // Properties ********************************
  props: {
    title: String,
    showOk: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
      disableBtnOk: this.disableOk,
    };
  },

  // Computed ********************************
  computed: {
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      self.contentShowing = true;
      //setTimeout(function () { // wait for close transition
      //self.contentShowing = true;
      //}, 200);
    });
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    handleOkClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('ok', 'close');
      }, 200);
    },
    handleDeleteClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('delete', 'close');
      }, 200);
    },
    setBtnOkState(disabled) {
      this.disableBtnOk = disabled;
      if (this.$refs.btn_ok) {
        this.$refs.btn_ok.setDisabled(disabled);
      }
    },
    close() {
      this.contentShowing = false;
    },
    getScrollableElement() {
      return this.$refs.modal_content_slot_content;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;/*
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 92%;
    max-width: 600px;*/
    overflow: hidden;

    height: 100%;
    width: 100%;

    box-sizing: border-box;
    padding: 12px;

    /*
    height: 95%;
    width: 95%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $theme-border-radius;
    */

    &__slot {
      position: relative;
      display: flex;
      flex-direction: column;
      background: white;
      /*margin: 0 $padding-section;
      border-radius: $theme-border-radius;*/
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: $theme-border-radius;
    }

    &__slot_content {
      flex: 1 1;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      //padding: $padding-section;
    }

    &__title {
      position: relative;
      flex: 0 0 auto;
      background: #f5f5f5;
      color: black;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-lg;
      font-family: $base-font-family;
      padding: 14px 90px;
      border-bottom: 1px solid $theme-list-divider-color;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__close {
        position: absolute;
        //color: black;
        font-weight: normal;
        left: 0;
        top: 0;
        bottom: 0;
      }

      &__close_icon {
        position: absolute;
        color: black;
        font-weight: normal;
        left: 5px;
        top: 5px;
        bottom: 5px;
        padding: 0 22px;
        background: $theme-3-bg;
        border: 1px solid $theme-border;
        box-sizing: border-box;

        &:hover {
          background: $theme-2-bg;
        }
      }

      &__ok {
        position: absolute;
        right: 4px;
        top: 4px;
        bottom: 4px;
        padding: 10px 40px 10px 20px;
        line-height: 1;
        box-sizing: border-box;
      }
      &__save {
        position: absolute;
        font-size: $font-size-md;
        right: 5px;
        top: 5px;
        bottom: 5px;
        padding: 2px 16px 2px 16px;
        line-height: 1;
        box-sizing: border-box;
      }
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      max-height: 92%;
      max-width: 600px;

      height: initial;
      width: initial;

      &__slot {
        margin: 0 $padding-section;
        border-radius: $theme-border-radius;
        height: initial;
        width: initial;
      }
    }
  }
}
</style>
