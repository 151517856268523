import jsrsasign from 'jsrsasign';
import Auth from '@/models/auth';

export default {

  /**
   * get JWT token data
   * @returns Auth with JWT payload
   */
  parseToken(token) {
    /**
     * Read JWT Token
     */
    const JWS = new jsrsasign.KJUR.jws.JWS();
    JWS.parseJWS(token);
    const payload = JSON.parse(JWS.parsedJWS.payloadS);
    //console.log(payload);
    //console.log(JSON.stringify(payload, null, 2));

    return new Auth(token, payload.Data);
  },

};
