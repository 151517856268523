
<template>
  <div class="section-header">
    <h3 class="section-header__title">
      {{ titleLabel }}
    </h3>
  </div>
</template>


<script>
export default {
  name: 'SectionHeader',

  // Properties ********************************
  props: {
    tKey: String,
    label: String,
  },

  data() {
    return {
      contentKey: '',
      contentLabel: '',
    };
  },

  // Computed ********************************
  computed: {
    titleLabel() {
      return this.contentKey ? this.$t(this.contentKey) : this.contentLabel;
    },
  },

  created() {
    this.contentKey = this.tKey;
    this.contentLabel = this.label;
  },

  // Methods ********************************
  methods: {
    setText(val) {
      this.contentKey = null;
      this.contentLabel = val;
    },
  },
};
</script>


<style lang="scss" scoped>

.section-header {
  background: white;
  padding: 8px $padding-section;

  &__title {
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 12px;
    color: $theme-2-color;
    margin: 0;
    text-transform: uppercase;
  }
}

</style>
