<template>
  <div
    :class="sizeClass"
    class="discount_container">
    <span class="discount_container__span">
      <span
        v-if="isVariable"
        class="discount_container__span__until">
        {{ $t('button.discount_variable_until') }}
      </span>
      {{ value }}
    </span>
  </div>
</template>


<script>
const SIZES = ['sm', 'md', 'lg', 'xl', 'xxl'];

export default {
  name: 'IlmDiscount',

  // Properties ********************************
  props: {
    value: String,
    isVariable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: s => SIZES.includes(s),
    },
  },

  // Data ********************************
  data() {
    return {};
  },


  // Computed ********************************
  computed: {
    sizeClass() {
      return this.size ? `discount_container--${this.size}` : null;
    },
  },
};
</script>


<style lang="scss" scoped>

.discount_container {
  width: 33px;
  height: 33px;
  background: $discount-background-color;
  border-radius: 50%;
  text-align: center;
  display: table;
  margin: auto;

  &__span {
    font-family: $heading-font-family;
    font-size: 12px;
    font-weight: bold;
    color: black;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    line-height: 1.2;

    &__until {
      display: block;
      font-size: 9px;
    }
  }

  &--xl {
    width: 90px;
    height: 90px;
  }
  &--xl ::v-deep &__span {
    font-size: 28px;
    line-height: 32px;
  }

  &--md {
    width: 44px;
    height: 44px;
  }
}
</style>
