
<template>
  <div
    class="appointment-container">
    <div>
      <div
        v-if="hasFutureAppointments"
        ref="next_appointments_header">
        <!--div
          :class="futureHeaderClasses"
          class="appointment-container__header">
          {{ $t('headers.my_appointments_future_appointments') }}
        </div-->
        <!--Header
          t-key="headers.my_appointments_future_appointments"
          class="appointment-container__header" /-->
      </div>
      <div
        v-else-if="!hasFutureAppointments"
        ref="next_appointments_header">

        <div
          v-if="!hasPastAppointments"
          class="appointment-container__empty-future-appointments
          appointment-container__empty-future-appointments--icon-container">
          <IlmIcon
            icon="event_busy"
            class="appointment-container__empty-future-appointments__icon" />
        </div>

        <p
          class="appointment-container__empty-future-appointments"
          v-html="emptyAppointmentsText" />

        <div class="appointment-container__empty_button_new_container">
          <IlmButton
            :on-click="clickNewAppointment"
            t-key="button.my_appointments_new"
            prevent-default
            theme="block success"
            class="appointment-container__empty_button_new_container__button" />
        </div>
      </div>

      <!--transition name="collapse"-->
      <div
        v-if="!futureAppointmentsCollapsed">
        <div
          v-for="item in futureAppointments"
          :key="item.day"
          class="appointment-container__section">

          <!--div class="appointment-container__section__content">
            <span class="appointment-container__section__date">{{ dateString(item.day) }}</span>
            <appointment-row
              v-for="(obj, index) in item.array"
              :key="obj.key"
              :element="obj"
              :show-divider="index < item.array.length - 1"
              :on-click="handleRowClick"
              class="appointment-container__row" />
          </div>
          <div class="appointment-container__section__handle">
            <IlmIcon
              size="xl"
              icon="keyboard_arrow_right" />
          </div-->
          <appointment-section
            :day="item.day"
            :elements="item.array"
            :is-past="false"
            :on-click="clickSection" />
        </div>

        <div
          v-if="showMore"
          class="appointment-container__section--show-more">
          <IlmButtonBadge
            :on-click="clickShowMore"
            t-key="button.my_appointments_show_more"
            prevent-default
            theme="inverted"
            class="appointment-container__section--show-more__button" />
        </div>
      </div>
      <!--/transition-->
    </div>

    <div>
      <div
        v-if="hasPastAppointments"
        ref="past_appointments_header">
        <div
          :class="pastHeaderClasses"
          class="appointment-container__header"
          @click="handleHeaderClick(false)">
          <span
            class="appointment-container__header__caret">
            <IlmIcon
              :icon="pastAppointmentsCollapsed ? 'caret-right' : 'caret-down'" />
          </span>
          {{ $t('headers.my_appointments_past_appointments') }}
        </div>
        <!--Header
          t-key="headers.my_appointments_past_appointments"
          class="appointment-container__header padding-top" /-->
      </div>
      <div
        v-else-if="hasFutureAppointments && !hasPastAppointments">
        <p
          class="appointment-container__empty-past-appointments">
          {{ $t('notes.my_past_appointments_empty') }}
        </p>
      </div>

      <!--transition name="collapse"-->
      <div
        v-if="!pastAppointmentsCollapsed">
        <div
          v-for="item in pastAppointments"
          :key="item.day"
          class="appointment-container__section">

          <!--div class="appointment-container__section__content">
            <span
              class="appointment-container__section__date
              appointment-container__section__date--past">
              {{ dateString(item.day) }}
            </span>
            <appointment-row
              v-for="(obj, index) in item.array"
              :key="obj.id"
              :element="obj"
              :show-divider="index < item.array.length - 1"
              :on-click="handleRowClick"
              class="appointment-container__row" />
          </div>
          <div class="appointment-container__section__handle">
            <IlmIcon
              size="xl"
              icon="keyboard_arrow_right" />
          </div-->
          <appointment-section
            :day="item.day"
            :elements="item.array"
            :is-past="true"
            :on-click="clickSection" />
        </div>
      </div>
      <!--/transition-->
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonBadge from '@/components/Buttons/IlmButtonBadge.vue';
import AppointmentSection from './AppointmentListSection.vue';

//const DEFAULT_MAX_FUTURE_APPOINTMENTS_COUNT = 5;
const FUTURE_APPOINTMENTS_PAGE_COUNT = 5;
let currentPage = 1;


function groupByDate(rows, groupBy) {
  /*const orderByTime = function compare(a, b) {
    if (a.time < b.time) {
      return -1;
    } if (a.time > b.time) {
      return 1;
    }
    return 0;
  };
  const orderByDay = function compare(a, b) {
    if (a.day < b.day) {
      return -1;
    } if (a.day > b.day) {
      return 1;
    }
    return 0;
  };*/

  return rows.reduce(function (res, obj) {
    const key = obj[groupBy];
    if (res.length === 0 || res[res.length - 1][groupBy] !== key) {
      const o = {};
      o[groupBy] = key;
      o.array = [obj];
      res.push(o);
    } else {
      const arr = res[res.length - 1].array;
      arr.push(obj);
      res[res.length - 1].array = arr;//arr.sort(orderByTime);
    }
    //return res.sort(orderByDay);
    return res;
  }, []);
}

export default {
  name: 'AppointmentList',

  // Components ********************************
  components: {
    AppointmentSection,
    IlmIcon,
    IlmButton,
    IlmButtonBadge,
  },


  // Properties ********************************
  props: {
    items: {
      type: Array,
      required: true,
    },
    onAppointmentSelected: Function,
    onSectionSelected: Function,
    onSectionScroll: Function,
  },

  data() {
    return {
      isAllFutureAppointmentsVisible: false,
      originalFutureAppointments: null,
      futureAppointments: null,
      pastAppointments: null,
      futureAppointmentsCollapsed: false,
      pastAppointmentsCollapsed: true,
      currentYear: null,
    };
  },

  // Computed ********************************
  computed: {
    futureHeaderClasses() {
      const classNames = {
        'appointment-container__header--clickable': false, //this.hasPastAppointments,
        'appointment-container__header--collapsed': false, //this.futureAppointmentsCollapsed,
      };
      return classNames;
    },
    pastHeaderClasses() {
      const classNames = {
        'appointment-container__header--clickable': true, //this.hasFutureAppointments,
        'appointment-container__header--collapsed': this.pastAppointmentsCollapsed,
      };
      return classNames;
    },
    hasFutureAppointments() {
      return this.futureAppointments && this.futureAppointments.length > 0;
    },
    hasPastAppointments() {
      return this.pastAppointments && this.pastAppointments.length > 0;
    },
    isFutureAppointmentsCollapsible() {
      return false; //this.hasPastAppointments;
    },
    isPastAppointmentsCollapsible() {
      return true;//this.hasFutureAppointments;
    },
    emptyAppointmentsText() {
      if (!this.hasFutureAppointments && this.hasPastAppointments) {
        return this.$t('notes.my_future_appointments_empty');
      }
      if (!this.hasFutureAppointments && !this.hasPastAppointments) {
        return this.$t('notes.my_appointments_empty');
      }
      return '';
    },
    showMore() {
      return this.originalFutureAppointments !== null
          //&& this.originalFutureAppointments.length > DEFAULT_MAX_FUTURE_APPOINTMENTS_COUNT
          && this.originalFutureAppointments.length > (FUTURE_APPOINTMENTS_PAGE_COUNT * currentPage)
          && this.isAllFutureAppointmentsVisible === false;
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    const self = this;
    this.$nextTick(function () {
      window.addEventListener('scroll', self.handleScroll);
    });
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  // Mounted ********************************
  mounted() {
    const self = this;
    const timezone = this.$dateUtils.getTimezone();
    const now = this.$moment.tz(new Date(), timezone);
    this.currentYear = now.year();

    // Future appointments
    let arr = this.items.filter(function (item) {
      //const date = self.$moment.tz(`${item.day} ${item.time}`, timezone);
      const date = self.$moment.tz(`${item.day} 23:59`, timezone);
      return date.isAfter(now);
    }).sort(function compare(a, b) {
      if (a.day < b.day) {
        return -1;
      } if (a.day > b.day) {
        return 1;
      } if (a.time < b.time) {
        return -1;
      } if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
    this.originalFutureAppointments = arr;
    this.setFutureAppointments();

    // Past appointments
    arr = this.items.filter(function (item) {
      //const date = self.$moment.tz(`${item.day} ${item.time}`, timezone);
      const date = self.$moment.tz(`${item.day} 23:59`, timezone);
      return date.isBefore(now);
    }).sort(function compare(a, b) {
      if (a.day < b.day) {
        return 1;
      } if (a.day > b.day) {
        return -1;
      } if (a.time < b.time) {
        return -1;
      } if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
    this.pastAppointments = groupByDate(arr, 'day');

    if (!this.hasFutureAppointments && this.hasPastAppointments) {
      this.pastAppointmentsCollapsed = false;
    }

    //this.onSectionScroll(this.$t('headers.my_appointments_future_appointments'));
    this.onSectionScroll(this.$t('title.my_appointments'));
  },

  // Methods ********************************
  methods: {
    handleScroll() {
      if (!this.onSectionScroll) return;

      const nextAppointmentsHeader = this.$refs.next_appointments_header;
      const pastAppointmentsHeader = this.$refs.past_appointments_header;
      if (nextAppointmentsHeader && pastAppointmentsHeader) {
        const pageY = window.pageYOffset;
        if (pageY > pastAppointmentsHeader.offsetTop - 100) {
          this.onSectionScroll(this.$t('headers.my_appointments_past_appointments'));
        } else {
          //this.onSectionScroll(this.$t('headers.my_appointments_future_appointments'));
          this.onSectionScroll(this.$t('title.my_appointments'));
        }/*else if (pageY > nextAppointmentsHeader.offsetTop - 100) {
          this.onSectionScroll(this.$t('headers.my_appointments_future_appointments'));
        } else {
          this.onSectionScroll(null);
        }*/
      }
    },
    setFutureAppointments() {
      const arr = this.originalFutureAppointments;
      const size = FUTURE_APPOINTMENTS_PAGE_COUNT * currentPage;
      if (this.isAllFutureAppointmentsVisible === false
        && arr.length >= size) {
        this.futureAppointments = groupByDate(arr.slice(0, size), 'day');
      } else {
        this.futureAppointments = groupByDate(arr, 'day');
      }
    },
    dateString(text) {
      const date = this.$moment(text);

      const dateYear = date.year();
      if (dateYear !== this.currentYear) {
        return this.$dateUtils.getDateString(date, 'ddd D MMMM YYYY', true).toUpperCase();
      }
      return this.$dateUtils.getDateString(date, 'ddd D MMMM', true).toUpperCase();
      //return date.format('ddd D MMMM YYYY');
    },
    handleRowClick(item) {
      if (!this.onAppointmentSelected) return;
      this.onAppointmentSelected(item);
    },
    handleHeaderClick(isFuture) {
      if (isFuture === true && this.hasPastAppointments === true) {
        // future appointments header click
        // toggle future appointments list
        this.futureAppointmentsCollapsed = !this.futureAppointmentsCollapsed;
      } else if (isFuture === false/* && this.hasFutureAppointments === true*/) {
        // past appointments header click
        // toggle past appointments list
        this.pastAppointmentsCollapsed = !this.pastAppointmentsCollapsed;
      }
    },
    clickNewAppointment() {
      this.$router.push({ name: this.$constants.ROUTE_SEARCH_SERVICE }, () => {});
    },
    clickShowMore() {
      currentPage += 1;
      if (this.originalFutureAppointments.length
          <= (FUTURE_APPOINTMENTS_PAGE_COUNT * currentPage)) {
        this.isAllFutureAppointmentsVisible = true;
      }
      this.setFutureAppointments();
    },
    clickSection(key, date, elements) {
      if (!this.onSectionSelected) return;
      this.onSectionSelected(key, date, elements);
    },
  },

};
</script>


<style lang="scss" scoped>

.vue-sticky-el.top-sticky {
  box-shadow: $box-shadow-bottom;
}

.appointment-container {
  display: block;
  background: #f5f5f5;

  &__header {
    /*
    margin: 0;
    padding: 12px $padding-section;
    border-bottom: 1px solid $theme-list-divider-color;
    background: #f5f5f5;
    color: black;
    */

    margin: 0;
    margin-top: 30px;
    //border-bottom: 1px solid $theme-list-divider-color;
    padding: 12px $padding-section;
    //background: $theme-ilm-color;
    //background: white;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 16px;
    //color: white;
    color: black;

    &--clickable {
      cursor: pointer;
      touch-action: none;
    }

    &--collapsed {
      background: none;
      color: $theme-link-color;
      border-bottom: none;
    }

    &__caret {
      margin-right: 10px;
    }
  }


  &__section {
    /*
    position: relative;
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    border-bottom: 1px solid $theme-list-divider-color;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-width: 0;
    max-width: 100%;

    cursor: pointer;
    touch-action: auto;
    -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
    transition: $hover-transition;

    @media (hover) {
      &:hover {
        background: $list-pressed-row-background;
      }
    }

    &__content {
      flex: 1 1 auto;
    }

    &__handle {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: 20px;
      background: $theme-border;
      color: white;
    }


    &--border-top {
      border-top: 1px solid $theme-list-divider-color;
    }

    &__date {
      display: block;
      color: black;
      font-family: $base-font-family;
      font-size: $font-size-sm;
      font-weight: bold;
      padding: 10px $padding-section;

      &--past {
        color: $theme-2-color;
      }
    }
    */
    &--show-more {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 20px;

      &__button {
        color: $theme-link-color;
      }
      &__button:hover {
        color: $theme-link-variant;
      }
    }
  }

  &__section + &__section {
    margin-top: 20px;
    //border-top: 1px solid $theme-list-divider-color;
  }

  &__empty-past-appointments, &__empty-future-appointments {
    color: $theme-2-color;
    font-family: $base-font-family;
    font-size: 14px;
    text-align: center;
    padding: 25px $padding-section;
    margin: 0;
  }

  &__empty-future-appointments {
    color: black;
    padding: 0 $padding-section 25px $padding-section;
    font-size: 16px;

    &--icon-container {
      padding-bottom: 10px;
    }
    &__icon {
      color: $theme-2-bg;
      font-size: 120px;
    }
  }

  &__empty_button_new_container {
    padding: 0 $padding-section 30px $padding-section;
    text-align: center;
    //border-bottom: 1px solid $theme-list-divider-color;
    margin-bottom: 20px;

    &__button {
      max-width: 480px;
    }
  }

  /*
  &__row {
    //border-bottom: 1px solid $theme-list-divider-color;
  }

  &__row + &__row {
    //margin-top: 10px;
    //border-top: 1px solid $theme-list-divider-color;
  }*/
}


</style>
