
<template>
  <div
    :class="itemClasses"
    class="row-container"
    @click="handleClick">
    <span
      class="row-container__icon">
      <img
        :src="cardImage"
        class="row-container__icon__card">
    </span>
    <div
      class="row-container__text-container">
      <span
        class="row-container__text">
        {{ cardBrand }}
        <IlmIcon
          v-if="card.isPrefered"
          icon="star"
          size="lg" />
      </span>
      <span
        v-if="showInactive"
        class="row-container__text-container__inactive">
        INATIVO
      </span>
    </div>

    <span
      class="row-container__number">
      <!--IlmIcon
        v-for="i in 4"
        :key="i"
        icon="circle"
        size="sm"
        class="row-container__number__icon" /-->
      ****
      {{ card.lastDigits }}
    </span>
    <span
      class="row-container__icon">
      <IlmIcon
        icon="keyboard_arrow_right"
        size="xl" />
    </span>

    <div
      v-if="showDivider"
      class="row-divider" />
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import cardUtils from '@/utils/payment/utils';
import utils from '@/utils/utils';

export default {
  name: 'PaymentCardListRow',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    card: {
      type: Object,
      required: true,
    },
    showDivider: Boolean,
    onClick: Function,
  },

  // Computed ********************************
  computed: {
    itemClasses() {
      return {
        'row-container--inactive': this.showInactive,
      };
    },
    showInactive() {
      return this.card.status.toLowerCase() !== 'active';
    },
    cardImage() {
      const cardType = this.card.brand;
      if (cardType) {
        return cardUtils.iconCardImage(cardType);
      }
      return null;
    },
    cardBrand() {
      const cardType = this.card.brand ? this.card.brand : '';
      if (cardType.toLowerCase() === 'visa') {
        return `${cardType.toUpperCase()}`;
      }
      return utils.capitalizeWords(cardType);
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onClick) return;
      this.onClick(this.card);
    },
  },
};
</script>


<style lang="scss" scoped>

.row-container {
  position: relative;
  background: white;
  padding: 12px $padding-section;
  min-height: 37px;
  cursor: pointer;
  color: $theme-3-color;
  //color: black;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }
/*
  &:hover {
    background: $theme-3-bg;
  }

  &:active {
    background: $list-pressed-row-background;
  }
*/
  &__text-container {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-content: center;

    &__inactive {
    }
  }

  &__text {
    font-family: $heading-font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2em;
    color: black;
  }

  &__number {
    //font-family: $heading-font-family;
    font-family: 'Lucida Console', monospace;
    font-size: 16px;
    line-height: 1;
    padding-right: 8px;
    padding-left: 8px;
    color: black;
    text-align: right;

    flex: 0 0 auto;
    display: flex;
    align-items: center;

    &__icon {
      padding-right: 3px;
      font-size: 8px;
      line-height: 1;

      &:last-child {
        margin-right: 6px;
      }
    }
  }

  &__icon {
    font-family: $heading-font-family;
    font-size: 14px;
    line-height: 1.2em;
    color: $theme-color-dim;

    flex: 0 0 auto;

    &__card {
      width: 40px;
      height: 25px;
      padding: 0;
      margin-right: $padding-section;
    }
  }

  &--inactive ::v-deep &__text,
  &--inactive ::v-deep &__number {
    color: $theme-3-color;
  }

}

.row-divider {
  position: absolute;
  bottom: 0;
  left: $padding-section;
  right: $padding-section;
  height: 1px;
  background: $theme-list-divider-color;
}

</style>
