<template>
  <div class="drawer-schedule">
    <div
      class="drawer-schedule__row drawer-schedule__row--clickable"
      @click="handleClick">
      <div
        class="drawer-schedule__row__icon">
        <IlmIcon
          :icon="toggleIcon"
          size="lg" />
      </div>
      <div class="drawer-schedule__row__title drawer-schedule__row__title--bold">
        {{ drawerTitle }}
      </div>
      <div
        v-if="isColapsed"
        class="drawer-schedule__row__schedule">
        <div
          v-for="(item, index) in drawerSchedule.schedules"
          :key="index"
          class="drawer-schedule__row__schedule__row">
          {{ `${item[0]} - ${item[1]}` }}
        </div>
      </div>
    </div>

    <div
      v-if="isColapsed"
      class="drawer-schedule__divider" />

    <transition name="fade">
      <div v-if="!isColapsed">
        <div
          v-for="(schedule, index) in schedules"
          :key="index"
          :class="rowClass(schedule, index)"
          class="drawer-schedule__row">
          <div class="drawer-schedule__row__title">
            {{ getWeekdayName(schedule.weekday) }}
          </div>
          <div
            class="drawer-schedule__row__schedule">
            <div
              v-for="(item, indexS) in schedule.schedules"
              :key="indexS"
              class="drawer-schedule__row__schedule__row">
              {{ `${item[0]} - ${item[1]}` }}
            </div>
            <div
              v-if="schedule.schedules === null || schedule.schedules.length === 0"
              class="drawer-schedule__row__schedule__row">
              {{ $t('text.schedule_day_unavailable') }}
            </div>
          </div>
        </div>
        <div class="drawer-schedule__divider" />
      </div>
    </transition>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'DrawerSchedule',


  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    business: Object,
  },

  // Data ********************************
  data() {
    return {
      isColapsed: true,
      now: null,
    };
  },

  // Computed ********************************
  computed: {
    toggleIcon() {
      return this.isColapsed ? 'caret-right' : 'caret-down';
    },
    schedules() {
      return this.business.getWeekSchedule();
    },
    drawerTitle() {
      const schedule = this.drawerSchedule;
      if (schedule.schedules === null || schedule.schedules.length === 0) {
        return this.$t('section_list.business_schedule_unavailable');
      }
      let hours;
      let firstHour;
      let finalHour;
      let initialDate;
      let finalDate;
      for (let i = 0; i < schedule.schedules.length; i++) {
        // [10:00, 20:00]
        hours = schedule.schedules[i];
        firstHour = hours[0];
        finalHour = hours[1];
        initialDate = this.$moment(`${this.now.format('YYYY-MM-DD')} ${firstHour}`, 'YYYY-MM-DD HH:mm');
        finalDate = this.$moment(`${this.now.format('YYYY-MM-DD')} ${finalHour}`, 'YYYY-MM-DD HH:mm');
        if (this.now.isBetween(initialDate, finalDate)) {
          return this.$t('section_list.business_schedule_available');
        }
      }
      return this.$t('section_list.business_schedule_unavailable');
    },
    drawerSchedule() {
      return this.schedules[this.now.isoWeekday() - 1];
    },
  },

  // Created ********************************
  created() {
    this.now = this.$moment(new Date());
  },


  // Mounted ********************************
  mounted() {
  },

  /*
  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    next();
  },
*/
  // Methods ********************************
  methods: {
    handleClick() {
      this.isColapsed = !this.isColapsed;
    },
    getWeekdayName(day) {
      return this.$t(`calendar.weekday_${day}`);
    },
    rowClass(schedule, index) {
      return {
        'drawer-schedule__row--unavailable': schedule.schedules === null || schedule.schedules.length === 0,
        'drawer-schedule__row--today': schedule.weekday === this.now.isoWeekday(),
        'drawer-schedule__row--divider': index < this.schedules.length - 1,
      };
    },
  },
};
</script>


<style lang="scss" scoped>

.drawer-schedule {
  padding: 0;

  &__divider {
    border-bottom: 1px solid $theme-list-divider-color;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: $padding-section;

    &--divider {
      border-bottom: 1px solid $theme-list-divider-color;
      padding: 4px 0;
      margin: 0 $padding-section;
      min-height: 44px;
    }

    &__title {
      display: inline-flex;
      font-family: $heading-font-family;
      font-weight: normal;
      font-size: 18px;
      color: black;
      margin: 0;
      line-height: 1.2em;

      padding-right: 20px;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;

      &--bold {
        font-weight: bold;
      }
    }

    &__icon {
      display: inline-flex;
      flex: 0 0 auto;
      color: black;
      padding-right: $padding-section;
    }

    &__schedule {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: $heading-font-family;
      font-size: 14px;
      color: black;
      margin: 0;
      text-align: right;

      flex: 0 0 auto;

      &__row {
        color: black;
        text-align: right;
      }
    }

    &--unavailable ::v-deep &__title,
    &--unavailable ::v-deep &__schedule {
       color: $theme-2-color;

       &__row {
        color: $theme-2-color;
        //color: $theme-error;
        font-weight: bold;
      }
    }

    &--today ::v-deep &__title,
    &--today ::v-deep &__schedule {
       //color: $theme-ilm-color;
       font-weight: bold;

      &__row {
        //color: $theme-ilm-color;
        font-weight: bold;
      }
    }

    &--clickable {
      cursor: pointer;
      touch-action: auto;
      -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
      transition: $hover-transition;
    }
    @media (hover) {
      &--clickable:hover {
        background: $list-pressed-row-background;
      }
    }
  }

}

</style>
