const moment = require('moment-timezone');

const DEFAULT_WEEK_DAYS = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

class Discount {
  constructor(obj) {
    this.campaignId = obj.campaignId;
    this.id = obj.id;
    this.title = obj.conditionsDescription;
    this.description = obj.valuesDescription;
    this.isFeatured = obj.isFeatured;
    this.sortWeight = obj.sortWeight;
    this.weekValues = {};
    this.conditions = {};

    const values = obj.values;
    if (values && values !== '') {
      this.weekValues = JSON.parse(values);
    }

    if (obj.conditions && obj.conditions !== '') {
      this.conditions = JSON.parse(obj.conditions);
    }

    this.maxValue = this.getMaxDiscountValue();
  }


  static typecast(obj) {
    const finalObj = Object.assign(new Discount(), obj);
    return finalObj;
  }

  hasServiceId(id) {
    const limitServices = this.conditions.limit_services_to;
    if (limitServices && limitServices.length > 0) {
      return limitServices.map(o => `${o}`).indexOf(id) > -1;
    }
    return false;
  }

  getServiceIds() {
    const limitServices = this.conditions.limit_services_to;
    if (limitServices && limitServices.length > 0) {
      return limitServices.map(o => `${o}`);
      //const res = [];
      //limitServices.forEach(o => res.push(`${o}`));
      //return res;
    }
    return [];
  }

  getMaxDiscountValue() {
    let max = 0;
    for (let i = 0; i < 7; i++) {
      const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[i]];
      if (weekdayArray) {
        for (let j = 0; j < weekdayArray.length; j++) {
          const weekDayValue = weekdayArray[j];
          const value = weekDayValue.value;
          if (value && value > max) {
            max = value;
          }
        }
      }
    }
    return max;
  }

  getMaxDiscount(serviceId) {
    let max = 0;
    const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
    for (let i = 0; i < 7; i++) {
      const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[i]];
      const limitServices = this.conditions.limit_services_to;
      if (weekdayArray && ((!serviceIdNumeric && !limitServices)
        || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
        for (let j = 0; j < weekdayArray.length; j++) {
          const weekDayValue = weekdayArray[j];
          const value = weekDayValue.value;
          if (value && value > max) {
            max = value;
          }
        }
      }
    }
    return max > 0 ? max : null;
  }

  getDayDiscount(weekday, serviceId) {
    if (!weekday) {
      weekday = moment(new Date()).isoWeekday();
    }
    const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[weekday - 1]];
    const limitServices = this.conditions.limit_services_to;
    if (weekdayArray
      && (!limitServices
        || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
      let max = 0;
      for (let j = 0; j < weekdayArray.length; j++) {
        const value = weekdayArray[j].value;
        if (value && value > max) {
          max = value;
        }
      }
      return max > 0 ? max : null;
    }
    return null;
  }

  getDayAvailableDiscounts(weekday, serviceId) {
    if (!weekday) {
      weekday = moment(new Date()).isoWeekday();
    }
    const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[weekday - 1]];
    const limitServices = this.conditions.limit_services_to;
    if (weekdayArray
      && (!limitServices
        || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
      const values = [];
      for (let j = 0; j < weekdayArray.length; j++) {
        const value = weekdayArray[j].value;
        if (value) {
          values.push(value);
        }
      }
      return values;
    }
    return null;
  }

  getDayDiscountValue(weekday, serviceIds) {
    if (!weekday) {
      weekday = moment(new Date()).isoWeekday();
    }
    let max = 0;
    for (let i = 0; i < serviceIds.length; i++) {
      const serviceId = serviceIds[i];
      const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
      const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[weekday - 1]];
      const limitServices = this.conditions.limit_services_to;
      if (weekdayArray
        && (!limitServices
          || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
        for (let j = 0; j < weekdayArray.length; j++) {
          const value = weekdayArray[j].value;
          if (value && value > max) {
            max = value;
          }
        }
      }
      return max > 0 ? max : null;
    }
    return null;
  }

  getCalendarDayDiscount(weekday) {
    if (!weekday) {
      weekday = moment(new Date()).isoWeekday();
    }
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[weekday - 1]];
    if (weekdayArray) {
      let max = 0;
      for (let j = 0; j < weekdayArray.length; j++) {
        const value = weekdayArray[j].value;
        if (value && value > max) {
          max = value;
        }
      }
      return max > 0 ? max : null;
    }
    return null;
  }

  hasDayDiscount(weekday, serviceId) {
    const dayDiscount = this.getDayDiscount(weekday, serviceId);
    if (dayDiscount) {
      return true;
    }
    return false;
  }

  hasDiscount() {
    for (let i = 1; i <= 7; i++) {
      const value = this.weekValues[DEFAULT_WEEK_DAYS[i]];
      if (value) {
        return true;
      }
    }
    return false;
  }

  getHourDiscount(day, hour) {
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[day - 1]];
    if (weekdayArray) {
      for (let i = 0; i < weekdayArray.length; i++) {
        const hours = weekdayArray[i].hours;
        if (hours) {
          const hoursArray = hours.split(',');
          if (hoursArray.length === 2) {
            const startHour = hoursArray[0];
            const endHour = hoursArray[1];
            if (hour >= startHour && hour < endHour) {
              return weekdayArray[i].value;
            }
          }
        }
      }
    }
    return null;
  }

  hasHourDiscount(serviceId, day, start, end) {
    const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[day - 1]];
    const limitServices = this.conditions.limit_services_to;
    if (weekdayArray
      && (!limitServices
        || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
      for (let i = 0; i < weekdayArray.length; i++) {
        const hours = weekdayArray[i].hours;
        if (hours) {
          const hoursArray = hours.split(',');
          if (hoursArray.length === 2) {
            const startHour = hoursArray[0];
            const endHour = hoursArray[1];
            if ((start >= startHour && start < end) || (end > startHour && end <= endHour)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  hasTimeDiscount(serviceId, day, time) {
    const serviceIdNumeric = serviceId ? parseInt(serviceId, 10) : null;
    const weekdayArray = this.weekValues[DEFAULT_WEEK_DAYS[day - 1]];
    const limitServices = this.conditions.limit_services_to;
    if (weekdayArray
      && (!limitServices
        || (serviceIdNumeric && limitServices && limitServices.includes(serviceIdNumeric)))) {
      for (let i = 0; i < weekdayArray.length; i++) {
        const hours = weekdayArray[i].hours;
        if (hours) {
          const hoursArray = hours.split(',');
          if (hoursArray.length === 2) {
            const startHour = hoursArray[0];
            const endHour = hoursArray[1];
            if (time >= startHour && time < endHour) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}

export default Discount;
