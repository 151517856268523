<template>
  <div class="payment-section">
    <h1
      v-if="showTitle"
      class="payment-section__title">
      {{ $t('headers.payment_details_title_1') }}
    </h1>
    <div
      v-if="showTitle"
      class="payment-section__text">
      {{ $t('text.payment_details_text_1') }}
    </div>
    <div class="payment-section__fields">
      <SectionInput
        ref="input_name"
        :value="customer.name"
        :placeholder="$t('placeholders.payment_user_name')"
        :on-change="onNameChange"
        :focus-on-start="false"
        :validate-field="validateNameField"
        :on-input-enter="onInputEnter"
        :disabled="isLoading"
        :caps-first-letter="true"
        icon="face"
        class="payment-section__fields__input" />
      <SectionInput
        ref="input_email"
        :value="customer.email"
        :placeholder="$t('placeholders.payment_user_email')"
        :on-change="onEmailChange"
        :disabled="isLoading"
        :focus-on-start="false"
        :on-input-enter="onInputEnter"
        :validate-field="validateEmailField"
        type="email"
        icon="mail_outline"
        class="payment-section__fields__input" />
      <SectionInput
        ref="input_tax_number"
        :value="customer.taxNumber"
        :placeholder="$t('placeholders.payment_user_cpf')"
        :on-change="onTaxNumberChange"
        :disabled="isLoading"
        :focus-on-start="false"
        :on-input-enter="onInputEnter"
        :validate-field="validateTaxNumberField"
        :format-input="formatTaxNumberField"
        type="tel"
        icon="payment"
        class="payment-section__fields__input" />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import SectionInput from '@/components/Inputs/SectionInput.vue';

import utils from '@/utils/utils';

export default {
  name: 'PaymentSection1',

  components: {
    SectionInput,
  },

  // Properties ********************************
  props: {
    customer: Object,
    editState: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onDataChanged: Function,
    onProceed: Function,
  },

  // Data ********************************
  data() {
    return {
      originalName: null,
      originalEmail: null,
      originalTaxNumber: null,
    };
  },

  // Computed ********************************
  computed: {
  },

  created() {
    if (this.customer && this.customer.taxNumber) {
      this.customer.taxNumber = this.formatTaxNumberField(this.customer.taxNumber);
    }
    if (this.customer && this.editState === true) {
      this.originalName = this.customer.name;
      this.originalEmail = this.customer.email;
      this.originalTaxNumber = this.customer.taxNumber;
    }
  },

  // Mounted ********************************
  mounted() {
    /*this.name = this.customer.name;
    this.email = this.customer.email;
    this.cpf = this.customer.cpf;*/

    //this.checkDataChanged();
  },

  // Methods ********************************
  methods: {
    isFormComplete() {
      //if (this.editState === false) {
      if (!this.customer.name || this.customer.name.trim().length === 0) return false;
      if (!this.customer.email || utils.isEmailValid(this.customer.email) === false) return false;
      if (!this.customer.taxNumber
        || utils.isCpfValid(this.customer.taxNumber) === false) return false;
      /*} else if (this.customer.taxNumber
            && utils.isCpfValid(this.customer.taxNumber) === false) return false;*/


      if (this.editState === true && this.originalDataChanged() === false) {
        return false;
      }
      return true;
    },
    originalDataChanged() {
      if (this.customer.name === this.originalName
        && this.customer.email === this.originalEmail
        && this.customer.taxNumber === this.originalTaxNumber) {
        return false;
      }
      return true;
    },
    checkDataChanged() {
      if (this.onDataChanged) {
        this.onDataChanged(this.isFormComplete());
      }
    },

    onInputEnter(el) {
      let input;
      if (el === this.$refs.input_name) {
        input = this.$refs.input_email;
      } else if (el === this.$refs.input_email) {
        input = this.$refs.input_tax_number;
      } else if (el === this.$refs.input_tax_number && this.onProceed) {
        this.onProceed();
        return;
      }
      if (input) {
        input.setFocus();
      }
    },

    onNameChange(text) {
      //this.name = text;
      this.customer.name = text;
      this.checkDataChanged();
    },
    onEmailChange(text) {
      //this.email = text;
      this.customer.email = text;
      this.checkDataChanged();
    },
    onTaxNumberChange(text) {
      //this.cpf = text;
      this.customer.taxNumber = text;
      this.checkDataChanged();
    },

    validateNameField() {
      if (this.customer.name && this.customer.name.length > 1) {
        return true;
      }
      return false;
    },
    validateEmailField() {
      /*if (this.editState === true) {
        if (!this.customer.email || this.customer.email.length === 0) {
          return true;
        }
      }*/
      if (this.customer.email && utils.isEmailValid(this.customer.email)) {
        return true;
      }
      return false;
    },
    validateTaxNumberField() {
      /*if (this.editState === true) {
        if (!this.customer.taxNumber || this.customer.taxNumber.length === 0) {
          return true;
        }
      }*/
      if (this.customer.taxNumber && utils.isCpfValid(this.customer.taxNumber)) {
        return true;
      }
      return false;
    },
    formatTaxNumberField(val) {
      return utils.formatCpf(val);
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-section {
  padding: $padding-section 0;

  &__text {
    font-size: 14px;
    color: black;
    text-align: center;
    padding: 0 $padding-section;
    max-width: 380px;
    margin: 0 auto;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 8px $padding-section;
    margin: 0;
  }

  &__fields {
    margin-top: $padding-section;

    &__input {
      margin-top: 12px;
    }
  }
}
</style>
