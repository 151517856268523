class MyAppointmentPolicies {
  constructor(obj) {
    this.key = obj.key;
    this.update = obj.update;
    this.cancel = obj.cancel;
  }

  canUpdate() {
    return this.update && this.update === true;
  }

  canCancel() {
    return this.cancel && this.cancel === true;
  }
}

export default MyAppointmentPolicies;
