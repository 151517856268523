<template>
  <transition name="fade">
    <div class="alert">
      <div
        class="alert__frame"
        @click="handleCloseClick" />
      <transition name="fade">
        <div
          v-if="contentShowing"
          class="alert__content">
          <div class="alert__content__slot">
            <div class="alert__content__slot__content">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="alert__content__slot__content__row"
                @click="itemClicked(index)">
                <p
                  class="alert__content__slot__content__row__text">
                  {{ item }}
                </p>
              </div>
            </div>
            <div class="alert__content__btn_container">
              <IlmButton
                v-if="btnCancel"
                ref="btn_cancel"
                :on-click="handleCancelClick"
                :label="btnCancel"
                theme="simple"
                class="alert__content__btn_container__ok" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';

const noScrollClass = 'no-scroll';


export default {
  name: 'AlertItemList',

  // Components ********************************
  components: {
    IlmButton,
  },

  // Properties ********************************
  props: {
    items: Array,
    btnCancel: String,
    itemSelected: Function,
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
    };
  },

  // Computed ********************************
  computed: {
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    this.contentShowing = true;
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    handleCancelClick() {
      this.handleCloseClick();
    },
    itemClicked(index) {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('selected', index);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 90%;
    max-width: 500px;
    overflow: hidden;
    text-align: right;

    &__slot {
      position: relative;
      display: block;
      background: white;
      margin: 0 $padding-section*2;
      border-radius: $theme-border-radius;
      overflow: hidden;
      padding: $padding-section;
      padding-bottom: 10px;
      overflow-y: auto;
      max-height: 300px;

      &__content {
        display: block;
        position: relative;
        //margin-bottom: 40px;

        &__row {
          min-height: 60px;
          cursor: pointer;
          touch-action: auto;
          -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
          transition: $hover-transition;
          display: flex;

          &__text {
            color: black;
            text-align: left;
            font-weight: bold;
            font-size: $font-size-md;
            font-family: $base-font-family;
            margin: auto 0;
          }
        }
        &__row + &__row {
          border-top: 1px solid $theme-list-divider-color;
        }
        @media (hover) {
          &__row:hover {
            background: $list-pressed-row-background;
          }
        }
      }
    }

    &__btn_container {
      position: sticky;
      background: white;
      padding-bottom: 10px;
      bottom: -10px;
      z-index: 99;
      border-radius: $theme-border-radius;

      &__ok {
        display: inline;
        margin: 0;
        font-size: $font-size-md;
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
