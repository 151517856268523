<template>
  <div class="new-booking-confirmation">
    <NavigationBar
      :title="dateText"
      :subtitle="navbarSubtitle"
      :alternative-subtitle="navbarSubtitle"
      :small-title="totalPriceTitle"
      :small-subtitle="navbarSmallSubtitle"
      show-divider
      show-back />
    <div
      id="new-booking-confirmation-scroll"
      :class="contentClasses">
      <SectionAppointments
        :appointments="sectionList"
        :show-state="false"
        :on-remove-click="onAppointmentRemove"
        :show-notes="false"
        confirmation-mode />
    </div>

    <!--Sticky position="bottom"-->
    <div
      :class="footerClass"
      class="new-booking-confirmation__footer new-booking-confirmation__footer--shadow">
      <IlmButton
        v-if="!isEditMode"
        :on-click="clickConfirm"
        :label="confirmButtonLabel"
        :disabled="isLoading"
        class="new-booking-confirmation__footer__confirm_button"
        icon="arrow_forward"
        prevent-default
        theme="block success" />
      <IlmButtonColumnized
        v-else
        :on-click="clickConfirm"
        :label-one="confirmButtonLabel"
        :label-two="confirmButtonPrice"
        :is-working="isLoading"
        :disabled="isLoading"
        :is-price="false"
        prevent-default
        theme="block success"
        class="new-booking-confirmation__footer__confirm_button" />
    </div>
    <!--/Sticky-->

    <Alert
      v-if="simpleAlertShowing"
      :title="simpleAlertTitle"
      :message="simpleAlertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onSimpleAlertClosed"
      @ok="onSimpleAlertClosed" />
  </div>
</template>

<script>
import NavigationBar from '@/components/Nav/NavigationBar.vue';
import SectionAppointments from '@/components/Sections/SectionAppointments.vue';
import IlmButtonColumnized from '@/components/Buttons/IlmButtonColumnized.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import Appointment from '@/models/appointment';
import Error from '@/graphql/error';
import jwt from '@/utils/jwt';
import DateUtils from '@/utils/dateUtils.js';
import formatter from '@/utils/formatter.js';

export default {
  name: 'NewBookingConfirmation',

  // Components ********************************
  components: {
    NavigationBar,
    SectionAppointments,
    IlmButtonColumnized,
    IlmButton,
    Alert,
  },

  data() {
    return {
      appointments: [],
      sectionList: [],
      business: null,
      isLoading: false,

      isEditMode: false,

      simpleAlertShowing: false,
      simpleAlertTitle: '',
      simpleAlertMessage: '',
      simpleAlertCallback: null,
    };
  },

  // Computed ********************************
  computed: {
    navbarSubtitle() {
      if (this.$root.isDesktop) {
        return this.$t('title.appointment_confirm_title');
      }
      return this.business ? this.business.name : '';
    },
    navbarSmallSubtitle() {
      /*if (this.$root.isDesktop) {
        return null;
      }
      return this.$t('title.appointment_confirm_title');*/
      return null;
    },
    comandaDate() {
      if (this.sectionList.length > 0) {
        const day = this.sectionList[0].day;
        return this.$moment(day, 'YYYY-MM-DD');
      }
      return null;
    },
    dateText() {
      const date = this.comandaDate;
      if (date) {
        const currDate = date.format('D MMMM');
        const isToday = DateUtils.isTodayDate(date);
        const isTomorrow = DateUtils.isTomorrowDate(date);

        if (isToday === true || isTomorrow === true) {
          return this.$t(isToday ? 'dates.today_format' : 'dates.tomorrow_format', { date: currDate });
        }

        return `${date.format('ddd').toUpperCase()}, ${currDate}`;
      }
      return '';
    },
    contentClasses() {
      return {
        'new-booking-confirmation__content': true,
        'new-booking-confirmation__content--padding-bottom': this.$root.isDesktop === false,
      };
    },
    footerClass() {
      return { 'new-booking-confirmation__footer--sticky': this.$root.isDesktop };
    },
    confirmButtonLabel() {
      if (this.isEditMode) return this.$t('button.confirm_change');
      return this.$tc('button.confirm_schedule', this.sectionList.length, { count: this.sectionList.length });
      /*return this.business.id === '1164'
        ? this.$tc('button.confirm_schedule',
        this.sectionList.length, { count: this.sectionList.length })
        : this.$t('button.confirm');*/
      //return this.sectionList ? this.$tc('title.services_counter',
      //this.sectionList.length, { count: this.sectionList.length }) : '';
    },
    confirmButtonPrice() {
      return this.sectionList ? this.$tc('title.services_counter', this.sectionList.length, { count: this.sectionList.length }) : '';
      /*const weekday = this.comandaDate.isoWeekday();
      const priceTotal = this.sectionList.reduce((prev, item) => {
        const price = item.service.value ? item.service.value : item.service.price;
        if (item.service.discount) {
          const discountValue = item.service.discount.getHourDiscount(weekday, item.time);
          if (discountValue) {
            return prev + (price * (100 - discountValue) * 0.01);
          }
        }
        return prev + price;
      }, 0);
      return formatter.formatPrice(priceTotal, this.businessLocale);*/
    },
    totalPriceTitle() {
      if (!this.comandaDate) {
        return null;
      }
      if (this.sectionList.some(x => x.service.isPriceUnavailable === true)) return null;
      const weekday = this.comandaDate.isoWeekday();
      const priceTotal = this.sectionList.reduce((prev, item) => {
        const price = item.service.value ? item.service.value : item.service.price;
        if (item.service.discount) {
          const discountValue = item.service.discount.getHourDiscount(weekday, item.time);
          if (discountValue) {
            return prev + price * (100 - discountValue) * 0.01;
          }
        }
        return prev + price;
      }, 0);
      if (priceTotal > 0) {
        return this.$t('title.payment_total_estimate', { value: formatter.formatPrice(priceTotal, this.businessLocale) });
      }
      return null;
    },
    businessLocale() {
      let locale = 'pt-BR';
      if (this.business && this.business.locale) {
        locale = this.business.locale;
      }
      return locale;
    },
  },

  // Watch ********************************
  watch: {},

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    this.business = auth.business;

    const appointmentsList = this.$store.getters.getAppointments;
    if (!appointmentsList) {
      this.$router.go(-1);
      return;
    }

    this.isEditMode = this.$store.getters.getEditAppointment != null;

    this.appointments = appointmentsList.getAppointments();
  },

  // Mounted ********************************
  mounted() {
    if (this.appointments.length === 0) {
      this.$router.go(-1);
      return;
    }
    this.sectionList = this.appointments.sort(function compare(a, b) {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });

    this.$analytics.track('Visit: Confirm Appointments');

    const discounts = this.$store.getters.getDiscounts;
    if (discounts && discounts.getItems().length > 0) {
      let item = null;
      let weekday = null;
      let serviceDiscounts = null;
      let discountValue = null;
      let maxDiscount = 0;
      let selectedDiscount = null;

      for (let i = 0; i < this.sectionList.length; i++) {
        item = this.sectionList[i];
        weekday = this.$moment(item.day).isoWeekday();
        serviceDiscounts = discounts.getServiceDiscounts(weekday, item.service.id);
        maxDiscount = 0;
        if (serviceDiscounts && serviceDiscounts.length > 0) {
          for (let j = 0; j < serviceDiscounts.length; j++) {
            const d = serviceDiscounts[j];
            discountValue = d.getHourDiscount(weekday, item.time);
            if (discountValue > maxDiscount) {
              maxDiscount = discountValue;
              selectedDiscount = d;
            }
          }
          item.discount = selectedDiscount;
        }
      }
    }
  },

  destroyed() {},

  // Methods ********************************
  methods: {
    clickConfirm() {
      this.$analytics.track('Action: Confirm Appointments');
      const auth = this.$store.getters.getAuth;
      if (auth) {
        if (auth.isAuthenticated() && !auth.needNameConfirmation()) {
          this.saveAppointments();
        } else {
          this.$router.replace({ name: this.$constants.ROUTE_VERIFY_PHONE }, () => {});
        }
      } else {
        this.isLoading = false;
        this.$root.loading = false;
        const self = this;
        this.alert(this.$t('errors.generic_title'), this.$t('errors.generic_message'), function () {
          self.$router.replace({ name: self.$constants.ROUTE_SEARCH_SERVICE }, () => {});
        });
      }
    },

    saveAppointments() {
      //this.$router.push({ name: 'new-booking-confirmation' });

      this.isLoading = true;
      //this.$router.app.loading = true;
      const self = this;
      const appointments = [];

      this.sectionList.forEach(item => {
        const appointment = new Appointment();
        appointment.date = item.date;
        appointment.day = item.day;
        appointment.time = item.time;
        appointment.service = item.service;
        appointment.professional = item.professional;
        appointment.key = item.key;

        if (item.service.discount) {
          appointment.discount = item.service.discount;
        }
        appointments.push(appointment);
      });

      const auth = this.$store.getters.getAuth;
      if (auth) {
        if (auth.isAuthenticated() && !auth.needNameConfirmation()) {
          this.sendAppointments(auth, appointments);
        } else {
          this.$router.push({ name: this.$constants.ROUTE_VERIFY_PHONE }, () => {});
        }
      } else {
        this.isLoading = false;
        this.$root.loading = false;
        this.alert(this.$t('errors.generic_title'), this.$t('errors.generic_message'), function () {
          self.$router.replace({ name: self.$constants.ROUTE_SEARCH_SERVICE }, () => {});
        });
      }
    },

    sendAppointments(auth, appointments) {
      const self = this;

      /*if (this.isEditMode) {
        this.$analytics.track('Request Edit Appointment');
        this.$graphql.editAppointment(appointment,
          function (appointmentPost) {
            self.sendAppointmentSuccess(auth, appointment, appointmentPost, true);
          },
          function (error) {
            self.sendAppointmentError(error);
          });
      } else {
        this.$analytics.track('Request Create Appointment');
        this.$graphql.createAppointment(appointment,
          function (appointmentPost) {
            self.sendAppointmentSuccess(auth, appointment, appointmentPost, false);
          },
          function (error) {
            self.sendAppointmentError(error);
          });
      }*/
      if (appointments.length === 1 && this.isEditMode && appointments[0].key) {
        this.$analytics.track('Request: Edit Appointment');
        this.$graphql.editAppointment(
          appointments,
          function (appointmentsPost) {
            self.sendAppointmentSuccess(auth, appointments, appointmentsPost, true);
          },
          function (error) {
            self.sendAppointmentError(error);
          },
        );
      } else {
        this.$analytics.track('Request: Create Appointment');
        this.$graphql.createAppointments(
          appointments,
          function (appointmentsPost) {
            self.sendAppointmentSuccess(auth, appointments, appointmentsPost, false);
          },
          function (error) {
            self.sendAppointmentError(error);
          },
        );
      }
    },

    sendAppointmentSuccess(auth, appointments, appointmentsPost, isEditing) {
      this.$analytics.trackNewBookingEvent(auth.business.id);

      const discounts = this.$store.getters.getDiscounts;
      const professionals = this.$store.getters.getProfessionals;
      /*
      const keys = appointmentsPost.map(x => x.key);
      //const keysString = keys.join(',');
      try {
        const now = this.$moment(new Date()).startOf('day');
        const diff = this.comandaDate.startOf('day').diff(now, 'days');
        keys.forEach(key => {
          this.$analytics.track(mixpanelAction, {
            key,
            dayDiff: diff,
            campaignId: auth.campaignId,
          });
        });
      } catch (ex) {
        console.log(ex);
        keys.forEach(key => {
          this.$analytics.track(mixpanelAction, {
            key,
            campaignId: auth.campaignId,
          });
        });
      }*/

      let diff = null;
      try {
        const now = this.$moment(new Date()).startOf('day');
        diff = this.comandaDate.startOf('day').diff(now, 'days');
      } catch (ex) {
        console.log(ex);
      }

      const mixpanelAction = isEditing ? 'Response: Appointment Edited' : 'Response: Appointment Created';
      const changedAppointments = [];
      for (let i = 0; i < appointmentsPost.length; i++) {
        const item = appointmentsPost[i];

        this.$analytics.track(mixpanelAction, {
          key: item.key,
          dayDiff: diff,
          campaignId: auth.campaignId,
        });

        const appointment = appointments.filter(x => x.service.id === item.serviceId && x.time === item.time)[0];
        if (appointment) {
          const newAppointment = new Appointment();
          newAppointment.key = item.key;
          newAppointment.day = item.day;
          newAppointment.time = item.time;
          newAppointment.service = appointment.service;
          newAppointment.state = 'booked';

          if (professionals) {
            const professionalsFilter = professionals.filter(p => p.id === item.professionalId);
            if (professionalsFilter.length > 0) {
              newAppointment.professional = professionalsFilter[0];
            }
          }

          if (discounts) {
            newAppointment.discount = discounts.getDiscountWithId(item.discountId);
          }

          changedAppointments.push(newAppointment);
        }
      }

      /*if (!isEditing) {
        appointment.key = appointmentPost.key;
      }*/
      /*const professionalsFilter = this.professionals
        .filter(item => item.id === appointmentsPost.professionalId);
      if (professionalsFilter.length > 0) {
        appointment.professional = professionalsFilter[0];
      }*/
      //this.$store.dispatch('setAppointmentParams', appointment);

      if (!auth.client.id && this.$store.getters.isExternalSource) {
        this.requestExternalSourceToken(changedAppointments);
      } else {
        this.$root.loading = false;
        this.$router.replace(
          {
            name: this.$constants.ROUTE_SUCCESS,
            //params: { isEditMode: this.isEditMode },
            params: {
              isEditMode: isEditing,
              appointments: changedAppointments,
            },
          },
          () => {},
        );
      }
    },

    sendAppointmentError(error) {
      this.isLoading = false;
      this.$root.loading = false;
      const errorCode = error.getCode();
      if (errorCode === Error.unavailableSlotCode) {
        const self = this;
        this.alert(this.$t('errors.payment_generic_title'), this.$t('errors.slot_unavailable'), function () {
          self.$router.go(-1);
        });
        this.$analytics.track('Error: unavailableSlotCode', {});
      } else if (errorCode === Error.unavailableProfessionalOverrideCode) {
        const self = this;
        this.alert(this.$t('errors.payment_generic_title'), this.$t('errors.professional_override'), function () {
          self.$router.go(-1);
        });
        this.$analytics.track('Error: unavailableProfessionalOverrideCode', {});
      } else if (errorCode === Error.expiredTokenCode) {
        this.$localStorage.setToken('');
        this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      } else if (errorCode === Error.appointmentsEditCode) {
        const self = this;
        const minutes = this.business.minTimeToUpdate;
        let time = '';
        if (minutes) {
          const hours = minutes / 60;
          if (hours < 1) {
            time = this.$tc('errors.appointment_edit_time_exceed_minutes', minutes, { minutes });
          } else {
            time = this.$tc('errors.appointment_edit_time_exceed_hours', hours, { hours });
          }
        }
        this.alert(this.$t('errors.payment_generic_title'), this.$t('errors.appointment_edit_time_exceed', { time }), function () {
          self.$router.go(-1);
        });
        this.$analytics.track('Error: editAppointmentMinUpdateTime', {});
      } else {
        this.alert(this.$t('errors.generic_title'), this.$t('errors.generic_message'));
      }
    },

    requestExternalSourceToken(appointments) {
      //const savedToken = this.$localStorage.getToken();
      const auth = this.$store.getters.getAuth;
      const savedToken = auth.token;
      let source = this.$constants.SOURCE_GENERAL_KEY;
      if (this.$store.getters.getSourceDescription) {
        source = this.$store.getters.getSourceDescription;
      }

      const parsedSavedToken = jwt.parseToken(savedToken);
      const permalink = parsedSavedToken.business.permalink;

      const self = this;
      this.$graphql.getAccessToken(
        source,
        savedToken !== '' ? savedToken : null,
        permalink,
        function (obj) {
          const token = obj.token;
          if (token) {
            const auth = jwt.parseToken(token);
            self.$store.dispatch('setAuth', auth);
            self.$localStorage.setToken(token);
          }
          self.$router.push(
            {
              name: self.$constants.ROUTE_SUCCESS,
              //params: { isEditMode: self.isEditMode },
              params: {
                isEditMode: false,
                appointments,
              },
            },
            () => {},
          );
        },
        function () {
          self.$router.push(
            {
              name: self.$constants.ROUTE_SUCCESS,
              //params: { isEditMode: self.isEditMode },
              params: {
                isEditMode: false,
                appointments,
              },
            },
            () => {},
          );
        },
      );
    },

    onAppointmentRemove(appointment) {
      for (let i = 0; i < this.sectionList.length; i++) {
        const item = this.sectionList[i];
        if (appointment.service.id === item.service.id && appointment.time === item.time) {
          this.sectionList.splice(i, 1);
          break;
        }
      }

      this.$store.getters.getAppointments.removeAppointmentItem(appointment);
    },

    onSimpleAlertClosed() {
      this.simpleAlertShowing = false;
      const callback = this.simpleAlertCallback;
      if (callback) {
        this.simpleAlertCallback = null;
        callback();
      }
    },

    alert(title, message, callback) {
      this.simpleAlertTitle = title;
      this.simpleAlertMessage = message;
      this.simpleAlertCallback = callback;
      this.simpleAlertShowing = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-booking-confirmation {
  min-height: 100vh;
  background: $theme-3-bg;
  position: relative;

  @media screen and (min-width: $min-screen-width) {
    min-height: initial;
  }

  &__fixed_top {
    background: white;
  }

  &__content {
    z-index: 0;

    &--padding-bottom {
      padding-bottom: 70px; /* CHANGE LATER: because of fixed footer height */
    }

    &__section {
      margin-bottom: 10px;
      user-select: none;
    }

    &__note_button {
      font-size: 13px;
      margin: 10px 0;
    }
  }

  &__footer {
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    padding: $padding-section;
    //padding: $padding-section;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {
      max-width: 480px;
      margin: 0 auto;
    }

    &__note {
      color: black;
      font-size: 12px;
      margin: 0 0 $padding-section 0;
      cursor: pointer;

      &__icon {
        color: $theme-3-color;
        font-size: 14px;
        vertical-align: text-bottom;
        margin-left: 6px;
      }
    }
  }
}
</style>
