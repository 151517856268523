<template>
  <div class="section-resume">
    <div class="section-resume__date-container">
      <div class="section-resume__date-container__date">
        {{ dateText }}
      </div>
      <div class="section-resume__date-container__time">
        {{ timeText }}
      </div>
    </div>
    <div
      v-if="showBusinessName"
      class="section-resume__business">
      <IlmButton
        :on-click="clickBusinessName"
        :label="businessText"
        icon="keyboard_arrow_right"
        prevent-default
        theme="simple"
        class="section-resume__business__button" />
    </div>
    <div class="section-resume__service">
      {{ serviceText }}
    </div>
    <div
      v-if="discountBadge"
      class="section-resume__discount">
      <IlmDiscountBadge
        :value="discountBadge"
        class="section-resume__discount__badge" />
      {{ $t('section_list.appointment_discount') }}
    </div>
    <div class="section-resume__details-container">
      <div class="section-resume__details-container__wrapper">
        <span class="section-resume__details-container__wrapper__label">
          {{ $t('section_list.appointment_professional') }}
        </span>
        <span class="section-resume__details-container__wrapper__value">
          {{ professionalText }}
        </span>
      </div>
      <div class="section-resume__details-container__wrapper">
        <span class="section-resume__details-container__wrapper__label">
          {{ $t('section_list.appointment_service_duration') }}
        </span>
        <span class="section-resume__details-container__wrapper__value">
          {{ durationText }}
        </span>
      </div>
      <div class="section-resume__details-container__wrapper">
        <span class="section-resume__details-container__wrapper__label">
          {{ priceLabel }}
        </span>
        <span class="section-resume__details-container__wrapper__value">
          {{ priceText }}
          <span
            v-if="oldPriceText"
            class="section-resume__details-container__wrapper__value__strike">
            {{ oldPriceText }}
          </span>
        </span>
      </div>
      <div
        v-if="hasNotes"
        class="section-resume__details-container__wrapper">
        <span class="section-resume__details-container__wrapper__label">
          {{ $t('section_list.appointment_client_note') }}
        </span>
        <span
          class="section-resume__details-container__wrapper__value section-resume__details-container__wrapper__value--italic">
          {{ noteText }}
        </span>
      </div>
      <div
        v-if="hasCancelReason"
        class="section-resume__details-container__wrapper section-resume__details-container__wrapper--cancel-reason">
        <span
          class="section-resume__details-container__wrapper__label section-resume__details-container__wrapper__label--cancel-reason">
          {{ $t('section_list.appointment_cancel_reason') }}
        </span>
        <span
          class="section-resume__details-container__wrapper__value section-resume__details-container__wrapper__value--italic">
          {{ cancelReasonText }}
        </span>
      </div>
      <div
        v-if="showOptions && showCancel"
        class="section-resume__details-container__options_container">
        <IlmButton
          :on-click="clickEdit"
          :is-working="isLoading"
          t-key="button.edit_appointment"
          prevent-default
          theme="block inverted"
          class="section-resume__details-container__options_container__button section-resume__details-container__options_container__button--edit" />
        <IlmButton
          :on-click="clickCancel"
          :is-working="isLoading"
          t-key="button.cancel"
          prevent-default
          theme="destructive"
          class="section-resume__details-container__options_container__button section-resume__details-container__options_container__button--cancel" />
      </div>
      <div
        v-else-if="showOptions && !showCancel"
        class="section-resume__details-container__options_container">
        <IlmButton
          :is-working="isLoading"
          :on-click="clickRepeat"
          t-key="button.book_again"
          prevent-default
          theme="block info-inverted"
          class="section-resume__details-container__options_container__button" />
      </div>
    </div>
  </div>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmDiscountBadge from '@/components/IlmIcon/IlmDiscountBadge.vue';
import formatter from '@/utils/formatter.js';

export default {
  name: 'SectionResume',

  // Components ********************************
  components: {
    IlmButton,
    IlmDiscountBadge,
  },

  // Properties ********************************
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    discount: Object,
    business: {
      type: Object,
      required: true,
    },
    showNotes: {
      type: Boolean,
      default: true,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onBusinessClick: Function,
    onCancelClick: Function,
    onRepeatClick: Function,
    onEditClick: Function,
  },

  // Data ********************************
  data() {
    return {};
  },

  // Computed ********************************
  computed: {
    timeText() {
      if (this.appointment) {
        return this.appointment.time;
      }
      return '';
    },
    dateText() {
      if (this.appointment) {
        const day = this.appointment.day;
        const date = this.$moment(day);

        const dayStr = `${date.format('ddd')} ${date.format('D')}`;
        return this.$t('dates.normal_format', {
          day: dayStr,
          month: date.format('MMMM'),
        });
      }
      return '';
    },
    showBusinessName() {
      //return this.$store.getters.isExternalSource;
      return false;
    },
    businessText() {
      if (this.business && this.business.name) {
        return this.business.name;
      }
      return '';
    },
    serviceText() {
      if (this.appointment) {
        return this.appointment.service.name;
      }
      return '';
    },
    discountBadge() {
      const discountValue = this.getDiscountValue();
      return discountValue && !this.isPaid
        ? this.$t('button.discount_label', { value: discountValue })
        : null;
    },
    professionalText() {
      if (this.appointment) {
        if (this.appointment.professional) {
          return this.appointment.professional.name;
        }
        return this.$t('section_list.appointment_professional_summary_any_abbr');
      }
      /*if (this.appointment) {
        return this.appointment.professional.name;
      }*/
      return '';
    },
    durationText() {
      if (this.appointment) {
        const duration = this.appointment.service.duration;
        return formatter.formatDuration(duration);
      }
      return '';
    },
    priceLabel() {
      if (this.appointment) {
        return this.isPaid
          ? this.$t('section_list.appointment_service_price')
          : this.$t('section_list.appointment_service_price_estimate');
      }
      return '';
    },
    isPaid() {
      return ['paid', 'pending'].includes(this.appointment.state);
    },
    priceText() {
      if (this.appointment.service.isPriceUnavailable === true) {
        return this.$t('section_list.service_price_under_evaluation');
      }
      const discountValue = this.getDiscountValue();
      const priceValue = this.getPriceValue();
      if (priceValue) {
        if (discountValue && !this.isPaid && priceValue > 0) {
          return formatter.formatPrice(
            priceValue * (100 - discountValue) * 0.01,
            this.businessLocale,
          );
        }
        if (priceValue > 0 || this.isPaid) {
          return formatter.formatPrice(priceValue, this.businessLocale);
        }
      }
      return '';
    },
    oldPriceText() {
      const discountValue = this.getDiscountValue();
      const priceValue = this.getPriceValue();
      if (discountValue && priceValue && !this.isPaid) {
        return formatter.formatPrice(priceValue, this.businessLocale);
      }
      return null;
    },
    hasNotes() {
      if (this.appointment) {
        return (
          this.showNotes === true && this.appointment.notes && this.appointment.notes.trim() !== ''
        );
      }
      return false;
    },
    noteText() {
      return this.appointment.notes;
    },
    hasCancelReason() {
      return (
        this.appointment
        && this.appointment.state === 'canceled'
        && this.appointment.cancelReason
        && this.appointment.cancelReason !== 'other'
      );
    },
    cancelReasonText() {
      if (this.appointment.cancelReason) {
        return this.$t(`cancel.${this.appointment.cancelReason}`);
      }
      return '';
    },
    businessLocale() {
      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }
      return locale;
    },
  },

  // Created ********************************
  created() {},

  // Created ********************************
  mounted() {},

  methods: {
    getPriceValue() {
      if (this.appointment) {
        let price = this.appointment.service.value;
        if (price) {
          return price;
        }
        price = this.appointment.service.price;
        if (price) {
          return price;
        }
      }
      return null;
    },
    getDiscountValue() {
      if (this.appointment) {
        const day = this.appointment.day;
        const date = this.$moment(day);
        if (this.discount && date) {
          const weekday = date.isoWeekday();
          const value = this.discount.getHourDiscount(weekday, this.appointment.time);
          return value;
        }
      }
      return null;
    },

    clickBusinessName() {
      if (this.onBusinessClick) {
        this.onBusinessClick();
      }
    },

    clickCancel() {
      if (this.onCancelClick) {
        this.onCancelClick();
      }
    },

    clickRepeat() {
      if (this.onRepeatClick) {
        this.onRepeatClick();
      }
    },

    clickEdit() {
      if (this.onEditClick) {
        this.onEditClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-resume {
  background: white;

  &__date-container {
    display: flex;
    padding: 14px 0;
    margin: 0 $padding-section;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    font-family: $heading-font-family;
    font-size: 28px;
    font-weight: bold;
    color: black;
    line-height: 1.2em;
    border-bottom: 1px solid $theme-list-divider-color;

    &__date {
      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
    }

    &__time {
      display: inline-flex;
      flex: 0 0 auto;
    }
  }

  &__business {
    font-family: $heading-font-family;
    font-size: 20px;
    font-weight: bold;
    //color: black;
    line-height: 1.2em;
    //padding: 14px $padding-section 0 $padding-section;

    &__button {
      padding-left: $padding-section;
      padding-right: 40px;
    }
  }

  &__service {
    font-family: $heading-font-family;
    font-size: 28px;
    color: black;
    //color: $theme-button-simple-color;
    line-height: 1.2em;
    padding: $padding-section $padding-section 6px $padding-section;
    /*cursor: pointer;

    @media (hover) {
      &:hover {
        color: darken($theme-button-simple-color, 20%);
      }
    }*/
  }

  &__discount {
    font-family: $heading-font-family;
    font-size: 16px;
    color: black;
    line-height: 1.2em;
    padding: 0 $padding-section;

    &__badge {
      margin-right: 6px;
    }
  }

  &__details-container {
    padding: $padding-section $padding-section $padding-section;
    border-bottom: 1px solid $theme-list-divider-color;
    font-family: $heading-font-family;
    font-size: 16px;
    line-height: 1.2em;

    &__wrapper {
      overflow: hidden;
      width: 100%;
      padding: 2px 0;

      &__label {
        color: $theme-2-color;
        float: left;
        width: 136px;

        &--cancel-reason {
          color: $theme-error;
        }
      }

      &--cancel-reason {
        margin-top: 16px;
      }

      &__value {
        color: black;
        display: block;
        overflow: hidden;

        &__strike {
          text-decoration: line-through;
          color: $theme-3-color;
          margin-left: 6px;
        }

        &--italic {
          font-style: italic;
        }
      }
    }

    &__options_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      border-top: 1px solid $theme-list-divider-color;
      padding-top: $padding-section;
      margin-top: 20px;

      &__button {
        &--edit {
          flex: 1 1 auto;
          min-width: 0;
          max-width: 100%;
          margin-right: 8px;
        }
        &--cancel {
          flex: 0 0 auto;
          margin-left: 8px;
          padding-left: 24px;
          padding-right: 24px;
          //width: 100px;
        }
      }
    }
  }
}
</style>
