<template>
  <transition name="fade">
    <img
      v-show="loaded"
      :src="src"
      class="icon"
      @load="onLoaded">
  </transition>
</template>

<script>
export default {
  name: 'ImageFade',
  components: {
  },
  props: {
    src: String,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
