<template>
  <BaseModal
    ref="basemodal"
    :title="title"
    class="modal-country-code"
    @closed="onClosed">
    <div
      ref="modal_content"
      class="modal-country-code__content">
      <div
        class="modal-country-code__content__header-container">

        <SearchBox
          ref="search_input"
          :focus-on-enter="false"
          :placeholder-key="searchPlaceholderKey"
          name="search"
          type="text"
          icon="search"
          @change="searchTextChange" />

        <Header
          ref="header"
          :label="headerTitle"
          class="modal-country-code__content__header" />
      </div>

      <ItemList
        :items="items"
        :selected-item="selectedCountry"
        :search-text="searchText"
        :on-item-selected="onItemSelected" />
    </div>
  </BaseModal>
</template>


<script>
import BaseModal from '@/components/Modals/BaseModal.vue';
import SearchBox from '@/components/Inputs/SearchBox.vue';
import Header from '@/components/Lists/Header.vue';
import ItemList from '@/components/Lists/ItemList.vue';
import utils from '@/utils/utils';


export default {
  name: 'ModalCountryCode',

  // Components ********************************
  components: {
    BaseModal,
    SearchBox,
    Header,
    ItemList,
  },

  // Properties ********************************
  props: {
    countries: Array,
    selectedCountry: Object,
    showCountryCode: {
      type: Boolean,
      default: false,
    },
  },

  // Data ********************************
  data() {
    return {
      searchText: null,
      items: null,
    };
  },

  // Computed ********************************
  computed: {
    title() {
      if (this.showCountryCode) {
        return this.$t('modals.titles.choose_country_code');
      }
      return this.$t('modals.titles.choose_country');
    },
    headerTitle() {
      if (this.showCountryCode) {
        return this.$t('headers.choose_country_code');
      }
      return this.$t('headers.choose_country');
    },
    searchPlaceholderKey() {
      if (this.showCountryCode) {
        return 'placeholders.search_box_country_code';
      }
      return 'placeholders.search_box_country';
    },
  },

  // Created ********************************
  mounted() {
    if (this.showCountryCode === true) {
      this.items = this.countries.map(function (item) {
        return {
          id: item.iso,
          name: `${item.name} +${item.code}`,
        };
      });
    } else {
      this.items = this.countries.map(function (item) {
        return {
          id: item.iso,
          name: item.name,
        };
      });
    }

    const self = this;
    this.$nextTick(function () {
      setTimeout(function () { // timeout because of animation
        if (self.$refs.modal_content) {
          self.$refs.modal_content.addEventListener('scroll', self.handleScroll);
        }
        if (utils.isMobile() === false && self.$refs.search_input) {
          self.$refs.search_input.clear();
        }
      }, 500);
    });
  },

  // Methods ********************************
  methods: {
    searchTextChange(val) {
      this.searchText = val;
      /*if (val && val.length > 0) {
      } else {
      }*/
    },
    onItemSelected(item) {
      this.$refs.basemodal.close();
      const self = this;
      setTimeout(function () { // timeout because of animation
        self.$emit('selected', item.id);
      }, 300);
      //this.$emit('selected', item.id);
    },
    close() {
      this.$refs.basemodal.handleCloseClick();
    },
    onClosed() {
      this.$emit('closed', 'close');
    },

    // Scroll EVENT

    handleScroll() {
      this.$refs.search_input.removeFocus();
    },
  },
};
</script>


<style lang="scss" scoped>

.modal-country-code {

  &__content {
    position: relative;
    //padding: $padding-section;
    //overflow: auto;
    height: 100%;
    width: 100%;
    //max-height: 460px;
    text-align: left;
    -webkit-overflow-scrolling: touch;

    &__header {
      margin: $padding-section+10px $padding-section 0 $padding-section;
    }

    &__list {
      margin: 0;
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__content {
      //padding: $padding-section;
      max-height: 460px;
      height: initial;
      width: initial;
    }
  }
}
</style>
