<template>
  <transition name="fade">
    <div class="alert">
      <div
        class="alert__frame"
        @click="handleCloseClick" />
      <transition name="fade">
        <div
          v-if="contentShowing"
          class="alert__content">
          <div class="alert__content__slot">
            <h1
              v-if="title"
              class="alert__content__title">
              {{ title }}
            </h1>
            <p
              v-if="message"
              class="alert__content__message">
              {{ message }}
            </p>
            <div class="alert__content__options">
              <IlmButton
                v-if="btnCancel"
                ref="btn_cancel"
                :on-click="handleCancelClick"
                :label="btnCancel"
                theme="simple"
                class="alert__content__options__btn_ok" />
              <IlmButton
                v-if="btnDestructive"
                ref="btn_ok"
                :on-click="handleDestructiveClick"
                :label="btnDestructive"
                theme="destructive"
                class="alert__content__options__btn_ok" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';

const noScrollClass = 'no-scroll';


export default {
  name: 'AlertDestructive',

  // Components ********************************
  components: {
    IlmButton,
  },

  // Properties ********************************
  props: {
    title: String,
    message: String,
    btnCancel: String,
    btnDestructive: String,
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
    };
  },

  // Computed ********************************
  computed: {
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    this.contentShowing = true;
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    handleCancelClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('cancel', 'close');
      }, 200);
    },
    handleDestructiveClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('ok', 'close');
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: 98%;
    max-width: 380px;
    overflow: hidden;
    text-align: right;

    &__slot {
      position: relative;
      display:block;
      background: white;
      margin: 0 $padding-section*2;
      border-radius: $theme-border-radius;
      overflow: hidden;
      padding: 26px $padding-section 20px $padding-section;
      padding-bottom: 10px;
    }

    &__title {
      position: relative;
      color: black;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-lg;
      font-family: $base-font-family;
      margin: 0;
      margin-bottom: 10px;
    }
    &__message {
      position: relative;
      color: black;
      text-align: center;
      font-size: $font-size-md;
      font-family: $base-font-family;
      margin: 0;
      margin-bottom: 10px;
    }

    &__options {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      &__btn_ok {
        /*display: inline;*/
        margin: 0;
        font-size: $font-size-lg;
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
