//import mixpanel from 'mixpanel-browser';
import Vue from 'vue';
import VueGtag, { bootstrap, setOptions } from 'vue-gtag';

const GOOGLE_CONVERSION_PIXELS = {
  1308: {
    id: 'AW-681505556',
    events: { booked: 'AW-681505556/8fHnCNPUgO4BEJTm-8QC' },
  },
};

let isActive = false;

export default {

  gtag: null,
  optedIn: false,
  businessId: null,
  trackingId: null,


  init(router) {
    Vue.use(VueGtag, {
      bootstrap: false,
    }, router);

    return this;
  },

  optOut() {
    this.optedIn = false;
    this.checkInit();

    if (this.isActive) {
      if (this.optedIn) {
        Vue.$gtag.optIn();
      } else {
        Vue.$gtag.optOut();
      }
    }
  },

  optIn() {
    this.optedIn = true;
    this.checkInit();

    if (this.isActive) {
      if (this.optedIn) {
        Vue.$gtag.optIn();
      } else {
        Vue.$gtag.optOut();
      }
    }
  },

  checkInit() {
    if (this.businessId) {
      const obj = GOOGLE_CONVERSION_PIXELS[this.businessId];
      if (this.trackingId) {
        setOptions({
          config: { id: this.trackingId },
          enable: this.optedIn,
        });
      } else if (obj && process.env.VUE_APP_DEPLOY_TARGET === 'production') {
        setOptions({
          config: {
            id: process.env.VUE_APP_ANALYTICS_TRACKING_ID,
          },
          includes: [
            {
              id: obj.id,
              params: {
                send_page_view: false,
              },
            },
          ],
          enable: this.optedIn,
        });
      } else {
        setOptions({
          config: { id: process.env.VUE_APP_ANALYTICS_TRACKING_ID },
          enable: this.optedIn,
        });
      }

      const self = this;
      return new Promise(function (resolve) {
        try {
          bootstrap(Vue.$gtag).then(() => {
            //self.gtag = gtag;
            self.gtag = Vue.$gtag;
            isActive = true;

            if (self.optedIn) {
              Vue.$gtag.optIn();
            } else {
              Vue.$gtag.optOut();
            }

            resolve();
          });
        } catch (e) {
          resolve();
        }
      });
    }
    return false;
  },

  setTrackingId(id) {
    this.trackingId = id;
  },

  setBusiness(businessId) {
    this.businessId = businessId;
    this.checkInit();
    /*
    const obj = GOOGLE_CONVERSION_PIXELS[businessId];
    if (obj && process.env.VUE_APP_DEPLOY_TARGET === 'production') {
      setOptions({
        config: {
          id: process.env.VUE_APP_ANALYTICS_TRACKING_ID,
        },
        includes: [
          {
            id: obj.id,
            params: {
              send_page_view: false,
            },
          },
        ],
        enable: this.optedIn,
      });
    } else {
      setOptions({
        config: { id: process.env.VUE_APP_ANALYTICS_TRACKING_ID },
        enable: this.optedIn,
      });
    }

    const self = this;
    return new Promise(function (resolve) {
      try {
        bootstrap(Vue.$gtag).then(() => {
          //self.gtag = gtag;
          self.gtag = Vue.$gtag;
          isActive = true;

          resolve();
        });
      } catch (e) {
        resolve();
      }
    });*/
  },

  /**
   * Identify a user with a unique ID instead of a Mixpanel randomly generated distinct_id.
   * If the method is never called, then unique visitors will be identified by a UUID generated
   * the first time they visit the site.
   * @param {*} uniqueId
   */
  identify(userKey) {
    if (isActive === true) {
      this.gtag.config({
        user_id: userKey,
      });
    }
  },


  /**
   * Track an event. This is the most important and frequently used Mixpanel function.
   * @param {*} eventName
   * @param {*} properties
   */
  track(eventName, properties) {
    if (isActive === true) {
      if (!properties) {
        properties = {};
      }
      if (this.businessId) {
        properties.business_id = this.businessId;
      }

      properties.send_to = process.env.VUE_APP_ANALYTICS_TRACKING_ID;
      this.gtag.event(eventName, properties);

      this.gtag.pageview(eventName);
    }
  },


  trackNewBookingEvent(businessId) {
    if (process.env.VUE_APP_DEPLOY_TARGET !== 'production') {
      return;
    }
    if (isActive === true) {
      const obj = GOOGLE_CONVERSION_PIXELS[businessId];
      if (obj && obj.events.booked) {
        const properties = {
          send_to: obj.events.booked, //'AW-681505556/8fHnCNPUgO4BEJTm-8QC',
          business_id: businessId,
        };

        this.gtag.event('conversion', properties);

        this.gtag.event('Create', {
          event_category: 'Booking',
          event_label: 'New booking',
          value: '',
        });
      }
    }
  },

};
