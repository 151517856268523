<template>
  <div class="error">
    <div class="error__container">
      <Navbar
        :subtitle="$t('errors.unable_to_create_client_title')"
        :on-back="onNavBack"
        class="error__container__navbar" />

      <p
        class="error__container__message"
        v-html="errorMessage" />

      <div class="error__container__button_container">
        <IlmButton
          v-if="showCallButton"
          :on-click="clickCall"
          :label="callButtonTitle"
          icon="phone"
          icon-position="left"
          prevent-default
          theme="block info"
          class="error__container__button_container__call_button" />
        <div
          v-else
          class="error__container__button_container__call_container">
          <p
            class="error__container__button_container__call_container__note">
            {{ $t('notes.phone_business_call') }}
          </p>
          <p
            class="error__container__button_container__call_container__phone"
            v-html="businessPhoneText" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import Navbar from '@/components/Nav/Navbar.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import utils from '@/utils/utils';

export default {

  components: {
    Navbar,
    IlmButton,
  },


  // BeforRouteLeave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton && to.name !== this.$constants.ROUTE_LOGIN) {
      next(false);
      this.onNavBack();
    } else {
      next();
    }
  },

  // Properties ********************************
  props: {
  },

  // Data ********************************
  data() {
    return {
    };
  },

  // Computed ********************************
  computed: {
    errorMessage() {
      return this.$t('errors.unable_to_create_client_message');
    },
    showCallButton() {
      return utils.isMobile();
    },
    callButtonTitle() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        const business = auth.business;
        if (business && business.name) {
          return this.$t('button.contact_business', { name: business.name });
        }
      }
      return this.$t('button.contact_business_unknown');
    },
    businessPhoneText() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        const business = auth.business;
        if (business && business.phoneCountry && business.phoneNumber) {
          const formatter = new AsYouType();
          const fullPhone = formatter.input(`+${business.phoneCountry}${business.phoneNumber}`);
          return fullPhone;
        }
      }
      return this.$t('notes.phone_business_call_unavailable');
    },
  },

  // Mounted ********************************
  mounted() {
    this.$analytics.track('Visit: Error restricted client');
  },

  // Methods ********************************
  methods: {
    onNavBack() {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
    },
    clickCall() {
      this.$analytics.track('Action: Click call business');
      const auth = this.$store.getters.getAuth;
      const business = auth.business;
      if (auth && business.phoneCountry && business.phoneNumber) {
        const phoneLink = `tel:+${business.phoneCountry}${business.phoneNumber}`;
        window.open(phoneLink);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.error {
  height: 100%;
  min-height: 100vh;
  background: $theme-3-bg;
  @media screen and (min-width: $min-screen-width) {
    height: initial;
    min-height: initial;
  }

  &__container {

    &__message {
      font-size: 16px;
      color: black;
      text-align: center;
      line-height: 1.2;
      padding: $padding-section;
    }

    &__button_container {
      padding: 0 $padding-section;

      &__call_button {
        margin-top: 20px;
        max-width: 480px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__call_container {
        margin-top: 20px;
        font-family: $heading-font-family;
        font-size: 22px;
        color: $theme-2-color;

        border: 1px solid $theme-2-color;
        border-radius: 8px;
        display: flex;
        padding: 12px;

        &__note {
          flex: 0 0 auto;
          margin :0;
        }
        &__phone {
          text-align: right;
          margin :0;

          flex: 1 1 auto;
          min-width: 0;
          max-width: 100%;
        }
      }
    }
  }
}

</style>
