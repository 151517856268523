
<template>
  <div
    :class="slotClassNames"
    data-test-id="slot-button"
    :data-test-time="itemSlot.time"
    :data-is-available="itemSlot.available === true ? 'true' : 'false'"
    @click="itemSelected">
    <span
      :class="`${mainClass}__title`">
      {{ itemSlot.time }}
    </span>
    <span
      v-if="hasDiscount"
      :class="discountClassNames">
      {{ discountValue }}
    </span>
  </div>
</template>


<script>

export default {
  name: 'MultipleServiceGridSlotsButton',

  // Components ********************************
  components: {

  },

  // Properties ********************************
  props: {
    itemSlot: Object,
    index: Number,
    servicesSize: Number,
    discount: Number,
    maxDiscount: Number,
    onSlotClicked: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'slot-button';
    },
    slotClassNames() {
      const classNames = {
        'slot-button': true,
        'slot-button--locked': this.itemSlot.locked,
        'slot-button--disabled': this.itemSlot.available === false,
        'slot-button--selected': this.selected,
        'slot-button--first': this.itemSlot.first,
        'slot-button--last': this.itemSlot.last,
        'slot-button--first-hour': this.itemSlot.firstHour,
        'slot-button--first-section': this.itemSlot.firstSection,
        'slot-button--last-section': this.itemSlot.lastSection,
        'slot-button--ghost-discount': this.itemSlot.hasSomeDiscount && this.servicesSize >= 3,
      };
      return classNames;
    },
    discountClassNames() {
      const classNames = {
        'slot-button__discount': true,
        'slot-button__discount--thin-column': this.servicesSize >= 3,
        'slot-button__discount--max': this.hasDiscount && this.discount === this.maxDiscount,
      };
      return classNames;
    },
    hasDiscount() {
      if (this.discount && this.discount !== null) {
        return true;
      }
      return false;
    },
    discountValue() {
      if (this.discount) {
        return `-${this.discount}%`;
      }
      return null;
    },
  },

  // Watchers ********************************
  watch: {
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  destroyed() {
  },

  methods: {
    itemSelected() {
      if (this.onSlotClicked) {
        this.onSlotClicked(this.itemSlot);
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.slot-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 18px 0 !important;
  width: 100% !important;
  border-radius: 0;
  background: $theme-ilm-slot-background;
  color: black;
  cursor: pointer;
  box-sizing: border-box;
  height: 46px;
  //height: 46px;
  //border-color: $theme-ilm-slot-background-selected;
  border: 3px solid $theme-ilm-slot-background;
  //background: $theme-ilm-slot-background-selected;
  //color: white;
  margin-bottom: 2px;
  padding: 10px 0;
  user-select: none;
  //transition: $hover-transition, border-width 0s;

  &--ghost-discount {
    height: 60px;
  }

  &__title {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 1.2;
  }

  &__discount {
    font-size: 12px !important;
    font-weight: bold;
    //margin-top: 2px;
    line-height: 1.2;
    background: $discount-default-background-color;
    border-radius: 4px;
    padding: 2px 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    right: 2px;
    box-sizing: border-box;
    user-select: none;

    &--max {
      background: $discount-max-background-color;
      //color: white;
    }

    &--thin-column {
      position: relative;
      right: 0;
      top: 0;
      transform: none;
      width: 86%;
      margin-top: 4px;
    }
  }


  &--has-discount {
    //background: $discount-background-color;
  }

  @media (hover) {
    &:hover {
      //background: $theme-ilm-selection-dimmed;
      //background: $theme-ilm-slot-background-selected;
      border: 3px solid $theme-ilm-slot-background-selected;
      //opacity: 0.5;
      //color: white;
    }
    &--has-discount:hover {
      //background: $discount-background-color-dimmed;
      //border: 3px solid $discount-background-color-border;
    }
  }

  &--locked {
    //text-decoration: line-through;
    cursor: not-allowed;
    box-shadow: none;
    background: $theme-ilm-slot-background-locked;
    border-color: $theme-ilm-slot-background-locked;
    user-select: none;
    color: $theme-ilm-slot-color-locked;
  }

  &--disabled {
    color: #D2D2D2 !important;
    text-decoration: line-through;
    box-shadow: none;
    background: #eee;
    border-color: #eee;
    cursor: not-allowed;
    user-select: none;
    //background: #ccc;
  }

  &--locked &__discount {
    background: rgb(179, 158, 158);
    border-color: rgb(179, 158, 158);
    color: #fff !important;
  }

  &--disabled &__discount {
    background: rgb(206, 206, 206);
    border-color: rgb(206, 206, 206);
    color: #fff !important;
  }

  @media (hover) {
    &--locked:hover {
      color: $theme-ilm-slot-color-locked;
      background: $theme-ilm-slot-background-locked;
      border-color: transparent;
    }
    &--disabled:hover {
      color: #D2D2D2 !important;
      background: #eee;
      border-color: #eee;
    }
  }

  &--first, &--first-section {
    border-top-left-radius: $theme-border-radius;
    border-top-right-radius: $theme-border-radius;
  }

  &--last, &--last-section {
    border-bottom-left-radius: $theme-border-radius;
    border-bottom-right-radius: $theme-border-radius;
  }

  &--first-hour {
    margin-top: 8px;
  }

  &--first-section {
    margin-top: 20px;
  }

}

</style>
