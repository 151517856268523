<template>
  <div
    v-if="isInitialized"
    class="search-service">
    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="!selectedItem"
        key="autocomplete">
        <NavigationSearchBar
          v-if="showSearchBar"
          ref="search_input_container"
          :focus-on-enter="focusTextBox"
          :title="businessName"
          :alternative-title="$t('title.search_services')"
          :is-loading="isLoading"
          :on-filter-click="onDiscountsClick"
          :on-resize="onSearchBoxResize"
          :on-change="searchTextChange"
          :on-back="onNavBack"
          :show-discount-option="hasDiscounts"
          :discount-option-active="selectedDiscountsArray
            && selectedDiscountsArray.length > 0 && !searchText"
          show-back />

        <div
          id="search-service-scroll"
          v-touch:moved="handleTouch"
          class="search-service__list-container">

          <div
            v-if="isShowingAllServices">
            <div
              v-if="selectedDiscountsArray && selectedDiscountsArray.length > 0
                && (selectedDiscounts.length < discounts.length || discounts.length === 1)"
              class="search-service__list-container__filters_container">
              <IlmButton
                v-for="(discount, index) in selectedDiscountsArray"
                :key="index"
                :label="discount.title"
                :on-click="onDiscountRemoveClick"
                :index="index"
                icon="cancel"
                icon-size="lg"
                class="search-service__list-container__filters_container__tag" />
            </div>
            <div
              v-else-if="selectedDiscountsArray && selectedDiscountsArray.length > 0
                && selectedDiscountsArray.length === discounts.length"
              class="search-service__list-container__filters_container">
              <IlmButton
                :label="$t('button.discount_card_choose_all')"
                :on-click="onAllDiscountRemoveClick"
                icon="cancel"
                icon-size="lg"
                class="search-service__list-container__filters_container__tag" />
            </div>
            <div
              v-if="showClientSuggestions
                && clientSuggestions
                && clientSuggestions.length > 0 && (!searchText || searchText.length === 0)"
              class="search-service__client-suggestions">
              <Sticky :distance="searchBarHeight">
                <div
                  class="services-header-container">
                  <ListHeader
                    ref="header"
                    :t-key="clientHeaderTKey"
                    icon="favorite"
                    icon-color="#F10000" />
                </div>
              </Sticky>
              <AutocompleteList
                ref="list_client_suggestions"
                :on-item-selected="itemSelected"
                :on-service-selected="serviceSelected"
                :items="clientSuggestionsFiltered"
                :show-category="false"
                :is-loading="isLoading"
                data-type="client" />

              <div
                class="search-service__list-container__divider" />
            </div>

            <div
              v-if="!isLoading && services.length === 0"
              class="services-header-container services-header-container--first">
              <ListHeader
                ref="header"
                :label="$t('text.service_search_empty_results')" />
            </div>
            <div
              v-for="(item, index) in services"
              v-else
              :key="item.category">
              <Sticky
                :distance="searchBarHeight">
                <div
                  :class="index === 0 ? 'services-header-container--first' : ''"
                  class="services-header-container">
                  <ListHeader
                    ref="header"
                    :label="$t(`service_categories.${item.category}`)" />
                </div>
              </Sticky>
              <ServicesList
                :items="item.services"
                :on-item-selected="serviceSelected"
                :show-category="false"
                :search-text="searchText"
                class="search-service__list-container__section" />
            </div>

            <div
              v-if="selectedDiscountsArray
                && selectedDiscountsArray.length > 0 && services.length > 0"
              class="search-service__list-container__clear_filters_container">
              <IlmButton
                :on-click="onFiltersClearClick"
                label="Limpar filtros"
                icon="close"
                icon-size="md"
                class="search-service__list-container__clear_filters_container__button" />
            </div>
          </div>

          <div
            v-else-if="isShowingSuggestions">

            <div
              v-if="showVoucher"
              class="search-service__list-container__section">
              <SectionVoucher
                :on-selected="onVoucherSelected"
                class="search-service__list-container__section__voucher" />
            </div>

            <div
              v-if="clientSuggestions && clientSuggestions.length > 0">
              <Sticky>
                <div
                  class="search-service__header-container">
                  <ListHeader
                    ref="header"
                    :t-key="clientHeaderTKey"
                    icon="favorite"
                    icon-color="#F10000" />
                </div>
              </Sticky>
              <AutocompleteList
                ref="list_client_suggestions"
                :on-item-selected="itemSelected"
                :on-service-selected="serviceSelected"
                :items="clientSuggestionsFiltered"
                :show-category="false"
                :is-loading="isLoading"
                data-type="client" />

              <div
                class="search-service__list-container__divider" />
            </div>

            <div
              v-if="suggestions.length > 0">
              <Sticky>
                <div
                  class="search-service__header-container">
                  <ListHeader
                    ref="header"
                    t-key="headers.search_service_list"
                    icon="star"
                    icon-size="lg"
                    icon-color="#FFC000" />
                </div>
              </Sticky>
              <AutocompleteList
                ref="list_suggestions"
                :on-item-selected="itemSelected"
                :on-service-selected="serviceSelected"
                :items="businessSuggestionsFiltered"
                :show-category="false"
                :is-loading="isLoading"
                data-type="business" />
            </div>
          </div>

          <div v-else>
            <AutocompleteList
              ref="list_results"
              :on-item-selected="itemSelected"
              :on-service-selected="serviceSelected"
              :items="results.items"
              :search-text="searchText"
              :is-loading="isLoading"
              :highlight-results="true" />

            <div
              v-if="results.categories && results.categories.length > 0"
              class="category-header-container">
              <ListHeader
                ref="header"
                t-key="headers.search_service_list_categories" />
            </div>

            <AutocompleteList
              ref="list_categories"
              :on-item-selected="itemSelected"
              :items="results.categories"
              :is-loading="isLoading"
              :search-text="searchText" />
          </div>

          <div
            v-if="!searchText && !showAllServices"
            class="search-service__see-more-container">
            <IlmButtonBadge
              :on-click="showAllClicked"
              t-key="button.show_all_services"
              prevent-default
              theme="inverted"
              class="search-service__see-more-container__button" />
          </div>
        </div>
      </div>

      <div
        v-else
        key="selected">
        <NavigationBar
          :title="selectedItem ? selectedItem.name : ''"
          :subtitle="businessName"
          :is-extendable="true"
          :alternative-subtitle="$t('title.search_services_category')"
          show-back />

        <div
          class="search-service__header-container">
          <ListHeaderExtended
            :subtitle="resultsCounter"
            t-key="headers.search_service_list_choose_service" />
        </div>

        <ServicesList
          id="search-service-services-scroll"
          ref="list_services"
          :items="results.items"
          :on-item-selected="serviceSelected" />

      </div>
    </transition>

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertClosed"
      @ok="onAlertClosed" />
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import NavigationBar from '@/components/Nav/NavigationBar.vue';
import NavigationSearchBar from '@/components/Nav/NavigationSearchBar.vue';
import ListHeader from '@/components/Lists/Header.vue';
import ListHeaderExtended from '@/components/Lists/HeaderExtended.vue';
import AutocompleteList from '@/components/Lists/AutocompleteList.vue';
import ServicesList from '@/components/Lists/ServicesList.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonBadge from '@/components/Buttons/IlmButtonBadge.vue';
import SectionVoucher from '@/components/Sections/SectionVoucher.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import matcher from '@/utils/matcher.js';
import Error from '@/graphql/error';
import utils from '@/utils/utils';
//import { setTimeout } from 'timers';

//import gql from 'graphql-tag';
//import graphql from '../graphql/queries.js';


export default {
  name: 'SearchService',


  // Components ********************************
  components: {
    NavigationBar,
    NavigationSearchBar,
    ListHeader,
    ListHeaderExtended,
    AutocompleteList,
    ServicesList,
    IlmButton,
    IlmButtonBadge,
    SectionVoucher,
    Alert,
  },

  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      if (this.$store.getters.isExternalSource) {
        if (this.selectedItem) {
          next(false);
          this.resetSearch();
        } else {
          next();
        }
        return;
      }
      next(false);
      if (this.selectedItem) {
        this.saveSelectedCategory(null);
        this.selectedItem = null;
        this.setSavedText();
      } else if (this.showAllServices) {
        if (this.showBusinessServices === false) {
          this.isShowingSuggestions = true;
          this.showAllServices = false;
          this.resetSearch();
          this.setSearchSavedText(null);
        }
      } else {
        const fbMessenger = this.$store.getters.getMessenger;
        if (fbMessenger.isMessenger && fbMessenger.inFrame) {
          window.MessengerExtensions.requestCloseBrowser(function success() {
            // webview closed
          }, function error() {
            // an error occurred
          });
        }
      }
      return;
    }
    next();
  },

  // Properties ********************************
  props: {
    initScreen: {
      type: Boolean,
      default: false,
    },
    showBusinessServices: {
      type: Boolean,
      default: false,
    },
    showClientSuggestions: {
      type: Boolean,
      default: false,
    },
    selectedDiscounts: Array,
  },

  // Data ********************************
  data() {
    return {
      isInitialized: false,
      searchBarHeight: 0,
      clientHeaderTKey: 'headers.search_service_client_list',
      headerTKey: 'headers.search_service_list',
      defaultSearch: null,
      searchText: null,
      showAllServices: false,

      showBusinessSuggestions: false,
      isShowingSuggestions: false,
      clientSuggestions: [],
      suggestions: [],
      results: {
        items: [],
        categories: [],
      },
      resultServices: null,
      discountFilterServices: null,
      selectedItem: null,
      selectedDiscountsArray: this.selectedDiscounts,

      discounts: null,
      hasDiscounts: false,
      isLoading: false,

      showVoucher: false,
      showServicesList: false,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: null,
    };
  },

  // Computed ********************************
  computed: {
    businessName() {
      const auth = this.$store.getters.getAuth;
      if (auth && auth.business.name) {
        return auth.business.name;
      }
      return '';
    },
    resultsCounter() {
      const total = this.results.items.length;
      return this.$tc('headers.search_service_list_count', total, { count: total });
    },
    showSuggestions() {
      return this.showBusinessSuggestions
        && (!this.searchText || this.searchText.length < this.$constants.MIN_SEARCH_STRING_LENGTH);
    },
    focusTextBox() {
      return utils.isMobile() === false;
    },
    isShowingAllServices() {
      return this.showAllServices
        && (this.searchText === null
        || this.searchText.length < this.$constants.MIN_SEARCH_STRING_LENGTH);
    },
    showSearchBar() {
      return !this.isShowingSuggestions;
    },
    showSearchBarShadow() {
      return !this.showSuggestions && this.searchText
        && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH;
    },
    services() {
      if (this.searchText !== null
        && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH) {
        const term = this.searchText.trim().toLowerCase();
        const arr = [];
        const size = this.resultServices.length;
        let obj;
        //let servicesArray = [];
        for (let i = 0; i < size; i++) {
          obj = this.resultServices[i];
          const servicesArray = obj.services.filter(function (item) {
            return matcher(item.name.toLowerCase(), term);
          });

          if (servicesArray.length > 0) {
            arr.push({
              category: obj.category,
              services: servicesArray,
            });
          }
        }
        return arr;
      }
      if (this.discountFilterServices) {
        return this.discountFilterServices;
      }

      return this.resultServices !== null ? this.resultServices : [];
    },
    clientSuggestionsFiltered() {
      return this.clientSuggestions.slice(0, 3);
    },
    businessSuggestionsFiltered() {
      if (this.clientSuggestionsFiltered.length > 0 && this.suggestions.length > 0) {
        const ids = this.clientSuggestionsFiltered.map(item => item.services[0].id);
        return this.suggestions.filter(function (item) {
          return ids.includes(item.services[0].id) === false;
        }).slice(0, 5);
      }
      return this.suggestions.slice(0, 5);
    },

  },

  // Watch ********************************
  watch: {
    /*searchText(val) {
      if (val && val.length > 0) {
        //this.$refs.list.setItems(this.results.items);
        //this.$refs.list.setCategories(this.results.categories);
        this.headerTKey = 'headers.search_service_list_results';
      } else {
        //this.$refs.list.setItems(null);
        //this.$refs.list.setCategories(null);
        this.headerTKey = 'headers.search_service_list';
      }
    },*/
    searchText: {
      handler: 'requestSearch',
      immediate: false, //no need to call on 'created'
    },
  },

  // Created ********************************
  created() {
    //this.$store.dispatch('resetAppointment');
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.id) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    this.$root.loading = true;
    this.isLoading = true;

    //this.selectedDiscountsArray = this.selectedDiscounts;

    this.showBusinessSuggestions = this.$store.getters.isExternalSource === false;
    this.isShowingSuggestions = this.showBusinessSuggestions && this.initScreen;

    this.showVoucher = auth.business.hasCovidActive() && auth.business.hasPaymentsActive();
    this.showServicesList = auth.business.hasBookingsActive();

    if (this.isShowingSuggestions) {
      const savedSearch = this.$store.getters.getServiceSearch;
      if (savedSearch) {
        this.isShowingSuggestions = false;
      }
    } else {
      this.clientSuggestions = this.$store.getters.getClientSuggestions;
    }

    //this.showAllServices = this.showBusinessServices;
    this.showAllServices = this.$store.getters.isExternalSource || !this.isShowingSuggestions;

    //window.addEventListener('scroll', this.handleScroll);

    this.$analytics.track('Visit: Search service');
  },

  // Mounted ********************************
  mounted() {
    if (!this.$store.getters.getAuth || !this.$store.getters.getAuth.business.id) {
      return;
    }
    // Get Suggestions
    const self = this;

    if (self.$store.getters.isExternalSource/*self.showBusinessServices*/) {
      if (this.$store.getters.getDiscounts) {
        this.discounts = this.$store.getters.getDiscounts.getItems();
        this.hasDiscounts = this.discounts && this.discounts.length > 0;
      }
      this.$nextTick(function () {
        self.showAllClicked();
        self.isInitialized = true;
        self.updateSearchBarHeight();
      });
    } else {
      this.getDiscounts();
    }
  },

  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
    //this.$refs.search_service_scroll.removeEventListener('touchstart', this.handleTouch);
  },

  // Methods ********************************
  methods: {
    onNavBack() {
      const appointments = this.$store.getters.getAppointments;
      if (!appointments || appointments.getAppointments().length === 0) {
        this.$router.replace({ name: this.$constants.ROUTE_BUSINESS_DETAILS }, () => {});
      } else {
        this.$router.go(-1);
      }
    },
    onSearchBoxResize() {
      if (this.$refs.search_input_container && !this.isShowingSuggestions) {
        //this.searchBarHeight = this.$refs.search_input_container.clientHeight - 14;
        this.searchBarHeight = this.$refs.search_input_container.clientHeight();
      } else {
        this.searchBarHeight = 0;
      }
    },
    updateSearchBarHeight() {
      const self = this;
      this.$nextTick(function () {
        setTimeout(function () {
          self.onSearchBoxResize();
        }, 300);
      });
    },
    searchTextChange(val) {
      //console.log(val);
      //this.searchText = val ? `Search: ${val}` : '';
      this.searchText = val;
    },
    resetSearch() {
      this.searchText = null;
      //this.$refs.search_input.clear();
      this.selectedItem = null;

      //this.$store.dispatch('setServiceSearch', null);
      this.saveSelectedCategory(null);
      this.setSavedText();
      this.updateSearchBarHeight();
    },
    itemSelected(item) {
      //const self = this;
      this.results.items = item.services;
      this.selectedItem = item;
      this.saveSearchText();
      this.saveSelectedCategory(this.selectedItem.groupKey);
      window.scrollTo(0, 0);
    },
    serviceSelected(item, dataType) {
      this.saveSearchText();
      this.$store.dispatch('setAppointmentService', item);

      if (this.searchText && this.searchText !== '') {
        this.$analytics.track('Action: Choose Service (searched)');
      } else if (dataType === 'client') {
        this.$analytics.track('Action: Choose Service (client suggestion)');
      } else {
        this.$analytics.track('Action: Choose Service (business suggestion)');
      }
      //this.$router.push({ name: this.$constants.ROUTE_NEW_APPOINTMENT }, () => {});
      if (!this.$store.getters.getAppointments) {
        this.$store.dispatch('resetAppointment');
      }
      if (this.$store.getters.getAppointments.addService(item)) {
        this.$router.replace({ name: this.$constants.ROUTE_NEW_APPOINTMENT_SERVICES });
      } else {
        this.alert(this.$t('errors.services_select_duplicated_title'), this.$t('errors.services_select_duplicated'));
      }
    },
    showAllClicked() {
      this.isShowingSuggestions = false;
      this.saveSelectedCategory(null);
      this.setSavedText();
      this.setSearchSavedText(null);
      this.$root.loading = true;
      this.isLoading = true;
      this.$analytics.track('Visit: Business services');
      this.showAllServices = true;
      window.scrollTo(0, 0);
      this.requestAllServices();
      //this.$router.push({ name: this.$constants.ROUTE_BUSINESS_SERVICES });
    },
    saveSearchText() {
      if (this.searchText && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH) {
        this.$store.dispatch('setServiceSearch', this.searchText);
      } else {
        this.$store.dispatch('setServiceSearch', null);
      }
    },
    saveSelectedCategory(categoryGroupKey) {
      this.$store.dispatch('setSelectedCategory', categoryGroupKey);
    },
    setSavedText() {
      const savedSearch = this.$store.getters.getServiceSearch;
      if (savedSearch) {
        this.setSearchSavedText(savedSearch);
      }
    },
    setSearchSavedText(term) {
      //this.searchText = savedSearch;
      //const self = this;
      this.searchTextChange(term);
      const self = this;
      setTimeout(function () {
        //const input = self.$refs.search_input;
        const container = self.$refs.search_input_container;
        if (container) {
          container.setSearchText(term);
          //input.searchText = term;
        }
      }, 300);
      this.requestSearch();
    },

    onDiscountsClick() {
      this.$router.push({
        name: this.$constants.ROUTE_DISCOUNTS_LIST,
        params: {
          isModal: true,
          selectedDiscounts: this.selectedDiscountsArray,
        },
      }, () => {});
    },

    onDiscountRemoveClick(index) {
      this.selectedDiscountsArray.splice(index, 1);
      this.filterServices();
    },
    onAllDiscountRemoveClick() {
      this.selectedDiscountsArray = [];
      this.filterServices();
    },

    onFiltersClearClick() {
      this.selectedDiscountsArray = [];
      this.discountFilterServices = null;
      window.scrollTo(0, 0);
    },


    onVoucherSelected() {
      this.$router.push({ name: this.$constants.ROUTE_VOUCHER }, () => {});
    },

    // Scroll EVENT

    handleScroll() {
      if (!utils.isMobile() && !this.selectedItem && this.$refs.search_input_container) {
        this.$refs.search_input_container.removeFocus();
      }
    },

    // Touch EVENT

    handleTouch() {
      if (!this.selectedItem && this.$refs.search_input_container) {
        this.$refs.search_input_container.removeFocus();
      }
    },

    // Requests

    getDiscounts() {
      const self = this;
      this.$graphql.businessDiscountsQuery(function (discounts) {
        self.$store.dispatch('setDiscounts', discounts);
        //self.discounts = discounts;
        if (discounts) {
          self.discounts = discounts.getItems();
          self.hasDiscounts = self.discounts && self.discounts.length > 0;
        }
        self.getSuggestions();
      }, function () {
        self.$store.dispatch('setDiscounts', null);
        self.getSuggestions();
      });
    },

    getSuggestions() {
      const self = this;
      this.$graphql.suggestionsQuery(5, 10, function (suggestions) {
        //console.log(suggestions);
        //self.suggestions = suggestions.getItems();
        //self.clientSuggestions = suggestions.getClientItems();
        const suggestionsArray = suggestions.getItems();
        const clientSuggestionsArray = suggestions.getClientItems();
        const discounts = self.$store.getters.getDiscounts;
        const discount = discounts ? discounts.getDiscount() : null;
        if (discount) {
          for (let i = 0; i < suggestionsArray.length; i++) {
            const services = suggestionsArray[i].services;
            for (let j = 0; j < services.length; j++) {
              services[j].discount = discount;
            }
            suggestionsArray[i].services = services;
          }
          for (let i = 0; i < clientSuggestionsArray.length; i++) {
            const services = clientSuggestionsArray[i].services;
            for (let j = 0; j < services.length; j++) {
              services[j].discount = discount;
            }
            clientSuggestionsArray[i].services = services;
          }
        }
        self.suggestions = suggestionsArray;
        self.clientSuggestions = clientSuggestionsArray;


        if (self.$store.getters.isExternalSource/*self.showBusinessServices*/) {
          self.showAllClicked();
          self.isInitialized = true;
        } else {
          const savedSearch = self.$store.getters.getServiceSearch;
          if (savedSearch) {
            self.setSearchSavedText(savedSearch);
          } else {
            const selectedCategoryKey = self.$store.getters.getSelectedCategory;
            if (selectedCategoryKey) {
              const items = self.suggestions.filter(i => i.groupKey === selectedCategoryKey);
              if (items.length > 0) {
                const item = items[0];
                self.results.items = item.services;
                self.selectedItem = item;
              }
            } else if (self.showAllServices) {
              self.showAllClicked();
            }
            self.isInitialized = true;
            self.$root.loading = false;
            self.isLoading = false;
          }
        }
        self.updateSearchBarHeight();
      }, function (error) {
        self.suggestions = [];
        self.clientSuggestions = [];
        //console.log(error);
        self.isInitialized = true;
        self.$root.loading = false;
        self.isLoading = false;
        self.updateSearchBarHeight();
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
          self.$router.push({ name: self.$constants.ROUTE_LOGIN }, () => {});
        } else {
          self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        }
      });
    },

    requestSearch() {
      if (this.searchText && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH) {
        this.isLoading = true;
        const self = this;
        const text = this.searchText;
        const discounts = this.$store.getters.getDiscounts;

        this.$graphql.searchQuery(text,
          function (response) {
            //console.log(response);
            const servicesList = response.getItems();
            if (discounts) {
              const discount = discounts.getDiscount();
              //if (discount) {
              for (let i = 0; i < servicesList.length; i++) {
                const services = servicesList[i].services;
                for (let j = 0; j < services.length; j++) {
                  services[j].discount = discount;
                  const serviceId = services[j].id;
                  const serviceDiscount = discounts.getServiceDiscount(serviceId);
                  if (serviceDiscount) {
                    if (!discount
                      || (serviceDiscount.getMaxDiscount(serviceId) > discount.getMaxDiscount())) {
                      services[j].discount = serviceDiscount;
                    }
                  }
                }
                servicesList[i].services = services;
              }
              //}
            }
            self.results.items = servicesList;
            if (self.results.items.length === 0) {
              self.headerTKey = null;
            } else {
              const selectedCategoryKey = self.$store.getters.getSelectedCategory;
              if (selectedCategoryKey) {
                const items = self.results.items.filter(i => i.groupKey === selectedCategoryKey);
                if (items.length > 0) {
                  const item = items[0];
                  self.results.items = item.services;
                  self.selectedItem = item;
                }
              }
            }
            self.isInitialized = true;
            self.$root.loading = false;
            self.isLoading = false;
          }, function () {
            self.results.items = [];
            //console.error(error);
            self.isInitialized = true;
            self.$root.loading = false;
            self.isLoading = false;
          });
      }
    },

    requestAllServices() {
      const self = this;

      this.$graphql.businessServicesQuery(function (services) {
        self.isLoading = false;
        self.$root.loading = false;
        const servicesList = services;
        const discounts = self.$store.getters.getDiscounts;
        if (discounts) {
          const discount = discounts.getDiscount();
          //if (discount) {
          for (let i = 0; i < servicesList.length; i++) {
            const services = servicesList[i].services;
            for (let j = 0; j < services.length; j++) {
              services[j].discount = discount;
              const serviceId = services[j].id;

              const serviceDiscount = discounts.getServiceDiscount(serviceId);
              if (serviceDiscount) {
                if (!discount
                  || (serviceDiscount.getMaxDiscount(serviceId) > discount.getMaxDiscount())) {
                  services[j].discount = serviceDiscount;
                }
              }
            }
            servicesList[i].services = services;
          }
          //}
        }
        self.resultServices = servicesList;
        self.filterServices();
        self.updateSearchBarHeight();
      }, function () {
        self.isLoading = false;
        self.$root.loading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
      });
    },

    filterServices() {
      if (this.selectedDiscountsArray && this.selectedDiscountsArray.length > 0) {
        let discountServiceIds = [];
        let discountForAll = false;
        this.selectedDiscountsArray.forEach(item => {
          const serviceIds = item.getServiceIds();
          if (serviceIds.length === 0) {
            discountForAll = true;
          } else {
            discountServiceIds = discountServiceIds.concat(serviceIds);
          }
        });

        this.discountFilterServices = [];
        let item = null;
        let filteredServices = null;
        for (let i = 0; i < this.resultServices.length; i++) {
          item = this.resultServices[i];

          if (discountForAll) {
            filteredServices = item.services;
          } else {
            filteredServices = item.services
              // eslint-disable-next-line no-loop-func
              .filter(o => discountServiceIds.indexOf(o.id) > -1);
          }

          if (filteredServices.length > 0) {
            this.discountFilterServices.push({
              category: item.category,
              services: filteredServices,
            });
          }
        }
      } else {
        this.discountFilterServices = null;
      }
    },

    // Alerts

    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertClosed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: $min-screen-width) {
  .search-service {
    &__list-container {
      border-radius: 8px;
    }
  }
}

.search-service {
  position: relative;
  background: white;
  /*padding: $padding-section 0 0 0;*/

  /*&__client-suggestions {
    //background: $theme-3-bg;
    //padding-bottom: 10px;
  }*/

  &__client-suggestions &__list-container__divider {
    border-top: 1px solid $theme-list-divider-color;
    border-bottom: none !important;
    background: $theme-3-bg;
    //padding-bottom: 10px;
  }

  &__top-search-container {
    background: white;
    position: relative;
    z-index: 2;
  }

  &__header-container {
    //position: relative;
    background: white;
    padding: $padding-section;
    /*box-shadow: $box-shadow-bottom;*/
    z-index: 1;
  }

  &__list-container {
    background: white;
    position: relative;
    z-index: 0;
    padding-top: 30px;

    &__divider {
      border-bottom: 1px solid $theme-list-divider-color;
      margin-bottom: 20px;
    }

    &__empty {
      padding: $padding-section 0;
      text-align: center;
    }

    &__section {
      padding-bottom: $padding-section;
    }

    &__filters_container {
      padding: 0 $padding-section;

      &__tag {
        padding: 6px 38px 6px 10px;
        font-size: 14px;
        font-weight: normal;
        color: black;
        border-radius: 20px;
        background: $discount-background-color;
        margin-bottom: 8px;
        margin-right: 4px;
      }
    }

    &__clear_filters_container {
      padding: 0 $padding-section;
      text-align: center;

      &__button {
        background: none;
        color: $theme-3-color;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 34px 10px 10px;
        margin-bottom: 20px;
      }
    }
  }

  &__see-more-container {
    text-align: center;
    padding: $padding-section+10px 0;
    border-top: 1px solid $theme-list-divider-color;

    &__button {
      color: $theme-link-color;
    }
    &__button:hover {
      color: $theme-link-variant;
    }
  }
}

/*.input-container {
}*/
/*
.clear-container {
  padding: $padding-section;
}
*/
.category-header-container {
  position: relative;
  background: white;
  padding: ($padding-section * 2) $padding-section 10px $padding-section;
}

.services-header-container {
  position: relative;
  background: white;
  padding: $padding-section $padding-section 14px $padding-section;

  /*&--first {
    padding-top: 10px;
  }*/
}


</style>
