const testRegex = new RegExp(/^dev\.agende\.online:8080\/(.*)(\/.*)?/, 'gi');
//const testRegex = new RegExp(/^booking\.dev\.ilove\.me:8080\/(.*)(\/.*)?/, 'gi');
// booking.dev.ilove.me:8080

//const messengerRegex =
//new RegExp(/^(staging\.)?agende\.online\/messenger_bot\/?(.*)(\/.*)?/, 'gi');
const messengerRegex = new RegExp(/^.*?(agende\.online|ilove\.me)\/messenger_bot\/?(.*)(\/.*)?/, 'gi');
const genericRegex = new RegExp(/^([\w-]*\.)?agende\.online\/(.*)(\/.*)?/, 'gi');

function isMessenger(host) {
  return host.match(messengerRegex);
}

function isTest(host) {
  return host.match(testRegex);
}

function isGeneric(host) {
  return host.match(genericRegex) && !isMessenger(host) && !isTest(host);
}

export default {
  isMessenger,
  isGeneric,
  isTest,

  getTestPermalink(host) {
    if (this.isTest(host)) {
      return testRegex.exec(host)[1];
    }
    return '';
  },


  getPermalink(host) {
    let match;

    if (this.isGeneric(host)) {
      match = genericRegex.exec(host);
    }
    if (match) {
      const matchGroup = match[2];
      /*if (matchGroup === '-staging') {
        matchGroup = match[2];
      }*/
      //return matchGroup.replace('/', '');
      if (matchGroup) {
        return matchGroup.split('/')[0];
      }
    }
    return '';
    /*
    if (this.isWhatsapp(host)) {
      match = whatsappRegex.exec(host);
    } else if (this.isGeneral(host)) {
      match = generalRegex.exec(host);
    } else if (this.isGoogle(host)) {
      match = googleRegex.exec(host);
    }
    if (match) {
      const matchGroup = match[2];
      //return matchGroup.replace('/', '');
      if (matchGroup) {
        return matchGroup.split('/')[0];
      }
    }
    return '';*/
  },

};
