<template>
  <div>
    <!--SectionHeader
      :label="header"
      class="input-header"/-->
    <div
      :class="containerClassNames">
      <div
        v-if="icon"
        class="container__icon-container">
        <IlmIcon
          :icon="icon"
          class="ontainer__icon-container__icon"
          size="xl" />
      </div>
      <div class="input-container">
        <input
          ref="inputf"
          v-model.trim="text"
          v-debounce="debounce"
          :placeholder="inputPlaceholder"
          :name="name"
          :disabled="isDisabled"
          :class="inputClassNames"
          :autofocus="focusOnStart"
          :type="inputType"
          class="input-field"
          @focus="handleFocus(true)"
          @blur="handleFocus(false)"
          @keyup.enter="handleEnter">
        <div
          v-if="showError === true"
          class="error-container">
          <IlmIcon
            class="error-container__icon"
            icon="warning"
            size="sm" />
          {{ $t('errors.invalid_phone') }}
        </div>
      </div>
      <div
        v-if="showHelper"
        class="input-clear-container"
        @click="handleHelperClick">
        <IlmIcon
          class="input-icon input-icon--help"
          icon="question-circle"
          size="xl" />
      </div>
      <div
        v-show="showClearButton"
        class="input-clear-container"
        @click="handleClear">
        <IlmIcon
          class="input-icon input-icon--clear"
          icon="cancel"
          size="xl" />
      </div>
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'SectionInput',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    debounce: {
      type: Number,
      default: 0,
    },
    header: String,
    value: {
      type: String,
      default: '',
    },
    placeholderKey: String,
    placeholder: String,
    name: String,
    icon: String,
    type: String,
    disabled: Boolean,
    focusOnStart: {
      type: Boolean,
      default: true,
    },
    showClear: {
      type: Boolean,
      default: true,
    },
    showHelper: {
      type: Boolean,
      default: false,
    },
    capsFirstLetter: {
      type: Boolean,
      default: false,
    },
    onChange: Function,
    onInputEnter: Function,
    validateField: Function,
    formatInput: Function,
    onHelperClicked: Function,
  },

  // Data ********************************
  data() {
    return {
      hasFocus: false,
      text: '',
      changeListenerEnabled: {
        type: Boolean,
        default: true,
      },
      showError: false,
      hasError: false,
    };
  },

  // Computed ********************************
  computed: {
    inputType() {
      return this.type ? this.type : 'text';
    },

    isDisabled() { return this.disabled; },

    showClearButton() { return this.showClear && !this.showHelper && !!this.text; },

    inputPlaceholder() {
      if (this.placeholderKey || this.placeholder) {
        return this.placeholderKey ? this.$t(this.placeholderKey) : this.placeholder;
      }
      //return this.countryIso === 'BR'
      //? this.$t('placeholders.phone_box_br') : this.$t('placeholders.phone_box');
      return '';
    },

    inputClassNames() {
      const classNames = {
        'input-field--disabled': this.isDisabled,
        'input-field--iconized': this.icon,
      };
      return classNames;
    },

    containerClassNames() {
      const classNames = {
        container: true,
        'container--disabled': this.isDisabled,
        'container--focused': this.hasFocus,
        'container--error': this.hasError && this.validateField,
        'container--valid': !this.hasError && this.text.length > 0 && this.validateField && !this.hasFocus,
      };
      return classNames;
    },
  },

  // Watchers ********************************
  watch: {
    text(val, oldVal) {
      if (!this.onChange) return;

      if (this.formatInput && val.length >= oldVal.length) {
        const t = this.formatInput(val);
        if (t !== this.text) {
          this.text = t;
        } else if (this.changeListenerEnabled) {
          this.onChange(val);
        }
      } else {
        if (this.capsFirstLetter) {
          this.text = val.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        }
        if (this.changeListenerEnabled) {
          this.onChange(val);
        }
      }
    },
    value(val) {
      if (this.text !== val) {
        this.text = val;
      }
    },
  },

  created() {
    if (this.value) {
      this.changeListenerEnabled = false;
      if (this.formatInput) {
        this.text = this.formatInput(this.value);
      } else {
        this.text = this.value;
      }
      const self = this;
      setTimeout(() => {
        self.changeListenerEnabled = true;
      }, 400);
    }
  },

  // Mounted ********************************
  mounted() {
    if (this.focusOnStart === true) {
      const self = this;
      this.$nextTick(function () {
        setTimeout(function () { // timeout because set focus causes funky animation transition.
          self.$refs.inputf.focus();
        }, 300);
      });
    }
  },

  // Methods ********************************
  methods: {
    handleFocus(val) {
      this.hasFocus = val;

      if (val) {
        this.hasError = false;
      } else if (this.validateField) {
        this.hasError = this.validateField() === false;
      }
    },
    setFocus() {
      this.$refs.inputf.focus();
    },
    handleClear() {
      this.text = '';
      this.$refs.inputf.focus();
      this.showError = false;
    },
    handleEnter() {
      this.$refs.inputf.blur();
      if (this.onInputEnter) {
        this.onInputEnter(this);
      }
    },
    handleHelperClick() {
      if (this.onHelperClicked) {
        this.onHelperClicked();
      }
    },
    getText() {
      return this.text;
    },
    setText(val) {
      this.changeListenerEnabled = false;
      if (this.formatInput) {
        const t = this.formatInput(val);
        if (t !== this.text) {
          this.text = t;
        }
      }
      const self = this;
      setTimeout(() => {
        self.changeListenerEnabled = true;
      }, 400);
    },
  },
};
</script>


<style lang="scss" scoped>

.input-header {
  text-align: left;
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.2em;

  padding: 10px $padding-section 8px $padding-section;
  border-top: 1px solid $theme-list-divider-color;
}

.container {
  background: white;
  display: flex;
  position: relative;
  /*background: $theme-3-bg;*/
  /*border: 1px solid $box-border-color;*/
  //border-radius: $theme-border-radius;
  //padding: 5px $padding-section;
  //padding-top: 10px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  //border-bottom: 1px solid $theme-list-divider-color;
  border: 1px solid $theme-list-divider-color;
  border-radius: $theme-border-radius;
  //margin: 0 18px;
  height: 50px;

  &--focused {
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &--error {
    border: 1px solid $theme-error;
  }
  &--valid {
    border: 1px solid $theme-ilm-selection;
  }

  &__icon-container {
    padding: 13px 12px;
    background: $theme-3-bg;
    height: 100%;
    border-right: 1px solid $theme-list-divider-color;
    box-sizing: border-box;
    border-top-left-radius: $theme-border-radius - 2px;
    border-bottom-left-radius: $theme-border-radius - 2px;
  }

  &--error ::v-deep &__icon-container {
    background: $theme-error;
    border-right: 1px solid $theme-error;
    color: white;
  }

}

.input-container {
  flex: 1 1 auto;

  display: inline-block;
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.input-field {
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-lg;
  display: inline-block;
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;

  &:focus, &:active {
    outline: none;
    // border: none;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

.error-container {
  text-align: left;
  //padding: 4px $padding-section 0 $padding-section;
  padding: 2px $padding-section 0 0;
  color: red;
  font-size: 14px;

  &__icon {
    margin-right: 2px;
  }
}


.input-icon {
  color: $theme-3-color;
  margin-right: 6px;
  flex: 0 0 auto;

  &--clear {
    margin-right: 0;
    margin-left: 6px;
  }

  &--help {
    margin-right: 0;
    margin-left: 6px;
    color: $theme-helper-color;
  }
}

.input-clear-container {
  cursor: pointer;
  margin-top: 6px;
  margin-right: 8px;

  @media (hover) {
    &:hover i {
      color: $theme-link-color;
    }
  }
}


</style>
