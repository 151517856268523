<template>
  <button
    :class="classNames"
    :disabled="isDisabled"
    class="button"
    @click="handleClick">
    <span class="button__col_one">{{ buttonLabelOne }}</span>
    <span class="button__col_two">{{ buttonLabelTwo }}</span>
    <LoadingSpinner
      v-if="isWorking"
      class="button__spinner" />
  </button>
</template>


<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

// const THEMES = [
//   'success',
//   'info',
//   'danger',
//   'destructive',
// ];

export default {
  name: 'IlmButtonColumnized',

  // Components ********************************
  components: {
    LoadingSpinner,
  },

  // Properties ********************************
  props: {
    tKeyOne: String,
    labelOne: String,
    tKeyTwo: String,
    labelTwo: String,
    disabled: Boolean,
    isWorking: Boolean,
    preventDefault: Boolean,
    isPrice: {
      type: Boolean,
      default: false,
    },
    onClick: Function,
    theme: {
      type: String,
      default: 'success',
      // validator: t => THEMES.includes(t)
    },
  },

  // Data ********************************
  data() {
    return {
      mutableDisabled: this.disabled,
    };
  },

  // Computed ********************************
  computed: {
    isDisabled() { return this.mutableDisabled || this.isWorking; },
    buttonLabelOne() {
      return this.tKeyOne ? this.$t(this.tKeyOne) : this.labelOne;
    },
    buttonLabelTwo() {
      return this.tKeyTwo ? this.$t(this.tKeyTwo) : this.labelTwo;
    },
    classNames() {
      const classNames = {
        'button--disabled': this.isDisabled,
        'button--working': this.isWorking,
        'button--single_col': !this.tKeyTwo && !this.labelTwo,
        'button--is_price': this.isPrice,
      };
      if (this.theme) {
        const themes = this.theme.split(' ');
        themes.forEach(t => { classNames[`button--${t}`] = true; });
      }
      return classNames;
    },
  },

  watch: {
    disabled(val) {
      this.setDisabled(val);
    },
  },

  // Methods ********************************
  methods: {
    handleClick(e) {
      if (this.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (this.isWorking || this.isDisabled || !this.onClick) return;
      this.onClick();
    },
    setDisabled(value) {
      this.mutableDisabled = value;
    },
  },
};
</script>


<style lang="scss" scoped>
@mixin button-theme($color, $bg: transparent) {
  color: $color;
  background-color: $bg;

  &:hover {
    @if $bg != transparent {
      background-color: darken($bg, 2%);
      color: $color;
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .2);
    } @else {
      color: darken($color, 20%);
    }
  }

  @if $bg != transparent {
    &:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, .2);
      background-color: darken($bg, 7%);
    }
  }

  // &:focus { outline: 1px dotted rgba($theme-focus-color, .5); }

  @if $bg != transparent {
    &--disabled,
    &:disabled {
      &:active,
      &:hover {
        background-color: $bg;
        box-shadow: none;
      }
    }
  }
}

.button {
  text-decoration: none;
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-lg;
  padding: 14px $html-font-size;
  border-radius: $theme-border-radius;
  line-height: 1.2em;
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  -webkit-tap-highlight-color:  rgba(0, 0, 0, 0);
  transition: $hover-transition;
  user-select: none;

  &--success {
    @include button-theme($theme-button-success-color, $theme-button-success-background );
  }
  &--info {
    @include button-theme($theme-button-info-color, $theme-button-info-background );
  }
  &--danger {
    @include button-theme($theme-button-danger-color, $theme-button-danger-background );
  }
  &--destructive {
    @include button-theme($theme-button-destructive-color, $theme-button-destructive-background );
  }
  &--simple {
    @include button-theme($theme-button-simple-color, $theme-button-simple-background );
  }
  &--inverted {
    @include button-theme($theme-button-inverted-color, $theme-button-inverted-background );
  }

  &--default {
    @include button-theme($theme-button-default-color, $theme-button-default-background );
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &--block {
    width: 100%;
    text-align: center;
    // display: inline-block;
  }

  &--working {
    color: transparent !important;
    position: relative;
    opacity: 1;
    cursor: progress;
  }

  &__col_one {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  &__col_two {
    flex: 0 0 auto;
    padding-left: 6px;
    text-align: right;
  }

  &--is_price ::v-deep &__col_two {
    font-size: 22px !important;
  }

  &--single_col &__col_one {
    text-align: center;
  }

  &__spinner {
    position: absolute !important;
    left: 50%;
    top: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }
}
</style>
