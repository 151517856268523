
<template>
  <div class="calendar-container">
    <!--swiper
      ref="mySwiper"
      :options="swiperOption"
      @someSwiperEvent="callback"
      @slideNextTransitionEnd="slideNextTransitionEnd"
      @slidePrevTransitionEnd="slidePrevTransitionEnd">
      <swiper-slide
        v-for="d in dates"
        :key="d.index">
        <IlmCalendarDay
          :date="d.date"
          :is-selected="isSelected(d.date)"
          :unavailable-days="unavailableWeekDays"
          :on-selected="onCalendarDateSelected" />
      </swiper-slide>
    </swiper-->
    <div
      ref="slider"
      class="swiper-container">
      <div class="swiper-wrapper">
        <!-- It is important to set "left" style prop on every slide -->
        <div
          v-for="d in dates"
          :key="d.index"
          class="swiper-slide">
          <IlmCalendarDay
            :date="d.date"
            :discount="d.discount"
            :is-selected="isSelected(d.date)"
            :unavailable-days="unavailableWeekDays"
            :on-selected="onCalendarDateSelected" />
        </div>
      </div>
    </div>

    <div class="calendar-container__date_selected">
      <!--span class="calendar-container__date_selected__text">{{ dateSelectedText }}</span-->
    </div>
  </div>
</template>


<script>
// https://surmon-china.github.io/vue-awesome-swiper/
//import 'swiper/dist/css/swiper.css';
//import { swiper, swiperSlide } from 'vue-awesome-swiper';
//import 'swiper/css/swiper.min.css';

// https://swiperjs.com
import Swiper from 'swiper';
import DateUtils from '@/utils/dateUtils.js';
import IlmCalendarDay from './IlmCalendarDay';

export default {
  name: 'IlmCalendar',

  // Components ********************************
  components: {
    //swiper,
    //swiperSlide,
    IlmCalendarDay,
  },

  // Properties ********************************
  props: {
    minDate: Object,
    selected: Object,
    dayDiscounts: Object,
    unavailableWeekDays: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    onDateSelected: Function,
    onPageChange: Function,
  },

  // Data ********************************
  data() {
    return {
      slider: null,
      maxDays: 56,
      dates: [],
      selectedDate: this.selected,
      swiperOption: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        /*slidesPerView: 28,
        slidesPerGroup: 28,*/
        iOSEdgeSwipeDetection: true,
        /*
        breakpoints: {
          // when window width is <= 220px
          220: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          // when window width is <= 320px
          320: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 7,
            spaceBetween: 7,
          },
          // when window width is <= 1024px
          1024: {
            slidesPerView: 10,
            spaceBetween: 10,
          },
          // when window width is <= 1400px
          1400: {
            slidesPerView: 16,
            spaceBetween: 16,
          },
        },
        */
      },
      swipeOptions: {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 7,
        slidesPerGroup: 7,
        iOSEdgeSwipeDetection: true,
        threshold: 5,
        on: {
          slideNextTransitionEnd: this.slideNextTransitionEnd,
          slidePrevTransitionEnd: this.slidePrevTransitionEnd,
          slideChange: () => {
            if (this.onPageChange) {
              this.onPageChange();
            }
          },
        },
      },
    };
  },

  // Computed ********************************
  computed: {
    swiper() {
      //return this.$refs.mySwiper.swiper;
      return this.slider;
    },
    currentSlideIndex() {
      return this.swiper.activeIndex;
    },
    daysPerPage() {
      return 7;
      /*
      const windowWidth = window.outerWidth;
      if (windowWidth <= 220) {
        return 3;
      }
      if (windowWidth <= 320) {
        return 5;
      }
      if (windowWidth <= 480) {
        return 7;
      }
      if (windowWidth <= 1024) {
        return 10;
      }
      if (windowWidth <= 1400) {
        return 16;
      }
      return 28;*/
    },
    dateSelectedText() {
      if (this.selectedDate) {
        const currDate = this.selectedDate.format('D MMMM YYYY');
        const isToday = DateUtils.isTodayDate(this.selectedDate);
        const isTomorrow = DateUtils.isTomorrowDate(this.selectedDate);

        if (isToday === true || isTomorrow === true) {
          return this.$t(isToday ? 'dates.today_format' : 'dates.tomorrow_format', { date: currDate });
        }

        return currDate;
      }
      return '';
    },
  },

  watch: {
    dayDiscounts() {
      let weekday;
      for (let i = 0; i < this.dates.length; i++) {
        weekday = this.dates[i].weekday;
        this.dates[i].discount = this.dayDiscounts[weekday];
      }
    },
  },

  destroyed() {
  },

  mounted() {
    //console.log('this is current swiper instance object', this.swiper);
    //this.swiper.slideTo(3, 1000, false);
    this.initDates();

    this.slider = new Swiper(this.$refs.slider, this.swipeOptions);
    //this.slider.init();
    const self = this;
    this.$nextTick(() => {
      if (self.selectedDate) {
        self.setSelectedPage(self.selectedDate);
      }
      self.slider.update();
    });
  },

  methods: {
    callback() {
    },


    // INITIALIZATION *****************

    initDates() {
      const dt = this.$moment(this.minDate ? this.minDate : new Date());

      let i = 0;
      let dateObj;
      let discount;
      let weekday;
      while (i < this.maxDays) {
        dateObj = this.$moment(dt);
        weekday = dateObj.isoWeekday();
        if (this.dayDiscounts) {
          discount = this.dayDiscounts[weekday];
        } else {
          discount = null;
        }
        this.dates.push({
          index: i,
          weekday,
          date: dateObj,
          discount,
        });
        dt.add(1, 'd');
        i++;
      }
    },


    // GETTERS *****************

    totalPages() {
      return this.maxDays / this.daysPerPage;
    },
    currentPage() {
      return Math.floor(this.currentSlideIndex / this.daysPerPage);
      //return this.currentSlideIndex / this.daysPerPage;
    },
    isToday(d) {
      return d.isSame(this.$moment(new Date()), 'day');
    },
    isSelected(d) {
      return this.selectedDate ? d.isSame(this.selectedDate, 'day') : false;
    },
    isDayUnavailable(d) {
      const weekday = d.isoWeekday();
      return this.unavailableWeekDays.includes(weekday);
    },

    canSlideBack() {
      //const current = this.currentPage();
      //return current > 0;
      return this.swiper.isBeginning === false;
    },
    canSlideForward() {
      //const current = this.currentPage();
      //const total = this.totalPages();
      //return current < total - 1;

      return this.swiper.isEnd === false;
    },
    monthName() {
      const slide = this.currentPage();
      const index = slide * this.daysPerPage;
      const startDate = this.$moment(this.dates[index].date);
      let month = startDate.format('MMMM');
      startDate.add(this.daysPerPage - 1, 'd');
      const endMonth = startDate.format('MMMM');
      if (month !== endMonth) {
        month = `${month} - ${endMonth}`;
      }

      return month;
    },

    update() {
      this.swiper.update();
    },


    // SETTERS *****************

    setSelectedPage(date) {
      const filtered = this.dates.filter(function (el) {
        return el.date.format('YYYY MM DD') === date.format('YYYY MM DD');
      });
      if (filtered.length > 0) {
        const index = filtered[0].index;
        const page = Math.floor(index / this.daysPerPage);
        if (page !== this.currentPage) {
          const goToIndex = page * this.daysPerPage;
          const self = this;
          this.$nextTick(() => {
            self.swiper.slideTo(goToIndex, 0, false);
          });
        }
      }
    },

    setSelectedDate(d) {
      this.selectedDate = d;
      this.setSelectedPage(d);

      let discount = null;
      const filtered = this.dates.filter(function (el) {
        return el.date.format('YYYY MM DD') === d.format('YYYY MM DD');
      });
      if (filtered.length > 0) {
        const index = filtered[0].index;
        discount = this.dates[index].discount;
      }

      if (this.onDateSelected) this.onDateSelected(d, discount);
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
    nextSlide() {
      this.swiper.slideNext();
    },


    // EVENTS *****************

    handleResize() {
      //this.swiperOption.slidesPerView = 4;
      //this.swiperOption.slidesPerGroup = 4;
    },

    slideNextTransitionEnd() {
      /*
      const index = this.currentSlideIndex;
      const pageDays = this.daysPerPage;
      console.log(`slideNextTransitionEnd ${index} | daysPerPage: ${pageDays}`);
      */
      //const index = this.currentSlideIndex;
      const pageDays = this.daysPerPage;
      const page = this.currentPage();
      //console.log(`slideNextTransitionEnd ${page} | daysPerPage: ${pageDays}`);

      if (this.selectedDate) {
        //const dt = this.$moment(this.selectedDate);
        //dt.add(pageDays, 'd');
        const dt = this.$moment(new Date());
        dt.add(page * pageDays, 'd');
        this.setSelectedDate(dt);
      }
    },
    slidePrevTransitionEnd() {
      /*
      const page = this.currentSlideIndex;
      const pageDays = this.daysPerPage;
      console.log(`slidePrevTransitionEnd ${page} | daysPerPage: ${pageDays}`);
      */
      //const index = this.currentSlideIndex;
      const pageDays = this.daysPerPage;
      const page = this.currentPage();
      //console.log(`slidePrevTransitionEnd ${page} | daysPerPage: ${pageDays}`);

      if (this.selectedDate) {
        //const dt = this.$moment(this.selectedDate);
        //dt.add(-pageDays, 'd');
        const dt = this.$moment(new Date());
        dt.add(page * pageDays, 'd');
        this.setSelectedDate(dt);
      }
    },
    onCalendarDateSelected(d) {
      if (this.isSelected(d)/* || this.isLoading*/) return;

      //console.log(`date selected ${d.format('DD-MM-YYYY')}`);

      this.selectedDate = d;
      if (this.onDateSelected) {
        let discount = null;
        const filtered = this.dates.filter(function (el) {
          return el.date.format('YYYY MM DD') === d.format('YYYY MM DD');
        });
        if (filtered.length > 0) {
          const index = filtered[0].index;
          discount = this.dates[index].discount;
        }

        this.onDateSelected(d, discount);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.swiper-container {
  //height: 60px;
  overflow-x: hidden;
}
.swiper-wrapper {
  //height: 100%;
  display: flex;
  //overflow-x: scroll;
}
.swiper-slide {
  flex-shrink: 0;
}
.calendar-container {
  position: relative;
  //padding: 6px 0 0 0;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    opacity: 0.9;
    z-index: 10;
  }

  &__date_selected {
    //margin: 14px 20px 10px 20px;
    //margin: 26px 20px 0 20px;
    margin: $padding-section 0 0 0;
    //border-top: 1px solid $theme-list-divider-color;

    &__text {
      display: block;
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 12px;
      color: $theme-ilm-color;
      margin: 8px;
      text-align: center;
    }
  }
}

</style>
