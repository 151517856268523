<template>
  <div>
    <div
      :class="containerClassNames">
      <div
        v-if="icon"
        class="container__icon-container">
        <IlmIcon
          :icon="icon"
          class="ontainer__icon-container__icon"
          size="xl" />
      </div>
      <div class="input-container">
        <input
          v-if="typeCard"
          ref="inputf"
          v-model="text"
          v-cardformat:formatCardNumber
          :placeholder="inputPlaceholder"
          :name="name"
          :disabled="isDisabled"
          :class="inputClassNames"
          :autofocus="focusOnStart"
          type="text"
          inputmode="numeric"
          class="input-field"
          @focus="handleFocus(true)"
          @blur="handleFocus(false)"
          @keyup.enter="handleEnter">
        <input
          v-else-if="typeDate"
          ref="inputf"
          v-model="text"
          v-cardformat:formatCardExpiry
          :placeholder="inputPlaceholder"
          :name="name"
          :disabled="isDisabled"
          :class="inputClassNames"
          :autofocus="focusOnStart"
          type="text"
          inputmode="numeric"
          class="input-field"
          @focus="handleFocus(true)"
          @blur="handleFocus(false)"
          @keyup.enter="handleEnter">
        <input
          v-else-if="typeCvv"
          ref="inputf"
          v-model="text"
          v-cardformat:formatCardCVC
          :placeholder="inputPlaceholder"
          :name="name"
          :disabled="isDisabled"
          :class="inputClassNames"
          :autofocus="focusOnStart"
          type="text"
          inputmode="numeric"
          class="input-field"
          @focus="handleFocus(true)"
          @blur="handleFocus(false)"
          @keyup.enter="handleEnter">
      </div>
      <div
        v-if="typeCard && iconCardImage"
        class="input-icon-container">
        <img
          :src="iconCardImage"
          class="input-icon-container__icon">
      </div>
      <div
        v-if="showHelper"
        class="input-helper-container"
        @click="handleHelperClick">
        <IlmIcon
          class="input-icon input-icon--help"
          icon="question-circle"
          size="xl" />
      </div>
      <!--div
        v-show="showClearButton"
        class="input-clear-container"
        @click="handleClear">
        <IlmIcon
          class="input-icon input-icon--clear"
          icon="cancel"
          size="xl"/>
      </div-->
    </div>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

//https://github.com/wuori/vue-credit-card-validation
import cardValidation from '@/utils/payment/validation';
import cardUtils from '@/utils/payment/utils';

export default {
  name: 'PaymentCardInput',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    typeCard: {
      type: Boolean,
      default: false,
    },
    typeDate: {
      type: Boolean,
      default: false,
    },
    typeCvv: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholderKey: String,
    placeholder: String,
    name: String,
    icon: String,
    disabled: Boolean,
    focusOnStart: {
      type: Boolean,
      default: true,
    },
    showHelper: {
      type: Boolean,
      default: false,
    },
    onChange: Function,
    onHelperClicked: Function,
  },

  // Data ********************************
  data() {
    return {
      hasFocus: false,
      text: this.value ? this.value : '',
      showError: false,
      cardType: null,
    };
  },

  // Computed ********************************
  computed: {
    isDisabled() { return this.disabled; },

    showClearButton() { return !this.showHelper && !!this.text; },

    inputPlaceholder() {
      if (this.placeholderKey || this.placeholder) {
        return this.placeholderKey ? this.$t(this.placeholderKey) : this.placeholder;
      }
      return '';
    },

    inputClassNames() {
      const classNames = {
        'input-field--disabled': this.isDisabled,
        'input-field--iconized': this.icon,
      };
      return classNames;
    },

    containerClassNames() {
      const classNames = {
        container: true,
        'container--disabled': this.isDisabled,
        'container--focused': this.hasFocus,
        'container--error': this.showError,
        'container--valid': !this.showError && this.text.length > 0 && !this.hasFocus,
      };
      return classNames;
    },

    iconCardImage() {
      return cardUtils.iconCardImage(this.cardType);
      /*
      const relativeUrl = '/images/payment-cards/';
      if (this.cardType === 'visa') {
        return `${relativeUrl}visa.svg`;
      }
      if (this.cardType === 'mastercard') {
        return `${relativeUrl}mastercard.svg`;
      }
      if (this.cardType === 'amex') {
        return `${relativeUrl}amex.svg`;
      }
      if (this.cardType === 'dinersclub') {
        return `${relativeUrl}dinners.svg`;
      }
      if (this.cardType === 'discover') {
        return `${relativeUrl}discover.svg`;
      }
      if (this.cardType === 'jcb') {
        return `${relativeUrl}jcb.svg`;
      }
      if (this.cardType === 'elo') {
        return `${relativeUrl}elo.svg`;
      }
      if (this.cardType !== null) {
        return `${relativeUrl}unknown.svg`;
      }
      return null;
      */
    },
  },

  // Watchers ********************************
  watch: {
    text(val) {
      //if (cardValidation.validateCardNumber(val)) {
      //console.log('Credit Card VALID!');
      //}

      let isValid = this.isValid();

      if (this.typeCard) {
        const card = cardUtils.cardFromNumber(val);
        if (card) {
          this.cardType = card.type;
        } else {
          this.cardType = null;
        }

        if (isValid === true) {
          /*if (this.typeCvv === false || ['mastercard', 'visa'].includes(this.cardType) === true) {
            this.handleEnter();
          }*/
          const possibleCardLengths = card.length;
          const inputLength = val.replace(/\D/g, '').length;
          if (inputLength >= possibleCardLengths[possibleCardLengths.length - 1]) {
            this.handleEnter();//cvcLength
          } else {
            // so that the onChange callback doesn't change input focus
            isValid = false;
          }
        }
      } else if (this.typeDate) {
        /*if (isValid === true) {
          const inputLength = val.replace(/\D/g, '').length;
          if (inputLength >= 4) {
            this.handleEnter();
          } else {
            // so that the onChange callback doesn't change input focus
            isValid = false;
          }
        }*/
      } else if (this.typeCvv) {
        if (isValid === true) {
          /*const possibleCardLengths = card.length;
          const inputLength = val.replace(/\D/g, '').length;
          if (inputLength >= possibleCardLengths[possibleCardLengths.length - 1]) {
            this.handleEnter();
          } else {
            // so that the onChange callback doesn't change input focus
            isValid = false;
          }*/
        }
        //cvcLength
      }

      if (this.isDisabled || !this.onChange) return;
      this.onChange(val, isValid);
    },
  },

  // Mounted ********************************
  mounted() {
    if (this.focusOnStart === true) {
      const self = this;
      this.$nextTick(function () {
        setTimeout(function () { // timeout because set focus causes funky animation transition.
          const input = self.$refs.inputf;
          if (input) {
            input.focus();
          }
        }, 300);
      });
    }
  },

  // Methods ********************************
  methods: {
    handleFocus(val) {
      this.hasFocus = val;

      if (val) {
        this.showError = false;
      } else {
        this.showError = !this.isValid();
      }
    },
    handleClear() {
      this.text = '';
      this.$refs.inputf.focus();
      this.showError = false;
    },
    handleEnter() {
      this.$refs.inputf.blur();
    },
    setFocus() {
      this.$refs.inputf.focus();
    },
    handleHelperClick() {
      if (this.onHelperClicked) {
        this.onHelperClicked();
      }
    },
    getText() {
      return this.text;
    },

    isValid() {
      if (this.typeCard === true) {
        if (cardValidation.validateCardNumber(this.text)) {
          return true;
        }
      }
      if (this.typeDate === true) {
        if (cardValidation.validateCardExpiry(this.text)) {
          return true;
        }
      }
      if (this.typeCvv === true) {
        if (cardValidation.validateCardCVC(this.text)) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>


<style lang="scss" scoped>

.input-header {
  text-align: left;
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.2em;

  padding: 10px $padding-section 8px $padding-section;
  border-top: 1px solid $theme-list-divider-color;
}

.container {
  background: white;
  display: flex;
  position: relative;
  /*background: $theme-3-bg;*/
  /*border: 1px solid $box-border-color;*/
  //border-radius: $theme-border-radius;
  //padding: 5px $padding-section;
  //padding-top: 10px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  //border-bottom: 1px solid $theme-list-divider-color;
  border: 1px solid $theme-list-divider-color;
  border-radius: $theme-border-radius;
  //margin: 0 18px;
  height: 50px;

  &--focused {
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &__icon-container {
    padding: 13px 12px;
    background: $theme-3-bg;
    height: 100%;
    border-right: 1px solid $theme-list-divider-color;
    box-sizing: border-box;
    border-top-left-radius: $theme-border-radius - 2px;
    border-bottom-left-radius: $theme-border-radius - 2px;
  }

  &--error {
    border: 1px solid $theme-error;
  }
  &--valid {
    border: 1px solid $theme-ilm-selection;
  }

  &--error ::v-deep &__icon-container {
    background: $theme-error;
    color: white;
    border-right: 1px solid $theme-error;
  }

}

.input-container {
  flex: 1 1 auto;

  display: inline-block;
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.input-field {
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-lg;
  display: inline-block;
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;

  &:focus, &:active {
    outline: none;
    // border: none;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

.error-container {
  text-align: left;
  //padding: 4px $padding-section 0 $padding-section;
  padding: 2px $padding-section 0 0;
  color: red;
  font-size: 14px;

  &__icon {
    margin-right: 2px;
  }
}


.input-icon {
  color: $theme-3-color;
  margin-right: 6px;
  flex: 0 0 auto;

  &--clear {
    margin-right: 0;
    margin-left: 6px;
  }

  &--help {
    margin-right: 0;
    margin-left: 4px;
    color: $theme-helper-color;
  }
}

.input-icon-container {
  margin-top: 6px;
  margin-right: 8px;

  &__icon {
    width: 40px;
    height: 25px;
  }
}
.input-helper-container {
  cursor: pointer;
  margin-top: 6px;
  margin-right: 6px;

  @media (hover) {
    &:hover i {
      color: $theme-link-color;
    }
  }
}


</style>
