
<template>
  <div :class="mainClass">
    <div
      ref="slider"
      class="swiper-container">
      <DiscountsCard
        v-if="discountItems.length === 1"
        :index="0"
        :discount="discountItems[0]"
        :selected="true"
        :on-click="onCardClick"
        :class="`${mainClass}__single-discount`" />
      <div
        v-else-if="discountItems.length > 1"
        class="swiper-wrapper">
        <div
          v-for="(discount, index) in discountItems"
          :key="index"
          class="swiper-slide">
          <DiscountsCard
            :index="index"
            :discount="discount"
            :selected="isCardSelected(index)"
            :on-click="onCardClick" />
        </div>
        <div
          v-if="discountItems.length > 2"
          class="swiper-slide">
          <DiscountsCardMore
            :selected="isCardSelected(discountItems.length)"
            :on-click="onCardMoreClick" />
        </div>
      </div>
    </div>
    <div
      v-if="showPrev"
      :class="`${mainClass}__nav-arrow ${mainClass}__nav-arrow--left`"
      @click="onClickPrev">
      <IlmIcon
        icon="keyboard_arrow_left" />
    </div>
    <div
      v-if="showNext"
      :class="`${mainClass}__nav-arrow ${mainClass}__nav-arrow--right`"
      @click="onClickNext">
      <IlmIcon
        icon="keyboard_arrow_right" />
    </div>
    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />
  </div>
</template>


<script>
// https://swiperjs.com
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

import DiscountsCard from '@/components/Sections/Discounts/DiscountsCard.vue';
import DiscountsCardMore from '@/components/Sections/Discounts/DiscountsCardMore.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';

export default {
  name: 'DiscountsSlider',

  // Components ********************************
  components: {
    DiscountsCard,
    DiscountsCardMore,
    IlmIcon,
    Alert,
  },

  // Properties ********************************
  props: {
    initialize: {
      type: Boolean,
      default: false,
    },
    discounts: Object,
  },

  // Data ********************************
  data() {
    return {
      discountItems: [],

      slider: null,
      isAnimatingSlider: false,
      showPrev: false,
      showNext: false,

      swipeOptions: {
        direction: 'horizontal',
        centeredSlides: true,
        spaceBetween: 10,
        //slidesOffsetBefore: 18,
        //slidesOffsetAfter: 18,
        grabCursor: true,
        loop: false,
        slidesPerView: 1.7,
        slidesPerGroup: 1,
        iOSEdgeSwipeDetection: true,
        threshold: 5,
        /*pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 8,
          hideOnClick: false,
        },*/
        /*navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },*/
        breakpoints: {
          // when window width is >= 300px
          300: {
            slidesPerView: 1.8,
            spaceBetween: 6,
          },
          // when window width is >= 360px
          360: {
            slidesPerView: 2.0,
            spaceBetween: 6,
          },
          // when window width is >= 480px
          375: {
            slidesPerView: 2.2,
            spaceBetween: 6,
          },
          // when window width is >= 540px
          400: {
            slidesPerView: 2.4,
            spaceBetween: 10,
          },
          460: {
            slidesPerView: 2.6,
            spaceBetween: 6,
          },
          540: {
            slidesPerView: 3.2,
            spaceBetween: 6,
          },
          // when window width is >= 768px
          660: {
            slidesPerView: 3.4,
            spaceBetween: 10,
          },
          770: {
            slidesPerView: 3.2,
            spaceBetween: 10,
          },
        },
        on: {
          //slideNextTransitionEnd: this.slideNextTransitionEnd,
          //slidePrevTransitionEnd: this.slidePrevTransitionEnd,
          slideChange: this.onSlideChange,
        },
      },

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'discounts-slider';
    },
  },

  watch: {
    initialize(val) {
      if (val) {
        this.animate();
      }
    },
    discounts(val) {
      this.discountItems = val.getFeaturedDiscounts();
      this.$nextTick(() => {
        this.slider.update();
      });
    },
  },

  // Created ********************************
  created() {
    this.discountItems = this.discounts.getFeaturedDiscounts();
  },

  // Mounted ********************************
  mounted() {
    this.slider = new Swiper(this.$refs.slider, this.swipeOptions);
    if (this.initialize) {
      this.animate();
    }
    this.$nextTick(() => {
      if (this.discountItems.length > 1) {
        this.showPrev = false;
        this.showNext = true;
      } else {
        this.showPrev = false;
        this.showNext = false;
      }
    });
  },

  // Methods ********************************
  methods: {
    animate() {
      if (this.$localStorage.showDiscountAnimation()) {
        if (this.discountItems.length > 2) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.isAnimatingSlider = true;
              this.slider.slideTo(2, 400, false);
              setTimeout(() => {
                this.slider.slideTo(0, 400, true);
                this.isAnimatingSlider = false;
                this.$localStorage.setDiscountAnimationShown();
              }, 800);
              //this.slider.update();
            }, 500);
          });
        }
      }
    },
    isCardSelected(index) {
      return this.slider
        && this.slider.activeIndex === index
        && !this.isAnimatingSlider;
    },
    onSlideChange() {
      this.showPrev = this.slider.activeIndex > 0;

      if (this.discountItems.length === 1) {
        this.showNext = false;
      } else {
        // discountItems length + last card (show more)
        this.showNext = this.slider.activeIndex < this.discountItems.length;
      }
    },
    onCardClick(index) {
      if (index !== this.slider.activeIndex) {
        this.slider.slideTo(index);
      } else {
        this.openNextScreen(this.discountItems[index]);
      }
    },
    onCardMoreClick() {
      if (this.slider.activeIndex <= this.discountItems.length - 1) {
        this.slider.slideTo(this.discountItems.length);
      } else {
        this.$router.push({
          name: this.$constants.ROUTE_DISCOUNTS_LIST,
        }, () => {});
      }
    },
    onClickPrev() {
      if (this.slider.activeIndex > 0) {
        this.slider.slidePrev();
      }
    },
    onClickNext() {
      if (this.discountItems.length > 1 && this.slider.activeIndex < this.discountItems.length) {
        this.slider.slideNext();
      }
    },

    openNextScreen(discount) {
      if (discount.getServiceIds().length === 1) {
        this.requestServiceDetails(discount.getServiceIds()[0], discount);
      } else {
        this.$router.replace({
          name: this.$constants.ROUTE_SEARCH_SERVICE,
          params: {
            showBusinessServices: true,
            selectedDiscounts: [discount],
          },
        }, () => {});
      }
    },

    requestServiceDetails(serviceId, discount) {
      this.$root.loading = true;

      this.$graphql.servicesDetailsQuery([serviceId], services => {
        this.$root.loading = false;

        if (services.length === 0) {
          return;
        }
        const service = services[0];
        if (!this.$store.getters.getAppointments) {
          this.$store.dispatch('resetAppointment');
        }

        service.discount = discount;

        if (this.$store.getters.getAppointments.addService(service)) {
          this.$router.replace({ name: this.$constants.ROUTE_NEW_APPOINTMENT_SERVICES });
        } else {
          this.alert(this.$t('errors.services_select_duplicated_title'), this.$t('errors.services_select_duplicated'));
        }
      }, () => {
        this.$root.loading = false;
        this.showError(this.$t('errors.generic_title'), this.$t('errors.generic_message'));
      });
    },

    showError(title, message) {
      this.alert(title, message);
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.discounts-slider {
  position: relative;
  padding: 0 0 20px 0;
  overflow: hidden;

  &__single-discount {
    margin: 0 auto;
  }

  &__nav-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: all .5s ease-in-out 0s;
    font-size: 34px;
    color: $theme-2-color;
    cursor: pointer;

    &--left {
      left: -4px;
      opacity: 0;
      transform: translateX(-10px) translateY(-50%);

    }
    &--right {
      right: -4px;
      opacity: 0;
      transform: translateX(10px) translateY(-50%);
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__nav-arrow {
      display: initial;
    }

    @media (hover) {
      &:hover &__nav-arrow  {
        opacity: 1;
        transform: translateX(0) translateY(-50%);
      }
    }
  }
}

</style>
