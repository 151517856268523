import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  // STATE
  state: {
    token: null,
    source: {
      fbMessenger: { isMessenger: false, inFrame: false },
      description: '',
    },
    auth: null,
    canResumeVerification: true,
    clientSuggestions: null,
    serviceSearch: null,
    selectedCategory: null,
    appointment: null,
    editAppointment: null,
    appointments: null,
    discounts: null,
    businessClosedWeekdays: [],
    professionals: [],
    customer: null,
  },
  // GETTERS
  getters,
  // MUTATIONS
  mutations,
  // ACTIONS
  actions,
};
