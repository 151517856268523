//import Professional from './professional';
//import SlotGroup from './slotGroup';
import SlotItem from './slotItem';
//import { stripDiacritics } from '../utils/matcher';

class AvailabilityDay {
  constructor(data) {
    this.slots = [];
    //this.scheduleStart = null;
    //this.scheduleEnd = null;
    if (data) {
      const self = this;
      if (data.slots) {
        //let availabilityFound = false;
        data.slots.forEach(function (element) {
          //const slot = new SlotGroup(element);
          //self.slots.push(slot);

          const slot = new SlotItem(element);
          self.slots.push(slot);
        });
      }
    }
  }

  getSlots() {
    return this.slots;
  }
  /*
  getProfessionals() {
    return this.professionals;
  }

  orderByProfessionalName = function compare(a, b) {
    const first = stripDiacritics(a.name);
    const second = stripDiacritics(b.name);
    if (first < second) {
      return -1;
    } if (first > second) {
      return 1;
    }
    return 0;
  }*/
}

export default AvailabilityDay;
