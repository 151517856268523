
<template>
  <div class="autocomplete-list-container">
    <div v-if="highlightResults">
      <ListHeader
        v-if="!isLoading && mainResults.length === 0 && suggestionResults.length === 0"
        t-key="text.service_search_empty_results"
        class="autocomplete-list-container__header" />
      <ListHeader
        v-if="mainResults.length > 0"
        :t-key="headerKey"
        class="autocomplete-list-container__header" />
      <div
        v-if="mainResults.length > 0"
        class="autocomplete-list-container__results">
        <div
          v-for="(item, index) in mainResults"
          :key="index">
          <summary-row
            v-if="item.isGroup === false"
            :element="item.elem"
            :search-text="searchText"
            :show-category="showCategory"
            :on-item-selected="handleServiceClick"
            :show-divider="index < mainResults.length-1"
            highlight-simple
            class="autocomplete-list-container__row" />
          <AutocompleteListRow
            v-else
            :item="item.elem"
            :search-text="searchText"
            :on-click="handleRowClick"
            :show-divider="index < mainResults.length-1"
            highlight-simple
            class="autocomplete-list-container__row" />
        </div>
        <div
          v-if="suggestionResults.length > 0"
          class="autocomplete-list-container__divider" />
      </div>
      <ListHeader
        v-if="suggestionResults.length > 0"
        t-key="headers.search_service_list_results_suggestions"
        class="autocomplete-list-container__header" />
      <div v-if="suggestionResults.length > 0">
        <div
          v-for="(item, index) in suggestionResults"
          :key="index">
          <summary-row
            v-if="item.isGroup === false"
            :element="item.elem"
            :search-text="searchText"
            :show-category="showCategory"
            :on-item-selected="handleServiceClick"
            :show-divider="index < suggestionResults.length-1"
            class="autocomplete-list-container__row" />
          <AutocompleteListRow
            v-else
            :item="item.elem"
            :search-text="searchText"
            :on-click="handleRowClick"
            :show-divider="index < suggestionResults.length-1"
            class="autocomplete-list-container__row" />
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(item, index) in items"
        :key="index">

        <AutocompleteListRow
          v-if="item.count > 1"
          :item="item"
          :search-text="searchText"
          :on-click="handleRowClick"
          :show-divider="index < items.length-1"
          class="autocomplete-list-container__row" />
        <summary-row
          v-else-if="item.count === 1"
          :element="item.services[0]"
          :search-text="searchText"
          :show-category="showCategory"
          :on-item-selected="handleServiceClick"
          :show-divider="index < items.length-1"
          class="autocomplete-list-container__row" />
      </div>
    </div>
  </div>
</template>


<script>
import ListHeader from '@/components/Lists/Header.vue';
import utils from '@/utils/utils';
import AutocompleteListRow from './AutocompleteListRow.vue';
import SummaryRow from './SummaryListRow.vue';

export default {
  name: 'AutocompleteList',

  // Components ********************************
  components: {
    AutocompleteListRow,
    SummaryRow,
    ListHeader,
  },


  // Properties ********************************
  props: {
    searchText: String,
    items: Array,
    highlightResults: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
    dataType: String,
    onItemSelected: Function,
    onServiceSelected: Function,
  },

  // Data ********************************
  data() {
    return {
      mainResults: [],
      suggestionResults: [],
    };
  },

  // Computed ********************************
  computed: {
    headerKey() {
      if (this.searchText && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH) {
        return 'headers.search_service_list_results';
      }
      return 'headers.search_service_list';
    },
  },

  // Watch ********************************
  watch: {
    items() {
      if (this.highlightResults === true) {
        this.setResults();
      }
    },
    discounts() {
      if (this.highlightResults === true) {
        this.setResults();
      }
    },
  },

  created() {
    if (this.highlightResults === true) {
      this.setResults();
    }
  },

  // Methods ********************************
  methods: {
    handleRowClick(item) {
      if (!this.onItemSelected) return;
      this.onItemSelected(item, this.dataType);
    },
    handleServiceClick(item) {
      if (!this.onServiceSelected) return;
      this.onServiceSelected(item, this.dataType);
    },
    setResults() {
      this.mainResults = [];
      this.suggestionResults = [];
      if (this.items) {
        const self = this;

        let element;
        if (this.items.length === 1) {
          if (this.items[0].services) {
            this.items[0].services.forEach(function (item) {
              element = { elem: item, isGroup: false };
              if (utils.isExactMatch(item.name, self.searchText)) {
                self.mainResults.push(element);
              } else {
                self.suggestionResults.push(element);
              }
            });
          }
        } else {
          this.items.forEach(function (item) {
            if (item.services) {
              if (item.services.length === 1) {
                element = { elem: item.services[0], isGroup: false };
              } else {
                element = { elem: item, isGroup: true };
              }
              if (utils.isExactMatch(element.elem.name, self.searchText)) {
                self.mainResults.push(element);
              } else {
                self.suggestionResults.push(element);
              }
            }
          });
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.autocomplete-list-container {
  display: block;
  padding: 0;
  touch-action: auto;

  &__divider {
    border-bottom: 1px solid $theme-list-divider-color;
    margin-bottom: 20px;
  }

  &__header {
    background: white;
    padding: $padding-section;
  }

  /*
  &__row {
    //border-bottom: 1px solid $theme-list-divider-color;
  }

  &__row:last-child {
    //border-bottom: none;
  }*/
}


</style>
