<template>
  <button
    :class="classNames"
    :disabled="isDisabled"
    class="button-iconized"
    @click="handleClick">
    {{ buttonLabel }}
    <IlmIcon
      :icon="icon"
      :size="size"
      class="button-iconized__icon" />
  </button>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'IlmButtonIcon',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    icon: String,
    size: {
      type: String,
      default: 'xl',
    },
    tKey: String,
    label: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    isWorking: Boolean,
    preventDefault: Boolean,
    onClick: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    isDisabled() { return this.disabled; },
    buttonLabel() {
      return this.tKey ? this.$t(this.tKey) : this.label;
    },
    classNames() {
      const classNames = {
        'button-iconized--disabled': this.isDisabled,
      };
      return classNames;
    },
  },

  // Methods ********************************
  methods: {
    handleClick(e) {
      if (this.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (this.isDisabled || !this.onClick) return;
      this.onClick();
    },
    setIsDisabled(disable) {
      this.isDisabled = disable;
    },
  },
};
</script>


<style lang="scss">

@mixin button-iconized-theme($color, $bg: transparent) {
  color: $color;
  background-color: $bg;

  &:hover {
    @if $bg != transparent {
      background-color: darken($bg, 2%);
      color: $color;
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .2);
    } @else {
      color: darken($color, 20%);
    }
  }

  @if $bg != transparent {
    &:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, .2);
      background-color: darken($bg, 7%);
    }
  }

  // &:focus { outline: 1px dotted rgba($theme-focus-color, .5); }

  @if $bg != transparent {
    &--disabled,
    &:disabled {
      &:active,
      &:hover {
        background-color: $bg;
        box-shadow: none;
      }
    }
  }
}

.button-iconized {
  text-decoration: none;
  font-family: $base-font-family;
  font-weight: bold;
  padding: 20px;
  border-radius: $theme-border-radius;
  background: transparent;
  line-height: 1.2em;
  border: 0;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-tap-highlight-color:  rgba(0, 0, 0, 0);
  transition: $hover-transition;
  user-select: none;

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &--block {
    width: 100%;
    text-align: center;
    // display: inline-block;
  }

  &__icon {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
