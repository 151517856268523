<template>
  <div>
    <div class="loading-spinner" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

$spinner-color: #000;
$spinner-duration: 1.1s;
$spinner-size: .9em;
$spinner-border-size: 1px;

.loading-spinner {
  font-size: $spinner-size;
  display: inline-block;
  position: relative;
  text-indent: -9999em;
  color: rgba($spinner-color, .2);
  border-width: $spinner-border-size;
  border-style: solid;
  border-left-color: $theme-ilm-color;
  transform: translateZ(0);
  animation: load8 $spinner-duration infinite linear;
  align-self: center;
  user-select: none;

  &,
  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  &--sm { font-size: $font-size-sm; }
  &--lg { font-size: $font-size-lg; }
  &--xl {
    font-size: $font-size-xl;
    border-width: 2px;
  }
}
</style>
