
/**
 *
 * Set value in localStorage
 * @param key localStorage key to add
 * @param value value to save in localStorage
 */
function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
}

/**
 *
 * Get value from localStorage
 * @param key localStorage key to get
 */
function getLocalStorage(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
  return '';
}

function removeLocalStorage(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
}

/**
 *
 * Remove from localStorage
 * @param key localStorage key to remove
 */
/*
function removeLocalStorage(key) {
  localStorage.removeItem(key);
}*/


const USER_TOKEN_KEY = 'user_token';
const BUSINESS_NAME_KEY = 'business';
const DIFF_TIMEZONE_ALERT_KEY = 'diff_timezone_alert';
const INTENT_KEY = 'user_intent';
const INTENT_ID_KEY = 'user_intent_id';
const DISCOUNT_ANIMATION_KEY = 'discount_animation_shown';

//const COOKIES_CONSENT_KEY = 'cookie_consent';

export default {

  isProduction: true,

  setToken(token) {
    // change later for localStorage
    try {
      /*let cookieName = 'user_token';
      if (this.isProduction === false) {
        cookieName = 'user_token_stg';
      }*/
      if (token && token !== '') {
        /*
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        const expires = date.toGMTString();
        document.cookie = `${cookieName}=${token}; expires=${expires};
        domain=agende.online; path=/; ${this.isProduction ? 'secure' : ''}`;
        */
        setLocalStorage(USER_TOKEN_KEY, token);
      } else {
        removeLocalStorage(USER_TOKEN_KEY);
        /*document.cookie = `${cookieName}=; max-age=0; domain=agende.online;
        path=/; ${this.isProduction ? 'secure' : ''}`;*/
      }
    } catch (e) {
      console.log(e);
    }
    //window.$cookies.set('token', token);
  },

  getToken() {
    // change later for localStorage
    try {
      const cookie = document.cookie;
      if (cookie) {
        let regexp = new RegExp(/(?:^user_token|;\s*user_token)=(.*?)(?:;|$)/, 'g');
        if (this.isProduction === false) {
          regexp = new RegExp(/(?:^user_token_stg|;\s*user_token_stg)=(.*?)(?:;|$)/, 'g');
        }
        const result = regexp.exec(cookie);
        if (result && result[1] !== '') {
          setLocalStorage(USER_TOKEN_KEY, result[1]);

          let cookieName = 'user_token';
          if (this.isProduction === false) {
            cookieName = 'user_token_stg';
          }
          document.cookie = `${cookieName}=; max-age=0; domain=agende.online; path=/; ${this.isProduction ? 'secure' : ''}`;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return getLocalStorage(USER_TOKEN_KEY);
  },


  /**
   *
   * Save business name
   * @param name business name to save in localStorage
   */
  setBusinessName(name) {
    setLocalStorage(BUSINESS_NAME_KEY, name);
  },

  /**
   *
   * Get business name
   */
  getBusinessName() {
    return getLocalStorage(BUSINESS_NAME_KEY);
  },


  /**
   *
   * Save timezone alert was shown to user
   */
  setTimezoneAlert() {
    setLocalStorage(DIFF_TIMEZONE_ALERT_KEY, true);
  },

  /**
   *
   * Get timezone alert was shown to user
   */
  getTimezoneAlert() {
    const value = getLocalStorage(DIFF_TIMEZONE_ALERT_KEY);
    return value === 'true';
  },

  /**
   *
   * Save user intent
   */
  setIntent(intent) {
    setLocalStorage(INTENT_KEY, intent);
  },

  /**
   *
   * Get user intent
   */
  getIntent() {
    const value = getLocalStorage(INTENT_KEY);
    return value;
  },

  /**
   *
   * Increment discount animation shown
   */
  setDiscountAnimationShown() {
    const value = getLocalStorage(DISCOUNT_ANIMATION_KEY);
    let count = 1;
    if (value) {
      count = parseInt(value, 10);
      count += 1;
    }
    setLocalStorage(DISCOUNT_ANIMATION_KEY, count);
  },

  /**
   *
   * Can show discount animation
   */
  showDiscountAnimation() {
    const value = getLocalStorage(DISCOUNT_ANIMATION_KEY);
    let count = 0;
    if (value) {
      count = parseInt(value, 10);
    }
    return count < 2;
  },


  setIntentId(intentId) {
    if (intentId) {
      const obj = getLocalStorage(INTENT_ID_KEY);
      if (obj && obj.length > 0) {
        const array = JSON.parse(obj);
        if (array && array.length > 0) {
          array.push({
            id: intentId,
          });
          setLocalStorage(INTENT_ID_KEY, JSON.stringify(array));
          return;
        }
      }

      const array = [
        {
          id: intentId,
        },
      ];
      setLocalStorage(INTENT_ID_KEY, JSON.stringify(array));
    }
  },


  isIntentIdProcessed(intentId) {
    if (intentId) {
      const obj = getLocalStorage(INTENT_ID_KEY);
      if (obj && obj.length > 0) {
        const array = JSON.parse(obj);
        if (array && array.length > 0) {
          let index = 0;
          let found = -1;
          array.forEach(elem => {
            if (elem.id === intentId) {
              found = index;
            }
            index++;
          });
          if (found > -1) {
            array.splice(found, 1);
            setLocalStorage(INTENT_ID_KEY, JSON.stringify(array));
            return false;
          }
        }
      }
    }
    return true;
  },

  /**
   *
   * Clear localStorage
   */
  clear() {
    localStorage.clear();
  },

};
