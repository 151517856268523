<template>
  <div id="app">
    <transition-page>
      <router-view />
    </transition-page>
    <IlmLoader :is-loading="$root.loading" />
  </div>
</template>

<script>
import TransitionPage from '@/components/Transitions/TransitionPage.vue';
import IlmLoader from '@/components/IlmPageLoader.vue';

export default {

  components: {
    TransitionPage,
    IlmLoader,
  },

  mounted() {
    /*
    const self = this;
    window.extAsyncInit = function () {
      // the Messenger Extensions JS SDK is done loading
      //console.log('using getUserId in', window.name);

      const isMessengerExtensionsSupported = window.MessengerExtensions.isInExtension();
      self.$store.dispatch('setMessengerFrame', isMessengerExtensionsSupported);

      //console.log('MessengerExtensions supported: ', isMessengerExtensionsSupported);
    };
    */
    /*
      window.MessengerExtensions.getUserID(function success(uids) {
        // User ID was successfully obtained.
        const psid = uids.psid;
        console.log(`your User Id Is ${psid}`);
        //document.getElementById('userId').innerText = `your User Id Is ${psid}`;
      }, function error(err, errorMessage) {
        // Error handling code
        console.log('some Error', err, errorMessage);
      });

      window.MessengerExtensions.getContext('192257194950997',
        function success(result) {
          //document.getElementById('context').innerText = `result ${result}`;
          console.log('success', result);
        },
        function error(result) {
          console.log('error', result);
        });*/
  },
};
</script>

<style src="./styles/app.scss" lang="scss"></style>

<style lang="scss" scoped>


</style>
