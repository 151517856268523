<template>
  <button
    :class="classNames"
    :disabled="isDisabled"
    class="button"
    @click="handleClick">
    {{ buttonLabel }}
    <LoadingSpinner
      v-if="isWorking"
      class="button__spinner" />
  </button>
</template>


<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

// const THEMES = [
//   'success',
//   'info',
//   'danger',
//   'destructive',
// ];

export default {
  name: 'IlmButtonBadge',

  // Components ********************************
  components: {
    LoadingSpinner,
  },

  // Properties ********************************
  props: {
    tKey: String,
    label: String,
    icon: String,
    disabled: Boolean,
    isWorking: Boolean,
    preventDefault: Boolean,
    onClick: Function,
    theme: {
      type: String,
      default: 'success',
      // validator: t => THEMES.includes(t)
    },
  },

  // Data ********************************
  data() {
    return {
      mutableDisabled: this.disabled,
    };
  },


  // Computed ********************************
  computed: {
    isDisabled() { return this.mutableDisabled || this.isWorking; },
    buttonLabel() {
      return this.tKey ? this.$t(this.tKey) : this.label;
    },
    classNames() {
      const classNames = {
        'button--disabled': this.isDisabled,
        'button--working': this.isWorking,
      };
      if (this.theme) {
        const themes = this.theme.split(' ');
        themes.forEach(t => { classNames[`button--${t}`] = true; });
      }
      return classNames;
    },
  },

  // Methods ********************************
  methods: {
    handleClick(e) {
      if (this.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (this.isWorking || this.isDisabled || !this.onClick) return;
      this.onClick();
    },
    setDisabled(value) {
      this.mutableDisabled = value;
    },
  },
};
</script>


<style lang="scss" scoped>
@mixin button-theme($color, $bg: transparent) {
  color: $color;
  background-color: $bg;

  &:hover {
    @if $bg != transparent {
      background-color: darken($bg, 2%);
      color: $color;
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .2);
    } @else {
      color: darken($color, 20%);
    }
  }

  @if $bg != transparent {
    &:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, .2);
      background-color: darken($bg, 7%);
    }
  }

  // &:focus { outline: 1px dotted rgba($theme-focus-color, .5); }

  @if $bg != transparent {
    &--disabled,
    &:disabled {
      &:active,
      &:hover {
        background-color: $bg;
        box-shadow: none;
      }
    }
  }
}

.button {
  text-decoration: none;
  font-family: $base-font-family;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 16px;
  border: 0;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-tap-highlight-color:  rgba(0, 0, 0, 0);
  transition: $hover-transition;

  &--success {
    @include button-theme($theme-button-success-color, $theme-button-success-background );
  }
  &--info {
    @include button-theme($theme-button-info-color, $theme-button-info-background );
  }
  &--danger {
    @include button-theme($theme-button-danger-color, $theme-button-danger-background );
  }
  &--destructive {
    @include button-theme($theme-button-destructive-color, $theme-button-destructive-background );
  }
  &--simple {
    @include button-theme($theme-button-simple-color, $theme-button-simple-background );
  }
  &--inverted {
    @include button-theme($theme-button-inverted-color, $theme-button-inverted-background );
  }

  &--default {
    @include button-theme($theme-button-default-color, $theme-button-default-background );
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &--iconized {
    padding-right: 44px;
    padding-left: 44px;
  }

  &--block {
    width: 100%;
    text-align: center;
    // display: inline-block;
  }

  &--working {
    color: transparent !important;
    position: relative;
    opacity: 1;
    cursor: progress;
  }

  &__spinner {
    position: absolute !important;
    left: 50%;
    top: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }
}
</style>
