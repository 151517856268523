<template>
  <i
    :class="classNames"
    :title="title"
    class="icon"
  />
</template>


<script>
const SIZES = ['sm', 'md', 'lg', 'xl', 'xxl'];

export default {
  name: 'IlmIcon',

  // Properties ********************************
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: String,
    fw: Boolean, // fixed-width
    size: {
      type: String,
      validator: s => SIZES.includes(s),
    },
  },

  // Data ********************************
  data() {
    return {};
  },


  // Computed ********************************
  computed: {
    iconClass() {
      return `icon-${this.icon}`;
    },
    sizeClass() {
      return this.size ? `icon--${this.size}` : null;
    },
    classNames() {
      const classNames = {
        'icon--fw': this.fw,
        'icon--working': this.isWorking,
        [this.iconClass]: true,
      };
      if (this.sizeClass) {
        classNames[this.sizeClass] = true;
      }
      return classNames;
    },
  },
};
</script>


<style lang="scss">
@import 'iconmoon/style';

@mixin ilm-icon($icon) {
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ilm-erb' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: $icon;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon {
  &--fw {
    width: calc(18em / 14);
    text-align: center;
  }

  &--sm { font-size: 12px; }
  &--md { font-size: 14px; }
  &--lg { font-size: 18px; }
  &--xl { font-size: 24px; }
  &--xxl { font-size: 28px; }
}
</style>
