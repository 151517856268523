<template>
  <BaseModal
    ref="basemodal"
    :title="$t('modals.titles.choose_professional')"
    class="modal-professional"
    @closed="onClosed">
    <div
      ref="modal_content"
      class="modal-professional__content">
      <div
        class="modal-professional__content__service-container">
        <h1 class="modal-professional__content__service-container__text">
          {{ selectedService.name }}
        </h1>
      </div>
      <div
        class="modal-professional__content__header-container">

        <SearchBox
          v-if="professionalList.length > 10"
          ref="search_input"
          :focus-on-enter="false"
          placeholder-key="placeholders.search_box_professionals"
          class="modal-professional__content__header-container__search"
          name="search"
          type="text"
          icon="search"
          @change="searchTextChange" />

          <!--Header
          ref="header"
          t-key="headers.search_professional"
          class="modal-professional__content__header" /-->
      </div>

      <!--ProfessionalList
        id="modal-professional-list"
        :items="professionalList"
        :selected-item="selectedProfissional"
        :search-text="searchText"/-->
      <div
        v-if="showAnyAvailableOption"
        class="item-list-container">
        <ItemListRow
          :item="anyAvailableOption"
          :selected="isAnyAvailableOptionSelected"
          :on-click="onItemSelected"
          :show-divider="professionalList.length > 0"
          class="item-list-container__row modal-professional__content__any_available"
          show-icon />
      </div>

      <ItemList
        :items="items"
        :selected-item="selectedProfissional"
        :search-text="searchText"
        :on-item-selected="onItemSelected"
        class="modal-professional__content__list" />
    </div>
  </BaseModal>
</template>


<script>
import BaseModal from '@/components/Modals/BaseModal.vue';
import SearchBox from '@/components/Inputs/SearchBox.vue';
import ItemList from '@/components/Lists/ItemList.vue';
import ItemListRow from '@/components/Lists/ItemListRow.vue';
import Utils from '@/utils/utils.js';


export default {
  name: 'ModalProfessional',

  // Components ********************************
  components: {
    BaseModal,
    SearchBox,
    ItemList,
    ItemListRow,
  },

  // Properties ********************************
  props: {
    selectedProfissional: Object,
    selectedService: Object,
    //serviceId: String,
    timeSlot: Object,
    selectedDate: Object,
    professionalList: Array,
  },

  // Data ********************************
  data() {
    return {
      searchText: null,
      items: null,
      anyAvailableOption: Object,
      onProfessionalSelected: Function,
    };
  },

  // Computed ********************************
  computed: {
    isAnyAvailableOptionSelected() {
      return !this.selectedProfissional;
    },
    showAnyAvailableOption() {
      /*if (!this.selectedProfissional) {
        return false;
      }*/
      return (!this.searchText
      || this.searchText.length < this.$constants.MIN_SEARCH_STRING_LENGTH)
      && this.professionalList.length > 0;
    },
  },

  created() {
    this.items = [];
    const self = this;
    this.professionalList.forEach(function (item) {
      item.subtitle = self.subtitleText(item);
      item.profile = Utils.getNameInitials(item.name);
      item.profileUrl = item.profileImageUrl;
      self.items.push(item);
    });
  },

  // Mounted ********************************
  mounted() {
    this.anyAvailableOption = {
      id: -1,
      name: this.$t('section_list.appointment_professional_any'),
      //name: this.$t('button.appointment_choose_professional_empty').replace('<br>', ' '),
    };

    const self = this;
    this.$nextTick(function () {
      setTimeout(function () { // timeout because of animation
        if (self.$refs.modal_content) {
          self.$refs.modal_content.addEventListener('scroll', self.handleScroll);
        }
      }, 500);
    });
  },

  destroyed() {
    this.items = [];
  },

  // Methods ********************************
  methods: {
    searchTextChange(val) {
      this.searchText = val;
      /*if (val && val.length > 0) {
      } else {
      }*/
    },
    subtitleText(item) {
      if (this.selectedDate && item) {
        const weekday = this.selectedDate.isoWeekday();
        if (item.isAvailable(weekday) === false) {
          return this.$t('notes.professional_unavailable_on_date');
        }
      }
      return null;
    },
    onItemSelected(item) {
      this.$refs.basemodal.close();
      const self = this;
      setTimeout(function () { // timeout because of animation
        self.$emit('selected', item);
      }, 300);
      //this.$emit('selected', item.id);

      /*if (this.onProfessionalSelected) {
        this.onProfessionalSelected(item);
      }*/
    },
    close() {
      this.$refs.basemodal.handleCloseClick();
    },
    onClosed() {
      this.$emit('closed', 'close');
    },

    // Scroll EVENT

    handleScroll() {
      this.$refs.search_input.removeFocus();
    },
  },
};
</script>


<style lang="scss" scoped>

.modal-professional {

  &__content {
    position: relative;
    //padding: $padding-section;
    //overflow: scroll;
    //max-height: 380px;
    /*-webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;*/

    &__header {
      margin: 0 $padding-section;
    }

    &__service-container {
      padding: 24px $padding-section;
      padding-bottom: 10px;

      &__text {
        font-size: 26px;
        text-align: center;
        margin: 0;
      }
    }

    &__list {
      margin: 0;
    }

    &__any_available ::v-deep .row-container__highlighted {
      font-weight: normal;
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__content {
      //padding: $padding-section;
      max-height: 460px;
      height: initial;
      width: initial;
    }
  }
}
</style>
