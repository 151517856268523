<template>

  <div class="modal">
    <!--transition name="fade"-->
    <div
      class="modal__frame"
      @click="handleCloseClick" />
    <!--/transition-->
    <transition name="slide-up">
      <div
        v-if="contentShowing"
        class="modal__content">
        <div class="modal__content__slot">
          <div
            ref="modal_content_slot_content"
            class="modal__content__slot_content">
            <h1 class="modal__content__slot_content__title">
              {{ $t('modals.titles.credit_card_date_helper_title') }}
            </h1>
            <p class="modal__content__slot_content__text">
              {{ $t('text.credit_card_date_helper') }}
            </p>
            <img
              ref="img"
              :class="imgClasses"
              class="modal__content__slot_content__img"
              src="@/assets/images/cc_front.png"
              @load="onImageLoad">
            <IlmButton
              :on-click="handleCloseClick"
              t-key="modals.buttons.credit_card_date_helper"
              prevent-default
              theme="inverted"
              class="modal__content__slot_content__btn" />
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';

const noScrollClass = 'no-scroll';


export default {
  name: 'ModalPaymentDateHelper',

  // Components ********************************
  components: {
    IlmButton,
  },

  // Properties ********************************
  props: {
    title: String,
    showOk: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
      disableBtnOk: this.disableOk,
      showImage: false,
    };
  },

  // Computed ********************************
  computed: {
    imgClasses() {
      return {
        'modal__content__slot_content__img--visible': this.showImage,
      };
    },
  },

  watch: {
    contentShowing(newValue) {
      if (newValue) {
        const self = this;
        this.$nextTick(function () {
          const img = self.$refs.img;
          const container = self.$refs.modal_content_slot_content;
          if (img) {
            const width = Math.min(container.clientWidth, 300);
            const originalWidth = 844;
            const originalHeight = 488;
            const height = width / (originalWidth / originalHeight);
            img.height = height;
            img.width = width;
          }
        });
      }
    },
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      self.contentShowing = true;
      //setTimeout(function () { // wait for close transition
      //self.contentShowing = true;
      //}, 200);
    });
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    close() {
      this.contentShowing = false;
    },
    onImageLoad() {
      this.showImage = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;/*
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 92%;
    max-width: 600px;*/
    overflow: hidden;

    //height: 100%;
    width: 100%;
    max-width: 400px;

    box-sizing: border-box;
    padding: 12px;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);

    /*
    height: 95%;
    width: 95%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $theme-border-radius;
    */

    &__slot {
      position: relative;
      display: flex;
      flex-direction: column;
      background: white;
      /*margin: 0 $padding-section;
      border-radius: $theme-border-radius;*/
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: $theme-border-radius;
    }

    &__slot_content {
      flex: 1 1;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      //padding: $padding-section;
      padding: 0 $padding-section;;

      &__title {
        font-size: 20px;
        font-weight: bold;
        color: black;
        //text-align: center;
        padding: 8px 0;
        margin-top: $padding-section;
        margin-bottom: 0;
      }

      &__text {
        font-size: 16px;
        color: black;
        padding: 8px 0;
        margin-top: 0;
        line-height: 1.3;
      }

      &__img {
        display: block;
        width: 100%;
        //height: auto;
        max-width: 300px;
        margin: 0 auto;
        border-radius: 16px;
        box-shadow: 0px 0px 18px 8px rgba(156,156,156,0.5);
        opacity: 0;
        transition: opacity .3s ease-in;

        &--visible {
          opacity: 1;
        }
      }

      &__btn {
        display: block;
        //width: 100%;
        //max-width: 160px;
        margin: $padding-section*2 auto;
      }
    }
  }

  @media screen and (min-width: $min-screen-width) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      max-height: 92%;
      max-width: 500px;

      height: initial;
      width: initial;

      &__slot {
        margin: 0 $padding-section;
        border-radius: $theme-border-radius;
        height: initial;
        width: initial;
      }
    }
  }
}
</style>
