
class SlotItem {
  constructor(obj) {
    this.time = obj.time;
    this.price = obj.price;
    this.professionals = obj.professionals;
  }
}

export default SlotItem;
