import MessengerContext from './models/messengerContext';

let facebookAppId;

function addMessengerExtensions() {
  /************* Load Messenger SDK *************/
  return (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/messenger.Extensions.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'Messenger'));
  /**************************/
}

const LOG_REQUESTS = process.env.VUE_APP_SHOW_DEBUG === 'true';

function logResponse(response, key) {
  if (LOG_REQUESTS) {
    if (key) {
      if (console.groupCollapsed) {
        console.groupCollapsed(key);
      }
      console.log(response);
      //console.trace();
      if (console.groupEnd) {
        console.groupEnd(key);
      }
      //console.log(JSON.stringify(response));
    } else {
      console.log(response);
    }
  }
}

export default {

  isInitiated() {
    return facebookAppId !== null && facebookAppId !== undefined;
  },

  /**
   *
   * Add MessengerExtensions
   * @appId facebook app id
   * @return Promise with boolean if isInExtension (iframe messenger)
   *
   */
  init(appId) {
    facebookAppId = appId;
    return new Promise(function (resolve) {
      window.extAsyncInit = function () {
        // the Messenger Extensions JS SDK is done loading
        const isInExtension = window.MessengerExtensions.isInExtension();
        logResponse(`got facebook callback: ${isInExtension}`, 'facebook init');
        resolve(isInExtension);
      };
      addMessengerExtensions();
    });
  },

  /**
   *
   * Get user context
   * @return Promise with user context object
   *
   * https://developers.facebook.com/docs/messenger-platform/reference/messenger-extensions-sdk/getContext
   * psid: "2183594428325892"
   * signed_request: "..."
   * thread_type: "USER_TO_PAGE"
   * tid: "2183594428325892"
   *
   */
  getContext() {
    return new Promise(function (resolve, reject) {
      if (!facebookAppId || window.MessengerExtensions.isInExtension() === false) {
        reject();
      } else {
        window.MessengerExtensions.getContext(facebookAppId,
          function success(result) {
            const context = new MessengerContext(result);
            resolve(context);
          },
          function error() {
            reject();
          });
      }
    });
  },

};
