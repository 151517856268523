<template>
  <div class="main_container">
    <div class="error_container">
      <h1 class="error_container__title">
        {{ $t('text.page_not_found_title') }}
      </h1>

      <div v-if="businessNotFound">
        <p class="error_container__text">
          {{ $t('text.business_not_found_text') }}
        </p>
        <p
          v-if="business"
          class="error_container__text container__text--small-margin"
          v-html="$t('text.business_not_found_business_name', { name: businessName })" />
        <div
          v-if="business"
          :class="callButtonClasses"
          class="error_container__call_container"
          @click="clickCall">
          <p
            class="error_container__call_container__phone"
            v-html="businessPhoneText" />
        </div>
      </div>

      <p
        v-else
        class="error_container__text">{{ $t('text.page_not_found_text') }}</p>

    </div>
  </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import utils from '@/utils/utils';

export default {
  name: 'PageNotFound',

  // Components ********************************
  components: {
  },

  // Props ********************************
  props: {
    businessNotFound: {
      type: Boolean,
      default: false,
    },
    business: Object,
  },

  data() {
    return {
    };
  },

  // Computed ********************************
  computed: {
    businessName() {
      return this.business ? this.business.name : '';
    },
    businessPhoneText() {
      if (this.business && this.business.phoneCountry && this.business.phoneNumber) {
        const formatter = new AsYouType();
        const fullPhone = formatter.input(`+${this.business.phoneCountry}${this.business.phoneNumber}`);
        return fullPhone;
      }
      return this.$t('notes.phone_business_call_unavailable');
    },
    callButtonClasses() {
      return { 'container__call_container--clickable': utils.isMobile() };
    },
  },


  // Watch ********************************
  watch: {
  },

  // Mounted ********************************
  mounted() {
    this.$root.loading = false;

    this.$analytics.track('Visit: Page Not Found', { businessName: this.businessName, businessNotFound: this.businessNotFound });
  },

  destroyed() {
  },

  // Methods ********************************
  methods: {
    clickCall() {
      if (utils.isMobile() === false) return;
      this.$analytics.track('Action: Click call business');
      if (this.business && this.business.phoneCountry && this.business.phoneNumber) {
        const phoneLink = `tel:+${this.business.phoneCountry}${this.business.phoneNumber}`;
        window.open(phoneLink);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.main_container {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error_container {
  padding: 0 $padding-section 40px $padding-section;
  text-align: center;
  //flex-direction: column;

  &__title {
    color: black;
    text-align: center;
    font-weight: normal;
    font-size: 40px;
    font-family: $base-font-family;
    margin: 0;
  }

  &__text {
    color: black;
    text-align: center;
    font-size: 18px;
    font-family: $base-font-family;
    margin-bottom: 0;

    &--small-margin {
      margin-top: 6px;
    }
  }

  &__call_container {
    margin-top: 30px;
    font-family: $heading-font-family;
    font-size: 22px;
    color: $theme-ilm-color;
    text-align: center;

    border: 2px solid $theme-ilm-color;
    border-radius: 8px;
    //display: flex;
    padding: 12px;
    display: inline-block;

    &--clickable {
      cursor: pointer;
    }

    &--clickable:hover {
      background: $theme-ilm-color;
      color: white;
    }

    &__phone {
      text-align: center;
      margin :0;
      /*
      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;*/
    }
  }
}

</style>
