<template>
  <div
    ref="card_item"
    :style="cardStyle"
    :class="cardClasses"
    class="card-item"
    @click="cardClicked">

    <span
      v-if="card"
      class="card-item__icon">
      <img
        :src="cardImage"
        class="card-item__icon__card">
    </span>
    <div
      v-if="card"
      class="card-item__details">

      <span class="card-item__details__field">
        Número
      </span>
      <span class="card-item__details__number">
        {{ cardNumber }}
      </span>
      <div class="card-item__details__holder">
        <div class="card-item__details__holder__col">
          <span class="card-item__details__field">
            Nome
          </span>
          <span class="card-item__details__value">
            {{ cardName }}
          </span>
        </div>
        <div class="card-item__details__holder__col card-item__details__holder__col--left">
          <span class="card-item__details__field">
            Validade
          </span>
          <span class="card-item__details__value">
            {{ cardExpireDate }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-else
      class="card-item__new">
      <div class="card-item__new__container">
        <span
          class="card-item__new__container__icon_add">
          <IlmIcon
            icon="add"
            size="xl" />
        </span>
        <div
          class="card-item__new__container__text-container">
          {{ $t('account.add_card') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import cardValidation from '@/utils/payment/validation';
import cardUtils from '@/utils/payment/utils';

export default {
  name: 'CardItem',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Props ********************************
  props: {
    card: Object,
    index: Number,
    onClick: Function,
  },

  data() {
    return {
      height: 0,
    };
  },

  // Computed ********************************
  computed: {
    cardStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    cardClasses() {
      const classNames = {
        'card-item--new': !this.card,
      };
      if (this.cardBrand) {
        classNames[`card-item--${this.cardBrand}`] = true;
      }
      return classNames;
    },
    cardNumber() {
      if (this.card) {
        return `**** **** **** ${this.card.lastDigits}`;
      }
      return '';
    },
    cardName() {
      if (this.card) {
        return this.card.holderName;
      }
      return '';
    },
    cardExpireDate() {
      if (this.card) {
        return cardValidation.formatExpiry(this.card.expirationDate);
      }
      return '';
    },
    cardImage() {
      if (this.card) {
        const cardType = this.card.brand;
        if (cardType) {
          return cardUtils.iconCardImage(cardType);
        }
      }
      return null;
    },
    cardBrand() {
      if (this.card && this.card.brand) {
        return this.card.brand.toLowerCase();
      }
      return null;
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
  },


  // Mounted ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      window.addEventListener('resize', self.handleResize);
      self.handleResize();
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },


  // Methods ********************************
  methods: {
    handleResize() {
      //const windowWidth = document.body.clientWidth;
      //const windowHeight = document.body.clientHeight;
      const elem = this.$refs.card_item;
      if (elem) {
        const width = elem.clientWidth;
        this.height = width / (844 / 488);
      }
    },

    cardClicked() {
      if (this.onClick) {
        this.onClick(this.index);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.card-item {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  background: gray;

  &--new {
    background: white;
    border: 2px solid $theme-helper-color;
  }
  /*
  &--visa {
    background: linear-gradient(155deg, rgba(51,13,105,1) 10%,
    rgba(99,56,160,1) 25%, rgba(99,56,160,1) 30%, rgba(51,13,105,1) 45%);
  }*/
  &--visa {
    background: linear-gradient(155deg, rgba(51,13,105,1) 10%,
    rgba(89,35,166,1) 25%, rgba(89,35,166,1) 30%, rgba(51,13,105,1) 45%);
  }
  &--mastercard {
    background: linear-gradient(155deg, rgba(202,92,27,1) 10%,
    rgba(244,138,75,1) 25%, rgba(244,138,75,1) 30%, rgba(202,92,27,1) 45%);
  }
  &--amex {
    background: linear-gradient(155deg, rgba(36,130,134,1) 10%,
    rgba(51,187,193,1) 25%, rgba(51,187,193,1) 30%, rgba(36,130,134,1) 45%);
  }
  &--elo {
    background: linear-gradient(155deg, rgba(51,51,51,1) 10%,
    rgba(108,108,108,1) 25%, rgba(108,108,108,1) 30%, rgba(51,51,51,1) 45%);
  }
  &--hipercard {
    background: linear-gradient(155deg, rgba(173,18,26,1) 10%,
    rgba(220,46,55,1) 25%, rgba(220,46,55,1) 30%, rgba(173,18,26,1) 45%);
  }
  &--aura {
    background: linear-gradient(155deg, rgba(34,90,128,1) 10%,
    rgba(40,121,176,1) 25%, rgba(40,121,176,1) 30%, rgba(34,90,128,1) 45%);
  }
  &--dinersclub {
    background: linear-gradient(155deg, rgba(2,113,94,1) 10%,
    rgba(16,192,145,1) 25%, rgba(16,192,145,1) 30%, rgba(2,113,94,1) 45%);
  }
  &--discover {
    background: linear-gradient(155deg, rgba(74,83,88,1) 10%,
    rgba(105,120,129,1) 25%, rgba(105,120,129,1) 30%, rgba(74,83,88,1) 45%);
  }
  &--unionpay {
    background: linear-gradient(155deg, rgba(204,106,130,1) 10%,
    rgba(241,155,176,1) 25%, rgba(241,155,176,1) 30%, rgba(204,106,130,1) 45%);
  }
  &--jcb {
    background: linear-gradient(155deg, rgba(183,23,151,1) 10%,
    rgba(210,44,177,1) 25%, rgba(210,44,177,1) 30%, rgba(183,23,151,1) 45%);
  }

  &__new {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;

    @media (hover) {
      &:hover {
        opacity: 0.5;
      }
    }

    &__container {
      text-align: center;

      &__icon_add {
        //line-height: 1.2em;
        //color: $theme-helper-color;
        background: $theme-helper-color;
        border-radius: 50%;
        color: white;
        min-width: 40px;
        text-align: center;
        width: 40px;
        height: 40px;
        display: block;
        margin: 0 auto;

        & i {
          font-size: 40px !important;
          font-weight: bold;
        }
      }

      &__text-container {
        font-size: 20px;
        font-weight: bold;
        color: $theme-helper-color;
        margin-top: 16px;
      }
    }

  }

  &__icon {
    position: absolute;
    top: 30px;
    right: 30px;

    &__card {
      width: 60px;
      height: 38px;
      padding: 0;
    }
  }

  &__details {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: column;

    &__number {
      color: white;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.2em;
      padding-top: 10px;
    }

    &__field {
      color: $theme-3-color;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
    }

    &__value {
      color: white;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.2em;
      padding-top: 10px;
    }

    &__holder {
      display: flex;
      width: 100%;

      padding-top: 40px;
      &__col {
        display: flex;
        flex-direction: column;

        &--left {
          flex: 1 1 auto;
          text-align: right;
        }
      }
    }
  }

  &--amex &__details, &--discover &__details, &--mastercard &__details {
    &__field {
      color: #333333;
    }
  }

  &__icon {
    display: none;
  }
  &__details {
    left: 10px;
    right: 10px;
    bottom: 10px;

    &__holder {
      padding-top: 10px;
    }
  }

  @media screen and (min-width: 300px) {
    &__icon {
      display: initial;
      top: 16px;
      right: 20px;
      &__card {
        width: 40px;
        height: 25px;
      }
    }
    &__details {
      left: 20px;
      right: 20px;
      bottom: 20px;

      &__holder {
        padding-top: 14px;
      }
    }
  }
  @media screen and (min-width: 380px) {
    &__icon {
      top: 20px;
      right: 20px;
      &__card {
        width: 60px;
        height: 38px;
      }
    }
    &__details {
      left: 20px;
      right: 20px;
      bottom: 20px;

      &__holder {
        padding-top: 20px;
      }
    }
  }
  @media screen and (min-width: 480px) {
    &__icon {
      top: 20px;
      right: 20px;
      &__card {
        width: 60px;
        height: 38px;
      }
    }
    &__details {
      left: 20px;
      right: 20px;
      bottom: 20px;

      &__holder {
        padding-top: 20px;
      }
    }
  }
  @media screen and (min-width: 540px) {
    &__icon {
      top: 30px;
      right: 30px;
      &__card {
        width: 60px;
        height: 38px;
      }
    }
    &__details {
      left: 30px;
      right: 30px;
      bottom: 30px;

      &__holder {
        padding-top: 30px;
      }
    }
  }
  @media screen and (min-width: $min-screen-width) {
    &__icon {
      top: 30px;
      right: 30px;
      &__card {
        width: 60px;
        height: 38px;
      }
    }
    &__details {
      left: 30px;
      right: 30px;
      bottom: 30px;

      &__holder {
        padding-top: 20px;
      }
    }
  }
}

</style>
