import Discount from './discount';

class DiscountList {
  constructor(data) {
    this.discounts = [];
    if (data) {
      data.forEach(element => this.discounts.push(new Discount(element)));
    }
  }

  getItems() {
    return this.discounts;
  }

  getDiscounts() {
    return this.discounts
      .sort((a, b) => {
        if (a.sortWeight > b.sortWeight) {
          return 1;
        }
        if (a.sortWeight < b.sortWeight) {
          return -1;
        }
        if (a.maxValue < b.maxValue) {
          return 1;
        }
        if (a.maxValue > b.maxValue) {
          return -1;
        }
        return 0;
      });
  }

  getFeaturedDiscounts() {
    return this.discounts
      .filter(item => item.isFeatured === true)
      .sort((a, b) => {
        if (a.sortWeight > b.sortWeight) {
          return 1;
        }
        if (a.sortWeight < b.sortWeight) {
          return -1;
        }
        if (a.maxValue < b.maxValue) {
          return 1;
        }
        if (a.maxValue > b.maxValue) {
          return -1;
        }
        return 0;
      });
  }

  addDiscount(discount) {
    if (discount && discount.id) {
      const existing = this.discounts.filter(item => item.id === discount.id);
      if (existing.length === 0) {
        this.discounts.push(discount);
      }
    }
  }

  getDiscountWithId(discountId) {
    if (this.discounts && discountId) {
      const existing = this.discounts.filter(item => item.id === discountId);
      if (existing.length > 0) {
        return existing[0];
      }
    }
    return null;
  }

  /*
  getDayDiscount(weekday) {
    return (this.discounts.length > 0
      && this.discounts[0].hasDayDiscount(weekday)) ? this.discounts[0] : null;
  }*/

  /*getDiscount() {
    return (this.discounts.length > 0
      && this.discounts[0].hasDiscount()) ? this.discounts[0] : null;
  }*/

  getMaxDiscountValue() {
    const maxDiscountValue = this.discounts.reduce(
      (max, item) => (item.maxValue > max ? item.maxValue : max), 0
    );
    return maxDiscountValue > 0 ? maxDiscountValue : null;
  }

  getDiscount() {
    let maxValueDiscount = null;
    if (this.discounts.length > 0) {
      let maxValue = 0;
      for (let i = 0; i < this.discounts.length; i++) {
        const discount = this.discounts[i];
        if (discount) {
          const max = discount.getMaxDiscount();
          //console.log(`maxDiscoutn: ${max}`);
          if (max && max > maxValue) {
            maxValue = max;
            maxValueDiscount = discount;
            //console.log('isMax');
          }
        }
      }
      //console.log('end');
    }
    return maxValueDiscount !== null ? maxValueDiscount : null;
  }

  getServiceDiscount(serviceId) {
    let maxValueDiscount = null;
    if (this.discounts.length > 0) {
      let maxValue = 0;
      for (let i = 0; i < this.discounts.length; i++) {
        const discount = this.discounts[i];
        if (discount) {
          const max = discount.getMaxDiscount(serviceId);
          if (max && max > maxValue) {
            maxValue = max;
            maxValueDiscount = discount;
          }
        }
      }
    }
    return maxValueDiscount !== null ? maxValueDiscount : null;
  }

  getServiceDateDiscounts(serviceId) {
    const weekdayDiscounts = {};
    if (this.discounts.length > 0) {
      for (let d = 1; d <= 7; d++) {
        let maxValue = 0;
        let maxValueDiscount = null;
        for (let i = 0; i < this.discounts.length; i++) {
          const discount = this.discounts[i];
          if (discount) {
            const max = discount.getDayDiscount(d, serviceId);
            if (max && max > maxValue) {
              maxValue = max;
              maxValueDiscount = discount;
            }
          }
        }
        weekdayDiscounts[d] = maxValueDiscount;
      }
    }
    return weekdayDiscounts;
  }

  getServiceDateDiscountsMax(servicesIds) {
    const weekdayDiscounts = {};
    if (this.discounts.length > 0) {
      for (let d = 1; d <= 7; d++) {
        let maxValue = 0;
        let maxValueDiscount = null;
        for (let i = 0; i < this.discounts.length; i++) {
          const discount = this.discounts[i];
          if (discount) {
            for (let j = 0; j < servicesIds.length; j++) {
              const serviceId = servicesIds[j];
              const max = discount.getDayDiscount(d, serviceId);
              if (max && max > maxValue) {
                maxValue = max;
                maxValueDiscount = discount;
              }
            }
          }
        }
        weekdayDiscounts[d] = maxValueDiscount;
      }
    }
    return weekdayDiscounts;
  }

  getServiceDiscounts(day, serviceId) {
    const list = [];
    if (this.discounts.length > 0) {
      for (let i = 0; i < this.discounts.length; i++) {
        const discount = this.discounts[i];
        if (discount) {
          const max = discount.getDayDiscount(day, serviceId);
          if (max) {
            list.push(discount);
          }
        }
      }
    }
    return list;
  }

  hasServiceDateTimeDiscounts(servicesIds, day, time) {
    if (this.discounts.length > 0) {
      //for (let d = 1; d <= 7; d++) {
      //if (d === day) {
      for (let i = 0; i < this.discounts.length; i++) {
        const discount = this.discounts[i];
        if (discount) {
          for (let j = 0; j < servicesIds.length; j++) {
            const serviceId = servicesIds[j];
            const hasDiscount = discount.hasTimeDiscount(serviceId, day, time);
            if (hasDiscount === true) {
              return true;
            }
          }
        }
      }
      //}
      //}
    }
    return false;
  }

  hasServiceDateDiscounts(servicesIds, day, startHour, endHour) {
    if (this.discounts.length > 0) {
      //for (let d = 1; d <= 7; d++) {
      //if (d === day) {
      for (let i = 0; i < this.discounts.length; i++) {
        const discount = this.discounts[i];
        if (discount) {
          for (let j = 0; j < servicesIds.length; j++) {
            const serviceId = servicesIds[j];
            const hasDiscount = discount.hasHourDiscount(serviceId, day, startHour, endHour);
            if (hasDiscount === true) {
              return true;
            }
          }
        }
      }
      //}
      //}
    }
    return false;
  }
}

export default DiscountList;
