<template>
  <div class="my-account">
    <div
      v-if="showRootView">
      <div>
        <NavigationBar
          :title="navbarSubtitle"
          :subtitle="navbarTitle"
          :show-divider="false"
          show-back
          is-modal
          is-extendable />
      </div>

      <SectionHeader
        :label="$t('account.section_personal_details')" />

      <SimpleListRow
        :title="$t('account.row_basic_info')"
        :on-click="basicInfoClick"
        show-divider />

      <SimpleListRow
        :inactive="addressButtonStyle"
        :title="$t('account.row_address')"
        :on-click="addressClick" />

      <div
        class="my-account__divider" />

      <div v-if="client">
        <SectionHeader
          :label="$t('account.section_payment_cards')" />

        <PaymentCardListRow
          v-for="(card, index) in clientCards"
          :key="index"
          :card="card"
          :on-click="cardClick"
          show-divider />

        <div
          class="my-account__add_card_container"
          @click="addCardClick">
          <span
            :class="addCardButtonStyle"
            class="my-account__add_card_container__icon_add">
            <IlmIcon
              icon="control_point"
              size="xl" />
          </span>
          <div
            :class="addCardButtonStyle"
            class="my-account__add_card_container__text-container">
            {{ $t('account.row_add_card') }}
          </div>
          <span
            class="my-account__add_card_container__icon">
            <IlmIcon
              icon="keyboard_arrow_right"
              size="xl" />
          </span>
        </div>

        <div
          class="my-account__divider" />
      </div>

      <div
        ref="footer"
        :class="footerClass"
        class="my-account__footer">
        <IlmButton
          :on-click="handleLogoutClick"
          t-key="button.logout"
          icon="power_settings_new"
          icon-size="md"
          theme="simple"
          class="my-account__footer__logout_btn" />
      </div>

      <AlertDestructive
        v-if="alertShowing"
        :title="alertTitle"
        :message="alertMessage"
        :btn-cancel="alertCancel"
        :btn-destructive="alertOk"
        @closed="onAlertDismissed"
        @cancel="onAlertDismissed"
        @ok="onAlertOk" />
    </div>
    <router-view
      v-else
      :key="$route.path" />
  </div>
</template>

<script>
import NavigationBar from '@/components/Nav/NavigationBar.vue';
import SectionHeader from '@/components/Lists/SectionHeader.vue';
import SimpleListRow from '@/components/Lists/SimpleListRow.vue';
import PaymentCardListRow from '@/components/Lists/PaymentCardListRow.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'Account',

  // Components ********************************
  components: {
    NavigationBar,
    SectionHeader,
    SimpleListRow,
    PaymentCardListRow,
    IlmButton,
    AlertDestructive,
    IlmIcon,
  },

  // Props ********************************
  props: {
  },

  data() {
    return {
      showRootView: true,
      navbarTitle: null,
      navbarSubtitle: null,

      client: null,
      clientCards: [],

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCancel: '',
      alertOk: '',
      alertCallback: Function,

      windowWidth: null,
      footerFixed: false,
    };
  },

  // Computed ********************************
  computed: {
    /*showRootView() {
      return this.$router.currentRoute.name === this.$constants.ROUTE_ACCOUNT;
    },*/
    showNavbar() {
      return this.$store.getters.isExternalSource === true;
      //return true;
    },
    containerStyle() {
      /*
      if (this.appointments && this.appointments.length === 0) {
        return {
          background: '#ffffff',
        };
      }*/
      return {};
    },
    addressButtonStyle() {
      const auth = this.$store.getters.getAuth;
      if (auth && (auth.hasPaymentBasic() || auth.hasAddress() || auth.hasCard())) {
        return false;
      }
      return true;
    },
    addCardButtonStyle() {
      const auth = this.$store.getters.getAuth;
      let isDimmed = true;
      if (auth.hasAddress() || auth.hasCard()) {
        isDimmed = false;
      }
      return {
        'my-account__add_card_container--dimmed': isDimmed,
      };
    },
    footerClass() {
      return {
        'my-account__footer--sticky': !this.footerFixed,
      };
    },
  },


  // Watch ********************************
  watch: {
    $route(val, prev) {
      this.showRootView = val.name === this.$constants.ROUTE_ACCOUNT;
      window.scrollBy(0, 0);

      if (this.showRootView && prev.name === this.$constants.ROUTE_ACCOUNT_CARDS) {
        const auth = this.$store.getters.getAuth;
        if (auth && auth.isAuthenticated() && auth.client) {
          this.requestCurrentClient(auth);
        }
      }
    },
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated() || !auth.client) {
      this.$router.replace({
        name: this.$constants.ROUTE_LOGIN,
      }, () => {});
      return;
    }
    this.client = auth.client;

    this.navbarTitle = this.client.name ? this.client.name : this.client.phoneNumber;
    this.navbarSubtitle = this.$t('title.my_account');

    this.showRootView = this.$router.currentRoute.name === this.$constants.ROUTE_ACCOUNT;

    this.requestCurrentClient(auth);
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated() || !auth.client) {
      return;
    }

    if (this.showRootView) {
      this.$analytics.track('Visit: My Account');
    }

    const self = this;
    this.$nextTick(function () {
      window.addEventListener('resize', self.handleResize);
      self.handleResize();
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },


  // Methods ********************************
  methods: {
    handleResize() {
      const windowWidth = document.body.clientWidth;
      const windowHeight = document.body.clientHeight;
      const scrollHeight = document.body.scrollHeight;
      const footer = this.$refs.footer;
      if (windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        this.footerFixed = false;
      } else if (footer
        && windowHeight - footer.clientHeight >= scrollHeight - footer.clientHeight) {
        this.footerFixed = true;
      } else {
        this.footerFixed = false;
      }
    },

    requestCurrentClient(auth) {
      this.$root.loading = true;
      const self = this;
      this.$graphql.getCurrentCustomer(true, function (obj) {
        auth.updateCurrentClient(obj);
        self.$store.dispatch('updateAuth', auth);
        self.client = auth.client;
        self.updateClientCards();
        self.$root.loading = false;
      }, function (error) {
        self.$root.loading = false;
        if (error.getCode() === Error.expiredTokenCode) {
          self.$localStorage.setToken('');
        }
        //self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
      });
    },

    updateClientCards() {
      if (this.client) {
        this.clientCards = this.client.cards.sort(function (a, b) {
          if (a.isPrefered === b.isPrefered) return 0;
          if (a.isPrefered === true) return -1;
          return 1;
        });
      }
    },

    basicInfoClick() {
      this.$router.push({
        name: this.$constants.ROUTE_ACCOUNT_DETAILS,
      }, () => {});
    },
    addressClick() {
      const auth = this.$store.getters.getAuth;
      if (auth.hasPaymentBasic() || auth.hasAddress() || auth.hasCard()) {
        this.$router.push({
          name: this.$constants.ROUTE_ACCOUNT_ADDRESS,
        }, () => {});
      } else {
        this.alert(this.$t('alerts.alert_account_missing_fields_title'),
          this.$t('alerts.alert_account_missing_info_on_address_message'),
          this.$t('alerts.alert_account_missing_fields_cancel'),
          this.$t('alerts.alert_account_missing_info_ok'),
          this.basicInfoClick);
      }
    },
    cardClick(card) {
      this.$router.push({
        name: this.$constants.ROUTE_ACCOUNT_CARDS,
        params: {
          showCardList: false,
          editCard: card,
        },
      }, () => {});
    },
    addCardClick() {
      const auth = this.$store.getters.getAuth;
      if (auth.hasAddress() || auth.hasCard()) {
        this.$router.push({
          name: this.$constants.ROUTE_ACCOUNT_CARDS,
          params: { showCardList: false },
        }, () => {});
      } else if (auth.hasPaymentBasic()) {
        this.alert(this.$t('alerts.alert_account_missing_fields_title'),
          this.$t('alerts.alert_account_missing_address_message'),
          this.$t('alerts.alert_account_missing_fields_cancel'),
          this.$t('alerts.alert_account_missing_address_ok'),
          this.addressClick);
      } else {
        this.alert(this.$t('alerts.alert_account_missing_fields_title'),
          this.$t('alerts.alert_account_missing_info_message'),
          this.$t('alerts.alert_account_missing_fields_cancel'),
          this.$t('alerts.alert_account_missing_info_ok'),
          this.basicInfoClick);
      }
    },

    alert(title, message, cancel, ok, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertCancel = cancel;
      this.alertOk = ok;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertOk() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    handleLogoutClick() {
      this.alert(this.$t('alerts.alert_logout_title'),
        this.$t('alerts.alert_logout_message'),
        this.$t('alerts.alert_logout_no'),
        this.$t('alerts.alert_logout_yes'),
        this.logout);
    },
    logout() {
      this.$localStorage.setToken('');
      this.$analytics.reset();
      this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
    },
    onAlertDismissed() {
      this.alertShowing = false;
      this.alertCallback = null;
    },
  },
};
</script>


<style lang="scss" scoped>

.my-account {
  //height: 100%;
  position: relative;
  background: $theme-3-bg;

  @media screen and (min-width: $min-screen-width) {
    min-height: 100vh;
  }

  &__divider {
    border-bottom: 1px solid $theme-list-divider-color;
    margin-bottom: 20px;
  }

  &__list_btn {
    &--dimmed span {
      opacity: 0.4;
    }
  }

  &__add_card_container {
    position: relative;
    background: white;
    padding: 12px $padding-section;
    min-height: 37px;
    font-weight: bold;
    cursor: pointer;
    color: $theme-helper-color;
    touch-action: auto;
    -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
    transition: $hover-transition;

    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;

    &--dimmed {
      opacity: 0.4;
    }

    @media (hover) {
      &:hover {
        background: $list-pressed-row-background;
      }
    }

    &__text-container {
      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
      font-size: 18px;
      padding-left: $padding-section;
    }

    &__icon_add {
      line-height: 1.2em;
      color: $theme-helper-color;
      flex: 0 0 auto;
      min-width: 40px;
      text-align: center;

      & i {
        font-size: 26px !important;
      }
    }
    &__icon {
      font-size: 14px;
      line-height: 1.2em;
      color: $theme-color-dim;
      flex: 0 0 auto;
    }
  }

  &__footer {
    border-top: 1px solid $theme-list-divider-color;
    background: white;
    text-align: center;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /*position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;*/

    &--sticky {
      position: relative;
    }

    &__logout_btn {
      font-weight: normal;
      font-size: 12px;
      color: $theme-destructive-contrast;
      padding-right: 32px;
      padding-left: 16px;
    }
    &__logout_btn:hover {
      color: $theme-danger;
    }
  }

}

</style>
