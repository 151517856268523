<template>
  <div class="success">
    <div
      :style="headerStyle"
      :class="headerClasses"
      class="success__icon-container">
      <div class="success__icon-container__animator-container">
        <span
          v-if="showBusinessName"
          class="success__icon-container__animator__business_name"
          @click="clickComplete">
          {{ businessName }}
          <IlmIcon
            icon="keyboard_arrow_right"
            class="success__icon-container__animator__business_name__icon" />
        </span>
        <div
          v-if="initialized"
          class="success__icon-container__animator">
          <IlmIcon
            icon="check_circle"
            class="success__icon-container__animator__icon" />
        </div>
      </div>
      <h1
        class="success__icon-container__title"
        v-html="screenTitle" />
    </div>

    <div
      :style="contentStyle"
      class="success__confirmation-content">
      <div v-if="customer">
        <SectionVoucherSuccess :customer="customer" />
      </div>

      <div v-else-if="appointments">
        <SectionAppointments
          :appointments="sectionList"
          :date-text="dateText"
          :sticky-top="10"
          :on-edit-note-click="clickEditNote"
          confirmation-mode />
      </div>
    </div>

    <transition name="slide-up-fast">
      <div
        v-if="showComplete && !isEditingNotes /*!notes || !isEditingNotes*/"
        :class="footerClass"
        class="success__footer success__footer--shadow">
        <div class="success__footer__split">
          <IlmButton
            v-if="showComplete && !isEditingNotes"
            :on-click="clickComplete"
            :t-key="closeButtonTitleKey"
            prevent-default
            theme="block success"
            class="success__footer__confirm_button success__footer__confirm_button--left" />
        </div>
      </div>
    </transition>

    <ModalNotes
      v-if="modalNotesShowing"
      ref="notes_modal"
      :saved-note="appointmentEditNote ? appointmentEditNote.notes : null"
      @closed="onModalNotesClosed"
      @ok="onModalNotesSaved" />

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertClosed"
      @ok="onAlertClosed" />

    <Popover
      v-if="popupSuccessShowing"
      :title="$t('popover.save_success')"
      icon="check"
      @closed="popupSuccessShowing = false" />
  </div>
</template>

<script>
import confetti from 'canvas-confetti'; // https://github.com/catdad/canvas-confetti
import SectionAppointments from '@/components/Sections/SectionAppointments.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import SectionVoucherSuccess from '@/components/Sections/SectionVoucherSuccess.vue';
import ModalNotes from '@/components/Modals/ModalNotes.vue';
import Popover from '@/components/Popover/PopoverSimple.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import DateUtils from '@/utils/dateUtils.js';

const GOTO_MY_APPOINTMENTS_TIME_LIMIT = 3000;

export default {
  name: 'Success',
  components: {
    SectionAppointments,
    IlmButton,
    SectionVoucherSuccess,
    ModalNotes,
    IlmIcon,
    Popover,
    Alert,
  },

  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      next(false);
      if (this.modalNotesShowing) {
        //this.modalNotesShowing = false;
        this.$refs.notes_modal.close();
        return;
      }
      this.$store.dispatch('resetAppointment');
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger && fbMessenger.inFrame) {
        window.MessengerExtensions.requestCloseBrowser(
          function success() {
            // webview closed
          },
          function error() {
            // an error occurred
          },
        );
      } else {
        this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
      }
      return;
    }
    next();
  },

  props: {
    appointments: Array,
    isEditMode: {
      type: Boolean,
      default: false,
    },
    customer: Object,
  },

  // Data ********************************
  data() {
    return {
      isLoading: false,
      sectionList: [],
      subtitle: '',
      notes: null,
      initialized: false,
      showComplete: Boolean,
      successMessage: String,
      myAppointmentsAllowed: false,
      myAppointmentsLoading: false,
      timer: null,
      windowWidth: null,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: null,

      isEditingNotes: false,
      appointmentEditNote: null,
      modalNotesShowing: false,

      popupSuccessShowing: false,
    };
  },

  // Computed ********************************
  computed: {
    screenTitle() {
      if (this.customer && this.customer.voucher) {
        return this.$t('title.voucher_success');
      }
      return this.isEditMode
        ? this.$t('title.appointment_edit_success')
        : this.$tc('title.appointment_success', this.sectionList.length, {
          count: this.sectionList.length,
        });
    },
    comandaDate() {
      if (this.sectionList.length > 0) {
        const day = this.sectionList[0].day;
        return this.$moment(day, 'YYYY-MM-DD');
      }
      return null;
    },
    dateText() {
      const date = this.comandaDate;
      if (date) {
        const currDate = date.format('D MMMM');
        const isToday = DateUtils.isTodayDate(date);
        const isTomorrow = DateUtils.isTomorrowDate(date);

        if (isToday === true || isTomorrow === true) {
          return this.$t(isToday ? 'dates.today_format' : 'dates.tomorrow_format', {
            date: currDate,
          });
        }

        return `${date.format('ddd').toUpperCase()}, ${currDate}`;
      }
      return '';
    },
    getAppointment() {
      return this.appointment;
    },
    discountValue() {
      if (this.appointment && this.appointment.discount) {
        const weekday = this.$moment(this.appointment.date).isoWeekday();
        const value = this.appointment.discount.getHourDiscount(weekday, this.appointment.time);
        return value;
      }
      return null;
    },
    headerStyle() {
      if (this.customer && this.customer.voucher) {
        return {
          //background: this.customer.voucher.color,
        };
      }
      return {};
    },
    headerClasses() {
      return {
        'success__icon-container--voucher': this.customer && this.customer.voucher,
      };
    },
    contentStyle() {
      if (this.windowWidth < this.$constants.MIN_SCREEN_WIDTH) {
        const style = {
          paddingBottom: '100px',
        };
        return style;
      }
      return {
        paddingBottom: '20px',
      };
    },
    footerClass() {
      return {
        'success__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    footerStyles() {
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return {
          position: 'sticky',
        };
      }
      return {
        position: 'fixed',
      };
    },
    closeButtonTitleKey() {
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger === true && fbMessenger.inFrame === true) {
        return 'button.close';
      }
      return 'button.start';
    },
    showBusinessName() {
      return (
        this.$store.getters.isExternalSource && this.windowWidth < this.$constants.MIN_SCREEN_WIDTH
      );
    },
    business() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        return auth.business;
      }
      return null;
    },
    businessName() {
      const business = this.business;
      if (business && business.name) {
        return business.name;
      }
      return '';
    },
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  // Mounted ********************************
  mounted() {
    const fbMessenger = this.$store.getters.getMessenger;
    this.showComplete
      = (fbMessenger.isMessenger === true && fbMessenger.inFrame === true)
      || this.$store.getters.isExternalSource === true;

    this.$store.dispatch('setSelectedCategory', null);
    //this.appointment = this.$store.getters.getAppointment;
    const auth = this.$store.getters.getAuth;

    if ((!this.customer && !this.appointments) || !auth || !auth.business.name) {
      this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    this.$store.dispatch('setCustomer', null);

    this.windowWidth = document.body.clientWidth;
    window.addEventListener('resize', this.handleResize);

    if (this.appointments) {
      // [...arr] copy array so that sort don't mutate original array
      this.sectionList = [...this.appointments].sort(function compare(a, b) {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      });
    }

    const self = this;
    this.$nextTick(function () {
      setTimeout(function () {
        // timeout because set focus causes funky animation transition.
        self.initialized = true;
        self.timer = setTimeout(function () {
          self.myAppointmentsAllowed = true;
          if (self.myAppointmentsLoading === true) {
            self.myAppointmentsLoading = false;
            self.clickMyAppointments();
          }
        }, GOTO_MY_APPOINTMENTS_TIME_LIMIT);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: {
            y: 0.6,
          },
        });
      }, 400);
    });

    if (this.customer) {
      this.$analytics.track('Visit: Voucher Success');
    } else {
      this.$analytics.track('Visit: Success');
    }
  },

  methods: {
    clickAddNote() {
      this.myAppointmentsLoading = false;
      this.modalNotesShowing = true;
    },
    onNotesSectionClear() {
      this.isEditingNotes = false;
      this.saveNote('');
    },
    onNotesSectionEdit() {
      this.modalNotesShowing = true;
    },
    onModalNotesClosed() {
      this.modalNotesShowing = false;
    },
    onModalNotesSaved(notes) {
      this.modalNotesShowing = false;

      if (this.appointmentEditNote) {
        const prevNotes = this.appointmentEditNote.notes;
        for (let i = 0; i < this.sectionList.length; i++) {
          const a = this.sectionList[i];
          if (
            a.service.id === this.appointmentEditNote.service.id
            && a.time === this.appointmentEditNote.time
          ) {
            if (notes) {
              a.notes = notes.trim();
            } else {
              a.notes = null;
            }
            if (prevNotes !== a.notes) {
              //this.isEditingNotes = true;
              this.saveNotes(a);
            }
            break;
          }
        }
      }
    },
    getNotesSection() {
      return { section: this.$t('section_list.note_appointment_title'), name: this.notes };
    },
    saveNotes(appointment) {
      this.isLoading = true;
      this.$root.loading = true;
      const self = this;

      const appointmentList = [appointment];

      this.$graphql.saveAppointmentNote(
        appointmentList,
        function () {
          self.isEditingNotes = false;
          //self.notes = note;
          //self.$store.dispatch('setAppointmentNote', self.notes);
          if (appointment.notes && appointment.notes.length > 0) {
            try {
              const keysNotes = appointmentList.map(x => x.key).join(',');
              self.$analytics.track('Response: Appointment Note Created', { keys: keysNotes });
            } catch (ex) {
              console.log(ex);
            }
          }

          self.isLoading = false;
          self.$root.loading = false;
          self.myAppointmentsAllowed = false;
          self.setTimer();
          self.popupSuccessShowing = true;
        },
        function () {
          self.$root.loading = false;
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        },
      );
    },
    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const self = this;
      this.timer = setTimeout(function () {
        self.myAppointmentsAllowed = true;
        if (self.myAppointmentsLoading === true) {
          self.myAppointmentsLoading = false;
          self.clickMyAppointments();
        }
      }, GOTO_MY_APPOINTMENTS_TIME_LIMIT);
    },
    clickComplete() {
      this.$store.dispatch('resetAppointment');
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger && fbMessenger.inFrame) {
        window.MessengerExtensions.requestCloseBrowser(
          function success() {
            // webview closed
          },
          function error() {
            // an error occurred
          },
        );
      } else if (this.$store.getters.isExternalSource === true) {
        this.$router.replace({ name: 'root' }, () => {});
      }
    },
    clickMyAppointments() {
      if (this.myAppointmentsAllowed === true) {
        this.$store.dispatch('resetAppointment');
        this.$router.replace({ name: this.$constants.ROUTE_MY_BOOKINGS }, () => {});
      } else {
        this.myAppointmentsLoading = true;
      }
    },

    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },

    clickEditNote(appointment) {
      //if (appointment.isFuture()) {
      this.appointmentEditNote = appointment;
      this.modalNotesShowing = true;
      //}
    },

    // Alerts
    showError(title, message) {
      this.isLoading = false;
      this.alert(title, message);
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertCallback = callback;
      this.alertShowing = true;
    },
    onAlertClosed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-bar {
  &__title {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin: 0;
  }
  @media screen and (min-width: $min-screen-width) {
    &__title {
      display: none;
    }
  }
}

.success {
  height: initial;
  min-height: 100vh;
  background: $theme-3-bg;

  @media screen and (min-width: $min-screen-width) {
    height: initial;
    min-height: initial;
  }

  &__icon-container {
    text-align: center;
    background: $theme-success;
    min-height: 200px;

    &--voucher {
      background: rgb(218, 66, 255);
      background: $voucher-gradient;
    }

    &__animator-container {
      min-height: 101px;
    }

    &__animator {
      padding-top: 20px;
      animation: heartbeat 0.5s ease-out;
      transform-origin: center center;
      will-change: transform;
      min-height: 121px;
      box-sizing: border-box;

      @media screen and (min-width: $min-screen-width) {
        padding-top: 30px;
      }

      &__business_name {
        display: block;
        font-size: 16px;
        font-weight: bold;
        padding-top: 16px;
        color: white;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          color: white;
          font-size: 26px;
        }

        @media (hover) {
          &:hover {
            opacity: 0.6;
          }
        }
      }

      &__icon {
        color: white;
        font-size: 90px;
      }
    }

    &__title {
      color: white;
      font-size: 28px;
      text-align: center;
      line-height: 1.2em;
      margin: 0;
      font-weight: bold;
      padding: $padding-section;
    }
  }

  &__confirmation-content {
    z-index: 0;
    //padding-bottom: 160px; /* CHANGE LATER: because of fixed footer height */

    &__section {
      margin-bottom: 10px;
      user-select: none;
    }

    &__note-section {
      margin-top: 20px;
      border-top: 1px solid $theme-list-divider-color;
      border-bottom: 1px solid $theme-list-divider-color;
    }

    &__note_button {
      font-size: 16px;
      margin: 10px $padding-section $padding-section 0;
    }
  }

  &__footer {
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    padding: $padding-section;
    position: fixed;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {
      &--left {
        margin-bottom: 6px;
      }

      &--right {
        margin-left: 0;
      }

      &--save {
        position: relative;
        background: white;
        color: $theme-success;
        border: 1px solid $theme-success;
      }
    }

    &__split {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 600px;
    }
  }
}

@keyframes heartbeat {
  00% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.4);
  }
}
</style>
