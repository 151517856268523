import NodeRSA from 'node-rsa';
import qs from 'qs';
import utils from '@/utils/utils';

class CustomerCard {
  constructor() {
    this.uid = null;
    this.number = null;
    this.brand = null;
    this.holderName = null;
    this.expirationDate = null;
    this.cvv = null;

    this.lastDigits = null;
    this.isPrefered = null;
    this.status = null;
  }

  update(obj) {
    this.uid = obj.uid;
    this.holderName = obj.holderName;
    this.lastDigits = obj.lastDigits;
    this.expirationDate = obj.expirationDate;
    this.brand = obj.brand;
    this.isPrefered = obj.isPrefered;
    this.status = obj.status;
  }

  queryString() {
    const stripedNumber = utils.stripInvalidChars(this.number);
    const stripedDate = utils.stripInvalidChars(this.expirationDate);
    return qs.stringify({
      card_number: stripedNumber,
      card_holder_name: this.holderName,
      card_expiration_date: stripedDate,
      card_cvv: this.cvv,
    });
  }

  getCardEncrypted(publicKey, id) {
    const key = new NodeRSA(publicKey, {
      encryptionScheme: 'pkcs1',
    });
    const cardString = this.queryString();
    const encrypted = key.encrypt(cardString, 'base64');
    if (id) {
      return `${id}_${encrypted}`;
    }
    return encrypted;
  }
}

export default CustomerCard;
