<template>
  <div class="payment-root">
    <Sticky>
      <NavbarPayment
        :show-back="showNavbarBack"
        :disable-btn-back="isLoadingPurchase"
        :show-shadow="false"
        :show-ok="currentStep < 4"
        :disable-btn-ok="!formComplete"
        :on-ok="onButtonOk"
        :title="navbarTitle"
        :subtitle="navbarSubtitle"
        :is-loading="isLoadingStep"
        show-back-bevel
        class="payment__navbar" />
    </Sticky>

    <div
      v-if="currentStep < 4"
      class="payment">

      <StepsNavigation
        v-show="!isLoading"
        :current-step="currentStep" />

      <PaymentSection1
        v-if="currentStep === 1 && customer && !isLoading"
        :customer="customer"
        :on-data-changed="stepDataChanged"
        :on-proceed="onButtonOk" />

      <PaymentSection2
        v-if="currentStep === 2 && customer && !isLoading"
        :customer="customer"
        :on-data-changed="stepDataChanged"
        :on-proceed="onButtonOk" />

      <PaymentSection3
        v-if="currentStep === 3 && customer && !isLoading"
        :customer="customer"
        :on-data-changed="stepDataChanged"
        :on-date-helper-clicked="onDateExpireHelperClicked"
        :on-cvv-helper-clicked="onCvvHelperClicked" />

    </div>

    <PaymentResumeSection
      v-if="currentStep === 4 && customer"
      :customer="customer"
      :is-loading="isLoadingPurchase"
      :on-confirm="onPurchaseConfirm"
      class="resume" />

    <ModalDateHelper
      v-if="expireDateModalShowing"
      @closed="onModalHelperClosed" />

    <ModalCvvHelper
      v-if="cvvModalShowing"
      @closed="onModalHelperClosed" />

    <ModalCardTypeHelper
      v-if="cardModalShowing"
      @closed="onModalHelperClosed" />

    <ModalPaymentCardCreated
      v-if="cardCreatedModalShowing"
      :card="customer.card"
      @closed="onModalCardCreatedClosed" />

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />

    <AlertDestructive
      v-if="alertConfirmShowing"
      :title="alertConfirmTitle"
      :message="alertConfirmMessage"
      :btn-cancel="alertConfirmCancel"
      :btn-destructive="alertConfirmOk"
      @closed="onAlertConfirmDismissed"
      @cancel="onAlertConfirmDismissed"
      @ok="onAlertConfirmOk" />
  </div>
</template>

<script>
import NavbarPayment from '@/components/Nav/NavbarPayment.vue';
import StepsNavigation from '@/components/Payments/StepsNavigation.vue';
import PaymentSection1 from '@/components/Payments/PaymentSection_1.vue';
import PaymentSection2 from '@/components/Payments/PaymentSection_2.vue';
import PaymentSection3 from '@/components/Payments/PaymentSection_3.vue';
import PaymentResumeSection from '@/components/Payments/PaymentResumeSection.vue';
import ModalDateHelper from '@/components/Modals/ModalPaymentDateHelper.vue';
import ModalCvvHelper from '@/components/Modals/ModalPaymentCvvHelper.vue';
import ModalCardTypeHelper from '@/components/Modals/ModalPaymentCardHelper.vue';
import ModalPaymentCardCreated from '@/components/Modals/ModalPaymentCardCreated.vue';

import Alert from '@/components/Alerts/BaseAlert.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';

import Error from '@/graphql/error';

//import formatter from '@/utils/formatter.js';

const STEP_BASIC_DETAILS = 1;
const STEP_ADDRESS = 2;
const STEP_PAYMENT_CARD = 3;
const STEP_PAYMENT_CONFIRMATION = 4;

export default {
  name: 'PaymentClientDetails',

  components: {
    NavbarPayment,
    StepsNavigation,
    PaymentSection1,
    PaymentSection2,
    PaymentSection3,
    PaymentResumeSection,
    ModalDateHelper,
    ModalCvvHelper,
    ModalCardTypeHelper,
    ModalPaymentCardCreated,
    Alert,
    AlertDestructive,
  },

  // BeforRouteLeave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      if (this.isLoadingStep) {
        next(false);
        return;
      }
      if (this.alertShowing || this.alertConfirmShowing) {
        next(false);
        this.alertShowing = false;
        this.alertConfirmShowing = false;
        return;
      }
      if (this.showConfirmationOnBack) {
        next(false);
        const self = this;
        this.alertConfirm(
          this.$t('alerts.alert_payment_details_on_back_title'),
          this.$t('alerts.alert_payment_details_on_back_message'),
          this.$t('alerts.alert_payment_details_on_back_yes'),
          this.$t('alerts.alert_payment_details_on_back_no'),
          function () {
            self.showConfirmationOnBack = false;
            self.$router.go(-1);
          }
        );
        return;
      }
      if (this.currentStep > this.initialStep) {
        next(false);
        this.currentStep--;
        return;
      }
    }
    next();
  },

  // Properties ********************************
  props: {
  },

  // Data ********************************
  data() {
    return {
      isLoading: false,
      isLoadingStep: false,
      isLoadingPurchase: false,
      initialStep: STEP_BASIC_DETAILS,
      currentStep: 0,
      showConfirmationOnBack: false,

      formComplete: false,
      expireDateModalShowing: false,
      cvvModalShowing: false,
      cardModalShowing: false,
      cardCreatedModalShowing: false,

      customer: null,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,
      alertCancelCallback: Function,

      alertConfirmShowing: false,
      alertConfirmTitle: null,
      alertConfirmMessage: null,
      alertConfirmOk: null,
      alertConfirmCancel: null,
    };
  },

  // Computed ********************************
  computed: {
    showNavbarBack() {
      return this.$store.getters.isExternalSource === true;
      //return true;
    },
    navbarTitle() {
      if (this.currentStep === STEP_PAYMENT_CONFIRMATION) {
        //return this.customer.billing.description;
        //return this.$t('title.payment_confirm');
        const auth = this.$store.getters.getAuth;
        if (auth && auth.business && auth.business.name) {
          return auth.business.name;
        }
      }
      return null;
    },
    navbarSubtitle() {
      /*if (this.currentStep === STEP_PAYMENT_CONFIRMATION) {
        const price = formatter.formatPrice(this.customer.billing.value);
        return this.$t('title.payment_total', { value: price });
      }*/
      return null;
    },
  },

  watch: {
    currentStep(newValue/*, oldValue*/) {
      //console.log(`step: ${newValue} | before: ${oldValue}`);
      if (newValue === STEP_PAYMENT_CARD) {
        this.requestCheckCustomerForPayment();
      } else if (newValue === STEP_PAYMENT_CONFIRMATION) {
        this.requestAuthorizePayment();
      }

      if (newValue === STEP_BASIC_DETAILS) {
        this.$analytics.track('Visit: Payment Basic Info');
      } else if (newValue === STEP_ADDRESS) {
        this.$analytics.track('Visit: Payment Address');
      } else if (newValue === STEP_PAYMENT_CARD) {
        this.$analytics.track('Visit: Payment Card Details');
      } else if (newValue === STEP_PAYMENT_CONFIRMATION) {
        this.$analytics.track('Visit: Payment Confirmation');
      }

      if (newValue === STEP_PAYMENT_CONFIRMATION) {
        this.showConfirmationOnBack = false;
      }
    },
  },

  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      this.$router.push({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }

    this.customer = this.$store.getters.getCustomer;

    if (!this.customer) {
      this.$router.replace({ name: this.$constants.ROUTE_LOGIN }, () => {});
      return;
    }
    this.requestCurrent();
  },

  // Methods ********************************
  methods: {
    requestCurrent() {
      const self = this;
      this.isLoading = true;
      this.$root.loading = true;
      this.$graphql.getCurrentCustomer(true, function (responseCustomer) {
        self.customer.name = responseCustomer.name;
        self.customer.email = responseCustomer.email;
        self.customer.taxNumber = responseCustomer.taxNumber;

        if (responseCustomer.addresses.length > 0) {
          const address = responseCustomer.getPreferedAddress();
          self.customer.address.update(address);
        }

        if (responseCustomer.cards.length > 0) {
          const card = responseCustomer.getPreferedCard();
          if (!self.customer.card.uid || self.customer.card.uid === card.uid) {
            self.customer.card.update(card);
          }
        }

        const auth = self.$store.getters.getAuth;
        auth.updateCurrentClient(responseCustomer);
        self.$store.dispatch('updateAuth', auth);

        self.showConfirmationOnBack = true;

        if (responseCustomer.hasCard()) {
          self.initialStep = STEP_PAYMENT_CONFIRMATION;
          self.currentStep = STEP_PAYMENT_CONFIRMATION;
          self.showConfirmationOnBack = false;
        } else if (responseCustomer.hasAddress()) {
          self.initialStep = STEP_PAYMENT_CARD;
          self.currentStep = STEP_PAYMENT_CARD;
        } else if (responseCustomer.hasPaymentBasic()) {
          self.initialStep = STEP_ADDRESS;
          self.currentStep = STEP_ADDRESS;
          self.$root.loading = false;
        } else {
          self.initialStep = STEP_BASIC_DETAILS;
          self.currentStep = STEP_BASIC_DETAILS;
          self.$root.loading = false;
        }

        self.$root.loading = false;
        self.isLoading = false;
      }, function () {
        self.$root.loading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
          self.$router.go(-1);
          self.isLoading = false;
        });
        self.$analytics.track('Error: Current Customer');
      });
    },
    onButtonOk() {
      if (this.formComplete === false) return;

      if (this.currentStep === STEP_BASIC_DETAILS) {
        this.requestUpdateBasicInfo();
      } else if (this.currentStep === STEP_ADDRESS) {
        this.requestUpdateAddress();
      } else if (this.currentStep === STEP_PAYMENT_CARD) {
        //this.nextStep();
        if (!this.customer.card.brand) {
          this.onCardHelperClicked();
        } else {
          this.requestEncryptedKey();
        }
      }
    },
    stepDataChanged(complete) {
      this.formComplete = complete;
    },

    onDateExpireHelperClicked() {
      this.expireDateModalShowing = true;
    },
    onCvvHelperClicked() {
      this.cvvModalShowing = true;
    },
    onCardHelperClicked() {
      this.cardModalShowing = true;
    },
    onModalHelperClosed() {
      this.expireDateModalShowing = false;
      this.cvvModalShowing = false;
      this.cardModalShowing = false;
    },

    showModalCardCreated() {
      this.cardCreatedModalShowing = true;
    },
    onModalCardCreatedClosed() {
      this.cardCreatedModalShowing = false;
      this.nextStep();
      this.initialStep = STEP_PAYMENT_CONFIRMATION;
    },


    nextStep() {
      this.formComplete = false;
      this.isLoadingStep = false;
      this.currentStep++;
      this.showConfirmationOnBack = true;
    },


    requestUpdateBasicInfo() {
      this.isLoadingStep = true;
      const self = this;
      this.$graphql.updateCustomerBasicInfo(this.customer, function () {
        self.nextStep();
        self.initialStep = STEP_ADDRESS;
      }, function () {
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Update Basic Info');
      });
    },


    requestUpdateAddress() {
      this.isLoadingStep = true;
      const self = this;
      this.$graphql.updateCustomerAddress(this.customer.address, function () {
        self.nextStep();
        self.initialStep = STEP_PAYMENT_CARD;
      }, function () {
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Update Address');
      });
    },


    requestCheckCustomerForPayment() {
      this.$root.loading = true;
      this.isLoadingStep = true;
      const self = this;
      this.$graphql.getCheckCustomerForPayment('credit_card', function () {
        self.$root.loading = false;
        self.isLoadingStep = false;
      }, function () {
        self.$root.loading = false;
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Check Customer For Payment');
      });
    },

    requestAuthorizePayment() {
      this.$root.loading = true;
      this.isLoadingStep = true;

      const type = 'bundle';
      const code = this.customer.voucher.id;
      const quantity = 1;
      const self = this;
      this.$graphql.getAuthorizePayment(type, code, quantity, function (data) {
        self.customer.billing.reference = data.reference;
        self.customer.billing.hash = data.hash;
        self.$root.loading = false;
        self.isLoadingStep = false;

        if (self.customer.card && self.customer.card.status.toLowerCase() !== 'active') {
          const auth = self.$store.getters.getAuth;
          if (auth.getClientActiveCards().length > 0) {
            self.alertConfirm(self.$t('alerts.alert_payment_card_inactive_title2'),
              self.$t('alerts.alert_payment_card_inactive_message2'),
              self.$t('alerts.alert_payment_card_inactive_ok2'),
              self.$t('alerts.alert_payment_card_inactive_cancel2'), function () {
                self.$router.push({
                  name: self.$constants.ROUTE_ACCOUNT_CARDS,
                  params: {
                    showCardList: true,
                    showNewCard: false,
                    customer: self.customer,
                  },
                }, () => {});
                self.isLoading = false;
              }, function () {
                self.$router.go(-1);
                self.isLoading = false;
              });
          } else {
            self.alertConfirm(self.$t('alerts.alert_payment_card_inactive_title'),
              self.$t('alerts.alert_payment_card_inactive_message'),
              self.$t('alerts.alert_payment_card_inactive_ok'),
              self.$t('alerts.alert_payment_card_inactive_cancel'), function () {
                self.$router.push({
                  name: self.$constants.ROUTE_ACCOUNT_CARDS,
                  params: {
                    showCardList: true,
                    showNewCard: true,
                    customer: self.customer,
                  },
                }, () => {});
                self.isLoading = false;
              }, function () {
                self.$router.go(-1);
                self.isLoading = false;
              });
          }
        }
      }, function () {
        self.$root.loading = false;
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Authorize Payment');
      });
    },

    requestRevalidateAuthorization(purchaseAgain, attempt) {
      const hash = this.customer.billing.hash;
      const self = this;
      this.isLoadingStep = false;
      this.$graphql.getRevalidateAuthorization(hash, function (data) {
        self.customer.billing.reference = data.reference;
        self.customer.billing.hash = data.hash;
        if (purchaseAgain) {
          self.onPurchaseConfirm(attempt + 1);
        } else {
          self.$root.loading = false;
          self.isLoadingStep = false;
          self.isLoadingPurchase = false;
        }
      }, function () {
        self.$root.loading = false;
        self.isLoadingStep = false;
        self.isLoadingPurchase = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
          self.$router.go(-1);
        });
        self.$analytics.track('Error: Revalidate Authorization');
      });
    },


    requestEncryptedKey() {
      this.isLoadingStep = true;
      const self = this;
      this.$graphql.getCardEncryptionKey(function (encryptionObj) {
        self.requestUpdateCard(encryptionObj);
      }, function () {
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Encryption Key');
      });
    },


    requestUpdateCard(encryptionObj) {
      const cardHash = this.customer.card.getCardEncrypted(encryptionObj.key, encryptionObj.id);
      const self = this;
      this.$graphql.createCustomerCard(cardHash, function () {
        //self.nextStep();
        //self.$root.loading = false;
        self.updateCustomer(true);
      }, function () {
        self.isLoadingStep = false;
        //self.$root.loading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Create Card');
      });
    },


    updateCustomer(showCardCreatedSuccess) {
      const self = this;
      this.$graphql.getCurrentCustomer(true, function (responseCustomer) {
        self.customer.name = responseCustomer.name;
        self.customer.email = responseCustomer.email;
        self.customer.taxNumber = responseCustomer.taxNumber;

        if (responseCustomer.addresses.length > 0) {
          const address = responseCustomer.addresses[0];
          self.customer.address.update(address);
        }

        if (responseCustomer.cards.length > 0) {
          const card = responseCustomer.cards[0];
          self.customer.card.update(card);
        }

        const auth = self.$store.getters.getAuth;
        auth.updateCurrentClient(responseCustomer);
        self.$store.dispatch('updateAuth', auth);

        if (showCardCreatedSuccess === true) {
          self.showModalCardCreated();
        } else {
          self.nextStep();
        }
      }, function () {
        self.isLoadingStep = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Current Customer');
      });
    },

    requestReauthorizePayment(attempt) {
      const type = 'bundle';
      const code = this.customer.voucher.id;
      const quantity = 1;
      const self = this;
      this.$graphql.getAuthorizePayment(type, code, quantity, function (data) {
        self.customer.billing.reference = data.reference;
        self.customer.billing.hash = data.hash;
        self.onPurchaseConfirm(attempt + 1);
      }, function () {
        self.$root.loading = false;
        self.isLoadingStep = false;
        self.isLoadingPurchase = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
          self.$router.go(-1);
        });
        self.$analytics.track('Error: Reauthorize Payment');
      });
    },

    onPurchaseConfirm(attempt) {
      const hash = this.customer.billing.hash;
      const card = this.customer.card;

      //this.$root.loading = true;
      this.isLoadingStep = true;
      this.isLoadingPurchase = true;
      const self = this;
      this.$graphql.buy(hash, 'credit_card', card.uid, function (obj) {
        let success = false;
        if (obj.status && obj.status.toLowerCase() === 'paid') {
          success = true;
        }
        self.$analytics.track('Purchase Status', {
          purchaseSuccess: success,
          status: obj.status,
          statusReason: obj.statusReason,
          refuseReason: obj.refuseReason,
        });

        if (success) {
          self.customer.voucher.userValue = obj.amount;
          self.$router.replace({
            name: self.$constants.ROUTE_SUCCESS,
            params: { customer: self.customer },
          }, () => {});
        } else if (obj.refuseReason
          && ['internal_error', 'acquirer_timeout', 'erro_interno_iloveme'].includes(obj.refuseReason.toLowerCase())
          && (!attempt || attempt < 1)) {
          // OCORREU DE NOVO, TENTAR NOVAMENTE
          self.requestRevalidateAuthorization(true, attempt || 0);
        } else if (obj.refuseReason
          && ['antifraud', 'no_acquirer'].includes(obj.refuseReason.toLowerCase())) {
          // antifraud -> ANTIFRAUDE, O CARTÃO NUNCA MAIS PDOE SER USADO
          // no_acquirer -> NENHUM ADQUIRENTE SABE TRATAR DESTE CARTÃO, NAO PODE USAR NOVAMENTE
          self.alert(self.$t('errors.payment_title'), self.$t('errors.payment_antifraud'), function () {
            self.requestCurrent();
          });
          self.isLoadingStep = false;
          self.isLoadingPurchase = false;
        } else if (obj.refuseReason
          && ['acquirer'].includes(obj.refuseReason.toLowerCase())) {
          // acquirer -> O adquirente REJEITOU POR ALGUM MOTIVO, saldo? cartão cancelado?
          self.alert(self.$t('errors.payment_title'), self.$t('errors.payment_aquire'), function () {
            self.requestRevalidateAuthorization(false);
          });
          self.isLoadingStep = false;
          self.isLoadingPurchase = false;
        } else {
          self.alert(self.$t('errors.payment_title'), self.$t('errors.payment_message'), function () {
            self.$router.go(-1);
          });
          self.isLoadingStep = false;
          self.isLoadingPurchase = false;
        }
      }, function (error) {
        self.$analytics.track('Purchase Status', { purchaseSuccess: false, error_code: error.code });

        const errorCode = error.getCode();
        if (errorCode === Error.purchaseHashExpired
          && (!attempt || attempt < 1)) {
          self.requestReauthorizePayment(attempt || 0);
        } else if (errorCode === Error.purchaseCreateTransaction
          || errorCode === Error.purchaseCaptureTransaction) {
          self.isLoadingPurchase = false;
          self.isLoadingStep = false;
          self.alert(self.$t('errors.generic_title'), self.$t('errors.payment_invalid_card_message'), function () {
            self.$router.go(-1);
          });
        } else {
          self.isLoadingPurchase = false;
          self.isLoadingStep = false;
          self.alert(self.$t('errors.payment_generic_title'), self.$t('errors.payment_generic_message'), function () {
            self.$router.go(-1);
          });
        }
        /*
        payments.provider.create_transaction -> mensagem que nao foi removido $
        payments.provider.capture_transaction -> // //
        payments.provider.capture_transaction_no_refund -> mensagem generica
        outro erro -> mensagem generica
         */
      });
    },


    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    alertConfirm(title, message, buttonOk, buttonCancel, callback, cancelCallback) {
      this.alertConfirmTitle = title;
      this.alertConfirmMessage = message;
      this.alertConfirmCancel = buttonCancel;
      this.alertConfirmOk = buttonOk;
      this.alertConfirmShowing = true;
      this.alertCallback = callback;
      this.alertCancelCallback = cancelCallback;
    },
    onAlertConfirmOk() {
      this.alertConfirmShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },
    onAlertConfirmDismissed() {
      this.alertConfirmShowing = false;
      this.alertCallback = null;
      const callback = this.alertCancelCallback;
      if (callback) {
        this.alertCancelCallback = null;
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-root {
  position: relative;
  background: white;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.payment {
  padding: 10px $padding-section;
  flex: 0 0 auto;
}

.resume {
  flex: 1 1 auto;
}
</style>
