import Professional from './professional';
import { stripDiacritics } from '../utils/matcher';

class ProfessionalList {
  constructor(data) {
    this.professionals = [];
    if (data) {
      data.forEach(element => this.professionals.push(new Professional(element)));
    }
  }

  getItems() {
    return this.professionals.sort(this.orderProfessional);
  }

  getProfessionals(serviceId) {
    const list = this.getItems();
    const result = list.filter(p => p.hasService(serviceId) === true);
    return result;
  }

  orderProfessional = function compare(a, b) {
    const first = stripDiacritics(a.name);
    const second = stripDiacritics(b.name);
    if (first < second) {
      return -1;
    } if (first > second) {
      return 1;
    }
    return 0;
  }

  hasProfessional(professionalId) {
    return this.professionals.filter(x => x.id === `${professionalId}`).length > 0;
  }

  print() {
    this.professionals.forEach(element => console.log(element.name));
  }
}

export default ProfessionalList;
