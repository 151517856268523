
<template>
  <div
    :class="mainClass">

    <h2
      v-if="showHeader"
      :class="`${mainClass}__header`">
      {{ headerText }}
    </h2>

    <AppointmentServiceRow
      v-for="(item, index) in appointments"
      :key="index"
      :service="item.service"
      :index="index"
      :is-single-child="appointments.length === 1"
      :on-item-deleted="handleItemDeleted"
      :class="`${mainClass}__section`" />

  </div>
</template>


<script>
import AppointmentServiceRow from '@/components/Lists/AppointmentServices/AppointmentServiceRow.vue';

export default {
  name: 'AppointmentServiceList',

  // Components ********************************
  components: {
    AppointmentServiceRow,
  },

  // Properties ********************************
  props: {
    appointments: {
      type: Array,
      required: true,
    },
    onItemDeleted: Function,
  },

  // Computed ********************************
  computed: {
    mainClass() {
      return 'appointment-service-list';
    },
    showHeader() {
      return this.$root.isDesktop === false;
    },
    headerText() {
      return this.$tc('headers.services_counter', this.appointments.length, { count: this.appointments.length });
    },
  },

  // Methods ********************************
  methods: {
    handleItemDeleted(index) {
      if (!this.onItemDeleted) return;
      this.onItemDeleted(index);
    },
  },
};
</script>


<style lang="scss" scoped>

.appointment-service-list {

  &__header {
    padding-left: $padding-section + 4px;
    padding-right: $padding-section;
    padding-top: 10px;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 18px;
    color: $theme-2-color;
  }

  &__section {
    margin: $padding-section;
    margin-top: $padding-section;
  }

  &__header + &__section {
    margin-top: 6px;
  }

  &__section + &__section {
    margin-top: 0;
  }
}


</style>
