/* eslint-disable guard-for-in */
/* eslint-disable no-use-before-define */
import Cookies from 'js-cookie';
import templates from './templates';

import gdprCookieNoticeLocales from './langs/index';

// Load locales

export default function gdprCookieNotice(config) {
  const namespace = 'gdprcookienotice';
  const pluginPrefix = 'gdpr-cookie-notice';
  const gdprCookies = Cookies;
  let modalLoaded = false;
  let noticeLoaded = false;
  let cookiesAccepted = false;
  const categories = ['performance', 'analytics', 'marketing'];

  // Default config options
  if (!config.locale) config.locale = 'en';
  if (!config.timeout) config.timeout = 500;
  if (!config.domain) config.domain = null;
  if (!config.expiration) config.expiration = 30;

  // Use 'en' locale if current locale doesn't exist
  if (typeof gdprCookieNoticeLocales[config.locale] === 'undefined') {
    config.locale = 'en';
  }

  // Get the users current cookie selection
  const currentCookieSelection = getCookie();
  let cookiesAcceptedEvent = new CustomEvent('gdprCookiesEnabled', { detail: currentCookieSelection });

  // Show cookie bar if needed
  if (!currentCookieSelection) {
    showNotice();

    // Accept cookies on page scroll
    if (config.implicit) {
      acceptOnScroll();
    }
  } else {
    deleteCookies(currentCookieSelection);
    document.dispatchEvent(cookiesAcceptedEvent);
  }

  // Get gdpr cookie notice stored value
  function getCookie() {
    return gdprCookies.getJSON(namespace);
  }

  // Delete cookies if needed
  function deleteCookies(savedCookies) {
    let notAllEnabled = false;
    for (let i = 0; i < categories.length; i++) {
      if (config[categories[i]] && !savedCookies[categories[i]]) {
        for (let ii = 0; ii < config[categories[i]].length; ii++) {
          notAllEnabled = true;
        }
      }
    }

    // Show the notice if not all categories are enabled
    if (notAllEnabled) {
      //showNotice();
    } else {
      hideNotice();
    }
  }

  // Hide cookie notice bar
  function hideNotice() {
    document.documentElement.classList.remove(`${pluginPrefix}-loaded`);

    setTimeout(function () {
      document.documentElement.classList.add(`${pluginPrefix}-removed`);
    }, 500);
  }

  // Write gdpr cookie notice's cookies when user accepts cookies
  function acceptCookies(save) {
    const value = {
      date: new Date(),
      necessary: true,
      performance: true,
      analytics: true,
      marketing: true,
      expires: config.expiration,
    };

    // If request was coming from the modal, check for the settings
    if (save) {
      for (let i = 0; i < categories.length; i++) {
        const el = document.getElementById(`${pluginPrefix}-cookie_${categories[i]}`);
        value[categories[i]] = el && el.checked;
      }
    }
    gdprCookies.set(namespace, value, { expires: config.expiration, domain: config.domain });
    deleteCookies(value);

    // Load marketing scripts that only works when cookies are accepted
    cookiesAcceptedEvent = new CustomEvent('gdprCookiesEnabled', { detail: value });
    document.dispatchEvent(cookiesAcceptedEvent);

    hideModal();
    hideNotice();
  }

  // Show the cookie bar
  function buildNotice() {
    if (noticeLoaded) {
      return false;
    }

    const noticeHtml = localizeTemplate('bar.html');
    document.body.insertAdjacentHTML('beforeend', noticeHtml);

    // Load click functions
    setNoticeEventListeners();

    // Make sure its only loaded once
    noticeLoaded = true;

    return true;
  }

  // Show the cookie notice
  function showNotice() {
    buildNotice();

    // Show the notice with a little timeout
    setTimeout(function () {
      document.documentElement.classList.add(`${pluginPrefix}-loaded`);
    }, config.timeout);
  }

  // Localize templates
  function localizeTemplate(template, prefix) {
    const str = templates[template];
    const data = gdprCookieNoticeLocales[config.locale];

    if (prefix) {
      prefix = `${prefix}_`;
    } else {
      prefix = '';
    }

    if (typeof str === 'string' && (data instanceof Object)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        // eslint-disable-next-line guard-for-in
        return str.replace(/({([^}]+)})/g, function (i) {
          const key = i.replace(/{/, '').replace(/}/, '');

          if (key === 'prefix') {
            return prefix.slice(0, -1);
          }

          if (data[key]) {
            return data[key];
          } if (data[prefix + key]) {
            return data[prefix + key];
          }
          return i;
        });
      }
    }
    return '';
  }

  // Build modal window
  function buildModal() {
    if (modalLoaded) {
      return false;
    }

    // Load modal template
    const modalHtml = localizeTemplate('modal.html');

    // Append modal into body
    document.body.insertAdjacentHTML('beforeend', modalHtml);

    // Get empty category list
    const categoryList = document.querySelector(`.${pluginPrefix}-modal-cookies`);

    //Load essential cookies
    categoryList.innerHTML += localizeTemplate('category.html', 'cookie_essential');
    const input = document.querySelector(`.${pluginPrefix}-modal-cookie-input`);
    const label = document.querySelector(`.${pluginPrefix}-modal-cookie-input-switch`);
    label.innerHTML = gdprCookieNoticeLocales[config.locale].always_on;
    label.classList.add(`${pluginPrefix}-modal-cookie-state`);
    label.classList.remove(`${pluginPrefix}-modal-cookie-input-switch`);
    input.remove();

    // Load other categories if needed
    categories.forEach(ctg => {
      if (!config[ctg]) return;
      categoryList.innerHTML += localizeTemplate('category.html', `cookie_${ctg}`);
      if (currentCookieSelection) {
        // Update checkboxes based on stored info(if any)
        document.getElementById(`${pluginPrefix}-cookie_${ctg}`).checked = currentCookieSelection[ctg];
      }
    });

    // Load click functions
    setModalEventListeners();

    // Make sure modal is only loaded once
    modalLoaded = true;
    return true;
  }

  // Show modal window
  function showModal() {
    buildModal();
    document.documentElement.classList.add(`${pluginPrefix}-show-modal`);
  }

  // Hide modal window
  function hideModal() {
    document.documentElement.classList.remove(`${pluginPrefix}-show-modal`);
  }

  // Click functions in the notice
  function setNoticeEventListeners() {
    const settingsButton = document.querySelectorAll(`.${pluginPrefix}-nav-item-settings`)[0];
    const acceptButton = document.querySelectorAll(`.${pluginPrefix}-nav-item-accept`)[0];

    settingsButton.addEventListener('click', function (e) {
      e.preventDefault();
      showModal();
    });

    acceptButton.addEventListener('click', function (e) {
      e.preventDefault();
      acceptCookies();
    });
  }

  // Click functions in the modal
  function setModalEventListeners() {
    const closeButton = document.querySelectorAll(`.${pluginPrefix}-modal-close`)[0];
    const statementButton = document.querySelectorAll(`.${pluginPrefix}-modal-footer-item-statement`)[0];
    const categoryTitles = document.querySelectorAll(`.${pluginPrefix}-modal-cookie-title`);
    const saveButton = document.querySelectorAll(`.${pluginPrefix}-modal-footer-item-save`)[0];

    closeButton.addEventListener('click', function () {
      hideModal();
      return false;
    });

    statementButton.addEventListener('click', function (e) {
      e.preventDefault();
      //window.location.href = config.statement;
      window.open(
        config.statement,
        '_blank'
      );
    });

    for (let i = 0; i < categoryTitles.length; i++) {
      categoryTitles[i].addEventListener('click', function () {
        this.parentNode.parentNode.classList.toggle('open');
        return false;
      });
    }

    saveButton.addEventListener('click', function (e) {
      e.preventDefault();
      saveButton.classList.add('saved');
      setTimeout(function () {
        saveButton.classList.remove('saved');
        acceptCookies(true);
      }, 1000);
    });
  }

  // Settings button on the page somewhere
  const globalSettingsButton = document.querySelectorAll(`.${pluginPrefix}-settings-button`);
  if (globalSettingsButton) {
    for (let i = 0; i < globalSettingsButton.length; i++) {
      globalSettingsButton[i].addEventListener('click', function (e) {
        e.preventDefault();
        showModal();
      });
    }
  }


  // Get document height
  function getDocHeight() {
    const D = document;
    return Math.max(
      D.body.scrollHeight, D.documentElement.scrollHeight,
      D.body.offsetHeight, D.documentElement.offsetHeight,
      D.body.clientHeight, D.documentElement.clientHeight
    );
  }

  // Check if at least page is 25% scrolled down
  function amountScrolled() {
    const winheight = window.innerHeight
    || (document.documentElement || document.body).clientHeight;
    const docheight = getDocHeight();
    const scrollTop = window.pageYOffset
    || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const trackLength = docheight - winheight;
    // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
    const pctScrolled = Math.floor((scrollTop / trackLength) * 100);
    if (pctScrolled > 25 && !cookiesAccepted) {
      cookiesAccepted = true;
      return true;
    }
    return false;
  }

  // Accept cookies on scroll
  function acceptOnScroll() {
    window.addEventListener('scroll', function _listener() {
      if (amountScrolled()) {
        acceptCookies();
        window.removeEventListener('click', _listener);
      }
    });
  }
}
