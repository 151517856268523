<template>
  <div
    :class="iconContainerClasses"
    class="icon_validation">
    <!--img
      src="@/assets/images/validate-phone/background.png"
      class="icon_validation__background" -->
    <!--IlmDynamicSvg
      :icon-path="mainIcon"
      class="icon_validation__icon" /-->
    <img
      v-show="initialized && step === 0"
      src="@/assets/images/validate-phone/cell.svg"
      class="icon_validation__icon">
    <img
      v-show="initialized && step === 1"
      src="@/assets/images/validate-phone/lock.svg"
      class="icon_validation__icon">
    <img
      v-show="initialized && step === 2 && !isAnimatingStep"
      src="@/assets/images/validate-phone/smiley-01.svg"
      class="icon_validation__icon">
    <img
      v-show="initialized && step === 2 && isAnimatingStep"
      src="@/assets/images/validate-phone/smiley-02.svg"
      class="icon_validation__icon">
    <img
      src="@/assets/images/validate-phone/check.svg"
      class="icon_validation__icon--check">
  </div>
</template>


<script>

export default {
  name: 'IlmIconValidation',

  components: {
  },

  // Properties ********************************
  props: {
    step: {
      type: Number,
      default: 0,
    },
    isAnimatingTransition: {
      type: Boolean,
      default: false,
    },
    animateBeforeDuration: {
      type: Number,
      default: 1200,
    },
  },

  // Data ********************************
  data() {
    return {
      initialized: false,
      currentStep: this.step,
      isAnimatingStep: false,
      isIconVisible: false,
    };
  },


  // Computed ********************************
  computed: {
    mainIcon() {
      let name = 'smiley-01';
      if (this.step === 0) {
        name = 'cell';
      }
      if (this.step === 1) {
        name = 'lock';
      }
      if (this.step === 2 && this.isAnimatingStep) {
        name = 'smiley-02';
      }
      return `@/assets/images/validate-phone/${name}.svg`;
    },
    iconContainerClasses() {
      return {
        'icon_validation--animate': this.isAnimatingStep,
        'icon_validation--animate-final': this.isAnimatingStep && this.step === 2,
        'icon_validation--visible-initial': this.isIconVisible && this.step === 0,
        'icon_validation--visible': this.isIconVisible,
      };
    },
  },

  // Watchers ********************************
  watch: {
    isAnimatingTransition(val) {
      this.isAnimatingStep = val;
    },
    step(newValue) {
      this.currentStep = newValue;
    },
    currentStep(newValue, oldValue) {
      if (newValue > oldValue) {
        // animate
        this.isIconVisible = true;
        this.isAnimatingStep = false;
        setTimeout(() => {
          this.isIconVisible = true;
        }, 300);
      }
    },
  },

  mounted() {
    this.isIconVisible = false;

    this.$nextTick(() => {
      this.isIconVisible = true;
      this.initialized = true;
    });
  },
};
</script>


<style lang="scss" scoped>
@keyframes popout {
  from {transform:scale(0.4)}
  75% {transform:scale(1.5)}
  to {transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0.4)}
  to {transform:scale(1)}
}

@keyframes fade_in_out {
  from {opacity: 0;}
  45% {opacity: 0.2;}
  60% {opacity: 0.2;}
  to {opacity: 0;}
}

@keyframes slide_up {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform:translateY(0);
    opacity: 1;
  }
}

.icon_validation {

  &__icon {
    width: 100%;
    height: auto;
    position: absolute;
    transition: none;
    opacity: 0;

    &--check {
      //opacity: 0;
      width: 100%;
      height: auto;
      position: absolute;
      transition: none;
    }
  }

  &__background {
    width: 100%;
    height: auto;
    position: absolute;
    top: -4px;
    opacity: 0;
  }
  &--visible ::v-deep &__background {
    transition: opacity 1s ease-in 0.6s;
    transform: scale(1.9, 1.9);
    animation: fade_in_out 3.8s linear 0.6s;
    z-index: 20;
    //opacity: 0.15;
  }

  &--visible ::v-deep &__icon {
    //transition: opacity 4.2s ease-in 0.3s;
    //animation: zoom 0.8s ease-in;
    animation: slide_up 1s ease-out;
    opacity: 1;

    &--check {
      transition: none;
      opacity: 0;
    }
  }
  &--visible-initial ::v-deep &__icon {
    /*transition: opacity 1s ease-in;
    animation: slide_up 1s ease-out;
    opacity: 1;*/
    margin-left: 4px;

    &--check {
      transition: none;
      opacity: 0;
    }
  }

  &--animate ::v-deep &__background {
    transition: none;
    opacity: 0;
    animation-name: none;
  }

  &--animate ::v-deep &__icon {
    //display: none;
    transition: none;
    animation-name: none;
    opacity: 0;

    &--check {
      //display: initial;
      transition: opacity 0.3s ease-in;
      animation: popout 0.5s ease;
      opacity: 1;
    }
  }
  &--animate-final ::v-deep &__icon {
    opacity: 1;
    transition: transform .4s ease-in-out;
    transform: rotate3d(0, 1, 0, 720deg);

    &--check {
      //display: initial;
      transition: none;
      animation: none;
      opacity: 0;
    }
  }

}
</style>
