
<template>
  <div :class="componentClass">
    <div
      v-if="businessUnavailable"
      :class="`${mainClass}__unavailable`">
      <IlmIcon
        :class="`${mainClass}__unavailable__icon`"
        icon="do_not_disturb_alt" />
      <p
        :class="`${mainClass}__unavailable__text`"
        v-html="$t('notes.business_not_available')" />

    </div>
    <div
      v-else-if="slotsUnavailable && !isLoading"
      :class="`${mainClass}__unavailable`">
      <IlmIcon
        :class="`${mainClass}__unavailable__icon`"
        icon="watch_later" />

      <div v-if="appointments[0].professional">
        <p
          :class="`${mainClass}__unavailable__title`"
          v-html="unavailableProfessionalMessageTitle" />
        <div
          v-if="nextAvailableDay && professionalNextAvailabilityText">
          <p
            :class="`${mainClass}__unavailable__text`"
            v-html="professionalNextAvailabilityText" />
          <IlmButton
            :on-click="onDateChangeClick"
            :label="professionalNextAvailabilityButtonTitle"
            prevent-default
            theme="block success"
            :class="`${mainClass}__unavailable__change_date_button`" />
        </div>
        <IlmButton
          :on-click="onProfessionalChangeClick"
          t-key="button.change_professional"
          prevent-default
          theme="block success-inverted"
          :class="`${mainClass}__unavailable__change_professional_button`" />
      </div>
      <p
        v-else
        :class="`${mainClass}__unavailable__text`"
        v-html="$t('notes.slots_unavailable')" />
    </div>

    <span
      v-else-if="appointments && appointments.length > 1"
      :class="`${mainClass}__header`">
      {{ $tc('section_list.appointments_slots_selection', appointments.length) }}
    </span>

    <Sticky
      v-if="!dayUnavailable"
      :z-index="100"
      :distance="60"
      :on-stick-update="onStickUpdate">

      <div
        ref="professionals_header"
        :class="professionalContainerClasses">
        <div
          v-if="appointments && appointments.length > 1"
          :style="gridTemplateColumnsStyle"
          :class="`${mainClass}__slot_columns`">

          <div
            v-for="(item, index) in appointments"
            :key="`service_${index}`"
            :class="`${mainClass}__slot_columns__column`">

            <span
              :class="columnServiceClasses">
              {{ item.service.name }}
            </span>

          </div>

          <div
            v-for="(item, index) in appointments"
            :key="`professional_${index}`"
            :class="`${mainClass}__slot_columns__column`">

            <MultipleServiceGridProfessional
              :professional="item.professional"
              :index="index"
              :on-item-clicked="professionalClicked" />

          </div>

        </div>
        <div
          v-else-if="appointments && appointments.length === 1"
          :style="gridTemplateColumnsStyle"
          :class="`${mainClass}__slot_columns`">

          <div>
            <p
              :class="`${mainClass}__serviceName`"
              v-html="appointments[0].service.name" />
          </div>

          <SingleServiceGridProfessional
            :professional="appointments[0].professional"
            :service="appointments[0].service"
            :on-item-clicked="professionalClicked" />

        </div>
      </div>
    </Sticky>

    <div
      :class="`${mainClass}__loader_container`">
      <LoadingSpinner
        v-if="isLoading"
        :class="`${mainClass}__loader_container__loader`" />
    </div>

    <div
      v-if="appointments && appointments.length > 1 && !dayUnavailable"
      :class="`${mainClass}__container`">
      <div
        :style="gridTemplateColumnsStyle"
        :class="`${mainClass}__slot_columns`">

        <div
          v-for="(item, index) in appointments"
          :key="`slots_${index}`"
          :class="`${mainClass}__slot_columns__column`">

          <MultipleServiceGridSlots
            :ref="`column_${index}`"
            :is-loading="isLoading"
            :header-height="stickyHeaderHeight"
            :service="item.service"
            :services-size="appointments.length"
            :selected-date="dateSelected"
            :on-slot-selected="onSlotSelected"
            :on-slot-tooltip-selected="scrollToPosition" />

        </div>

      </div>

    </div>

    <div
      v-if="appointments && appointments.length === 1 && !dayUnavailable">
      <!--FormDateConfirmation
        v-if="singleServiceSelectedSlot"
        :element="getScheduleSection()"
        :is-loading="isLoading"
        :on-item-selected="onScheduleSectionSelected"
        class="new-booking__content__section"/-->
      <ScheduleGrid
        ref="schedule_grid"
        :is-loading="isLoading"
        :service="appointments[0].service"
        :selected-date="dateSelected"
        :on-item-selected="onSingleServiceSlotSelected" />
    </div>
  </div>
</template>


<script>
import MultipleServiceGridProfessional from '@/components/Slots/MultipleServiceGridProfessional.vue';
import SingleServiceGridProfessional from '@/components/Slots/SingleServiceGridProfessional.vue';
import MultipleServiceGridSlots from '@/components/Slots/MultipleServiceGridSlots.vue';
import ScheduleGrid from '@/components/Slots/ScheduleGrid.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import MultipleServiceAvailabilities from '@/models/multipleServiceAvailabilities.js';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import DateUtils from '@/utils/dateUtils';

//const MORNING = 13;
//const AFTERNOON = 17;


export default {
  name: 'MultipleServiceGrid',

  // Components ********************************
  components: {
    MultipleServiceGridProfessional,
    SingleServiceGridProfessional,
    MultipleServiceGridSlots,
    ScheduleGrid,
    LoadingSpinner,
    IlmIcon,
    IlmButton,
  },

  // Properties ********************************
  props: {
    business: Object,
    appointments: Array,
    editAppointment: Object,
    dateSelected: Object,

    navbarHeight: {
      type: Number,
      default: 0,
    },

    onDateChangeClicked: Function,
    onProfessionalClicked: Function,
    onHeaderStickUpdate: Function,
    onLoaderChanged: Function,
    onSlotSelectedChanged: Function,
    onScrollToSection: Function,
    onSlotsChanged: Function,
    onAlert: Function,
  },

  // Data ********************************
  data() {
    return {
      isLoading: false,
      businessUnavailableWeekDays: null,
      serviceProfessionals: {},
      singleServiceSelectedSlot: null,
      slotsUnavailable: false,
      nextAvailableDay: null,

      multipleServiceAvailabilities: null,
      selectedSlots: {},

      discounts: null,

      professionalsHeaderHeight: 0,
      scrollHeight: null,
      bodyHeight: 0,
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'multiple-service-grid';
    },
    componentClass() {
      const classes = {};
      classes[`${this.mainClass}`] = true;
      classes[`${this.mainClass}--unavailable`] = this.dayUnavailable;
      return classes;
    },
    professionalContainerClasses() {
      const classes = {};
      classes[`${this.mainClass}__container`] = true;
      classes[`${this.mainClass}__container--single_service`] = this.appointments.length === 1;
      return classes;
    },
    columnServiceClasses() {
      const classes = {};
      classes[`${this.mainClass}__slot_columns__column__service`] = true;
      classes[`${this.mainClass}__slot_columns__column__service--single`] = this.appointments.length === 1;
      return classes;
    },
    gridTemplateColumnsStyle() {
      if (!this.business || !this.appointments || this.appointments.length === 1) {
        return {};
      }
      let value = '';
      const columns = this.appointments.length;
      for (let i = 0; i < columns; i++) {
        value += '1fr';
        if (i < columns - 1) {
          value += ' ';
        }
      }
      return {
        'grid-template-columns': value,
      };
    },
    dayUnavailable() {
      return this.businessUnavailable || this.slotsUnavailable;
    },
    businessUnavailable() {
      const weekday = this.$moment(this.dateSelected).isoWeekday();
      return (this.businessUnavailableWeekDays !== null
        && this.businessUnavailableWeekDays.includes(weekday));
    },
    stickyHeaderHeight() {
      return this.professionalsHeaderHeight + this.navbarHeight;
    },
    unavailableProfessionalMessageTitle() {
      if (this.appointments.length !== 1) return '';
      const professionalName = this.appointments[0].professional?.name;
      return this.$t('notes.professional_not_available', { professional: professionalName });
    },
    professionalNextAvailabilityText() {
      if (this.appointments.length !== 1 || !this.nextAvailableDay) return '';
      const professionalName = this.appointments[0].professional?.name;
      return this.$t('notes.professional_available_on_date',
        { professional: professionalName, date: this.nextAvailableDay.format('D MMMM') });
    },
    professionalNextAvailabilityButtonTitle() {
      if (!this.nextAvailableDay) return '';
      return this.$t('button.goto_date', { date: this.nextAvailableDay.format('dddd D MMM') });
    },
  },

  // Watchers ********************************
  watch: {
    dateSelected() {
      this.$nextTick(() => {
        this.refresh();
      });
    },
  },

  // Created ********************************
  created() {
    this.businessUnavailableWeekDays = this.business.getUnavailableWeekdays();
    this.multipleServiceAvailabilities = new MultipleServiceAvailabilities(this.business);
    this.discounts = this.$store.getters.getDiscounts;
  },

  // Mounted ********************************
  mounted() {
    this.requestServicesProfessionals();

    this.$nextTick(() => {
      const header = this.$refs.professionals_header;
      if (header) {
        this.professionalsHeaderHeight = header.offsetHeight;
      }
      this.scrollHeight = document.body.scrollHeight;
      this.bodyHeight = document.documentElement.clientHeight;

      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleScroll);
    });
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  },

  methods: {
    isDaySlotsUnavailable() {
      if (this.multipleServiceAvailabilities.serviceSlots === null
        || Object.keys(this.multipleServiceAvailabilities.serviceSlots).length === 0) {
        return true;
      }
      if (Object.keys(this.multipleServiceAvailabilities.serviceSlots).length
        !== this.appointments.length) {
        return false;
      }
      if (this.appointments.length === 1) {
        let serviceId;
        for (let i = 0; i < this.appointments.length; i++) {
          serviceId = `${this.appointments[i].service.id}`;
          if (this.multipleServiceAvailabilities.serviceSlots[serviceId]
            .filter(item => item.available === true).length > 0) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    isDayUnavailable() {
      return this.slotsUnavailable || this.businessUnavailable;
    },

    refresh() {
      //this.selectedSlots = {};
      if (this.businessUnavailable) {
        if (this.onSlotsChanged) {
          this.onSlotsChanged();
        }
        return;
      }
      this.slotsUnavailable = false;
      //this.onSelectedSlotsChaged();
      this.requestAvailabilities();
    },

    onSelectedSlotsChaged(item) {
      if (this.onSlotSelectedChanged) {
        this.onSlotSelectedChanged(this.selectedSlots);
      }
      this.$nextTick(() => {
        if (item) {
          const endTime = item.endTime;
          const gridSingleService = this.$refs.schedule_grid;
          if (gridSingleService) {
            const y = gridSingleService.getSlotPosition(endTime) - this.stickyHeaderHeight - 80;
            window.scrollTo({
              top: (y < 0 ? 0 : y) + window.scrollY,
              left: 0,
              behavior: 'smooth',
            });
          } else {
            const elColumn = this.$refs.column_0;
            if (elColumn && elColumn[0]) {
              let y = 0;
              if (Object.keys(this.selectedSlots).length === this.appointments.length
                && Object.values(this.selectedSlots).every(o => o !== null)) {
                y = elColumn[0].getSlotPosition(item.time) - this.stickyHeaderHeight - 20;
              } else {
                y = elColumn[0].getSlotPosition(endTime) - this.stickyHeaderHeight - 80;
              }
              window.scrollTo({
                top: (y < 0 ? 0 : y) + window.scrollY,
                left: 0,
                behavior: 'smooth',
              });
            }
            //}
          }
        }
      });
    },

    onStickUpdate(isSticked) {
      if (this.onHeaderStickUpdate) {
        this.onHeaderStickUpdate(isSticked);
      }
    },

    onScheduleSectionSelected() {
      if (this.isLoading) {
        return;
      }
      this.onSingleServiceSlotSelected(null);
    },

    setLoading(isLoading) {
      this.isLoading = isLoading;
      if (this.onLoaderChanged) {
        this.onLoaderChanged(isLoading);
      }
    },

    alert(title, message, callback) {
      if (this.onAlert) {
        this.onAlert(title, message, callback);
      }
    },

    handleScroll() {
      //this.windowScrollY + document.body.scrollHeight
      if (this.onScrollToSection) {
        const ref = this.$refs.column_0;
        if (ref && ref[0]) {
          const morningPosition = ref[0].getMorningPosition();
          const afternoonPosition = ref[0].getAfternoonPosition();
          const position = window.scrollY - this.stickyHeaderHeight + 20;
          //const position = window.pageYOffset + 190;
          if (position >= afternoonPosition) {
            this.onScrollToSection(2);
          } else if (position >= morningPosition) {
            this.onScrollToSection(1);
          } else {
            this.onScrollToSection(0);
          }
        } else {
          const gridSingleService = this.$refs.schedule_grid;
          if (gridSingleService) {
            const morningPosition = gridSingleService.getMorningPosition();
            const afternoonPosition = gridSingleService.getAfternoonPosition();
            const position = window.scrollY - this.stickyHeaderHeight;
            if (position >= afternoonPosition) {
              this.onScrollToSection(2);
            } else if (position >= morningPosition) {
              this.onScrollToSection(1);
            } else {
              this.onScrollToSection(0);
            }
          }
        }
      }

      const length = this.appointments.length;
      if (length > 1) {
        const bottomMargin = 90;
        const top = window.scrollY - this.stickyHeaderHeight;
        const bottom = top + document.body.clientHeight - this.stickyHeaderHeight - bottomMargin;

        for (let i = 0; i < length; i++) {
          const elColumn = this.$refs[`column_${i}`];

          if (elColumn && elColumn[0] && elColumn[0].$el) {
            const boundingBox = elColumn[0].$el.getBoundingClientRect();
            const columnAbsoluteTop = boundingBox.top;
            //const columnAbsoluteBottom = boundingBox.bottom;
            const columnTop = elColumn[0].$el.offsetTop;
            const width = elColumn[0].$el.offsetWidth;
            //const left = elColumn[0].$el.offsetLeft;
            const left = boundingBox.left;

            const obj = {
              //top: top + columnTop - 20,
              top: columnAbsoluteTop - this.stickyHeaderHeight,
              //bottom: bottom - columnTop,
              bottom: this.$root.isDesktop
                ? (this.bodyHeight - this.stickyHeaderHeight) : (bottom - columnTop),
              fixedTop: this.stickyHeaderHeight + 20,
              fixedBottom: bottomMargin,
              center: (width / 2) + left,
            };
            elColumn[0].onScroll(obj);
          }
        }
      }
    },

    scrollToHour(tab) {
      const gridSingleService = this.$refs.schedule_grid;
      if (gridSingleService) {
        gridSingleService.tabSelected(tab);
        let y = 0;
        if (tab === 1) {
          y = gridSingleService.getMorningPosition() + this.stickyHeaderHeight;
        } else if (tab === 2) {
          y = gridSingleService.getAfternoonPosition() + this.stickyHeaderHeight;
        }
        const rootEl = document.getElementById('root-frame__scroller');
        if (rootEl) {
          y += rootEl.offsetTop;
        }
        window.scrollTo({
          top: y < 0 ? 0 : y, //rect.top - 140,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        const elColumn = this.$refs.column_0;
        if (elColumn) {
          let y = 0;
          if (tab === 1) {
            y = elColumn[0].getMorningPosition() + this.stickyHeaderHeight - 10;
          } else if (tab === 2) {
            y = elColumn[0].getAfternoonPosition() + this.stickyHeaderHeight - 10;
          }
          const rootEl = document.getElementById('root-frame__scroller');
          if (rootEl) {
            y += rootEl.offsetTop;
          }
          window.scrollTo({
            //top: y + window.scrollY - 190, //rect.top - 140,
            top: y < 0 ? 0 : y, //rect.top - 140,
            left: 0,
            behavior: 'smooth',
          });
        }
        //}
      }
    },

    scrollToPosition(position) {
      window.scrollTo({
        top: position + window.scrollY - this.stickyHeaderHeight - 10,
        left: 0,
        behavior: 'smooth',
      });
    },

    onSingleServiceSlotSelected(item) {
      const serviceId = this.appointments[0].service.id;
      this.selectedSlots[serviceId] = item;
      this.singleServiceSelectedSlot = item;
      //this.multipleServiceAvailabilities.setSelectedSlot(service.id, item);
      //this.setComponentSlots();

      this.onSelectedSlotsChaged(item, serviceId);
    },

    onSlotSelected(item, service) {
      this.selectedSlots[service.id] = item;
      this.multipleServiceAvailabilities.setSelectedSlot(service.id, item);
      this.setComponentSlots();

      this.onSelectedSlotsChaged(item, service.id);
    },

    professionalClicked(index) {
      if (this.onProfessionalClicked && Object.keys(this.serviceProfessionals).length > 0) {
        const serviceId = this.appointments[index].service.id;
        this.onProfessionalClicked(index, this.serviceProfessionals[serviceId]);
      }
    },
    onProfessionalChangeClick() {
      this.professionalClicked(0);
    },
    onDateChangeClick() {
      this.onDateChangeClicked(this.nextAvailableDay);
    },

    setComponentSlots() {
      const size = this.appointments.length;
      let slotsArray;
      let serviceId;

      this.slotsUnavailable = this.isDaySlotsUnavailable();

      if (size === 1) {
        const gridElement = this.$refs.schedule_grid;
        if (!gridElement) {
          return;
        }
        serviceId = `${this.appointments[0].service.id}`;
        slotsArray = this.multipleServiceAvailabilities.serviceSlots[serviceId];
        if (this.discounts) {
          const weekday = this.$moment(this.dateSelected).isoWeekday();
          const serviceDiscounts = this.discounts.getServiceDiscounts(weekday, serviceId);
          gridElement.setDiscounts(serviceDiscounts);
        }
        //gridElement.setItems(this.multipleServiceAvailabilities.getSingleServiceSlots());

        // Edit Mode for an appointment: set slot selected
        if (this.editAppointment && slotsArray) {
          const time = this.editAppointment.time;
          const editingTimeSlot = slotsArray.filter(t => t.time === time);
          if (editingTimeSlot.length > 0) {
            editingTimeSlot[0].selected = this.editAppointment.day === this.dateSelected.format('YYYY-MM-DD');
          }
        }
        gridElement.setItems(slotsArray);
      } else {
        for (let i = 0; i < this.appointments.length; i++) {
          if (this.$refs[`column_${i}`]) {
            const columnElement = this.$refs[`column_${i}`][0];
            if (columnElement) {
              //columnElement.clearSlots();
              serviceId = `${this.appointments[i].service.id}`;
              slotsArray = this.multipleServiceAvailabilities.serviceSlots[serviceId];
              if (this.discounts) {
                const weekday = this.$moment(this.dateSelected).isoWeekday();
                const serviceDiscounts = this.discounts.getServiceDiscounts(weekday, serviceId);
                columnElement.setDiscounts(serviceDiscounts);
              }
              columnElement.setSlots(slotsArray);
            }
          }
        }
      }

      if (this.onSlotsChanged) {
        this.onSlotsChanged();
      }
    },

    // REQUESTS

    requestServicesProfessionals() {
      const serviceIds = this.appointments.map(item => item.service.id);
      const self = this;

      this.setLoading(true);

      this.$graphql.getProfessionalsServices(
        serviceIds,
        function (items) {
          if (self.editAppointment
            && !items.hasProfessional(self.editAppointment.professional.id)) {
            self.alert(self.$t('alerts.alert_edit_appointment_professional_unavailable_title'),
              self.$t('alerts.alert_edit_appointment_professional_unavailable_message'), function () {
                self.$router.go(-1);
              });
            return;
          }
          let serviceId = '';
          self.appointments.forEach(element => {
            serviceId = `${element.service.id}`;
            self.serviceProfessionals[serviceId] = items.getProfessionals(serviceId);
          });
          self.$store.dispatch('setProfessionals', items.getItems());
          self.requestAvailabilities();
        }, function (error) {
          if (error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            self.$router.push({ name: self.$constants.ROUTE_LOGIN }, () => {});
          } else {
            self.alert(self.$t('errors.generic_title'),
              self.$t('errors.generic_message'), function () {
                self.$router.replace({ name: self.$constants.ROUTE_SEARCH_SERVICE }, () => {});
              });
          }
        }
      );
    },


    requestAvailabilities() {
      if (this.businessUnavailable) {
        return;
      }
      const weekday = this.$moment(this.dateSelected).isoWeekday();
      const scheduleDay = this.business.getSchedule(weekday);

      this.setLoading(true);

      if (scheduleDay) {
        this.scheduleStartTime = scheduleDay.scheduleStart;
        this.scheduleEndTime = scheduleDay.scheduleEnd;
      } else {
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
      }
      this.serviceAvailabilities = {};
      this.availableSlots = {};

      const self = this;

      const startDate = this.$moment(this.dateSelected);
      const endDate = this.$moment(this.dateSelected);
      //endDate.add(6, 'd');

      this.$graphql.getServicesAvailabilities(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        this.appointments,
        this.editAppointment !== null ? this.editAppointment.key : null,
        function (availabilities) {
          const day = self.dateSelected.format('YYYY-MM-DD');

          let serviceId = '';
          let serviceList = [];
          let dayAvailability = null;

          self.appointments.forEach(function (e) {
            serviceId = `${e.service.id}`;
            serviceList = availabilities.getServiceAvailabilities(serviceId);
            if (serviceList) {
              dayAvailability = serviceList.availabilities.getSlotsInDay(day);
              self.serviceAvailabilities[serviceId] = dayAvailability;
            }
          });

          self.multipleServiceAvailabilities.initAvailabilities(
            self.dateSelected, self.appointments, availabilities, self.discounts
          );

          self.setComponentSlots();

          // Reselect previously selected slots
          let i = 0;
          self.appointments.forEach(function (e) {
            const serviceId = `${e.service.id}`;
            let selectedTime = null;
            if (self.selectedSlots[serviceId]) {
              selectedTime = self.selectedSlots[serviceId].time;
            } else if (e.time && e.day === day) {
              selectedTime = e.time;
            }

            if (selectedTime) {
              const elColumn = self.$refs[`column_${i}`];
              if (elColumn
                && elColumn[0]
                && self.multipleServiceAvailabilities.serviceSlots[serviceId]) {
                const slots = self.multipleServiceAvailabilities.serviceSlots[serviceId]
                  .filter(o => o.time === selectedTime);
                if (slots.length > 0) {
                  elColumn[0].onSlotClicked(slots[0]);
                }
              }
            }
            i++;
          });

          self.nextAvailableDay = null;
          if (self.appointments.length === 1
            && self.slotsUnavailable
            && self.appointments[0].professional
            && !self.editAppointment) {
            self.requestProfessionalAvailabilities();
          } else {
            self.setLoading(false);
          }
        }, function (error) {
          self.setLoading(false);
          if (error.getCode() === Error.unavailableServiceCode) {
            self.alert(self.$t('errors.generic_title'), self.$t('errors.service_unavailable'), function () {
              self.$router.replace({ name: self.$constants.ROUTE_SEARCH_SERVICE }, () => {});
            });
          } else if (error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            self.$router.push({ name: self.$constants.ROUTE_LOGIN }, () => {});
          } else {
            self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
              self.$router.go(-1);
            });
          }
        }
      );
    },

    requestProfessionalAvailabilities() {
      const startDate = this.$moment(this.dateSelected);
      const endDate = this.$moment(this.dateSelected);
      if (DateUtils.isTodayDate(this.dateSelected)) {
        startDate.add(1, 'd');
        endDate.add(6, 'd');
      } else {
        const now = this.$moment(new Date());
        const diff = startDate.diff(now, 'days');
        //const pageFactor = diff / 7;
        //const calendarPage = Math.floor(pageFactor);
        startDate.subtract(Math.min(diff + 1, 4), 'd');
        endDate.add(4, 'd');
        // if (calendarPage > 0) {
        //   startDate.subtract(4, 'd');
        //   endDate.add(4, 'd');
        // } else {
        //   startDate.subtract(Math.min(diff, 4), 'd');
        //   endDate.add(4, 'd');
        // }
      }

      const self = this;
      this.$graphql.getServicesAvailabilities(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        this.appointments,
        this.editAppointment !== null ? this.editAppointment.key : null,
        function (availabilities) {
          const days = availabilities.serviceAvailabilities[self.appointments[0].service.id]
            .availabilities?.days;
          const daysAvailable = Object.fromEntries(Object.entries(days)
            .filter(([key]) => days[key].slots.length > 0));

          let availableDate = null;
          let i = 1;
          while (i < 8 && !availableDate) {
            const nextDate = self.$moment(self.dateSelected).add(i, 'd');
            const prevDate = self.$moment(self.dateSelected).subtract(i, 'd');
            if (daysAvailable[nextDate.format('YYYY-MM-DD')]) {
              availableDate = nextDate;
            } else if (daysAvailable[prevDate.format('YYYY-MM-DD')]) {
              availableDate = prevDate;
            }
            i++;
          }
          self.nextAvailableDay = availableDate;

          self.setLoading(false);
        }, function () {
          self.setLoading(false);
        }
      );
    },

    getAppointments() {
      this.appointments.forEach(item => {
        const selectedSlot = this.selectedSlots[item.service.id];
        item.time = selectedSlot.time;
        item.date = this.dateSelected;
        item.day = this.dateSelected.format('YYYY-MM-DD');
      });
      return this.appointments;
    },

  },

};
</script>


<style lang="scss" scoped>

.multiple-service-grid {
  position: relative;
  background: white;
  min-height: 220px;

  &--unavailable {
    background: none;
  }

  &__unavailable {
    text-align: center;
    padding: 10px;

    &__icon {
      font-size: 80px;
      color: $theme-color-dim;
    }

    &__text {
      display: block;
      max-width: 480px;
      margin: 1em auto;
      margin-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      font-family: $heading-font-family;
      font-size: 14px;
      color: $theme-2-color;
    }
    &__title {
      display: block;
      max-width: 480px;
      margin: 1em auto;
      margin-bottom: 0;
      font-family: $heading-font-family;
      font-size: 18px;
      color: black;
      padding-left: 16px;
      padding-right: 16px;
    }
    &__change_professional_button {
      max-width: 480px;
      margin: 0 auto;
      margin-top: 16px;
    }
    &__change_date_button {
      max-width: 480px;
      margin: 0 auto;
    }
  }

  &__serviceName {
    color: black;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-lines: 2;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &__header {
    display: block;
    width: 100%;
    font-family: $heading-font-family;
    font-size: 14px;
    color: $theme-2-color;
    //font-weight: bold;
    padding: 10px 0;
    margin-bottom: 10px;
    text-align: center;
  }

  &__loader_container {
    position: relative;
    &__loader {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      z-index: 1;
    }
  }

  &__container {
    padding: 8px 28px;
    background: white;
    position: relative;

    &--single_service {
      padding: 8px 16px;
    }
  }

  &__slot_columns {
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 8px;

    &__column {
      text-align: center;
      display: flex;

      &__service {
        align-self: center;
        font-size: 12px;
        font-weight: bold;
        color: black;
        text-align: center;
        width: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-lines: 2;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;

        &--single {
          font-size: 18px;
        }
      }
    }
  }
}

</style>
