<template>
  <transition name="fade">
    <div class="popover">
      <div
        class="popover__frame"
        @click="handleCloseClick" />
      <transition name="fade">
        <div
          v-if="contentShowing"
          class="popover__content">
          <IlmIcon
            v-if="icon"
            :icon="icon"
            class="popover__content__icon" />
          <p
            v-if="title"
            class="popover__content__title">
            {{ title }}
          </p>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

const noScrollClass = 'no-scroll';


export default {
  name: 'PopoverSimple',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    title: String,
    icon: String,
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
    };
  },

  // Computed ********************************
  computed: {
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    this.contentShowing = true;

    const self = this;
    setTimeout(function () {
      if (self.contentShowing === true) {
        self.handleCloseClick();
      }
    }, 2000);
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.1;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 130px;
    overflow: hidden;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.66);
    box-shadow: $box-shadow-bottom;
    padding: 12px 10px 12px 10px;
    border-radius: 14px;
    box-sizing: border-box;

    &__title {
      position: relative;
      color: black;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-md;
      line-height: 1.2;
      font-family: $base-font-family;
      margin: 0;
      color: white;
    }

    &__icon {
      color: white;
      font-size: 60px;
    }

  }
}
</style>
