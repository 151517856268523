
<template>
  <h3
    :class="classNames"
    class="section-title">
    <IlmIcon
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      :style="iconStyle"
      :class="iconClasses" />
    {{ titleLabel }}
  </h3>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'Header',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    tKey: String,
    label: String,
    icon: String,
    iconSize: {
      type: String,
      default: 'md',
    },
    circleColor: {
      type: String,
      default: null,
    },
    iconColor: String,
  },

  data() {
    return {
      contentKey: '',
      contentLabel: '',
    };
  },

  // Computed ********************************
  computed: {
    classNames() {
      const classNames = {
        'section-title--iconized': !!this.icon,
      };
      return classNames;
    },
    titleLabel() {
      return this.contentKey ? this.$t(this.contentKey) : this.contentLabel;
    },
    iconStyle() {
      const styles = {};
      if (this.iconColor) {
        styles.color = this.iconColor;
      }
      if (this.circleColor) {
        styles.backgroundColor = this.circleColor;
      }
      return styles;
    },
    iconClasses() {
      const classNames = {
        'section-title__icon': true,
      };
      if (this.circleColor) {
        classNames['section-title__icon--circle'] = true;
      }
      return classNames;
    },
  },

  created() {
    this.contentKey = this.tKey;
    this.contentLabel = this.label;
  },

  // Methods ********************************
  methods: {
    setText(val) {
      this.contentKey = null;
      this.contentLabel = val;
    },
  },
};
</script>


<style lang="scss" scoped>

.section-title {
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  margin: 0;
  text-transform: uppercase;

  &--iconized {
    display: flex;
    align-items: center;
  }

  &__icon {
    //vertical-align: middle;
    margin-right: 8px;
    font-size: 25px;

    &--circle {
      border-radius: 50%;
      font-size: 16px;
      padding: 6px;
    }
  }
}

</style>
