<template>
  <div>
    <!--div
      v-if="showBusinessName"
      class="navbar-bar">
      <h3
        class="navbar-bar__title">
        {{ businessName }}
      </h3>
    </div-->
    <div
      :class="searchbarClasses"
      class="searchbar">
      <!--div
        v-if="canShowBack"
        class="searchbar__back-container">
        <IlmButtonIcon
          v-if="canShowBack"
          :on-click="handleBackClick"
          icon="arrow_back"
          prevent-default
          class="searchbar__back-container__back" />
      </div-->
      <div
        class="searchbar__container">
        <label
          :class="containerClassNames"
          class="input-container">
          <IlmIcon
            v-if="icon && !isSelected"
            :icon="icon"
            class="input-icon"
            size="xl" />
          <input
            ref="input"
            v-model.trim="message"
            v-debounce="500"
            :placeholder="inputPlaceholder"
            :name="ramdomText"
            :type="type"
            :disabled="isDisabled || isSelected"
            :class="inputClassNames"
            :autocomplete="ramdomText"
            class="input-field"
            @change="handleChange"
            @focus="handleFocus(true)"
            @blur="handleFocus(false)"
            @keyup.enter="handleEnter">
          <div
            v-if="isLoading"
            class="input-loading-container">
            <LoadingSpinner
              class="input-loading-container__spinner" />
          </div>
          <div
            v-else-if="showClearButton"
            class="input-clear-container"
            @click="handleClear">
            <IlmIcon
              class="input-icon input-icon--clear"
              icon="cancel"
              size="xl" />
          </div>
        </label>
      </div>
    </div>
  </div>
</template>


<script>
//import Vue from 'vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'SearchBox',

  // Components ********************************
  components: {
    IlmIcon,
    LoadingSpinner,
  },

  // Properties ********************************
  props: {
    icon: String,
    placeholderKey: String,
    placeholder: String,
    name: String,
    type: String,
    disabled: Boolean,
    showBack: {
      type: Boolean,
      default: false,
    },
    showBusiness: {
      type: Boolean,
      default: false,
    },
    error: null,
    focusOnEnter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onResize: Function,
  },

  // Data ********************************
  data() {
    return {
      hasFocus: false,
      message: '',
      isSelected: false,
    };
  },


  // Computed ********************************
  computed: {
    isDisabled() { return this.disabled; },
    showClearButton() { return !!this.message; },
    inputPlaceholder() {
      return this.placeholderKey ? this.$t(this.placeholderKey) : this.placeholder;
    },
    inputClassNames() {
      const classNames = {
        'input-field--disabled': this.isDisabled,
        'input-field--iconized': this.icon,
      };
      return classNames;
    },
    searchbarClasses() {
      return {
        'searchbar--withbar': this.showBusinessName,
      };
    },
    containerClassNames() {
      const classNames = {
        'input-container--disabled': this.isDisabled,
        'input-container--focused': this.hasFocus,
        'input-container--is-selected': this.isSelected,
      };
      return classNames;
    },
    ramdomText() {
      // ramdom string on input ´name´ 'autocomplete' to avoid autocomplete field
      return `search-box-${Date.now()}`;
    },
    canShowBack() {
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (body.classList.contains('os--ios') && fbMessenger.inFrame) {
        return false;
      }
      return this.showBack;
    },
    searchText: {
      get() {
        return this.message;
      },
      set(val) {
        //console.log(val);
        this.message = val;
      },
    },
  },


  // Created ********************************
  mounted() {
    const self = this;
    if (this.focusOnEnter) {
      this.$nextTick(function () {
        self.$refs.input.focus();
      });
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    clear() {
      this.handleClear();
    },

    handleFocus(val) {
      this.hasFocus = val;
    },

    handleClear() {
      this.message = '';
      this.emitChange();
      this.$refs.input.focus();
    },

    handleEnter() {
      this.removeFocus();
    },

    handleBackClick() {
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },

    removeFocus() {
      if (this.hasFocus === true) {
        this.$refs.input.blur();
        this.handleFocus(false);
      }
    },

    handleChange() {
      if (this.isDisabled) return;
      this.emitChange();
    },

    emitChange() {
      this.$emit('change', this.message);
    },

    setSavedText() {
      const savedSearch = this.$store.getters.getServiceSearch;
      if (savedSearch) {
        //this.searchText = savedSearch;
        this.message = savedSearch;
      }
    },
    /*
    setSelected(text) {
      if (text) {
        this.removeFocus();
        this.message = text;
        this.isSelected = true;
      } else {
        this.setSavedText();
        this.isSelected = false;
      }
    },*/

  },
};
</script>


<style lang="scss" scoped>

.searchbar {
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 6px $padding-section 0 $padding-section;

  &--withbar {
    padding-top: 4px;//$padding-section * 0.5;
  }

  &__container {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }
}
.input-container {
  display: flex;
  position: relative;
  //background: $theme-3-bg;
  background: white;
  border-bottom: 2px solid $theme-3-color;
  //border: 1px solid $box-border-color;
  //border-radius: $theme-border-radius;
  padding: 8px 6px;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  &--focused {
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

  &--is-selected {
    border-bottom-style: dashed;
    background: white;
  }
}


.input-field {
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-xl;
  display: inline-block;
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;
  flex: 1 1 auto;

  &:focus, &:active {
    outline: none;
    // border: none;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

.input-field:disabled {
  color: black;
}


.input-icon {
  //color: $theme-3-color;
  color: black;
  margin-right: 6px;
  flex: 0 0 auto;

  &--clear {
    margin-right: 0;
    margin-left: 6px;
  }
}

.input-clear-container {
  cursor: pointer;
  padding-top: 3px;

  @media (hover) {
    &:hover i {
      color: $theme-link-color;
    }
  }
}

.input-loading-container {
  &__spinner {
    flex: 0 0 auto;
    margin-top: 4px;
    margin-right: 4px;
  }
}


</style>
