<template>
  <div
    class="account-cards">
    <Sticky>
      <Navbar
        :title="navbarTitle"
        :show-shadow="false"
        :on-ok="onButtonOk"
        :disable-btn-ok="!formComplete"
        :save-btn="navbarSaveButton"
        :is-modal="isNavbarModal"
        :is-loading="isLoading"
        show-back-bevel
        class="account-cards__navbar" />
    </Sticky>

    <div class="account-cards__content">
      <!--transition name="fade"-->
      <PaymentSection3
        v-if="currentStep === 2 && client && !isEditMode"
        :is-loading="isLoading"
        :show-title="false"
        :customer="client"
        :on-data-changed="stepDataChanged"
        :on-date-helper-clicked="onDateExpireHelperClicked"
        :on-cvv-helper-clicked="onCvvHelperClicked"
        :on-save-clicked="onButtonOk"
        class="account-cards__content__card_form" />
      <AccountCardDetails
        v-if="currentStep === 2 && isEditMode"
        :customer="client"
        :show-alert="alert"
        class="account-cards__content__card_form" />
      <CardList
        v-if="currentStep === 1 && client"
        :is-loading="isLoading"
        :show-new-card="showNewCard"
        :customer="client"
        :on-add-card="onAddCard"
        :on-card-selected="onCardSelected" />
        <!--/transition-->
    </div>

    <ModalDateHelper
      v-if="expireDateModalShowing"
      @closed="onModalHelperClosed" />

    <ModalCvvHelper
      v-if="cvvModalShowing"
      @closed="onModalHelperClosed" />

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertDismissed"
      @ok="onAlertDismissed" />

  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import PaymentSection3 from '@/components/Payments/PaymentSection_3.vue';
import AccountCardDetails from '@/components/Payments/AccountCardDetails.vue';
import CardList from '@/components/Payments/CardList.vue';
import ModalDateHelper from '@/components/Modals/ModalPaymentDateHelper.vue';
import ModalCvvHelper from '@/components/Modals/ModalPaymentCvvHelper.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';

import CustomerDetails from '@/models/billing/customerDetails';
import CustomerCard from '@/models/billing/customerCard';

import utils from '@/utils/utils';

const STEP_CARDS_LIST = 1;
const STEP_NEW_CARD = 2;

export default {
  name: 'AccountCards',

  // Components ********************************
  components: {
    Navbar,
    PaymentSection3,
    AccountCardDetails,
    CardList,
    ModalDateHelper,
    ModalCvvHelper,
    Alert,
  },

  // BeforRouteLeave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      if (this.currentStep > this.initialStep && !this.cardAdded) {
        this.currentStep -= 1;
        next(false);
        return;
      }
    }
    next();
  },

  // Props ********************************
  props: {
    showCardList: {
      type: Boolean,
      default: false,
    },
    showNewCard: {
      type: Boolean,
      default: false,
    },
    editCard: Object,
    customer: Object,
  },

  data() {
    return {
      isLoading: false,
      initialStep: STEP_CARDS_LIST,
      currentStep: STEP_CARDS_LIST,

      client: null,

      formComplete: false,
      expireDateModalShowing: false,
      cvvModalShowing: false,

      cardAdded: false,

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,
    };
  },

  // Computed ********************************
  computed: {
    showNavbarBack() {
      return this.$store.getters.isExternalSource === true;
    },
    isNavbarModal() {
      return this.initialStep === STEP_CARDS_LIST && this.currentStep === STEP_CARDS_LIST;
    },
    navbarTitle() {
      if (this.currentStep === STEP_CARDS_LIST) {
        const auth = this.$store.getters.getAuth;
        if (auth && auth.client) {
          const count = auth.getClientActiveCardsCount();
          return this.$tc('title.payment_cards_list', count, { count });
        }
        return 'Cartões disponíveis';
      }
      if (this.currentStep === STEP_NEW_CARD) {
        if (this.isEditMode) {
          return this.$t('account.edit_card_title', { brand: utils.capitalizeWords(this.editCard.brand) });
        }
        return 'Novo cartão';
      }
      return null;
    },
    navbarSaveButton() {
      if (this.currentStep === STEP_NEW_CARD && !this.isEditMode) {
        //return this.$t('modals.buttons.save');
      }
      return null;
    },
    isEditMode() {
      if (this.editCard) {
        return true;
      }
      return false;
    },
  },


  // Watch ********************************
  watch: {
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated()) {
      this.$router.replace({
        name: this.$constants.ROUTE_LOGIN,
      }, () => {});
      return;
    }

    if (!this.showCardList) {
      this.initialStep = STEP_NEW_CARD;
      this.currentStep = STEP_NEW_CARD;
      this.client = new CustomerDetails();
      if (this.isEditMode) {
        this.client.card = this.editCard;
      }
    } else {
      this.client = this.customer;
    }
  },


  // Mounted ********************************
  mounted() {
    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.isAuthenticated()) {
      return;
    }

    if (this.showCardList) {
      this.$analytics.track('Visit: Payment Card Change');
    } else if (this.isEditMode) {
      this.$analytics.track('Visit: My Account Card Details');
    } else {
      this.$analytics.track('Visit: My Account New Card');
    }
  },

  destroyed() {
  },


  // Methods ********************************
  methods: {
    onAddCard() {
      this.$analytics.track('Action: Add Card');
      this.currentStep = STEP_NEW_CARD;
    },

    onCardSelected(cardSelected) {
      if (this.customer) {
        this.$analytics.track('Action: Card Selected');
        const card = new CustomerCard();
        card.update(cardSelected);
        // eslint-disable-next-line vue/no-mutating-props
        this.customer.card = card;
        this.$router.go(-1);
      }
    },

    onButtonOk() {
      const auth = this.$store.getters.getAuth;
      const cards = auth.client.cards;
      if (cards.length === 0) {
        this.requestCheckCustomerForPayment();
      } else {
        this.requestEncryptedKey();
      }
    },

    stepDataChanged(complete) {
      this.formComplete = complete;
    },

    onDateExpireHelperClicked() {
      this.expireDateModalShowing = true;
    },
    onCvvHelperClicked() {
      this.cvvModalShowing = true;
    },
    onModalHelperClosed() {
      this.expireDateModalShowing = false;
      this.cvvModalShowing = false;
      this.cardModalShowing = false;
    },

    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    requestCheckCustomerForPayment() {
      this.isLoading = true;
      const self = this;
      this.$graphql.getCheckCustomerForPayment('credit_card', function () {
        self.requestEncryptedKey();
      }, function () {
        self.isLoading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Account Check Customer For Payment');
      });
    },

    requestEncryptedKey() {
      this.isLoading = true;
      const self = this;
      this.$graphql.getCardEncryptionKey(function (encryptionObj) {
        self.requestUpdateCard(encryptionObj);
      }, function () {
        self.isLoading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Account Encryption Key');
      });
    },


    requestUpdateCard(encryptionObj) {
      const cardHash = this.client.card.getCardEncrypted(encryptionObj.key, encryptionObj.id);
      const self = this;
      this.$graphql.createCustomerCard(cardHash, function (obj) {
        self.$analytics.track('Payment Card Created');
        const auth = self.$store.getters.getAuth;
        const preferedCard = auth.getPreferedCard();
        if (!preferedCard || (preferedCard && preferedCard.status.toLowerCase() !== 'active')) {
          self.setPreferedCard(obj.uid);
        } else if (self.initialStep === STEP_NEW_CARD) {
          self.$router.go(-1);
        } else {
          self.updateCustomer(obj.uid);
        }
      }, function () {
        self.isLoading = false;
        //self.$root.loading = false;
        self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        self.$analytics.track('Error: Account Create Card');
      });
    },

    updateCustomer(uid) {
      const self = this;
      this.$graphql.getCurrentCustomer(true, function (obj) {
        const auth = self.$store.getters.getAuth;
        auth.updateCurrentClient(obj);
        self.$store.dispatch('updateAuth', auth);
        if (self.showCardList) {
          if (uid) {
            const created = auth.getClientCard(uid);
            const card = new CustomerCard();
            card.update(created);
            // eslint-disable-next-line vue/no-mutating-props
            self.customer.card = card;
          }
          self.cardAdded = true;
          self.$router.go(-1);
        } else {
          self.currentStep -= 1;
        }
        self.isLoading = false;
      }, function () {
        self.isLoading = false;
        self.$router.go(-1);
      });
    },

    setPreferedCard(uid) {
      const self = this;
      this.$graphql.setPreferedCard(uid, function () {
        if (self.initialStep === STEP_NEW_CARD) {
          self.$router.go(-1);
        } else {
          self.updateCustomer(uid);
        }
      }, function () {
        if (self.initialStep === STEP_NEW_CARD) {
          self.$router.go(-1);
        } else {
          self.updateCustomer(uid);
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>

.account-cards {
  //height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  min-height: 360px;

  @media screen and (min-width: $min-screen-width) {
    //min-height: 100vh;
  }
  @media screen and (max-width: $min-screen-width - 1) {
    min-height: 100vh;
  }

  &__content {
    flex: 0 0 auto;

    &__card_form {
      margin: 0 $padding-section;
    }
  }

}

</style>
