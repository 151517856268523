<template>
  <div
    :class="rowClasses"
    class="voucher-row"
    @click="buttonClick">
    <div
      :style="backgroundStyle"
      class="voucher-row__background" />
    <span
      class="voucher-row__title">
      {{ title }}
    </span>
    <div class="voucher-row__description">
      <h2
        class="voucher-row__description__text"
        v-html="description" />
      <IlmButton
        :on-click="buttonClick"
        t-key="voucher.voucher_details_buy"
        prevent-default
        theme="info"
        class="voucher-row__description__button" />
    </div>
    <div
      v-if="isLoading"
      class="voucher-row__is-loading">
      <LoadingCircle />
    </div>
  </div>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import LoadingCircle from '@/components/LoadingCircle.vue';
import formatter from '@/utils/formatter.js';

export default {
  name: 'VoucherRow',

  components: {
    IlmButton,
    LoadingCircle,
  },

  // Properties ********************************
  props: {
    voucher: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    onSelected: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },

  // Computed ********************************
  computed: {
    rowClasses() {
      return {
        'voucher-row--disabled': this.isDisabled && !this.isLocked,
        'voucher-row--locked': this.isLocked,
      };
    },
    backgroundStyle() {
      if (this.voucher) {
        return { background: this.voucher.color };
      }
      return {};
    },
    title() {
      if (this.voucher) {
        return this.voucher.title;
      }
      return '';
    },
    description() {
      if (this.voucher) {
        const auth = this.$store.getters.getAuth;
        let locale = 'pt-BR';
        if (auth && auth.business && auth.business.locale) {
          locale = auth.business.locale;
        }
        return this.$t('voucher.voucher_description',
          {
            userValue: formatter.formatPrice(this.voucher.userValue, locale),
            value: formatter.formatPrice(this.voucher.value, locale),
          });
      }
      return '';
    },
  },

  // Mounted ********************************
  mounted() {
  },


  // Methods ********************************
  methods: {
    buttonClick() {
      if (this.onSelected && this.voucher) {
        this.onSelected(this.voucher);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-row {
  position: relative;
  padding: 16px 0 16px 16px;
  margin-bottom: $padding-section;
  cursor: pointer;
  transition: opacity .2s ease-in-out 0s;

  &--disabled {
    opacity: 0.6;
  }

  &__background {
    position: absolute;
    top: 0;
    right: $padding-section;
    bottom: 0;
    left: 0;
    //border-radius: 16px;
    background: rgb(218,66,255);
    transition: $hover-transition;
    outline: 1px solid white;
    outline-offset: -5px;
    //background: linear-gradient(150deg, rgba(218,66,255,1) 10%, rgba(255,172,149,1) 80%);

    /*&:before {
      content: ' ';
      position: absolute;
      width: 14px;
      height: 14px;
      left: -7px;
      top: 38px;
      border-radius: 50%;
      background: black;
      clip-path: circle(14px at center);
    }*/
  }

  &__title {
    position: relative;
    display: block;
    color: black;
    font-size: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &__description {
    position: relative;
    display: flex;
    align-items: flex-end;

    &__text {
      flex: 1 1 auto;
      color: white;
      font-size: 22px;
      line-height: 1.3;
      margin: 0;
    }

    &__button {
      flex: 0 0 auto;
      padding: 8px 20px;
      transition: $hover-transition;
    }
  }

  @media (hover) {
    &:hover:not(&--disabled) ::v-deep &__background {
      //border: 2px inset black;
      right: 0;

      &__button {
        margin-right: $padding-section;
      }
    }
    &:hover:not(&--disabled) ::v-deep &__description {
      &__button {
        margin-right: $padding-section;
      }
    }
  }

  &--locked {
    cursor: initial !important;
  }

  &--locked ::v-deep &__background {
    right: 0 !important;
  }

  &--locked ::v-deep &__description {
    &__button {
      display: none !important;
    }
  }

  &__is-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    opacity: 0.7;
    //border-radius: 16px;
  }

  /*
  &--locked ::v-deep &__background {
    &:before, &:after {
      content: ' ';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: white;
      top: 28px;
    }
    &:before {
      left: -9px;
    }
    &:after {
      right: -9px;
    }
  }*/

}
</style>
