<template>
  <div class="navbar">
    <Sticky>
      <transition name="fade">
        <div>
          <div
            v-if="!isExtended"
            :class="smallNavbarClassNames"
            class="navbar__small fixed-with-limited">
            <div
              :class="smallNavbarContainerClassNames"
              class="navbar__small__nav">
              <h1
                v-if="title"
                class="navbar__small__nav__title"
                v-html="title" />
            </div>
            <IlmButtonIcon
              v-if="canShowBack"
              :on-click="handleBackClick"
              :icon="backIcon"
              prevent-default
              class="navbar-container__back navbar__small__back" />
          </div>
        </div>
      </transition>
    </Sticky>


    <div
      v-if="isExtendable"
      ref="navbar_container"
      :class="navbarClasses"
      class="navbar-container">
      <transition name="fade">
        <div>
          <div
            :class="navContainerClasses"
            class="navbar-container__top">
            <div
              :class="titleClassNames"
              class="navbar-container__top__nav">
              <IlmButton
                v-if="showMyAppointments && futureAppointmentsCount !== null"
                :on-click="myAppointmentsClick"
                :label="myAppointmentsLabel"
                icon="keyboard_arrow_right"
                class="navbar-container__top__nav__button"
                prevent-default
                theme="simple" />
              <h3
                v-else-if="!showMyAppointments"
                class="navbar-container__top__nav__subtitle"
                v-html="subtitleText" />
            </div>
            <IlmButtonIcon
              v-if="canShowBack"
              :on-click="handleBackClick"
              :icon="backIcon"
              prevent-default
              class="navbar-container__top__back navbar__small__back" />
            <IlmButtonIcon
              v-if="primaryOptionIcon"
              :on-click="onPrimaryOption"
              :icon="primaryOptionIcon"
              prevent-default
              class="navbar-container__top__option navbar__small__option" />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="navbar-container__title-container">
          <h5
            v-if="smallSubtitle"
            class="navbar-container__title-container__small-subtitle"
            v-html="smallSubtitle" />
          <h1
            v-if="title"
            class="navbar-container__title-container__title"
            v-html="title" />
          <h5
            v-if="smallTitle"
            class="navbar-container__title-container__small-title"
            v-html="smallTitle" />
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';

export default {
  name: 'NavigationBar',

  // Components ********************************
  components: {
    IlmButtonIcon,
    IlmButton,
  },

  // Properties ********************************
  props: {
    title: String,
    subtitle: String,
    alternativeSubtitle: String,
    smallTitle: String,
    smallSubtitle: String,
    isExtendable: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showMyAppointments: {
      type: Boolean,
      default: false,
    },
    primaryOptionIcon: String,
    onBack: Function,
    onPrimaryOption: Function,
    onMyAppointmentsClick: Function,
  },

  data() {
    return {
      isExtended: true,
      windowWidth: null,

      futureAppointmentsCount: null,
    };
  },

  // Computed ********************************
  computed: {
    titleClassNames() {
      const classNames = {
        'navbar-container__top__nav--show-nav-back': this.canShowBack || this.primaryOptionIcon,
      };
      return classNames;
    },
    navContainerClasses() {
      return { 'navbar-container__top--empty': !this.subtitleText && !this.canShowBack && !this.primaryOptionIcon };
    },
    navbarClasses() {
      const classNames = {
        'navbar-container--divider': this.showDivider,
      };

      return classNames;
    },
    smallNavbarClassNames() {
      return {
        'navbar__small--fixed': this.isExtendable,
      };
    },
    smallNavbarContainerClassNames() {
      return {
        'navbar__small__nav--show-nav-back': this.canShowBack || this.primaryOptionIcon,
      };
    },
    hideSubtitle() {
      return this.$store.getters.isExternalSource === false
        || this.isBigScreen;
    },
    subtitleText() {
      if (this.hideSubtitle) {
        if (this.canShowBack) {
          return this.alternativeSubtitle;
        }
        return null;
      }
      return this.subtitle;
    },
    isBigScreen() {
      return this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH;
    },
    canShowBack() {
      if (this.showBack === false) {
        return false;
      }
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (fbMessenger.inFrame && body.classList.contains('os--ios')) {
        return false;
      }
      if (this.$store.getters.isExternalSource === true) {
        return true;
      }

      return this.showBack;
    },
    backIcon() {
      return this.isModal ? 'close' : 'arrow_back';
    },
    myAppointmentsLabel() {
      return this.futureAppointmentsCount > 0
        ? this.$t('button.my_future_appointments')
        : this.$t('button.my_appointments');
    },
  },

  created() {
    this.isExtended = this.isExtendable;
  },

  mounted() {
    const self = this;
    this.$nextTick(function () {
      self.windowWidth = document.body.clientWidth;
      window.addEventListener('resize', self.handleResize);
      window.addEventListener('scroll', self.handleScroll);
    });

    if (this.showMyAppointments) {
      const auth = this.$store.getters.getAuth;
      if (auth && auth.isAuthenticated()) {
        this.requestMyAppointments();
      } else {
        this.futureAppointmentsCount = 0;
      }
    }
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },

  // Methods ********************************
  methods: {
    requestMyAppointments() {
      this.$graphql.getMyAppointments(list => {
        if (list.length > 0) {
          const timezone = this.$dateUtils.getTimezone();
          const now = this.$moment.tz(new Date(), timezone);
          const arr = list.filter(item => {
            const date = this.$moment.tz(`${item.day} ${item.time}`, timezone);
            return date.isAfter(now);
          });
          this.futureAppointmentsCount = arr.length;
        } else {
          this.futureAppointmentsCount = 0;
        }
      },
      () => {});
    },
    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },
    handleScroll() {
      const container = this.$refs.navbar_container;
      const windowScrollY = window.pageYOffset;
      //console.log(`container.offsetTop: ${container.offsetTop}`);
      //console.log(`windowScrollY: ${windowScrollY}`);
      //console.log(`container.clientHeight: ${container.clientHeight}`);
      if (container && this.isExtendable) {
        const offset = container.offsetTop;
        const containerHeight = container.clientHeight;
        const ditanceGap = this.isBigScreen ? 0 : 70;
        if (offset + containerHeight - ditanceGap > windowScrollY) {
          this.isExtended = true;
        } else {
          this.isExtended = false;
        }
      }
    },
    handleBackClick() {
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },
    myAppointmentsClick() {
      if (this.onMyAppointmentsClick) {
        this.onMyAppointmentsClick();
      }
    },
  },

};
</script>


<style lang="scss" scoped>
.theme-brazil {
  height: 6px;
  background: rgb(0,156,59);
  background: linear-gradient(90deg, rgba(0,156,59,1) 0%, rgba(0,156,59,1) 30%,
  rgba(255,223,0,1) 30%, rgba(255,223,0,1) 46%,
  rgba(0,39,118,1) 46%, rgba(0,39,118,1) 50%, rgba(0,39,118,1) 54%,
  rgba(255,223,0,1) 54%, rgba(255,223,0,1) 70%, rgba(0,156,59,1) 70%, rgba(0,156,59,1) 100%);

  @media screen and (min-width: $min-screen-width) {
    display: none;
  }
}
.navbar {
  display: initial;
  background: white;

  &__small {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-family: $heading-font-family;
    transition: all 0.3s ease;

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      box-shadow: $box-shadow-bottom;
    }

    &__back {
      top: 10px;
      bottom: 10px;
      display: block;
      position: absolute;
      left: 16px;
      background: white;
      color: black !important;
      font-size: 24px;
      height: 40px;
      border: 1px solid $theme-list-divider-color;

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }

    &__option {
      top: 10px;
      bottom: 10px;
      display: block;
      position: absolute;
      right: 16px;
      background: white;
      color: black !important;
      font-size: 24px;
      height: 40px;
      border: 1px solid $theme-list-divider-color;

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }

    &__nav {
      //padding: $padding-section;
      max-width: 100%;
      flex: 1 1 auto;
      box-sizing: border-box;

      &--show-nav-back {
        padding-left: 62px;
        padding-right: 62px;
      }

      &__title {
        color: black;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        line-height: 1.5em;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }
}
.navbar-container {
  background: white;
  position: relative;
  //min-height: 48px;
  //padding: 60px $padding-section 54px $padding-section;
  text-align: center;

  &--divider {
    border-bottom: 1px solid $theme-list-divider-color;
  }

  &__top {
    position: relative;

    &__nav {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;

      &--show-nav-back {
        padding-left: 62px;
        padding-right: 62px;
      }

      &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em;
        color: #000;
        margin: 0;
        //padding-bottom: 6px;
      }

      &__button {
        font-size: 16px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    &--empty &__nav {
      min-height: 30px;
    }

    &__back {
      display: block;
      position: absolute;
      top: 10px;
      left: 16px;
      background: white;
      color: black !important;
      font-size: 24px;
      height: 40px;
      border: 1px solid $nav-button-border;

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }

    &__option {
      display: block;
      position: absolute;
      top: 10px;
      right: 16px;
      background: white;
      color: black !important;
      font-size: 24px;
      height: 40px;
      border: 1px solid $nav-button-border;

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }
  }

  &__title-container {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $padding-section;
    box-sizing: border-box;

    &__small-subtitle {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin: 0;
      color: $theme-success;
      margin-bottom: 8px;
    }

    &__title {
      min-height: 42px;
      font-size: 28px;
      font-weight: bold;
      line-height: 1;
      margin: 0;
      color: #000;
      box-sizing: border-box;
    }

    &__small-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      margin: 0;
      color: $theme-2-color;
      margin-top: -5px;
      margin-bottom: 15px;
    }
  }
}

</style>
