<template>
  <div
    :style="contentStyle"
    class="payment-section">
    <div class="payment-section__header">
      <span
        class="payment-section__header__title">
        {{ $t('title.payment_confirm') }}
      </span>
      <span
        class="payment-section__header__value">
        {{ headerTitleValue }}
      </span>
    </div>
    <span
      class="payment-section__header-count">
      {{ $tc('title.payment_vouchers', 1, { count: 1 }) }}
    </span>
    <div class="payment-section__list">
      <VoucherRow
        :voucher="customer.voucher"
        :is-locked="true"
        :is-loading="isLoading" />

      <div
        v-if="isLoading"
        class="payment-section__list__processing">
        <p class="payment-section__list__processing__message">
          {{ $t('payment.processing_purchase') }}
        </p>
      </div>
    </div>
    <!--div class="payment-section__options">
      <IlmButtonSelect
        :on-click="handleDropdownClick"
        label="2 x R$ 49,50"
        class="payment-section__options__select
        payment-section__options__left" />
      <IlmButton
        ref="btn_ok"
        :on-click="handleOkClick"
        :disabled="!formComplete"
        :label="$t('button.payment_complete')"
        icon="check"
        theme="success"
        class="payment-section__options__ok
        payment-section__options__right" />
    </div>

    <div
      class="payment-section__footer"
      @click="footerNoteClick">
      <p
        class="payment-section__footer__text"
        v-html="$t('notes.payment_confirm')" />
    </div-->
    <transition name="slide-up-fast-delay mobile">
      <div
        v-show="showFooter"
        ref="footer"
        :class="footerClass"
        class="payment-section__footer">
        <div class="payment-section__footer__header">
          <span
            class="payment-section__footer__header__title">
            {{ $t('title.payment_card') }}
          </span>
          <span class="payment-section__footer__header__button">
            <IlmButton
              :on-click="clickEditCard"
              :icon="cardEditIcon"
              :label="$t('button.payment_card_change')"
              icon-size="md"
              theme="simple"
              class="payment-section__footer__header__button__icon" />
          </span>
        </div>
        <div
          :class="cardDetailsClasses"
          class="payment-section__footer__details"
          @click="clickEditCard">
          <div class="payment-section__footer__details__card_type">
            <img
              :src="cardImage"
              class="payment-section__footer__details__card_type__icon">
          </div>
          <div class="payment-section__footer__details__dropdown">
            <div class="payment-section__footer__details__dropdown__text">
              <span class="payment-section__footer__details__dropdown__text__name">
                {{ cardInformationName }}
              </span>
              <span class="payment-section__footer__details__dropdown__text__card">
                {{ cardInformation }}
              </span>
            </div>
            <span
              v-if="buttonDisabled"
              class="payment-section__footer__details__dropdown__inactive">
              Inativo
            </span>
          </div>
        </div>
        <div
          class="payment-section__footer__split">
          <IlmButtonColumnized
            :on-click="clickConfirm"
            :label-one="buttonConfirmLabel1"
            :label-two="buttonConfirmLabel2"
            :is-working="isLoading"
            :disabled="buttonDisabled"
            is-price
            prevent-default
            theme="block success"
            class="payment-section__footer__confirm_button" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VoucherRow from '@/components/Lists/VoucherRow.vue';
import IlmButtonColumnized from '@/components/Buttons/IlmButtonColumnized.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';

import cardUtils from '@/utils/payment/utils';
import formatter from '@/utils/formatter.js';

export default {
  name: 'PaymentResumeSection',

  components: {
    VoucherRow,
    IlmButtonColumnized,
    IlmButton,
  },

  // Properties ********************************
  props: {
    customer: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
    onConfirm: Function,
  },

  // Data ********************************
  data() {
    return {
      initialized: false,

      windowWidth: null,
      windowScrollY: 0,
      showFooterShadow: false,
      footerHeight: null,
    };
  },

  // Computed ********************************
  computed: {
    contentStyle() {
      if (this.windowWidth < this.$constants.MIN_SCREEN_WIDTH) {
        return {
          'padding-bottom': `${this.footerHeight}px`,
        };
      }
      return {};
    },
    showFooter() {
      return this.initialized;
    },
    footerClass() {
      return {
        'payment-section__footer--shadow': this.showFooterShadow,
        'payment-section__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    cardDetailsClasses() {
      return {
        'payment-section__footer__details--inactive': this.customer.card.status !== 'active',
      };
    },
    cardImage() {
      const cardType = this.customer.card.brand;
      if (cardType) {
        return cardUtils.iconCardImage(cardType);
      }
      return null;
    },
    cardInformationName() {
      return this.customer.card.holderName ? this.customer.card.holderName : '';
    },
    cardInformation() {
      const cardType = this.customer.card.brand ? this.customer.card.brand : '';
      return `${cardType.toUpperCase()} ****** ${this.customer.card.lastDigits}`;
    },
    cardEditIcon() {
      //return this.buttonDisabled ? 'close' : 'caret-down'; //check
      return 'mode_edit'; //check
    },
    buttonDisabled() {
      return this.customer.card.status !== 'active';
    },
    buttonConfirmLabel1() {
      return this.$t('payment.buy_voucher');
    },
    buttonConfirmLabel2() {
      return formatter.formatPrice(this.customer.billing.value, this.businessLocale);
    },
    headerTitleValue() {
      const price = formatter.formatPrice(this.customer.billing.value, this.businessLocale);
      return this.$t('title.payment_total', { value: price });
    },
    businessLocale() {
      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }
      return locale;
    },
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },

  // Mounted ********************************
  mounted() {
    const self = this;
    self.initialized = true;
    this.$nextTick(function () {
      self.windowWidth = document.body.clientWidth;
      window.addEventListener('scroll', self.handleScroll);
      window.addEventListener('resize', self.handleResize);
      const footer = this.$refs.footer;
      if (footer) {
        self.footerHeight = footer.clientHeight;
      }
    });
  },

  // Methods ********************************
  methods: {
    handleScroll() {
      this.windowScrollY = window.pageYOffset;
      if (this.$refs.footer) {
        if (this.windowWidth > this.$constants.MIN_SCREEN_WIDTH) {
          const offset = this.$refs.footer.offsetTop;
          const height = this.$refs.footer.offsetHeight;
          if (this.$refs.footer && this.$refs.footer.offsetParent) {
            const parentHeight = this.$refs.footer.offsetParent.clientHeight;
            this.showFooterShadow = (offset + height < parentHeight);
          }
        } else {
          const height = document.body.clientHeight;
          this.showFooterShadow = (this.windowScrollY + height
            < document.body.scrollHeight);
        }
      }
    },
    handleResize() {
      this.windowWidth = document.body.clientWidth;
      this.handleScroll();
    },

    clickConfirm() {
      if (this.onConfirm) {
        this.onConfirm();
      }
    },

    clickEditCard() {
      this.$router.push({
        name: this.$constants.ROUTE_ACCOUNT_CARDS,
        params: {
          showCardList: true,
          customer: this.customer,
        },
      }, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-section {
  position: relative;
  background: $theme-3-bg;
  //border-top: 1px solid $theme-list-divider-color;
  min-height: 100%;

  &__header {
    background: white;
    padding: $padding-section;
    padding-top: 10px;
    border-bottom: 1px solid $theme-list-divider-color;
    text-align: center;

    &__title {
      font-size: 24px;
      color: black;
      display: block;
      line-height: 1.2em;
    }
    &__value {
      font-size: 26px;
      color: black;
      display: block;
      font-weight: bold;
      line-height: 1.2em;
    }
  }

  &__header-count {
    font-size: 12px;
    color: $theme-2-color;
    padding-left: $padding-section;
    margin-top: 10px;
    display: block;
  }

  &__list {
    padding: 10px $padding-section $padding-section $padding-section;

    @media screen and (min-width: $min-screen-width) {
      min-height: 280px;
    }

    &__processing {
      border-top: 1px solid $theme-list-divider-color;
      padding-top: 12px;

      &__message {
        color: black;
        font-size: 20px;
        margin: 0 auto;
        max-width: 280px;
        text-align: center;
        line-height: 1.2;
      }
    }
  }

  /*
  &__footer {
    margin: 20px 0;
    padding-right: 20px;
    cursor: pointer;

    &__text {
      font-size: 14px;
      line-height: 1.2;
      color: $theme-2-color;

      & ::v-deep span {
        font-weight: bold;
        color: $theme-focus-color;
      }
    }
  }*/

  &__footer {
    background: white;
    padding: 10px $padding-section $padding-section $padding-section;
    border-top: 1px solid $theme-list-divider-color;
    text-align: center;
    position: fixed;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &__title {
        flex: 1 1 auto;
        font-size: 12px;
        color: $theme-2-color;
        display: block;
        text-align: left;
      }

      &__button {
        flex: 0 0 auto;
        text-align: right;

        &__icon {
          padding-top: 4px;
          padding-bottom: 4px;
          padding-right: 34px;
          margin-right: -10px;
          font-size: 12px;
        }
      }
    }

    &__details {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $theme-list-divider-color;
      padding-bottom: $padding-section;
      margin-bottom: $padding-section;
      cursor: pointer;

      @media (hover) {
        &:hover {
          opacity: 0.5;
        }
      }

      /*&--inactive {
        //opacity: 0.5;
      }*/

      &__card_type {
        flex: 0 0 auto;
        height: 37px;
        padding-right: $padding-section;

        &__icon {
          width: 60px;
          height: 37px;
          padding: 0;
        }
      }
      &__dropdown {
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        &__text {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin: 0;
          color: black;

          &__name {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 4px;
          }
          &__card {
            font-size: 12px;
            line-height: 1;
          }
        }

        &__icon {
          flex: 0 0 auto;
          line-height: 1;

          & i {
            font-size: 26px;
          }
        }

        &__inactive {
          flex: 0 0 auto;
          color: $theme-error;
          font-size: 14px;
          line-height: 1;
          padding: 0 8px;
        }
        /*&__inactive + &__icon {
          display: none;
        }*/
      }
    }
  }
}
</style>
