<template>
  <div
    ref="stickyContainer"
    :class="classNames"
    :style="style"
    class="sticky-container">
    <slot />
  </div>
</template>

<script>
import { setTimeout } from 'timers';

export default {
  name: 'Sticky',

  props: {
    showShadow: {
      type: Boolean,
      default: true,
    },
    distance: Number,
    position: {
      type: String,
      default: 'top',
    },
    zIndex: Number,
    onStickUpdate: Function,
  },

  data() {
    return {
      isSticked: false,
      stickPoint: null,
      height: null,
      scrollable: null,
    };
  },

  computed: {
    classNames() {
      const classNames = {
        'sticky-container--is-sticky': true,
        'sticky-container--show-shadow-top': this.showShadow && this.isSticked && this.position === 'bottom',
        'sticky-container--show-shadow-bottom': this.showShadow && this.isSticked && this.position === 'top',
        'sticky-container--sticky-top': this.position === 'top',
        'sticky-container--sticky-bottom': this.position === 'bottom',
      };
      return classNames;
    },
    style() {
      if (this.distance) {
        if (this.position === 'top') {
          return {
            top: `${this.distance}px`,
            'z-index': this.zIndex !== null ? this.zIndex : 10,
          };
        }
        if (this.position === 'bottom') {
          return {
            bottom: `${this.distance}px`,
            'z-index': this.zIndex !== null ? this.zIndex : 10,
          };
        }
      }
      return { 'z-index': this.zIndex !== null ? this.zIndex : 10 };
    },
  },

  watch: {
  },

  created() {
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  mounted() {
    const self = this;
    this.$nextTick(function () {
      setTimeout(function () {
        self.updateStickyPoint();
      }, 300);
      window.addEventListener('scroll', self.handleScroll);
    });
  },

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element && element.tagName.toLowerCase() !== 'html') {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return this.distance ? offsetTop - this.distance : offsetTop;
    },
    updateStickyPoint() {
      const container = this.$refs.stickyContainer;
      if (container) {
        this.stickPoint = this.getOffsetTop(container);//container.offsetTop;
        //this.height = this.$refs.stickyContainer.offsetHeight;
        this.height = container.clientHeight;
      }
    },
    handleScroll() {
      //this.updateStickyPoint();
      const offset = window.pageYOffset;
      //const distance = this.$refs.stickyContainer.offsetTop - offset;

      const lastSticky = this.isSticked;
      /*
      if (distance < 0 && !this.isSticked) {
        //this.siblingElement.style.top = `${this.height}px`;
        this.isSticked = true;
      } else if (this.isSticked && offset <= this.stickPoint) {
        this.isSticked = false;
      }*/

      //this.isSticked = this.$refs.stickyContainer.offsetTop > 0;
      if (this.position === 'top') {
        this.isSticked = offset > this.stickPoint;
      } else {
        this.isSticked = offset + document.body.clientHeight < document.body.scrollHeight;
      }
      //this.isSticked = offset > this.stickPoint;

      if (lastSticky !== this.isSticked && this.onStickUpdate) {
        this.onStickUpdate(this.isSticked);
      }
      //console.log(window.pageYOffset);
      //console.log(this.$refs.stickyContainer.offsetTop);
      /*
      if (this.siblingElement) {
        if (this.isSticked) {
          //el.style = { top: `${this.height}px` };
          this.siblingElement.style.paddingTop = `${this.height}px`;
        } else {
          this.siblingElement.style.paddingTop = '0';
        }
      }*/

      //this.scrollY = Math.round(window.scrollY);
    },
  },
};
</script>

<style lang="scss" scoped>

.sticky-container {
  //overflow: hidden;
  //position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  box-sizing: border-box;

  &--sticky-top {
    top: 0;
  }

  &--sticky-bottom {
    bottom: 0;
  }

  /*&--is-sticky {
    /*position: -webkit-sticky;
    position: sticky;*/
    /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;*/
  /*}*/

  &--show-shadow-bottom {
    box-shadow: $box-shadow-bottom;
  }
  &--show-shadow-top {
    box-shadow: $box-shadow-top;
  }
}


</style>
