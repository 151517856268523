
<template>
  <div
    :class="mainClasses"
    @click="handleClick">
    <div :class="innerCardClasses">
      <span
        :class="`${mainClass}__inner__discount`">
        {{ `-${discountValue}%` }}
      </span>
      <div :class="`${mainClass}__inner__info`">
        <span :class="`${mainClass}__inner__info__title`">
          {{ title }}
        </span>
        <span :class="`${mainClass}__inner__info__description`">
          {{ description }}
        </span>
      </div>
    </div>
    <button
      :class="buttonClasses"
      @click="handleButtonClick">
      {{ $t('button.discount_card_select') }}
      <IlmIcon
        :class="`${mainClass}__button__icon`"
        icon="check" />
    </button>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'DiscountsCard',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    index: Number,
    discount: Object,
    selected: {
      type: Boolean,
      default: false,
    },

    onClick: Function,
  },

  // Data ********************************
  data() {
    return {
    };
  },


  // Computed ********************************
  computed: {
    mainClass() {
      return 'discounts-card';
    },
    mainClasses() {
      const classes = {};
      classes[`${this.mainClass}`] = true;
      /*if (this.discountValue <= 10) {
        classes[`${this.mainClass}--10`] = true;
      } else {
        const roundValue = Math.round(this.discountValue / 10) * 10;
        classes[`${this.mainClass}--${roundValue}`] = true;
      }*/
      let roundValue = Math.ceil(this.discountValue / 10) * 10;
      if (roundValue === this.discountValue) {
        roundValue = Math.ceil((roundValue - 1) / 10) * 10;
      }
      classes[`${this.mainClass}--${roundValue}`] = true;
      return classes;
    },
    innerCardClasses() {
      const classes = {};
      classes[`${this.mainClass}__inner`] = true;
      classes[`${this.mainClass}__inner--selected`] = this.selected;
      return classes;
    },
    buttonClasses() {
      const classes = {};
      classes[`${this.mainClass}__button`] = true;
      classes[`${this.mainClass}__button--visible`] = this.selected;
      return classes;
    },

    discountValue() {
      return this.discount.maxValue;
    },
    title() {
      return this.discount.title;
    },
    description() {
      return this.discount.description;
    },
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick(this.index);
      }
    },
    handleButtonClick() {
      this.handleClick();
    },
  },

};
</script>


<style lang="scss" scoped>

.discounts-card {
  position: relative;
  max-width: 260px;
  height: 260px;
  user-select: none;

  &__inner {
    position: absolute;
    border-radius: 20px;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    transform: scale(0.9);
    transition: $hover-transition;

    &--selected {
      box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.3);
      transform: scale(1.0);
    }

    &__discount {
      flex: 0 0 auto;
      color: white;
      font-family: 'DINNeuzeitGroteskStd-BdCond';
      font-size: 64px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      line-height: 1;
      padding: 18px 10px;
      box-sizing: border-box;
    }

    &__info {
      position: relative;
      flex: 1 1 auto;
      width: 100%;
      height: 0;
      box-sizing: border-box;
      background: white;
      padding: 10px 14px 26px 14px;
      margin-bottom: 6px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $theme-2-bg;
      border-right: 1px solid $theme-2-bg;

      &__title {
        color: black;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2;
        padding-bottom: 8px;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
      }
      &__description {
        flex: 0 0 auto;
        color: black;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        box-sizing: border-box;
        left: -1px;
        right: -1px;
        top: -6px;
        height: 10px;
        border-left: 1px solid $theme-2-bg;
        border-right: 1px solid $theme-2-bg;
        background:
          linear-gradient(45deg,
            transparent 33.333%,
            #fff 33.333%,
            #fff 66.667%,
            transparent 66.667%
          ),
          linear-gradient(-45deg,
            transparent 33.333%,
            #fff 33.333%,
            #fff 66.667%,
            transparent 66.667%
          );
        background-size: 10px (10px * 2);
        background-position: 0 -10px;
        transform: rotate(180deg);
      }
    }
  }

  &__inner, &__button {
    // default
    background: $theme-discount-40;
  }
  &--10 &__inner, &--10 &__button {
    background: $theme-discount-10;
  }
  &--20 &__inner, &--20 &__button {
    background: $theme-discount-20;
  }
  &--30 &__inner, &--30 &__button {
    background: $theme-discount-30;
  }
  &--40 &__inner, &--40 &__button {
    background: $theme-discount-40;
  }
  &--50 &__inner, &--50 &__button {
    background: $theme-discount-50;
  }
  &--60 &__inner, &--60 &__button {
    background: $theme-discount-60;
  }
  &--70 &__inner, &--70 &__button {
    background: $theme-discount-70;
  }
  &--80 &__inner, &--80 &__button {
    background: $theme-discount-80;
  }
  &--90 &__inner, &--90 &__button {
    background: $theme-discount-90;
  }

  &__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-family: $base-font-family;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 20px;
    padding-bottom: 12px;
    border-radius: $theme-border-radius;
    line-height: 1.2em;
    border: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    -webkit-tap-highlight-color:  rgba(0, 0, 0, 0);
    transition: $hover-transition;
    user-select: none;
    box-shadow: inset 0 -4px 0 rgba(0, 0, 0, .2);
    opacity: 0;

    &--visible {
      opacity: 1;
    }

    &__icon {
      font-size: 16px;
      font-weight: bold;
      padding-left: 2px;
      margin-right: -2px;
    }

    /*@media (hover) {
      &:hover:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $theme-border-radius;
        background: rgba(0, 0, 0, .2);
      }
    }*/
  }

  @media (hover) {
    &:hover &__inner--selected {
      cursor: pointer;
    }
    &:hover &__button--visible {
      bottom: 6px;
    }
    &:hover &__button--visible:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: $theme-border-radius;
      background: rgba(0, 0, 0, .1);
    }
  }

}

</style>
