import utils from '../utils/utils';
import Service from './service';

class Suggestion {
  constructor(obj) {
    this.name = utils.capitalizeWords(obj.name);
    this.count = obj.docCount;
    this.minPrice = obj.minPrice;
    this.maxPrice = obj.maxPrice;
    this.minDuration = obj.minDuration;
    this.maxDuration = obj.maxDuration;
    this.groupType = obj.groupType;
    this.groupKey = obj.groupKey;
    this.score = obj.score;

    this.services = [];
    if (obj.services) {
      obj.services.forEach(element => this.services.push(new Service(element)));
    }
  }

  getName() {
    return this.name;
  }

  toString() {
    return this.getName();
  }
}

export default Suggestion;
