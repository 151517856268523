
<template>
  <div
    class="row-section">
    <div class="row-section__content">
      <span
        :class="dateClass"
        class="row-section__content__date">
        {{ dateString(day) }}
      </span>
      <appointment-row
        v-for="(obj, index) in elements"
        :key="obj.key"
        :element="obj"
        :show-divider="index < elements.length - 1"
        :on-click="handleRowClick"
        class="row-section__content__row" />
    </div>
    <div class="row-section__handle">
      <IlmIcon
        size="lg"
        icon="keyboard_arrow_right" />
    </div>
  </div>
</template>


<script>
import AppointmentRow from '@/components/Lists/AppointmentListRow.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'AppointmentListSection',

  // Components ********************************
  components: {
    AppointmentRow,
    IlmIcon,
  },


  // Properties ********************************
  props: {
    day: {
      type: String,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      default: true,
    },
    onClick: Function,
    showDivider: Boolean,
  },

  data() {
    return {
      currentYear: null,
    };
  },

  computed: {
    dateClass() {
      return {
        'row-section__content__date--past': this.isPast,
      };
    },
  },

  mounted() {
    const timezone = this.$dateUtils.getTimezone();
    const now = this.$moment.tz(new Date(), timezone);
    this.currentYear = now.year();
  },

  methods: {
    dateString(text) {
      const date = this.$moment(text);

      const dateYear = date.year();
      if (dateYear !== this.currentYear) {
        return this.$dateUtils.getDateString(date, 'ddd D MMMM YYYY', true).toUpperCase();
      }
      return this.$dateUtils.getDateString(date, 'ddd D MMMM', true).toUpperCase();
      //return date.format('ddd D MMMM YYYY');
    },

    handleRowClick(element) {
      if (!this.onClick) return;
      this.onClick(element.key, this.day, this.elements);
    },
  },

};
</script>


<style lang="scss" scoped>

.row-section {
  width: 100%;
  position: relative;
  background: white;
  border-top: 1px solid $theme-list-divider-color;
  border-bottom: 1px solid $theme-list-divider-color;
  //overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-width: 0;
  max-width: 100%;

  /*
  cursor: pointer;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }*/


  &__content {
    flex: 1 1 auto;

    &__date {
      position: sticky;
      top: 60px;
      background: white;
      z-index: 2;
      display: block;
      color: black;
      font-family: $base-font-family;
      font-size: $font-size-sm;
      font-weight: bold;
      padding: 10px $padding-section;

      &--past {
        color: $theme-2-color;
      }
    }
  }

  &__handle {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    background: $theme-border;
    color: white;

    & > i {
      font-size: 20px !important;
    }
  }
}

</style>
