<template>
  <div :class="mainClass">
    <div :class="`${mainClass}__row`">
      <div :class="`${mainClass}__row__col1`">
        <h2
          ref="title"
          :class="`${mainClass}__row__col1__title`">
          {{ serviceName }}
        </h2>
      </div>
      <div :class="`${mainClass}__row__col2`">
        <!--span :class="`${mainClass}__row__col2__duration`">
          {{ duration }}
        </span-->
        <IlmButton
          :on-click="handleDeleteClick"
          :label="removeButtonLabel"
          :class="removeButtonClasses"
          prevent-default
          theme="simple" />
      </div>
    </div>

    <div :class="`${mainClass}__row`">
      <div :class="`${mainClass}__row__col1`">
        <span :class="`${mainClass}__row__col1__price`">
          {{ price }}
        </span>
        <!--div
          v-if="hasDiscount"
          :class="`${mainClass}__row__col1__discount`">
          <IlmDiscountBadge
            :value="discountValue"
            :class="`${mainClass}__row__col1__discount__badge`" />
        </div-->
      </div>
      <div :class="`${mainClass}__row__col2`">
        <div
          v-if="hasDiscount"
          :class="`${mainClass}__row__col2__discount`">
          <IlmDiscountBadge
            :value="discountValue"
            :class="`${mainClass}__row__col2__discount__badge`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmDiscountBadge from '@/components/IlmIcon/IlmDiscountBadge.vue';
import formatter from '@/utils/formatter.js';

export default {
  name: 'AppointmentServiceRow',

  // Components ********************************
  components: {
    IlmButton,
    IlmDiscountBadge,
  },

  // Properties ********************************
  props: {
    service: {
      type: Object,
      required: true,
    },
    index: Number,
    isSingleChild: {
      type: Boolean,
      default: true,
    },
    onItemDeleted: Function,
  },

  // Computed ********************************
  computed: {
    mainClass() {
      return 'appointment-service-row';
    },
    serviceName() {
      return this.service.name;
    },
    removeButtonLabel() {
      return this.isSingleChild ? this.$t('section_list.appointment_service_list_change') : this.$t('section_list.appointment_service_list_remove');
    },
    removeButtonClasses() {
      const classes = {};
      classes[`${this.mainClass}__row__col2__button`] = true;
      classes[`${this.mainClass}__row__col2__button--single`] = this.isSingleChild;
      return classes;
    },
    hasDiscount() {
      if (this.service.discount) {
        return true;
      }
      return false;
    },
    discountValue() {
      //return this.service.discount !== null ? this.service.discount.maxValue : '';
      if (this.service.discount) {
        return this.$t('button.discount_variable_label', { value: this.service.discount.maxValue });
      }
      return null;
    },
    price() {
      /*
      // NO USE: use Vue filters instead
      const price = this.element.price;
      if (price) {
        return formatter.formatPrice(price);
      }*/
      if (this.service.isPriceUnavailable === true) {
        return this.$t('section_list.service_price_under_evaluation');
      }
      const price = this.service.price;
      if (price > 0) {
        const priceText = formatter.formatPrice(price, this.businessLocale);
        if (this.service.isPriceVariable === true) {
          return this.$t('section_list.service_price', { price: priceText });
        }
        if (this.service.estimate === true) {
          return this.$t('section_list.service_price_estimate', { price: priceText });
        }
        return priceText;
      }
      return '';
    },
    duration() {
      if (this.service.duration) {
        const duration = this.service.duration;
        //const duration = this.element.category.value;
        return formatter.formatDuration(duration);
      }
      return null;
    },
    businessLocale() {
      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }
      return locale;
    },
  },

  // Methods ********************************
  methods: {
    handleDeleteClick() {
      if (!this.onItemDeleted) return;
      this.onItemDeleted(this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment-service-row {
  position: relative;
  background: white;
  padding: 12px $padding-section;
  border-radius: 2px;
  //box-shadow: 0px 4px 4px 0px rgba(156,156,156,0.3);
  //box-shadow: $box-shadow-bottom;
  border: 1px solid $theme-list-divider-color;

  &__row {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &__col1 {
      display: inline-flex;
      padding-right: 10px;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;

      &__title {
        font-family: $heading-font-family;
        font-size: 20px;
        color: black;
        margin: 0;
        line-height: 1.2em;
        margin-bottom: 10px;
      }

      &__price {
        font-family: $heading-font-family;
        font-size: 18px;
        color: black;
        margin: 0;
      }

      &__discount {
        font-family: $heading-font-family;
        font-size: 16px;
        color: black;
        line-height: 1.2em;
        padding: 0 $padding-section;

        &__badge {
          margin-right: 2px;
        }
      }
    }

    &__col2 {
      flex: 0 0 auto;
      align-self: flex-start;

      &__duration {
        font-family: $heading-font-family;
        font-size: 12px;
        color: $theme-3-color;
        line-height: 1.2em;
        text-align: right;
      }

      &__button {
        //margin-right: -10px;
        padding-right: 0;
        text-align: right;
        font-size: 12px;
        color: $theme-button-destructive-color;
        padding-top: 0;

        &--single {
          color: $theme-link-color;
        }
      }
    }
  }
}
</style>
