
<template>
  <div class="services-container">
    <!--div
      v-for="(item, index) in items"
      :key="index">
      <service-category-list-row
        v-if="item.docCount > 1"
        :element="item"
        :on-item-selected="handleCategoryClick"
        :show-divider="true" />
      <summary-row
        v-if="item.docCount == 1"
        :element="item.services[0]"
        :on-item-selected="handleCategoryClick"
        :show-divider="index < items.length-1" />
    </div-->
    <summary-row
      v-for="(item, index) in items"
      :key="index"
      :element="item"
      :search-text="searchText"
      :show-category="showCategory"
      :on-item-selected="handleRowClick"
      :show-divider="index < items.length-1" />
  </div>
</template>


<script>
import SummaryRow from './SummaryListRow.vue';

export default {
  name: 'ServicesList',

  // Components ********************************
  components: {
    SummaryRow,
  },


  // Properties ********************************
  props: {
    searchText: String,
    items: {
      type: Array,
      required: true,
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
    onItemSelected: Function,
  },

  // Methods ********************************
  methods: {
    handleRowClick(item) {
      if (!this.onItemSelected) return;
      this.onItemSelected(item);
    },
  },
};
</script>


<style lang="scss" scoped>

.services-container {
  display: block;
  padding: 0;
  touch-action: auto;
}


</style>
