class Appointment {
  constructor() {
    this.key = null;
    this.date = null;
    this.day = null;
    this.time = null;
    this.service = null;
    this.professional = null;
    this.notes = null;
    this.state = null;
    this.isLocked = null;
    this.checkoutState = null;
    this.discount = null;
  }

  static typecast(obj) {
    const finalObj = Object.assign(new Appointment(), obj);
    return finalObj;
  }

  getIsLocked() {
    return this.isLocked && this.isLocked === true;
  }

  getState() {
    return this.checkoutState === 'pending' ? 'pending' : this.state;
  }

  equals(appointment) {
    if (this.key !== appointment.key) {
      return false;
    }
    if (this.day !== appointment.day) {
      return false;
    }
    if (this.time !== appointment.time) {
      return false;
    }
    if (this.service.id !== appointment.service.id) {
      return false;
    }
    if (this.professional.id !== appointment.professional.id) {
      return false;
    }
    return true;
  }
}

export default Appointment;
