import dateUtils from '@/utils/dateUtils.js';
import utils from '@/utils/utils';

const DEFAULT_STATES = ['booked', 'canceled', 'paid', 'noshow'];
class MyAppointment {
  constructor(obj) {
    this.key = obj.key;
    this.day = obj.day;
    this.time = obj.time;
    this.service = {
      id: obj.serviceId,
      name: utils.capitalizeWords(obj.serviceName),
      price: obj.price,
      value: obj.finalPrice ? obj.finalPrice : obj.price,
      duration: obj.duration,
      category: obj.serviceCategory,
    };
    this.professional = {
      id: obj.professionalId,
      name: obj.professionalName,
      profileImageUrl: obj.professionalProfileImageUrl !== '' ? obj.professionalProfileImageUrl : null,
    };
    //this.serviceId = obj.serviceId;
    //this.serviceName = obj.serviceName;
    //this.professionalId = obj.professionalId;
    //this.professionalName = obj.professionalName;
    this.notes = obj.clientNotes;
    this.state = obj.state;
    if (!this.state || DEFAULT_STATES.includes(this.state) === false) {
      this.state = DEFAULT_STATES[0];
    }
    this.isLocked = obj.isLocked;
    this.checkoutState = obj.checkoutState;
    this.discountId = obj.discountId;
    this.cancelReason = obj.cancelReason;
    this.isPriceUnavailable = obj.isPriceUnavailable;
  }

  getIsLocked() {
    return this.isLocked && this.isLocked === true;
  }

  getState() {
    return this.checkoutState === 'pending' ? 'pending' : this.state;
  }

  isFuture() {
    if (this.day && this.time) {
      const day = this.day;
      const time = this.time;
      const moment = dateUtils.getMoment();

      const timezone = dateUtils.getTimezone();
      const date = moment.tz(`${day} ${time}`, timezone);
      const now = moment.tz(new Date(), timezone);
      return date.isAfter(now);
    }
    return false;
  }
}

export default MyAppointment;
