import AvailabilityDay from './availabilityDay';

class AvailabilityDays {
  constructor(data) {
    this.days = {};

    if (data) {
      //data.forEach(element => this.search.push(new Search(element)));
      const self = this;
      data.forEach(function (element) {
        self.days[element.day] = new AvailabilityDay(element);
      });
    }
  }

  getSlotsInDay(day) {
    return this.days[day];
  }
}

export default AvailabilityDays;
