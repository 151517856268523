import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vue2TouchEvents from 'vue2-touch-events';
import * as Sentry from '@sentry/browser';
//import VueGtag from 'vue-gtag';
import smoothscroll from 'smoothscroll-polyfill';
import Sticky from '@/components/Utils/Sticky.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import constants from './utils/constants';
import utils from './utils/utils';
import translations from './locales/translations';
import dateUtils from './utils/dateUtils';
import requests from './graphql/requests';
import LocalStorage from './store/localStorage';
import fb from './libs/facebook';
//import mixpanel from './libs/mixpanel';
import analytics from './libs/analytics/analytics';
import webApp from './libs/webApp';
import VueCardFormat from './utils/payment/index';
import gdprCookieNotice from './libs/cookie-consent/index';

console.log('APP_VERSION', process.env.APP_VERSION);

if (process.env.VUE_APP_DEPLOY_TARGET !== 'production') {
  Vue.config.productionTip = true;
} else {
  Vue.config.productionTip = false;
}


/**
 * Sentry Lib
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,
    ignoreErrors: ['Network Error', 'Request aborted'],
  });

  Vue.prototype.$sentry = Sentry;
}

/**
 * Google Analytics
 */
//if (process.env.NODE_ENV === 'production') {
/*Vue.use(VueGtag, {
  bootstrap: false,
}, router);*/
/*Vue.use(VueGtag, {
  config: {
    //id: process.env.VUE_APP_ANALYTICS_TRACKING_ID,
    id: 'UA-76110349-6',
  },
  includes: [
    {
      id: 'G-V189NT751M',
      params: {
        send_page_view: false,
      },
    },
    /*{
      id: 'AW-681505556',
      params: {
        send_page_view: false,
      },
    },*/
/*
  ],
}, router);*/
//}

/**
 * Mixpanel Lib
 */
//Vue.prototype.$mixpanel = mixpanel.init();

/**
 * Analytics Lib
 */
Vue.prototype.$analytics = analytics.init();


/**
 * Moment Lib
 */
const moment = require('moment-timezone');

//moment.tz.setDefault('America/Sao_Paulo');
Vue.use(require('vue-moment'), {
  moment,
});

/**
 * TouchEvents
 */
Vue.use(Vue2TouchEvents);

/**
 * ilove.me Constants
 */
Vue.prototype.$constants = constants;
/**
 * ilove.me DateUtils
 */
Vue.prototype.$dateUtils = dateUtils;
/**
 * ilove.me localStorage
 */
Vue.prototype.$localStorage = LocalStorage;
/**
 * facebook lib
 */
Vue.prototype.$facebook = fb;
/**
 * ilove.me Graphql for API requests
 */
requests.setSentry(Sentry);
Vue.prototype.$graphql = requests;

/**
 * Sticky Component
 */
Vue.component('Sticky', Sticky);


/**
 * Custom Lib for Payment Cards validation
 * https://github.com/wuori/vue-credit-card-validation
 */
Vue.use(VueCardFormat);


/**
 * VueI18n translations
 * Create VueI18n instance with options
 */
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'pt-BR', // set locale
  fallbackLocale: 'pt-BR',
  messages: translations, // set locale messages
});


/**
 * Vue Filters
 * https://scotch.io/tutorials/how-to-create-filters-in-vuejs-with-examples
 */
Vue.filter('price', function (value) {
  //return `R$ ${value},00`;
  const result = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 });
  return `${result}`;
});


/**
 * Debounce
 * https://jsfiddle.net/hej7L1jy/2/
 */
function debounce(fn, delay = 300) {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID);

    const args = arguments;
    const that = this;

    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}


// this is where we integrate the v-debounce directive!
// We can add it globally (like now) or locally!
Vue.directive('debounce', (el, binding) => {
  if (binding.value !== binding.oldValue) {
    // window.debounce is our global function what we defined at the very top!
    el.oninput = debounce(function () {
      el.dispatchEvent(new Event('change'));
    }, parseInt(binding.value, 10) || 300);
  }
});


/*
// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: (el) => {
    // Focus the element
    el.focus();
  },
});
*/


// Register a global custom directive called `v-test` for unit tests identifying
Vue.directive('test', (el, binding) => {
  if (process.env.NODE_ENV === 'test') {
    Object.keys(binding.value).forEach(value => {
      el.setAttribute(`data-test-${value}`, binding.value[value]);
    });
  }
});


/**
 * Initiate Vue /////////////////////////////////////////
 *
 */
new Vue({
  i18n,
  data: {
    loading: false,
    isProduction: true,
    isStandalone: false,
    isDesktop: false,
    cookieData: null,
  },
  router,
  store,
  created() {
    if (utils.isIos()) {
      const body = document.body;
      body.classList.add('os--ios');

      // for smooth scroll in unsupported browsers (safari...)
      smoothscroll.polyfill();
      window.__forceSmoothScrollPolyfill__ = true;
    }

    this.$dateUtils.setTranslation(i18n);
    this.$moment.locale('pt-br');

    if (process.env.VUE_APP_DEPLOY_TARGET === 'staging') {
      window.analytics = this.$analytics;
    } else if (process.env.VUE_APP_DEPLOY_TARGET !== 'production') {
      this.isProduction = false;
      this.$localStorage.isProduction = false;
    }
    this.isStandalone = utils.isInStandaloneMode();

    const self = this;
    this.$nextTick(function () {
      self.handleResize();
      window.addEventListener('resize', self.handleResize);
    });

    webApp.initServiceWorker();
    webApp.init(this.isStandalone);

    //this.checkCookieConsent();
    analytics.disableTracking();
  },
  mounted() {
    //webApp.initServiceWorker();
    //webApp.init(this.isStandalone);
  },
  methods: {
    handleResize() {
      this.isDesktop = document.body.clientWidth >= constants.MIN_SCREEN_WIDTH;
    },
    bypassApi() {
      return false;
      //return (process.env.NODE_ENV === 'development' && this.$constants.BYPASS_API);
    },
    showPWAPrompt() {
      //webApp.showPrompt();
    },

    checkCookieConsent() {
      analytics.disableTracking();
      /*if (window.parent && window !== window.parent) {
        return;
      }*/

      if (document.body.getAttribute('cookie-consent-shown') !== 'true') {
        document.body.setAttribute('cookie-consent-shown', 'true');
        document.addEventListener('gdprCookiesEnabled', e => {
          this.cookieData = e.detail;

          if (e.detail.analytics) { //checks if analytics cookies are enabled
            analytics.enableTracking();
          }

          if (e.detail.performance) {
            const auth = this.$store.getters.getAuth;
            if (this.$sentry && auth && auth.business.id) {
              this.$sentry.configureScope(scope => {
                scope.setTag('businessId', auth.business.id);
                scope.setTag('businessName', auth.business.name);
                scope.setTag('pageId', auth.pageId);

                if (auth.isAuthenticated()) {
                  scope.setUser({
                    id: auth.client.customerId,
                    username: auth.client.name,
                  });
                }
              });
            }
          }
        });
        gdprCookieNotice({
          locale: 'pt-br',
          timeout: 1000, //Time until the cookie bar appears
          expiration: 365, //This is the default value, in days
          domain: '.agende.online',
          implicit: false, //Accept cookies on page scroll automatically
          statement: 'https://ilove.me/privacy-policy.html#cookies', //Link to your cookie statement page
          performance: [
            this.isProduction ? 'user_token' : 'user_token_stg',
          ], //Cookies in the performance category.
          analytics: [
            'ga',
            'mp',
          ], //Cookies in the analytics category.
          //marketing: ['SSID'], //Cookies in the marketing category.
        });
      }
    },
  },
  render: h => h(App),
}).$mount('#app');
