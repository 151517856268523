class ResponseAddress {
  constructor(obj) {
    this.uid = obj.uid;
    this.country = obj.country;
    this.zipcode = obj.zipcode;
    this.street = obj.street;
    this.door = obj.streetNumber;
    this.doorExtra = obj.complement;
    this.neighbourhood = obj.neighborhood;
    this.city = obj.city;
    this.state = obj.state;
    this.isPrefered = obj.isPrefered;
  }
}

export default ResponseAddress;
