<template>
  <div>
    <div
      :class="containerClassNames">
      <div class="country-code-container">
        <div
          class="country-code-container__icon-container"
          @click="handleCountryCodeClick">
          <IlmIcon
            class="country-code-container__icon-container__icon"
            icon="smartphone"
            size="xl" />
        </div>
        <IlmSelect
          :disabled="isDisabled"
          :label="countryCodeText"
          :on-click="handleCountryCodeClick"
          class="country-code-container__select" />
      </div>
      <div class="input-container">
        <input
          ref="inputf"
          v-model.trim="phone"
          :placeholder="inputPlaceholder"
          :name="name"
          :disabled="isDisabled"
          :class="inputClassNames"
          pattern="[0-9]*"
          type="text"
          class="input-field"
          inputmode="numeric"
          autofocus
          @focus="handleFocus(true)"
          @blur="handleFocus(false)"
          @keyup.enter="handleEnter"
          @keyup="handleKeyUp">
      </div>
      <div
        v-show="showClearButton"
        class="input-clear-container"
        @click="handleClear">
        <IlmIcon
          class="input-icon input-icon--clear"
          icon="cancel"
          size="xl" />
      </div>
    </div>
    <div
      v-if="showError === true"
      class="error-container">
      <IlmIcon
        class="error-container__icon"
        icon="warning"
        size="sm" />
      {{ $t('errors.invalid_phone') }}
    </div>

    <ModalCountryCode
      v-if="modalCountriesShowing"
      ref="country_code_modal"
      :selected-country="selectedCountry"
      :countries="countries"
      show-country-code
      @selected="onCountryCodeSelected"
      @closed="onModalClosed" />
  </div>
</template>


<script>
import axios from 'axios';
import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import ModalCountryCode from '@/components/Modals/ModalCountryCode.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import IlmSelect from '@/components/Buttons/IlmSelect.vue';
import utils from '@/utils/utils';

import CountryCodes from '../../json/country_codes.json';
import { stripDiacritics } from '../../utils/matcher.js';

async function loadCountries(filename) {
  //return import(`../../locales/${filename}.json`);
  //return import(/* webpackMode: "eager" */ path);
  const baseURL = process.env.VUE_APP_PUBLIC_PATH || '/';
  console.log('baseURL', baseURL);
  const path = `/json/${filename}.json`;
  const httpAxios = axios.create({ baseURL });
  const response = await httpAxios.get(path);
  return response;
}

export default {
  name: 'PhoneBox',

  // Components ********************************
  components: {
    ModalCountryCode,
    IlmIcon,
    IlmSelect,
  },

  // Properties ********************************
  props: {
    placeholderKey: String,
    placeholder: String,
    name: String,
    type: String,
    disabled: Boolean,
    onChange: Function,
  },

  // Data ********************************
  data() {
    return {
      hasFocus: false,
      businessLocale: '',
      phone: '',
      countryCodes: CountryCodes,
      countries: null,
      selectedCountry: null,
      modalCountriesShowing: false,
      showError: false,
    };
  },

  // Computed ********************************
  computed: {
    isDisabled() { return this.disabled; },

    showClearButton() { return !!this.phone; },

    inputPlaceholder() {
      if (this.placeholderKey || this.placeholder) {
        return this.placeholderKey ? this.$t(this.placeholderKey) : this.placeholder;
      }
      return this.countryIso === 'BR' ? this.$t('placeholders.phone_box_br') : this.$t('placeholders.phone_box');
    },

    inputClassNames() {
      const classNames = {
        'input-field--disabled': this.isDisabled,
        'input-field--iconized': this.icon,
      };
      return classNames;
    },

    containerClassNames() {
      const classNames = {
        container: true,
        'container--disabled': this.isDisabled,
        'container--focused': this.hasFocus,
      };
      return classNames;
    },

    countryCodeText() {
      return `+${this.getCountryCode()}`;
      //return this.selectedCountry ? `+${this.selectedCountry.code}` : '';
    },

    countryIso() {
      if (!this.selectedCountry || !this.selectedCountry.iso) {
        if (this.businessLocale === 'pt-PT') {
          return 'PT';
        }
        return 'BR';
      }
      return this.selectedCountry.iso.toUpperCase();
    },
  },

  // Watchers ********************************
  watch: {
    phone(val) {
      if (this.isDisabled || !this.onChange) return;
      this.onChange(val);
    },
  },

  created() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.business && auth.business.locale) {
      this.businessLocale = auth.business.locale;
    } else {
      this.businessLocale = 'pt-BR';
    }
    this.loadCountryList();
  },

  // Mounted ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      setTimeout(function () { // timeout because set focus causes funky animation transition.
        if (self.$refs.inputf) {
          self.$refs.inputf.focus();
        }
      }, 300);
    });
  },

  // Methods ********************************
  methods: {
    loadCountryList() {
      const self = this;

      // load countries from file
      loadCountries(this.$t('file.countries')).then(function (response) {
        self.countries = [];
        const countryList = response.data;

        self.countryCodes.forEach(function (element) {
          const country = countryList[`countries.${element.iso}`];
          if (country) {
            const obj = {
              id: element.iso,
              iso: element.iso,
              code: element.phone,
              name: country,
            };
            self.countries.push(obj);

            if (obj.iso === 'br' && self.businessLocale === 'pt-BR') {
              self.selectedCountry = obj;
            } else if (obj.iso === 'pt' && self.businessLocale === 'pt-PT') {
              self.selectedCountry = obj;
            }
          }
        });

        // sort country list
        self.countries = self.countries.sort(function compare(a, b) {
          const first = stripDiacritics(a.name);
          const second = stripDiacritics(b.name);
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
      });
    },
    handleFocus(val) {
      this.hasFocus = val;

      if (val && utils.isIos()) {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 300);
      }
    },
    handleClear() {
      this.phone = '';
      this.$refs.inputf.focus();
      this.showError = false;
    },
    handleEnter() {
      this.$refs.inputf.blur();
    },
    handleKeyUp(event) {
      //this.phone = formatNumber({ country: 'BR', phone: this.phone }, 'National');
      if (event.key !== 'Backspace' && event.key !== 'Delete') {
        const formatter = new AsYouType(this.countryIso);
        this.phone = formatter.input(this.phone);
      }
      this.showError = false;
    },
    handleCountryCodeClick() {
      if (this.countries) {
        this.modalCountriesShowing = true;
      }
    },
    onModalClosed() {
      this.modalCountriesShowing = false;
    },
    onCountryCodeSelected(iso) {
      this.onModalClosed();

      this.selectedCountry = this.countries.filter(function (item) {
        return item.iso === iso;
      })[0];
      if (this.phone && this.phone.length > 0) {
        const formatter = new AsYouType(this.countryIso);
        this.phone = formatter.input(this.phone);
      }
      this.$refs.inputf.focus();
    },
    getFullPhoneNumber() {
      //const formatter = new AsYouType(this.countryIso);
      //formatter.input(this.phone);
      //console.log(formatter.getNumber);
      if (this.selectedCountry && this.phone) {
        return `+${this.selectedCountry.code} ${this.phone}`;
      }
      return '';
    },
    getPhoneNumber() {
      return this.phone.replace(/\D/g, '');
    },
    getCountryCode() {
      if (!this.selectedCountry) {
        if (this.businessLocale === 'pt-PT') {
          return '351';
        }
        return '55';
      }
      return this.selectedCountry.code;
    },
    closeCountryModal() {
      this.$refs.country_code_modal.close();
    },
    checkValid() {
      let valid = false;
      if (this.countryIso === 'BR') {
        valid = this.validateBrazilPhone();
      } else if (this.countryIso === 'PT') {
        valid = this.validatePortugalPhone();
      } else {
      /*
        const formatter = new AsYouType(this.countryIso);
        const fullPhone = formatter.input(this.getPhoneNumber()).isValidNumber;
        console.log(fullPhone);
        const phoneNumber = parsePhoneNumber(`Call: ${fullPhone}`, this.countryIso);
        console.log(phoneNumber);
        valid = phoneNumber.isValid();*/
        valid = true;
      }
      this.showError = !valid;
      return valid;
    },
    validateBrazilPhone() {
      const num = this.getPhoneNumber();
      if (num.length !== 11) {
        return false;
      } if (num.charAt(2) !== '9') {
        return false;
      }
      return true;
    },
    validatePortugalPhone() {
      const num = this.getPhoneNumber();
      if (num.length !== 9) {
        return false;
      } if (num.charAt(0) !== '9') {
        return false;
      }
      return true;
    },
  },
};
</script>


<style lang="scss" scoped>

.input-header {
  text-align: left;
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.2em;

  padding: 10px $padding-section 8px $padding-section;
  border-top: 1px solid $theme-list-divider-color;
}

.container {
  background: white;
  display: flex;
  position: relative;
  /*background: $theme-3-bg;*/
  /*border: 1px solid $box-border-color;*/
  //border-radius: $theme-border-radius;
  //padding: 5px $padding-section;
  //padding-top: 10px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  //border-bottom: 1px solid $theme-list-divider-color;
  border: 1px solid $theme-list-divider-color;
  border-radius: $theme-border-radius;
  //margin: 0 18px;
  box-sizing: border-box;

  &--focused {
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }

}

.country-code-container {
  display: flex;
  position: relative;

  flex: 0 0 auto;
  align-items: center;
  height: 50px;
  cursor: pointer;

  &__icon-container {
    padding: 13px 12px;
    background: $theme-2-bg;
    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: $theme-border-radius - 2px;
    border-bottom-left-radius: $theme-border-radius - 2px;

    &__icon {
      color: black;
    }
  }

  &__select {
    padding-left: 12px;
  }
}

.input-container {
  margin-left: 14px;
  flex: 1 1 auto;

  display: inline-block;
  position: relative;
  width: 100%;
}

.input-field {
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-lg;
  display: inline-block;
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;

  &:focus, &:active {
    outline: none;
    // border: none;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

.error-container {
  text-align: center;
  //padding: 4px $padding-section 0 $padding-section;
  padding: 2px $padding-section 0 0;
  color: red;
  font-size: 14px;
  margin-top: 6px;

  &__icon {
    margin-right: 2px;
  }
}


.input-icon {
  color: $theme-3-color;
  margin-right: 6px;
  flex: 0 0 auto;

  &--clear {
    margin-right: 0;
    margin-left: 6px;
  }
}

.input-clear-container {
  cursor: pointer;
  margin-top: 6px;
  margin-right: 8px;

  @media (hover) {
    &:hover i {
      color: $theme-link-color;
    }
  }
}


</style>
