<template>
  <div class="voucher_success">
    <h2 class="voucher_success__title">
      {{ $t('voucher.voucher_success_title') }}
    </h2>
    <h1 class="voucher_success__value">
      {{ valueTitle }}
    </h1>

    <p class="voucher_success__text">
      {{ $t('voucher.voucher_success_text1') }}
    </p>
    <p class="voucher_success__text">
      {{ $t('voucher.voucher_success_text2') }}
    </p>

    <div class="voucher_success__footer">
      <div class="voucher_success__footer__details">
        <span class="voucher_success__footer__details__price_label">
          {{ $t('payment.payed_total') }}
        </span>
        <span class="voucher_success__footer__details__price_value">
          {{ value }}
        </span>
      </div>
      <div
        class="voucher_success__footer__details
        voucher_success__footer__details--card_container">
        <div class="voucher_success__footer__details__card_type">
          <img
            :src="cardImage"
            class="voucher_success__footer__details__card_type__icon">
        </div>
        <div class="voucher_success__footer__details__dropdown">
          <div class="voucher_success__footer__details__dropdown__text">
            <span class="voucher_success__footer__details__dropdown__text__name">
              {{ cardInformationName }}
            </span>
            <span class="voucher_success__footer__details__dropdown__text__card">
              {{ cardInformation }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatter from '@/utils/formatter.js';
import cardUtils from '@/utils/payment/utils';

export default {

  props: {
    customer: Object,
  },

  computed: {
    valueTitle() {
      return this.customer && this.customer.voucher
        ? formatter.formatPrice(this.customer.voucher.value, this.businessLocale) : '';
    },
    value() {
      return this.customer && this.customer.voucher
        ? formatter.formatPrice(this.customer.voucher.userValue, this.businessLocale) : '';
    },
    cardImage() {
      const cardType = this.customer.card.brand;
      if (cardType) {
        return cardUtils.iconCardImage(cardType);
      }
      return null;
    },
    cardInformationName() {
      return this.customer.card.holderName ? this.customer.card.holderName : '';
    },
    cardInformation() {
      const cardType = this.customer.card.brand ? this.customer.card.brand : '';
      return `${cardType.toUpperCase()} ***** ${this.customer.card.lastDigits}`;
    },
    businessLocale() {
      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }
      return locale;
    },
  },

  mounted() {
  },

};
</script>

<style lang="scss" scoped>

.voucher_success {
  color: black;
  text-align: center;
  padding: 24px $padding-section;

  &__title {
    font-size: 28px;
    line-height: 1.2em;
    margin: 0;
    font-weight: bold;
    margin-bottom: 6px;
  }
  &__value {
    font-size: 40px;
    line-height: 1.2em;
    margin: 0;
    font-weight: bold;
  }
  &__text {
    font-size: 16px;
    line-height: 1.2em;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 340px;
  }

  &__footer {
    border-top: 1px solid $theme-list-divider-color;
    margin-top: 26px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $min-screen-width) {
      flex-direction: row-reverse;
    }

    &__details {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      padding: 10px 0 0 0;

      &--card_container {
        flex: 1 1 auto;
      }

      &__price_label {
        font-weight: bold;
        font-size: 18px;
        padding-right: 6px;
      }
      &__price_value {
        font-size: 18px;
      }

      &__card_type {
        flex: 0 0 auto;
        height: 37px;
        padding-right: $padding-section;

        &__icon {
          width: 60px;
          height: 37px;
          padding: 0;
        }
      }
      &__dropdown {
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        &__text {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin: 0;
          color: black;

          &__name {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 4px;
          }
          &__card {
            font-size: 12px;
            line-height: 1;
          }
        }

        &__icon {
          flex: 0 0 auto;
        }
      }
    }
  }
}

</style>
