import Vue from 'vue';
import Router from 'vue-router';
import Root from './views/Root.vue';
import Constants from './utils/constants.js';
import store from './store';
import IlmDomain from './libs/ilmDomain';
import routes from './routes';
import PageNotFound from './views/PageNotFound.vue';

Vue.use(Router);

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

function getBusinessName() {
  const auth = store.getters.getAuth;
  if (auth && auth.business.name) {
    return auth.business.name;
  }
  return '';
}

function getBusinessUrl() {
  const auth = store.getters.getAuth;
  if (auth && auth.business.permalink) {
    return `${window.location.protocol}//${window.location.host}/${auth.business.permalink}`;
  }
  return '';
}


const url = `${window.location.host}${window.location.pathname}`;
let routing;
/*if (IlmDomain.isWhatsapp(url) || IlmDomain.isGeneral(url) || IlmDomain.isGoogle(url)
  || (Constants.TEST_USE_MESSENGER_FLOW === false && IlmDomain.isTest(url))) {
  routing = [
    {
      path: '/:permalink',
      component: Root,
      children: routes.getRoutes('', getBusinessName()),
    },
  ];
} else {
  routing = routes.getRoutes('/', getBusinessName());
}
*/
if (IlmDomain.isMessenger(url)
    || (Constants.TEST_USE_MESSENGER_FLOW === true && IlmDomain.isTest(url))) {
  routing = routes.getRoutes('/', getBusinessName());
} else {
  routing = [
    {
      path: '/:permalink',
      component: Root,
      children: routes.getRoutes('', getBusinessName()),
    },
    {
      path: '*',
      component: PageNotFound,
      props: true,
      meta: {
        title: 'Página não encontrada',
        showAccount: false,
      },
    },
  ];
}

/*
routing = [
  {
    path: '/:permalink',
    component: Root,
    children: routes.getRoutes(''),
  },
];*/

const router = new Router({
  mode: 'history',
  routes: routing,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});


function setMetaTags(to) {
  // This goes through the matched routes from last to first,
  // finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some,
  // /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  //const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse()
  //.find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = getBusinessName();
  }

  const url = getBusinessUrl();

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  let tag = document.createElement('meta');
  tag.setAttribute('property', 'og:title');
  tag.setAttribute('content', getBusinessName());
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);

  tag = document.createElement('meta');
  tag.setAttribute('property', 'og:image');
  tag.setAttribute('content', `${window.location.protocol}//${window.location.host}/images/icon.png`);
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);

  tag = document.createElement('meta');
  tag.setAttribute('property', 'og:url');
  tag.setAttribute('content', url);
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);

  tag = document.createElement('meta');
  tag.setAttribute('property', 'apple-mobile-web-app-title');
  tag.setAttribute('content', getBusinessName());
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);

  // show if business not found
  //Array.from(document.querySelectorAll('meta[name="robots"]'))
  //.map(el => el.parentNode.removeChild(el));
  if (to.name !== Constants.ROUTE_BUSINESS_DETAILS) {
    tag = document.createElement('meta');
    tag.setAttribute('name', 'robots');
    tag.setAttribute('content', 'noindex');
    tag.setAttribute('data-vue-router-controlled', '');
    document.head.appendChild(tag);
  }

  //Array.from(document.querySelectorAll('link[rel="canonical"]'))
  //.map(el => el.parentNode.removeChild(el));
  if (url) {
    tag = document.createElement('link');
    tag.setAttribute('rel', 'canonical');
    tag.setAttribute('href', url);
    tag.setAttribute('data-vue-router-controlled', '');
    document.head.appendChild(tag);
  }

  /*

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return;// next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));
  */
  //return next();
}
/*
function setLoading(isLoading) {
  router.app.loading = isLoading;
}
*/


///////////////////////////////
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  if (to.name === Constants.ROUTE_TERMS_CONDITIONS) {
    next();
    return;
  }

  setMetaTags(to, from, next);
  window.popStateDetected = false;

  const auth = store.getters.getAuth;
  let needAuthentication = false;

  if (to.name !== 'root' && to.name !== Constants.ROUTE_LOGIN && to.name !== Constants.ROUTE_VERIFY_PHONE) {
    const canResumeVerification = store.getters.getCanResumeVerification;
    if (auth && canResumeVerification && auth.needCodeConfirmation()
    /*&& (auth.needNameConfirmation() || auth.needCodeConfirmation())*/) {
      needAuthentication = true;
    }
  }

  if (to.name === Constants.ROUTE_MY_BOOKINGS || to.name === Constants.ROUTE_PAYMENT_DETAILS) {
    if (auth && !auth.isAuthenticated()) {
      needAuthentication = true;
    }
  }

  if (needAuthentication) {
    next({
      name: Constants.ROUTE_VERIFY_PHONE,
      params: { previousRouteName: to.name },
    });
  } else {
    next();
  }
});
///////////////////////////////


router.afterEach(() => {
  /*
  console.log(`router.afterEach ${to.name}`);
  if (window.popStateDetected === false
    && to.name !== 'root' && to.name !== Constants.ROUTE_LOGIN
    && to.name !== Constants.ROUTE_VERIFY_PHONE) {
    console.log('router.afterEach 1');
    const auth = store.getters.getAuth;
    const canResumeVerification = store.getters.getCanResumeVerification;
    if (auth
        && canResumeVerification
        && (auth.needNameConfirmation() || auth.needCodeConfirmation())) {
      console.log('router.afterEach 2');
      router.push({
        name: Constants.ROUTE_VERIFY_PHONE,
        params: { previousRouteName: to.name },
      });
    }
  }*/
  //setLoading(false);
  // reset the loading state
  /*setTimeout(function () {
    setLoading(false);
  }, 200); // transition animation duration*/
});


export default router;
