
<template>
  <div class="schedule-grid">
    <div
      class="schedule-grid-section">
      <div
        v-if="slots"
        :style="gridTemplateColumnsStyle"
        class="schedule-grid-section__container">
        <div
          v-for="item in items"
          :key="item.time"
          class="schedule-grid-section__container__slot-container"
          data-test-id="slot-button"
          :data-test-time="item.time"
          :data-is-available="item.available === true ? 'true' : 'false'">
          <div
            class="schedule-grid-section__container__slot-container__slot">
            <ScheduleGridSlot
              :ref="itemReference(item.time)"
              :item-slot="item"
              :active="item.selected"
              :discount="getMaxDiscountValue(item.time)"
              :max-discount="getMaxDiscount()"
              :on-item-selected="itemSelected" />
          </div>
        </div>
        <div
          v-if="isLoading"
          class="schedule-grid-section__container__loading" />
      </div>
    </div>
  </div>
</template>


<script>
import ScheduleGridSlot from '@/components/Slots/ScheduleGridSlot.vue';


export default {
  name: 'ScheduleGrid',

  // Components ********************************
  components: {
    ScheduleGridSlot,
  },

  // Properties ********************************
  props: {
    service: Object,
    discount: Object,
    selectedDate: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
    onItemSelected: Function,
  },

  // Data ********************************
  data() {
    return {
      slots: [],
      items: [],
      discounts: null,
      selectedTab: 0,
      currentHour: 0,
      isToday: false,
      business: null,
      mappingSetting: 'dynamic',

      morningPosition: null,
      afternoonPosition: null,
    };
  },


  // Computed ********************************
  computed: {
    gridTemplateColumnsStyle() {
      if (!this.business) {
        return {};
      }
      let value = '';
      if (this.business.isMappingTypeDynamic()) {
        value = '1fr 1fr 1fr 1fr 1fr 1fr';
      } if (this.business.isMappingTypeCustom()) {
        const options = this.business.getCustomMappingTypeValues();
        const size = options.length;
        value = '';
        for (let i = 0; i < size; i++) {
          value += '1fr';
          if (i < size - 1) {
            value += ' ';
          }
        }
        if (options[0] !== 0) {
          value = `1fr ${value}`;
        }
      } else {
        const type = this.business.getSlotMappingType();
        if (type === '15min') {
          value = '1fr 1fr 1fr 1fr';
        } else if (type === '10min') {
          value = '1fr 1fr 1fr 1fr 1fr 1fr';
        } else if (type === '20min') {
          value = '1fr 1fr 1fr';
        } else if (type === '30min') {
          value = '1fr 1fr';
        } else if (type === '60min') {
          value = '1fr';
        }
      }

      return {
        'grid-template-columns': value,
      };
    },
  },

  // Watchers ********************************
  watch: {
    items() {
      this.$nextTick(() => {
        const len = this.items.length;
        let s;
        let time;
        let timeSplit;
        let hour;
        let minute;
        for (let i = 0; i < len; i++) {
          s = this.items[i];
          time = s.time;
          timeSplit = time.split(':');
          hour = parseInt(timeSplit[0], 10);
          minute = parseInt(timeSplit[1], 10);
          if (hour === this.$constants.SCHEDULE_MORNING && minute === 0) {
            const reference = this.itemReference(time);
            const startElement = this.$refs[reference][0].$el;
            if (startElement) {
              this.morningPosition = startElement.offsetTop;
              //this.morningPosition = startElement.getBoundingClientRect().top;
            }
          } else if (hour === this.$constants.SCHEDULE_AFTERNOON && minute === 0) {
            const reference = this.itemReference(time);
            const startElement = this.$refs[reference][0].$el;
            if (startElement) {
              this.afternoonPosition = startElement.offsetTop;
              //this.afternoonPosition = startElement.getBoundingClientRect().top;
              break;
            }
          }
        }
      });
    },
    /*selectedTab(val) {

    },*/
  },

  // Created ********************************
  created() {
  },

  // Mounted ********************************
  mounted() {
    //const d = this.$moment(new Date());
    //d.set('second', 0);

    const auth = this.$store.getters.getAuth;
    if (auth && auth.business && auth.business.slotsMappingType) {
      this.business = auth.business;
    }
  },

  destroyed() {
    this.items = [];
  },

  methods: {
    itemReference(time) {
      return `${this.service.id}_${time.replace(':', '_')}`;
    },
    setSlots(slots) {
      this.slots = slots;
    },
    setItems(slots) {
      this.items = slots;
    },


    itemSelected(item) {
      if (this.onItemSelected) {
        this.onItemSelected(item);
      }
    },
    tabSelected(tab) {
      //onsole.log(`currentHour: ${this.currentHour} | tab: ${tab}`);
      if (this.isToday === false
        || ((this.currentHour >= this.$constants.SCHEDULE_AFTERNOON && (tab === 2))
        || (this.currentHour < this.$constants.SCHEDULE_AFTERNOON && tab > 0))
        || (this.currentHour < this.$constants.SCHEDULE_MORNING)) {
        //this.selectedTab = tab;
        /*const elem = this.$refs[this.itemReference(hour)];
        if (elem) {
          const y = this.getSlotPosition(hour);
          window.scrollTo({
            top: y + window.scrollY - 190, //rect.top - 140,
            left: 0,
            behavior: 'smooth',
          });
        }*/
      }
    },

    getMaxDiscountValue(time) {
      const weekday = this.$moment(this.selectedDate).isoWeekday();
      if (this.discounts && this.discounts.length > 0) {
        const discountValue = this.discounts.reduce((prev, current) => {
          const currentValue = current.getHourDiscount(weekday, time);
          const prevValue = prev.getHourDiscount(weekday, time);
          return (prevValue > currentValue) ? prev : current;
        });
        //console.log(`service: ${this.service.id} time: ${time} discount: ${discountValue}`);
        return discountValue.getHourDiscount(weekday, time);
      }
      return null;
    },

    getMaxDiscount() {
      const serviceId = `${this.service.id}`;
      const weekday = this.$moment(this.selectedDate).isoWeekday();
      if (this.discounts && this.discounts.length > 0) {
        let maxDiscount;
        let discountValues = [];
        this.discounts.forEach(element => {
          const currentValue = element.getDayDiscount(weekday, serviceId);
          if (maxDiscount) {
            if (currentValue > maxDiscount) {
              maxDiscount = currentValue;
            }
          } else {
            maxDiscount = currentValue;
          }
          const values = element.getDayAvailableDiscounts(weekday, serviceId);
          discountValues = discountValues.concat(values);
        });
        // check discount values > 1
        /*if (discountValues.filter((item, pos) =>
        discountValues.indexOf(item) === pos).length > 1) {
          return maxDiscount;
        }*/
        return maxDiscount;
      }
      return null;
    },

    setDiscounts(discounts) {
      this.discounts = discounts;
    },

    getSlotPosition(hour) {
      let time;
      let timeSplit;
      const length = this.items.length;
      for (let i = 0; i < length; i++) {
        time = this.items[i].time;
        timeSplit = time.split(':');
        if (parseInt(timeSplit[0], 10) === hour) {
          const eref = this.itemReference(time);
          const el = this.$refs[eref][0].$el;
          return el.getBoundingClientRect().top;
          //return el.offsetTop;
        }
      }
      return 0;
    },
    getMorningPosition() {
      return this.morningPosition;
    },
    getAfternoonPosition() {
      return this.afternoonPosition;
    },

  },

};
</script>


<style lang="scss" scoped>

.schedule-grid-section {
  position: relative;
  background: white;
  display: block;
  //padding: 10px;
  padding: 6px $padding-section - 3px;
  //border-top: 1px solid $theme-list-divider-color;
  //border-bottom: 1px solid $theme-list-divider-color;

  &__container {
    display: grid;
    /*grid-template-columns: auto auto auto auto auto auto;*/
    max-width: 700px;
    margin: 0 auto;
    padding: 0 2px;

    &__loading {
      position: absolute;
      top: 0; bottom: 0;
      left: 0;
      right: 0;
      background: white;
      opacity: 0.4;
    }

    &__slot-container {
      padding: 5px 0.5px;
      text-align: center;

      &__slot {
        width: 100%;
      }
    }
  }

  &--loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    opacity: 0.9;
    text-align: right;

    &__loader {
      margin-top: $padding-section;
      margin-right: $padding-section;
    }
  }
}

</style>
