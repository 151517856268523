class Address {
  constructor() {
    this.uid = null;
    this.country = null;
    this.cep = null;
    this.street = null;
    this.door = null;
    this.doorExtra = null;
    this.neighbourhood = null;
    this.city = null;
    this.state = null;
    this.isPrefered = null;
  }

  update(responseAddress) {
    this.uid = responseAddress.uid;
    this.country = responseAddress.country;
    this.cep = responseAddress.zipcode;
    this.street = responseAddress.street;
    this.door = responseAddress.door;
    this.doorExtra = responseAddress.doorExtra;
    this.neighbourhood = responseAddress.neighbourhood;
    this.city = responseAddress.city;
    this.state = responseAddress.state;
    this.isPrefered = responseAddress.isPrefered;
  }
}

export default Address;
