
<template>
  <div
    class="row-container"
    @click="handleClick">
    <div class="row-container__flex">
      <div
        class="row-container__flex__text"
        v-html="highlight()" />
      <div
        class="row-container__flex__counter">
        {{ $t('section_list.category_services_count', { count: item.services.length }) }}
      </div>
    </div>
    <div
      v-if="showDivider"
      class="row-divider" />
  </div>
</template>


<script>
import utils from '@/utils/utils';

export default {
  name: 'AutocompleteRow',

  // Components ********************************
  components: {
  },

  // Properties ********************************
  props: {
    item: {
      type: Object,
      required: true,
    },
    highlightSimple: {
      type: Boolean,
      default: false,
    },
    searchText: String,
    showDivider: Boolean,
    onClick: Function,
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onClick) return;
      this.onClick(this.item);
    },
    highlight() {
      if (this.highlightSimple) {
        return utils.highlightSimple(this.item.name, this.searchText, 'row-container__flex__text__highlighted', this.$constants.MIN_SEARCH_STRING_LENGTH);
      }
      return utils.highlight(this.item.name, this.searchText, 'row-container__flex__text__highlighted', this.$constants.MIN_SEARCH_STRING_LENGTH);
    },
  },
};
</script>


<style lang="scss" scoped>

.row-container {
  position: relative;
  background: white;
  padding: $padding-section;
  cursor: pointer;
  color: $theme-3-color;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  @media (hover) {
    &:hover {
      background: $list-pressed-row-background;
    }
  }
  /*
  &:hover {
    //background: $theme-3-bg;
    //background: green;
  }

  &:active {
    //background: $list-pressed-row-background;
  }
  */

  &__flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &__text {
      font-family: $heading-font-family;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2em;
      //white-space: pre;

      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;

      ::v-deep &__highlighted {
        color: black;

        &--pad-left {
          padding-left: 0.3em;
        }
        &--pad-right {
          padding-right: 0.3em;
        }
      }
    }

    &__counter {
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 12px;
      color: $theme-3-color;
      line-height: 1.2em;

      flex: 0 0 auto;

      color: $theme-simple;
    }

  }
}

.row-divider {
  position: absolute;
  bottom: 0;
  left: $padding-section;
  right: $padding-section;
  height: 1px;
  background: $theme-list-divider-color;
}

</style>
