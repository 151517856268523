
<template>
  <div class="state-badge-container">
    <span
      :class="classNames"
      class="state-badge">
      {{ badgeLabel }}
    </span>
  </div>
</template>


<script>

const states = ['booked', 'canceled', 'noshow', 'started', 'late', 'confirmed', 'waiting', 'paid', 'pending'];

export default {
  name: 'IlmStateBadge',

  // Properties ********************************
  props: {
    state: {
      type: String,
      required: true,
      validator(value) {
        return states.indexOf(value) !== -1;
      },
    },
  },

  // Computed ********************************
  computed: {
    badgeLabel() {
      return this.$t(`states.${this.state}`);
    },
    classNames() {
      return `state-badge--${this.state}`;
    },
  },

};
</script>


<style lang="scss" scoped>

.state-badge-container {
  display: inline-block;
}


.state-badge {
  display: initial;
  border-radius: 4px;
  padding: 4px 8px;
  font-family: $base-font-family;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: white;
  text-align: center;

  &--booked { background: $theme-state-booked; }
  &--late { background: $theme-state-late; }
  &--confirmed { background: $theme-state-confirmed; }
  &--started { background: $theme-state-started; }
  &--waiting { background: $theme-state-waiting; }
  &--canceled { background: $theme-state-canceled; }
  &--noshow { background: $theme-state-noshow; }
  &--paid { background: $theme-state-paid; }
  &--pending { color: $theme-state-paid; border: 1px solid $theme-state-paid; }

}


</style>
