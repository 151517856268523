<template>
  <transition name="fade">
    <div class="alert">
      <div
        class="alert__frame"
        @click="handleCloseClick" />
      <transition name="fade">
        <div
          v-if="contentShowing"
          class="alert__content">
          <div class="alert__content__slot">
            <div class="alert__content__slot__top_container">
              <h1
                v-if="title"
                class="alert__content__title">
                {{ title }}
              </h1>
              <p
                v-if="message"
                class="alert__content__message">
                {{ message }}
              </p>
            </div>

            <div class="alert__content__slot__list_container">
              <IlmButtonRadio
                v-for="(item, index) in cancelOptions"
                :key="index"
                :text="$t(`cancel.${item.key}`)"
                :name="item.key"
                :is-selected="selectedOption === item.key"
                :on-click="handleOptionClick"
                class="alert__content__slot__list_container__item" />
            </div>

            <div class="alert__content__slot__bottom_container">
              <IlmButton
                v-if="btnCancel"
                ref="btn_cancel"
                :on-click="handleCancelClick"
                :label="buttonCancelText"
                theme="simple"
                class="alert__content__btn_ok" />
              <IlmButton
                v-if="btnDestructive"
                ref="btn_ok"
                :on-click="handleDestructiveClick"
                :label="buttonDestructiveText"
                :disabled="!selectedOption"
                :theme="btnOkTheme"
                class="alert__content__btn_ok" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import IlmButtonRadio from '@/components/Buttons/IlmButtonRadio.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';

const noScrollClass = 'no-scroll';

const CANCEL_OPTIONS = [
  {
    id: 'cancel.key_1',
    key: 'change_parameters',
  },
  {
    id: 'cancel.key_2',
    key: 'cant_go',
  },
  {
    id: 'cancel.key_3',
    key: 'curiosity',
  },
  {
    id: 'cancel.key_4',
    key: 'other',
  },
];

export default {
  name: 'AlertCancelPrompt',

  // Components ********************************
  components: {
    IlmButtonRadio,
    IlmButton,
  },

  // Properties ********************************
  props: {
    title: String,
    message: String,
    btnCancel: String,
    btnDestructive: String,
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
      selectedOption: null,
      buttonDestructiveText: null,
      buttonCancelText: null,
    };
  },

  // Computed ********************************
  computed: {
    cancelOptions() {
      return CANCEL_OPTIONS;
    },
    btnOkTheme() {
      if (this.buttonDestructiveText === this.btnDestructive) {
        return 'destructive';
      }
      return 'simple';
    },
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Created ********************************
  mounted() {
    this.contentShowing = true;
    this.buttonDestructiveText = this.btnDestructive;
    this.buttonCancelText = this.btnCancel;
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleOptionClick(key) {
      this.selectedOption = key;
      this.$refs.btn_ok.setDisabled(!this.selectedOption);

      if (this.selectedOption === this.cancelOptions[0].key) {
        this.buttonDestructiveText = this.$t('alerts.alert_cancel_appointment_yes_edit');
        this.buttonCancelText = this.$t('alerts.alert_cancel_appointment_no_edit');
      } else {
        this.buttonDestructiveText = this.btnDestructive;
        this.buttonCancelText = this.btnCancel;
      }
    },
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    handleCancelClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('cancel', 'close');
      }, 200);
    },
    handleDestructiveClick() {
      this.contentShowing = false;
      const option = this.selectedOption;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('ok', option);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: 98%;
    max-width: 400px;
    overflow: hidden;
    text-align: right;

    &__slot {
      position: relative;
      display:block;
      background: white;
      margin: 0 24px;
      border-radius: $theme-border-radius;
      overflow: hidden;
      padding: 26px $padding-section 20px $padding-section;
      padding-bottom: 10px;
      max-height: 438px;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      &__top_container {
        flex: 0 0 auto;
      }

      &__list_container {
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
        overflow-y: auto;

        &__item {
          padding: 10px 0;
        }
        &__item + &__item {
          border-top: 1px solid $theme-list-divider-color;
        }
      }

      &__bottom_container {
        flex: 0 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }

    &__title {
      position: relative;
      color: black;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-lg;
      font-family: $base-font-family;
      margin: 0;
      margin-bottom: 10px;
    }
    &__message {
      position: relative;
      color: black;
      text-align: center;
      font-size: $font-size-md;
      font-family: $base-font-family;
      margin: 0;
      margin-bottom: 10px;
    }
    &__btn_ok {
      display: inline;
      margin: 0;
      font-size: $font-size-md;
      background: none !important;
      box-shadow: none;
    }
  }
}
</style>
