
<template>
  <div class="header-container">
    <div class="row1">
      <h3 class="section-title">
        {{ titleLabel }}
      </h3>
    </div>
    <div class="row2">
      <span class="section-subtitle">
        {{ subtitle }}
      </span>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HeaderExtended',

  // Properties ********************************
  props: {
    tKey: String,
    label: String,
    subtitle: String,
  },

  // Computed ********************************
  computed: {
    titleLabel() {
      return this.tKey ? this.$t(this.tKey) : this.label;
    },
  },
};
</script>


<style lang="scss" scoped>

.header-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  background: white;
}

.row1 {
  display: inline-flex;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
}

.row2 {
  display: inline-flex;
  flex: 0 0 auto;
}

.section-title {
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  margin: 0;
  text-transform: uppercase;
}

.section-subtitle {
  font-family: $heading-font-family;
  font-weight: bold;
  font-size: 12px;
  color: $theme-2-color;
  text-align: right;
  margin: 0;
  padding-left: $padding-section;
}

</style>
