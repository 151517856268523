import ResponseAddress from './responseAddress';
import ResponseCard from './responseCard';

class ResponseCustomer {
  constructor(obj) {
    this.state = obj.signupState;

    this.name = obj.name;
    this.email = obj.email;
    this.taxNumber = obj.taxNumber;

    this.addresses = [];
    this.cards = [];

    if (obj.addresses) {
      obj.addresses.forEach(element => this.addresses.push(new ResponseAddress(element)));
    }
    if (obj.cards) {
      obj.cards.forEach(element => this.cards.push(new ResponseCard(element)));
    }
  }

  hasName() {
    return this.state === 'has_name';
  }

  hasPaymentBasic() {
    return this.state === 'has_payment_basic';
  }

  hasAddress() {
    return this.state === 'has_address';
  }

  hasCard() {
    return this.state === 'has_card';
  }


  getPreferedCard() {
    if (this.cards.length > 0) {
      let card;
      for (let i = 0; i < this.cards.length; i++) {
        card = this.cards[i];
        if (card.isPrefered === true) {
          return card;
        }
      }
      for (let i = 0; i < this.cards.length; i++) {
        card = this.cards[i];
        if (card.status && card.status.toLowerCase() === 'active') {
          return card;
        }
      }
      return this.cards[0];
    }
    return null;
  }

  getPreferedAddress() {
    if (this.addresses.length > 0) {
      let address;
      for (let i = 0; i < this.addresses.length; i++) {
        address = this.addresses[i];
        if (address.isPrefered === true) {
          return address;
        }
      }
      return this.addresses[0];
    }
    return null;
  }
}

export default ResponseCustomer;
