import utils from '@/utils/utils';

export default {
  getQueryAccessToken(app, signedRequest, permalink) {
    if (permalink) {
      if (signedRequest) { // token
        return `{
          getAccessToken (source: "${app}", token:"${signedRequest}", permalink: "${permalink}") {
            token
            intent
          }
        }`;
      }
      return `{
        getAccessToken (source: "${app}", permalink: "${permalink}") {
          token
          intent
        }
      }`;
    }
    return `{
      getAccessToken (source: "${app}", token: "${signedRequest}") {
        token
        intent
      }
    }`;
  },


  getQueryCurrentBusiness() {
    return `{
      currentBusiness {
        id
        timezone
        locale
        brand
        category
        phoneCountry
        phoneNumber
        settings {
          b2cSlotsType
          b2cAdvanceTime
          b2cActiveModules
          b2cMinTimeToCancel
          b2cMinTimeToUpdate
          b2cMinInterval
        }
        publicSchedules {
          weekday
          timeIntervals
        }
        schedules {
          weekday
          timeIntervals
        }
        addressPostal
        addressState
        addressCity
        addressNeighbourhood
        addressStreet
        addressLatitude
        addressLongitude
        addressCountry
      }
    }`;
  },

  getQueryBusinessDiscounts() {
    return `{
      forClient {
        id
        values
        campaignId
        conditions
        valuesDescription
        conditionsDescription
        isFeatured
        sortWeight
      }
    }`;
  },

  getQueryBusinessServices() {
    return `{
      list {
        id
        name
        price
        duration
        category
        isPriceVariable
        description
        isPriceUnavailable
      }
    }`;
  },

  getQueryServicesDetails(serviceIds) {
    const stringsArray = serviceIds.map(x => `"${x}"`);
    const paramsString = `[${stringsArray.join(',')}]`;

    return `{
      get (ids: ${paramsString}) {
        id
        name
        price
        duration
        category
        isPriceVariable
        description
        isPriceUnavailable
      }
    }`;
  },

  getQuerySuggestions(clientSize, businessSize) {
    return `{
      suggestions (clientSize: ${clientSize}, businessSize: ${businessSize}) {
        name
        groupType
        groupKey
        docCount
        minPrice 
        maxPrice 
        minDuration 
        maxDuration
        score
        services {
          id
          name
          price
          duration
          category
          isPriceVariable
          description
          isPriceUnavailable
        }
      }
    }`;
  },

  getQuerySearch(term) {
    return `{
      search (term: "${term}") {
        name
        groupType
        groupKey
        docCount
        minPrice 
        maxPrice 
        minDuration 
        maxDuration
        score
        services {
          id
          name
          price
          duration
          category
          isPriceVariable
          description
          isPriceUnavailable
        }
      }
    }`;
  },

  getQuerySearchByOption(key, type) {
    return `{
      listByOption (optionKey: "${key}", optionType: "${type}") {
        name
        price 
        duration 
        category 
        isPriceVariable
        description
        isPriceUnavailable
      }
    }`;
  },

  /*
  getQueryProfessionals(serviceId) {
    return `{
      professionalsForService (serviceId: "${serviceId}") {
        id
        name
        weekSchedule
      }
    }`;
  },*/

  getQueryProfessionalsServices(serviceIds) {
    const stringsArray = serviceIds.map(x => `"${x}"`);
    const paramsString = `[${stringsArray.join(',')}]`;

    return `{
      professionalsForServices (serviceIds: ${paramsString}) {
        id
        name
        weekSchedule
        serviceIds
        profileImageUrl
      }
    }`;
  },

  getQueryProfessionals(professionalIds) {
    const stringsArray = professionalIds.map(x => `"${x}"`);
    const paramsString = `[${stringsArray.join(',')}]`;
    return `{
      professionals (professionalIds: ${paramsString}) {
        id
        name
        weekSchedule
        serviceIds
        profileImageUrl
      }
    }`;
  },


  getQueryAvailabilities(startDate, endDate, serviceId, professionalId, appointmentKey) {
    return `{
      inDays (sinceDay: "${startDate}", untilDay: "${endDate}", serviceId: "${serviceId}", professionalId: "${professionalId !== undefined && professionalId !== null ? professionalId : ''}",
      ignoreKey: "${appointmentKey !== undefined && appointmentKey !== null ? appointmentKey : ''}") {  
        day
        slots {
          time
          price
          professionals
        }  
      }
    }`;
  },

  getQueryServicesAvailabilities(startDate, endDate, appointments, appointmentKey) {
    const size = appointments.length;
    let index = 0;
    let paramsString = '[';

    appointments.forEach(element => {
      paramsString += '{';
      paramsString += `serviceId: "${element.service.id}"`;
      if (element.professional) {
        paramsString += `, professionalId: "${element.professional.id}"`;
      } else {
        paramsString += ', professionalId: ""';
      }
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }
      index++;
    });
    paramsString += ']';

    return `{
      inDaysBulk (sinceDay: "${startDate}", untilDay: "${endDate}", servicePairs: ${paramsString},
      ignoreKey: "${appointmentKey !== undefined && appointmentKey !== null ? appointmentKey : ''}") {
        serviceId
        professionalId
        availabilities {
          day
          slots {
            time
            price
            professionals
          }
        }
      }
    }`;
  },

  getQueryProfessionalsAvailable(day, time, serviceId) {
    return `{
      inSlot (day: "${day}", time: "${time}", serviceId: "${serviceId}") {  
        id
        name
      }
    }`;
  },

  getQuerySaveAppointment(appointments) {
    //const appointments = [appointment];

    const size = appointments.length;
    let index = 0;
    let paramsString = '[';

    appointments.forEach(element => {
      paramsString += '{';
      paramsString += `day: "${element.day}", 
        time: "${element.time}",
        serviceId: "${element.service.id}"`;
      if (element.professional) {
        paramsString += `, 
          professionalId: "${element.professional.id}"`;
      }
      if (element.discount) {
        //const discountValue = element.discount.getDayDiscount(element.date.isoWeekday(),
        //element.service.id);
        const discountValue = element.discount.getHourDiscount(
          element.date.isoWeekday(),
          element.time
        );
        if (discountValue) {
          paramsString += `,discountId: "${element.discount.id}"`;
          if (element.discount.campaignId && element.discount.campaignId !== '') {
            paramsString += `,campaignId: "${element.discount.campaignId}"`;
          }
        }
      }
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    let query = `mutation {
      create (appointments: ${paramsString}`;

    query += `) {
        key
        professionalId
        serviceId
        time
        day
        discountId
        campaignId
      }
    }`;

    return query;
  },

  getQueryUpdateAppointment(appointments) {
    const size = appointments.length;
    let index = 0;
    let paramsString = '[';

    appointments.forEach(element => {
      paramsString += '{';
      paramsString += `key: "${element.key}", 
        day: "${element.day}", 
        time: "${element.time}",
        serviceId: "${element.service.id}"`;
      if (element.professional) {
        paramsString += `, 
          professionalId: "${element.professional.id}"`;
      } else {
        paramsString += ',professionalId: ""';
      }
      if (element.discount) {
        //const discountValue = element.discount.getDayDiscount(element.date.isoWeekday(),
        //  element.service.id);
        const discountValue = element.discount.getHourDiscount(
          element.date.isoWeekday(),
          element.time
        );
        if (discountValue) {
          paramsString += `,discountId: "${element.discount.id}"`;
        } else {
          paramsString += ',discountId: null';
        }
      } else {
        paramsString += ',discountId: null';
      }
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    let query = `mutation {
      update (appointments: ${paramsString}`;


    query += `) {
        key
        professionalId
        serviceId
        time
        day
        discountId
        campaignId
      }
    }`;
    return query;
  },

  getQuerySaveNote(appointments) {
    //const keys = [appointmentKey];

    const size = appointments.length;
    let index = 0;
    let paramsString = '[';

    appointments.forEach(element => {
      paramsString += '{';
      paramsString += `key: "${element.key}",`;
      if (element.notes) {
        //encodeURIComponent(JSON.stringify(element.notes.replace(/(?:\r\n|\r|\n)/g, '\\n')))
        paramsString += `note: "${element.notes.replace(/(?:\r\n|\r|\n)/g, '\\n').replace(/(")/g, '').replace('\\', '')}"`;
        //paramsString +=
        // `note: "${element.notes.replace(/(?:\r\n|\r|\n)/g, '\\n').replace(/(")/g, '\\"')}"`
        // .replace(/(")/g, '').replace('\\', '')
      } else {
        paramsString += 'note: "",';
      }
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    console.log(paramsString);
    //JSON.stringify(json)

    return `mutation {
      updateClientNotes (appointments: ${paramsString}) { 
        key
      }
    }`;
  },

  getQueryCancelAppointment(appointmentKey, reason) {
    const keys = [appointmentKey];

    const size = keys.length;
    let index = 0;
    let paramsString = '[';

    keys.forEach(element => {
      paramsString += '{';
      paramsString += `key: "${element}"`;
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    if (reason) {
      return `mutation {
        cancel (appointments: ${paramsString}, cancelReason: "${reason}") { 
          key
        }
      }`;
    }
    return `mutation {
      cancel (appointments: ${paramsString}) { 
        key
      }
    }`;
  },

  getQueryMyAppointments() {
    return `{
      list {  
        key
        day
        time
        serviceId
        serviceName
        finalPrice
        price
        duration
        state
        professionalId
        professionalName
        professionalProfileImageUrl

        clientNotes
        isLocked
        checkoutState
        discountId
        cancelReason
        isPriceUnavailable
      }
    }`;
  },

  getQueryAppointmentDetails(keys) {
    //const keys = [appointmentKey];

    const size = keys.length;
    let index = 0;
    let paramsString = '[';

    keys.forEach(element => {
      paramsString += '{';
      paramsString += `key: "${element}"`;
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    return `{ 
      get(appointments: ${paramsString}) {
        key
        day
        time
        serviceId
        serviceName
        finalPrice
        price
        duration
        state
        professionalId
        professionalName
        professionalProfileImageUrl
        clientNotes
        isLocked
        checkoutState
        discountId
        cancelReason
        isPriceUnavailable
      }
    }`;
  },

  getQueryAppointmentPolicies(keys) {
    const size = keys.length;
    let index = 0;
    let paramsString = '[';

    keys.forEach(element => {
      paramsString += '{';
      paramsString += `key: "${element}"`;
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    return `{ 
      policies(appointments: ${paramsString}) {
        key
        update
        cancel
      }
    }`;
  },

  getQueryAppointmentDiscount(discountId) {
    return `{
      get(discountId: "${discountId}") {
        id
        values
        campaignId
      }
    }`;
  },

  getQueryPhoneConfirmationToken(phoneCountry, phoneNumber, sendPhoneCodeSms, intent) {
    if (sendPhoneCodeSms === false) {
      return `{
        getPhoneConfirmationToken (phoneCountry: "${phoneCountry}", phoneNumber: "${phoneNumber}", sendPhoneCodeSms: false, intent: "${intent}") {  
          token
        }
      }`;
    }
    return `{
      getPhoneConfirmationToken (phoneCountry: "${phoneCountry}", phoneNumber: "${phoneNumber}", sendPhoneCodeSms: true, intent: "${intent}") {  
        token
      }
    }`;
  },


  getQueryConfirmPhone(code) {
    return `mutation {
      confirmPhone (code: "${code}") {  
        token
      }
    }`;
  },

  getQueryConfirmName(name) {
    return `mutation {
      confirmName (name: "${name}") {  
        token
      }
    }`;
  },


  /* BILLING */

  getQuerySearchCep(cep) {
    return `{
      searchCep (cep: "${cep}") { 
        State
        City
        Neighborhood
        Street
        StreetNumber
        Zipcode
        Country
      }
    }`;
  },

  getQueryCurrentCustomer() {
    return `{
      current () { 
        Name
        Email
        TaxNumber
        SignupState
      }
    }`;
  },

  getQueryCurrentCustomerFull() {
    return `{
      current () { 
        Name
        Email
        TaxNumber
        SignupState
        Addresses {
          Uid
          Country
          State
          City
          Neighborhood
          Complement
          Street
          StreetNumber
          Zipcode
          IsPrefered
        }
        Cards {
          Uid
          HolderName
          LastDigits
          ExpirationDate
          Brand
          IsPrefered
          Status
        }
      }
    }`;
  },

  getQueryUpdateBasicInfo(name, email, taxNumber) {
    let paramsString = '';
    if (name && name.length > 0) {
      paramsString += `name: "${name}",`;
    }
    if (email && email.length > 0) {
      paramsString += `email: "${email}",`;
    } else {
      paramsString += 'email: "",';
    }
    if (taxNumber && taxNumber.length > 0) {
      paramsString += `taxNumber: "${taxNumber}",`;
    } else {
      paramsString += 'taxNumber: "",';
    }
    return `mutation {
      updateBasicInfo (${paramsString}) { 
      }
    }`;
    /*
    if (email && email !== '' && taxNumber && taxNumber !== '') {
      return `mutation {
        updateBasicInfo (name: "${name}", email: "${email}", taxNumber: "${taxNumber}") {
        }
      }`;
    }
    return `mutation {
      updateBasicInfo (name: "${name}") {
      }
    }`;*/
  },

  getQueryUpdateAddress(address) {
    let paramsString = '{';
    if (address.uid && address.uid.length > 0) {
      paramsString += `Uid: "${address.uid}",`;
    }
    paramsString += `Country: "${address.country ? address.country : ''}",`;
    paramsString += `State: "${address.state ? address.state : ''}",`;
    paramsString += `City: "${address.city ? address.city : ''}",`;
    paramsString += `Neighborhood: "${address.neighbourhood ? address.neighbourhood : ''}",`;
    paramsString += `Complement: "${address.doorExtra ? address.doorExtra : ''}",`;
    paramsString += `Street: "${address.street ? address.street : ''}",`;
    paramsString += `StreetNumber: "${address.door ? address.door : ''}",`;
    paramsString += `Zipcode: "${address.cep ? utils.stripInvalidChars(address.cep) : ''}"`;
    paramsString += '}';
    return `mutation {
      updateAddress (address: ${paramsString}) { 
      }
    }`;
  },

  getQueryCheckCustomerForPayment(paymentType) {
    return `{
      checkCustomerForPayment (paymentType: "${paymentType}") { 
      }
    }`;
  },

  getQueryAuthorizePayment(items) {
    const size = items.length;
    let index = 0;
    let paramsString = '[';

    items.forEach(element => {
      paramsString += '{';
      paramsString += `Type: "${element.type}",`;
      paramsString += `Code: "${element.code}",`;
      paramsString += `Quantity: ${element.quantity}`;
      paramsString += '}';
      if (index < size - 1) {
        paramsString += ',';
      }

      index++;
    });
    paramsString += ']';

    return `{
      authorize (items: ${paramsString}) { 
        ReferenceKey
        Hash
      }
    }`;
  },


  getQueryRevalidateAuthorization(hash) {
    return `{
      revalidateAuthorization (hash: "${hash}") { 
        ReferenceKey
        Hash
      }
    }`;
  },

  getQueryCardEncryptionKey() {
    return `{
      getCardEncryptionKey () { 
        Key
        Id
      }
    }`;
  },

  getQueryCreateCard(cardEncryptionKey) {
    return `mutation {
      createCard (hash: "${cardEncryptionKey}") {
        Uid
      }
    }`;
  },

  getQuerySetPreferedCard(uid) {
    return `mutation {
      setPreferedCard (uid: "${uid}") {
      }
    }`;
  },

  getQueryBuy(hash, paymentType, cardId) {
    return `mutation {
      buy (hash: "${hash}", paymentType: "${paymentType}", paymentId: "${cardId}") {
        Amount       
        Status
        StatusReason
        RefuseReason
      }
    }`;
  },


};
