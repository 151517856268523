<template>
  <Sticky :show-shadow="false">
    <transition name="fade">
      <div class="navbar">
        <div
          ref="navbar">
          <div
            v-if="canShowBack"
            :class="smallNavbarClassNames"
            class="navbar__small fixed-with-limited">
            <div
              :class="smallNavbarContainerClassNames"
              class="navbar__small__nav">
              <h3
                class="navbar__small__nav__title"
                v-html="titleText" />
            </div>
            <IlmButtonIcon
              v-if="canShowBack"
              :on-click="handleBackClick"
              icon="arrow_back"
              prevent-default
              class="navbar-container__back navbar__small__back" />
            <IlmButtonIcon
              v-if="showDiscountOption"
              :on-click="handleFilterClick"
              :class="discountOptionClasses"
              icon="discount-tag"
              size="lg"
              prevent-default />
          </div>
          <div class="navbar__search-container fixed-with-limited">
            <SearchBox
              ref="search_input"
              :is-loading="isLoading"
              :focus-on-enter="focusOnEnter"
              :show-back="showBack"
              :placeholder="searchPlaceholder"
              type="text"
              icon="search"
              @change="onChange"
            />
          </div>
        </div>
      </div>
    </transition>
  </Sticky>
</template>


<script>
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';
import SearchBox from '@/components/Inputs/SearchBox.vue';

export default {
  name: 'NavigationSearchBar',

  // Components ********************************
  components: {
    IlmButtonIcon,
    SearchBox,
  },

  // Properties ********************************
  props: {
    title: String,
    alternativeTitle: String,
    focusOnEnter: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showDiscountOption: {
      type: Boolean,
      default: false,
    },
    discountOptionActive: {
      type: Boolean,
      default: false,
    },
    onBack: Function,
    onFilterClick: Function,
    onResize: Function,
    onChange: Function,
  },

  data() {
    return {
      isExtended: true,
      windowWidth: null,
    };
  },

  // Computed ********************************
  computed: {
    titleClassNames() {
      const classNames = {
        'navbar-container__top__nav--show-nav-back': this.canShowBack,
      };
      return classNames;
    },
    smallNavbarClassNames() {
      return {
        'navbar__small--fixed': this.isExtendable,
      };
    },
    smallNavbarContainerClassNames() {
      return {
        'navbar__small__nav--show-nav-back': this.canShowBack,
      };
    },
    hideSubtitle() {
      return this.$store.getters.isExternalSource === false
        || this.isBigScreen;
    },
    titleText() {
      if (this.hideSubtitle && this.canShowBack) {
        return this.alternativeTitle;
      }
      return this.title;
    },
    searchPlaceholder() {
      if (this.hideSubtitle) {
        return this.$t('placeholders.search_box_services_alternative');
      }
      return this.$t('placeholders.search_box_services');
    },
    isBigScreen() {
      return this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH;
    },
    showBusinessName() {
      if (this.isBigScreen) {
        return false;
      }
      return this.showBusiness && this.$store.getters.isExternalSource;
    },
    canShowBack() {
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (fbMessenger.inFrame && body.classList.contains('os--ios')) {
        return false;
      }
      if (this.$store.getters.isExternalSource) {
        return true;
      }

      return this.showBack;
    },
    discountOptionClasses() {
      const classes = {};
      classes['navbar-container__filter navbar__small__filter'] = true;
      classes['navbar-container__filter navbar__small__filter--active'] = this.discountOptionActive;
      return classes;
    },
  },

  // Watch ********************************
  watch: {
    windowWidth() {
      if (this.onResize) this.onResize();
    },
  },

  created() {
    this.isExtended = this.isExtendable;
  },

  mounted() {
    const self = this;
    this.$nextTick(function () {
      self.windowWidth = document.body.clientWidth;
      window.addEventListener('resize', self.handleResize);
      window.addEventListener('scroll', self.handleScroll);
    });
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },

  // Methods ********************************
  methods: {
    handleResize() {
      this.windowWidth = document.body.clientWidth;
    },
    handleScroll() {
      const container = this.$refs.navbar_container;
      const windowScrollY = window.pageYOffset;
      //console.log(`container.offsetTop: ${container.offsetTop}`);
      //console.log(`windowScrollY: ${windowScrollY}`);
      //console.log(`container.clientHeight: ${container.clientHeight}`);
      if (container && this.isExtendable) {
        const offset = container.offsetTop;
        const containerHeight = container.clientHeight;
        const ditanceGap = this.isBigScreen ? 0 : 70;
        if (offset + containerHeight - ditanceGap > windowScrollY) {
          this.isExtended = true;
        } else {
          this.isExtended = false;
        }
      }
    },
    handleBackClick() {
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },
    handleFilterClick() {
      if (this.onFilterClick) {
        this.onFilterClick();
      }
    },
    clientHeight() {
      if (this.$refs.navbar) {
        return this.$refs.navbar.clientHeight;
      }
      return 0;
    },
    removeFocus() {
      if (this.$refs.search_input) {
        this.$refs.search_input.removeFocus();
      }
    },
    setSearchText(text) {
      if (this.$refs.search_input) {
        this.$refs.search_input.searchText = text;
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.navbar {
  //display: initial;
  background: white;

  &__small {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-family: $heading-font-family;
    transition: all 0.3s ease;

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      //box-shadow: $box-shadow-bottom;
    }

    &__back {
      top: 10px;
      bottom: 10px;
      display: block;
      position: absolute;
      left: 16px;
      background: white;
      color: black !important;
      font-size: 24px;
      height: 40px;
      border: 1px solid $nav-button-border;

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }

    &__filter {
      top: 10px;
      bottom: 10px;
      display: block;
      position: absolute;
      right: 16px;
      background: white;
      color: black !important;
      font-size: 20px;
      height: 40px;
      border: 1px solid $nav-button-border;

      &--active {
        background: $discount-background-color;
        border: 1px solid $discount-background-color-dimmed;
      }

      @media (hover) {
        &:hover {
          background: $theme-2-bg;
        }
      }
    }

    &__nav {
      //padding: $padding-section;
      max-width: 100%;
      flex: 1 1 auto;
      box-sizing: border-box;

      &--show-nav-back {
        padding-left: 62px;
        padding-right: 62px;
      }

      &__title {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em;
        color: black;
        text-align: center;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }
}

</style>
