import MyAppointment from './myAppointment';

class MyAppointmentList {
  constructor(data) {
    this.list = [];
    if (data) {
      data.forEach(element => this.list.push(new MyAppointment(element)));
    }
  }

  getItems() {
    return this.list;
  }

  print() {
    this.list.forEach(element => console.log(element.getName()));
  }
}

export default MyAppointmentList;
