<template>
  <div
    :class="classNames"
    class="section-fixed-container"
    @click="handleClick">
    <div
      v-if="!showCategory"
      class="section-fixed-container__row">
      <div class="section-fixed-container__col1">
        <h2
          ref="title"
          class="section-fixed-container__title"
          v-html="highlight()" />
        <span
          v-if="element.description"
          ref="description"
          class="section-fixed-container__subcategory">
          {{ element.description }}
        </span>
      </div>
      <div class="section-fixed-container__col2">
        <span class="section-fixed-container__col2__price">
          {{ price }}
        </span>
        <span class="section-fixed-container__col2__duration">
          {{ duration }}
        </span>
      </div>
      <div
        v-if="element.discount"
        class="section-fixed-container__col3">
        <IlmDiscount :value="`-${element.discount.maxValue}%`" />
      </div>
    </div>
    <div
      v-else
      class="section-fixed-container__row">
      <div class="section-fixed-container__col1">
        <div style="width: 100%">
          <div class="section-fixed-container__section">
            <h3
              v-if="element.section"
              class="section-fixed-container__section-title">
              {{ element.section }}
            </h3>
            <span
              v-if="element.editText"
              class="section-fixed-container__section-edit"
              @click="handleEditClick">
              {{ element.editText }}
            </span>
            <span
              v-if="element.deleteText"
              class="section-fixed-container__section-delete"
              @click="handleDeleteClick">
              {{ element.deleteText }}
            </span>
          </div>
          <div class="section-fixed-container__title-container">
            <h2
              ref="title"
              class="section-fixed-container__title"
              v-html="highlight()" />
            <span class="section-fixed-container__subtitle">
              {{ price }}
            </span>
          </div>
          <div
            v-if="element.category || duration"
            class="section-fixed-container__category-container">
            <span class="section-fixed-container__category">
              {{ category }}
            </span>
            <span class="section-fixed-container__subcategory">
              <!--{{ $t("section_list.service_duration", {count: element.category.value}) }}-->
              {{ duration }}
            </span>
          </div>
          <span
            v-if="element.description"
            ref="description"
            class="section-fixed-container__subcategory">
            {{ element.description }}
          </span>
        </div>
      </div>
      <div
        v-if="element.discount"
        class="section-fixed-container__col3">
        <IlmDiscount :value="`-${element.discount.maxValue}%`" />
      </div>
    </div>

    <div
      v-if="showDivider"
      class="row-divider" />
  </div>
</template>

<script>
import IlmDiscount from '@/components/IlmIcon/IlmDiscount.vue';
import utils from '@/utils/utils';
import formatter from '../../utils/formatter.js';

export default {
  name: 'SummaryRow',

  components: {
    IlmDiscount,
  },

  // Properties ********************************
  props: {
    element: {
      type: Object,
      required: true,
    },
    highlightSimple: {
      type: Boolean,
      default: false,
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
    searchText: String,
    showDivider: Boolean,
    onItemSelected: Function,
    onItemDeleted: Function,
    onItemEdit: Function,
  },

  data() {
    return {};
  },

  // Computed ********************************
  computed: {
    classNames() {
      const classNames = {
        'section-fixed-container--clickable': this.onItemSelected,
      };
      return classNames;
    },
    price() {
      /*
      // NO USE: use Vue filters instead
      const price = this.element.price;
      if (price) {
        return formatter.formatPrice(price);
      }*/
      if (this.element.isPriceUnavailable === true) {
        return this.$t('section_list.service_price_under_evaluation');
      }
      const price = this.element.price;
      if (price > 0) {
        const auth = this.$store.getters.getAuth;
        let locale = 'pt-BR';
        if (auth && auth.business && auth.business.locale) {
          locale = auth.business.locale;
        }
        const priceText = formatter.formatPrice(price, locale);
        if (this.element.isPriceVariable === true) {
          return this.$t('section_list.service_price', { price: priceText });
        }
        if (this.element.estimate === true) {
          return this.$t('section_list.service_price_estimate', { price: priceText });
        }
        return priceText;
      }
      return '';
    },
    duration() {
      if (this.element.duration) {
        const duration = this.element.duration;
        //const duration = this.element.category.value;
        return formatter.formatDuration(duration);
      }
      return null;
    },
    category() {
      if (
        this.element.category
        && this.$te(`service_categories.${this.element.category}`) === true
      ) {
        return this.$t(`service_categories.${this.element.category}`);
      }
      return this.element.category;
    },
  },

  mounted() {},

  // Methods ********************************
  methods: {
    handleClick() {
      if (!this.onItemSelected) return;
      this.onItemSelected(this.element);
    },
    handleDeleteClick() {
      if (!this.onItemDeleted) return;
      this.onItemDeleted(this.element);
    },
    handleEditClick() {
      if (!this.onItemEdit) return;
      this.onItemEdit(this.element);
    },
    highlight() {
      if (this.highlightSimple) {
        return utils.highlightSimple(
          this.element.name,
          this.searchText,
          'section-fixed-container__title__highlighted',
          this.$constants.MIN_SEARCH_STRING_LENGTH,
        );
      }
      return utils.highlight(
        this.element.name,
        this.searchText,
        'section-fixed-container__title__highlighted',
        this.$constants.MIN_SEARCH_STRING_LENGTH,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.section-fixed-container {
  position: relative;
  display: block;
  background: white;
  padding: 12px $padding-section;
  touch-action: pan-y;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: $hover-transition;

  &--clickable {
    cursor: pointer;
    touch-action: auto;
    -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
    transition: $hover-transition;
    //-webkit-tap-highlight-color: $list-pressed-row-background;
  }
  @media (hover) {
    &--clickable:hover {
      background: $list-pressed-row-background;
    }
  }
  /*
  &--clickable:hover {
    background: $theme-3-bg;
  }

  &--clickable:active {
    background: $list-pressed-row-background;
  }*/

  &__section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__section-title,
  &__section-edit {
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: $font-size-sm;
    color: $theme-2-color;
    margin: 0 0 $padding-section 0;
    text-transform: uppercase;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__section-edit {
    font-size: $font-size-sm;
    color: $theme-link-color;
    cursor: pointer;
    touch-action: auto;
    text-transform: initial;
    margin-right: 40px;
  }

  &__section-delete {
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: $font-size-sm;
    color: red;
    margin: 0;
    cursor: pointer;
    touch-action: auto;

    flex: 0 0 auto;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    /*padding-top: 10px;*/
    min-width: 0;
  }

  &__title {
    //display: inline-flex;
    display: inline;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: $font-size-lg;
    color: $theme-3-color;
    margin: 0;
    line-height: 1.2em;
    /*white-space: pre;*/

    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    ::v-deep &__highlighted {
      color: black;
      /*
      &--pad-left {
        //padding-left: 0.3em;
      }
      &--pad-right {
        //padding-right: 0.3em;
      }*/
    }
  }

  &__subtitle {
    display: inline-flex;
    font-family: $heading-font-family;
    font-size: 14px;
    color: black;
    margin: 0;

    min-width: 0;
    flex: 0 0 auto;
  }

  &__category-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;

    margin-top: 4px;
  }

  &__category {
    display: inline-flex;
    font-family: $heading-font-family;
    font-weight: bold;
    font-size: 12px;
    color: $theme-2-color;
    line-height: 1.2em;

    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__subcategory {
    display: inline-flex;
    font-family: $heading-font-family;
    font-size: 12px;
    color: $theme-3-color;
    line-height: 1.2em;
    margin: 0;

    flex: 0 0 auto;
  }

  &__row {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &__col1 {
    display: inline-flex;
    flex-direction: column;
    padding-right: 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }
  &__col2 {
    display: flex;
    flex-direction: column;
    margin: 0;

    flex: 0 0 auto;

    &__price {
      font-family: $heading-font-family;
      font-size: 14px;
      color: black;
      margin: 0;
      text-align: right;
    }
    &__duration {
      font-family: $heading-font-family;
      font-size: 12px;
      color: $theme-3-color;
      line-height: 1.2em;
      text-align: right;
    }
  }

  &__col3 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: 10px;

    flex: 0 0 auto;
  }
}

.row-divider {
  position: absolute;
  bottom: 0;
  left: $padding-section;
  right: $padding-section;
  height: 1px;
  background: $theme-list-divider-color;
}
</style>
