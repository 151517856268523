import Service from './service';
import { stripDiacritics } from '../utils/matcher';

class ServiceList {
  constructor(data) {
    this.services = [];
    if (data) {
      data.forEach(element => this.services.push(new Service(element)));
    }
  }

  getItems() {
    return this.services;
  }

  getCategoryServices() {
    const categories = [];
    const keys = {};
    let index = 0;

    const array = this.services.sort(this.orderService);
    const size = array.length;
    for (let i = 0; i < size; i++) {
      const service = array[i];
      const category = service.category;
      let obj = {
        category,
        services: [],
      };
      if (keys[category] === undefined) {
        keys[category] = index++;
        obj.services.push(service);
        categories.push(obj);
      } else {
        obj = categories[keys[category]];
        obj.services.push(service);
      }
    }
    return categories.sort(this.orderCategory);
  }

  orderService = function compare(a, b) {
    const first = stripDiacritics(a.name);
    const second = stripDiacritics(b.name);
    if (first < second) {
      return -1;
    } if (first > second) {
      return 1;
    }
    if (a.duration < b.duration) {
      return -1;
    } if (a.duration > b.duration) {
      return 1;
    }
    return 0;
  }

  orderCategory = function compare(a, b) {
    const first = stripDiacritics(a.category);
    const second = stripDiacritics(b.category);
    if (first < second) {
      return -1;
    } if (first > second) {
      return 1;
    }
    return 0;
  }

  print() {
    this.services.forEach(element => console.log(element.getName()));
  }
}

export default ServiceList;
