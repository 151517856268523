
<template>
  <div class="item-list-container">
    <ItemListRow
      v-for="(item, index) in filteredItems"
      :key="index"
      :item="item"
      :selected="isSelected(item)"
      :search-text="searchText"
      :subtitle-text="item.subtitle"
      :on-click="handleRowClick"
      :show-divider="index < filteredItems.length-1"
      class="item-list-container__row" />
  </div>
</template>


<script>
import ItemListRow from './ItemListRow.vue';
import matcher from '../../utils/matcher.js';

export default {
  name: 'ItemlList',

  // Components ********************************
  components: {
    ItemListRow,
  },


  // Properties ********************************
  props: {
    searchText: String,
    items: Array,
    selectedItem: {
      type: Object,
      default: null,
    },
    onItemSelected: Function,
  },


  // Data ********************************
  data() {
    return {
    };
  },

  // Computed ********************************
  computed: {
    filteredItems() {
      if (this.items && this.searchText
        && this.searchText.length >= this.$constants.MIN_SEARCH_STRING_LENGTH) {
        const term = this.searchText.trim().toLowerCase();
        return this.items.filter(function (item) {
          //return item.name.toLowerCase().includes(term);
          return matcher(item.name.toLowerCase(), term);
        });
      }
      return this.items;
    },
  },

  // Watch ********************************
  /*
  watch: {
    searchText(val) {
      console.log(val);
    },
  },*/

  // Methods ********************************
  methods: {
    handleRowClick(item) {
      if (!this.onItemSelected) return;
      this.onItemSelected(item);
    },
    isSelected(el) {
      return (this.selectedItem && this.selectedItem.id)
        ? (this.selectedItem.id === el.id)
        : false;
    },
  },
};
</script>


<style lang="scss" scoped>

.item-list-container {
  display: block;
  padding: 0;

  /*
  &__row {
    //border-bottom: 1px solid $theme-list-divider-color;
  }

  &__row:last-child {
    //border-bottom: none;
  }*/
}


</style>
