import axios from 'axios';
import qs from 'qs';
import httpAxios from '../http-common';
import queries from './queries';
import BusinessData from '../models/businessData';
import SuggestionList from '../models/suggestionList';
import ServiceList from '../models/serviceList';
import ProfessionalList from '../models/professionalList';
import MyAppointmentList from '../models/myAppointmentList';
import MyAppointment from '../models/myAppointment';
import MyAppointmentPolicies from '../models/myAppointmentPolicies';
import AvailabilityDays from '../models/availabilityDays';
import AvailabilityServiceList from '../models/availabilityServiceList';
import DiscountList from '../models/discountList';
import Discount from '../models/discount';
import AppointmentPost from '../models/appointmentPost';
import errorHandler from './errorHandler';
import ResponseAddress from '../models/billing/responseAddress';
import ResponseCustomer from '../models/billing/responseCustomer';
//import ResponseCard from '../models/billing/responseCard';

const MIN_REQUEST_TIME = 700;

const USERS_ENDPOINT = 'users';
const SERVICES_ENDPOINT = 'services';
const BUSINESSES_ENDPOINT = 'businesses';
const AVAILABILITIES_ENDPOINT = 'availabilities';
const APPOINTMENTS_ENDPOINT = 'appointments';
const DISCOUNTS_ENDPOINT = 'discounts';
const CUSTOMERS_ENDPOINT = 'customers';
const PURCHASE_ENDPOINT = 'purchases';

const LOG_REQUESTS = process.env.VUE_APP_SHOW_DEBUG === 'true';

const CancelToken = axios.CancelToken;
let cancelAvailability;
/*
const mapRequests = {
  availabilities: 0,
};
*/
function logResponse(response, key) {
  if (LOG_REQUESTS) {
    if (key) {
      if (console.groupCollapsed) {
        console.groupCollapsed(key);
      }
      console.log(response);
      //console.trace();
      if (console.groupEnd) {
        console.groupEnd(key);
      }
      //console.log(JSON.stringify(response));
    } else {
      console.log(response);
    }
  }
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  axios: httpAxios,

  showLogs: LOG_REQUESTS,

  setSentry(sentry) {
    errorHandler.sentry = sentry;
  },

  /**
   * Get access token
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getAccessToken(app, signedRequest, permalink, success, error) {
    httpAxios.get(`${USERS_ENDPOINT}?query=${queries.getQueryAccessToken(app, signedRequest, permalink)}`)
      .then(({ data }) => {
        logResponse(data, data.errors ? 'getAccessToken: Error' : 'getAccessToken');

        if (data.errors) {
          error(errorHandler.processError(data.errors));
        } else {
          const token = data.data.getAccessToken.token;
          const intent = data.data.getAccessToken.intent;
          logResponse(`token: ${token}`);
          success({
            token,
            intent,
          });
        }
      })
      .catch(ex => {
        logResponse(ex, 'getAccessToken: Exception');
        error(errorHandler.processError(ex));
      });
  },


  /**
   * Get business details
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getBusinessDetails(success, error) {
    const then = new Date().getTime();
    httpAxios.get(`${BUSINESSES_ENDPOINT}?query=${queries.getQueryCurrentBusiness()}`)
      .then(({ data }) => {
        logResponse(data, data.errors ? 'getBusinessDetails: Error' : 'getBusinessDetails');

        if (data.errors) {
          error(errorHandler.processError(data.errors));
        } else {
          const b = data.data.currentBusiness;
          const businessData = new BusinessData(b);

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(businessData));
          } else {
            success(businessData);
          }
        }
      })
      .catch(ex => {
        logResponse(ex, 'getBusinessDetails: Exception');
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get services list
   * @param {*} success success callback
   * @param {*} error error callback
   */
  businessServicesQuery(success, error) {
    httpAxios.get(`${SERVICES_ENDPOINT}?query=${queries.getQueryBusinessServices()}`)
      .then(response => {
        logResponse(response.data, response.data.errors ? 'businessServicesQuery: Error' : 'businessServicesQuery');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.list;
          const services = new ServiceList(data);
          success(services.getCategoryServices());
        }
      })
      .catch(ex => {
        logResponse(ex, 'businessServicesQuery: Exception');
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get services list
   * @param {*} serviceIds service ids array
   * @param {*} success success callback
   * @param {*} error error callback
   */
  servicesDetailsQuery(serviceIds, success, error) {
    httpAxios.get(`${SERVICES_ENDPOINT}?query=${queries.getQueryServicesDetails(serviceIds)}`)
      .then(response => {
        logResponse(response.data, response.data.errors ? 'servicesDetailsQuery: Error' : 'servicesDetailsQuery');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.get;
          const services = new ServiceList(data);
          success(services.getItems());
        }
      })
      .catch(ex => {
        logResponse(ex, 'servicesDetailsQuery: Exception');
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get discounts list
   * @param {*} success success callback
   * @param {*} error error callback
   */
  businessDiscountsQuery(success, error) {
    httpAxios.get(`${DISCOUNTS_ENDPOINT}?query=${queries.getQueryBusinessDiscounts()}`)
      .then(response => {
        logResponse(response.data, response.data.errors ? 'businessDiscountsQuery: Error' : 'businessDiscountsQuery');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.forClient;
          const discounts = new DiscountList(data);
          success(discounts);
        }
      })
      .catch(ex => {
        logResponse(ex, 'businessDiscountsQuery: Exception');
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get the service and categories suggestions
   * @param {*} success success callback
   * @param {*} error error callback
   */
  suggestionsQuery(clientSize, businessSize, success, error) {
    httpAxios.get(`${SERVICES_ENDPOINT}?query=${queries.getQuerySuggestions(clientSize, businessSize)}`)
      .then(response => {
        logResponse(response.data, response.data.errors ? 'suggestionsQuery: Error' : 'suggestionsQuery');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.suggestions;
          const suggestions = new SuggestionList(data);
          //suggestions.print();
          success(suggestions);
        }
      })
      .catch(ex => {
        logResponse(ex, 'suggestionsQuery: Exception');
        error(errorHandler.processError(ex));
      });
  },

  /**
   *
   * @param {*} term search term
   * @param {*} success success callback
   * @param {*} error error callback
   */
  searchQuery(term, success, error) {
    const methodName = 'searchQuery';

    const options = {
      params: {
        query: `${queries.getQuerySearch(term.replace(/(")/g, '').replace('\\', ''))}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };
    httpAxios.get(SERVICES_ENDPOINT, options)
    //httpAxios.get(`${SERVICES_ENDPOINT}?query=${queries.getQuerySearch(term)}`)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : `${methodName}: ${term}`);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.search;
          const search = new SuggestionList(data);
          //search.print();
          success(search);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
  },

  /**
   *
   * @param {*} key service group key
   * @param {*} type service group type
   * @param {*} success success callback
   * @param {*} error error callback
   */
  /*
  searchByOptionQuery(key, type, success, error) {
    httpAxios.get(`${SERVICES_ENDPOINT}?query=${queries.getQuerySearchByOption(key, type)}`)
      .then(response => {
        logResponse(response);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.listByOption;
          const services = new ServiceList(data);
          //services.print();
          success(services.getItems());
        }
      })
      .catch(() => error(errorHandler.processError()));
    //.catch(err => error(err));
  },
*/

  /**
   *
   * @param {*} serviceId service id
   * @param {*} success success callback
   * @param {*} error error callback
   */
  /*
  getProfessionals(serviceId, success, error) {
    //const then = new Date().getTime();
    httpAxios.get(`${BUSINESSES_ENDPOINT}?query=${queries.getQueryProfessionals(serviceId)}`)
      .then(response => {
        logResponse(response, response.data.errors
          ? 'getProfessionals: Error' : 'getProfessionals');
        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.professionalsForService;
          const obj = new ProfessionalList(data);
          success(obj.getItems());
        }
      })
      .catch(ex => {
        logResponse(ex, `getProfessionals: Exception: ${ex}`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },*/

  /**
   *
   * @param {*} serviceIds service ids array
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getProfessionalsServices(serviceIds, success, error) {
    //const then = new Date().getTime();
    httpAxios.get(`${BUSINESSES_ENDPOINT}?query=${queries.getQueryProfessionalsServices(serviceIds)}`)
      .then(response => {
        logResponse(response, response.data.errors ? 'getProfessionalsServices: Error' : 'getProfessionalsServices');
        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.professionalsForServices;
          const obj = new ProfessionalList(data);
          success(obj);
        }
      })
      .catch(ex => {
        logResponse(ex, `getProfessionalsServices: Exception: ${ex}`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   *
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getProfessionals(professionalIds, success, error) {
    const methodName = 'getProfessionals';
    //const then = new Date().getTime();
    httpAxios.get(`${BUSINESSES_ENDPOINT}?query=${queries.getQueryProfessionals(professionalIds)}`)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);
        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.professionals;
          const obj = new ProfessionalList(data);
          success(obj);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception: ${ex}`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  cancelAvailabilitiesRequest() {
    if (cancelAvailability) {
      cancelAvailability();
      cancelAvailability = null;
    }
  },

  /**
   *
   * @param {*} startDate starting date of results ('yyyy-MM-dd')
   * @param {*} endDate ending date of results ('yyyy-MM-dd')
   * @param {*} serviceId selected service id
   * @param {*} professionalId selected professional id
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getAvailabilities(startDate, endDate, serviceId, professionalId, appointmentKey, success, error) {
    this.cancelAvailabilitiesRequest();

    //const then = new Date().getTime();
    httpAxios.get(`${AVAILABILITIES_ENDPOINT}?query=${queries.getQueryAvailabilities(startDate, endDate, serviceId, professionalId, appointmentKey)}`, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        if (startDate === endDate) {
          cancelAvailability = c;
        }
      }),
    })
      .then(response => {
        logResponse(response, response.data.errors ? 'getAvailabilities: Error' : `getAvailabilities: startDate: ${startDate} | endDate: ${endDate} | serviceId: ${serviceId} | professionalId: ${professionalId} | appointmentKey: ${appointmentKey}`);
        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.inDays;
          const slots = new AvailabilityDays(data);
          //services.print();
          /*
          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(() => {
              success(slots);
            });
          } else {
            success(slots);
          }*/
          success(slots);
        }
      })
      .catch(ex => {
        if (axios.isCancel(ex) === false) {
          logResponse(ex, `getAvailabilities: Exception: startDate: ${startDate} | endDate: ${endDate} | serviceId: ${serviceId} | professionalId: ${professionalId}`);
          error(errorHandler.processError(ex));
        }
      });
    //.catch(err => error(err));
  },

  /**
   *
   * @param {*} startDate starting date of results ('yyyy-MM-dd')
   * @param {*} endDate ending date of results ('yyyy-MM-dd')
   * @param {*} appointments appointments with services and professionals
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getServicesAvailabilities(startDate, endDate, appointments, appointmentKey, success, error) {
    this.cancelAvailabilitiesRequest();

    //const then = new Date().getTime();
    httpAxios.get(`${AVAILABILITIES_ENDPOINT}?query=${queries.getQueryServicesAvailabilities(startDate, endDate, appointments, appointmentKey)}`, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        if (startDate === endDate) {
          cancelAvailability = c;
        }
      }),
    })
      .then(response => {
        logResponse(response, response.data.errors ? 'getServicesAvailabilities: Error' : `getServicesAvailabilities: startDate: ${startDate} | endDate: ${endDate} | appointmentKey: ${appointmentKey}`);
        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.inDaysBulk;
          const availabilities = new AvailabilityServiceList(data);

          success(availabilities);
        }
      })
      .catch(ex => {
        if (axios.isCancel(ex) === false) {
          logResponse(ex, `getServicesAvailabilities: Exception: startDate: ${startDate} | endDate: ${endDate}`);
          error(errorHandler.processError(ex));
        }
      });
    //.catch(err => error(err));
  },


  /**
   *
   * @param {*} startDate starting date of results ('yyyy-MM-dd')
   * @param {*} endDate ending date of results ('yyyy-MM-dd')
   * @param {*} serviceId selected service id
   * @param {*} businessId business id
   * @param {*} success success callback
   * @param {*} error error callback
   */
  /*
  getProfessionalsAvailable(day, time, serviceId, success, error) {
    httpAxios.get(`${AVAILABILITIES_ENDPOINT}?query=${
      queries.getQueryProfessionalsAvailable(day, time, serviceId)}`)
      .then(response => {
        logResponse(response,
          response.data.errors ? 'getProfessionalsAvailable: Error' : 'getProfessionalsAvailable');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.inSlot;
          //const slots = new AvailabilityDays(data);
          console.log(data);
          //services.print();
          //success(slots);
        }
      })
      .catch(ex => {
        logResponse(ex, 'getProfessionalsAvailable: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },*/


  /**
   *
   * @param {*} appointment appointment to save
   * @param {*} success success callback
   * @param {*} error error callback
   */
  createAppointments(appointments, success, error) {
    const then = new Date().getTime();
    httpAxios.get(`${APPOINTMENTS_ENDPOINT}?query=${queries.getQuerySaveAppointment(appointments)}`)
      .then(response => {
        logResponse(response, response.data.errors ? 'createAppointment: Error' : 'createAppointment');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.create;
          const appointmentsArray = [];
          data.forEach(element => {
            appointmentsArray.push(new AppointmentPost(element));
          });
          //const post = appointmentsArray[0];

          //console.log(post);
          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(appointmentsArray));
          } else {
            success(appointmentsArray);
          }
          //success(post);
        }
      })
      .catch(ex => {
        logResponse(ex, 'createAppointment: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   *
   * @param {*} appointment appointment to edit
   * @param {*} success success callback
   * @param {*} error error callback
   */
  editAppointment(appointments, success, error) {
    const then = new Date().getTime();
    httpAxios.get(`${APPOINTMENTS_ENDPOINT}?query=${queries.getQueryUpdateAppointment(appointments)}`)
      .then(response => {
        logResponse(response, response.data.errors ? 'editAppointment: Error' : 'editAppointment');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.update;
          const appointmentsArray = [];
          data.forEach(element => {
            appointmentsArray.push(new AppointmentPost(element));
          });
          //const post = new AppointmentPost(data);
          //const post = appointmentsArray[0];

          //console.log(post);
          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(appointmentsArray));
          } else {
            success(appointmentsArray);
          }
          //success(post);
        }
      })
      .catch(ex => {
        logResponse(ex, 'editAppointment: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   * Save appointment note
   * @param {*} appointment appointment with note to save
   * @param {*} success success callback
   * @param {*} error error callback
   */
  saveAppointmentNote(appointments, success, error) {
    /*const options = {
      params: {
        query: `${queries.getQuerySaveNote(appointments)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };*/

    const body = {
      query: `${queries.getQuerySaveNote(appointments)}`,
      paramsSerializer: (query => qs.stringify(query)),
    };

    const then = new Date().getTime();
    httpAxios.post(APPOINTMENTS_ENDPOINT, body)
    //httpAxios.get(APPOINTMENTS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? 'saveAppointmentNote: Error' : 'saveAppointmentNote');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.updateClientNotes;
          const keys = [];
          data.forEach(k => {
            keys.push(k);
          });
          //const key = data.key;
          const key = keys[0];

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(key));
          } else {
            success(key);
          }
          //success(post);
        }
      })
      .catch(ex => {
        logResponse(ex, 'saveAppointmentNote: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   * Cancel appointment
   * @param {*} appointment appointment with note to save
   * @param {*} success success callback
   * @param {*} error error callback
   */
  cancelAppointment(appointment, reason, success, error) {
    const options = {
      params: {
        query: `${queries.getQueryCancelAppointment(appointment.key, reason)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    const then = new Date().getTime();
    httpAxios.get(APPOINTMENTS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? 'cancelAppointment: Error' : 'cancelAppointment');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.cancel;
          const keys = [];
          data.forEach(k => {
            keys.push(k);
          });
          //const key = data.key;
          const key = keys[0];

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(key));
          } else {
            success(key);
          }
          //success(post);
        }
      })
      .catch(ex => {
        logResponse(ex, 'cancelAppointment: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   *
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getMyAppointments(success, error) {
    const then = new Date().getTime();
    httpAxios.get(`${APPOINTMENTS_ENDPOINT}?query=${queries.getQueryMyAppointments()}`)
      .then(({ data }) => {
        logResponse(data, data.errors ? 'getMyAppointments: Error' : 'getMyAppointments');

        if (data.errors) {
          error(errorHandler.processError(data.errors));
        } else {
          const list = data.data.list;
          const myAppointments = new MyAppointmentList(list);

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(myAppointments.getItems()));
          } else {
            success(myAppointments.getItems());
          }
        }
      })
      .catch(ex => {
        logResponse(ex, 'getMyAppointments: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  /**
   * @param {*} appointmentKey appointment key to get details from
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getAppointmentDetails(appointmentKeys, success, error) {
    const options = {
      params: {
        query: `${queries.getQueryAppointmentDetails(appointmentKeys)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    const then = new Date().getTime();
    httpAxios.get(APPOINTMENTS_ENDPOINT, options)
      .then(({ data }) => {
        logResponse(data, data.errors ? 'getAppointmentDetails: Error' : 'getAppointmentDetails');

        if (data.errors) {
          error(errorHandler.processError(data.errors));
        } else {
          const details = data.data.get;
          const appointments = [];
          details.forEach(element => {
            appointments.push(new MyAppointment(element));
          });
          //const appointment = new MyAppointment(details);
          //const appointment = appointments[0];

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(appointments));
          } else {
            success(appointments);
          }
        }
      })
      .catch(ex => {
        logResponse(ex, 'getAppointmentDetails: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  /**
   * @param {*} appointmentKey appointment key to get details from
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getAppointmentPolicies(appointmentKeys, success, error) {
    const options = {
      params: {
        query: `${queries.getQueryAppointmentPolicies(appointmentKeys)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    const then = new Date().getTime();
    httpAxios.get(APPOINTMENTS_ENDPOINT, options)
      .then(({ data }) => {
        logResponse(data, data.errors ? 'getAppointmentPolicies: Error' : 'getAppointmentPolicies');

        if (data.errors) {
          error(errorHandler.processError(data.errors));
        } else {
          const details = data.data.policies;
          const appointmentsPolicies = [];
          details.forEach(element => {
            appointmentsPolicies.push(new MyAppointmentPolicies(element));
          });
          //const appointment = new MyAppointment(details);
          //const appointment = appointments[0];

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(appointmentsPolicies));
          } else {
            success(appointmentsPolicies);
          }
        }
      })
      .catch(ex => {
        logResponse(ex, 'getAppointmentPolicies: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  /**
   *
   * @param {*} discountId discount id
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getAppointmentDiscount(discountId, success, error) {
    httpAxios.get(`${DISCOUNTS_ENDPOINT}?query=${queries.getQueryAppointmentDiscount(discountId)}`)
      .then(response => {
        logResponse(response, response.data.errors ? 'getAppointmentDiscount: Error' : 'getAppointmentDiscount');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.get;
          const discount = new Discount(data);

          success(discount);
        }
      })
      .catch(ex => {
        logResponse(ex, 'getAppointmentDiscount: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  /**
   *
   * @param {*} countryCode country code
   * @param {*} countrphoneyCode phone
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getPhoneConfirmationToken(countryCode, phone, sendPhoneCodeSms, intent, success, error) {
    const methodName = 'getPhoneConfirmationToken';

    let intentString = '';
    if (intent) {
      intentString = encodeURIComponent(JSON.stringify(intent));//.replace(/"/g, '\\"');
      //intentString = JSON.stringify(intent);
    }

    /*const body = {
      params: {
        query: `${queries.getQueryPhoneConfirmationToken(
          countryCode, phone, sendPhoneCodeSms, intentString)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };*/
    const body = {
      query: `${queries.getQueryPhoneConfirmationToken(countryCode, phone, sendPhoneCodeSms, intentString)}`,
      paramsSerializer: (query => qs.stringify(query)),
    };

    /*const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };*/

    httpAxios.post(USERS_ENDPOINT, body)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.getPhoneConfirmationToken;
          const confirmationToken = data.token;

          success(confirmationToken);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  confirmPhone(code, success, error) {
    const then = new Date().getTime();
    httpAxios.get(`${USERS_ENDPOINT}?query=${queries.getQueryConfirmPhone(code)}`)
      .then(response => {
        logResponse(response, response.data.errors ? 'confirmPhone: Error' : 'confirmPhone');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.confirmPhone;
          const token = data.token;

          const now = new Date().getTime();
          const diff = now - then;
          if (diff < MIN_REQUEST_TIME) {
            delay(MIN_REQUEST_TIME - diff).then(success(token));
          } else {
            success(token);
          }
        }
      })
      .catch(ex => {
        logResponse(ex, 'confirmPhone: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  getNameConfirmationToken(name, success, error) {
    const options = {
      params: {
        query: `${queries.getQueryConfirmName(name)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(USERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? 'getNameConfirmationToken: Error' : 'getNameConfirmationToken');

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.confirmName;
          const confirmationToken = data.token;

          success(confirmationToken);
        }
      })
      .catch(ex => {
        logResponse(ex, 'getNameConfirmationToken: Exception');
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  /**
   *  BILLING
   *
   * */

  searchCep(cep, success, error) {
    const methodName = 'searchCep';
    httpAxios.get(`${CUSTOMERS_ENDPOINT}?query=${queries.getQuerySearchCep(cep)}`)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.searchCep;
          const address = new ResponseAddress(data);
          success(address);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  getCurrentCustomer(allParams, success, error) {
    const methodName = 'getCurrentCustomer';
    const options = {
      params: {
        query: allParams === true ? `${queries.getQueryCurrentCustomerFull()}` : `${queries.getQueryCurrentCustomer()}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.current;
          if (data) {
            const customer = new ResponseCustomer(data);
            success(customer);
          } else {
            error(errorHandler.processGenericError());
          }
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  updateCustomerName(name, success, error) {
    const methodName = 'updateCustomerName';

    const options = {
      params: {
        query: `${queries.getQueryUpdateBasicInfo(name, null, null)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.updateBasicInfo;

          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  updateCustomerBasicInfo(customer, success, error) {
    const methodName = 'updateCustomerBasicInfo';

    const options = {
      params: {
        query: `${queries.getQueryUpdateBasicInfo(customer.name,
          customer.email,
          customer.getCleanTaxNumber())}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.updateBasicInfo;

          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  updateCustomerAddress(address, success, error) {
    const methodName = 'updateCustomerAddress';

    const options = {
      params: {
        query: `${queries.getQueryUpdateAddress(address)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.updateAddress;

          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  getCheckCustomerForPayment(paymentType, success, error) {
    const methodName = 'getCheckCustomerForPayment';

    const options = {
      params: {
        query: `${queries.getQueryCheckCustomerForPayment(paymentType)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.checkCustomerForPayment;
          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  getAuthorizePayment(type, code, quantity, success, error) {
    const methodName = 'getAuthorizePayment';

    const items = [
      {
        type,
        code,
        quantity,
      },
    ];

    const options = {
      params: {
        query: `${queries.getQueryAuthorizePayment(items)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(PURCHASE_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.authorize;
          success({
            reference: data.referenceKey,
            hash: data.hash,
          });
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  getRevalidateAuthorization(hash, success, error) {
    const methodName = 'getRevalidateAuthorization';

    const options = {
      params: {
        query: `${queries.getQueryRevalidateAuthorization(hash)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(PURCHASE_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.revalidateAuthorization;
          success({
            reference: data.referenceKey,
            hash: data.hash,
          });
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },


  getCardEncryptionKey(success, error) {
    const methodName = 'getCardEncryptionKey';

    const options = {
      params: {
        query: `${queries.getQueryCardEncryptionKey()}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.getCardEncryptionKey;
          success({
            key: data.key,
            id: data.id,
          });
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  createCustomerCard(cardEncryptionKey, success, error) {
    const methodName = 'createCustomerCard';

    const options = {
      params: {
        query: `${queries.getQueryCreateCard(cardEncryptionKey)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.createCard;

          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  setPreferedCard(uid, success, error) {
    const methodName = 'setPreferedCard';

    const options = {
      params: {
        query: `${queries.getQuerySetPreferedCard(uid)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(CUSTOMERS_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.setPreferedCard;

          success(data);
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

  buy(hash, paymentType, cardId, success, error) {
    const methodName = 'buy';

    const options = {
      params: {
        query: `${queries.getQueryBuy(hash, paymentType, cardId)}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get(PURCHASE_ENDPOINT, options)
      .then(response => {
        logResponse(response, response.data.errors ? `${methodName}: Error` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.buy;

          success({
            amount: data.amount,
            status: data.status,
            statusReason: data.statusReason,
            refuseReason: data.refuseReason,
          });
        }
      })
      .catch(ex => {
        logResponse(ex, `${methodName}: Exception`);
        error(errorHandler.processError(ex));
      });
    //.catch(err => error(err));
  },

};
