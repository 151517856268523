<template>
  <div
    :class="classNames"
    class="calendar-day"
    @click="onClick">
    <span
      class="calendar-day__month notranslate"
      translate="no">
      {{ weekDay }}
    </span>
    <span class="calendar-day__day">
      {{ date.date() }}
    </span>
    <IlmDiscount
      v-if="discountValue"
      :value="`-${discountValue}%`"
      class="calendar-day__discount"
      size="md"
      is-variable />
  </div>
</template>


<script>
import IlmDiscount from '@/components/IlmIcon/IlmDiscount.vue';

export default {
  name: 'IlmCalendarDay',

  components: {
    IlmDiscount,
  },

  // Properties ********************************
  props: {
    date: {
      type: Object,
      required: true,
    },
    discount: Object,
    unavailableDays: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    serviceId: Number,
    /*isToday: {
      type: Boolean,
      default: false,
    },
    isUnavailable: {
      type: Boolean,
      default: false,
    },*/
    onSelected: Function,
  },

  // Computed ********************************
  computed: {
    classNames() {
      const classNames = {
        'calendar-day--is-today': this.isToday,
        'calendar-day--selected': this.isSelected,
        'calendar-day--weekend': this.weekday === 6 || this.weekday === 7,
        'calendar-day--is-unavailable': this.isUnavailable,
        'calendar-day--has-discount': this.discountValue,
        //'calendar-day--is-unavailable-selected': this.isSelected && this.isUnavailable,
      };
      return classNames;
    },
    weekDay() {
      if (this.isToday) {
        return this.$t('calendar.today');
      }
      return this.date.format('ddd');
    },
    discountValue() {
      return this.discount && this.isUnavailable === false
        //? this.discount.getDayDiscount(this.date.isoWeekday(), this.serviceId)
        ? this.discount.getCalendarDayDiscount(this.weekday)
        : null;
    },
    isToday() {
      return this.date.isSame(this.$moment(new Date()), 'day');
    },
    weekday() {
      return this.date.isoWeekday();
    },
    isUnavailable() {
      return this.unavailableDays && this.unavailableDays.includes(this.weekday);
    },
  },

  mounted() {
    //console.log(this.date.format('D MMMM YYYY'));
  },

  beforeUpdate() {
    //console.log(this.date.format('D MMMM YYYY'));
  },

  // Methods ********************************
  methods: {
    onClick() {
      if (this.onSelected) {
        this.onSelected(this.date);
      }
    },
  },
};

</script>


<style lang="scss" scoped>

.calendar-day {
  display: block;
  text-align: center;
  color: black;
  cursor: pointer;
  padding: 6px 1px 3px 1px;
  max-width: 48px;
  margin: auto;
  border-radius: 12px;
  touch-action: auto;
  -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
  transition: $hover-transition;

  &--has-discount {
    //border-radius: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  /*
  &--is-today {
    color: $theme-ilm-color !important;
  }*/
  @media (hover) {
    &:hover {
      //background: $list-pressed-row-background;
      background: $theme-ilm-slot-background;
    }

    &--is-unavailable:hover {
      background: #eee;
    }
  }

  &--weekend &__month {
    //font-weight: bold;
    text-transform: uppercase;
  }

  &--is-today &__month {
    color: $theme-ilm-slot-background-selected;
    font-weight: bold;
  }

  &--selected {
    //background: $theme-ilm-selection-dimmed !important;
    background: $theme-ilm-slot-background-selected !important;
  }
  &--selected &__month, &--selected &__day {
    //color: black !important;
    color: white;
    font-weight: bold;
  }


  &--is-unavailable {
    /*text-decoration: line-through;*/
    color: #999999;
  }

  &--selected {
    &.calendar-day--is-unavailable {
      background: $theme-2-bg !important;
    }
    &.calendar-day--is-unavailable .calendar-day__month,
    &.calendar-day--is-unavailable .calendar-day__day {
      color: black !important;
    }
  }
/*
  &--is-unavailable-selected:before, &--is-unavailable-selected:after {
    position: absolute;
    left: 50%;
    bottom: 1px;
    content: ' ';
    height: 96%;
    width: 2px;
    background-color: white;
  }
  &--is-unavailable-selected:before {
    transform: rotate(36deg);
  }
  &--is-unavailable-selected:after {
    transform: rotate(-36deg);
  }
*/

  &__month {
    display: block;
    font-size: 12px;
  }

  &__day {
    display: block;
    font-size: 22px;
    line-height: 1;
    //font-weight: bold;
    padding-top: 4px;
    padding-bottom: 3px;
  }
}

</style>
