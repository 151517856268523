export default {
  getToken: state => state.token,

  getSource: state => state.source,

  getMessenger: state => state.source.fbMessenger,

  getSourceDescription: state => state.source.description,

  getGeneral: state => state.source.general,

  getGoogle: state => state.source.google,

  isExternalSource: state => !state.source.fbMessenger.isMessenger,

  getBusinessClosedWeekdays: state => state.businessClosedWeekdays,

  getAuth: state => state.auth,

  getCanResumeVerification: state => state.canResumeVerification,

  getClientSuggestions: state => state.clientSuggestions,

  getServiceSearch: state => state.serviceSearch,

  getSelectedCategory: state => state.selectedCategory,

  getAppointment: state => state.appointment,

  getEditAppointment: state => state.editAppointment,

  getAppointments: state => state.appointments,

  getService: state => (state.appointment ? state.appointment.service : null),

  getProfessional: state => (state.appointment ? state.appointment.professional : null),

  getDate: state => (state.appointments ? state.appointments.getAppointmentsDate() : null),

  getDiscounts: state => state.discounts,

  getProfessionals: state => state.professionals,

  getCustomer: state => state.customer,
};
