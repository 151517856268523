class AppointmentPost {
  constructor(obj) {
    this.key = obj.key;
    this.professionalId = `${obj.professionalId}`;
    this.serviceId = `${obj.serviceId}`;
    this.discountId = `${obj.discountId}`;
    this.campaignId = `${obj.campaignId}`;
    this.time = obj.time;
    this.day = obj.day;
  }
}

export default AppointmentPost;
