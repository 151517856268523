export default {
  ROUTE_LOGIN: 'login',
  ROUTE_ACCOUNT: 'account',
  ROUTE_ACCOUNT_DETAILS: 'account-me',
  ROUTE_ACCOUNT_CARDS: 'account-cards',
  ROUTE_ACCOUNT_ADDRESS: 'account-address',
  ROUTE_BUSINESS_DETAILS: 'business-details',
  ROUTE_SEARCH_SERVICE: 'search-service',
  ROUTE_DISCOUNTS_LIST: 'discounts-list',
  ROUTE_NEW_APPOINTMENT_SERVICES: 'new-booking-services',
  ROUTE_NEW_APPOINTMENT: 'new-booking',
  ROUTE_NEW_APPOINTMENT_CONFIRMATION: 'new-booking-confirmation',
  ROUTE_VERIFY_PHONE: 'verify-phone',
  ROUTE_SUCCESS: 'success',
  ROUTE_MY_BOOKINGS: 'my-bookings',
  ROUTE_MY_BOOKINGS_ITEM: 'my-bookings-item',
  ROUTE_MY_BOOKINGS_ITEMS: 'my-bookings-items',
  ROUTE_PAYMENT_DETAILS: 'payment-details',
  ROUTE_VOUCHER: 'voucher',
  ROUTE_TERMS_CONDITIONS: 'terms-conditions',
  ROUTE_PRIVACY_POLICY: 'privacy-policy',
  ROUTE_NOT_FOUND: 'not-found',
  ROUTE_ERROR: 'error',

  SOURCE_LOCATION_MY_BOOKINGS: 'my-bookings',

  MIN_SCREEN_WIDTH: 768,

  MIN_SEARCH_STRING_LENGTH: 2,

  SCHEDULE_MORNING: 13,
  SCHEDULE_AFTERNOON: 17,

  SHOW_USER_ACCOUNT: false,

  //BUSINESS_TEST_ID: '1038', // Freshy Spa

  //BUSINESS_TEST_ID: '1156', // Barberia da Cidade
  //CLIENT_TEXT_ID: '123300', // ABELAR FREIRE LOPES
  //CLIENT_TEXT_ID: '116614', // ABELI

  BUSINESS_TEST_ID: '1', // SP1
  CLIENT_TEXT_ID: '34647', // Abie Rod

  //BUSINESS_TEST_ID: '1152', // Espaço Séfora
  //CLIENT_TEXT_ID: '126404', // Adriana

  // FACEBOOK
  FACEBOOK_APP_ID: process.env.VUE_APP_FB_APP_ID,
  TEST_USE_MESSENGER_FLOW: false,
  TEST_SIGNED_REQUEST: 'AEIlEcDz0reSawG98BxTR45bYbx7qh5YvhFCUjJq4rk.eyJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImNvbW11bml0eV9pZCI6bnVsbCwiaXNzdWVkX2F0IjoxNTkyNTE1ODY2LCJtZXRhZGF0YSI6bnVsbCwicGFnZV9pZCI6NTA5OTY1MTY2MTIyMDY4LCJwc2lkIjoiMjE4MzU5NDQyODMyNTg5MiIsInRocmVhZF9wYXJ0aWNpcGFudF9pZHMiOm51bGwsInRocmVhZF90eXBlIjoiVVNFUl9UT19QQUdFIiwidGlkIjoiMjE4MzU5NDQyODMyNTg5MiJ9',
  TEST_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJEYXRhIjp7ImFwcCI6Im1lc3NlbmdlciIsImF1dGhJZCI6IjUwOTk2NTE2NjEyMjA2OCIsImF1dGhUb2tlbiI6IkVBQUZKd25ybWd4OEJBRVpBOWxObWZOODNhNUhhQkxrQTRwTlg0UUNDaUl4QnNWbHdheldHRVU5OEh1dlhxWkJUYmxSc1RaQXBRWkNHd1JCa2diWkJwbnFHNTdaQVpCSFlpRFE2c1h0dnJWWkN5b29Jb0daQlBqWUpJWHRaQ0J1blVBcUlWa3FHdmpkR2VYcFpDV250SHFlcXJWcW5uSGZmU1EzbXBRdmZPcUpaQ2VkUHl3WkRaRCIsImJ1c2luZXNzSWQiOiIxIiwiYnVzaW5lc3NOYW1lIjoiU2Fsw6NvIFPDo28gUGF1bG8gMSIsImJ1c2luZXNzUGVybWFsaW5rIjoic2FsYW8tc2FvLXBhdWxvLTEiLCJjbGllbnRJZCI6bnVsbCwiY2xpZW50TmFtZSI6IlBlZHJvIExvcGVzIiwiY2xpZW50VHlwZSI6IiIsImN1c3RvbWVySWQiOm51bGwsInRpbWV6b25lIjoiQW1lcmljYS9TYW9fUGF1bG8iLCJ0eXBlIjoiYjJjIn0sImV4cCI6MTYwNjMwMTcxNn0.UKBkAMId8KIIpLtWf8tZ3_v5cEXhEc0fsWIplmdzCZw',
  BYPASS_API: false,

  // GENERAL
  SOURCE_GENERAL_KEY: 'booking-web', //'website',
  // MESSENGER
  SOURCE_MESSENGER_KEY: 'bot-messenger', //'messenger',
  // FACEBOOK
  SOURCE_FACEBOOK_KEY: 'booking-web.facebook',
  // WHATSAPP
  SOURCE_WHATSAPP_KEY: 'booking-web.whatsapp', //'whatsapp',
  REF_WHATSAPP_KEY: 'w',
  // MARKETING
  SOURCE_MARKETING_KEY: 'booking-web.marketing.landingpage', //'marketing',
  SOURCE_MARKETING_GOOGLE_KEY: 'booking-web.marketing.google',
  SOURCE_MARKETING_INSTAGRAM_KEY: 'booking-web.marketing.instagram',
  SOURCE_MARKETING_FACEBOOK_KEY: 'booking-web.marketing.facebook',
  REF_MARKETING_KEY: 'ilm_ref',
  REF_MARKETING_VALUE: 'lp-mkt',
  REF_MARKETING_GOOGLE_VALUE: 'ggl-mkt',
  REF_MARKETING_INSTAGRAM_VALUE: 'ig-mkt',
  REF_MARKETING_FACEBOOK_VALUE: 'fb-mkt',
  // GOOGLE
  SOURCE_GOOGLE_KEY: 'booking-web.google', //'google',
  REF_GOOGLE_KEY: 'g',
  // SMS
  SOURCE_SMS_KEY: 'booking-web.sms',
  REF_SMS_KEY: 's',
  // SMS MARKETING
  SOURCE_SMS_MARKETING_KEY: 'booking-web.sms.marketing',
  REF_SMS_MARKETING_KEY: 'sm',
  // QRCODE
  SOURCE_QRCODE_KEY: 'booking-web.qrcode',
  REF_QRCODE_KEY: 'qrcode',

  REF_ANALYTICS_TRACKING_KEY: 'ilm_analytics',


  theme_state_booked: '#007aff',
  theme_state_confirmed: '#5674b9',
  theme_state_waiting: '#fc0',
  theme_state_started: '#acd373',
  theme_state_late: '#f7941d',
  theme_state_paid: '#00aa4f',
  theme_state_canceled: '#ccc',
  theme_state_noshow: '#f00',
};
