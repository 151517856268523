<template>
  <div class="login">
    <div v-if="showBusinessForm">
      <label class="input-container">
        <input
          ref="input"
          v-model.trim="message"
          placeholder="Business Id"
          name="input"
          class="input-field">
      </label>
      <div class="button-container">
        <IlmButton
          :on-click="clickNext"
          label="Salvar"
          icon="arrow_forward"
          prevent-default
          theme="block success"
          class="confirm_button" />
      </div>
    </div>
    <IlmLoader
      ref="loading"
      :is-loading="isLoading" />

    <Alert
      v-if="alertShowing"
      :title="alertTitle"
      :message="alertMessage"
      :btn-ok="$t('alerts.alert_generic_ok')"
      @closed="onAlertClosed"
      @ok="onAlertClosed" />
  </div>
</template>

<script>
// @ is an alias to /src
import IlmLoader from '@/components/IlmPageLoader.vue';
import IlmButton from '@/components/Buttons/IlmButton.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import CustomerDetails from '@/models/billing/customerDetails';
import Appointment from '@/models/appointment';
import MessengerContext from '@/libs/models/messengerContext';
import Error from '@/graphql/error';
import utils from '@/utils/utils';
import jwt from '@/utils/jwt';
//import cookieMigration from '@/store/cookie-migration';

export default {
  name: 'Login',
  components: {
    IlmLoader,
    IlmButton,
    Alert,
  },

  // Route: before leave ********************************
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;

    if (IsItABackButton) {
      next(false);
    } else {
      next();
    }
  },

  // Props ********************************
  props: {
    token: String,
    endpoint: String,
    source: String,
    permalink: String,
    trackingId: String,
    bunsinessId: String,
    pageId: String,
    comp: String,
  },

  // Data ********************************
  data() {
    return {
      showBusinessForm: false,
      isLoading: true,
      message: '',

      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: null,
    };
  },

  created() {
    if (
      this.$sentry
      && (this.businessId || this.pageId)
      && this.$root.cookieData
      && this.$root.cookieData.performance
    ) {
      const self = this;
      this.$sentry.configureScope(scope => {
        scope.setTag('businessId', self.businessId);
        scope.setTag('pageId', self.pageId);
      });
    }
  },

  // Mounted ********************************
  mounted() {
    //this.$moment.locale('pt-br');
    const self = this;
    if (
      process.env.NODE_ENV === 'development'
      && (
        this.$constants.TEST_USE_MESSENGER_FLOW
        || this.source === this.$constants.SOURCE_MESSENGER_KEY
      )
    ) {
      self.$store.dispatch('setMessenger', true);
      self.$store.dispatch('setSourceDescription', self.$constants.SOURCE_MESSENGER_KEY);
      this.requestToken(
        new MessengerContext({
          signed_request: self.$constants.TEST_SIGNED_REQUEST,
        })
      );
    } else if (
      this.permalink
      && this.source
      && this.source.indexOf(this.$constants.SOURCE_GENERAL_KEY) !== -1
    ) {
      this.$store.dispatch('setSourceDescription', this.source);
      this.requestExternalSourceToken(this.source);
    } else if (this.$facebook.isInitiated() === false) {
      this.$facebook.init(this.$constants.FACEBOOK_APP_ID).then(isInExtension => {
        //console.log(`got facebook callback: ${isInExtension}`);
        self.$store.dispatch('setMessenger', self.source === self.$constants.SOURCE_MESSENGER_KEY);
        self.$store.dispatch('setMessengerFrame', isInExtension);

        if (self.source !== self.$constants.SOURCE_MESSENGER_KEY) {
          self.$store.dispatch('setSourceDescription', self.source);
          self.requestExternalSourceToken(self.source);
          /*
          self.requestToken(new MessengerContext({
            signed_request: self.$constants.TEST_SIGNED_REQUEST,
          }));*/
        } else {
          self.$store.dispatch('setSourceDescription', self.$constants.SOURCE_MESSENGER_KEY);
          self.getFacebookContext();
        }
      });
    } else {
      self.$store.dispatch('setSourceDescription', self.$constants.SOURCE_MESSENGER_KEY);
      this.getFacebookContext();
    }
  },

  // Methods ********************************
  methods: {
    getFacebookContext() {
      this.$facebook.getContext().then(
        context => this.requestToken(context),
        err => {
          //console.error(err);
          this.showError(this.$t('errors.generic_title'), this.$t('errors.generic_message'), true);
          if (this.$sentry) {
            this.$sentry.captureException(err);
          }
        }
      );
    },
    async requestExternalSourceToken(source) {
      const savedToken = this.$localStorage.getToken();
      if (this.$graphql.showLogs) {
        console.log(`saved cookie token: ${savedToken}`);
      }

      /*if (this.$localStorage.isProduction && (!savedToken || savedToken === '')) {
        try {
          const cookies = await cookieMigration.requestCookie();
          if (cookies.prod && cookies.prod !== '') {
            this.$localStorage.setToken(cookies.prod);
            savedToken = cookies.prod;
          }
        } catch (error) {
          console.error('Error', error);
        }
      }*/

      const self = this;
      this.$graphql.getAccessToken(
        source,
        savedToken !== '' ? savedToken : null,
        this.permalink,
        function (obj) {
          const token = obj.token;
          const intent = obj.intent;
          //const intent = obj.intent;
          if (token) {
            //self.$localStorage.setToken('');
            //console.log(self.$localStorage.getToken());
            self.setToken(token, intent);
          } else {
            //console.log('empty token returned by getAccessToken');
            self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
          }
        },
        function (error) {
          if (error.getCode() === Error.expiredTokenCode && savedToken && savedToken !== '') {
            self.$localStorage.setToken('');
            self.requestExternalSourceToken(source);
          } else if (
            error.getCode() === Error.businessNotFoundCode
            || error.getCode() === Error.appInactiveCode
          ) {
            self.$router.replace({
              name: self.$constants.ROUTE_NOT_FOUND,
              params: {
                businessNotFound: error.getCode() === Error.appInactiveCode,
                business: error.business,
              },
            });
          } else if (
            error.business
            && error.business.permalink
            && error.business.permalink !== self.permalink
          ) {
            self.isLoading = false;
            self.$root.loading = false;
            self.alert(self.$t('errors.generic_title'), self.$t('errors.generic_message'), function () {
              const url = `${window.location.protocol}//${window.location.host}/${error.business.permalink}`;
              window.location.replace(url);
            });
          } else {
            self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
          }
        }
      );
    },
    requestToken(messengerContext) {
      this.$analytics.identify(messengerContext.psid);
      this.$analytics.register({ psid: messengerContext.psid });
      this.$analytics.track('Open Webview', { ref: this.comp });
      const self = this;
      this.$graphql.getAccessToken(
        this.$constants.SOURCE_MESSENGER_KEY,
        messengerContext.signedRequest,
        null,
        function (obj) {
          const token = obj.token;
          if (token) {
            self.setToken(token);
          } else {
            //console.log('empty token returned by getAccessToken');
            self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
          }
        },
        function () {
          //console.log(error);
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
        }
      );
    },

    async setToken(token, intent) {
      const auth = jwt.parseToken(token);
      this.$store.dispatch('setAuth', auth);

      if (this.trackingId) {
        this.$analytics.setTrackingId(this.trackingId);
      }
      await this.$analytics.setBusiness(auth.business.id);

      if (this.$store.getters.isExternalSource) {
        this.$localStorage.setToken(token);

        /*if (auth.redirectTo && auth.permalink && window.history.replaceState) {
          window.history.replaceState({}, '', `/${auth.permalink}`);
        }*/

        if (auth.isAuthenticated()) {
          this.$analytics.identify(auth.client.customerId);
        }

        // test source string as prefix
        if (
          auth.source.lastIndexOf(this.$constants.SOURCE_SMS_KEY, 0) === 0
          && this.permalink !== auth.business.permalink
        ) {
          let ref = this.$constants.REF_SMS_KEY;
          if (auth.source === this.$constants.SOURCE_SMS_MARKETING_KEY) {
            ref = this.$constants.REF_SMS_MARKETING_KEY;
          }
          if (intent) {
            this.$analytics.track('Visit: SMS Login');
            this.$localStorage.setIntent(intent);

            const jsonIntent = JSON.parse(decodeURIComponent(intent));
            if (jsonIntent.id) {
              this.$localStorage.setIntentId(jsonIntent.id);

              if (intent.source === this.$constants.SOURCE_WHATSAPP_KEY) {
                ref = this.$constants.REF_WHATSAPP_KEY;
              }
            }
          }

          const url = `${window.location.protocol}//${window.location.host}/${auth.business.permalink}?ref=${ref}`;
          window.location.replace(url);
          return;
        }
        /*
        if (auth.redirectTo && auth.business.permalink) {
          const url = `${window.location.protocol}//
          ${window.location.host}/${auth.business.permalink}`;
          window.location.replace(url);
          return;
        }*/

        this.addAppManifest(auth);
      }

      if (this.$graphql.showLogs) {
        console.log(`${auth.source} | ${auth.business.permalink}`);
        console.log(auth);
      }

      const business = auth.business;
      const client = auth.client;

      if (this.$sentry && this.$root.cookieData && this.$root.cookieData.performance) {
        this.$sentry.configureScope(scope => {
          scope.setTag('businessId', business.id);
          scope.setTag('businessName', business.name);
          scope.setTag('pageId', auth.pageId);

          if (auth.isAuthenticated()) {
            scope.setUser({
              id: client.customerId,
              username: client.name,
            });
          }
        });
      }

      let appSource = '';
      if (auth.source) {
        appSource = auth.source;
      }

      this.$analytics.register({
        businessId: business.id,
        businessName: business.name,
        pageId: auth.pageId,
        clientName: client.name,
        clientId: client.id,
        clientPhone: `+${client.phoneCountry}${client.phoneNumber}`,
        customerId: client.customerId,
        source: appSource,
      });

      if (auth.isAuthenticated()) {
        this.$analytics.setPeopleParam('name', client.name);
        this.$analytics.setPeopleParam('clientId', client.id);
        this.$analytics.setPeopleParam('clientProfileId', client.customerId);

        if (client.phoneNumber && client.phoneCountry) {
          this.$analytics.setPeopleParam('phone', `+${client.phoneCountry}${client.phoneNumber}`);
        }
      }

      if (auth.campaignId) {
        this.$analytics.track('Campaign Visit', { campaignId: auth.campaignId });
      }

      if (auth.isAuthenticated()) {
        this.requestCurrentClient(auth);
      } else {
        this.requestBusinessDetails(auth);
      }
    },

    requestCurrentClient(auth) {
      const self = this;
      this.$graphql.getCurrentCustomer(
        false,
        function (obj) {
          auth.updateCurrentClient(obj);
          self.$store.dispatch('updateAuth', auth);
          self.requestBusinessDetails(auth);
        },
        function (error) {
          if (!error.getHttpCode() || error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            if (self.$store.getters.isExternalSource) {
              self.requestExternalSourceToken(self.source);
              return;
            }
          }
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
        }
      );
    },

    requestBusinessDetails(auth) {
      const self = this;
      this.$graphql.getBusinessDetails(
        function (businessData) {
          //self.$localStorage.setBusinessName(businessData.name);
          /*
        auth.business.id = businessData.id;
        auth.business.name = businessData.name;
        auth.business.phoneCountry = businessData.phoneCountry;
        auth.business.phoneNumber = businessData.phoneNumber;
        auth.business.slotsMappingType = businessData.slotsMappingType;
        */
          const permalink = auth.business.permalink;
          auth.business = businessData;
          auth.business.permalink = permalink;

          if (businessData.timezone) {
            self.$dateUtils.setTimezone(businessData.timezone);
          }

          if (businessData.locale) {
            self.$root.$i18n.locale = businessData.locale;
          }

          self.proceed(auth);
          //self.requestUserData(auth);
        },
        function (error) {
          if (error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            if (self.$store.getters.isExternalSource) {
              self.requestExternalSourceToken(self.source);
              return;
            }
          }
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'), true);
        }
      );
    },

    proceed(auth) {
      this.$store.dispatch('setAuth', auth);

      /*
      this.$root.loading = false;
      this.$router.push({
        name: this.$constants.ROUTE_VERIFY_PHONE,
        params: { endpoint: this.endpoint },
      });*/
      const intent = this.$localStorage.getIntent();
      if (this.endpoint && this.endpoint === this.$constants.SOURCE_LOCATION_MY_BOOKINGS) {
        this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS }, () => {});
        /*if (auth.isAuthenticated()) {
          // go to my appointments
          this.$router.push({ name: this.$constants.ROUTE_MY_BOOKINGS });
        } else {
          // go to phone verification before my appointments
          this.$root.loading = false;
          this.$router.push({
            name: this.$constants.ROUTE_VERIFY_PHONE,
            params: { endpoint: this.endpoint },
          });
        }*/
      } else if (intent && intent.length > 0) {
        //this.proceedToIntent(decodeURIComponent(intent));
        this.getDiscountsAndFollowIntent(decodeURIComponent(intent));
      } else {
        this.goToRoot();
      }
    },

    getDiscountsAndFollowIntent(intent) {
      const self = this;
      this.$graphql.businessDiscountsQuery(
        function (discounts) {
          self.$store.dispatch('setDiscounts', discounts);
          self.proceedToIntent(intent);
        },
        function () {
          self.$store.dispatch('setDiscounts', null);
          self.proceedToIntent(intent);
        }
      );
    },

    proceedToIntent(intent) {
      this.$localStorage.setIntent(null);
      if (this.$graphql.showLogs) {
        console.log(`Intent: ${intent}`);
      }
      const jsonIntent = JSON.parse(intent);
      if (jsonIntent) {
        this.$root.loading = false;
        const endpoint = jsonIntent.endpoint;
        const params = jsonIntent.params;
        if (endpoint === this.$constants.ROUTE_MY_BOOKINGS) {
          this.$router.push({ name: endpoint }, () => {});
          return;
        }
        if (endpoint === this.$constants.ROUTE_PAYMENT_DETAILS && params) {
          const customer = CustomerDetails.typecast(params);
          this.$store.dispatch('setCustomer', customer);
          this.$router.push({ name: endpoint }, () => {});
          return;
        }
        if (endpoint === this.$constants.ROUTE_NEW_APPOINTMENT_CONFIRMATION && params) {
          this.getIntentAppointmentDetails(jsonIntent);
          return;
        }
      }
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger === true) {
        this.$router.replace(
          {
            name: this.$constants.ROUTE_SEARCH_SERVICE,
            params: { showBusinessServices: false, initScreen: true },
          },
          () => {}
        );
      } else {
        this.$router.replace({ name: this.$constants.ROUTE_BUSINESS_DETAILS }, () => {});
      }
    },

    getIntentAppointmentDetails(intent) {
      //const endpoint = intent.endpoint;
      const params = intent.params;
      const serviceIds = [];
      const professionalIds = [];
      for (let i = 0; i < params.length; i++) {
        //apps.push(Appointment.typecast(params[i]));
        serviceIds.push(params[i].serviceId);
        if (params[i].professionalId) {
          professionalIds.push(params[i].professionalId);
        }
      }
      this.$graphql.servicesDetailsQuery(
        serviceIds,
        services => {
          if (professionalIds.length > 0) {
            this.$graphql.getProfessionals(
              professionalIds,
              professionals => {
                this.setAppointmentParams(intent, services, professionals.getItems());
              },
              () => {
                this.goToRoot();
              }
            );
          } else {
            this.setAppointmentParams(intent, services, null);
          }
        },
        () => {
          this.goToRoot();
        }
      );
      /*this.$store.dispatch('resetAppointment');
      this.$store.getters.getAppointments.updateAppointments(apps);
      this.$router.push({
        name: endpoint,
      }, () => {});*/
    },

    setAppointmentParams(intent, services, professionals) {
      const endpoint = intent.endpoint;
      const params = intent.params;
      const appointments = [];
      for (let i = 0; i < params.length; i++) {
        const a = params[i];
        const app = new Appointment();
        app.day = a.day;
        app.time = a.time;
        app.service = services.filter(e => e.id === a.serviceId)[0];
        if (a.professionalId && professionals) {
          const pFilter = professionals.filter(e => e.id === a.professionalId);
          if (pFilter.length > 0) {
            app.professional = pFilter[0];
          }
        }
        app.date = this.$moment(`${a.day} ${a.time}`, 'YYYY-MM-DD HH:mm');
        appointments.push(app);
      }
      console.log(appointments);
      this.$store.dispatch('resetAppointment');
      this.$store.getters.getAppointments.updateAppointments(appointments);
      this.$router.push(
        {
          name: endpoint,
        },
        () => {}
      );
    },

    goToRoot() {
      this.$root.loading = false;
      // go to service search
      const fbMessenger = this.$store.getters.getMessenger;
      if (fbMessenger.isMessenger === true) {
        this.$router.replace(
          {
            name: this.$constants.ROUTE_SEARCH_SERVICE,
            params: { showBusinessServices: false, initScreen: true },
          },
          () => {}
        );
      } else {
        this.$router.replace({ name: this.$constants.ROUTE_BUSINESS_DETAILS }, () => {});
      }
    },

    clickNext() {
      this.$router.push({ name: this.$constants.ROUTE_SEARCH_SERVICE, initScreen: true }, () => {});
    },

    // Alerts
    showError(title, message, closeOnDismiss) {
      this.isLoading = false;
      this.$root.loading = false;
      const self = this;
      this.alert(title, message, function () {
        if (!closeOnDismiss) {
          return;
        }
        const messenger = self.$store.getters.getMessenger;
        if (messenger.isMessenger === true && messenger.inFrame === true) {
          window.MessengerExtensions.requestCloseBrowser(
            function success() {
              // webview closed
            },
            function error() {
              // an error occurred
            }
          );
        }
      });
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertClosed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    addAppManifest(auth) {
      const url = `${window.location.protocol}//${window.location.host}/${auth.business.permalink}?app_source=true`;
      const imagesUrl = `${window.location.protocol}//${window.location.host}`;
      utils.addAppManifest(auth.business.name, auth.business.name, imagesUrl, url);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  position: relative;
  background: $theme-3-bg;
  border: 1px solid $box-border-color;
  border-radius: $theme-border-radius;
  padding: 5px 10px;
  margin: 16px;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  &--focused {
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

.input-field {
  font-family: $base-font-family;
  font-weight: bold;
  font-size: $font-size-lg;
  display: inline-block;
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;
  flex: 1 1 auto;

  &:focus,
  &:active {
    outline: none;
    // border: none;
  }
}

.button-container {
  padding: 0 16px 16px 16px;
}
</style>
