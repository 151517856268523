<template>
  <div>
    <div class="new-booking-items">
      <Sticky>
        <div class="new-booking__fixed_top">
          <!--NavigationBar
            :title="navbarSubtitle"
            :subtitle="navbarTitle"
            :small-title="navbarSmallTitle"
            :show-back="showNavbarBack"
            is-extendable
            show-divider/-->
          <Navbar
            :title="navbarTitle"
            :subtitle="navbarSubtitle"
            :show-shadow="false"
            :show-back="showNavbarBack"
            interchangeable-titles
            show-back-bevel
            show-divider />
        </div>
      </Sticky>
      <!--Sticky>
        <div class="new-booking__fixed_top">
          <NavigationBar
            :title="navbarSubtitle"
            :subtitle="navbarTitle"
            :show-back="showNavbarBack"
            is-extendable
            show-divider/>
        </div>
      </Sticky-->
      <div class="new-booking-items__content">
        <SectionAppointments
          :appointments="appointmentsList"
          :appointments-policies="appointmentsPolicies"
          :date-text="navbarSubtitle"
          :show-services-count="showServicesCount"
          :selected-key="selectedKey"
          :discount="discount"
          :is-loading="isLoading"
          :on-edit-note-click="clickEditNote"
          :on-cancel-click="clickCancel"
          :on-repeat-click="clickRepeat"
          :on-edit-click="clickEdit" />

        <SectionMap
          v-if="showEditableFields"
          :title="$t('button.business_details_open_map')" />
      </div>

      <AlertCancelPrompt
        v-if="alertCancelShowing"
        :title="$t('alerts.alert_cancel_appointment_message')"
        :btn-cancel="$t('alerts.alert_cancel_appointment_no')"
        :btn-destructive="$t('alerts.alert_cancel_appointment_yes')"
        @closed="onAlertClosed"
        @cancel="onAlertClosed"
        @ok="cancelAppointment" />

      <Alert
        v-if="alertShowing"
        :title="alertTitle"
        :message="alertMessage"
        :btn-ok="$t('alerts.alert_generic_ok')"
        @closed="onAlertDismissed"
        @ok="onAlertDismissed" />

      <ModalNotes
        v-if="modalNotesShowing"
        ref="notes_modal"
        :saved-note="appointmentEditNote.notes"
        @closed="onModalNotesClosed"
        @ok="onModalNotesSaved" />

      <AlertDestructive
        v-if="alertDeleteShowing"
        :title="$t('alerts.alert_delete_note_title')"
        :message="$t('alerts.alert_delete_note_message')"
        :btn-cancel="$t('alerts.alert_delete_note_no')"
        :btn-destructive="$t('alerts.alert_delete_note_yes')"
        @closed="onDeleteNoteAlertClosed"
        @cancel="onDeleteNoteAlertClosed"
        @ok="onDeleteNoteAlertConfirm" />

      <Popover
        v-if="popupSuccessShowing"
        :title="popupMessage"
        icon="check"
        @closed="popupSuccessShowing = false" />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Nav/Navbar.vue';
import SectionAppointments from '@/components/Sections/SectionAppointments.vue';
import SectionMap from '@/components/Sections/SectionMap.vue';
import Alert from '@/components/Alerts/BaseAlert.vue';
import AlertDestructive from '@/components/Alerts/AlertDestructive.vue';
import AlertCancelPrompt from '@/components/Alerts/AlertCancelPrompt.vue';
import ModalNotes from '@/components/Modals/ModalNotes.vue';
import Popover from '@/components/Popover/PopoverSimple.vue';
import Error from '@/graphql/error';
import Appointment from '@/models/appointment';

export default {
  name: 'MyBookingItems',

  // Components ********************************
  components: {
    Navbar,
    SectionAppointments,
    SectionMap,
    Alert,
    AlertDestructive,
    AlertCancelPrompt,
    ModalNotes,
    Popover,
  },

  // Props ********************************
  props: {
    selectedKey: String,
    day: {
      type: String,
      required: true,
    },
    appointments: {
      type: Array,
      required: true,
    },
  },

  // Data ********************************
  data() {
    return {
      navbarTitle: null,
      navbarSubtitle: null,
      isDestroyed: false,

      appointmentToCancel: null,
      alertCancelShowing: false,
      alertShowing: false,
      alertTitle: '',
      alertMessage: '',
      alertCallback: Function,

      appointmentEditNote: null,
      popupSuccessShowing: false,
      popupMessage: '',
      modalNotesShowing: false,
      alertDeleteShowing: false,

      appointmentsList: null,
      appointmentsPolicies: null,
      showServicesCount: true,
      discount: null,
      title: '',
      subtitle: '',
      state: null,
      isLockedAppointment: true,
      showOptions: true,
      sectionList: [],
      isLoading: false,
      windowWidth: null,
    };
  },

  // Computed ********************************
  computed: {
    showNavbarBack() {
      return this.$store.getters.isExternalSource === true;
    },
    navbarSmallTitle() {
      return this.appointmentsList && this.appointmentsList.length > 1 ? `${this.appointmentsList.length} Serviços` : null;
    },
    isFutureAppointment() {
      if (this.day) {
        const day = this.day;

        const timezone = this.$dateUtils.getTimezone();
        const date = this.$moment.tz(`${day} 23:59`, timezone);
        const now = this.$moment.tz(new Date(), timezone);

        return date.isAfter(now);
      }
      return false;
    },
    showEditableFields() {
      //return this.isFutureAppointment === true && this.state !== 'canceled';
      return this.isFutureAppointment === true;
    },
    showCancel() {
      return this.isLockedAppointment === false && ['paid', 'pending'].includes(this.state) === false;
    },
    footerClass() {
      return {
        'new-booking-confirmation__footer--sticky': this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH,
      };
    },
    footerStyles() {
      if (this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH) {
        return {
          position: 'sticky',
        };
      }
      return {
        position: 'fixed',
      };
    },
    business() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        return auth.business;
      }
      return null;
    },
  },

  // Watch ********************************
  watch: {},

  // Created ********************************
  created() {
    if (!this.appointments || !this.day) {
      this.$router.go(-1);
      //return;
    }

    //this.updateFields(this.appointment);
    //this.updateNavbar(this.appointment);
  },

  // Mounted ********************************
  mounted() {
    if (!this.appointments || !this.day) {
      return;
    }

    const auth = this.$store.getters.getAuth;
    if (!auth || !auth.business || !auth.business.name) {
      return;
    }

    this.windowWidth = document.body.clientWidth;
    window.addEventListener('resize', this.handleResize);

    this.$analytics.track('Visit: My bookings item');

    this.navbarTitle = this.$root.isDesktop
      ? this.$tc('title.services_counter', this.appointments.length, {
        count: this.appointments.length,
      })
      : auth.business.name;
    this.showServicesCount = this.$root.isDesktop === false;

    const timezone = this.$dateUtils.getTimezone();
    const now = this.$moment.tz(new Date(), timezone);
    const currentYear = now.year();
    const date = this.$moment(this.day);
    const dateYear = date.year();
    if (dateYear !== currentYear) {
      this.navbarSubtitle = `${this.$dateUtils.getDateString(date, 'ddd', true)} ${this.$dateUtils.getDateString(date, 'D MMMM YYYY', false)}`;
    } else {
      this.navbarSubtitle = `${this.$dateUtils.getDateString(date, 'ddd', true)} ${this.$dateUtils.getDateString(date, 'D MMMM', false)}`;
    }

    this.updateAppointmentsList(this.appointments);

    if (this.selectedKey) {
      const self = this;
      this.$nextTick(function () {
        const el = self.$el.getElementsByClassName('section-scroll-to')[0];
        if (el) {
          /*el.scrollIntoView({
            behavior: 'smooth',
          });*/
          const rect = el.getBoundingClientRect();
          // Scroll to the element (using x and y axis)
          setTimeout(function () {
            window.scrollTo({
              top: rect.top - 140,
              left: 0,
              behavior: 'smooth',
            });
          }, 500);
        }
      });
    }

    this.requestAppointment();
  },

  destroyed() {
    this.isDestroyed = true;
    this.sectionList = [];
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods ********************************
  methods: {
    shouldAnimate(key, index) {
      return this.selectedKey === key && index > 0;
    },
    updateNavbar(appointment) {
      // STATE
      this.state = appointment.getState();
      // DATE
      //const timezone = this.$dateUtils.getTimezone();
      const day = appointment.day;
      const date = this.$moment(day);
      const time = appointment.time;
      if (!date || !time) {
        this.$router.go(-1);
        return;
      }

      this.checkState();
    },

    updateAppointmentsList(aps) {
      this.appointmentsList = aps.sort(function compare(a, b) {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      });
    },

    updateFields(appointment) {
      this.updateNavbar(appointment);
      // SERVICE
      const service = appointment.service;
      const serviceObj = {
        section: this.$t('section_list.service_title'),
        name: service.name,
        price: service.price,
        estimate: ['paid', 'pending'].includes(this.state) === false,
        duration: service.duration,
      };
      const serviceIndex = this.sectionList.map(item => item.section).indexOf(serviceObj.section);
      if (serviceIndex === -1) {
        this.sectionList.push(serviceObj);
      } else {
        this.sectionList[serviceIndex] = serviceObj;
      }

      // PROFESSIONAL
      const professional = appointment.professional;
      const professionalObj = {
        section: this.$t('section_list.professional_title'),
        name: professional ? professional.name : this.$t('section_list.appointment_professional_any'),
      };
      const professionalIndex = this.sectionList.map(item => item.section).indexOf(professionalObj.section);
      if (professionalIndex === -1) {
        this.sectionList.push(professionalObj);
      } else {
        this.sectionList[professionalIndex] = professionalObj;
      }

      // NOTES
      const notes = appointment.notes;
      if (notes) {
        const notesObj = {
          section: this.$t('section_list.note_title'),
          name: notes,
        };
        const notesIndex = this.sectionList.map(item => item.section).indexOf(notesObj.section);
        if (notesIndex === -1) {
          this.sectionList.push(notesObj);
        } else {
          this.sectionList[notesIndex] = notesObj;
        }
      }
    },

    getNotesSection() {
      return {
        section: this.$t('section_list.note_appointment_title'),
        name: this.appointment.notes,
      };
    },
    /*
    clickAddNote() {
      if (this.isFutureAppointment) {
        this.modalNotesShowing = true;
      }
    },
*/
    clickEditNote(appointment) {
      if (appointment.isFuture()) {
        this.appointmentEditNote = appointment;
        this.modalNotesShowing = true;
      }
    },

    onNotesSectionClear() {
      if (this.isFutureAppointment) {
        this.saveNote('');
      }
    },

    onModalNotesClosed() {
      this.modalNotesShowing = false;
      this.appointmentEditNote = null;
    },
    onModalNotesSaved(notes) {
      this.modalNotesShowing = false;
      if (!this.appointmentEditNote) {
        return;
      }

      if (notes && notes.length > 0) {
        const trimmedNotes = notes.trim();
        if (trimmedNotes.length > 0) {
          this.saveNote(trimmedNotes);
          return;
        }
      }
      this.alertDeleteShowing = true;
      //this.saveNote('');
    },

    saveNote(notes) {
      this.isLoading = true;
      this.$root.loading = true;
      const self = this;
      this.appointmentEditNote.notes = notes;
      const appointmentList = [this.appointmentEditNote];
      this.$graphql.saveAppointmentNote(
        appointmentList,
        function () {
          /*if (notes && notes.length > 0) {
          self.sectionList.push({
            section: self.$t('section_list.note_title'),
            name: self.notes,
          });
        }*/
          //self.appointment.notes = notes;
          if (self.appointmentEditNote.notes && self.appointmentEditNote.notes.length > 0) {
            try {
              const keysNotes = appointmentList.map(x => x.key).join(',');
              self.$analytics.track('Action: Appointment Note Edited', { keys: keysNotes });
            } catch (ex) {
              console.log(ex);
            }
          }

          const index = self.appointmentsList.findIndex(a => a.key === self.appointmentEditNote.key);
          self.appointmentsList[index].notes = notes;
          self.appointmentEditNote = null;

          self.isLoading = false;
          self.$root.loading = false;

          self.popupSuccessShowing = false;
          self.popupMessage = self.$t('popover.save_success');
          self.popupSuccessShowing = true;
        },
        function () {
          self.appointmentEditNote = null;
          self.isLoading = false;
          self.$root.loading = false;
          self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
        },
      );
    },

    clickRepeat(appointment) {
      //const appointment = this.$store.getters.getAppointment;
      //const appointment = this.appointment;
      if (!appointment || !appointment.service) {
        return;
      }
      const serviceId = appointment.service.id;
      const professional = appointment.professional;
      const notes = appointment.notes;

      this.$analytics.track('Action: Repeat Booking', { key: appointment.key });

      this.isLoading = true;
      this.$root.loading = true;

      this.$graphql.servicesDetailsQuery(
        [serviceId],
        services => {
          this.isLoading = false;
          this.$root.loading = false;

          if (services.length === 0) {
            return;
          }

          this.$store.dispatch('resetAppointment');
          const service = services[0];
          const apps = this.$store.getters.getAppointments;
          if (apps.addService(service)) {
            apps.appointments[0].professional = professional;
            apps.appointments[0].notes = notes;

            // remove service user paid price
            //apps.appointments[0].service.value = null;

            if (appointment.discountId) {
              const discounts = this.$store.getters.getDiscounts;
              if (discounts) {
                apps.appointments[0].discount = discounts.getDiscountWithId(appointment.discountId);
              }
            }
            this.$store.dispatch('setAppointments', apps);
            this.$router.push({ name: this.$constants.ROUTE_NEW_APPOINTMENT_SERVICES });
          }
        },
        () => {
          this.isLoading = false;
          this.$root.loading = false;
          this.showError(this.$t('errors.generic_title'), this.$t('errors.generic_message'));
        },
      );
    },
    clickEdit(appointment) {
      if (!appointment) {
        return;
      }
      const auth = this.$store.getters.getAuth;
      if (auth.business && auth.business.minTimeToUpdate === 0) {
        let message = this.$t('alerts.alert_cannot_edit_appointment_message');
        const phoneFormatted = auth.business.getPhoneFormatted();
        const phone = auth.business.getPhone();
        if (phoneFormatted) {
          message = `${message}<br><br><a href="tel:${phone}">${phoneFormatted}</a>`;
        }
        this.alert(this.$t('alerts.alert_cannot_edit_appointment_title'), message);
        return;
      }

      //this.alert('Atenção', 'Em desenvolvimento...');
      this.$analytics.track('Action: Edit Booking', { key: appointment.key });

      const serviceId = appointment.service.id;
      const serviceDuration = appointment.service.duration;
      const professional = appointment.professional;
      const notes = appointment.notes;
      const time = appointment.time;
      const day = appointment.day;
      const discountId = appointment.discountId;
      const key = appointment.key;
      const date = this.$moment(`${day} ${time}`, 'YYYY-MM-DD HH:mm');

      this.isLoading = true;
      this.$root.loading = true;

      this.$graphql.servicesDetailsQuery(
        [serviceId],
        services => {
          this.isLoading = false;
          this.$root.loading = false;

          if (services.length === 0) {
            return;
          }

          this.$store.dispatch('resetAppointment');
          const service = services[0];
          const apps = this.$store.getters.getAppointments;
          if (apps.addService(service)) {
            apps.appointments[0].professional = professional;
            apps.appointments[0].notes = notes;
            apps.appointments[0].time = time;
            apps.appointments[0].day = day;
            apps.appointments[0].date = date;
            apps.appointments[0].discountId = discountId;
            apps.appointments[0].key = key;
            apps.appointments[0].service.duration = serviceDuration;

            // remove service user paid price
            //apps.appointments[0].service.value = null;

            if (appointment.discountId) {
              const discounts = this.$store.getters.getDiscounts;
              if (discounts) {
                apps.appointments[0].discount = discounts.getDiscountWithId(appointment.discountId);
              }
            }
            this.$store.dispatch('setAppointments', apps);
            const editAppointment = new Appointment();
            editAppointment.professional = professional;
            editAppointment.notes = notes;
            editAppointment.time = time;
            editAppointment.day = day;
            editAppointment.date = date;
            editAppointment.discountId = discountId;
            editAppointment.key = key;

            this.$store.dispatch('setEditAppointment', editAppointment);
            this.$router.replace(
              {
                name: this.$constants.ROUTE_NEW_APPOINTMENT,
                params: { isEditMode: true },
              },
              () => {},
            );
          }
        },
        () => {
          this.isLoading = false;
          this.$root.loading = false;
          this.showError(this.$t('errors.generic_title'), this.$t('errors.generic_message'));
        },
      );
    },
    clickCancel(appointment) {
      if (!appointment) {
        return;
      }
      const auth = this.$store.getters.getAuth;
      if (auth.business && auth.business.minTimeToCancel === 0) {
        let message = this.$t('alerts.alert_cannot_cancel_appointment_message');
        const phoneFormatted = auth.business.getPhoneFormatted();
        const phone = auth.business.getPhone();
        if (phoneFormatted) {
          message = `${message}<br><br><a href="tel:${phone}">${phoneFormatted}</a>`;
        }
        this.alert(this.$t('alerts.alert_cannot_cancel_appointment_title'), message);
        return;
      }
      this.appointmentToCancel = appointment;
      this.alertCancelShowing = true;
    },
    checkState() {
      const day = this.appointment.day;
      const time = this.appointment.time;

      const timezone = this.$dateUtils.getTimezone();
      const date = this.$moment.tz(`${day} ${time}`, timezone);

      const now = this.$moment.tz(new Date(), timezone);
      this.isLockedAppointment = !date.isAfter(now) || this.state === 'canceled' || this.appointment.getIsLocked() === true;

      //this.showOptions = !(date.isAfter(now) && ['paid', 'pending'].includes(this.state));

      this.title = this.$t(`states.${this.state}`).toUpperCase();
    },

    businessClick() {
      this.$router.replace({ name: 'root' }, () => {});
    },

    handleResize() {
      this.windowWidth = document.body.clientWidth;
      const auth = this.$store.getters.getAuth;
      this.navbarTitle = this.$root.isDesktop
        ? this.$tc('title.services_counter', this.appointments.length, {
          count: this.appointments.length,
        })
        : auth.business.name;
      this.showServicesCount = this.$root.isDesktop === false;
    },

    // Alerts
    showError(title, message) {
      this.alert(title, message);
    },
    onAlertClosed() {
      this.appointmentToCancel = null;
      this.alertCancelShowing = false;
    },
    alert(title, message, callback) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertShowing = true;
      this.alertCallback = callback;
    },
    onAlertDismissed() {
      this.alertShowing = false;
      const callback = this.alertCallback;
      if (callback) {
        this.alertCallback = null;
        callback();
      }
    },

    onDeleteNoteAlertClosed() {
      this.alertDeleteShowing = false;
      this.appointmentEditNote = null;
    },

    onDeleteNoteAlertConfirm() {
      this.alertDeleteShowing = false;
      this.saveNote('');
    },

    // REQUESTS

    async requestAppointment() {
      if (!this.appointments) {
        return;
      }
      const self = this;
      //this.$root.loading = true;
      this.isLoading = true;
      const keys = this.appointments.map(function (a) {
        return a.key;
      });

      //this.appointmentsPolicies = await this.requestPolicies(keys);

      this.$graphql.getAppointmentDetails(
        keys,
        function (aps) {
          if (self.isDestroyed) {
            return;
          }

          self.updateAppointmentsList(aps);

          const discountAppointments = aps.filter(function (a) {
            return a.discountId && a.discountId !== '';
          });
          if (discountAppointments && discountAppointments.length > 0) {
            const uniques = [];
            // remove discountId duplicates
            discountAppointments.forEach(element => {
              if (
                uniques.filter(function (a) {
                  return a.discountId === element.discountId;
                }).length === 0
              ) {
                uniques.push(element);
              }
            });
            self.requestDiscount(uniques, 0);
          } else {
            self.isLoading = false;
          }

          //self.$root.loading = false;
        },
        function (error) {
          //self.$root.loading = false;
          self.isLoading = false;
          if (error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            self.$router.push(
              {
                name: self.$constants.ROUTE_LOGIN,
                params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
              },
              () => {},
            );
          }
        },
      );
    },

    requestDiscount(arr, index) {
      const discountId = arr[index].discountId;
      //const appointmentKey = arr[index].key;
      const self = this;
      //this.$root.loading = true;
      this.isLoading = true;
      this.$graphql.getAppointmentDiscount(
        discountId,
        function (discount) {
          if (self.isDestroyed) {
            return;
          }
          //self.$store.dispatch('setAppointment', a);
          //self.discount = discount;
          //const index = self.appointmentsList.findIndex(a => a.key === appointmentKey);
          //self.appointmentsList[index].discount = discount;
          const indexesToUpdate = self.getAllIndexesOfDiscountId(self.appointmentsList, discountId);
          for (let i = 0; i < indexesToUpdate.length; i++) {
            self.appointmentsList[indexesToUpdate[i]].discount = discount;
          }

          if (arr.length - 1 < index) {
            self.requestDiscount(arr, index + 1);
          } else {
            self.isLoading = false;
          }
          //self.updateFields(a);
          //self.$root.loading = false;
        },
        function (error) {
          //self.$root.loading = false;
          self.isLoading = false;
          if (error.getCode() === Error.expiredTokenCode) {
            self.$localStorage.setToken('');
            self.$router.push(
              {
                name: self.$constants.ROUTE_LOGIN,
                params: { endpoint: self.$constants.SOURCE_LOCATION_MY_BOOKINGS },
              },
              () => {},
            );
          }
        },
      );
    },

    getAllIndexesOfDiscountId(arr, val) {
      const indexes = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].discountId === val) {
          indexes.push(i);
        }
      }
      return indexes;
    },

    cancelAppointment(reasonKey) {
      this.isLoading = true;
      this.alertCancelShowing = false;

      //const reason = reasonKey === 'other' ? null : reasonKey;
      if (!this.appointmentToCancel) {
        return;
      }

      if (reasonKey === 'change_parameters') {
        this.clickEdit(this.appointmentToCancel);
        return;
      }

      this.$analytics.track('Action: Cancel Appointment', {
        key: this.appointmentToCancel.key,
        reason: reasonKey,
      });

      const self = this;
      this.$graphql.cancelAppointment(
        this.appointmentToCancel,
        reasonKey,
        function () {
          if (self.isDestroyed) {
            return;
          }
          self.isLoading = false;
          self.$analytics.track('Response: Appointment Canceled', {
            key: self.appointmentToCancel.key,
            reason: reasonKey,
          });

          const index = self.appointmentsList.findIndex(a => a.key === self.appointmentToCancel.key);

          self.appointmentToCancel.isLocked = true;
          self.appointmentToCancel.state = 'canceled';
          self.appointmentToCancel.cancelReason = reasonKey;
          self.appointmentsList.splice(index, 1, self.appointmentToCancel);
          self.appointmentToCancel = null;

          self.popupSuccessShowing = false;
          self.popupMessage = self.$t('popover.cancel_success');
          self.popupSuccessShowing = true;
          //self.checkState();
        },
        function (error) {
          if (self.isDestroyed) {
            return;
          }
          self.isLoading = false;
          if (error.getCode() === Error.appointmentsCancelCode) {
            self.showError(self.$t('errors.generic_title'), self.$t('errors.appointment_cancel_time_exceed'));
          } else {
            self.showError(self.$t('errors.generic_title'), self.$t('errors.generic_message'));
          }
        },
      );
      /*
      this.state = 'canceled';
      this.checkState();*/
    },

    async requestPolicies(appointmentKeys) {
      const self = this;
      return new Promise(function (resolve) {
        self.$graphql.getAppointmentPolicies(
          appointmentKeys,
          function (policies) {
            resolve(policies);
          },
          function () {
            resolve(appointmentKeys);
          },
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/*
@keyframes fadeIt {
  0%   { background-color: $theme-highlight-color; }
  100% { background-color: white; }
}
*/
.new-booking-items {
  //height: 100%;
  background: $theme-3-bg;
  //padding-bottom: 100px;
  min-height: 100vh;

  &__fixed_top {
    background: white;
  }

  @media screen and (min-width: $min-screen-width) {
    min-height: initial;
  }

  &__footer {
    background: white;
    border-top: 1px solid $theme-list-divider-color;
    padding: $padding-section;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    &--shadow {
      box-shadow: $box-shadow-top;
    }

    &--sticky {
      position: sticky;
    }

    &__confirm_button {
      max-width: 480px;

      &--left {
        margin-right: 6px;
      }

      &--right {
        margin-left: 6px;
      }
    }

    &__split {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      max-width: 600px;
    }
  }
}
</style>
