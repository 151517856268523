export default {
  setToken: (context, value) => {
    context.commit('SET_TOKEN', value);
  },
  setMessenger: (context, value) => {
    context.commit('SET_MESSENGER', value);
  },
  setMessengerFrame: (context, value) => {
    context.commit('SET_MESSENGER_FRAME', value);
  },
  setSourceDescription: (context, value) => {
    context.commit('SET_SOURCE_DESCRIPTION', value);
  },
  setGoogle: (context, value) => {
    context.commit('SET_GOOGLE', value);
  },
  setGeneral: (context, value) => {
    context.commit('SET_GENERAL', value);
  },

  setBusinessClosedWeekdays: (context, value) => {
    context.commit('SET_BUSINESS_CLOSED_WEKKDAYS', value);
  },

  setAuth: (context, auth) => {
    context.commit('SET_AUTH', auth);
  },
  updateAuth: (context, auth) => {
    context.commit('UPDATE_AUTH', auth);
  },

  setCanResumeVerification: (context, value) => {
    context.commit('SET_CAN_RESUME_VERIFICATION', value);
  },

  setClientSuggestions: (context, value) => {
    context.commit('SET_CLIENT_SUGGESTIONS', value);
  },

  setServiceSearch: (context, text) => {
    context.commit('SET_SERVICE_SEARCH', text);
  },
  setSelectedCategory: (context, category) => {
    context.commit('SET_SELECTED_CATEGORY', category);
  },

  resetAppointment: context => {
    context.commit('RESET_APPOINTMENT');
  },

  setAppointmentService: (context, service) => {
    context.commit('SET_APPOINTMENT_SERVICE', service);
  },

  setAppointmentProfessional: (context, professional) => {
    context.commit('SET_APPOINTMENT_PROFESSIONAL', professional);
  },

  setAppointmentParams: (context, params) => {
    context.commit('SET_APPOINTMENT_PARAMS', params);
  },

  setAppointmentNote: (context, note) => {
    context.commit('SET_APPOINTMENT_NOTE', note);
  },

  setEditAppointment: (context, appointment) => {
    context.commit('SET_EDIT_APPOINTMENT', appointment);
  },
  setAppointment: (context, appointment) => {
    context.commit('SET_APPOINTMENT', appointment);
  },

  setAppointments: (context, appointments) => {
    context.commit('SET_APPOINTMENTS', appointments);
  },

  setDiscounts: (context, discounts) => {
    context.commit('SET_DISCOUNTS', discounts);
  },

  setProfessionals: (context, professionals) => {
    context.commit('SET_PROFESSIONALS', professionals);
  },

  setCustomer: (context, customer) => {
    context.commit('SET_CUSTOMER', customer);
  },
};
