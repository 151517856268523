
let deferredPrompt;

export default {

  initServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
        .then(function () {
          //console.log('Service Worker Registered');
        })
        .catch(error => console.log('ServiceWorker Warning: ', error));
    }
  },

  init(isStandalone) {
    if (isStandalone === false) {
      window.addEventListener('beforeinstallprompt', e => {
        // Stash the event so it can be triggered later.
        //console.log('beforeinstallprompt');
        deferredPrompt = e;
      });

      window.addEventListener('appinstalled', () => {
        //console.log('a2hs installed');
      });
    }
  },

  showPrompt() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice
        .then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
    }
  },

};
