import mixpanel from 'mixpanel-browser';

let isActive = false;

export default {

  init() {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
    //{ disable_persistence: true }
    isActive = true;
    return this;
  },

  optOut() {
    mixpanel.opt_out_tracking();
  },

  optIn() {
    mixpanel.opt_in_tracking();
  },

  /**
   * Identify a user with a unique ID instead of a Mixpanel randomly generated distinct_id.
   * If the method is never called, then unique visitors will be identified by a UUID generated
   * the first time they visit the site.
   * @param {*} userKey
   */
  identify(userKey) {
    if (isActive === true) {
      mixpanel.identify(userKey);
    }
  },

  /**
   * Create an alias, which Mixpanel will use to link two distinct_ids
   * going forward (not retroactively). Multiple aliases can map to the
   * same original ID, but not vice-versa. Aliases can also be chained
   * @param {*} userKey
   */
  alias(userKey) {
    if (isActive === true) {
      mixpanel.alias(userKey);
    }
  },

  /**
   * Register a set of super properties, which are included with all events.
   * This will overwrite previous super property values.
   * @param {*} properties
   */
  register(properties) {
    if (isActive === true) {
      mixpanel.register(properties);
    }
  },

  reset() {
    if (isActive === true) {
      mixpanel.reset();
    }
  },

  /**
   * Track an event. This is the most important and frequently used Mixpanel function.
   * @param {*} eventName
   * @param {*} properties
   */
  track(eventName, properties) {
    if (isActive === true) {
      if (!properties) {
        properties = {};
      }
      //const timestamp = new Date().getTime(); // milliseconds
      const timestamp = Math.floor(Date.now() / 1000); // seconds
      properties.time = timestamp;
      mixpanel.track(eventName, properties);
    }
  },

  /**
   * Set properties on a user record.
   * @param {*} key
   * @param {*} value
   */
  setPeopleParam(key, value) {
    if (isActive === true) {
      mixpanel.people.set(key, value);
    }
  },


};
