<template>
  <div
    :class="containerClassNames"
    class="container"
    @click="handleClick">
    <span
      :class="radioButtonClassNames"
      class="container__radio">
      <IlmIcon
        :icon="radioButtonIcon"
        :size="size" />
    </span>
    <h2
      :class="textClassNames"
      class="container__text">
      {{ text }}
    </h2>
  </div>
</template>


<script>
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';

export default {
  name: 'IlmButtonRadio',

  // Components ********************************
  components: {
    IlmIcon,
  },

  // Properties ********************************
  props: {
    name: String,
    text: String,
    isSelected: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xl',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: Function,
  },

  // Data ********************************
  data() {
    return {
      mutableDisabled: this.disabled,
    };
  },


  // Computed ********************************
  computed: {
    isDisabled() { return this.mutableDisabled; },
    containerClassNames() {
      return {
        'container--disabled': this.isDisabled,
        'container--selected': this.isSelected,
      };
    },
    radioButtonClassNames() {
      /*return {
        'container__radio--disabled': this.isDisabled,
        'container__radio--selected': this.isSelected,
      };*/
      return {};
    },
    textClassNames() {
      /*return {
        'container__text--disabled': this.isDisabled,
        'container__text--selected': this.isSelected,
      };*/
      return {};
    },
    radioButtonIcon() {
      if (this.isSelected === true) {
        return 'radio_button_checked';
      }
      return 'radio_button_unchecked';
    },
  },

  // Methods ********************************
  methods: {
    handleClick() {
      if (this.isDisabled || !this.onClick) return;
      //this.isSelected = !this.isSelected;
      this.onClick(this.name);
    },
    setIsDisabled(disable) {
      this.mutableDisabled = disable;
    },
  },
};
</script>


<style lang="scss">

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  &:hover/*, &:hover &__text*/ {
    //color: $theme-ilm-selection;
    cursor: pointer;
    touch-action: auto;
    -webkit-tap-highlight-color: $list-pressed-row-background-alpha;
    background: $theme-highlight-color;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__radio {
    color: $theme-2-color;
    padding-top: 4px;
    flex: 0 0 auto;
  }

  &__text {
    color: $theme-2-color;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    margin: 4px 0 4px 10px;

    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
  }


  &--selected &__radio {
    color: $theme-ilm-color;
  }
  &--selected &__text {
    color: black;
  }
}

</style>
