<template>
  <div id="root">
    <div
      id="root-cover"
      :style="coverContainerStyle">
      <!--IlmButton
        v-if="!$root.isProduction"
        :on-click="handleUserClick"
        label="Logout / Apagar cookie"
        theme="simple"
        class="user-button" /-->
      <IlmButtonIcon
        v-if="showAccount"
        :on-click="onUserAccountClick"
        :icon="userAccountIcon"
        prevent-default
        class="user-account-btn" />
    </div>
    <div id="root-frame">
      <h3
        v-if="showUser"
        id="root-frame__subtitle">
        {{ getUserName }}
      </h3>
      <h1 id="root-frame__title">
        {{ businessName }}
      </h1>
      <!--h3 id="root-frame__subtitle">{{ businessCategory }}</h3-->
      <!--div
        v-if="!$root.isProduction && $route.name === 'business-details'"
        class="mobile-user-button-container">
        <IlmButton
          :on-click="handleUserClick"
          label="Logout / Apagar cookie"
          theme="simple"
          class="user-button" />
      </div-->
      <div id="root-frame__scroller">
        <router-view />
      </div>
      <!--div
        v-if="showCookieWarning"
        id="cookie-container">
        <p
          id="cookie-container__text"
          v-html="cookieMessage" />
        <IlmButton
          id="cookie-container__btn"
          t-key="modals.buttons.ok"
          prevent-default
          theme="success" />
      </div-->
      <!--CookieLaw
        theme="ilm-cookie-theme"
        @accept="cookieAccepted()">
        <div
          slot-scope="props"
          class="Cookie__inner">
          <p
            slot="message"
            v-html="cookieMessage" />
          <IlmButton
            :on-click="props.accept"
            class="Cookie__button"
            t-key="modals.buttons.ok"
            prevent-default
            theme="success"/>
        </div>
      </CookieLaw-->
      <div
        v-if="!$root.loading"
        id="root-frame__footer">
        <IlmFooter />
        <!--img
          class="footer_logo"
          src="/logo.png" -->
        <!--p>
          <a
            href="https://ilove.me/terms.html"
            target="_blank"
            class="footer_link">Termos e condições</a> ∙
          <a
            href="https://ilove.me/privacy-policy.html"
            target="_blank"
            class="footer_link">Política de privacidade</a>
          <br>
          Powered by ilove.me.
        </p-->
      </div>
    </div>
  </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'; /* https://www.npmjs.com/package/libphonenumber-js */
import IlmFooter from '@/components/IlmFooter.vue';
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';
import placeholderImage from '@/assets/images/placeholder-grey.png';


export default {

  components: {
    IlmFooter,
    IlmButtonIcon,
  },

  data() {
    return {
      showAccount: false,
      showCookieWarning: false,
    };
  },

  computed: {
    showUser() {
      const auth = this.$store.getters.getAuth;
      return auth && auth.isAuthenticated() && (auth.client.name || auth.client.phoneNumber);
    },
    userAccountIcon() {
      return 'account_circle';
    },
    getUserName() {
      const auth = this.$store.getters.getAuth;
      if (auth) {
        if (auth.client.name) {
          return this.$t('headers.client_greeting', { username: auth.client.name });
        }
        if (auth.client.phoneNumber && auth.client.phoneCountry) {
          const formatter = new AsYouType();
          return formatter.input(`+${auth.client.phoneCountry}${auth.client.phoneNumber}`);
        }
        if (auth.client.phoneNumber) {
          return auth.client.phoneNumber;
        }
      }
      return '';
    },
    businessName() {
      const auth = this.$store.getters.getAuth;
      if (auth && auth.business.name) {
        return auth.business.name;
      }
      return '';
    },
    businessCategory() {
      const auth = this.$store.getters.getAuth;
      if (auth && auth.business.category) {
        return auth.business.category;
      }
      return '';
    },
    coverContainerStyle() {
      const img = this.businessPosterImage;
      if (img && img !== '') {
        return {
          'background-image': `url('${img}')`,
          'background-size': '100%',
          'background-repeat': 'no-repeat',
        };
      }
      return {};
    },
    businessPosterImage() {
      const auth = this.$store.getters.getAuth;
      /*
      let baseUrl = process.env.VUE_APP_ILM_HOST;
      if (process.env.NODE_ENV !== 'production') {
        baseUrl = 'http://booking.dev.ilove.me:8080/';
      }*/
      if (auth && auth.business.category) {
        /*let baseUrl = process.env.VUE_APP_ILM_HOST;
        if (process.env.NODE_ENV !== 'production') {
          baseUrl = 'http://booking.dev.ilove.me:8080/';
        }*/
        //return auth.getCoverImage(baseUrl);
        //return `${baseUrl}images/placeholder-grey.png`;
        //return '~@/assets/images/placeholder-grey.png';
        // if (auth.business.locale === 'pt-BR') {
        //   return placeholderImageBrazil;
        // }
        return placeholderImage;
      }
      //return '';
      //return `${baseUrl}images/placeholder-grey.png`;
      //return '~@/assets/images/placeholder-grey.png';
      return placeholderImage;
    },

  },

  watch: {
    $route() {
      this.setUserAccountVisibility();
      this.setCookieWarningVisibility();
    },
  },

  created() {
    this.setUserAccountVisibility();
    //this.setCookieWarningVisibility();
    /*
    gdprCookieNotice({
      timeout: 500, //Time until the cookie bar appears
      expiration: 365, //This is the default value, in days
      domain: '.agende.online',
      //If you run the same cookie notice on all subdomains,
      //define the main domain starting with a .
      implicit: false, //Accept cookies on page scroll automatically
      statement: 'https://google.com', //Link to your cookie statement page
      performance: ['user_token', 'user_token_stg'], //Cookies in the performance category.
      analytics: ['ga'], //Cookies in the analytics category.
      //marketing: ['SSID'], //Cookies in the marketing category.
    });*/
  },

  mounted() {
    /*
    const self = this;
    window.extAsyncInit = function () {
      // the Messenger Extensions JS SDK is done loading
      //console.log('using getUserId in', window.name);

      const isMessengerExtensionsSupported = window.MessengerExtensions.isInExtension();
      self.$store.dispatch('setMessengerFrame', isMessengerExtensionsSupported);

      //console.log('MessengerExtensions supported: ', isMessengerExtensionsSupported);
    };
    */
    /*
      window.MessengerExtensions.getUserID(function success(uids) {
        // User ID was successfully obtained.
        const psid = uids.psid;
        console.log(`your User Id Is ${psid}`);
        //document.getElementById('userId').innerText = `your User Id Is ${psid}`;
      }, function error(err, errorMessage) {
        // Error handling code
        console.log('some Error', err, errorMessage);
      });

      window.MessengerExtensions.getContext('192257194950997',
        function success(result) {
          //document.getElementById('context').innerText = `result ${result}`;
          console.log('success', result);
        },
        function error(result) {
          console.log('error', result);
        });*/
  },

  methods: {
    setUserAccountVisibility() {
      const auth = this.$store.getters.getAuth;
      if (auth
        && auth.isAuthenticated()
        && this.$constants.SHOW_USER_ACCOUNT
        && this.$router.currentRoute.meta.showAccount === true
        && auth.business.hasPaymentsActive()) {
        this.showAccount = true;
      } else {
        this.showAccount = false;
      }
    },
    setCookieWarningVisibility() {
      /*if (this.$router.currentRoute.name === this.$constants.ROUTE_BUSINESS_DETAILS
        || this.$root.isDesktop) {
        this.showCookieWarning = true;
      } else {
        this.showCookieWarning = false;
      }*/
      this.showCookieWarning = false;
    },
    cookieAccepted() {
      console.log('Cookies accepted');
    },
    handleUserClick() {
      this.$localStorage.setToken('');
      //window.location.reload(false); // @deprecated
      //window.location.reload();
      const currentHref = window.location.href;
      window.location.href = currentHref;
    },
    onUserAccountClick() {
      this.$router.push({ name: this.$constants.ROUTE_ACCOUNT }, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>

.user-button {
  font-size: 12px;
  color: #333;
}
.mobile-user-button-container {
  background: #333;
  text-align: center;
}
.mobile-user-button-container .user-button {
  color: white;
}

.Cookie--ilm-cookie-theme {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 9999;
  box-shadow: $box-shadow-top;
  padding: $padding-section;
  font-size: 14px;
  box-sizing: border-box;
  border-top: 1px solid $theme-border;

  @media screen and (min-width: $min-screen-width) {
    overflow: hidden;
    bottom: 10px;
    right: 10px;
    left: initial;
    width: 28%;
    max-width: 304px;
    border-radius: 6px;
    border: 1px solid $theme-border;
    box-shadow: $box-shadow-bottom;
  }

  & p {
    flex: 1 1 auto;
    margin: 0;
    line-height: 1.3;
    color: $theme-2-color;

    & ::v-deep a {
      color: $theme-helper-color;
      text-decoration: none;

      &:visited, &:active, &:focus {
        color: $theme-helper-color;
        text-decoration: none;
      }
      @media (hover) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.Cookie--ilm-cookie-theme .Cookie__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}
.Cookie--ilm-cookie-theme .Cookie__button {
  flex: 0 0 auto;
  margin-left: $padding-section;
}


#cookie-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 9999;
  box-shadow: $box-shadow-top;
  padding: $padding-section;
  font-size: 14px;
  box-sizing: border-box;
  border-top: 1px solid $theme-border;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text {
    flex: 1 1 auto;
    margin: 0;
    line-height: 1.3;
    color: $theme-2-color;

    & ::v-deep a {
      color: $theme-helper-color;
      text-decoration: none;

      &:visited, &:active, &:focus {
        color: $theme-helper-color;
        text-decoration: none;
      }
      @media (hover) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__btn {
    flex: 0 0 auto;
    margin-left: $padding-section;
  }

  @media screen and (min-width: $min-screen-width) {
    overflow: hidden;
    bottom: 10px;
    right: 10px;
    left: initial;
    width: 28%;
    max-width: 304px;
    border-radius: 6px;
    border: 1px solid $theme-border;
    box-shadow: $box-shadow-bottom;
  }
}

#root-frame {
  height: 100%;

  &__title {
    font-size: 36px;
    display: none;
    line-height: 1;
    color: white;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
  }
  &__subtitle {
    font-size: 18px;
    display: none;
    line-height: 1;
    color: white;
    margin: 0 0 5px 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
  }

  &__footer {
    display: none;
    text-align: center;
    padding: 20px 0 20px 0;
    //box-shadow: none !important;
    //min-height: initial !important;
    font-size: 12px;
    color: $theme-2-color;
  }

  &__scroller {
    //min-height: 100%;
    height: 100%;
  }
  &__scroller > div {
    min-height: 100%;
  }
}
.footer_logo {
  width: 100px;
  height: auto;
}
.footer_link {
  color: $theme-2-color;
}
#root-cover {
  text-align: right;
  display: none;
}
.user-account-btn {
  top: 20px;
  display: block;
  position: absolute;
  right: 20px;
  background: white;
  color: black !important;
  font-size: 24px;
  height: 40px;
  border: 1px solid $theme-list-divider-color;

  @media (hover) {
    &:hover {
      background: $theme-2-bg;
    }
  }
}

@media screen and (min-width: $min-screen-width) {
  .mobile-user-button-container {
    display: none;
  }
  #root {
    position: relative;
    min-height: 100%;
    background: #f5f5f5;
  }
  #root-frame {
    height: initial;
    position: relative;
    width: 560px;
    margin: 0 auto;
    //margin-top: $fixed-root-container-top;
    margin-top: 62px;

    &__title, &__subtitle, &__footer {
      display: block;
      text-align: center;
    }
    &__title {
      padding-bottom: 60px;
      text-align: center;
    }

    &__scroller {
      //overflow-y: auto;
      position: relative;
      height: 100%;
      border-radius: $fixed-root-border-radius;
      border-collapse: separate;
      box-shadow: $box-shadow;
    }
    &__scroller > div {
      min-height: 360px;
    }
  }
  /*
  #root-frame > div {
    border-radius: 8px;
    border-collapse: separate;
    box-shadow: $box-shadow;
    //min-height: 100%;
  }*/
  #root-cover {
    //z-index: -2;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 356px;
    background: #f5f5f5;
  }
}

</style>
