
<template>
  <div class="section-map">
    <div v-if="business && businessAddress !== ''">
      <div
        class="section-map__header-container">
        <ListHeader
          :label="title" />
      </div>
      <p
        class="section-map__address"
        v-html="businessAddress" />
    </div>

    <div
      v-if="business && showMap"
      class="section-map__map-container">
      <div
        class="section-map__map-container__clickable"
        @click="handleMapClick">
        <img
          :src="googleMapImage"
          class="section-map__map-container__clickable__img">
        <div
          class="section-map__map-container__clickable__button">
          {{ $t('button.business_details_open_map') }}
          <IlmIcon
            icon="open_in_new" />
        </div>
        <div class="section-map__map-container__clickable__overlay" />
      </div>
    </div>

    <AlertList
      v-if="mapsAlertShowing"
      :items="mapsAlertItems"
      :btn-cancel="$t('alerts.alert_cancel_appointment_title')"
      @closed="onAlertClosed"
      @selected="onMapsAlertSelected" />
  </div>
</template>


<script>
import ListHeader from '@/components/Lists/Header.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import AlertList from '@/components/Alerts/AlertItemList.vue';
import utils from '@/utils/utils';

export default {
  name: 'SectionMap',

  // Components ********************************
  components: {
    ListHeader,
    IlmIcon,
    AlertList,
  },

  // Properties ********************************
  props: {
    title: String,
  },

  // Data ********************************
  data() {
    return {
      business: null,
      mapsAlertShowing: false,
    };
  },


  // Computed ********************************
  computed: {
    businessAddress() {
      let postalCode = null;
      if (this.business.addressCountry && this.business.addressPostal) {
        const country = this.business.addressCountry.toLowerCase();
        if (country === 'br') {
          postalCode = utils.formatCep(this.business.addressPostal);
        } else if (country === 'pt') {
          postalCode = utils.formatPTPostalCode(this.business.addressPostal);
        } else {
          postalCode = this.business.addressPostal;
        }
      } else {
        postalCode = this.business.addressPostal;
      }

      let address = '';
      if (this.business.name) {
        address += `<b>${this.business.name}</b><br>`;
      }
      if (this.business.addressStreet) {
        address += `${this.business.addressStreet}<br>`;
      }
      if (this.business.addressNeighbourhood) {
        address += `${this.business.addressNeighbourhood}<br>`;
      }
      if (this.business.addressCity && this.business.addressState && postalCode) {
        address += `${this.business.addressCity} - ${this.business.addressState}, ${postalCode}`;
      } else if (this.business.addressState && postalCode) {
        address += `${this.business.addressState}, ${postalCode}`;
      } else if (postalCode) {
        address += `${postalCode}`;
      }
      return address;
    },
    showMap() {
      return this.business
        && this.business.addressLatitude
        && this.business.addressLatitude !== ''
        && this.business.addressLatitude !== 0.0
        && this.business.addressLongitude
        && this.business.addressLongitude !== ''
        && this.business.addressLongitude !== 0.0;
    },
    googleMapImage() {
      //if (this.business.addressLatitude && this.business.addressLongitude) {
      const lat = this.business.addressLatitude;
      const lon = this.business.addressLongitude;
      //const lat = '-23.575599';
      //const lon = '-46.576161';
      const zoom = 16;
      const size = '600x260';
      const maptype = 'roadmap';
      const feature = 'feature:poi|element:labels|visibility:off';
      /*let baseUrl = process.env.PUBLIC_PATH;
      if (process.env.NODE_ENV !== 'production') {
        baseUrl = 'http://booking.dev.ilove.me:8080/';
      }
      const markerIcon = `${baseUrl}favicon.ico`;*/
      const apiKey = 'AIzaSyBuIUssumA0tXI2KcF5i7Ohuu8f8hoIylw';//process.env.VUE_APP_MAPS_API_KEY;
      return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lon}&zoom=${zoom}&size=${size}&maptype=${maptype}&style=${feature}&markers=${lat},${lon}&key=${apiKey}`;
      //}
      //return '';
    },
    mapsAlertItems() {
      return this.getMapOptions();
    },
  },

  // Created ********************************
  created() {
    const auth = this.$store.getters.getAuth;
    if (auth) {
      this.business = auth.business;
    }
  },

  // Created ********************************
  mounted() {
  },

  methods: {
    handleMapClick() {
      const options = this.getMapOptions();
      if (options.length === 1) {
        this.onMapsAlertSelected(0);
      } else {
        this.mapsAlertShowing = true;
      }
    },
    onAlertClosed() {
      this.mapsAlertShowing = false;
    },
    getMapOptions() {
      const options = [];
      if (utils.isIos()) {
        options.push(this.$t('alerts.alert_maps_apple'));
      }
      options.push(this.$t('alerts.alert_maps_google'));
      if (utils.isMobile()) {
        options.push(this.$t('alerts.alert_maps_waze'));
      }
      return options;
    },
    onMapsAlertSelected(index) {
      this.mapsAlertShowing = false;
      const lat = this.business.addressLatitude;
      const lon = this.business.addressLongitude;
      //const lat = '-23.575599';
      //const lon = '-46.576161';
      const selectedOption = this.getMapOptions()[index];

      let url = `http://maps.google.com/?daddr=${lat},${lon}`;
      if (selectedOption === this.$t('alerts.alert_maps_apple')) {
        url = `http://maps.apple.com/?daddr=${lat},${lon}`;
      } else if (selectedOption === this.$t('alerts.alert_maps_waze')) {
        url = `https://waze.com/ul?ll=${lat},${lon}`;
      }
      window.open(url);
    },
  },

};
</script>


<style lang="scss" scoped>

.section-map {
  background: white;
  margin-top: 20px;
  border-top: 1px solid $theme-list-divider-color;
  border-bottom: 1px solid $theme-list-divider-color;

  &__address {
    color: black;
    padding: 10px $padding-section;
    margin: 0;
  }

  &__map-container {
    position: relative;
    padding: $padding-section;

    &__clickable {
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      touch-action: auto;

      &__img {
        box-sizing: border-box;
        display: block;
        width: 100%;
        border-radius: 10px;
        border: 1px solid $theme-list-divider-color;
      }

      &__overlay {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 10px;
        background: black;
        opacity: 0.0;
        transition: all 0.2s ease-in-out 0s;
      }

      @media (hover) {
        &:hover &__overlay {
          opacity: 0.3;
        }
      }

      &__button {
        box-sizing: border-box;
        position: absolute;
        background: white;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-top: 1px solid $theme-list-divider-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 10px 6px;
        color: $theme-button-simple-color;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  &__header-container {
    background: white;
    padding: $padding-section;
    z-index: 1;
  }
}


</style>
