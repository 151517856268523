<template>
  <div class="payment-section">
    <h1
      v-if="showTitle"
      class="payment-section__title">
      {{ $t('headers.payment_details_title_2') }}
    </h1>
    <div
      v-if="showTitle"
      class="payment-section__text">
      {{ $t('text.payment_details_text_2') }}
    </div>
    <div class="payment-section__fields">
      <div class="payment-section__fields__divide">
        <IlmButtonSelect
          :on-click="handleCountryClick"
          :label="selectedCountry ? selectedCountry.name : ''"
          class="payment-section__fields__divide__select
          payment-section__fields__divide__left
          payment-section__fields__divide__50" />
        <SectionInput
          ref="input_cep"
          :debounce="500"
          :value="customer.address.cep"
          :placeholder="postalCodePlaceholder"
          :on-change="onCepChange"
          :focus-on-start="false"
          :on-input-enter="onInputEnter"
          :validate-field="validateCepField"
          :disabled="isLoadingCep || isLoading"
          :format-input="formatCepField"
          type="tel"
          class="payment-section__fields__divide__input
          payment-section__fields__divide__right
          payment-section__fields__divide__50" />
      </div>

      <SectionInput
        ref="input_street"
        :value="customer.address.street"
        :placeholder="$t('placeholders.payment_user_street')"
        :on-change="onStreetChange"
        :disabled="isLoading"
        :focus-on-start="false"
        :on-input-enter="onInputEnter"
        class="payment-section__fields__input" />

      <div class="payment-section__fields__divide">
        <SectionInput
          ref="input_door"
          :value="customer.address.door"
          :placeholder="$t('placeholders.payment_user_door')"
          :on-change="onDoorChange"
          :disabled="isLoading"
          :focus-on-start="false"
          :on-input-enter="onInputEnter"
          class="payment-section__fields__divide__input
          payment-section__fields__divide__left
          payment-section__fields__divide__fixed_width" />
        <SectionInput
          ref="input_door_extra"
          :value="customer.address.doorExtra"
          :placeholder="complementaryPlaceholder"
          :on-change="onDoorExtraChange"
          :disabled="isLoading"
          :focus-on-start="false"
          :on-input-enter="onInputEnter"
          class="payment-section__fields__divide__input
          payment-section__fields__divide__right" />
      </div>

      <SectionInput
        ref="input_neighbourhood"
        :value="customer.address.neighbourhood"
        :placeholder="$t('placeholders.payment_user_neighbourhood')"
        :on-change="onNeighbourhoodChange"
        :disabled="isLoading"
        :focus-on-start="false"
        :on-input-enter="onInputEnter"
        class="payment-section__fields__input" />

      <div class="payment-section__fields__divide">
        <SectionInput
          ref="input_city"
          :value="customer.address.city"
          :placeholder="$t('placeholders.payment_user_city')"
          :on-change="onCityChange"
          :disabled="isLoading"
          :focus-on-start="false"
          :on-input-enter="onInputEnter"
          class="payment-section__fields__divide__input
          payment-section__fields__divide__left" />
        <SectionInput
          ref="input_city_extra"
          :value="customer.address.state"
          :placeholder="statePlaceholder"
          :on-change="onCityExtraChange"
          :disabled="isLoading"
          :focus-on-start="false"
          :on-input-enter="onInputEnter"
          class="payment-section__fields__divide__input
          payment-section__fields__divide__right
          payment-section__fields__divide__fixed_width" />
      </div>
    </div>

    <div class="payment-section__footer">
      <p
        class="payment-section__footer__text"
        v-html="$t('notes.payment_address')" />
    </div>

    <ModalCountryCode
      v-if="modalCountriesShowing"
      ref="country_code_modal"
      :selected-country="selectedCountry"
      :countries="countries"
      @selected="onCountryCodeSelected"
      @closed="onModalClosed" />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import axios from 'axios';
import IlmButtonSelect from '@/components/Buttons/IlmButtonSelect.vue';
import SectionInput from '@/components/Inputs/SectionInput.vue';
import ModalCountryCode from '@/components/Modals/ModalCountryCode.vue';

import utils from '@/utils/utils';

import CountryCodes from '../../json/country_codes.json';
import { stripDiacritics } from '../../utils/matcher.js';
//import countryList from '../../locales/countries_pt_br.json';

async function loadCountries(filename) {
  //return import(`../../locales/${filename}.json`);
  //return import(/* webpackMode: "eager" */ path);
  const path = `/json/${filename}.json`;
  const httpAxios = axios.create({
    baseURL: '/',
  });
  const response = await httpAxios.get(path);
  return response;
}

export default {
  name: 'PaymentSection2',

  components: {
    IlmButtonSelect,
    SectionInput,
    ModalCountryCode,
  },

  // Properties ********************************
  props: {
    editState: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customer: Object,
    onDataChanged: Function,
    onProceed: Function,
  },

  // Data ********************************
  data() {
    return {
      countries: null,
      countryCodes: CountryCodes,
      modalCountriesShowing: false,
      selectedCountry: null,

      isLoadingCep: false,

      originalCountry: null,
      originalCep: null,
      originalStreet: null,
      originalDoor: null,
      originalDoorExtra: null,
      originalNeighbourhood: null,
      originalCity: null,
      originalState: null,
    };
  },

  // Computed ********************************
  computed: {
    postalCodePlaceholder() {
      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        return this.$t('placeholders.payment_user_cep');
      }
      return this.$t('placeholders.payment_user_postal_code');
    },
    complementaryPlaceholder() {
      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        return this.$t('placeholders.payment_user_door_complementary');
      }
      return this.$t('placeholders.payment_user_door_extra');
    },
    statePlaceholder() {
      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        return this.$t('placeholders.payment_user_city_extra');
      }
      return this.$t('placeholders.payment_user_city_state');
    },
  },

  created() {
    if (this.customer && this.editState === true) {
      this.originalCountry = this.customer.address.country;
      this.originalCep = this.customer.address.cep;
      this.originalStreet = this.customer.address.street;
      this.originalDoor = this.customer.address.door;
      this.originalDoorExtra = this.customer.address.doorExtra;
      this.originalNeighbourhood = this.customer.address.neighbourhood;
      this.originalCity = this.customer.address.city;
      this.originalState = this.customer.address.state;
    }
    this.loadCountryList();
  },

  // Mounted ********************************
  mounted() {
  },

  // Methods ********************************
  methods: {
    loadCountryList() {
      const self = this;

      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }

      // load countries from file
      //import(`../../locales/${this.$t('file.countries')}.json`).then(function (countryList) {
      loadCountries(this.$t('file.countries')).then(function (response) {
        self.countries = [];
        const countryList = response.data;

        self.countryCodes.forEach(function (element) {
          const country = countryList[`countries.${element.iso}`];
          if (country) {
            const obj = {
              id: element.iso,
              iso: element.iso,
              code: element.phone,
              name: country,
            };
            self.countries.push(obj);

            const selected = self.customer.address.country;
            if (selected && selected === obj.iso) {
              self.selectedCountry = obj;
            } else if (obj.iso === 'br' && locale === 'pt-BR') {
              self.selectedCountry = obj;
            } else if (obj.iso === 'pt' && locale === 'pt-PT') {
              self.selectedCountry = obj;
            }
          }
        });
        if (self.selectedCountry) {
          self.customer.address.country = self.selectedCountry.iso;
          self.originalCountry = self.customer.address.country;
        }

        // sort country list
        self.countries = self.countries.sort(function compare(a, b) {
          const first = stripDiacritics(a.name);
          const second = stripDiacritics(b.name);
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
        self.checkDataChanged();
        if (self.customer.address.cep) {
          const cepField = self.$refs.input_cep;
          if (cepField) {
            cepField.setText(self.customer.address.cep);
            self.customer.address.cep = self.formatCepField(self.customer.address.cep);
          }
        }
      });
    },
    isFormComplete() {
      if (!this.selectedCountry) return false;
      if (this.validateCepField() === false) return false;
      if (!this.customer.address.street || this.customer.address.street.length === 0) return false;
      //if (!this.customer.address.door || this.customer.address.door.length === 0) return false;
      //if (!this.doorExtra || this.doorExtra.length === 0) return false;
      if (!this.customer.address.neighbourhood
        || this.customer.address.neighbourhood.length === 0) return false;
      if (!this.customer.address.city || this.customer.address.city.length === 0) return false;
      if (!this.customer.address.state
        || this.customer.address.state.length === 0) return false;

      if (this.editState === true && this.originalDataChanged() === false) {
        return false;
      }
      return true;
    },
    originalDataChanged() {
      if (this.originalCountry === this.customer.address.country
        && this.formatCepField(this.originalCep) === this.formatCepField(this.customer.address.cep)
        && this.originalStreet === this.customer.address.street
        && this.originalDoor === this.customer.address.door
        && this.originalDoorExtra === this.customer.address.doorExtra
        && this.originalNeighbourhood === this.customer.address.neighbourhood
        && this.originalCity === this.customer.address.city
        && this.originalState === this.customer.address.state) {
        return false;
      }
      return true;
    },
    handleCountryClick() {
      this.modalCountriesShowing = true;
    },
    onModalClosed() {
      this.modalCountriesShowing = false;
    },
    onCountryCodeSelected(iso) {
      this.onModalClosed();

      this.selectedCountry = this.countries.filter(function (item) {
        return item.iso === iso;
      })[0];

      this.customer.address.country = this.selectedCountry.iso;
      this.checkDataChanged();
    },

    checkDataChanged() {
      if (this.onDataChanged) {
        this.onDataChanged(this.isFormComplete());
      }
    },

    onCepChange(text) {
      this.customer.address.cep = text;
      this.checkDataChanged();

      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        this.requestCepAddress(text);
      }
    },
    onStreetChange(text) {
      this.customer.address.street = text;
      this.checkDataChanged();
    },
    onDoorChange(text) {
      this.customer.address.door = text;
      this.checkDataChanged();
    },
    onDoorExtraChange(text) {
      this.customer.address.doorExtra = text;
      this.checkDataChanged();
    },
    onNeighbourhoodChange(text) {
      this.customer.address.neighbourhood = text;
      this.checkDataChanged();
    },
    onCityChange(text) {
      this.customer.address.city = text;
      this.checkDataChanged();
    },
    onCityExtraChange(text) {
      this.customer.address.state = text;
      this.checkDataChanged();
    },

    onInputEnter(el) {
      let input;
      if (el === this.$refs.input_cep) {
        input = this.$refs.input_street;
      } else if (el === this.$refs.input_street) {
        input = this.$refs.input_door;
      } else if (el === this.$refs.input_door) {
        input = this.$refs.input_door_extra;
      } else if (el === this.$refs.input_door_extra) {
        input = this.$refs.input_neighbourhood;
      } else if (el === this.$refs.input_neighbourhood) {
        input = this.$refs.input_city;
      } else if (el === this.$refs.input_city) {
        input = this.$refs.input_city_extra;
      } else if (el === this.$refs.input_city_extra && this.onProceed) {
        this.onProceed();
        return;
      }
      if (input) {
        input.setFocus();
      }
    },

    validateCepField() {
      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        if (this.customer.address.cep && utils.isCepValid(this.customer.address.cep)) {
          return true;
        }
        return false;
      }
      if (this.selectedCountry && this.selectedCountry.iso === 'pt') {
        if (this.customer.address.cep && utils.isPTPostalCodeValid(this.customer.address.cep)) {
          return true;
        }
        return false;
      }
      return true;
    },
    formatCepField(val) {
      if (this.selectedCountry && this.selectedCountry.iso === 'br') {
        return utils.formatCep(val);
      }
      if (this.selectedCountry && this.selectedCountry.iso === 'pt') {
        return utils.formatPTPostalCode(val);
      }
      return val;
    },

    requestCepAddress(text) {
      const simpleCep = utils.stripInvalidChars(text);
      if (simpleCep.length === 8) {
        this.isLoadingCep = true;
        const self = this;
        this.$graphql.searchCep(simpleCep, function (address) {
          self.customer.address.street = address.street;
          self.customer.address.door = address.door;
          self.customer.address.city = address.city;
          self.customer.address.state = address.state;
          self.customer.address.neighbourhood = address.neighbourhood;
          self.isLoadingCep = false;
          self.checkDataChanged();
        }, function () {
          self.isLoadingCep = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-section {
  padding: $padding-section 0;

  &__text {
    font-size: 14px;
    color: black;
    text-align: center;
    padding: 0 $padding-section;
    max-width: 380px;
    margin: 0 auto;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 8px $padding-section;
    margin: 0;
  }

  &__fields {
    margin-top: $padding-section;

    &__divide {
      margin-top: 12px;
      display: flex;
      justify-content: space-evenly;

      &__select {
        flex: 1 1 auto;
        cursor: pointer;
      }
      &__input {
        flex: 1 1 auto;
      }
      &__left {
        margin-right: 6px;
      }
      &__right {
        margin-left: 6px;
      }
      &__fixed_width {
        flex: 0 0 auto;
        max-width: 94px;
        min-width: 94px;
      }
      &__50 {
        box-sizing: border-box;
        width: 50%;
      }
    }

    &__input {
      margin-top: 12px;
    }
  }

  &__footer {
    margin: 20px 0;
    padding-top: 16px;
    border-top: 1px solid $theme-list-divider-color;

    &__text {
      font-size: 12px;
      line-height: 1.2;
      color: $theme-2-color;
      margin-top: 0;
    }
  }
}
</style>
