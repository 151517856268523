<template>
  <div class="section">
    <div class="section-interruption">
      <div class="section-interruption__timeline">
        <div
          v-if="interruptionDuration"
          class="section-interruption__timeline__spacer" />
      </div>
      <div class="section-interruption__card">
        <span
          v-if="interruptionDuration"
          class="section-interruption__card__interruption_duration">
          {{ $t('text.appointment_service_difference', { minutes: interruptionDuration }) }}
        </span>
        <!--span
          v-else-if="isIntersectedBefore"
          class="section-interruption__card__interruption_spacer" /-->
      </div>
    </div>
    <div class="section-container">
      <div
        :class="timelineClasses"
        class="section-container__timeline">
        <div class="section-container__timeline__professional">
          <div class="section-container__timeline__professional__background">
            <span
              v-if="appointment.professional"
              class="section-container__timeline__professional__background__initials">
              {{ professionalInitials }}
            </span>
            <IlmIcon
              v-else
              class="section-container__timeline__professional__background__icon"
              icon="person"
              size="lg" />
            <ImageFade
              v-if="professionalProfileImage"
              :src="professionalProfileImage"
              class="section-container__timeline__professional__background__img" />
          </div>
        </div>

        <div
          v-if="showSpacerAfter || showInterruption"
          class="section-container__timeline__spacer" />

        <div
          v-if="showInterruption"
          class="section-container__timeline__interruption">
          <div class="section-container__timeline__interruption__background">
            <IlmIcon
              class="section-container__timeline__interruption__background__icon"
              icon="hourglass_empty"
              size="lg" />
          </div>
        </div>
        <!--div
          v-if="showSpacerBefore"
          class="section-container__timeline__spacer" /-->
      </div>

      <div class="section-container__card">
        <!--span
          v-if="interruptionDuration"
          class="section-container__card__interruption_duration">
          {{ $t('text.appointment_service_difference', { minutes: interruptionDuration }) }}
        </span-->
        <div>
          <div
            :class="sectionCardClasses"
            class="section-card">
            <div
              :class="stateClasses"
              class="section-card__state">
              <span
                class="section-card__state__time"
                v-html="appointmentTime" />
              <span
                v-if="showState"
                class="section-card__state__state">
                {{ $t(`states.${state}`) }}
              </span>
            </div>
            <!--div
              v-else-if="confirmationMode"
              :class="stateClasses"
              class="section-card__state"
              v-html="appointmentTime" /-->

            <div class="section-card__service-container">
              <div
                :class="`section-card__service-container__service
                  section-card__service-container__service--${state}`">
                {{ serviceText }}
              </div>
            </div>
            <!--div
              v-if="discountBadge"
              class="section-card__discount">
              <IlmDiscountBadge
                :value="discountBadge"
                class="section-card__discount__badge" />
              {{ $t('section_list.appointment_discount') }}
            </div-->
            <div class="section-card__details-container">
              <div class="section-card__details-container__wrapper">
                <!--span
                  :class="`section-card__details-container__wrapper__label
                  section-card__details-container__wrapper__label--${state}`">
                  {{ $t('section_list.appointment_professional') }}
                </span-->
                <span
                  :class="`section-card__details-container__wrapper__value
                  section-card__details-container__wrapper__value--${state}`">
                  {{ professionalText }}
                </span>
              </div>
              <div class="section-card__details-container__wrapper">
                <span
                  v-if="showPriceLabel"
                  :class="`section-card__details-container__wrapper__label
                  section-card__details-container__wrapper__label--${state}`">
                  {{ priceLabel }}
                </span>
                <span
                  :class="`section-card__details-container__wrapper__value
                  section-card__details-container__wrapper__value--${state}`">
                  {{ priceText }}
                  <span
                    v-if="oldPriceText"
                    class="section-card__details-container__wrapper__value__strike">
                    {{ oldPriceText }}
                  </span>
                  <span
                    v-else
                    class="section-card__details-container__wrapper__value__strike" />
                  <IlmDiscountBadge
                    v-if="discountBadge"
                    :value="discountBadge"
                    class="section-card__discount__badge" />
                </span>
              </div>
              <div
                v-if="showNotes && hasNotes"
                class="section-card__details-container__wrapper">
                <span
                  :class="`section-card__details-container__wrapper__label
                  section-card__details-container__wrapper__label--${state}
                  section-card__details-container__wrapper__label--notes`">
                  {{ $t('section_list.appointment_client_note') }}
                  <IlmButton
                    v-if="showNotesEditButton"
                    :on-click="clickEditNote"
                    :is-disabled="isLoading"
                    t-key="button.edit"
                    prevent-default
                    theme="simple"
                    class="section-card__details-container__note_button section-card__details-container__note_button--edit section-card__details-container__wrapper__label__button" />
                </span>
                <span
                  :class="`section-card__details-container__wrapper__value
                  section-card__details-container__wrapper__value--italic
                  section-card__details-container__wrapper__value--${state}`">
                  {{ noteText }}
                </span>
              </div>
              <IlmButton
                v-if="showNotesAddButton"
                :on-click="clickEditNote"
                :is-disabled="isLoading"
                :label="addNotesButtonText"
                prevent-default
                theme="simple"
                class="section-card__details-container__note_button" />
              <div
                v-if="hasCancelReason"
                class="section-card__details-container__wrapper section-card__details-container__wrapper--cancel-reason">
                <span
                  :class="`section-card__details-container__wrapper__label
                  section-card__details-container__wrapper__label--cancel-reason
                  section-card__details-container__wrapper__label--${state}`">
                  {{ $t('section_list.appointment_cancel_reason') }}
                </span>
                <span
                  :class="`section-card__details-container__wrapper__value
                  section-card__details-container__wrapper__value--italic
                  section-card__details-container__wrapper__value--${state}`">
                  {{ cancelReasonText }}
                </span>
              </div>
              <div
                v-if="showOptions && (showCancel || showEdit)"
                class="section-card__details-container__options_container">
                <IlmButton
                  v-if="showEdit"
                  :on-click="clickEdit"
                  :is-disabled="isLoading"
                  t-key="button.edit_appointment"
                  theme="simple"
                  prevent-default
                  class="section-card__details-container__options_container__button section-card__details-container__options_container__button--edit" />
                <span class="section-card__details-container__options_container__spacer" />
                <IlmButton
                  v-if="showCancel"
                  :on-click="clickCancel"
                  :is-disabled="isLoading"
                  t-key="button.cancel"
                  prevent-default
                  theme="simple"
                  class="section-card__details-container__options_container__button section-card__details-container__options_container__button--cancel" />
              </div>
              <div
                v-else-if="isAppointmentAllowed && showOptions"
                class="section-card__details-container__options_container">
                <IlmButton
                  :is-disabled="isLoading"
                  :on-click="clickRepeat"
                  t-key="button.book_again"
                  prevent-default
                  theme="simple"
                  class="section-card__details-container__options_container__button section-card__details-container__options_container__button--edit" />
              </div>
            </div>
          </div>
        </div>
        <!--div
          v-if="showInterruption"
          class="section-container__card__end">
          {{ $t('text.appointment_service_end') }}
        </div-->
        <div
          v-if="showInterruption"
          class="section-container__card__end" />
      </div>
    </div>
  </div>
</template>

<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmIcon from '@/components/IlmIcon/IlmIcon.vue';
import ImageFade from '@/components/ImageFade.vue';
import IlmDiscountBadge from '@/components/IlmIcon/IlmDiscountBadge.vue';
import formatter from '@/utils/formatter.js';
import Utils from '@/utils/utils.js';

export default {
  name: 'SectionAppointmentCard',

  // Components ********************************
  components: {
    IlmButton,
    IlmIcon,
    ImageFade,
    IlmDiscountBadge,
  },

  // Properties ********************************
  props: {
    selectedKey: String,
    appointment: {
      type: Object,
      required: true,
    },
    appointmentPolicies: Object,
    confirmationMode: {
      type: Boolean,
      default: false,
    },
    showEndingTime: {
      type: Boolean,
      default: false,
    },
    showSpacerBefore: {
      type: Boolean,
      default: false,
    },
    showInterruption: {
      type: Boolean,
      default: false,
    },
    interruptionDuration: String,
    showSpacerAfter: {
      type: Boolean,
      default: true,
    },
    isIntersectedBefore: {
      type: Boolean,
      default: false,
    },
    showState: {
      type: Boolean,
      default: true,
    },
    discount: Object,
    business: {
      type: Object,
      required: true,
    },
    showNotes: {
      type: Boolean,
      default: true,
    },
    showAnimation: {
      type: Boolean,
      default: false,
    },
    scrollToSelected: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onEditNoteClick: Function,
    onCancelClick: Function,
    onRepeatClick: Function,
    onEditClick: Function,
    onRemoveClick: Function,
  },

  // Data ********************************
  data() {
    return {
      state: null,
      isLockedAppointment: true,
      isAppointmentAllowed: false,
    };
  },

  // Computed ********************************
  computed: {
    sectionCardClasses() {
      const classNames = {};
      if (this.state) {
        classNames[`section-card--${this.state}`] = true;
      }
      classNames['section-card--animated'] = this.showAnimation;
      classNames['section-scroll-to'] = this.scrollToSelected;
      classNames['section-card--spacer-after'] = this.showSpacerAfter || this.showInterruption;
      classNames['section-card--show-state'] = this.showState || this.confirmationMode;
      return classNames;
      /*return {
        'section-card--animated sectionScrollTo': this.showAnimation,
        'section-card--spacer-after': this.showSpacerAfter,
        'section-card--show-state': this.showState,
      };*/
    },
    hourStartClasses() {
      return {
        'section-container__hours__box--show-state': this.showState || this.confirmationMode,
      };
    },
    timelineClasses() {
      return {
        'section-container__timeline--spacer-before': this.showSpacerBefore && this.isIntersectedBefore === false,
        'section-container__timeline--spacer-after': this.showSpacerAfter,
        'section-container__timeline--hide-end': this.showEndingTime === false,
        'section-container__timeline--show-state': this.showState || this.confirmationMode,
      };
    },
    stateClasses() {
      const classNames = {};
      if (this.showState && this.state) {
        classNames[`section-card__state--${this.appointment.state}`] = true;
      } else if (this.confirmationMode) {
        classNames['section-card__state--time'] = true;
      }

      return classNames;
    },
    isFutureAppointment() {
      if (this.appointment) {
        const day = this.appointment.day;
        const time = this.appointment.time;

        const timezone = this.$dateUtils.getTimezone();
        const date = this.$moment.tz(`${day} ${time}`, timezone);
        const now = this.$moment.tz(new Date(), timezone);
        return date.isAfter(now);
      }
      return false;
    },
    showOptions() {
      //return this.appointmentPolicies !== null;
      return this.confirmationMode === false;
    },
    showCancel() {
      return this.isLockedAppointment === false && ['paid', 'pending'].includes(this.appointment.state) === false;
      //&& (this.appointmentPolicies && this.appointmentPolicies.cancel === true);
    },
    showEdit() {
      return this.isLockedAppointment === false && ['paid', 'pending'].includes(this.appointment.state) === false;
      //&& (this.appointmentPolicies && this.appointmentPolicies.update === true);
    },
    timeText() {
      if (this.appointment) {
        return this.appointment.time;
      }
      return '';
    },
    timeEndText() {
      if (this.appointment) {
        const day = this.appointment.day;
        const time = this.appointment.time;
        const duration = this.appointment.service.duration;
        const date = this.$moment(`${day} ${time}`).add(duration, 'minutes');
        return date.format('HH:mm');
      }
      return '';
    },
    appointmentTime() {
      return `<b>${this.timeText}</b> - ${this.timeEndText}`;
    },
    dateText() {
      if (this.appointment) {
        const day = this.appointment.day;
        const date = this.$moment(day);

        const dayStr = `${date.format('ddd')} ${date.format('D')}`;
        return this.$t('dates.normal_format', {
          day: dayStr,
          month: date.format('MMMM'),
        });
      }
      return '';
    },
    serviceText() {
      if (this.appointment) {
        return this.appointment.service.name;
      }
      return '';
    },
    discountBadge() {
      const discountValue = this.getDiscountValue();
      return discountValue && !this.isPaid && this.appointment.state !== 'canceled' ? this.$t('button.discount_label', { value: discountValue }) : null;
    },
    professionalText() {
      if (this.appointment) {
        if (this.appointment.professional) {
          return this.appointment.professional.name;
        }
        return this.$t('section_list.appointment_professional_summary_any_abbr');
      }
      return '';
    },
    professionalProfileImage() {
      if (this.appointment) {
        if (this.appointment.professional) {
          return this.appointment.professional.profileImageUrl;
        }
      }
      return null;
    },
    professionalInitials() {
      if (this.appointment) {
        if (this.appointment.professional) {
          return Utils.getNameInitials(this.appointment.professional.name);
        }
      }
      return '';
    },
    durationText() {
      if (this.appointment) {
        const duration = this.appointment.service.duration;
        return formatter.formatDuration(duration);
      }
      return '';
    },
    showPriceLabel() {
      const price = this.getPriceValue();
      return !(this.appointment && this.isPaid) && price && price > 0;
    },
    priceLabel() {
      if (this.appointment) {
        return this.isPaid ? this.$t('section_list.appointment_service_price') : this.$t('section_list.appointment_service_price_estimate');
      }
      return '';
    },
    isPaid() {
      return ['paid', 'pending'].includes(this.appointment.state);
    },
    priceText() {
      if (this.appointment.isPriceUnavailable === true || this.appointment.service.isPriceUnavailable === true) {
        return this.$t('section_list.service_price_under_evaluation');
      }
      const discountValue = this.getDiscountValue();
      const priceValue = this.getPriceValue();
      if (priceValue) {
        if (discountValue && !this.isPaid && priceValue > 0) {
          return formatter.formatPrice(priceValue * (100 - discountValue) * 0.01, this.businessLocale);
        }
        if (priceValue > 0 || this.isPaid) {
          return formatter.formatPrice(priceValue, this.businessLocale);
        }
      }
      return '';
    },
    oldPriceText() {
      const discountValue = this.getDiscountValue();
      const priceValue = this.getPriceValue();
      if (discountValue && priceValue && !this.isPaid && priceValue > 0) {
        return formatter.formatPrice(priceValue, this.businessLocale);
      }
      return null;
    },
    hasNotes() {
      if (this.appointment) {
        return this.showNotes === true && this.appointment.notes && this.appointment.notes.trim() !== '';
      }
      return false;
    },
    noteText() {
      return this.appointment.notes;
    },
    showNotesAddButton() {
      return this.isFutureAppointment && this.showNotes && this.appointment.state !== 'canceled' && (!this.appointment.notes || this.appointment.notes.trim() === '');
    },
    showNotesEditButton() {
      return this.isFutureAppointment && this.showNotes && this.appointment.state !== 'canceled' && this.appointment.notes && this.appointment.notes.trim() !== '';
    },
    addNotesButtonText() {
      /*return this.appointment.notes && this.appointment.notes.trim() !== ''
        ? this.$t('appointment_options.edit_note')
        : this.$t('appointment_options.add_note');
        */
      return this.$t('appointment_options.add_note');
    },
    hasCancelReason() {
      return this.appointment && this.appointment.state === 'canceled' && this.appointment.cancelReason && this.appointment.cancelReason !== 'other';
    },
    cancelReasonText() {
      if (this.appointment.cancelReason) {
        return this.$t(`cancel.${this.appointment.cancelReason}`);
      }
      return '';
    },
    businessLocale() {
      const auth = this.$store.getters.getAuth;
      let locale = 'pt-BR';
      if (auth && auth.business && auth.business.locale) {
        locale = auth.business.locale;
      }
      return locale;
    },
  },

  // Created ********************************
  created() {
    //this.state = this.appointment.getState();
    this.checkState();
  },

  updated() {
    this.checkState();
  },

  // Created ********************************
  mounted() {
    this.isAppointmentAllowed = this.business && this.business.hasBookingsActive && this.business.hasBookingsActive();
  },

  methods: {
    checkState() {
      this.state = this.appointment.getState();

      const day = this.appointment.day;
      const time = this.appointment.time;

      const timezone = this.$dateUtils.getTimezone();
      const date = this.$moment.tz(`${day} ${time}`, timezone);

      const now = this.$moment.tz(new Date(), timezone);
      this.isLockedAppointment = !date.isAfter(now) || this.appointment.state === 'canceled' || this.appointment.getIsLocked() === true;
    },
    getPriceValue() {
      if (this.appointment) {
        let price = this.appointment.service.value;
        if (price) {
          return price;
        }
        price = this.appointment.service.price;
        if (price) {
          return price;
        }
      }
      return null;
    },
    getDiscountValue() {
      if (this.appointment) {
        const day = this.appointment.day;
        const date = this.$moment(day);
        if (this.discount && date) {
          const weekday = date.isoWeekday();
          //const value = this.discount.getDayDiscount(weekday, this.appointment.service.id);
          const value = this.discount.getHourDiscount(weekday, this.appointment.time);
          return value;
        }
      }
      return null;
    },

    clickBusinessName() {
      if (this.onBusinessClick) {
        this.onBusinessClick();
      }
    },

    clickRemove() {
      if (this.onRemoveClick) {
        this.onRemoveClick(this.appointment);
      }
    },

    clickEditNote() {
      if (this.onEditNoteClick && this.isFutureAppointment) {
        this.onEditNoteClick(this.appointment);
      }
    },

    clickCancel() {
      if (this.onCancelClick) {
        this.onCancelClick(this.appointment);
      }
    },

    clickRepeat() {
      if (this.onRepeatClick) {
        this.onRepeatClick(this.appointment);
      }
    },

    clickEdit() {
      if (this.onEditClick) {
        this.onEditClick(this.appointment);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fadeIt {
  0% {
    background-color: $theme-highlight-color;
  }
  100% {
    background-color: white;
  }
}
@keyframes fadeArrow {
  0% {
    border-color: transparent $theme-highlight-color transparent transparent;
  }
  100% {
    border-color: transparent white transparent transparent;
  }
}

.section-interruption {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0 16px 0 10px;

  &__timeline {
    flex: 0 0 auto;
    width: 58px;
    margin: 0;
    margin-right: 6px;
    position: relative;

    &__spacer {
      width: 1px;
      background: $theme-border;
      position: absolute;
      left: 50%;
      top: 56px;
      bottom: -50px;
    }
  }
  &__card {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    &__interruption_duration {
      display: block;
      font-size: 16px;
      color: black;
      margin-left: 30px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    &__interruption_spacer {
      /*display: block;
      margin-top: 20px;*/
      flex: 1 1 auto;
      width: 1px;
      background: $theme-border;
    }
  }
}

.section-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0 16px 0 10px;

  &__timeline {
    position: relative;
    flex: 0 0 auto;
    width: 58px;
    margin: 0;
    margin-right: 6px;
    //background: $theme-border;
    //margin-top: 25px;
    //margin-bottom: 2px;
    //left: -27px;
    //top: 30px;
    //bottom: 0;
    //position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__professional {
      //position: sticky;
      flex: 0 0 auto;
      font-size: 18px;
      font-weight: bold;
      color: black;
      //background: $theme-3-bg;

      width: 54px;
      height: 54px;
      background: white;
      border-radius: 50%;
      border: 1px solid $theme-border;
      box-sizing: border-box;
      padding: 2px;

      &__background {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $theme-border;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          max-width: 100%;
          text-align: center;
          color: white;
          font-size: 24px;
        }

        &__img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        &__initials {
          max-width: 100%;
          text-align: center;
          color: white;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    &__spacer {
      flex: 1 1 auto;
      width: 1px;
      background: $theme-border;
      margin-bottom: -66px;
    }

    &__interruption {
      flex: 0 0 auto;
      font-size: 18px;
      font-weight: bold;
      color: black;

      width: 54px;
      height: 54px;
      background: white;
      border-radius: 50%;
      border: 1px solid $theme-border;
      box-sizing: border-box;
      padding: 2px;
      margin-bottom: -48px;

      &__background {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          color: $theme-border;
          font-size: 24px;
        }
      }
    }

    /*&__spacer {
      border-left: 2px solid $theme-border;
      position: absolute;
      top: 100%;
      height: 130px;
    }*/

    // BULLET START
    /*&:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid $theme-border;
      position: absolute;
      background: white;
      left: -7px;
      top: 0;
    }
    // BULLET END
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid $theme-border;
      position: absolute;
      background: $theme-3-bg;
      left: -7px;
      bottom: 0;
    }*/

    &--spacer-before {
      //top: 0;
      margin-top: 0;
      &:before {
        top: 25px;
      }
    }

    &--show-state {
      padding-top: 47px;
      /*&:before {
        top: 0;
      }*/
    }
    &--show-state.section-container__timeline--spacer-before {
      margin-top: 0;
      /*&:before {
        top: 66px;
      }*/
    }

    &--spacer-after {
      //bottom: -50px;
    }

    &--hide-end {
      &:after {
        display: none;
      }
    }
  }
  &__card {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
    margin-left: 10px;

    &__end {
      position: relative;
      font-size: 14px;
      color: $theme-2-color;
    }

    &__interruption_duration {
      display: block;
      font-size: 14px;
      color: $theme-2-color;
      margin-bottom: 20px;
    }
  }
}

.section-card {
  position: relative;
  //background: white;
  //margin-right: $padding-section;
  background-color: white;
  //padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 3px 8px 0px rgba(156, 156, 156, 0.5);

  margin-bottom: 20px;

  &--spacer-after {
    margin-bottom: 26px;
  }

  /* TRIANGLE SHADOW */
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    box-shadow: $box-shadow-bottom;
    box-sizing: border-box;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    top: 30px;
    left: -11px;
    z-index: -1;
  }
  /* TRIANGLE ARROW TOOLTIP */
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    box-sizing: border-box;
    top: 22px;
    left: -19px;
    z-index: 2;
  }

  &--animated {
    animation: fadeIt 4s ease-in-out;

    &:after {
      animation: fadeArrow 4s ease-in-out;
    }
  }

  &--show-state {
    /* TRIANGLE SHADOW */
    &:before {
      top: 72px;
    }
    /* TRIANGLE ARROW TOOLTIP */
    &:after {
      top: 64px;
    }
  }
}

.section-card {
  //background: white;
  //border-top: 1px solid $theme-list-divider-color;
  //border-bottom: 1px solid $theme-list-divider-color;
  padding: 3px;

  &__state {
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    padding: 6px 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $theme-state-booked;

    &--booked {
      background: $theme-state-booked;
    }
    &--confirmed {
      background: $theme-state-confirmed;
    }
    &--waiting {
      background: $theme-state-waiting;
    }
    &--started {
      background: $theme-state-started;
    }
    &--late {
      background: $theme-state-late;
    }
    &--paid {
      background: $theme-state-paid;
    }
    &--pending {
      background: $theme-state-paid;
    }
    &--canceled {
      background: $theme-state-canceled;
    }
    &--noshow {
      background: $theme-state-noshow;
    }

    &--time {
      //background: #999999;
      text-align: left;
      //font-size: 20px;
      font-weight: normal;
    }

    &__state {
      flex: 1 1 auto;
      text-align: right;
      padding: 0;
      font-size: 12px;
    }

    &__time {
      flex: 0 0 auto;
      text-align: left;
      font-size: 20px;
      font-weight: normal;
      padding: 0;
    }
  }

  &__service-container {
    display: flex;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 0;
    margin: 0 12px;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    font-family: $heading-font-family;
    font-size: 22px;
    font-weight: bold;
    color: black;
    line-height: 1.2em;

    &__service {
      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
      color: black;

      &--canceled {
        color: $theme-3-color;
        text-decoration: line-through;
      }
    }
  }

  &__discount {
    font-family: $heading-font-family;
    font-size: 16px;
    color: black;
    line-height: 1.2em;
    padding: 0 12px;
    margin-top: 10px;

    &__badge {
      margin-left: 6px;
    }
  }

  &__details-container {
    padding: 0 12px 6px 12px;
    font-family: $heading-font-family;
    font-size: 16px;
    line-height: 1.2em;

    &__note_button {
      font-size: 14px;
      padding-top: 6px;
      padding-left: 0;

      &--edit {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 10px;
      }
    }

    &__wrapper {
      overflow: hidden;
      width: 100%;
      padding: 2px 0;
      display: flex;
      flex-direction: column;

      &__label {
        color: $theme-2-color;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 4px;
        margin-top: 6px;
        //float: left;
        //width: 136px;

        &--canceled {
          color: $theme-3-color;
        }

        &--cancel-reason {
          color: $theme-error;
        }

        &--notes {
          display: flex;
          align-items: baseline;
        }

        &__button {
          text-align: right;
          flex: 1 1 auto;
        }
      }

      &--cancel-reason {
        margin-top: 16px;
      }

      &__value {
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        font-size: 16px;
        margin-bottom: 12px;

        &__value {
          flex: 1 1 auto;
        }

        &--canceled {
          color: $theme-3-color;
        }

        &__strike {
          text-decoration: line-through;
          color: $theme-3-color;
          margin-left: 6px;
          flex: 1 1 auto;
        }

        &--italic {
          font-style: italic;
        }

        &__button {
          padding-top: 0;
          padding-bottom: 0;
          flex: 0 0 auto;
          margin-right: -10px;
          padding-left: 10px;
          text-align: right;
          font-size: 14px;
          font-weight: normal;
          color: $theme-button-destructive-color;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    &__options_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid $theme-list-divider-color;
      padding-top: 2px;
      margin-top: 4px;

      &__spacer {
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
      }

      &__button {
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
        font-weight: normal;

        &--edit {
          flex: 0 0 auto;
          text-align: left;
          padding-left: 0;
        }
        &--cancel {
          flex: 0 0 auto;
          margin-right: -10px;
          padding-left: 0;
          text-align: right;
          font-weight: normal;
          color: $theme-button-destructive-color;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
