
<template>
  <div>
    <div
      ref="navbar_container"
      :class="navbarClasses"
      class="navbar-container">
      <div
        :class="titleClassNames"
        class="navbar-container__title-container">
        <transition name="fade">
          <h1
            v-if="title"
            class="navbar-container__title-container__title"
            v-html="titleText" />
        </transition>
        <transition name="fade">
          <h2
            v-if="subtitle"
            class="navbar-container__title-container__subtitle"
            v-html="subtitle" />
        </transition>
      </div>
      <IlmButtonIcon
        v-if="canShowBack"
        :class="backClasses"
        :on-click="handleBackClick"
        :disabled="isLoading"
        icon="arrow_back"
        prevent-default
        class="navbar-container__back" />
      <IlmButton
        v-if="showOk"
        ref="btn_ok"
        :is-working="isLoading"
        :on-click="handleOkClick"
        :disabled="disableBtnOk"
        :label="$t('modals.buttons.ok')"
        icon="arrow_forward"
        theme="success"
        class="navbar-container__ok" />
    </div>
  </div>
</template>


<script>
import IlmButton from '@/components/Buttons/IlmButton.vue';
import IlmButtonIcon from '@/components/Buttons/IlmButtonIcon.vue';

export default {
  name: 'NavbarPayment',

  // Components ********************************
  components: {
    IlmButton,
    IlmButtonIcon,
  },

  // Properties ********************************
  props: {
    title: String,
    subtitle: String,
    showOk: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showBackBevel: {
      type: Boolean,
      default: false,
    },
    showShadow: {
      type: Boolean,
      default: true,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disableBtnBack: {
      type: Boolean,
      default: false,
    },
    disableBtnOk: {
      type: Boolean,
      default: true,
    },
    onBack: Function,
    onOk: Function,
  },

  data() {
    return {
      barTitle: null,
      isScrolledDown: false,
    };
  },

  // Computed ********************************
  computed: {
    titleText() {
      return this.title;
    },
    titleClassNames() {
      const classNames = {
        'navbar-container__title-container--show-nav-back': this.canShowBack,
      };
      return classNames;
    },
    backClasses() {
      return {
        'navbar-container__back--bevel': this.showBackBevel,
        'navbar-container__back--disabled': this.disableBtnBack,
      };
    },
    navbarClasses() {
      const classNames = {
        'navbar-container--shadow': this.showShadow,
        'navbar-container--divider': this.showDivider,
      };
      if (this.state) {
        classNames[`navbar-container--${this.state}`] = true;
      }

      return classNames;
    },
    isBigScreen() {
      //return this.windowWidth >= this.$constants.MIN_SCREEN_WIDTH;
      return this.$root.isDesktop;
    },
    canShowBack() {
      const body = document.body;
      const fbMessenger = this.$store.getters.getMessenger;

      if (body.classList.contains('os--ios') && fbMessenger.inFrame) {
        return false;
      }
      if (this.$store.getters.isExternalSource) {
        return true;
      }

      return this.showBack;
    },
  },

  mounted() {
    const auth = this.$store.getters.getAuth;
    if (auth && auth.business && auth.business.name) {
      this.barTitle = auth.business.name;
    }
    /*
    const self = this;
    this.$nextTick(function () {
      if (self.interchangeableTitles) {
        window.addEventListener('scroll', self.handleScroll);
      }
    });*/
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    /*
    if (this.interchangeableTitles) {
      window.removeEventListener('scroll', this.handleScroll);
    }*/
  },

  // Methods ********************************
  methods: {
    handleScroll() {
      const container = this.$refs.navbar_container;
      const windowScrollY = window.pageYOffset;
      //console.log(`container.offsetTop: ${container.offsetTop}`);
      //console.log(`windowScrollY: ${windowScrollY}`);
      //console.log(`container.clientHeight: ${container.clientHeight}`);
      if (container) {
        const offset = container.offsetTop;
        const containerHeight = container.clientHeight;
        const ditanceGap = this.isBigScreen ? 130 : 0;
        if (offset + containerHeight + ditanceGap > windowScrollY) {
          this.isScrolledDown = false;
        } else {
          this.isScrolledDown = true;
        }
      }
    },
    handleBackClick() {
      if (this.disableBtnBack) return;
      if (this.onBack) {
        this.onBack();
      } else {
        this.$router.go(-1);
      }
    },

    handleOkClick() {
      if (this.onOk) {
        this.onOk();
      }
    },
  },

};
</script>


<style lang="scss" scoped>

.navbar-bar {
  background: white;

  &__title {
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin: 0;
  }
}

.navbar-container {
  position: relative;
  display: block;
  //padding: 4px 0;
  min-height: 60px;
  //background: $theme-ilm-color;
  background: white;
  //margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out 0s;

  &--shadow {
    box-shadow: $box-shadow-bottom;
  }

  &--divider {
    border-bottom: 1px solid $theme-list-divider-color;
  }

  &__title-container {
    display: block;/*
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);*/
    padding: 10px $padding-section;
    max-width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;

    &--show-nav-back {
      padding: 0 76px;
    }

    &__title {
      //color: white;
      //color: $theme-3-color;
      color: black;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      line-height: 1.2em;
      margin: 0;
      padding-bottom: 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      //color: white;
      color: black;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      line-height: 1.2em;
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__back {
    top: 10px;
    bottom: 10px;
    display: block;
    position: absolute;
    left: 16px;
    color: white !important;
    font-size: 24px;
    height: 40px;
      /*
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: white;
    font-size: 24px;
    height: 40px;
    */
    &--bevel {
      background: white;
      color: black !important;
      border: 1px solid $theme-list-divider-color;
    }
    @media (hover) {
      &:hover {
        background: $theme-2-bg;
      }
    }
    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__ok {
    position: absolute;
    right: 12px;
    top: 8px;
    bottom: 8px;
    padding: 10px 40px 10px 20px;
    line-height: 1;
    box-sizing: border-box;
  }

}

</style>
